import React from 'react';
import { useSelector } from 'react-redux';
import { selectEnabled } from 'store/Settings/ldap/selectors';
import BasicFields from './components/BasicFields';
import UserRoles from './components/UserRoles';
import Security from './components/Security';
import AdvancedSettings from './components/AdvancedSettings';
import { Wrapper, Row } from './styled';

const Form = () => {
  const enabled = useSelector(selectEnabled);

  return (
    <Wrapper enabled={ !enabled }>
      <BasicFields />
      <UserRoles />
      <Row>
        <Security />
        <AdvancedSettings />
      </Row>
    </Wrapper>
  );
};

export default Form;
