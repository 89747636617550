import styled from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';

export const Wrapper = styled.div`
  z-index: 9999;
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: 0px;
  text-align: center;
  left: 0;


  background-color: rgba(190, 200, 210, 0.75);

  ${fadeIn}
`;
Wrapper.displayName = 'Wrapper';

export const Container = styled.div`
  width: 90%;
  background: ${colors.paleGrey};
  padding: 1.5rem 0.5rem;
  border: 1px solid ${colors.grey8};

  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);



  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

`;
Container.displayName = 'Container';

export const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  line-height: 2.08;
  color: ${colors.blue6};
  font-weight: 600;
  margin: 0 10px;
`;
Title.displayName = 'Title';

export const Text = styled.div`
  display: inline-flex;
  font-family: SequelSans-RomanHead;
  font-size: 17px;
  font-weight: 100;
  color: ${colors.blueGrey};

  flex-basis: max-content;
`;
Text.displayName = 'Text';

export const Close = styled.button`
  float: right;
  border: 0;
  height: 20px;
  width: 20px;
  line-height: 20px;
  background: inherit;


  position: absolute;
  top: 10px;
  right: 20px;
`;
Close.displayName = 'Close';

export const Link = styled.a`
  margin: 0 5px;
  color: ${colors.blueCerulean};
`;
Link.displayName = 'Link';

export const Logo = styled.img`
  width: 97px;
  margin: 0 30px;
`;
