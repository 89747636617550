/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFileIndex, selectFilesToUpload, selectHasAssetTags, selectHasServiceTags, selectHasVulnTags, selectIgnoreInfo, selectResolveHost, selectUploadState } from 'store/ManageEditCreate/selectors';
import Wrapper from './styled';
import Body from './components/Body';
import Header from './components/Header';
import { uploadFile } from 'Screens/ManageEditCreate/actions/Actions';
import { selectProcessingQueueIsExpanded } from 'store/FileUploaderContext/selector';
import { selectTagsToAdd } from 'store/Tags/selectors';

const FileUploadContext = () => {
  const showBody = useSelector(selectProcessingQueueIsExpanded);
  const uploadState = useSelector(selectUploadState);
  const files = useSelector(selectFilesToUpload);
  const fileIndex = useSelector(selectFileIndex);
  const ignoreInfo = useSelector(selectIgnoreInfo);
  const resolveHost = useSelector(selectResolveHost);
  const tags = useSelector(selectTagsToAdd);
  const parsedTags = tags.map((t) => t.name).join(',');
  const dispatch = useDispatch();
  const hasAssetsTags = useSelector(selectHasAssetTags);
  const hasVulnsTags = useSelector(selectHasVulnTags);
  const hasServicesTags = useSelector(selectHasServiceTags);

  useEffect(() => {
    if (uploadState === 'PROCESSED' && fileIndex >= 0) {
      const params = [
        { name: 'file', value: files[fileIndex] },
        { name: 'ignore_info', value: ignoreInfo },
        { name: 'resolve_hostname', value: resolveHost }
      ];
      if (hasAssetsTags) params.push({ name: 'host_tag', value: parsedTags });
      if (hasVulnsTags) params.push({ name: 'vuln_tag', value: parsedTags });
      if (hasServicesTags) params.push({ name: 'service_tag', value: parsedTags });
      dispatch(uploadFile(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, uploadState]);

  return (
    <Wrapper>
      <Header showBody={ showBody } />
      {showBody && <Body /> }
    </Wrapper>
  );
};

export default FileUploadContext;
