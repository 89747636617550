import React from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { severitySelect } from 'Common/styles/style';
import { updateVuln } from 'store/Manage/actions';
import SeverityIcon from 'Common/Components/SeverityIcon';
import { selectVulnDetail, selectReadOnly } from 'store/Manage/selectors';
import { capitalize } from 'utils/stringUtils';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import get from 'lodash/get';
import { severities } from 'Screens/Constants';
import SeverityName from './styled';

const SeverityItem = ({ title }) => (
  <div style={ { display: 'flex', alignItems: 'center' } }>
    <SeverityIcon type={ title.charAt(0).toUpperCase() }>{title.charAt(0).toUpperCase()}</SeverityIcon>
    <SeverityName>{ capitalize(title) }</SeverityName>
  </div>
);

const SeverityPicker = () => {
  const dispatch = useDispatch();
  const vuln = useSelector(selectVulnDetail);
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const severity = get(vuln, 'severity', '');
  const value = {
    label: severity === 'med' ? 'medium' : severity,
    value: severity
  };

  return (
    <Select
      styles={ severitySelect }
      isSearchable={ false }
      value={ value }
      isDisabled={ readOnly || !canUpdate }
      onChange={ (e) => dispatch(updateVuln(vuln, 'severity', e.value)) }
      options={ severities.map((element) => ({ label: <SeverityItem title={ element.name } />, value: element.name })) }
    />
  );
};

export default SeverityPicker;
