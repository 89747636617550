import { selectPermissions } from 'store/Faraday/selectors';
import get from 'lodash/get';

const usePermission = (permission, type) => {
  const getState = get(window, 'store.getState', null);
  const permissions = selectPermissions(getState());
  return get(permissions, `${permission}.${type}.allowed_by_role`, false);
};

export default usePermission;
