import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserName } from 'store/Faraday/selectors';
import { Wrapper, Label, Input } from './styled';

const Username = () => {
  const userName = useSelector(selectUserName);
  return (
    <Wrapper>
      <Label>Username</Label>
      <Input>{ userName }</Input>
    </Wrapper>
  );
};

export default Username;
