import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { selectWsDetail } from 'store/Workspace/selectors';
import { selectWsEditCreateLocale } from 'store/WorkspaceEditCreate/selectors';
import { updateWS } from 'Screens/Contextualization/Workspaces/actions/Actions';
import TopButtons from './TopButtons';
import CheckBox from 'Common/Components/Checkbox/styled';
import DateRangePicker from 'Common/Components/Contextualization/DateRangePicket';
import CustomList from 'Common/Components/CustomList';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import {
  Wrapper, Line, Text, Table, TableBody, Row, Field,
  Label, CreationDate
} from './styled';
import moment from 'moment';

const ID = 'technicalInfo';

const TechnicalInfo = () => {
  const dispatch = useDispatch();
  const wsSelected = useSelector(selectWsDetail);
  const locale = useSelector(selectWsEditCreateLocale);
  const wsName = get(wsSelected, 'name', '');
  const publicWs = get(wsSelected, 'public', false);
  const vulnsCount = get(wsSelected, 'stats.total_vulns', 0);
  const assetsCount = get(wsSelected, 'stats.total_vulns', 0);
  const servicesCount = get(wsSelected, 'stats.services', 0);
  const creationDate = get(wsSelected, 'create_date', 0);
  const start = get(wsSelected, 'duration.start_date', null);
  const end = get(wsSelected, 'duration.end_date', null);
  const scope = get(wsSelected, 'scope', null);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'workspace.wsDetail.TechnicalInfo' });
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.UPDATE));
  const disabled = !canUpdate;

  const isExpanded = useExpandable('general', ID, true);

  const togglePublic = (e) => {
    e.stopPropagation();
    if (e.target.checked) {
      dispatch(updateWS(wsName, { public: true }));
    } else {
      dispatch(updateWS(wsName, { public: false }));
    }
  };

  const onCloseCalendar = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    dispatch(updateWS(wsName, { duration: { start_date: startDate.getTime(), end_date: endDate.getTime() } }));
  };

  const cleanDates = () => {
    dispatch(updateWS(wsName, { duration: { start_date: '', end_date: '' } }));
  };

  const onAddScope = (value) => dispatch(updateWS(wsName, { scope: [...scope, value] }));

  const onRemoveScope = (indexToRemove) => {
    const newScope = scope.filter((item, index) => index !== indexToRemove);
    dispatch(updateWS(wsName, { scope: newScope }));
  };

  const parseDate = (d) => {
    const today = moment();
    const isToday = moment(d).isSame(today, 'day');
    if (isToday) return 'Today';
    return moment(d).format('ddd MMM D YYYY');
  };

  return (
    <ExpandableWrapper id={ ID } title={ title } defaultValue={ isExpanded } tab="general" buttons={ <TopButtons /> }>
      <Wrapper>
        <Table>
          <TableBody>
           <Line>
              <Text>{ intl.formatMessage({ id: 'workspaces.wsDetail.publicWs' }) }</Text>
              <CheckBox checked={ publicWs } onClick={ togglePublic } title="Grant acces to every user in your instance" disabled={ disabled } />
           </Line>
            <Line>
              <Text>{ intl.formatMessage({ id: 'workspaces.table.columns.vulnerabilidades' }) }</Text>
              <Text>{ vulnsCount }</Text>
            </Line>
            <Line>
              <Text>{ intl.formatMessage({ id: 'workspaces.table.columns.assets' }) }</Text>
              <Text>{ assetsCount }</Text>
            </Line>
            <Line>
              <Text>{ intl.formatMessage({ id: 'workspaces.table.columns.services' }) }</Text>
              <Text>{ servicesCount }</Text>
            </Line>
          </TableBody>
        </Table>
        <Row>
          <Field>
            <Label>{ intl.formatMessage({ id: 'workspace.wsDetail.creationDate' }) }</Label>
            <CreationDate>{ parseDate(creationDate) }</CreationDate>
          </Field>
          <Field>
            <Label>{ intl.formatMessage({ id: 'workspace.wsDetail.startEndDate' }) }</Label>
            <DateRangePicker id="modal-ws-date" width="265px" height="34px" start={ start } end={ end } onClose={ onCloseCalendar } locale={ locale } cleanDates={ cleanDates } disabled={ disabled } hasMargin />
          </Field>
        </Row>
        <Field>
          <Label>{ intl.formatMessage({ id: 'workspaces.modal.newWorkspace.scope' }) }</Label>
          <CustomList
            key="MODAL_WS_CREATION"
            placeholder="Scope target"
            addItem={ onAddScope }
            removeItem={ onRemoveScope }
            listData={ scope }
            responsive
            canUpdate={ canUpdate }
          />
        </Field>
      </Wrapper>

    </ExpandableWrapper>

  );
};

export default TechnicalInfo;
