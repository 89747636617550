import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import CustomCheckbox from 'Common/Components/CustomCheckbox';
import {
  CheckboxLabel, WrapperCheckbox, Title, Subtitle
} from 'Screens/Contextualization/Settings/common-styled';

import { selectUseMarkdown, selectBorderWidth } from 'store/Settings/reportSelectors';
import { setReportField, saveReport, loadReport } from 'store/Settings/reportActions';
import Number from 'Common/Components/Number/styled';
import Header from '../Header';
import {
  Wrapper, Label, PreviewImage, ExampleContainer, ErrorMessage
} from './styled';
import TextPreview from './TextPreview/index';

const Report = ({ onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    dispatch(loadReport());
  }, [dispatch]);

  const useMarkdown = useSelector((state) => (selectUseMarkdown(state)));
  const borderWidth = useSelector((state) => (selectBorderWidth(state)));

  const change = (field, value) => {
    dispatch(setReportField(field, value));
  };

  const validBorderWidth = borderWidth >= 0;

  const close = () => {
    onClose();
  };

  const accept = () => {
    if (validBorderWidth) {
      dispatch(saveReport());
      close();
    } else {
      setErrorMessage('Border width must be a positive number.');
    }
  };

  return (
    <Wrapper>
      <Header onAccept={ accept } onClose={ close } />
      <Title>{intl.formatMessage({ id: 'preferences.report.title' })}</Title>
      <Subtitle>{intl.formatMessage({ id: 'preferences.report.subtitle' })}</Subtitle>
      <WrapperCheckbox>
        <CustomCheckbox id="useMarkdown" label="" handleCheckboxChange={ () => { change('useMarkdown', !useMarkdown); } } key="useMarkdown" isChecked={ useMarkdown } />
        <CheckboxLabel>{intl.formatMessage({ id: 'preferences.report.useMarkdown.title' })}</CheckboxLabel>
      </WrapperCheckbox>
      <Label>
        {intl.formatMessage({ id: 'preferences.report.borderWidth.title' })}
        <Number onChange={ (v) => { change('borderWidth', v.target.value); } } value={ borderWidth } min="0" />
      </Label>
      { errorMessage && (
      <ErrorMessage>
        { errorMessage }
        {' '}
      </ErrorMessage>
      ) }
      <Subtitle>{intl.formatMessage({ id: 'preferences.report.example.title' })}</Subtitle>
      <ExampleContainer>
        <TextPreview />
        <PreviewImage borderWidth={ borderWidth } />
      </ExampleContainer>
    </Wrapper>
  );
};
export default Report;
