import styled from 'styled-components';
import colors from 'Styles/colors';

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-bottom: 5px;
`;
TabsWrapper.displayName = 'TabsWrapper';

export const TabList = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;
TabList.displayName = 'TabList';

export const TabListContent = styled.div`
   padding-right: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: scroll;
`;
TabListContent.displayName = 'TabListContent';

export const Tab = styled.div`
  display: inline-block;
  padding: 0.5rem 0.75rem;
  font-size: 14.5px;
  margin-right: 10px;
  cursor: pointer;
  color: ${colors.dark2};
  user-select: none;
  ${({ active }) => active && `
    border-bottom: 2px solid ${colors.blueCerulean};
    color: ${colors.blueCerulean};
    font-weight: 600;
  `}
`;
Tab.displayName = 'Tab';
