import { get, isEmpty, isEqual } from 'lodash';
import { getFormattedDate } from 'store/Tasks/selectors';
import { GRAPH_LIST_VIEW } from './viewMode';

export const selectGraphs = (state) => get(state, 'analytics.graphsList', []);
export const selectIsEmpty = (state) => {
  const graphs = selectGraphs(state);
  return !(graphs.length > 0);
};
export const selectGraphSelected = (state) => get(state, 'analytics.graphSelected', {});

export const selectId = (state) => get(state, 'analytics.graphSelected.id', 0);
export const selectName = (state) => get(state, 'analytics.graphSelected.name', '');
export const selectDescription = (state) => get(state, 'analytics.graphSelected.description', '');
export const selectWorkspaces = (state) => get(state, 'analytics.graphSelected.workspaces', []);
export const selectHosts = (state) => get(state, 'analytics.graphSelected.hosts', []);
export const selectType = (state) => get(state, 'analytics.graphSelected.type', '');
export const selectNotConfirmed = (state) => get(state, 'analytics.graphSelected.notConfirmed', false);
export const selectClosed = (state) => get(state, 'analytics.graphSelected.closed', false);
export const selectAllowMultipleWs = (state) => get(state, 'analytics.graphSelected.allowMultipleWs', false);
export const selectAllowHosts = (state) => get(state, 'analytics.graphSelected.allowHosts', false);
export const selectData = (state) => get(state, 'analytics.graphSelected.data', {});
export const selectFrom = (state) => {
  const date = get(state, 'analytics.graphSelected.from', '');
  return getFormattedDate(date);
};

export const selectTo = (state) => {
  const date = get(state, 'analytics.graphSelected.to', '');
  return getFormattedDate(date);
};

export const selectRefreshedAt = (state) => get(state, 'analytics.graphSelected.refreshedAt', '');

export const selectError = (state) => get(state, 'analytics.error', false);
export const selectErrorMessage = (state) => get(state, 'analytics.errorMessage', '');

export const selectViewMode = (state) => get(state, 'graphs.viewMode', GRAPH_LIST_VIEW);

export const selectCurrentViewMode = (state) => get(state, 'analytics.currentViewMode', GRAPH_LIST_VIEW);
// al editar un graph pasarlo a true
export const selectGraphUpdated = (state) => get(state, 'analytics.graphUpdated', false);

export const graphTemplates = [
  {
    name: 'Vulnerabilities per Assets',
    description: 'Shows vulnerabilities inside assets that belong to a specific workspace with a bar chart.',
    key: 'vulnerabilities_per_host',
    allowMultipleWs: false,
    allowHosts: true,
    sideNoteType: 'severity'
  },
  {
    name: 'Monthly evolution of vulnerabilities by Severity',
    description: 'Shows the monthly evolution of vulnerabilities of a workspace classified by severity with a bar chart.',
    key: 'monthly_evolution_by_severity',
    allowMultipleWs: false,
    allowHosts: false,
    sideNoteType: 'severity'
  },
  {
    name: 'Monthly evolution of vulnerabilities by Status',
    description: 'Shows the monthly evolution of vulnerabilities of a workspace classified by status with a bar chart.',
    key: 'monthly_evolution_by_status',
    allowMultipleWs: false,
    allowHosts: false,
    sideNoteType: 'status'
  },
  {
    name: 'Most repeated vulnerabilities',
    description: 'Shows the most repeated vulnerabilities of a workspace with a bar chart.',
    key: 'top_ten_most_repeated_vulns',
    allowMultipleWs: false,
    allowHosts: false,
    sideNoteType: ''
  },
  {
    name: 'Top 10 most affected assets',
    description: 'Show the most affected assets of a workspace with a bar chart.',
    key: 'top_ten_most_affected_hosts',
    allowMultipleWs: false,
    allowHosts: false,
    sideNoteType: 'severity'
  },
  {
    name: 'Vulnerabilities per severity',
    description: 'Shows the amount of vulnerabilities by severity of one or several workspaces with a bar chart.',
    key: 'vulnerabilities_per_severity',
    allowMultipleWs: true,
    allowHosts: false,
    sideNoteType: 'severity'
  },
  {
    name: 'Vulnerabilities per status',
    description: 'Shows the amount of vulnerabilities by severity with a bar chart.',
    key: 'vulnerabilities_per_status',
    allowMultipleWs: true,
    allowHosts: false,
    sideNoteType: 'status'
  },
  {
    name: 'Vulnerabilities by Risk Score',
    description: 'Shows the amount of vulnerabilities by risk score with a bar chart.',
    key: 'vulnerabilities_by_risk_score',
    allowMultipleWs: true,
    allowHosts: false,
    sideNoteType: 'risk'
  }
];
export const selectIsFetching = (state) => get(state, 'analytics.isFetching', false);
export const selectHostsList = (state) => {
  const hosts = get(state, 'analytics.hosts.rows', []);
  const data = hosts.map((host) => host.value);
  return data;
};

// eslint-disable-next-line consistent-return
export const selectTableFromat = (type) => {
  const host = type === 'vulnerabilities_per_host';
  const ws = type === 'vulnerabilities_per_severity' || type === 'vulnerabilities_per_status' || type === 'vulnerabilities_by_risk_score';
  const month = type === 'monthly_evolution_by_severity' || type === 'monthly_evolution_by_status';
  const name = () => {
    if (host) return 'Host';
    if (ws) return 'Workspace';
    if (month) return 'Month';
    return 'Rank';
  };

  if (type === 'vulnerabilities_per_severity' || type === 'monthly_evolution_by_severity' || type === 'vulnerabilities_per_host') {
    return [{
      name: name(),
      selector: 'label'
    },
    {
      name: 'Critical',
      selector: 'critical'
    },
    {
      name: 'High',
      selector: 'high'
    },
    {
      name: 'Medium',
      selector: 'medium'
    },
    {
      name: 'Low',
      selector: 'low'
    },
    {
      name: 'Total',
      selector: 'total'
    }
    ];
  }
  if (type === 'vulnerabilities_per_status' || type === 'monthly_evolution_by_status') {
    return [{
      name: name(),
      selector: 'label'
    },
    {
      name: 'Open',
      selector: 'open'
    },
    {
      name: 'Closed',
      selector: 'closed'
    },
    {
      name: 'Re-Opened',
      selector: 're_opened'
    },
    {
      name: 'Risk-Accepted',
      selector: 'risk_accepted'
    },
    {
      name: 'Total',
      selector: 'total'
    }
    ];
  }
  if (type === 'top_ten_most_repeated_vulns') {
    return [{
      name: name(),
      selector: 'rank'
    },
    {
      name: 'Vulnerability Name',
      selector: 'label'
    },
    {
      name: 'Total',
      selector: 'total'
    }
    ];
  }
  if (type === 'top_ten_most_affected_hosts') {
    return [{
      name: 'Rank',
      selector: 'rank'
    },
    {
      name: 'Host',
      selector: 'label'
    },
    {
      name: 'Critical',
      selector: 'critical'
    },
    {
      name: 'High',
      selector: 'high'
    },
    {
      name: 'Medium',
      selector: 'medium'
    },
    {
      name: 'Low',
      selector: 'low'
    },
    {
      name: 'Total',
      selector: 'total'
    }
    ];
  }
  if (type === 'vulnerabilities_by_risk_score') {
    return [{
      name: name(),
      selector: 'label'
    },
    {
      name: 'Critical',
      selector: 'critical'
    },
    {
      name: 'High',
      selector: 'high'
    },
    {
      name: 'Medium',
      selector: 'medium'
    },
    {
      name: 'Low',
      selector: 'low'
    },
    {
      name: 'Info',
      selector: 'info'
    },
    {
      name: 'Total',
      selector: 'total'
    }
    ];
  }
};

export const selectWsErrors = (state) => get(state, 'analytics.graphSelected.data.errors.workspaces', []);
export const selectHostErrors = (state) => get(state, 'analytics.graphSelected.data.errors.hosts', []);
export const notificationType = (state) => {
  const wsErrors = selectWsErrors(state);
  const hostErrors = selectHostErrors(state);
  if (!isEmpty(wsErrors) && isEmpty(hostErrors)) return 'ws';
  if (isEmpty(wsErrors) && !isEmpty(hostErrors)) return 'host';
  return 'wsAndHost';
};

export const filtersChanged = (prevGraph, currentGraph, allowHosts, checkAll) => {
  const prevGraphFilters = (prevGraph && prevGraph.filters) || prevGraph;
  const notConfirmedChanged = isEqual(prevGraphFilters.only_confirmed, currentGraph.notConfirmed);
  const closedChanged = isEqual(prevGraphFilters.include_closed, currentGraph.closed);
  const fromChanged = isEqual(prevGraphFilters.date_from, currentGraph.from);
  const toChanged = isEqual(prevGraphFilters.date_to, currentGraph.to);
  const workspacesChanged = isEqual(prevGraphFilters.workspaces, currentGraph.workspaces);
  const hostsChanged = allowHosts ? isEqual(prevGraphFilters.hosts, currentGraph.hosts) : true;
  const nameChanged = prevGraph.name === currentGraph.name;
  const descriptionChanged = prevGraph.description === currentGraph.description;
  const refreshed = prevGraph.update_date === currentGraph.refreshedAt;

  const onlyFilters = !notConfirmedChanged ||
  !closedChanged ||
  !fromChanged ||
  !toChanged ||
  !workspacesChanged ||
  !hostsChanged ||
  !refreshed;

  const allChanged = !nameChanged || !descriptionChanged;

  return checkAll ? (onlyFilters || allChanged) : onlyFilters;
};

export const selectShowTable = (state) => get(state, 'analytics.graphSelected.showTable', true);
