/* eslint-disable react/no-children-prop */
import React from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import {
  MainDuplicateIcon, DuplicateIcon, Wrapper, Label
} from './styled';

const DuplicateLabel = ({ isMain }) => {
  const intl = useIntl();
  const mainLabel = intl.formatMessage({ id: 'vuln.detail.duplicatesTag.title.main' });
  const secondaryLabel = intl.formatMessage({ id: 'vuln.detail.duplicatesTag.title.secondary' });
  const Icon = () => (isMain ? <MainDuplicateIcon /> : <DuplicateIcon />);

  return (
    <Wrapper>
      <Icon />
      <Label children={ isMain ? mainLabel : secondaryLabel } />
    </Wrapper>
  );
};

DuplicateLabel.propTypes = {
  isMain: PropTypes.bool.isRequired
};

export default DuplicateLabel;
