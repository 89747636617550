import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  ExportButton
} from './styled';

const Export = ({ download }) => {
  const intl = useIntl();
  const exportLabel = intl.formatMessage({ id: 'analytics.createEdit.export' });

  const onClick = () => {
    download();
  };

  return (
    <ExportButton onClick={ onClick }>{ exportLabel }</ExportButton>
  );
};

Export.propTypes = {
  download: PropTypes.func.isRequired
};

export default Export;
