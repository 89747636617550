export const CATEGORIES = {
  agent: 'agent',
  cloudAgent: 'cloudAgent',
  asset: 'asset',
  preference: 'preference',
  report: 'report',
  schedule: 'schedule',
  user: 'user',
  vuln: 'vuln',
  workspace: 'workspace',
  account: 'account',
  upgrade: 'upgrade',
  subscription: 'subscription',
  search: 'search',
  pipelines: 'pipelines',
  duplicates: 'duplicates',
  planner: 'planner'
};

export const ACTIONS = {
  logIn: { name: 'logIn', label: 'Log In' },

  createVuln: { name: 'createVuln', label: 'Create Vuln' },
  deleteVuln: { name: 'deleteVuln', label: 'Delete Vuln' },
  editVuln: { name: 'editVuln', label: 'Edit Vuln' },
  importVulns: { name: 'importVulns', label: 'Import Vuln' },
  searchVulns: { name: 'searchVulns', label: 'Search Vuln' },

  createAsset: { name: 'createAsset', label: 'Create Asset' },
  deleteAseet: { name: 'deleteAseet', label: 'Delete Asset' },
  editAsset: { name: 'editAsset', label: 'Edit Asset' },

  createWorkspace: { name: 'createWorkspace', label: 'Create Workspace' },
  deleteWorkspace: { name: 'deleteWorkspace', label: 'Delete Workspace' },
  editWorkspace: { name: 'editWorkspace', label: 'Edit Workspace' },

  createAgent: { name: 'createAgent', label: 'Create Agent' },
  deleteAgent: { name: 'deleteAgent', label: 'Delete Agent' },
  runAgent: { name: 'runAgent', label: 'Run Agent' },

  runCloudAgent: { name: 'runCloudAgent', label: 'Run Cloud Agent' },

  editPreference: { name: 'editPreference', label: 'Edit Preference' },

  createUser: { name: 'createUser', label: 'Create User' },
  deleteUser: { name: 'deleteUser', label: 'Delete User' },
  editUser: { name: 'editUser', label: 'Edit User' },

  createSchedule: { name: 'createSchedule', label: 'Create Schedule' },
  editSchedule: { name: 'editSchedule', label: 'Edit Schedule' },
  deleteSchedule: { name: 'deleteSchedule', label: 'Delete Schedule' },

  createReport: { name: 'createReport', label: 'Create Report' },
  deleteReport: { name: 'deleteReport', label: 'Delete Report' },
  editReport: { name: 'editReport', label: 'Edit Report' },
  regenerateReport: { name: 'regenerateReport', label: 'Regenerate Report' },

  // category: upgrade
  showReport: { name: 'showReport', label: 'Show from Reports' },
  showAsset: { name: 'showAsset', label: 'Show from Assets' },
  showWorkspace: { name: 'showWorkspace', label: 'Show from Workspaces' },
  showUser: { name: 'showUser', label: 'Show from User' },
  showVulns: { name: 'showVulns', label: 'Show from Vulns' },
  showKnowledgeBase: { name: 'showKnowledgeBase', label: 'Show from Knowledge Base' },
  showAgents: { name: 'showAgents', label: 'Show from Agents' },
  showSchedule: { name: 'showSchedule', label: 'Show from Schedule' },
  showSendToTool: { name: 'showSendToTool', label: 'Show from Send To Tool' },
  showSettings: { name: 'showSettings', label: 'Show from Settings' },
  showImportVulns: { name: 'showImportVulns', label: 'Show from Import Vulns' },
  showAddAssets: { name: 'showAddAssets', label: 'Show from Add Assets' },
  showAddJobs: { name: 'showAddJobs', label: 'Show from Add Jobs' },
  clickReport: { name: 'clickReport', label: 'Click from Reports' },
  clickAsset: { name: 'clickAsset', label: 'Click from Assets' },
  clickWorkspaces: { name: 'clickWorkspace', label: 'Click from Workspaces' },
  clickUser: { name: 'clickUser', label: 'Click from User' },
  clickSendToTool: { name: 'clickSendToTool', label: 'Click from Send to Tool' },
  clickAgents: { name: 'clickAgents', label: 'Click from Agents' },
  clickSchedule: { name: 'clickSchedule', label: 'Click from Schedule' },
  clickKnowledgeBase: { name: 'clickKnowledgeBase', label: 'Click from Knowledge Base' },
  clickVulns: { name: 'clickVulns', label: 'Click from Vulns' },
  clickSettings: { name: 'clickSettings', label: 'Click from Settings' },
  clickImportVulns: { name: 'clickImportVulns', label: 'Click from Import Vulns' },
  clickAddAssets: { name: 'clickAddAssets', label: 'Click from Add Assets' },
  clickAddJobs: { name: 'clickAddJobs', label: 'Click from Add Jobs' },
  mySubscription: { name: 'mySubscription', label: 'My Subscription' },
  search: {
    vulns: {
      basic: { name: 'searchVulnsBasic', label: 'Search Vulns with Basic Filter' },
      avanced: { name: 'searchVulnsAvanced', label: 'Search Vulns with Advanced Filter' },
      preset: { name: 'searchVulnsPreset', label: 'Search Vulns with Preset Filter' },
      custom: { name: 'searchVulnsCustom', label: 'Search Vulns with Custom Filter' }
    },
    assets: {
      basic: { name: 'searchAssetsBasic', label: 'Search Assets with Basic Filter' },
      avanced: { name: 'searchAssetsAvanced', label: 'Search Assets with Advanced Filter' },
      preset: { name: 'searchAssetsPreset', label: 'Search Assets with Preset Filter' },
      custom: { name: 'searchAssetsCustom', label: 'Search Assets with Custom Filter' }
    }
  },
  clickCreatePipeline: { name: 'clickCreatePipeline', label: 'Create Pipeline' },
  clickRunPipeline: { name: 'clickRunPipeline', label: 'Run Pipeline' },
  clickSetAsDuplicates: { name: 'clickSetAsDuplicates', label: 'Set As Duplicates' },
  clickCreateProject: { name: 'clickCreateProject', label: 'Create Project' },
  clickCreateTask: { name: 'clickCreateTask', label: 'Create Task' },
  linkedVulnsTotal: { name: 'linkedVulnsTotal', label: 'Linked Vulnerabilities Total' }
};
