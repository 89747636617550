import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
    margin-bottom: 3px;
    text-align: initial;    
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    height: 17px;
    margin-bottom: 8px;
    text-align: left;
    font-size: 12.5px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    color: ${colors.grey17};
    margin-bottom: 1px;
    opacity: ${(props) => (props.disabled ? '0.3' : '1')};
    margin-bottom: 8px;
`;
Title.displayName = 'Title';

export const CustomInput = styled.input`
    width: ${(props) => (props.width ? `${props.width}` : '224px')};
    height: ${(props) => (props.height ? `${props.height}` : '38px')};    
    border-bottom: ${(props) => (props.error ? '1px solid red' : 'none')};    
    border-left: none;
    border-top: none;
    border-right: none;
    box-shadow: inset 0 -1px 0 0 ${colors.grey30};
    background-color: ${(props) => (props.disabled ? '#edf2f7' : colors.iceBlue)};
    padding-left: 15px;
    padding-right: 5px;
    font-size: 14.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.48;
    letter-spacing: normal; 
    color: ${(props) => (props.disabled ? '#90a9c0' : '#1c2243')};
    border-radius: 1px;

    &.full{
        background-color: white; 
    }

    &::placeholder {
        color: #afb1bc;
        font-size: 13.5px;
        font-weight: 300;
    }

    &:hover{
        border: 1px solid #afb1bc;        
    }

    &:focus{
        background-color: white;        

        &::placeholder {
            color: transparent;
        }
    }
`;
CustomInput.displayName = 'CustomInput';

export const Error = styled.div`
    margin-top: 6px;
    font-size: 10.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    color: #ea3158;
`;
Error.displayName = 'Error';
