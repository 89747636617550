/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnsSelected, selectSelectedMainVulns } from 'store/ManageGeneral/selectors';
import { markDuplicates } from 'store/ManageGeneral/duplicateActions';
import { unSelectAllVulns } from 'store/ManageGeneral/actions';
import isNull from 'lodash/isNull';
import { PropTypes } from 'prop-types';
import { ModalContent, RadioButtonsContainer } from 'Screens/Contextualization/Manage/components/CustomActionBar/components/MarkAsDuplicateModal/styled';
import RadioItem from 'Screens/Contextualization/Manage/components/CustomActionBar/components/MarkAsDuplicateModal/components/RadioItem';
import Buttons from 'Screens/Contextualization/Manage/components/CustomActionBar/components/MarkAsDuplicateModal/components/Buttons';
import Texts from 'Screens/Contextualization/Manage/components/CustomActionBar/components/MarkAsDuplicateModal/components/Texts';

const MarkAsDuplicateModal = ({ hideModal }) => {
  const [mainID, setMainID] = useState(null);
  const dispatch = useDispatch();
  const selectedVulns = useSelector(selectVulnsSelected);
  const selectedVulnsIDs = selectedVulns.map((v) => v.id);
  const selectionMains = useSelector(selectSelectedMainVulns);

  const markAsDuplicates = () => {
    dispatch(markDuplicates(selectedVulnsIDs, mainID));
    dispatch(unSelectAllVulns());
    setMainID(null);
    hideModal();
  };

  const onRadioClick = ({ target }) => setMainID(Number(target.value));

  const cancel = () => {
    setMainID(null);
    hideModal();
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <Texts />
        <RadioButtonsContainer>
          { selectionMains.map((m) => <RadioItem key={ m.id } vuln={ m } onRadioClick={ onRadioClick } checked={ m.id === mainID } />) }
        </RadioButtonsContainer>
        <Buttons onCancelClick={ cancel } onConfirmClick={ markAsDuplicates } disabled={ isNull(mainID) } />
      </ModalContent>
    </ModalWrapper>
  );
};

MarkAsDuplicateModal.propTypes = {
  hideModal: PropTypes.func.isRequired
};

export default MarkAsDuplicateModal;
