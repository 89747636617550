import styled from 'styled-components';
import colors from 'Styles/colors';
import { Default, Accept } from 'Common/Components/Button/styled';
import { fadeIn } from 'Styles/effects';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(99, 117, 141, 0.5);
  z-index: 500;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  & > :first-child {
    box-shadow: 18px 43px 50px 0 rgba(167, 167, 167, 0.5);
    border-radius: 2px 2px 0 0;
    overflow: auto;
    max-height: 85%;
    background: ${colors.white};
  }
  ${fadeIn(0, 1)}
`;
Wrapper.displayName = 'Wrapper';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 430px;
  width: 505px;
  background-color: #fff;
  padding: 34px;
  border-top-style: solid;
  border-top-width: 10px;
  border-top-color: ${colors.softBlue};
`;
ModalWrapper.displayName = 'ModalWrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  color: ${colors.darkBlueGrey};
  line-height: 1.49;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
Title.displayName = 'Title';

export const Message = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: ${colors.greyBlue};
  margin-top: 17px;
  width: 100%;
`;
Message.displayName = 'Message';

export const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 34px;
  display: flex;
  ${(props) => (!props.cancel && `
    justify-content: flex-end;
  `)};
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';

export const Cancel = styled(Default)`
  width: 50%;
`;
Cancel.displayName = 'Cancel';

export const Done = styled(Accept)`
  width: 50%;
`;
Done.displayName = 'Done';
