import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div` 
  display: flex;
  flex-direction: column;
  min-height: 70px;
  height: 100%;
  width: 100%;
  ${({ disabled }) => disabled && 'opacity: 0.5'};
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  flex: 0 0 14px;
  margin-bottom: 5px;
  letter-spacing: 0.09px;
  user-select: none;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${({ disabled, required, isEmpty }) => (!disabled && required && isEmpty ? '#EA3158' : '#AFB1BC')};
`;
Title.displayName = 'Title';

export const TextInput = styled.textarea`
  resize: ${({ disabled }) => (disabled ? 'none' : 'vertical')};
  min-height: 70px;
  width: 100%;
  border-style: solid;
  border-color: #afb1bc;
  border-width: 0px 0px 1px 0px;
  border-color: ${({
    disabled, error, required, isEmpty
  }) => ((error || (!disabled && required && isEmpty)) ? '#EA3158' : '#AFB1BC')};  padding: 15px;
  font-size: 14.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  color: #1c2243;
  background-color: ${({ isEmpty }) => (isEmpty ? `${colors.iceBlue}` : '#FFF')}; 

  &::placeholder {
    color: #afb1bc;
    font-size: 13.5px;
    font-weight: 300;
  }

  &:focus{
      background-color: white;        

      &::placeholder {
          color: transparent;
      }
  }
  
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  /* Firefox */
  &[type=number] {
  -moz-appearance: textfield;
  }
`;
TextInput.displayName = 'TextInput';
