import React from 'react';
import { useIntl } from 'react-intl';
import {
  Wrapper, Title, SubTitle, Icon
} from './styled';

const Empty = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Icon />
      <Title>{intl.formatMessage({ id: 'quickPlanner.empty.title' })}</Title>
      <SubTitle to="/planner">{intl.formatMessage({ id: 'quickPlanner.empty.subtitle' })}</SubTitle>
    </Wrapper>
  );
};

export default Empty;
