import styled from 'styled-components';
import TagsModal from 'Common/Components/TagsModal';
export const Wrapper = styled.div`
  height: 100%;
  padding: 13px 0px;
`;
Wrapper.displayName = 'Wrapper';

export const EmptyWrapper = styled.div`
  margin: 0px auto auto auto;
`;
EmptyWrapper.displayName = 'EmptyWrapper';

export const TableContainer = styled.div`
  height: 100%;
  flex: 1 1 0px;
  overflow: hidden;
`;
TableContainer.displayName = 'TableContainer';

export const Modal = styled(TagsModal)`
  
`;
Modal.displayName = 'Modal';
