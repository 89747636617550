import React from 'react';
import { VictoryStack, VictoryBar, VictoryTooltip } from 'victory';
import { Wrapper, colors } from './styled';
import Flyout from './components/Flyout';

const RiskHistogram = ({ data }) => (
  <Wrapper>
    {data &&
        (
          <VictoryStack height={ 50 } padding={ 10 }>
            <VictoryBar
              sortKey={ (datum) => datum.date }
              style={ { data: { fill: ({ datum }) => colors[datum.risk.severity] } } }
              data={ data }
              x="date"
              y="risk.avg"
              labels={ () => '' }
              labelComponent={ (
                <VictoryTooltip
                  flyoutComponent={ <Flyout data={ data } /> }
                />
            ) }
              barWidth={ 15 }
            />
          </VictoryStack>
        )}
  </Wrapper>
);

export default RiskHistogram;
