import styled from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .7);
  z-index: 500;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  & > :first-child {
    border-top: 10px solid ${colors.softBlue};
    padding: 36px 39px;
  }
  ${fadeIn(0, 1)}
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
