import React from 'react';
import PropTypes from 'prop-types';
import {
  SelectedWs, WorkspaceAlreadyAssignedStyles, Container
} from 'Screens/Users/components/ContextMenuUsersMassiveUpdate/SubMenuWorkspaces/styled';

const WorkspaceAlreadyAssigned = ({ workspace }) => (
  <Container>
    <WorkspaceAlreadyAssignedStyles>
      <SelectedWs>
        {workspace.name}
      </SelectedWs>
    </WorkspaceAlreadyAssignedStyles>
  </Container>
);

WorkspaceAlreadyAssigned.propTypes = {
  workspace: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};

export default WorkspaceAlreadyAssigned;
