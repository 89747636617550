import styled from 'styled-components';

export const DuplicatesWrapper = styled.div`
  margin: 10px 0;
`;
DuplicatesWrapper.displayName = 'DuplicatesWrapper';

export const ListWrapper = styled.div`
  max-height: 103px;
  overflow-y: auto;
  & > *:not(:last-child) {
    margin-bottom: 14px;
  }
`;
ListWrapper.displayName = 'ListWrapper';

export const Wrapper = styled.div`
  padding: 0 20px 20px 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Subtitle = styled.div`
  margin-top: 8px;
  margin-bottom: 14px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c4566;
  user-select: none;
  text-align: left;
`;
Subtitle.displayName = 'Subtitle';
