import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import {
  selectHostDetailHostnames, selectAssetDetailId,
  selectHostDetailOs, selectHostDetailCreateDate,
  selectToolsUsers, selectToolsParameters, selectToolsCommands
  , selectHostDetailMac,
  selectHostDetail,
  selectHostWorkspace
} from 'store/Contextualization/AssetDetail/selectors';
import TopButtons from './TopButtons';
import CustomList from 'Common/Components/CustomList';
import {
  Wrapper, Row, Field,
  Label, FixField, TextField
} from './styled';
import DateFormatter from 'Common/Components/DateFormatter';
import { updateHost } from 'store/Host/actions';
import List from './List';
import { getTools } from 'store/Contextualization/AssetDetail/actions';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { updateHostGeneral } from 'store/HostsGeneral/actions';

const ID = 'technicalInfo';

const TechnicalInfo = ({ assetsGeneral }) => {
  const dispatch = useDispatch();
  const hostDetail = useSelector(selectHostDetail);
  const hostId = useSelector(selectAssetDetailId);
  const hostnames = useSelector(selectHostDetailHostnames);
  const os = useSelector(selectHostDetailOs);
  const creationDate = useSelector(selectHostDetailCreateDate);
  const mac = useSelector(selectHostDetailMac);
  const users = useSelector(selectToolsUsers);
  const parameters = useSelector(selectToolsParameters);
  const commands = useSelector(selectToolsCommands);
  const [osValue, setOsValue] = useState(os);
  const [macValue, setMacValue] = useState(mac);
  const [isValidMacAddress, setIsValidMacAddress] = useState(true);
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.UPDATE));
  const canUpdate = !readOnly && allowedByRole;
  const workspace = useSelector(selectHostWorkspace);

  const intl = useIntl();
  const title = intl.formatMessage({ id: 'workspace.wsDetail.TechnicalInfo' });

  const isExpanded = useExpandable('general', ID, true);

  useEffect(() => {
    dispatch(getTools(workspace));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOsValue(hostDetail.os);
    setMacValue(hostDetail.mac);
  }, [hostDetail]);

  const update = (field, value) => assetsGeneral ? dispatch(updateHostGeneral(hostId, { [field]: value }, workspace)) : dispatch(updateHost(hostId, { [field]: value }));

  const onRemoveHostname = (indexToRemove) => {
    const newHostnames = hostnames.filter((item, index) => index !== indexToRemove);
    update('hostnames', newHostnames);
  };

  const validateMacAddress = (str) => {
    // eslint-disable-next-line prefer-regex-literals
    const regex = new RegExp('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$');
    setIsValidMacAddress(!!regex.test(str));
  };

  const onChangeMac = (value) => {
    setMacValue(value);
    if (value === '') setIsValidMacAddress(true);
    else validateMacAddress(value);
  };

  const updateMacAdress = () => {
    if (isValidMacAddress) update('mac', macValue);
  };
  return (
    <ExpandableWrapper id={ ID } title={ title } defaultValue={ isExpanded } tab="general" buttons={ <TopButtons assetsGeneral={ assetsGeneral } /> }>
      <Wrapper>
        <Field>
          <Label>{ intl.formatMessage({ id: 'host.hostDetail.hostnames' }) }</Label>
          <CustomList
            key="HOST_CREATION_HOST_NAMES"
            placeholder="net.hostname.com"
            addItem={ (value) => update('hostnames', [...hostnames, value]) }
            removeItem={ onRemoveHostname }
            listData={ hostnames }
            height="34px"
            responsive
            mtList="11px"
            canUpdate={ canUpdate }
          />
        </Field>
        <Row>
          <Field>
            <Label>{ intl.formatMessage({ id: 'host.hostDetail.user' }) }</Label>
            <List data={ users } />
          </Field>
          <Field>
            <Label>{ intl.formatMessage({ id: 'host.hostDetail.parameters' }) }</Label>
            <List data={ parameters } />
          </Field>
        </Row>
        <Row>
          <Field>
            <Label>{ intl.formatMessage({ id: 'host.hostDetail.creationDate' }) }</Label>
            <FixField><DateFormatter date={ creationDate } /></FixField>
          </Field>
          <Field>
            <TextField title={ intl.formatMessage({ id: 'host.hostDetail.os' }) } name="os" placeholder="Unix" value={ osValue } onChange={ (value) => setOsValue(value) } onBlur={ () => update('os', osValue) } disabled={ !canUpdate } />
          </Field>
        </Row>
        <Row>
          <Field>
            <TextField title="MAC Address" name="mac address" placeholder="00:00:5e:00:53:af" value={ macValue } onChange={ onChangeMac } onBlur={ updateMacAdress } error={ !isValidMacAddress } errorMessage="Invalid MAC Address" disabled={ !canUpdate } />
          </Field>
          <Field>
            <Label>{ intl.formatMessage({ id: 'host.hostDetail.command' }) }</Label>
            <List data={ commands } />
          </Field>
        </Row>
      </Wrapper>

    </ExpandableWrapper>

  );
};

export default TechnicalInfo;
