import styled from 'styled-components';

export const Wrapper = styled.div`
  & > * {
    margin-bottom: 21px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const FieldWrapper = styled.div`
  padding: 9px 20px;
`;
FieldWrapper.displayName = 'FieldWrapper';
