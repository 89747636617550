/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import Vuln from 'Screens/Planner/Tasks/CreateEdit/components/Tabs/LinkedVulns/VulnPreview';
import { useSelector, useDispatch } from 'react-redux';
import { selectTaskLinkedVulns } from 'store/Tasks/selectors';
import Error from 'Common/Components/Error';
import InputAdd from 'Common/Components/InputAdd';
import api from 'services/api';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { setTaskField } from 'store/Tasks/actions';
import { selectIsAdmin } from 'store/Faraday/selectors';
import isEmpty from 'lodash/isEmpty';
import {
  AddWrapper, Wrapper, Subtitle, VulnsWrapper
} from './styled';

const LinkedVulns = () => {
  const dispatch = useDispatch();
  const vulns = useSelector(selectTaskLinkedVulns);
  const [error, setError] = useState(null);
  const intl = useIntl();
  const isAdmin = useSelector(selectIsAdmin);
  const subtitle = intl.formatMessage({ id: 'planner.task.tab.linkedVulns.subtitle' });
  const clientSubtitle = intl.formatMessage({ id: 'planner.task.tab.linkedVulns.clientSubtitle' });

  const onAdd = async (value) => {
    if (value === '') return;
    let id = value;
    if (id.includes('manage/')) {
      const valueArray = id.split('/');
      id = valueArray[valueArray.length - 1];
      const workspace = valueArray[valueArray.length - 2];
      try {
        const vulnNotAdded = vulns.every((vuln) => vuln.id.toString() !== id);
        if (vulnNotAdded) {
          const relatedVuln = await api.manage.getVulnDetail(workspace, id);
          dispatch(setTaskField('linkedVulns', [...vulns, { ...relatedVuln, workspace, allowed: true }]));
          setError(null);
        }
      } catch (e) {
        setError(e.message);
      }
    } else {
      setError('Please enter a valid link');
    }
  };

  return (
    <Wrapper>
      <AddWrapper>
        <Subtitle children={ isAdmin ? subtitle : clientSubtitle } />
        { isAdmin && <InputAdd placeholder="/manage/my_workspace/101" onAdd={ onAdd } /> }
        { isAdmin && error && <Error>{error.replace(/&quot;/g, '')}</Error> }
      </AddWrapper>
      <VulnList vulns={ vulns } deleteVuln={ (id) => dispatch(setTaskField('linkedVulns', vulns.filter((v) => v.id !== id))) } />
    </Wrapper>
  );
};

export default LinkedVulns;

const VulnList = ({ vulns, deleteVuln }) => {
  const isAdmin = useSelector(selectIsAdmin);
  const hasVulns = !isEmpty(vulns);
  const sortedVulns = vulns.sort((v1, v2) => {
    if (v1.allowed && !v2.allowed) return -1;
    if (!v1.allowed && v2.allowed) return 1;
    return v1.id - v2.id;
  });

  if (!hasVulns) return null;
  return (
    <VulnsWrapper>
      {sortedVulns.map((v) => <Vuln isEditingTask key={ v.id } vuln={ v } deleteVuln={ isAdmin ? () => deleteVuln(v.id) : null } bgColor="#ffffff" />)}
    </VulnsWrapper>
  );
};

VulnList.propTypes = {
  deleteVuln: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  vulns: PropTypes.arrayOf(PropTypes.object).isRequired
};
