import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Title from './Title';
import { Wrapper, CloseIconWrapper, CloseIcon, Owned } from './styled';
import CopyLink from 'Common/Components/CopyLink';
import CopyApiLink from 'Common/Components/CopyApiLink';
import { selectCurrentWorkspace, selectIsReadOnly } from 'store/Faraday/selectors';
import { FEATURE_SERVICES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';

const Header = ({ data, owned, serviceId, redirectToService, updateService }) => {
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const intl = useIntl();
  const ownedText = owned ? intl.formatMessage({ id: 'host.hostDetail.owned' }) : intl.formatMessage({ id: 'host.hostDetail.notOwned' });
  const servicePath = `ws/${workspaceSelected}/services/${serviceId}`;
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_SERVICES, PERMISSION_TYPES.UPDATE));
  const canUpdate = !readOnly && allowedByRole;

  const updateOwned = () => {
    if (canUpdate) updateService(serviceId, { owned: !owned });
  };

  return (
    <Wrapper>
      <Title serviceDetail={ data } serviceId={ serviceId } updateService={ updateService } />
      <Owned owned={ owned } onClick={ updateOwned } disabled={ !canUpdate }>{ ownedText }</Owned>
      <CopyLink />
      <CopyApiLink path={ servicePath } />
      <CloseIconWrapper onClick={ () => dispatch(redirectToService()) }>
        <CloseIcon />
      </CloseIconWrapper>
    </Wrapper>
  );
};

export default Header;
