import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as _TokenIcon } from 'Images/icon-action-bar-token.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  padding: 6px 15px;
  border-top: 1px solid ${colors.light3};
`;
Wrapper.displayName = 'Wrapper';

export const RightItems = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  font-family: 'CentraNo2';
  font-size: 10px;
`;
RightItems.displayName = 'RightItems';

export const TokenIcon = styled(_TokenIcon)`
  width: 24px;
  height: 12.4px;
`;
TokenIcon.displayName = 'TokenIcon';

export const Description = styled.span`
  color: ${colors.blueGrey};
  margin: 0 7px;
`;
Description.displayName = 'Description';

export const Total = styled.span`
  color: ${colors.darkBlueGrey};
`;
Total.displayName = 'Total';
