import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Help } from 'Images/icon_noun_help.svg';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const TopRow = styled.div`
  display: flex;
  width: 100%;
`;
TopRow.displayName = 'TopRow';

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;
DateWrapper.displayName = 'DateWrapper';

export const WrapperHelpIcon = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const HelpIcon = styled(Help)`
  margin: 18px 24px 0 14px;
`;
HelpIcon.displayName = 'HelpIcon';

export const CheckboxWrapper = styled(DateWrapper)`
  margin-top: 22px;
`;
CheckboxWrapper.displayName = 'CheckboxWrapper';

export const Label = styled.div`
  font-size: 13px;
  color: ${colors.greyBlue};
  margin-left: 8px;
`;
Label.displayName = 'Label';
