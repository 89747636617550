/* eslint-disable react/no-children-prop */
import useExpandable from 'Hooks/useExpandable';
import React from 'react';
import { useIntl } from 'react-intl';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import AddTagButton from './components/AddTagButton';
import TagItems from './components/TagItems';
import {
  Wrapper
} from './styled';

const ID = 'tags';

const Tags = ({ serviceTags, entity }) => {
  const intl = useIntl();
  const isExpanded = useExpandable('general', ID, serviceTags);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } id="tags" title={ intl.formatMessage({ id: 'manage.detail.tab.general.tags' }) } tab="general">
      <Wrapper>
        <TagItems serviceTags={ serviceTags } />
        <AddTagButton entity={ entity } />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Tags;
