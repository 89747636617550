import styled from 'styled-components';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  flex: 1 1 0;
`;
Wrapper.displayName = 'Wrapper';

export const StyledTable = styled(CustomReactTable)`
  overflow: initial;
  .rt-tbody {
    background-color: ${colors.white1};
  }
  .rt-tbody .rt-tr {
    // This hack is because we need to align both gantt and table. 
    // Since Gantt uses SVGs with coordinates that are not well calculated,
    // any change equal than 1 px keeps making both elements unaligned.
    height: 49.2px; 
  }
  .rt-thead {
    &.-header {
      position: unset !important;
    }
  }
  .rt-thead .rt-tr {
    align-items: end !important;
  }
  
  .rt-thead .rt-th {
    margin-bottom: 5px !important;
  }

  .rt-tr-group {
    &:hover {
      .actionButton{
        visibility: visible;
      }
    }
    .rt-tr-group {
      background-color: ${colors.white};
      &:hover {
        background-color: ${colors.iceBlue};
        .taskActionButton {
          visibility: visible;
        }
      }
    }
  }

  .actionButton {
    padding: 0px !important;
    visibility: hidden;
    overflow: visible;
  }

  .taskActionButton {
    padding: 0px !important;
    visibility: hidden;
    overflow: visible;
  }
`;
CustomReactTable.displayName = 'CustomReactTable';
