import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Tag } from 'Images/tag.svg';
import { ReactComponent as CloseSVG } from 'Images/icon-close-without-background.svg';

export const Wrapper = styled.div`
  display: flex;  
`;
Wrapper.displayName = 'Wrapper';

export const TagIcon = styled(Tag)`
  margin-right: 20px;
  cursor: default;
`;
TagIcon.displayName = 'TagIcon';

export const Title = styled.span`
  font-size: 21.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.blueCerulean};
`;
Title.displayName = 'Title';

export const CloseIconWrapper = styled.div`
  height: 37px;
  width: 37px;
  margin: auto 0 auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 21px;
  right: 20px;
  &:hover{
    background-color: #edf2f7;
    border-radius: 50%;
  }  
`;
CloseIconWrapper.displayName = 'CloseIconWrapper';

export const CloseIcon = styled(CloseSVG)`
  cursor: pointer;
  & g {
    & path{
      fill: #63758d;
    }  
  }
`;
CloseIcon.displayName = 'CloseIcon';
