import React from 'react';
import Empty from './components/Empty';
// import { useSelector } from 'react-redux';
// import { selectShowLegacyUI } from 'store/Preferences/selectors';
import ActionBar from './components/ActionBar';
import { Wrapper, WrapperContent } from './styled';
import SelectType from './components/SelectType';

const NewGraph = () => {
  // const showLegacyUI = useSelector(selectShowLegacyUI);
  return (
    <>
    {/* { showLegacyUI
      ? <Wrapper>
        <ActionBar />
          <WrapperContent>
            <SelectType />
            <Empty />
          </WrapperContent>
        </Wrapper>
      :  */}
      <Wrapper showNewUI>
        <SelectType />
        <WrapperContent showNewUI>
          <ActionBar />
          <Empty />
        </WrapperContent>
      </Wrapper>
    </>
  );
};

export default NewGraph;
