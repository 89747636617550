import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox/index';
import { FormattedMessage } from 'react-intl';
import { selectIsHidingTasks } from 'store/Projects/selectors';
import { hideCompletedTasks } from 'store/Projects/actions';
import { Wrapper, Title } from './styled';

const HideTask = () => {
  const dispatch = useDispatch();
  const isChecked = useSelector(selectIsHidingTasks);

  const toggleCheckbox = () => dispatch(hideCompletedTasks(!isChecked));

  return (
    <Wrapper>
      <Checkbox onChange={ toggleCheckbox } state={ isChecked } theme="green" />
      <Title>
        <FormattedMessage id="planner.project.hideCompletedTasks" />
      </Title>
    </Wrapper>
  );
};

export default HideTask;
