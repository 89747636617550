import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toggleViewType } from 'store/Projects/actions';
import { selectViewType } from 'store/Projects/selectors';
import { Wrapper } from './styled';

const ViewSelector = () => {
  const dispatch = useDispatch();
  const viewType = useSelector(selectViewType);
  const toggleView = () => dispatch(toggleViewType());
  return (
    <Wrapper onClick={ toggleView }>
      { viewType.nextViewIcon }
      <FormattedMessage id={ viewType.nextViewIntlId } />
    </Wrapper>
  );
};

export default ViewSelector;
