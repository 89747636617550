import React, { useRef, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Wrapper,
  Title,
  CodeWrapper
} from './styled';
import CodeField from './components/CodeField';

const SecretCode = ({ onChange, submit }) => {
  const intl = useIntl();
  const [codes, setCodes] = useState(['', '', '', '', '', '']);
  const refs = useRef([]);

  useEffect(() => {
    if (refs.current[0]) {
      refs.current[0].focus();
    }
  }, []);

  const handleInputChange = (index) => (value) => {
    const newArr = [...codes];
    newArr[index] = value;
    setCodes(newArr);

    if (index < 5 && value) {
      refs.current[index + 1].focus();
    }

    if (onChange) onChange(newArr.join(''));
  };

  const handleBackspace = (index) => () => {
    if (index > 0) {
      refs.current[index - 1].focus();
    }
  };

  return (
    <Wrapper>
      <Title>{intl.formatMessage({ id: 'preferences.account.twoFactorAuthentication.secretCode.title' })}</Title>
      <CodeWrapper>
        {Array.from({ length: 6 }).map((_, i) => (
          <CodeField
            key={ i }
            ref={ (el) => (refs.current[i] = el) }
            value={ codes[i] }
            onChange={ handleInputChange(i) }
            onEnter={ submit }
            onBackspace={ handleBackspace(i) }
          />
        ))}
      </CodeWrapper>
    </Wrapper>
  );
};

export default SecretCode;
