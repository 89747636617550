import cloneDeep from 'lodash/cloneDeep';
import { copy } from '../../Common/Functions/Copy';
import {
  GET_DATA_USER_START,
  GET_DATA_USER_FAIL,
  GET_DATA_USER_SUCCESS,
  USER_SELECTED,
  USER_SELECTED_ALL,
  RESET_STATE_USER,
  RESET_TABLE_USER_WITH_ATTRIBUTTES,
  SET_USER_PAGE,
  SHOW_MODAL_DELETE_CONFIRMATION_USER,
  HIDE_MODAL_DELETE_CONFIRMATION_USER,
  DELETE_USER,
  HIDE_MODAL_DELETE_CONFIRMATION_USER_SUCCED,
  ENABLE_PROPAGATION_USER,
  SET_USER_2FA_STATUS,
  UPDATE_SECOND_FACTOR_SUCCESS,
  MORE_OPTIONS_STATUS_CHANGE_USERS,
  UPDATE_SUCCESS_BULK,
  USER_CREATE_SUCCESS,
  USER_CREATE_START,
  USER_CREATE_FAIL,
  GET_DATA_USERS_NEXT_PAGE,
  GET_DATA_USERS_FILTER,
  SET_USER_FOR_EDIT,
  SET_VISIBILITY_CREATE_MODAL_USER,
  SET_SHOW_USERS_CONTEXT_MENU,
  RESET_STATE_USER_CREATE_UPDATE,
  GET_USERS_COUNT_FAIL,
  USER_UNSELECT_ALL
} from '../../Screens/Users/actions/Actions';

const initialState = {
  name: '',
  username: '',
  lastSeen: '',
  from: '',
  status_2fa: '',
  roles: [],
  isFetching: false,
  error: false,
  errorMessage: '',
  userList: [],
  userCount: 0,
  rowsPerPage: 40,
  page: 1,
  allSelected: false,
  reloadTable: false,
  showDeleteConfirmation: false,
  sorted: '',
  stopPropagation: false,
  filters: '',
  sorting: [],
  success: false,
  message: '',
  secondFactorConfiguration: {
    initialStatus: '',
    status: '',
    requestPassword: false,
    modified: false,
    password: '',
    secret: '',
    rq: ''
  },
  userSelected: [],
  lastSelected: -1,
  lastIndexForRange: -1,
  lastUsersSelected: {},
  moreOptionsOpened: false,
  showModalCreate: false,
  isShowingContextMenu: false
};

function users (state = initialState, action) {
  switch (action.type) {
    case GET_DATA_USER_START: {
      return {
        ...state,
        isFetching: true,
        workspaceSelected: [],
        reloadTable: false
      };
    }

    case GET_DATA_USER_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }

    case GET_DATA_USER_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        userList: action.data,
        userCount: action.userCount,
        sorting: action.sorting ? action.sorting : [],
        stopPropagation: false
      };
    }
    case RESET_STATE_USER_CREATE_UPDATE: {
      return {
        ...state,
        success: false,
        error: false,
        message: '',
        userSelected: []
      };
    }

    case USER_SELECTED: {
      const {
        newRowsSelected, lastSelected, indexForRange, rowSelected
      } = action.usersSelectedInfo;

      return {
        ...state,
        lastUsersSelected: rowSelected,
        userSelected: newRowsSelected,
        lastSelected: lastSelected || -1,
        lastIndexForRange: indexForRange || -1
      };
    }

    case USER_UNSELECT_ALL: {
      return {
        ...state,
        allSelected: initialState.allSelected,
        userSelected: initialState.userSelected,
        lastSelected: initialState.lastSelected,
        lastIndexForRange: initialState.lastIndexForRange,
        lastUsersSelected: initialState.lastUsersSelected
      };
    }

    case USER_SELECTED_ALL: {
      return {
        ...state,
        allSelected: action.selected,
        userSelected: action.selected ? state.userList : []
      };
    }

    case RESET_STATE_USER: {
      return {
        ...copy(initialState)
      };
    }

    case RESET_TABLE_USER_WITH_ATTRIBUTTES: {
      return {
        ...copy(initialState),
        filters: action.filters,
        sorting: action.sorting,
        reloadTable: true,
        stopPropagation: true
      };
    }

    case SET_USER_PAGE: {
      return {
        ...state,
        page: action.page
      };
    }

    case ENABLE_PROPAGATION_USER: {
      return {
        ...state,
        stopPropagation: false
      };
    }

    case SHOW_MODAL_DELETE_CONFIRMATION_USER: {
      return {
        ...state,
        showDeleteConfirmation: true
      };
    }

    case HIDE_MODAL_DELETE_CONFIRMATION_USER: {
      return {
        ...state,
        showDeleteConfirmation: false
      };
    }

    case DELETE_USER: {
      return {
        ...state,
        isFetching: true
      };
    }

    case HIDE_MODAL_DELETE_CONFIRMATION_USER_SUCCED: {
      return {
        ...state,
        isFetching: false,
        showDeleteConfirmation: false,
        userSelected: [],
        reloadTable: true,
        stopPropagation: true,
        lastUsersSelected: initialState.lastUsersSelected
      };
    }

    case SET_USER_2FA_STATUS: {
      return {
        ...state,
        reloadTable: false,
        stopPropagation: true
      };
    }

    case UPDATE_SECOND_FACTOR_SUCCESS: {
      const { userList } = state;
      const index = userList.findIndex((user) => user.id === action.response.id);
      userList[index] = { ...action.response };

      return {
        ...state,
        userList
      };
    }

    case MORE_OPTIONS_STATUS_CHANGE_USERS: {
      return {
        ...state,
        moreOptionsOpened: action.value
      };
    }

    case UPDATE_SUCCESS_BULK: {
      const { userList, userSelected } = state;
      const newUserList = cloneDeep(userList);
      const newUserSelected = cloneDeep(userSelected);
      action.data.forEach((user) => {
        const index = newUserList.findIndex((usr) => usr.id === user.id);
        newUserList[index] = { ...user };
      });

      action.data.forEach((user) => {
        const index = newUserSelected.findIndex((usr) => usr.id === user.id);
        newUserSelected[index] = { ...user };
      });

      return {
        ...state,
        userList: newUserList,
        userSelected: newUserSelected
      };
    }

    case USER_CREATE_START: {
      return {
        ...state,
        isFetching: true,
        reloadTable: false
      };
    }

    case USER_CREATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        message: action.error
      };
    }

    case USER_CREATE_SUCCESS: {
      const { userList } = state;
      let newList = [];

      if (action.id) {
        const index = userList.findIndex((user) => user._id === action.id);
        newList = [...userList];
        newList[index] = action.user;
      } else newList = [...userList, action.user];

      return {
        ...state,
        userList: [...newList],
        userSelected: [],
        isFetching: false,
        success: true
      };
    }

    case GET_DATA_USERS_NEXT_PAGE: {
      return {
        ...state,
        isFetching: true,
        page: state.page + 1
      };
    }

    case GET_DATA_USERS_FILTER: {
      return {
        ...state,
        filters: action.filter,
        page: 1
      };
    }

    case SET_USER_FOR_EDIT: {
      return {
        ...state,
        showModalCreate: action.showModalCreate
      };
    }

    case SET_VISIBILITY_CREATE_MODAL_USER: {
      return {
        ...state,
        showModalCreate: action.value
      };
    }

    case SET_SHOW_USERS_CONTEXT_MENU: {
      return {
        ...state,
        isShowingContextMenu: action.value
      };
    }

    case GET_USERS_COUNT_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.error
      };
    }

    default: {
      return state;
    }
  }
}

export default users;
