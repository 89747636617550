import styled from 'styled-components';
import { Accept, Cancel } from 'Common/Components/Button/styled';

export const ButtonsContainer = styled.div`
display: flex;
justify-content: flex-end;
margin-top: 34px;
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const ConfirmButton = styled(Accept)`
`;
ConfirmButton.displayName = 'ConfirmButton';

export const CancelButton = styled(Cancel)`
  margin-right: 10px;
`;
CancelButton.displayName = 'CancelButton';
