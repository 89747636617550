import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1 1 auto;
  margin: 20px 0px;
  flex-wrap: wrap;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
