import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import InputTag from './components/InputTag';
import Items from './components/Items';
import { Wrapper, SubTitle } from './styled';
import { selectSelectAll } from 'store/Manage/selectors';
import { selectIsFiltering } from 'store/Filters/selectors';

const Content = ({ tags, onClickTag, addTags, allTags }) => {
  const selectAll = useSelector(selectSelectAll);
  const isFiltering = useSelector(state => selectIsFiltering('vulns', state));
  const intl = useIntl();
  const showItems = () => {
    if (selectAll || (selectAll && isFiltering)) {
      return !isEmpty(tags);
    } else {
      return true;
    }
  };

  return (
    <Wrapper>
      { showItems() &&
        <>
          <SubTitle children={ intl.formatMessage({ id: 'manage.tags.create_and_organize' }) } />
          <Items tags={ tags } onClickTag={ onClickTag } />
        </>
      }
      <InputTag addTags={ addTags } allTags={ allTags } hasItems={ showItems() } />
    </Wrapper>
  );
};

export default Content;
