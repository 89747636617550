import { Accept, Cancel } from 'Common/Components/Button/styled';
import PropTypes from 'prop-types';
import StandardDropdown from 'Common/Components/StandarDropdown';
import StyledCheckbox from 'Common/Components/StyledCheckbox';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ToolParams from 'Screens/Automation/Agents/components/Grid/components/UserAgents/components/RunUserAgentModal/components/ToolParams';
import { selectAllAgents, selectCloudAgentsForScheduler, selectUserAgents } from 'store/Agents/selectors';
import { saveSchedule, resetTagsFields } from 'store/Schedule/actions';
import { selectLastRowSelected } from 'store/Schedule/selectors';
import CronExpression from './components/CronExpression';
import WorkspacesSelector from './components/WorkspacesSelector';
import {
  Wrapper, WrapperTitle, Title, ButtonsWrapper, DescriptionSection,
  Label, Description, Subtitle, WrapperToolParams, WrapperAgent, AgentSection, ToolSubtitle
} from './styled';
import Tags from './components/Tags';
import AgentsDropdown from './components/AgentsDropdown';
import AgentParams from './components/AgentsParams';

const CreateEditSchedule = ({ isCreateSchedule, onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const schedule = useSelector((state) => isCreateSchedule ? {} : selectLastRowSelected(state));
  const agents = useSelector(selectAllAgents);
  const userAgents = useSelector(selectUserAgents);
  const cloudAgents = useSelector(selectCloudAgentsForScheduler);
  const [description, setDescription] = useState();
  const [active, setActive] = useState(true);
  const [currentAgent, setCurrentAgent] = useState();
  const [currentTool, setCurrentTool] = useState({});
  const [currentToolParams, setCurrentToolParams] = useState({});
  const [crontab, setCrontab] = useState(isCreateSchedule
    ? { name: 'day', expression: '0 4 * * *' }
    : {
        expression: get(schedule, 'value.crontab', '')
      });
  const tools = get(currentAgent, 'value.executors', []);
  const mappedTools = tools.map((t) => ({ name: t.name, desc: t.name }));
  const [scheduleWorkspaces, setScheduleWorkspaces] = useState([]);
  const [tags, setTags] = useState('');
  const [targetError, setTargetError] = useState('');
  const isNormalAgent = get(currentAgent, 'type', '') === 'agent';
  const isNormalScheduler = get(schedule, 'value.type', '') === 'agent';
  const parsedAgents = () => {
    if (isCreateSchedule && !isEmpty(agents)) return agents.map((t) => ({ label: get(t, 'name', ''), value: t }));
    else if (!isCreateSchedule && isNormalScheduler && !isEmpty(userAgents)) return userAgents.map((t) => ({ label: get(t, 'name', ''), value: t }));
    else if (!isCreateSchedule && !isNormalScheduler && !isEmpty(cloudAgents)) return cloudAgents.map((t) => ({ label: get(t, 'name', ''), value: t }));
    else return [];
  };
  useEffect(() => {
    if (!isCreateSchedule) {
      const defaultCrontab = () => {
        let crontabName = '';
        const crontabValue = get(schedule, 'value.crontab', '');
        switch (crontabValue) {
          case '0 4 * * *':
            crontabName = 'day';
            break;
          case '0 4 * * 0':
            crontabName = 'week';
            break;
          case '0 4 1 * *':
            crontabName = 'month';
            break;
          default:
            crontabName = 'custom';
            break;
        }
        return { name: crontabName, expression: crontabValue };
      };
      const vulnTags = get(schedule, 'value.vuln_tag', []);
      const assetTags = get(schedule, 'value.host_tag', []);
      const serviceTags = get(schedule, 'value.service_tag', []);
      const scheduleTags = [...vulnTags, ...assetTags, ...serviceTags];
      const parsedScheduleTags = [...new Set(scheduleTags)].join(',');
      setDescription(get(schedule, 'value.description', ''));
      if (isNormalScheduler || (isCreateSchedule && isNormalAgent)) {
        setCurrentAgent({ label: get(schedule, 'value.agent.name', ''), value: { ...get(schedule, 'value.agent', {}) }, type: 'agent' });
        const toolName = get(schedule, 'value.executor.name', '');
        const tool = get(schedule, 'value.agent.executors').find((t) => t.name === toolName);
        setCurrentTool(tool);
        setCurrentToolParams(get(schedule, 'value.parameters', {}));
      } else {
        setCurrentAgent({ label: get(schedule, 'value.cloud_agent.name', ''), value: { ...get(schedule, 'value.cloud_agent', {}), type: 'cloud_agent' } });
        setCurrentTool({});
        setCurrentToolParams(get(schedule, 'value.parameters', {}));
      }
      setActive(get(schedule, 'value.active', false));
      setCrontab(defaultCrontab());
      setScheduleWorkspaces(get(schedule, 'value.workspaces', []));
      setTags(parsedScheduleTags);
    }
  }, [isCreateSchedule, schedule]);

  const onChangeAgent = (obj) => {
    setCurrentAgent(obj);
    setCurrentTool({});
    setCurrentToolParams({});
  };

  const onChangeTool = (field, value) => {
    const tool = get(currentAgent, 'value.executors', get(schedule, 'value.agent.executors')).find((t) => t.name === value);
    setCurrentTool(tool);
  };

  const onChangeField = (field, value) => {
    const newParams = { ...currentToolParams };
    newParams[field] = value;
    if (!trim(value)) delete newParams[field];
    setCurrentToolParams(newParams);
  };

  const validateUrlTarget = (domain) => {
    // eslint-disable-next-line prefer-regex-literals
    const re = new RegExp(/^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,63}$/);
    if (domain && (!re.test(domain))) setTargetError('Target should be a domain. e.g.: domain.com');
    else setTargetError('');
  };

  const onChangeFieldCloud = (field, value, type) => {
    if (type === 'url') validateUrlTarget(value);
    const newParams = { ...currentToolParams, [field]: value };
    if (type === 'list') {
      const newValue = value.replace(/\s+/g, '');
      newParams[field] = newValue.split(',');
    }
    if (value === '') delete newParams[field];
    setCurrentToolParams(newParams);
  };

  const disableAccept = () => {
    const currentToolIsInvalid = () => isEmpty(currentTool);
    const currentToolParamsIsInvalid = () => {
      if (!isEmpty(currentTool.parameters_metadata) && isEmpty(currentToolParams)) return true;
      if (isEmpty(currentTool)) return true;
      if (isEmpty(currentTool.parameters_metadata)) return false;

      const params = currentTool.parameters_metadata;
      return Object.keys(params).some((p) => params[p].mandatory && isEmpty(currentToolParams[p]));
    };

    const cloudParamsIsInvalid = () => {
      if (currentAgent.value.type === 'cloud_agent') {
        const params = currentAgent?.value.params;
        return !isEmpty(params) && Object.keys(params).some((p) => params[p].mandatory && isEmpty(currentToolParams[p]));
      }
    };

    if (!currentAgent?.value.type && (isEmpty(currentAgent) || !description || currentToolIsInvalid() || currentToolParamsIsInvalid() || !crontab || isEmpty(scheduleWorkspaces))) return true;
    if (currentAgent?.value.type === 'cloud_agent' && (isEmpty(currentAgent) || !description || cloudParamsIsInvalid() || !crontab || isEmpty(scheduleWorkspaces))) return true;

    return false;
  };

  const onAccept = () => {
    if (!disableAccept()) {
      const workspacesNames = scheduleWorkspaces.map((ws) => {
        if (ws.name) {
          return ws.name;
        } else {
          return ws;
        }
      });
      dispatch(saveSchedule(get(schedule, 'id', 0), currentAgent.value.id, crontab.name, active, crontab.expression, description, currentTool.id, currentToolParams, workspacesNames, Intl.DateTimeFormat().resolvedOptions().timeZone, tags, currentAgent?.value.type));
      onClose();
    }
  };

  const getTitle = () => (isCreateSchedule ? intl.formatMessage({ id: 'schedule.createEditSchedule.create.title' }) : intl.formatMessage({ id: 'schedule.createEditSchedule.edit.title' }));

  const onChangeActive = () => {
    setActive(!active);
  };

  useEffect(() => () => {
    dispatch(resetTagsFields());
  }, [dispatch]);

  return (
    <Wrapper>
      <WrapperTitle>
        <ButtonsWrapper>
          <Cancel id="scheduler-modal-cancel" onClick={ onClose }>{ intl.formatMessage({ id: 'schedule.createEditSchedule.cancel' }) }</Cancel>
          <Accept id="scheduler-modal-accept" onClick={ onAccept } disabled={ disableAccept() }>{ intl.formatMessage({ id: 'schedule.createEditSchedule.accept' }) }</Accept>
        </ButtonsWrapper>
        <Title>{ getTitle() }</Title>
      </WrapperTitle>

      <DescriptionSection>
        <Label>{ intl.formatMessage({ id: 'schedule.createEditSchedule.description' }) }</Label>
        <Description id="scheduler-modal-description" onChange={ (e) => setDescription(e.target.value) } value={ description } placeholder={ intl.formatMessage({ id: 'schedule.createEditSchedule.description.placeholder' }) } />
        <StyledCheckbox id="scheduler-modal-active" checked={ active } label="Active" onChange={ onChangeActive } />
      </DescriptionSection>

      <CronExpression crontab={ crontab } onChangeCrontab={ (value) => setCrontab(value) } isCreateSchedule={ isCreateSchedule } />

      <AgentSection>
        <Subtitle>{ intl.formatMessage({ id: 'schedule.createEditSchedule.agentSelection' }) }</Subtitle>
        <WrapperAgent>
          <AgentsDropdown id="scheduler-modal-agent" options={ parsedAgents() } updateValue={ onChangeAgent } value={ currentAgent } placeholder={ intl.formatMessage({ id: 'schedule.createEditSchedule.dropdownAgent.placeholder' }) } />
        </WrapperAgent>

        { (!isEmpty(currentAgent) || !isEmpty(get(schedule, 'value.agent', null))) &&
          (
          <WrapperToolParams isCloud={ currentAgent?.value?.type === 'cloud_agent' }>
           { currentAgent?.value?.type === 'cloud_agent'
             ? <>
                <AgentParams currentAgent={ currentAgent } onChangeField={ onChangeFieldCloud } targetError={ targetError } defaultParams={ currentToolParams } />
               </>
             : <>
                  <StandardDropdown id="scheduler-modal-tool" defaultValue={ get(currentTool, 'name', '') } width="200px" field="toolParams" options={ mappedTools } updateValue={ onChangeTool } placeholder={ intl.formatMessage({ id: 'schedule.createEditSchedule.dropdownTool.placeholder' }) } />
                  <ToolSubtitle>{ intl.formatMessage({ id: 'schedule.createEditSchedule.dropdownTool.label' }) }</ToolSubtitle>
                  <ToolParams currentTool={ currentTool } defaultParams={ currentToolParams } onChangeField={ onChangeField } />
               </>
            }
          </WrapperToolParams>
          ) }
      </AgentSection>
      <WorkspacesSelector scheduleWorkspaces={ scheduleWorkspaces } setScheduleWorkspaces={ setScheduleWorkspaces } />
      <Tags tags={ tags } setTags={ setTags } isCreateSchedule={ isCreateSchedule } />
    </Wrapper>
  );
};

CreateEditSchedule.propTypes = {
  isCreateSchedule: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CreateEditSchedule;
