import get from 'lodash/get';

export const selectError = (state) => get(state, 'servicesGeneral.error', false);
export const selectErrorMessage = (state) => get(state, 'servicesGeneral.errorMessage', '');
export const selectServices = (state) => get(state, 'servicesGeneral.list', []);
export const selectIsFetching = (state) => get(state, 'servicesGeneral.isFetching', false);

export const selectShowDetail = (state) => get(state, 'servicesGeneral.showServiceDetail', false);
export const selectServiceDetail = (state) => get(state, 'servicesGeneral.serviceDetail', []);
export const selectServiceDetailId = (state) => get(state, 'servicesGeneral.serviceDetail._id', 0);
export const selectServiceDetailName = (state) => get(state, 'servicesGeneral.serviceDetail.name', '');
export const selectServiceDetailOwned = (state) => get(state, 'servicesGeneral.serviceDetail.owned', false);
export const selectServiceDetailPorts = (state) => get(state, 'servicesGeneral.serviceDetail.ports', '');
export const selectServiceDetailProtocol = (state) => get(state, 'servicesGeneral.serviceDetail.protocol', '');
export const selectServiceDetailVersion = (state) => get(state, 'servicesGeneral.serviceDetail.version', '');
export const selectServiceDetailDescription = (state) => get(state, 'servicesGeneral.serviceDetail.description', '');
export const selectServiceDetailStatus = (state) => get(state, 'servicesGeneral.serviceDetail.status', '');
export const selectServicesCount = (state) => get(state, 'servicesGeneral.count', 0);
export const selectServicesSelected = (state) => get(state, 'servicesGeneral.servicesSelected', []);
export const selectLastSelected = (state) => get(state, 'servicesGeneral.lastSelected', -1);
export const allServicesAreSelected = (state) => {
  const services = selectServices(state);
  const serviceList = selectServicesSelected(state);
  return services.every((testService) => serviceList.some((service) => service._id === testService._id));
};
export const someServiceIsSelected = (state) => {
  return selectServicesSelected(state).length > 0;
};
export const selectCurrentGeneralService = (state) => get(state, 'servicesGeneral.serviceSelectedForWorking[0]', {});
export const selectShowDeleteConfirmation = (state) => get(state, 'servicesGeneral.showDeleteConfirmation', false);
export const selectPermissions = (state) => get(state, 'faraday.permissions.services', []);

export const selectReadonly = (state) => {
  const readonly = get(state, 'faraday.readonly', false);
  return readonly;
};

export const selectAssetsList = (state) => get(state, 'servicesGeneral.hosts.list', []);
export const selectServiceWorkspace = (state) => get(state, 'servicesGeneral.serviceDetail.workspace_name', '');

export const selectServicesTags = (state) => {
  const servicesSelected = selectServicesSelected(state);
  const servicesTags = servicesSelected.flatMap(service => service.tags);
  return servicesTags;
};

export const selectServiceDetailTags = (state) => get(state, 'servicesGeneral.serviceDetail.tags', []);

export const selectSelectAllServices = (state) => get(state, 'servicesGeneral.selectAll', false);

export const selectUnselectedRowFromSelectAll = (state) => get(state, 'servicesGeneral.unselectedRowFromSelectAll', false);
