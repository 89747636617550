import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper, CustomReactTable } from './styled';
import { Status } from '../Grid/components/UserAgents/components/UserAgent/styled';
import { useIntl } from 'react-intl';
import TimeAgo from 'react-timeago';
import Switch from 'react-switch';
import colors from 'Styles/colors';
import { SwitchContainer } from 'Screens/Workspaces/components/Table/styled';
import RunIcon from '../Grid/components/UserAgents/components/UserAgent/components/RunIcon';
import { CheckColum, CheckHeader } from './components/columns';
import RunUserAgentModal from '../Grid/components/UserAgents/components/RunUserAgentModal';
import { MODAL_RUN_USER_AGENT } from 'store/modals/modals';
import selectModal from 'store/modals/selectors';
import EmptyAgents from '../Grid/components/EmptyAgents';
import { isEmpty } from 'lodash';
import { SelectShowCloudAgents, selectIsFetching } from 'store/Agents/selectors';
import { selectIsAdmin } from 'store/Faraday/selectors';
import { changeStateAgent } from 'store/Agents/actions';

const Table = ({ agents, isCloudAllowed }) => {
  const dispatch = useDispatch();
  const showCloudAgents = useSelector(SelectShowCloudAgents);
  const isAdmin = useSelector(selectIsAdmin);

  const columns = [
    {
      Header: () => <CheckHeader />,
      id: 'selected',
      accessor: 'selected',
      show: true,
      sortable: false,
      resizable: false,
      maxWidth: 55,
      minWidth: 55,
      headerStyle: { overflow: 'visible' },
      style: { display: 'flex' },
      Cell: ({ original }) => <CheckColum agent={ original } />
    },
    {
      Header: 'Name',
      accessor: 'name',
      id: 'name',
      show: true,
      sortable: true,
      Cell: ({ value }) => <div style={ { color: '#53686f', fontSize: '14.5px' } }>{value}</div>
    },
    {
      Header: 'Trigger Now',
      accessor: 'trigger',
      id: 'trigger',
      show: true,
      sortable: true,
      Cell: ({ original }) => <RunIcon agent={ original } />
    },
    {
      Header: 'Status',
      accessor: 'status',
      id: 'status',
      show: true,
      sortable: true,
      maxWidth: 150,
      minWidth: 150,
      Cell: ({ value }) => {
        const intl = useIntl();
        const statusLabel = value ? intl.formatMessage({ id: `agents.item.status.${value}` }) : 'Online';
        return <Status value={ value || 'online' } children={ statusLabel } noMargin />;
      }
    },
    {
      Header: 'Last Executed',
      accessor: 'last_run',
      id: 'last_run',
      show: true,
      sortable: true,
      Cell: ({ value }) => <TimeAgo date={ value } />
    },
    {
      Header: 'Active',
      accessor: 'active',
      id: 'active',
      show: !showCloudAgents,
      sortable: true,
      Cell: ({ original, value }) => (
    <SwitchContainer checked={ value }>
    <Switch
      checked={ value }
      onChange={ () => { dispatch(changeStateAgent(original)); } }
      onColor={ colors.green4 }
      offColor={ colors.grey14 }
      onHandleColor={ colors.green3 }
      offHandleColor={ colors.grey15 }
      boxShadow="0 0 4px 0 rgba(83, 83, 83, 0.5)"
      activeBoxShadow=""
      height={ 14 }
      width={ 39 }
      handleDiameter={ 21 }
      uncheckedIcon={ false }
      checkedIcon={ false }
      disabled={ !isAdmin }
    />
    </SwitchContainer>
      )
    }
  ];
  const showRunUserAgent = useSelector((state) => selectModal(state, MODAL_RUN_USER_AGENT));

  const isFetching = useSelector(selectIsFetching);
  const noAgents = isEmpty(agents) && !isFetching;

  if (noAgents || !isCloudAllowed) {
    return (
      <Wrapper>
        <EmptyAgents />
      </Wrapper>
    );
  }

  return (
      <Wrapper>
      {showRunUserAgent && <RunUserAgentModal /> }
    <CustomReactTable
      data={ agents }
      columns={ columns }
      minRows={ 0 }
      manual
      showPagination={ false }
    />
      </Wrapper>
  );
};

export default Table;
