import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';
import { ReactComponent as QuestionIcon } from 'Images/help-sysreq.svg';
import { ReactComponent as ArrowCollapsed } from 'Images/arrow-right.svg';
import { ReactComponent as ArrowDown } from 'Images/arrow-down.svg';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
Wrapper.displayName = 'Wrapper';

export const Tabs = styled.div`
  background-color: ${colors.iceBlue};
  flex: 0 0 184px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
Tabs.displayName = 'Tabs';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 500;
  line-height: 1.16;
  text-align: left;
  color: ${colors.softBlue};
  padding: 45px 34px 32px 18px;
`;
Title.displayName = 'Title';

export const Item = styled.div`
  display:flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;
  color: ${colors.dark2};
  line-height: 1.04;
  text-align: left;
  padding: ${({ isSubTab }) => isSubTab ? '10px 20px 10px 41px' : '10px 20px'};
  background: ${(props) => (props.active ? colors.lightGrey : 'transparent')};
  position: relative;
`;
Item.displayName = 'Item';

export const TabContent = styled.div`
  flex: 1 1 0;
  padding: 35px;
  overflow: hidden;
`;
TabContent.displayName = 'TabContent';

export const Container = styled.div`
  width: 900px;
  height: 650px;
  background-color: ${colors.white};
  & > :first-child {
    box-shadow: 18px 43px 50px 0 rgba(167, 167, 167, 0.5);
    border-top: 10px solid ${colors.softBlue};
    border-radius: 2px 2px 0 0;
    overflow: auto;
  }
  ${fadeIn(0, 1)}
`;
Container.displayName = 'Container';

export const MenuList = styled.div`
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin: auto 0 15px 18px;
`;

export const FooterLink = styled.a`
  font-size: 13.5px;
  font-weight: 500;
  color: ${colors.blueCerulean};
  cursor: pointer;
  &:hover {
    color: ${colors.blueCerulean}
  }
`;

export const QuestionMark = styled(QuestionIcon)`
  margin-right: 8px;
`;

export const SectionWrapper = styled.div`

`;
SectionWrapper.displayName = 'SectionWrapper';

export const ContentWrapper = styled.div`
  height: 100%;
`;
ContentWrapper.displayName = 'ContentWrapper';

export const ContentTitle = styled.h2`
  font-size: 21.5px;
  font-weight: 600;
  color: ${colors.dark2};
  margin-bottom: 14px;
`;
ContentTitle.displayName = 'ContentTitle';

export const Templates = styled.div`
  height: calc(100% - 90px);
  overflow: auto;
`;
Templates.displayName = 'Templates';

export const SubTitle = styled.h3`
  font-size: 17px;
  font-weight: 600;
  color: ${colors.dark2};
  margin-top:10px;
`;
SubTitle.displayName = 'SubTitle';

export const Section = styled.div`
  height: 100%;
`;
Section.displayName = 'Section';

const IconWrapper = css`
  position: absolute;
  top: 14px;
  right: 55px;
`;

export const ExpandedIcon = styled(ArrowDown)`
  ${IconWrapper};
  margin: 2px 5px auto auto;
  width: 10px;
  color: #63758d;
  path {
    fill: ${colors.blueGrey};
  }
`;
ExpandedIcon.displayName = 'ExpandedIcon';

export const CollapsedIcon = styled(ArrowCollapsed)`
  ${IconWrapper};
  top: 13px;
  margin: auto 5px auto auto;
  height: 10px;
  path {
    fill: ${colors.blueGrey};
  }
`;
CollapsedIcon.displayName = 'CollapsedIcon';
