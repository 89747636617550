import get from 'lodash/get';
import { DEFAULT_ACTIVE_CONFIG } from './types';

export const selectShowModal = (state) => get(state, 'settings.showModal', false);

export const selectTemplates = (state) => {
  const templates = get(state, 'settings.ticketingTools.templates', []);
  return templates.map((x) => ({
    desc: x.replace(/_/g, ' '),
    name: x
  }));
};

export const selectActiveConfig = (state) => {
  return get(state, 'settings.ticketingTools.activeConfig', DEFAULT_ACTIVE_CONFIG);
};

export const selectUserEmail = (state) => get(state, 'settings.userData.email', '');
