import React from 'react';
import Header from '../Header';
import Items from '../Items';
import { Section, ContentTitle, ContentWrapper, Templates } from '../../styled';

const Vulnerabilities = ({ title, onClose, onSelection }) => (
  <Section>
    <Header onClose={ onClose } />
    <ContentWrapper>
      <ContentTitle>{ title }</ContentTitle>
      <Templates>
        <Items onSelection={ onSelection } vulns />
      </Templates>
    </ContentWrapper>
  </Section>
);

export default Vulnerabilities;
