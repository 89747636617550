import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSolarWindsSettings, TOOL_NAME } from 'store/Settings/solarWindsActions';
import { getTemplates } from 'store/Settings/settingsActions';
import IssuesConfiguration from './components/IssuesConfiguration';
import Settings from './components/Settings';
import { Wrapper, Content } from './styled';
import { selectUrl, selectApiKey, selectTemplate } from 'store/Settings/solarWindsSelectors';

const SolarWinds = ({ setDisabled }) => {
  const dispatch = useDispatch();

  // obtengo los datos del servidor
  useEffect(() => {
    dispatch(getTemplates(TOOL_NAME));
    dispatch(getSolarWindsSettings());
  }, [dispatch]);

  const url = useSelector(selectUrl);
  const apiKey = useSelector(selectApiKey);
  const template = useSelector(selectTemplate);

  useEffect(() => {
    let isDis = false;
    if (url && apiKey && template) {
      isDis = false;
    } else {
      isDis = true;
    }
    setDisabled(isDis);
  }, [url, apiKey, template, setDisabled]);

  return (
    <Wrapper>
      <Content>
        <Settings />
        <IssuesConfiguration />
      </Content>
    </Wrapper>
  );
};
export default SolarWinds;
