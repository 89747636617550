import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { FEATURE_TAGS, FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import Wrapper from './styled';
import IconButton from 'Common/Components/IconButton';
import { Tag } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';
import { setCreationTagModal } from 'Screens/Contextualization/Tags/actions/Actions';
import ModalWarning from 'Common/Components/ModalWarning';

const TagButton = ({ hasItems, hostsSelected, entity }) => {
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const readonly = useSelector((state) => get(state, 'faraday.readonly', false));
  const canCreateTags = useSelector((state) => selectAllowedByRole(state, FEATURE_TAGS, PERMISSION_TYPES.CREATE));
  const canPatchHosts = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.PATCH));
  const canUpdate = canCreateTags && canPatchHosts && !readonly && hasItems;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'assets' });

  const handleClick = () => {
    if (canUpdate) {
      if ((hostsSelected.length > 0)) dispatch(setCreationTagModal(true, entity));
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <Wrapper>
      <IconButton icon={ <Tag /> } title="Tags" onClick={ handleClick } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Tags" description={ message } /> }
    </Wrapper>
  );
};

export default TagButton;
