import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { Edit } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';
import { selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import { redirectToManageDetail, selectVulnRow, unSelectAll } from 'store/Contextualization/AssetDetail/actions';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const EditButton = ({ hasVulns }) => {
  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useDispatch();
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const vulnData = get(vulnsSelected, '[0]', null);
  const vulnId = get(vulnsSelected, '[0]._id', 0);
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'vulnerability' });
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));

  const openVulnDetail = (e) => {
    dispatch(unSelectAll('vulns'));
    dispatch(redirectToManageDetail(vulnId));
    dispatch(selectVulnRow(e, vulnData));
  };

  const handleClick = (e) => {
    if (hasVulns && canUpdate) {
      if (vulnsSelected.length === 1) openVulnDetail(e);
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } disabled={ !hasVulns || !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Edit" description={ message } /> }
    </>
  );
};

export default EditButton;
