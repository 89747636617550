import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height:  100%;
  margin: auto
`;
Wrapper.displayName = 'Wrapper';

export const ContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  margin: auto;
  padding-top: 10px;
  flex-direction: row;
  flex: 1 1 auto;
  overflow: scroll;
`;
ContentWrapper.displayName = 'ContentWrapper';

export const PreviewWrapper = styled.div`
`;
PreviewWrapper.displayName = 'PreviewWrapper';
