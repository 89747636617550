import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  margin-bottom: 3px;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 12.5px;
  font-weight: 400;
  margin-bottom: 1px;
  color: ${colors.grey17};
`;
Title.displayName = 'Title';

export const Asterisk = styled.span`
  font-size: 12.5px;
  line-height: 2.08;
  color: ${colors.redPink};
  margin-bottom: 1px;
`;
Asterisk.displayName = 'Asterisk';

export const Input = styled.input`
  position: relative;
  padding: 5px 5px 5px 15px;
  font-size: 14.5px;
  border: none;
  box-shadow: inset 0 -1px 0 0 ${colors.grey30};
  background-color: ${colors.iceBlue};
  color: ${colors.darkBlueGrey};
  width: 100%;
  height: 34px;

  &::-webkit-inner-spin-button {
    z-index: 1;
  }

 &::-webkit-clear-button {
    z-index: 1;
 }
`;
Input.displayName = 'Input';
