/* eslint-disable react/no-children-prop */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectComments } from 'store/ManageGeneral/selectors';
import { openModal } from 'store/modals/actions';
import { setSelectedComment } from 'store/ManageGeneral/actions';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import CommentItem from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/CommentTab/Comments/components/CommentItem';
import { EmptyComments, CommentsWrapper } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/CommentTab/Comments/styled';

const Comments = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const emptyMessage = intl.formatMessage({ id: 'manage.detail.tab.comment.noComments' });
  const comments = useSelector(selectComments);
  const hasComments = !isEmpty(comments);

  const onClickRemove = (comment) => {
    dispatch(setSelectedComment(comment));
    dispatch(openModal('commentDeletion'));
  };

  if (!hasComments) return <EmptyComments children={ emptyMessage } />;
  return (
    <CommentsWrapper>
      {
        [...comments]
          .sort((a, b) => b.id - a.id) // we order depending on age
          .map((comment, i) => (
            <CommentItem
              onRemove={ onClickRemove }
              comment={ comment }
              index={ i }
              key={ `comment_${comment.id}` }
            />
          ))
      }
    </CommentsWrapper>
  );
};

export default Comments;
