import React from 'react';
import { Input, Title } from '../styled';

const DatePicker = ({ onChange, title, defaultValue = '' }) => (
  <>
    <Title>{title}</Title>
    <Input type="date" onChange={ (e) => onChange(e.target.value) } defaultValue={ defaultValue } />
  </>
);

export default DatePicker;
