import RadioButton from 'Common/Components/RadioButton/index';
import styled from 'styled-components';
import { ReactComponent as MainDuplicateSVG } from 'Images/main-duplicate.svg';

export const MainDuplicateIcon = styled(MainDuplicateSVG)`
  flex: 0 0 19px;
  margin: auto 0 auto 0;
`;
MainDuplicateIcon.displayName = 'DuplicateIcon';

export const StyledRadio = styled(RadioButton)`
    color: ${({ checked }) => (checked ? '#000' : '#1c2243')};
    opacity: ${({ checked }) => (checked ? '1' : '0.34')};
`;
StyledRadio.displayName = 'StyledRadio';

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
LabelContainer.displayName = 'LabelContainer';

export const Label = styled.div`
  max-width: 350px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
`;
Label.displayName = 'Label';
