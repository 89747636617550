import React from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import {
  Wrapper, Summary, Prev, Next, WrapperButton, Total
} from './styled';
import formatNumber from 'Common/Functions/FormatNumber';

const Button = ({
  icon, tooltip, onClick, disabled, small
}) => <WrapperButton type="button" title={ tooltip } onClick={ onClick } disabled={ disabled } small={ small }>{ icon }</WrapperButton>;

function Pagination ({
  offset, limit, total, onPrev, onNext, small
}) {
  const intl = useIntl();
  const getOffset = () => offset || 1;// offset viene null cuando se agrupa, el back no soporta paginacion.
  const isFirstPage = () => (getOffset() === 1);
  const isLastPage = () => (limit * getOffset()) >= total;
  const from = () => (total === 0 ? 0 : ((getOffset() - 1) * limit) + 1);
  const to = () => (isLastPage() ? total : limit * getOffset());

  // si el offset es null, no se muestra el paginador, esto pasa porque no se soporta paginador cuando se agrupa.
  if (!offset) return null;
  return (
    <Wrapper small={ small }>
      <Summary small={ small }>{ `${from()}-${to()} ${intl.formatMessage({ id: 'common.pagination.of' })}` }
        <Total title={ total }>&nbsp;{ `${formatNumber(total)}` }</Total>
      </Summary>
      <Button disabled={ isFirstPage() } icon={ <Prev small={ small } /> } tooltip={ intl.formatMessage({ id: 'common.pagination.prev' }) } onClick={ onPrev } small={ small } />
      <Button disabled={ isLastPage() } icon={ <Next small={ small } /> } tooltip={ intl.formatMessage({ id: 'common.pagination.next' }) } onClick={ onNext } small={ small } />
    </Wrapper>
  );
}

Pagination.propTypes = {
  offset: PropTypes.number,
  limit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  small: PropTypes.bool
};

Pagination.defaultProps = {
  offset: 1,
  small: false
};

export default Pagination;
