import fetchApi from 'services/api/connector';

export const downloadReport = (payload, id) => fetchApi(`reports/${id}/download`, payload);

export const getReportsList = (queryParam) => fetchApi(`reports?${queryParam}`);

export const getPreviewFiltered = (workspace, queryParam, cancelTokenSource) => fetchApi(`ws/${workspace}/vulns/filter?q=${escape(JSON.stringify(queryParam))}`, undefined, undefined, cancelTokenSource);

export const getVulnsPreview = (workspace, queryParam) => fetchApi(`ws/${workspace}/vulns/filter?q=${escape(JSON.stringify(queryParam))}}`);

export const fetchTemplates = () => fetchApi('reports/listTemplates');

export const createReport = (reportPayload) => fetchApi('reports', {
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  data: reportPayload
});

export const getReportDetail = (id) => fetchApi(`reports/${id}`);

export const updateReport = (payload) => fetchApi('reports', {
  method: 'PATCH',
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  data: payload
});

export const removeReport = (reportId) => fetchApi(`reports/${reportId}`, { method: 'DELETE' });

export const getReportConfiguration = () => fetchApi('settings/executive_reports', { method: 'GET' });

export const saveReportConfiguration = (useMarkdown, borderWidth) => fetchApi('settings/executive_reports', { method: 'PATCH', data: { markdown: useMarkdown, border_size: borderWidth } });

export const getCustomLogo = (id) => fetchApi(`reports/${id}/custom_logo`, { method: 'GET' });

export const regenerateReport = (id) => fetchApi(`reports/${id}/regenerate`);

export const clone = (id) => fetchApi(`reports/${id}/clone`);

export const testTemplate = (payload) => fetchApi('reports/test_template', {
  method: 'POST',
  responseType: 'blob',
  headers: {
    'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'Content-Type': 'multipart/form-data'
  },
  data: payload
});

export const getTemplateInfo = (payload) => fetchApi('reports/info_template', {
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  data: payload
});

export const uploadTemplate = (payload) => fetchApi('reports/upload_template', {
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  data: payload
});

export const deleteTemplate = (data) => fetchApi('reports/delete_template', { method: 'DELETE', data });

export const downloadTemplate = (isGrouped, filename) => fetchApi(`reports/download_template?is_grouped=${isGrouped}&filename=${filename}`, {
  responseType: 'blob',
  headers: {
    'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'Content-Type': 'multipart/form-data'
  }
});
