
import fetchApi from 'services/api/connector';

export const fetchHosts = (queryParam) => fetchApi(`hosts/filter?q=${escape(JSON.stringify(queryParam))}`);

export const getHosts = () => fetchApi('hosts');

export const fetchById = (id) => fetchApi(`hosts/${id}`);

export const deleteHost = (ids) => fetchApi('hosts', { method: 'DELETE', data: { ids } });

export const fetchHostsWithoutStats = (queryParam) => fetchApi(`hosts/filter?q=${escape(JSON.stringify(queryParam))}&stats=false`);

export const deleteAllHosts = (queryParam) => fetchApi(`hosts?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'DELETE', version: 'v3' });

export const updateHosts = (data) => fetchApi('hosts', { method: 'PATCH', data, version: 'v3' });

export const updateAllHosts = (queryParam, data) => fetchApi(`hosts?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'PATCH', data, version: 'v3' });

export const fetchServicesOutsideWs = (hostId) => fetchApi(`hosts/${hostId}/services`);

export const setHostsTags = (data) => fetchApi('hosts/set_tags', { method: 'POST', data, version: 'v3' });

export const setAllHostsTags = (queryParam, data) => fetchApi(`hosts/set_tags?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'POST', data, version: 'v3' });
