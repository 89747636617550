import { TEMPLATE_CREATE_UPDATE_SUCCESS } from 'Screens/KnowledgeBaseEditCreate/actions/Actions';
import {
  GET_DATA_VULN_TEMPLATE_START,
  GET_DATA_VULN_TEMPLATE_FAIL,
  GET_DATA_VULN_TEMPLATE_SUCCESS,
  VULN_TEMPLATE_SELECTED,
  SHOW_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE,
  HIDE_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE,
  DELETE_VULN_TEMPLATE,
  HIDE_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE_SUCCEED,
  SET_VISIBILITY_CREATE_MODAL_KB,
  GET_DATA_TEMPLATES_SORT,
  GET_DATA_TEMPLATES_FILTER,
  SET_UPLOAD_STATE_KB,
  UPLOAD_CSV_KB_UPDATE_PERCENT,
  UPLOAD_CSV_KB_ERROR,
  CANCEL_REQUEST_UPLOAD_CSV_KB,
  SET_CSV_KB_FILE_INDEX_MANAGE,
  SET_NAME_INTO_FILE_ERROR_LIST_CSV_KB,
  RESET_FILE_STATE_KB,
  SET_FILES_TO_UPLOAD_KB,
  GET_VULNS_COUNT_SUCCESS,
  GET_VULNS_COUNT_FAIL,
  SET_KNOWLEDGE_BASE_PAGE
} from '../../Screens/KnowledgeBase/actions/Actions';

import {
  CONTEXT_VULN_TEMPLATE_SELECTED,
  VULN_TEMPLATE_UNSELECTED,
  VULN_TEMPLATE_NEW_PIVOT,
  CONTEXT_SELECT_ALL_VULN_TEMPLATE,
  UNSELECT_ALL_VULN_TEMPLATE,
  VULN_TEMPLATE_SHOW_DETAIL,
  VULN_TEMPLATE_HIDE_DETAIL,
  VULN_TEMPLATE_UPDATE_SUCCESS,
  VULN_TEMPLATE_SET_DETAIL_TAB,
  CLEAR_ERROR
} from 'Screens/Contextualization/KnowledgeBase/actions/Actions';

import { impact } from '../../Screens/Constants/index';

import uniq from 'lodash/uniq';
import { copyArray } from 'Common/Functions/Copy';

const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  vulnTemplateList: [],
  vulnTemplateCount: 0,
  rowsPerPage: 50,
  page: 1,
  showModalNew: false,
  mode: 'new',
  templatesSelected: [],
  allSelected: false,
  customfieldsData: [],
  showDeleteConfirmation: false,
  showModalImport: false,
  csv: null,
  template: {
    id: null,
    name: '',
    description: '',
    data: '',
    resolution: '',
    references: [],
    policyviolations: [],
    impact: copyArray(impact),
    easeofresolution: '',
    exploitation: '',
    customfields: {},
    cve: [],
    cvss2: ''
  },
  lastSelected: -1,
  lastIndexForRange: -1,
  lastTemplateSelected: {},
  showModalCreate: false,
  filters: '',
  moreData: true,
  filesToUpload: [],
  uploadState: '',
  fileIndex: -1,
  errorFilesList: [],
  currentUploadState: '',
  currentUploadPercent: 0,
  currentUploadError: '',
  totalVulnTemplate: 0,
  vulnTemplateDetail: undefined,
  showVulnTemplateDetail: false,
  vulnTemplateDetailSelectedTab: 'general'
};

function knowledgeBase (state = initialState, action) {
  switch (action.type) {
    case GET_DATA_VULN_TEMPLATE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_DATA_VULN_TEMPLATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }

    case GET_DATA_VULN_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        vulnTemplateList: action.data,
        vulnTemplateCount: action.vulnTemplateCount,
        moreData: action.vulnTemplateCount > 0
      };
    }

    case VULN_TEMPLATE_SELECTED: {
      const {
        newRowsSelected, lastSelected, indexForRange, rowSelected
      } = action.selectionInfo;

      return {
        ...state,
        lastWorkspaceSelected: rowSelected,
        templatesSelected: newRowsSelected,
        lastSelected: lastSelected || -1,
        lastIndexForRange: indexForRange || -1
      };
    }

    case SHOW_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE: {
      return {
        ...state,
        showDeleteConfirmation: true
      };
    }

    case DELETE_VULN_TEMPLATE: {
      return {
        ...state,
        isFetching: false,
        lastWorkspaceSelected: initialState.lastWorkspaceSelected,
        templatesSelected: initialState.templatesSelected,
        lastSelected: initialState.lastSelected,
        lastIndexForRange: initialState.lastIndexForRange
      };
    }

    case HIDE_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE: {
      return {
        ...state,
        showDeleteConfirmation: false
      };
    }

    case HIDE_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE_SUCCEED: {
      return {
        ...state,
        showDeleteConfirmation: false
      };
    }

    case SET_VISIBILITY_CREATE_MODAL_KB: {
      return {
        ...state,
        showModalCreate: action.value
      };
    }

    case TEMPLATE_CREATE_UPDATE_SUCCESS: {
      return {
        ...state,
        showModalCreate: false,
        templatesSelected: []
      };
    }

    case GET_DATA_TEMPLATES_SORT: {
      return {
        ...state,
        isFetching: true,
        page: 1,
        sorting: action.sorting ? action.sorting : []
      };
    }

    case GET_DATA_TEMPLATES_FILTER: {
      return {
        ...state,
        filters: action.filter,
        page: 1
      };
    }

    // CSV UPLOADING

    case SET_CSV_KB_FILE_INDEX_MANAGE: {
      return {
        ...state,
        fileIndex: action.index
      };
    }

    case SET_FILES_TO_UPLOAD_KB: {
      return {
        ...state,
        filesToUpload: action.files
      };
    }

    case SET_UPLOAD_STATE_KB: {
      return {
        ...state,
        uploadState: action.state
      };
    }

    case UPLOAD_CSV_KB_UPDATE_PERCENT: {
      return {
        ...state,
        currentUploadState: 'Uploading',
        currentUploadPercent: Math.round((100 * action.progressEvent.loaded) / action.progressEvent.total),
        currentUploadError: ''
      };
    }

    case UPLOAD_CSV_KB_ERROR: {
      return {
        ...state,
        currentUploadState: 'Error',
        currentUploadPercent: 0,
        currentUploadError: action.error
      };
    }

    case CANCEL_REQUEST_UPLOAD_CSV_KB: {
      return {
        ...state,
        currentUploadState: 'Canceled',
        currentUploadPercent: 0,
        currentUploadError: ''
      };
    }

    case SET_NAME_INTO_FILE_ERROR_LIST_CSV_KB: {
      return {
        ...state,
        errorFilesList: [...state.errorFilesList, action.name]
      };
    }

    case RESET_FILE_STATE_KB: {
      return {
        ...state,
        currentUploadState: '',
        currentUploadPercent: 0,
        currentUploadError: '',
        errorFilesList: [],
        filesToUpload: [],
        fileIndex: -1,
        uploadState: ''
      };
    }

    case GET_VULNS_COUNT_SUCCESS: {
      return {
        ...state,
        totalVulnTemplate: action.total
      };
    }

    case GET_VULNS_COUNT_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.error
      };
    }

    case SET_KNOWLEDGE_BASE_PAGE: {
      return {
        ...state,
        page: action.page
      };
    }

    // context

    case CONTEXT_VULN_TEMPLATE_SELECTED: {
      return {
        ...state,
        templatesSelected: uniq([...state.templatesSelected, ...action.payload]),
        templateSelectedForWorking: uniq([...state.templatesSelected, ...action.payload])
      };
    }

    case VULN_TEMPLATE_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newTemplatesSelected = action.payload.reduce((templatesSelected, templateToDelete) => filterByID(templatesSelected, templateToDelete._id), state.templatesSelected);

      return {
        ...state,
        templatesSelected: newTemplatesSelected
      };
    }

    case VULN_TEMPLATE_NEW_PIVOT: {
      return {
        ...state,
        lastSelected: action.payload
      };
    }

    case CONTEXT_SELECT_ALL_VULN_TEMPLATE: {
      return {
        ...state,
        templatesSelected: [...action.templatesList]
      };
    }

    case UNSELECT_ALL_VULN_TEMPLATE: {
      return {
        ...state,
        templatesSelected: []
      };
    }

    case VULN_TEMPLATE_SHOW_DETAIL: {
      return {
        ...state,
        vulnTemplateDetail: action.vulnTemplate,
        showVulnTemplateDetail: true
      };
    }
    case VULN_TEMPLATE_HIDE_DETAIL: {
      return {
        ...state,
        vulnTemplateDetail: undefined,
        showVulnTemplateDetail: false
      };
    }

    case VULN_TEMPLATE_UPDATE_SUCCESS: {
      return {
        ...state,
        vulnTemplateDetail: { ...action.newTemplate },
        vulnTemplateList: action.templatesList
      };
    }

    case VULN_TEMPLATE_SET_DETAIL_TAB: {
      return {
        ...state,
        vulnTemplateDetailSelectedTab: action.vulnTemplateDetailSelectedTab
      };
    }

    case CLEAR_ERROR: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }

    default: {
      return state;
    }
  }
}

export default knowledgeBase;
