import React from 'react';
import { useIntl } from 'react-intl';
import { graphTemplates } from 'store/Analytics/selectors';
import SelectTypeCard from '../selectTypeCard';
import { Wrapper, Subtitle } from './styled';

const SelectType = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Subtitle>{intl.formatMessage({ id: 'analytics.selectType.subtitle' })}</Subtitle>
      {graphTemplates.map((temp) => <SelectTypeCard title={ temp.name } key={ temp.key } description={ temp.description } type={ temp.key } allowMultipleWs={ temp.allowMultipleWs } allowHosts={ temp.allowHosts } />)}
    </Wrapper>
  );
};

export default SelectType;
