import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUserSelected, hideUserModalDelete
} from 'Screens/Users/actions/Actions';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { selectUserSelected, selectShowDeleteConfirmation } from 'store/Users/selectors';

const DeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const userSelected = useSelector(selectUserSelected);
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ () => dispatch(deleteUserSelected()) }
      handleClose={ () => dispatch(hideUserModalDelete()) }
      entity="user"
      count={ userSelected.length }
    />
  );
};

export default DeleteConfirmationModal;
