import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
    background-image: linear-gradient(32deg, #fff 84%, #d3d3d3 109%, #dedede 111%);
    border: solid 1px #ddd;
    box-shadow: 12px 12px 50px 0 rgba(207, 207, 207, 0.5);
    display: flex;
    flex-direction: column;
    flex: 0 0 753px;
    margin: 0px auto auto auto;
    max-width: 589px;
    overflow: scroll;
    padding-bottom: 59px;
    padding-left: 59px;
    padding-top: 83px;
    width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    user-select: none;
    text-align: left;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0b2128;
`;
Title.displayName = 'Title';

export const Separator = styled.div`
    flex: 0 0 3px;
    opacity: 0.32;
    background-color: #d8d8d8;
    margin-bottom: 16px;
`;
Separator.displayName = 'Separator';

export const TableWrapper = styled.div`
    flex: 1 1 auto;
    overflow: hidden;
    padding-right: 50px;
`;
TableWrapper.displayName = 'TableWrapper';

export const StyledTable = styled(CustomReactTable)`
    height: 100%;
    background: transparent !important;

    .rt-tbody {
        background: transparent !important;
    }
    .rt-tr.-even {
        height: 30px;
    }
    .rt-tr.-odd {
        height: 30px;
    }
    rt-tr-group{
        border-bottom: 0px !important;
    }
    
    div.rt-tbody .rt-tr-group {
        height: 30px;
        background-color: transparent !important;
        flex: unset;
        border: 0;
        rt-td {
            border: 0;
        }
    }

    div.rt-tbody .rt-tr {
        background: transparent !important;
    }

    div.rt-tbody .rt-tr:hover {
        background: transparent !important;
    }
`;

export const Target = styled.div`
    text-align: left; 
    font-size: 11.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.76;
    letter-spacing: normal;
    color: #abaebf;
`;
Target.displayName = 'Target';

export const VulnName = styled.div`
    text-align: left; 
    font-size: 11.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #0b2128;
`;
VulnName.displayName = 'VulnName';
