import colors from 'Styles/colors';
import styled from 'styled-components';

const color = {
  'Critical Risk': colors.purple6,
  'High Risk': colors.red5,
  'Medium Risk': colors.orange4,
  'Low Risk': colors.green7,
  'Info Risk': colors.grey2
};

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.label`
  user-select: none;
  color: #122d46;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0;
`;
Title.displayName = 'Title';

export const Empty = styled.div`
  user-select: none;
  color: ${colors.grey5};
  display: inline;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
`;
Empty.displayName = 'Empty';

export const PieWrapper = styled.div`
  height: 210px;
`;

export const FlyoutWrapper = styled.div`
  width: 140px;
  height: 140px;
  text-align: center;
  background-color: ${({ risk }) => color[risk]};
  border-radius: 50%;
`;
FlyoutWrapper.displayName = 'FlyoutWrapper';

export const Data = styled.div`
  width: 120px;
  margin-left: 10px;
  padding-top: 33px;
`;
Data.displayName = 'Data';

export const Name = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${colors.white};
  line-height: 1.1;
`;
Name.displayName = 'Name';

export const Value = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: ${colors.white};
  line-height: 1.3;
`;
Value.displayName = 'Value';

export const References = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
References.displayName = 'References';

export const Reference = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: ${colors.blueGrey};
  margin: 0 17px 0 10px;
  cursor: pointer;
  
  &>:last-child {
    margin: 0 0 0 10px;
  }

  &::before {
    width: 11px;
    height: 11px;
    margin-right: 6px;
    content: "";
    background-color:${({ risk }) => color[risk]};
  }
`;
Reference.displayName = 'Reference';
