import styled from 'styled-components';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const RowsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
RowsWrapper.displayName = 'RowsWrapper';

export const DragFileWrapper = styled.div`
  padding: 10px 10px 10px 0px;
  width: 350px;
`;
DragFileWrapper.displayName = 'DragFileWrapper';

export const OptionsWrapper = styled.div`
  padding: 10px 0px 10px 10px;
  width: 400px;
`;
OptionsWrapper.displayName = 'OptionsWrapper';

export const AddMore = styled.div`
  cursor:pointer;
  width: fit-content;
  margin-top: 5px;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  color: #0082ed;

  svg {
    margin-right: 5px;
    font-size: 0.9em;
    position: relative;
    top: -2px;
  }

  label {
    cursor:pointer;
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: left;
    color: #0082ed;
    margin: 0px;
  }
`;
AddMore.displayName = 'AddMore';

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0px;
  border: solid 1px #d9e4ef;
`;
Separator.displayName = 'Separator';
