import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { downloadReport } from 'store/ExecutiveReport/actions';
import {
  Wrapper, DownloadIcon, Dropdown, Backdrop
} from './styled';
import ExportItem from './components/ExportItem';

const DownloadDropdown = ({ reportId, reportName, template_name }) => {
  const [showDropdown, setDropdownVisibility] = useState(false);
  const dispatch = useDispatch();
  const exportTo = (type) => dispatch(downloadReport(reportId, reportName, type));
  const isWord = template_name.toLowerCase().includes('.doc');

  return (
    <>
      <Wrapper title="Download" onClick={ () => setDropdownVisibility(!showDropdown) } keepVisible={ showDropdown }>
        <DownloadIcon />
        { showDropdown &&
        (
        <Dropdown>
          { isWord
            ? <ExportItem type="doc" exportAction={ exportTo } />
            : <ExportItem type="pdf" exportAction={ exportTo } />}
          <ExportItem type="csv" exportAction={ exportTo } />
        </Dropdown>
        )}
      </Wrapper>
      { showDropdown && <Backdrop onClick={ () => setDropdownVisibility(false) } /> }
    </>
  );
};

DownloadDropdown.propTypes = {
  reportId: PropTypes.number.isRequired,
  reportName: PropTypes.string.isRequired,
  template_name: PropTypes.string.isRequired
};

export default DownloadDropdown;
