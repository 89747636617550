import styled from 'styled-components';
import colors from 'Styles/colors';

export const CommentsItem = styled.div`
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-direction: column;
`;
CommentsItem.displayName = 'CommentsItem';

export const CommentsDate = styled.div`
    user-select: none;
    color: ${colors.blueGrey};
    margin-bottom: 18px;
`;
CommentsDate.displayName = 'CommentsDate';

export const CommentsContent = styled.span`
    color: #1c2243;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    width: 100%;
    p {
        display: inline;
    }
`;
CommentsContent.displayName = 'CommentsContent';

export const CommentRemove = styled.span`
    float: right;
    margin-right: 16px;
    border-radius: 50%;
    padding: 3px;
    cursor:pointer;   

    &:hover {
        background-color: #94acc2;
        svg {
            color: #fff!important;
        }
    }
`;
CommentRemove.displayName = 'CommentRemove';

export const CommentEdit = styled.span`
    float: right;
    margin-right: 16px;    
    border-radius: 50%;
    padding: 3px;
    cursor:pointer;    

    &:hover {
        background-color: #94acc2;
        svg {
            color: #fff!important;
        }
    }
`;
CommentEdit.displayName = 'CommentEdit';
