import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  selectClosed, selectDescription, selectName, selectNotConfirmed, selectType, selectAllowMultipleWs, selectAllowHosts, selectWorkspaces,
  selectHosts, selectFrom, selectTo, selectId, selectGraphs, selectGraphSelected, filtersChanged
} from 'store/Analytics/selectors';
import { useIntl } from 'react-intl';
import {
  setGraphClosed, setGraphDescription, setGraphHosts, setGraphName, setGraphNotConfirmed, setGraphWorkspaces, fetchHosts, refreshGraph
} from 'store/Analytics/actions';
import { redirect } from 'store/Router/actions';
import Checkbox from 'Common/Components/Checkbox';
import Hosts from 'Screens/Analytics/components/CreateEdit/components/EditGraph/components/Settings/components/Hosts';
import Workspaces from 'Screens/Analytics/components/CreateEdit/components/EditGraph/components/Settings/components/Workspaces';
import SingleWorkspace from 'Screens/Analytics/components/CreateEdit/components/EditGraph/components/SingleWorkspace';
import {
  Wrapper, Title, Name, Description, WrapperConfirm, WrapperClose, WrapperCheckbox, Label, GoBack, WrapperTitle
} from './styled';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';

const Settings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const type = useSelector(selectType);
  const name = useSelector(selectName);
  const description = useSelector(selectDescription);
  const notConfirmed = useSelector(selectNotConfirmed);
  const closed = useSelector(selectClosed);
  const workspaces = useSelector(selectWorkspaces);
  const allowMultipleWs = useSelector(selectAllowMultipleWs);
  const allowHosts = useSelector(selectAllowHosts);
  const hosts = useSelector(selectHosts);
  const from = useSelector(selectFrom);
  const to = useSelector(selectTo);
  const id = useSelector(selectId);
  const graphsList = useSelector(selectGraphs);
  const graphSelected = useSelector(selectGraphSelected);
  const workspacesList = useSelector(selectActiveWorkspaces);
  const workspacesNames = workspacesList.map((ws) => ws.name);
  const wsDeleted = workspaces.some((ws) => !workspacesNames.includes(ws));

  useEffect(() => {
    if (!isEmpty(workspaces) && allowHosts && !wsDeleted) dispatch(fetchHosts(workspaces));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces]);

  const onRemoveWorkspace = (index) => {
    dispatch(setGraphWorkspaces([
      ...workspaces.filter((_, i) => i !== index)
    ]));
  };

  const onAddWorkspace = () => {
    dispatch(setGraphWorkspaces([
      ...workspaces,
      ''
    ]));
  };

  const onSelectWorkspace = (index, name) => {
    if (!workspaces.some((ws) => ws === name)) {
      const newWorkspaces = [...workspaces];
      newWorkspaces[index] = name;
      dispatch(setGraphWorkspaces(newWorkspaces));
    }
  };

  const onRemoveHost = (index) => {
    dispatch(setGraphHosts([
      ...hosts.filter((_, i) => i !== index)
    ]));
  };

  const onAddHost = () => {
    dispatch(setGraphHosts([
      ...hosts,
      ''
    ]));
  };

  const onSelectHost = (index, name) => {
    if (!hosts.some((host) => host === name)) {
      const newHosts = [...hosts];
      newHosts[index] = name;
      dispatch(setGraphHosts(newHosts));
    }
  };

  const onSelectSingleWorkspace = (item) => {
    dispatch(setGraphWorkspaces([
      item.value
    ]));
  };

  const showIncludeClosed = () => type !== 'monthly_evolution_by_status' && type !== 'vulnerabilities_per_status';

  useEffect(() => {
    const graphHosts = allowHosts ? (hosts.length > 0 && !hosts.includes('')) : true;
    if (!id && workspaces.length > 0 && !workspaces.includes('') && graphHosts) {
      dispatch(refreshGraph());
    }
    if (id && workspaces.length > 0 && !workspaces.includes('') && graphHosts) {
      const prevGraph = graphsList.find((graph) => graph.id === id);
      const filtersUpdated = filtersChanged(prevGraph, graphSelected, allowHosts);
      if (filtersUpdated) dispatch(refreshGraph());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, workspaces, hosts, notConfirmed, closed]);

  if (!type) return null;

  return (
    <Wrapper>
      <WrapperTitle>
        <Title>{ intl.formatMessage({ id: `analytics.templates.${type}` }) }</Title>
        { !id && <GoBack onClick={ () => dispatch(redirect('/analytics/new')) }>{ intl.formatMessage({ id: 'analytics.settings.back' }) }</GoBack> }
      </WrapperTitle>
      <Name placeholder={ intl.formatMessage({ id: 'analytics.graph.name' }) } defaultValue={ name } onChange={ (e) => dispatch(setGraphName(e.target.value)) } />
      <Description placeholder={ intl.formatMessage({ id: 'analytics.graph.description' }) } defaultValue={ description } onChange={ (e) => dispatch(setGraphDescription(e.target.value)) } />
      <WrapperCheckbox>
        <WrapperConfirm title="Include Not Confirmed">
          <Checkbox state={ notConfirmed } onChange={ () => dispatch(setGraphNotConfirmed(!notConfirmed)) } />
          <Label>Not Confirmed</Label>
        </WrapperConfirm>
        { showIncludeClosed() &&
          (
            <WrapperClose title="Include Closed">
              <Checkbox state={ closed } onChange={ () => dispatch(setGraphClosed(!closed)) } />
              <Label>Closed</Label>
            </WrapperClose>
          )
        }
      </WrapperCheckbox>
      { allowMultipleWs
        ? <Workspaces workspacesSelected={ workspaces } onSelectCallback={ onSelectWorkspace } onRemoveCallback={ onRemoveWorkspace } onAddCallback={ onAddWorkspace } />
        : <SingleWorkspace onSelectWorkspaceCallback={ onSelectSingleWorkspace } defaultValue={ workspaces.length > 0 ? { label: workspaces[0], value: workspaces[0] } : '' } /> }
      { allowHosts && <Hosts hostsSelected={ hosts } onSelectCallback={ onSelectHost } onRemoveCallback={ onRemoveHost } onAddCallback={ onAddHost } /> }
    </Wrapper>
  );
};

export default Settings;
