import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { ModalContent, Content } from './styled';

const FlexModal = ({ children }) => (
  <ModalWrapper>
    <ModalContent>
      <Content>
        {children}
      </Content>
    </ModalContent>
  </ModalWrapper>
);

export default FlexModal;
