import sortBy from 'lodash/sortBy';
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const parseData = (data) => {
  const parsed = data.map((d) => {
    const date = moment(`01-${d.label}`, 'DD-MM-YYYY');

    return {
      ...d,
      date,
      label: date.format('MMM')
    };
  });
  return sortBy(parsed, ['date'], ['asc']);
};

export const getDataWithMinBarSize = (graphData) => {
  const newYKey = '_minY';
  const maxBarRatio = 50;

  const values = graphData.map(({ value }) => value);
  const maxValue = Math.max(...values);

  const dataWithMinBarSize = graphData.map(item => {
    const isHigherRatio = Math.round(maxValue / item.value) > maxBarRatio;

    return {
      ...item,
      [newYKey]: ((item.value > 0) && isHigherRatio) ? (maxValue / maxBarRatio) : item.value
    };
  });

  return [dataWithMinBarSize, newYKey];
};

export const getDataWithMinBarSizeStackedGraph = (graphData) => {
  const newYKey = '_minY';
  const maxBarRatio = 50;
  const values = graphData.map((item) => item.data.total);
  const maxValue = Math.max(...values);

  const dataWithMinBarSize = graphData.map(ws => {
    let newData = {};
    Object.entries(ws.data).forEach(([key, value]) => {
      if (key !== 'total') {
        const isHigherRatio = Math.round(maxValue / value) > maxBarRatio;
        const showMin = (value > 0) && isHigherRatio;
        newData = { ...newData, [newYKey]: { ...newData[newYKey], [key]: showMin ? (maxValue / maxBarRatio) : value } };
      }
    });
    return { ...ws, data: { ...ws.data, ...newData } };
  });

  return [dataWithMinBarSize, newYKey];
};
