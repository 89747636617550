import React, { useState } from 'react';
import ActionBar from './ActionBar';
import MarkAsDuplicateModal from './ActionBar/components/MarkAsDuplicateModal';

const CustomActionBar = () => {
  const [showDuplicateModal, toggleDuplicateModal] = useState(false);

  return (
    <>
      { showDuplicateModal ? <MarkAsDuplicateModal hideModal={ () => toggleDuplicateModal(false) } /> : null }
      <ActionBar entity="vulnsAssets" showDuplicateModal={ () => toggleDuplicateModal(true) } />
    </>
  );
};

export default CustomActionBar;
