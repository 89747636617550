import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Icon } from 'Images/blank_reports_view.svg';

export const DocumentIcon = styled(Icon)`
    margin: 50px auto 0px auto;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    margin: 22px auto 0px auto;
    font-size: 21.5px;
    text-align: center;
    color: ${colors.blueGrey};
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
    margin: 13px auto 0px auto;
    font-size: 12.5px;
    text-align: center;
    color: ${colors.blueGrey};
`;
Subtitle.displayName = 'Subtitle';
