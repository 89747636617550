import React from 'react';
import { Wrapper, EmptyImage } from './styled';

// empty list/grid view
const Empty = ({ showNewUI }) => (
  <Wrapper showNewUI={ showNewUI }>
    <EmptyImage />
  </Wrapper>
);

export default Empty;
