import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectVulnDetail, selectTempCVSSVector } from 'store/Manage/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { resetCVSS, updateVuln } from 'store/Manage/actions';
import Calculator from './components/Calculator';
import { Title } from '../ExpandableWrapper/styled';
import {
  Wrapper, Header, Button, Arrow, ResultsWrapper,
  Results, ResultsTitle, Score, Vector, CVSS2Title,
  IconWrapper, Remove
} from './styled';

const CVSS = ({ showRiskInfo }) => {
  const [showCalculator, toggleCalculator] = useState(false);
  const currentVuln = useSelector(selectVulnDetail);
  const { cvss2, cvss3 } = currentVuln;
  const tempCVSS = useSelector(selectTempCVSSVector);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const dispatch = useDispatch();
  const intl = useIntl();
  const resultsText = intl.formatMessage({ id: 'manage.detail.tab.general.cvss3.results' });
  const showCvss2 = !!((!cvss3.vector_string) && (cvss2.vector_string));

  const vectorString = () => {
    if (tempCVSS) return tempCVSS.vector_string;
    else if (cvss3.vector_string) return `${cvss3.vector_string}`;
    else return 'CVSS:3.1/AV:_/AC:_/PR:_/UI:_/S:_/C:_/I:_/A:_';
  };

  const cvss3Score = () => {
    if (tempCVSS && tempCVSS.base_score) return `${tempCVSS.base_severity} (${tempCVSS.base_score})`;
    else if (cvss3.base_score) return `${cvss3.base_severity} (${cvss3.base_score})`;
    else return 'N/A';
  };

  const severity = () => {
    if (tempCVSS && tempCVSS.base_severity) return tempCVSS.base_severity;
    else if (cvss3.base_severity) return cvss3.base_severity;
    else return '';
  };

  const incompleteVector = vectorString().includes('_');

  useEffect(() => {
    toggleCalculator(false);
    dispatch(resetCVSS());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVuln.id]);

  const removeCVSS = () => {
    dispatch(updateVuln(currentVuln, 'cvss3', { vector_string: '' }));
    dispatch(resetCVSS());
    toggleCalculator(false);
  };

  if (!showRiskInfo) return null;
  return (
    <Wrapper>
      <Header>
        <Title children={ intl.formatMessage({ id: 'manage.detail.tab.general.cvss3' }) } />
        { canUpdate &&
          <Button onClick={ () => toggleCalculator(!showCalculator) } >
            { showCalculator ? 'Hide Calculator' : 'Show Calculator' }
            { showCalculator
              ? <Arrow>&#9662;</Arrow>
              : <Arrow>&#9656;</Arrow>
            }
          </Button>
        }
      </Header>
      { showCalculator && <Calculator cvssData={ cvss3 } /> }
      <ResultsWrapper>
        <ResultsTitle>{ resultsText }</ResultsTitle>
        <Results>
          <Score severity={ severity() }>{ cvss3Score() }</Score>
          <Vector disable={ (!cvss3 || !cvss3.vector_string) && (!tempCVSS || !tempCVSS.vector_string) }>
            { vectorString() }
            { canUpdate &&
              <IconWrapper onClick={ removeCVSS } fullVector={ !incompleteVector } >
                <Remove />
              </IconWrapper>
            }
          </Vector>

        </Results>
      </ResultsWrapper>

      { showCvss2 &&
        <>
          <CVSS2Title children={ intl.formatMessage({ id: 'manage.detail.tab.general.cvss2' }) } />
          <ResultsWrapper>
            <ResultsTitle>{ resultsText }</ResultsTitle>
            <Results>
              <Score severity={ (cvss2.base_score && cvss2.base_severity) ? cvss2.base_severity : '' }>{ cvss2.base_score ? `${cvss2.base_severity} (${cvss2.base_score})` : 'N/A' }</Score>
              <Vector>{ `CVSS:2.0/${cvss2.vector_string}` }</Vector>
            </Results>
          </ResultsWrapper>
        </>
      }
    </Wrapper>
  );
};

export default CVSS;
