import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { selectInputField } from 'store/ExecutiveReportEditCreate/selectors';
import { setInputField } from 'store/ExecutiveReportEditCreate/actions';
import Expander from '../Expander/index';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';

const subtitles = {
  summary: 'Summary of methods and procedures used.',
  scope: 'Scope of analysis taken into consideration. It refers to the set of assets that were evaluated.',
  objectives: 'Objectives of the evaluation. Information expected to be obtained on the scope of analysis.',
  conclusions: 'Findings on vulnerabilities and their characteristics.',
  recommendations: 'Actions recommended to mitigate vulnerabilities found.'
};

const MarkdownSection = ({
  id, onDelete, onAdd, isAdded, inputType
}) => {
  const title = capitalize(inputType);
  const subtitle = get(subtitles, `${inputType}`, '');
  const dispatch = useDispatch();
  const inputValue = useSelector((state) => selectInputField(state, inputType));

  useEffect(() => {
    if (inputValue !== '' && !isAdded) onAdd(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const RemoveSection = () => {
    dispatch(setInputField(inputType, ''));
    onDelete(id);
  };

  return (
    <Expander id={ id } title={ title } subtitle={ subtitle } canExpand={ isAdded } onAdd={ onAdd } onDelete={ RemoveSection }>
        <MarkdownEditor
          value={ inputValue }
          onBlur={ (value) => dispatch(setInputField(inputType, value)) }
          minHeight={ 194 }
        />
    </Expander>
  );
};

MarkdownSection.propTypes = {
  id: PropTypes.number.isRequired,
  inputType: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  isAdded: PropTypes.bool.isRequired
};

export default MarkdownSection;
