import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAttachments, selectVulnDetailId
} from 'store/Manage/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import UploadEvidence from './components/UploadEvidence';
import Attachment from './components/Attachment';
import { Wrapper, ActiveDragBox, Text, Cloud } from './styled';
import api from 'services/api';
import { selectCurrentWorkspace, selectIsReadOnly } from 'store/Faraday/selectors';
import { LOAD_EVIDENCE_REQUEST, LOAD_EVIDENCE_SUCCESS } from 'store/Manage/types';
import { addEvidences } from 'store/Manage/actions';
import { openModal } from 'store/modals/actions';
import { MODAL_EVIDENCE_WARNING_UPLOAD } from 'store/modals/modals';
import { setAttachments } from 'Screens/ManageEditCreate/actions/Actions';

const EvidenceTab = ({ isEditing }) => {
  const dispatch = useDispatch();
  const [evidences, setEvidences] = useState({});
  const [dragActive, setDragActive] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const attachments = useSelector(selectAttachments);
  const vulnDetailId = useSelector(selectVulnDetailId);
  const readOnly = useSelector(selectIsReadOnly);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));

  const allowed = isEditing || canUpdate;
  const workspace = useSelector(selectCurrentWorkspace);
  const files = isEditing ? attachments : evidences;
  const boxRef = useRef(null);

  const getEvidences = useCallback(async () => {
    dispatch({ type: LOAD_EVIDENCE_REQUEST });
    try {
      const data = await api.attachments.getAttachments(workspace, vulnDetailId);
      setEvidences(data);
    } catch (e) {
      setEvidences({});
    } finally {
      dispatch({ type: LOAD_EVIDENCE_SUCCESS });
    }
  }, [workspace, vulnDetailId]);

  const deleteEvidence = useCallback(async (name) => {
    dispatch({ type: LOAD_EVIDENCE_REQUEST });
    try {
      await api.attachments.removeAttachments(workspace, vulnDetailId, name);
      const newEvidences = { ...evidences };
      delete newEvidences[name];
      setEvidences(newEvidences);
    } catch (e) {
    } finally {
      dispatch({ type: LOAD_EVIDENCE_SUCCESS });
    }
  }, [evidences, workspace, vulnDetailId]);

  useEffect(() => {
    getEvidences();
  }, [getEvidences]);

  const uploadEvidences = (files) => {
    const filesToUpload = [];
    for (const item of files) {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        const fileSize = file.size / 104857;
        if (fileSize <= 20) filesToUpload.push(file);
        else dispatch(openModal(MODAL_EVIDENCE_WARNING_UPLOAD));
      }
    }
    if (filesToUpload.length > 0) {
      if (isEditing) dispatch(setAttachments(filesToUpload));
      else dispatch(addEvidences(filesToUpload, getEvidences));
    }
  };

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.items;
    uploadEvidences(pastedData);
  };

  const handleDragEnter = (e) => {
    if (!readOnly && allowed) {
      e.preventDefault();
      e.stopPropagation();
      setDragCounter(1);
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDragActive(true);
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(0);
    if (dragCounter === 0) setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      uploadEvidences(e.dataTransfer.items);
      setDragCounter(0);
    }
  };

  return (
    <>
    <Wrapper onPasteCapture={ !readOnly && allowed && handlePaste } onDragEnter={ handleDragEnter } onDragOver={ handleDragOver } onDrop={ handleDrop } onDragLeave={ handleDragLeave } ref={ boxRef }>
      {!readOnly && allowed && <UploadEvidence getEvidences={ getEvidences } isEditing={ isEditing } /> }
      {Object.keys(files).map((key) => <Attachment key={ key } deleteEvidence={ deleteEvidence } evidence={ evidences[key] } name={ key } isEditing={ isEditing } />)}
      { dragActive &&
        <ActiveDragBox>
          <Cloud color="#0082ed" />
          <Text>Drop your files here</Text>
        </ActiveDragBox>
      }
    </Wrapper>
    </>
  );
};

export default EvidenceTab;
