import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
    margin-left: 27px;
    margin-right: 18px;
    opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    user-select: none;
    text-align: left;
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.37;
    letter-spacing: normal;
    color: #264d6d;
`;
Title.displayName = 'Title';

export const NoTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
NoTagsContainer.displayName = 'NoTagsContainer';

export const NoTags = styled.div`
  cursor: default;
  user-select: none;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #90a9c0;
`;
NoTags.displayName = 'NoTagsSubtitle';

export const NoTagsBold = styled(NoTags)`
    font-weight: 600;
    white-space: break-spaces;
`;
NoTagsBold.displayName = 'NoTagsBold';

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 568px;
  margin-top: 14px;
  margin-bottom: 14px;
  >:not(:nth-child(4n)){
    margin-right: 10px;
  }
  >:nth-child(n+5){
    margin-top: 14px;
  }
`;
TagsContainer.displayName = 'TagsContainer';
