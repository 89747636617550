import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as viewInLogIcon } from 'Images/viewInLog.svg';
import { ReactComponent as MarkAsReadIconSvg } from 'Images/icon-action-bar-column.svg';
import { ReactComponent as SettingsIconSvg } from 'Images/icon-customfields.svg';

export const Wrapper = styled.div`
  position: absolute;
  width: 256px;
  //max-height: 319px;
  border-radius: 2px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #edf2f7;
  background-color: #ffffff;
  top: 43px;
  right: 0px;
  z-index: 999;
`;

export const Footer = styled.div`
  height: 45px;
  margin: 0;
  padding: 5px;
`;

export const FooterButtom = styled.button`
background: none;
color: inherit;
border: 1px solid #63758d;
padding: 0;
font: inherit;
cursor: pointer;
outline: inherit;

font-size: 14px;
font-weight: 500;
text-align: center;
color: #1c2243;
width: 100%;
height: 100%;

`;

export const Header = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #d9e4ef;
  padding: 0 12px;
  height: 45px;

  & p {
    color: #1c2243;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-size: 11px;
  color: #1c2243;
  text-align: initial;
  padding: 10px 0;
`;

export const TimeStamp = styled.p`
  margin: 0;
  font-size: 9px;
  color: #63758d;
`;

export const NotificationList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const Icons = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
`;

export const Delete = styled(DeleteIcon)`
  width: 16px;
  display:none;
`;

export const ViewLog = styled(viewInLogIcon)`
  display:none;
`;

export const ListItem = styled.li`
  list-style: none;
  min-height: 70px;
  margin: 0;
  padding: 0 13px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #d9e4ef;
  cursor: pointer;
  background-color: ${(props) => (!props.read ? '#edf2f7' : 'inherit')};

  &:hover {
    background-color: #edf2f7;
  }

  &:hover ${Delete}{
    display:block;
  }
  &:hover ${ViewLog}{
    display:block;
  }
`;

export const MarkAsReadIcon = styled(MarkAsReadIconSvg)`
height:14px;
margin-right: 7px;
margin-left: auto;
`;

export const SettingsIcon = styled(SettingsIconSvg)`
height:16px;
& path {
  fill: #1C4566;
}
`;
