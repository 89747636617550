import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Subtitle, Title } from 'Screens/Settings/common-styled';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { saveServiceNowSettings } from 'store/Settings/serviceNowActions';
import { saveGitLabSettings } from 'store/Settings/gitLabActions';
import { saveSolarWindsSettings } from 'store/Settings/solarWindsActions';
import { selectAllowedByFeature } from 'Hooks/usePermission/selector';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import upgradeLicenseImage from 'Images/ticketing-license.png';
import {
  FEATURE_TICKETING_GITLAB, FEATURE_TICKETING_JIRA, FEATURE_TICKETING_SERVICENOW, FEATURE_TICKETING_WHD, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { ACTIONS } from 'tracking/GA/constants';
import Header from '../Header';
import Jira from './components/Jira';
import ServiceNow from './components/ServiceNow';
import {
  GitLabLogo, JiraLogo, ServicenowLogo, SolarwindsLogo, Wrapper, Content, Options, View
} from './styled';
import GitLab from './components/GitLab';
import SolarWinds from './components/SolarWinds';
import UpgradeLicense from './components/UpgradeLicense';
import Solarwinds from 'Images/preferences_solarwinds_logo.jpeg';
import { selectActiveConfig } from 'store/Settings/settingsSelectors';
import Checkbox from 'Common/Components/Checkbox';
import styled from 'styled-components';
import { fetchActiveConfig, updateActiveConfig } from 'store/Settings/settingsActions';

const TicketingTools = ({ onClose }) => {
  const [radioValue, setRadio] = useState(0);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [disabled, setDisabled] = useState(false);

  const isEnabledJira = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const isEnabledGitlab = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const isEnabledServiceNow = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const isEnabledWhd = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));

  const configActive = useSelector(selectActiveConfig);

  useEffect(() => {
    dispatch(fetchActiveConfig());
  }, [dispatch]);

  const onChange = (target) => {
    dispatch(updateActiveConfig(target));
  };

  const close = () => {
    onClose();
  };

  const accept = () => {
    if (radioValue === 2) dispatch(saveServiceNowSettings());
    if (radioValue === 3) dispatch(saveSolarWindsSettings());
    if (radioValue === 4) dispatch(saveGitLabSettings());

    close();
  };

  const featureEnabled = () => isEnabledJira || isEnabledGitlab || isEnabledServiceNow || isEnabledWhd;

  return (
    <Wrapper>
      <Header onAccept={ accept } onClose={ close } disabled={ disabled } />
      { featureEnabled()
        ? (
            <>
              <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.title' }) }</Title>
              <Subtitle>{ intl.formatMessage({ id: 'preferences.ticketingTools.subtitle' }) }</Subtitle>
              <Content>
              <Options>
  {isEnabledJira && <CheckBoxButton label={ <JiraLogo /> } value={ 1 } checked={ configActive.jira.active } onChange={ () => onChange('jira') } setRadioValue={ setRadio } selected={ radioValue === 1 } disabled={ !configActive.jira.available } />}
  {isEnabledServiceNow && <CheckBoxButton label={ <ServicenowLogo /> } value={ 2 } checked={ configActive.servicenow.active } onChange={ () => onChange('servicenow') } setRadioValue={ setRadio } selected={ radioValue === 2 } disabled={ !configActive.servicenow.available } />}
  {isEnabledWhd && <CheckBoxButton label={ <SolarwindsLogo src={ Solarwinds } /> } value={ 3 } checked={ configActive.whd.active } onChange={ () => onChange('whd') } setRadioValue={ setRadio } selected={ radioValue === 3 } disabled={ !configActive.whd.available } />}
  {isEnabledGitlab && <CheckBoxButton label={ <GitLabLogo /> } value={ 4 } checked={ configActive.gitlab.active } onChange={ () => onChange('gitlab') } setRadioValue={ setRadio } selected={ radioValue === 4 } disabled={ !configActive.gitlab.available } />}
              </Options>
                <View>
                  { radioValue === 1 && <Jira setDisabled={ setDisabled } /> }
                  { radioValue === 2 && <ServiceNow setDisabled={ setDisabled } /> }
                  { radioValue === 3 && <SolarWinds setDisabled={ setDisabled } /> }
                  { radioValue === 4 && <GitLab setDisabled={ setDisabled } /> }
                </View>
              </Content>
            </ >
          )
        : <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.ticketing.description" trackingShow={ ACTIONS.showSettings } trackingClick={ ACTIONS.clickSettings } />}
    </Wrapper>
  );
};

TicketingTools.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default TicketingTools;

const StyledLabel = styled.span`
  display: inline-block;
  height: 31px;
  line-height: 31px; 
  margin-left: 5px;
  `;

const StyledContainer = styled.div`
  padding: 5px; // Añade padding si es necesario para el espacio alrededor de la sombra
  box-shadow: ${props => props.isSelected ? '0px 0px 5px 2px rgba(0,0,0,0.2)' : 'none'};
  transition: box-shadow 0.3s ease;
`;
function CheckBoxButton ({
  value,
  onChange,
  name,
  id,
  label,
  disabled,
  checked,
  setRadioValue,
  selected
}) {
  const handleCheckboxChange = (e) => {
    // Puedes agregar cualquier lógica adicional aquí si es necesario
    onChange && onChange(e);
  };

  const handleLabelClick = () => {
    setRadioValue(value);
  };

  return (
    <StyledContainer isSelected={ selected }>
      <Checkbox
        state={ checked }
        onChange={ handleCheckboxChange }
        disabled={ disabled }
      />
      <StyledLabel onClick={ handleLabelClick }>{label}</StyledLabel>
    </StyledContainer>
  );
}
