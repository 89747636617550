import styled from 'styled-components';
import colors from 'Styles/colors';

export const GreyButton = styled.button`
  width: 89px;
  height: 34px;
  margin: auto 0px auto auto;
  border-radius: 3px;
  /* border: solid 1px #d4d7e5; */
  border: solid 1px #63758d;  
  background-color: ${colors.paleGrey};
  color: ${colors.darkBlueGrey};
  font-size: 14.5px;
  line-height: 2.14;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  :hover {
    background-color: ${colors.iceBlue};
  }
  :active {
    background-color: #d9e4ef;
  }
`;
GreyButton.displayName = 'GreyButton';

export const BlueButton = styled.button`
  width: 89px;
  height: 34px;
  margin: auto 12px auto 16px;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: ${({ disabled }) => (disabled ? `${colors.veryLightPink}` : `${colors.blueCerulean}`)};
  :hover {
    background-color: ${({ disabled }) => (disabled ? `${colors.veryLightPink}` : `${colors.blue14}`)};
  }
  :active {
    background-color: ${({ disabled }) => (disabled ? `${colors.veryLightPink}` : `${colors.softBlue}`)};
  }
  `;
BlueButton.displayName = 'BlueButton';
