import React from 'react';
import { useSelector } from 'react-redux';
import { selectDetail } from 'store/Contextualization/AssetDetail/selectors';
import SeverityPicker from './components/SeverityPicker';
import DisplayConfirmed from './components/DisplayConfirmed';
import EaseOfResolutionPicker from './components/EaseOfResolutionPicker';
import StatusPicker from './components/StatusPicker';
import Wrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ActionBar/styled';
import ShowRiskScore from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ActionBar/components/ShowRiskScore';

const ActionBar = () => {
  const currentVuln = useSelector((state) => selectDetail('vulns', state));
  return (
    <Wrapper>
      <ShowRiskScore currentVuln={ currentVuln } />
      <SeverityPicker />
      <DisplayConfirmed />
      <EaseOfResolutionPicker />
      <StatusPicker />
    </Wrapper>
  );
};
export default ActionBar;
