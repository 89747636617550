import get from 'lodash/get';
import api from 'services/api';
import { SEND_VULN_TO_TOOL } from './settingsActions';
import { selectShowDetail, selectVulnDetailId } from 'store/Manage/selectors';
import { loadVulnerabilityDetail } from 'store/Manage/actions';
import { UPDATE_VULN_CLIENT_SIDE } from 'store/Manage/types';
import { loadManageDetail } from 'store/Contextualization/AssetDetail/actions';
import { loadVulnDetail } from 'store/ManageGeneral/actions';
import { VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE } from 'store/Contextualization/AssetDetail/types';
import { GENERAL_MANAGE_UPDATE_VULN_CLIENT_SIDE } from 'store/ManageGeneral/types';

export const TOOL_NAME = 'gitlab';

// obtiene datos de ticketing tools
export const GET_SETTINGS_TICKETING_TOOLS_GITLAB_START = 'GET_SETTINGS_TICKETING_TOOLS_GITLAB_START';
export const GET_SETTINGS_TICKETING_TOOLS_GITLAB_FAIL = 'GET_SETTINGS_TICKETING_TOOLS_GITLAB_FAIL';
export const GET_SETTINGS_TICKETING_TOOLS_GITLAB_SUCCESS = 'GET_SETTINGS_TICKETING_TOOLS_GITLAB_SUCCESS';

// guarda los datos
export const SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_START = 'SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_START';
export const SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_SUCCESS = 'SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_SUCCESS';
export const SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_FAIL = 'SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_FAIL';

export const SET_SETTINGS_TICKETING_TOOLS_GITLAB_FIELD = 'SET_SETTINGS_TICKETING_TOOLS_GITLAB_FIELD'; // acutaliza el estado de un campo

export function getGitLabSettings () {
  return async (dispatch) => {
    dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_GITLAB_START });
    try {
      const response = await api.settings.fetchTicketingTools(TOOL_NAME);
      return dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_GITLAB_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_GITLAB_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function saveGitLabSettings () {
  return async (dispatch, getState) => {
    dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_START });
    try {
      const {
        url, accessToken, project, sslVerify, selfSignedCertificate, template
      } = getState().settings.ticketingTools.gitLab;
      const response = await api.settings.saveTicketingToolsGitLab(TOOL_NAME, url, accessToken, project, sslVerify, selfSignedCertificate, template);
      return dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function setField (field, value) {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_TICKETING_TOOLS_GITLAB_FIELD, field, value });
  };
}

export function sendVulnToGitLab () {
  return async (dispatch, getState) => {
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: true, error: false, message: '' } });
    try {
      const { vulnsSelected } = getState().manage;
      const data = { vulns: vulnsSelected };
      const isVulnDetailOpen = selectShowDetail(getState());
      const vulnId = selectVulnDetailId(getState());

      const updatedVulns = await api.settings.sendVulnToGitLab(data);
      updatedVulns.forEach(vuln => {
        dispatch({ type: UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
      });

      if (isVulnDetailOpen) dispatch(loadVulnerabilityDetail(vulnId, true));

      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: false, message: 'Operation was executed successfully.' } });
    } catch (e) {
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: true, message: get(e, 'message', 'There was an error, please try again.') } });
    }
  };
}

export function sendVulnToGitLabContext (vulnsSelected, vulnId, isVulnDetailOpen, entity) {
  return async (dispatch) => {
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: true, error: false, message: '' } });
    try {
      const data = { vulns: vulnsSelected };

      const updatedVulns = await api.settings.sendVulnToGitLab(data);
      updatedVulns.forEach(vuln => {
        if (entity === 'vulns') dispatch({ type: UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
        else if (entity === 'vulnsAssets') dispatch({ type: VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
        else dispatch({ type: GENERAL_MANAGE_UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
      });

      if (isVulnDetailOpen) {
        if (entity === 'vulns') dispatch(loadVulnerabilityDetail(vulnId, true));
        else if (entity === 'vulnsAssets') dispatch(loadManageDetail(vulnId));
        else dispatch(loadVulnDetail(vulnId));
      }
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: false, message: 'Operation was executed successfully.' } });
    } catch (e) {
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: true, message: get(e, 'message', 'There was an error, please try again.') } });
    }
  };
}
