import { copy } from '../../Common/Functions/Copy';
import * as types from './types';
import initialState from './initialState';

function executiveReport (state = initialState, action) {
  switch (action.type) {
    case types.RESET_STATE_REPORT: {
      return {
        ...copy(initialState),
        templates: state.templates
      };
    }
    case types.REMOVE_REPORT_SUCCESS: {
      return {
        ...state,
        reportList: [...state.reportList.filter((report) => report.id !== action.id)],
        reportsCount: state.reportsCount - 1
      };
    }
    case types.EXECUTIVE_REPORT_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.errorMessage
      };
    }
    case types.UPDATE_REPORT_STATUS_START: {
      return {
        ...state
      };
    }
    case types.UPDATE_REPORT_STATUS_SUCCESS: {
      return {
        ...state,
        reportList: action.updatedReportList
      };
    }
    case types.GET_DATA_REPORT_START: {
      return {
        ...state,
        isFetching: true,
        error: false,
        errorMessage: ''
      };
    }

    case types.GET_DATA_REPORT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        reportList: action.reportList,
        reportsCount: action.reportsCount
      };
    }

    case types.SET_EXECUTIVE_REPORT_PAGE: {
      return {
        ...state,
        page: action.page
      };
    }

    case types.SET_EXECUTIVE_REPORT_ORDER_BY: {
      return {
        ...state,
        order_by: action.order_by,
        order_by_dir: action.order_by_dir
      };
    }

    case types.HIDE_MODAL_DELETE_CONFIRMATION_REPORT_SUCCED: {
      return {
        ...copy(initialState)
      };
    }

    case types.DOWNLOAD_REPORT_START: {
      return {
        ...state
      };
    }

    case types.DOWNLOAD_REPORT_SUCCESS: {
      return {
        ...state
      };
    }

    case types.RESET_ERROR_VALUE_REPORT: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }

    case types.CLONE_REPORT_START: {
      return {
        ...state,
        isFetching: false
      };
    }

    case types.CLONE_REPORT_SUCCESS: {
      const newReportList = [
        action.newReport,
        ...state.reportList
      ];

      return {
        ...state,
        reportList: newReportList
      };
    }

    case types.CREATE_REPORT_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.REMOVE_REPORT_START: {
      return {
        ...state
      };
    }

    case types.CREATE_REPORT_FINISH: {
      const reportList = state.reportList;
      const report = action.data;
      report.status = 'processing';
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
        reportList: [report, ...reportList]
      };
    }

    case types.GET_REPORT_TEMPLATES_START: {
      return {
        ...state,
        isFetching: false,
        error: false
      };
    }

    case types.GET_REPORT_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        templates: [
          ...action.templates
        ]
      };
    }

    default: {
      return state;
    }
  }
}

export default executiveReport;
