import React, { useState } from 'react';
import { Tab, TabWrapper } from './styled';

const Tabs = ({ onToggleAgents, showCloudAgents }) => {
  const [showCloudAgentsTab, setShowCloudAgentsTab] = useState(showCloudAgents);

  const handleCloudAgentsTab = () => {
    setShowCloudAgentsTab(true);
    onToggleAgents(true);
  };

  const handleAgentsTab = () => {
    setShowCloudAgentsTab(false);
    onToggleAgents(false);
  };

  return (
    <TabWrapper>
      <Tab isActive={ !showCloudAgentsTab } onClick={ handleAgentsTab }>Agents</Tab>
      <Tab isActive={ showCloudAgentsTab } onClick={ handleCloudAgentsTab }>Cloud Agents</Tab>
    </TabWrapper>
  );
};

export default Tabs;
