import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { redirectToManageDetail, selectVulnRow, unSelectAll } from 'store/Contextualization/AssetDetail/actions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_DUPLICATES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import {
  NameWrapper, Name, MainDuplicateIcon, DuplicateIcon, IconWrapper
} from './styled';
import Skeleton from 'Common/Components/Skeleton';
import { selectShowQuickPlanner } from 'store/Manage/selectors';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import itemTypes from 'Common/Components/DragAndDrop/ItemTypes';

const NameCellVulnAsset = ({ rowInfo, showSkeleton }) => {
  const vulnName = get(rowInfo, 'name', '');
  const vulnId = get(rowInfo, '_id', 0);
  const dispatch = useDispatch();
  const confirmed = get(rowInfo, 'confirmed', false);
  const canUseDuplicates = useSelector((state) => selectAllowedByFeature(state, FEATURE_DUPLICATES, PERMISSION_TYPES.READ) && selectAllowedByRole(state, FEATURE_DUPLICATES, PERMISSION_TYPES.READ));
  const isQuickPlannerOpen = useSelector(selectShowQuickPlanner);

  const DuplicateMark = () => {
    const isMainDuplicate = get(rowInfo, 'duplicates_associated', []).length > 0;
    const isDuplicate = !isEmpty(get(rowInfo, 'duplicates_main', {}));
    if (!isDuplicate && !isMainDuplicate) return null;
    return (
      <IconWrapper>
        { isMainDuplicate ? <MainDuplicateIcon /> : <DuplicateIcon /> }
      </IconWrapper>
    );
  };

  const handleClick = (e) => {
    if (isQuickPlannerOpen) return;
    dispatch(unSelectAll('vulns'));
    dispatch(redirectToManageDetail(vulnId));
    dispatch(selectVulnRow(e, rowInfo));
  };

  const [, drag, preview] = useDrag(() => ({
    type: itemTypes.VULN,
    item: rowInfo,
    collect: () => ({
      canDrag: isQuickPlannerOpen
    }),
    canDrag: isQuickPlannerOpen
  }), [rowInfo]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showSkeleton) return <Skeleton />;
  return (
    <NameWrapper ref={ drag }>
      <Name onClick={ handleClick } confirmed={ confirmed }>{ vulnName }</Name>
      { canUseDuplicates && <DuplicateMark /> }
    </NameWrapper>
  );
};

export default NameCellVulnAsset;
