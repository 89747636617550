import React from 'react';
import { useIntl } from 'react-intl';
import {
  Title, Form, Label, Header, CancelButton, ConfirmButton, Input, WrapperField, Password
} from './styled';

const ConfirmSend = ({
  onClose, onSave, username, setUsername, password, setPassword
}) => {
  const intl = useIntl();

  return (
    <>
      <Header>
        <Title> Basic Auth </Title>
        <CancelButton onClick={ onClose }>Cancel</CancelButton>
        <ConfirmButton onClick={ onSave }>Done</ConfirmButton>
      </Header>
      <Form>
        <WrapperField>
          <Label>{ intl.formatMessage({ id: 'sendToTool.serviceNow.form.username.title' }) }</Label>
          <Input placeholder={ intl.formatMessage({ id: 'sendToTool.serviceNow.form.username.placeholder' }) } value={ username } onChange={ (e) => setUsername(e.target.value) } valid={ username } required />
        </WrapperField>
        <WrapperField>
          <Label>{ intl.formatMessage({ id: 'sendToTool.serviceNow.form.password.title' }) }</Label>
          <Password autoComplete="new-password" placeholder={ intl.formatMessage({ id: 'sendToTool.serviceNow.form.password.placeholder' }) } value={ password } onChange={ (e) => setPassword(e.target.value) } valid={ password } required />
        </WrapperField>
      </Form>
    </>
  );
};

export default ConfirmSend;
