import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Wrapper, Title, TextInput } from './styled';

const TextArea = ({
  required, title, type, placeholder, defaultValue, onChange, disabled, error, ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const parsedTitle = required ? `${title}*` : title;

  useEffect(() => {
    if (value !== defaultValue) setValue(defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, defaultValue]);

  const onChangeDebounce = debounce(onChange, 500);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeCallBack = useCallback(onChangeDebounce, []);

  const onChangeHandler = ({ target }) => {
    setValue(target.value);
    onChangeCallBack(target.value);
  };

  return (
    <Wrapper disabled={ disabled }>
      <Title disabled={ disabled } required={ required } isEmpty={ !value } children={ parsedTitle } />
      <TextInput
        disabled={ disabled }
        error={ error }
        onChange={ onChangeHandler }
        placeholder={ placeholder }
        type={ type }
        value={ value }
        required={ required }
        isEmpty={ !value }
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...props }
      />
    </Wrapper>
  );
};

export default TextArea;

TextArea.propTypes = {
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
};

TextArea.defaultProps = {
  required: false,
  defaultValue: '',
  disabled: false,
  error: '',
  placeholder: '',
  title: '',
  type: 'text'
};
