import styled from 'styled-components';
import { Title } from 'Screens/Settings/common-styled';
import Link from 'Common/Components/Link/styled';

export const Wrapper = styled.div`
  padding: 0px 0 20px 0;
  ${Title} {
    margin-bottom: 20px;
    font-size: 14.5px;
    font-weight: 500;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  flex-flow: wrap;
`;
Content.displayName = 'Content';

export const Test = styled(Link)`
  display: block;
`;
Test.displayName = 'Test';
