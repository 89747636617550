import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showQuickPlanner } from 'store/Manage/actions';
import { selectShowQuickPlanner } from 'store/Manage/selectors';
import { resetTasksStore } from 'store/Tasks/actions';
import { resetProjectStore } from 'store/Projects/actions';
import Projects from './Projects';
import Header from './Header';

import Wrapper from './styled';

const QuickPlanner = () => {
  const show = useSelector(selectShowQuickPlanner);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!show) dispatch(resetTasksStore());
  }, [dispatch, show]);

  useEffect(() => () => {
    dispatch(showQuickPlanner(false));
    dispatch(resetTasksStore());
    dispatch(resetProjectStore());
  }, [dispatch]);

  if (!show) return null;
  return (
    <Wrapper>
      <Header />
      <Projects />
    </Wrapper>
  );
};

export default QuickPlanner;
