/* eslint-disable react/no-children-prop */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectComments } from 'store/Manage/selectors';
import { openModal } from 'store/modals/actions';
import { setSelectedComment } from 'store/Manage/actions';
import isEmpty from 'lodash/isEmpty';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import CommentItem from './components/CommentItem';
import { EmptyComments, CommentsWrapper } from './styled';

const Comments = ({ setCommentToEdit }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const emptyMessage = intl.formatMessage({ id: 'manage.detail.tab.comment.noComments' });
  const comments = useSelector(selectComments);
  const hasComments = !isEmpty(comments);

  const onClickRemove = (comment) => {
    dispatch(setSelectedComment(comment));
    dispatch(openModal('commentDeletion'));
  };

  const onClickEdit = (comment) => {
    setCommentToEdit(comment);
  };

  if (!hasComments) return <EmptyComments children={ emptyMessage } />;
  return (
    <CommentsWrapper>
      {
        [...comments].reverse().map((comment, i) => (
          <CommentItem
            onRemove={ onClickRemove }
            onEdit={ onClickEdit }
            comment={ comment }
            index={ i }
            key={ `comment_${comment.id}` }
          />
        ))
      }
    </CommentsWrapper>
  );
};

Comments.propTypes = {
  setCommentToEdit: PropTypes.func.isRequired
};

export default Comments;
