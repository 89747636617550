import styled from 'styled-components';
import { Input, Title } from 'Screens/Settings/common-styled';

export const Wrapper = styled.div`
  padding: 20px 0 20px 0;
  ${Title} {
    font-size: 14.5px;
    font-weight: 500;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  padding-top: 12px;
  & > :nth-child(odd) {
    margin-right: 10px;
  }
`;
Content.displayName = 'Content';

export const Password = styled(Input).attrs(({
  width: '100%',
  type: 'password'
}))``;
Password.displayName = 'Password';

export const PleaseWait = styled.div`
  padding: 17px 0;
  font-size: 12.5px;
  line-height: 1.12;
  text-align: left;
  color: #63758d;
`;
PleaseWait.displayName = 'PleaseWait';

export const WrapperTitle = styled.div`
  display: flex;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;
WrapperTitle.displayName = 'WrapperTitle';
