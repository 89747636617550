/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, DisabledWrapper, Title, CustomInput } from './styled';

class InputString extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);

    this.state = {
      value: ''
    };
  }

  componentDidMount () {
    if (this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }

  componentDidUpdate (prevProps) {
    const deflt = this.props.defaultValue ? this.props.defaultValue : '';
    const prevDeflt = prevProps.defaultValue ? prevProps.defaultValue : '';
    if (prevDeflt !== deflt) {
      this.setState({ value: deflt });
    }
  }

  onChangeHandler (evt) {
    const { onChange, max } = this.props;
    const value = evt.target.value;
    if (max) {
      const newValue = value.slice(0, max);
      this.setState({ value: newValue });
    } else {
      this.setState({ value: evt.target.value });
    }
    onChange(evt.target.value);
  }

  onBlurHandler (v) {
    if (this.props.hardUpdate) {
      this.props.onChange(v);
    }
  }

  render () {
    const { value } = this.state;
    const {
      placeholder,
      title,
      width,
      height,
      type,
      id,
      max,
      marginBottom,
      renderError,
      autoComplete,
      disabled,
      tooltip
    } = this.props;

    return (
      <Wrapper id={ id } marginBottom={ marginBottom }>
        <Title renderError={ renderError }>{ title }</Title>
        <DisabledWrapper disabled={ disabled } title={ tooltip }>
          <CustomInput
            disabled={ disabled }
            autoComplete={ autoComplete }
            value={ value }
            placeholder={ placeholder }
            max={ max }
            type={ type }
            width={ width }
            height={ height }
            renderError={ renderError }
            onChange={ (e) => this.onChangeHandler(e) }
            onBlur={ (e) => this.onBlurHandler(e.target.value) }
          />
        </DisabledWrapper>
      </Wrapper>
    );
  }
}

InputString.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  hardUpdate: PropTypes.bool,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  max: PropTypes.number,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string
};

InputString.defaultProps = {
  placeholder: 'ie: 1545',
  title: '',
  width: '224px',
  height: '34px',
  hardUpdate: false,
  type: 'text',
  defaultValue: '',
  id: '',
  max: null,
  autoComplete: 'on',
  disabled: false,
  tooltip: ''
};

export default InputString;
