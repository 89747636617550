import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetProjectStore, getProjects } from 'store/Projects/actions';
import { selectQuery } from 'store/Router/selectors';
import {
  getData, closeTaskDetail, openTaskDetail, clearTasksList
} from 'store/Tasks/actions';
import get from 'lodash/get';
import { setHasPendingChanges } from 'store/Router/actions';
import Content from './Content';
import Modals from './Modals';
import Wrapper from './styled';
import ActionBar from './ActionBar';
// import { selectShowLegacyUI } from 'store/Preferences/selectors';

const Projects = () => {
  const dispatch = useDispatch();
  const query = useSelector(selectQuery);
  const projectId = parseInt(get(query, 'projectId', 0), 10);
  const taskId = parseInt(get(query, 'taskId', 0), 10);
  const tabId = get(query, 'tabId', 'general');
  // const showLegacyUI = useSelector(selectShowLegacyUI);

  useEffect(() => {
    if (projectId && taskId) dispatch(openTaskDetail(projectId, taskId, tabId));
    else {
      dispatch(getProjects());
      dispatch(closeTaskDetail());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (projectId && taskId) dispatch(getData(projectId, taskId));
  }, [dispatch, projectId, taskId]);

  useEffect(() => () => {
    dispatch(setHasPendingChanges(false));
    dispatch(resetProjectStore());
    dispatch(clearTasksList());
  }, [dispatch]);

  return (
    <Wrapper showNewUI>
      <ActionBar />
      <Modals />
      <Content />
    </Wrapper>
  );
};

export default Projects;
