import styled from 'styled-components';
import colors from 'Styles/colors';

export const Title = styled.div`
  color: #1c4566;
  font-size: 14.5px;
  font-weight: 600;
  padding-left: 30px;
`;
Title.displayName = 'Title';

export const ExpanderWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  & > :last-child {
    margin-left: auto;
  }
  height: 50px;
  align-items: center;
`;
ExpanderWrapper.displayName = 'ExpanderWrapper';

export const Text = styled.div`
  padding-left: 45px;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  &:after {
    ${({ isMilestone }) => isMilestone && `
      margin-left: 10px;
      background-color: ${colors.blueGrey};
      content: 'Milestone';
      font-size: 12.5px;
      font-weight: 700;
      color: ${colors.white};
      height: 25px;
      width: 79px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
    }
`;
Text.displayName = 'Text';

export const Name = styled.div`
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ isMilestone }) => isMilestone && `
    max-width: 64%;
  `}
  &:hover {
    text-decoration: underline;
  }
`;
Name.displayName = 'Name';

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
NameWrapper.displayName = 'NameWrapper';
