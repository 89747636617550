/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import { selectVulnDetailTags } from 'store/Manage/selectors';
import { TagsWrapper, Tag } from './styled';

const Tags = () => {
  const vulnTags = useSelector(selectVulnDetailTags);

  return (
    <TagsWrapper>
      {
        vulnTags.map((tag, i) => (
          <Tag key={ `${tag}_${i}` }>
            { tag }
          </Tag>
        ))
      }
    </TagsWrapper>
  );
};

export default Tags;
