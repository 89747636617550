import React, { useState, useEffect, useRef } from 'react';
import PropType from 'prop-types';
import debounce from 'lodash/debounce';
import {
  TextArea, Title, Wrapper, Asterisk, Texts, ErrorMessage, Warning
} from './styled';

const DebouncedTextArea = ({
  value, name, disabled, onChange, placeholder, title, mandatory, error, errorMessage, className, boldTitle
}) => {
  const [fieldValue, setFieldValue] = useState('');

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const updateName = useRef(debounce((v) => {
    if (name) onChange(v);
  }, 300));

  const handleChange = (e) => {
    const v = e.target.value;
    setFieldValue(v);
    updateName.current(v);
  };

  return (
    <Wrapper className={ className }>
      <Texts>
        {title && <Title boldTitle={ boldTitle }>{title}</Title>}
        {mandatory && <Asterisk>*</Asterisk>}
      </Texts>
      <TextArea
        resize="false"
        name={ name }
        value={ fieldValue }
        disabled={ disabled }
        onChange={ handleChange }
        isEmpty={ value.length === 0 }
        placeholder={ placeholder }
        error={ error }
      />
      {error && <Warning />}
      {error && <ErrorMessage>{ errorMessage }</ErrorMessage>}
    </Wrapper>
  );
};

DebouncedTextArea.defaultProps = {
  disabled: false,
  placeholder: '',
  mandatory: false,
  error: false,
  className: '',
  boldTitle: false,
  title: '',
  errorMessage: ''
};

DebouncedTextArea.propTypes = {
  value: PropType.string.isRequired,
  name: PropType.string.isRequired,
  disabled: PropType.bool,
  onChange: PropType.func.isRequired,
  placeholder: PropType.string,
  mandatory: PropType.bool,
  error: PropType.bool,
  className: PropType.string,
  boldTitle: PropType.bool,
  title: PropType.string,
  errorMessage: PropType.string
};

export default DebouncedTextArea;
