import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin: 0 0 0 21px;
  grid-row: 2/3;
  overflow: auto;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 27px;

  ${({ showNewUI }) => showNewUI && `
    margin: 0;
    height: 94%;
  `}
`;
Wrapper.displayName = 'Wrapper';

export const WrapperPreview = styled.div`
  padding-bottom: 10px;
  width: 100%;
  background: #fff;
`;
WrapperPreview.displayName = 'WrapperPreview';
