import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 21.5px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
`;
Title.displayName = 'Title';

export const Content = styled.div`
  // display: flex;
  // flex-flow: column nowrap;
  // flex: 1 1 0;
  // & > :first-child {
  //   border-bottom: 1px;
  //   border-bottom-style: solid;
  //   border-bottom-color: #e9e9e9;
  // }
`;
Content.displayName = 'Content';
