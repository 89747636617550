import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateFormatter from 'Common/Components/DateFormatter';
import { FiTrash2 as Remove, FiEdit3 as Edit /* , FiMessageSquare as MessageIcon */ } from 'react-icons/fi';
import { FEATURE_COMMENTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import {
  CommentsItem,
  CommentsAuthor,
  CommentsDate,
  CommentRemove,
  CommentEdit,
  CommentContainer,
  ButtonsContainer
} from './styled';
import EditableMarkdown from '../EditableMarkdown';
import { updateComment } from 'store/Manage/actions';

const UserComment = ({
  comment, isCreator, onRemove
}) => {
  const props = useSelector((state) => ({
    canEdit: selectAllowedByRole(state, FEATURE_COMMENTS, PERMISSION_TYPES.UPDATE),
    canDelete: selectAllowedByRole(state, FEATURE_COMMENTS, PERMISSION_TYPES.DELETE)
  }));

  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const onClickEdit = () => setIsEditing(true);

  return (
    <CommentsItem>
      <CommentContainer>
        <CommentsDate>
          <DateFormatter date={ comment.create_date } />{' - Edited '}<DateFormatter date={ comment.update_date } />
        </CommentsDate>
        <CommentsAuthor>{`${comment.creator}: `}</CommentsAuthor>
        <EditableMarkdown
          onSave={ (value) => dispatch(updateComment(value)) }
          comment={ comment }
          isEditing={ isEditing }
          setIsEditing={ setIsEditing }
          isCreator={ isCreator }
          editAllowedByRole={ props.canEdit }
        />
      </CommentContainer>
      {isCreator &&
      (
        <ButtonsContainer>
          {props.canDelete && (
          <CommentRemove onClick={ () => onRemove(comment) }>
            <Remove color="#bec8d2" size="1.2em" />
          </CommentRemove>
          )}
          {props.canEdit && (
          <CommentEdit onClick={ () => onClickEdit() }>
            <Edit color="#bec8d2" size="1.2em" />
          </CommentEdit>
          )}
        </ButtonsContainer>
      )}
    </CommentsItem>
  );
};

UserComment.propTypes = {
  comment: PropTypes.shape({
    create_date: PropTypes.string.isRequired,
    create_update: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  isCreator: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default UserComment;
