/* eslint-disable react/no-children-prop */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { FormattedMessage, useIntl } from 'react-intl';

const AcceptLabel = ({ agentToDelete }) => {
  const intl = useIntl();
  // Each agent has several executors that can be linked to multiple schedulers.
  // This takes the related schedulers of the executors and count them to change the warning message if needed.
  const executors = get(agentToDelete, 'executors', []);
  const executorsSchedules = executors.map((e) => get(e, 'schedules', []));
  const scheduleCount = uniq(executorsSchedules.flat().map((s) => s.id)).length;

  if (scheduleCount === 0) return <div children={ intl.formatMessage({ id: 'agents.delete.confirmation' }) } />;
  return (
    <FormattedMessage
      id="agents.delete.scheduleConfirmation"
      values={ { scheduleCount } }
    />
  );
};

export default AcceptLabel;
