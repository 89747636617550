import timeSince from 'Common/Functions/timeSince';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { selectCurrentViewMode, selectGraphs, selectIsEmpty } from 'store/Analytics/selectors';
import { GRAPH_GRID_VIEW } from 'store/Analytics/viewMode';
import { graphTypeParsed } from '../Table/cells';
import Options from '../Options';
import {
  Wrapper, CartWrapper, ActionBar, Title, Subtitle, Time
} from './styled';
import { redirect } from 'store/Router/actions';

const Grid = () => {
  const isEmpty = useSelector(selectIsEmpty);
  const graphs = useSelector(selectGraphs);
  const viewMode = useSelector(selectCurrentViewMode);
  if (isEmpty) return null;
  if (viewMode === GRAPH_GRID_VIEW) {
    return (
      <Wrapper>
        {graphs.map((graph) => <GraphCard title={ graph.name } type={ graph.type } description={ graph.description } time={ timeSince(graph.update_date) } id={ graph.id } key={ graph.id } />)}
      </Wrapper>
    );
  }
  return null;
};

export default Grid;

const GraphCard = ({
  title, type, description, time, id
}) => {
  const dispatch = useDispatch();
  const [showActionBar, toggleActionBar] = useState();
  const handleOnMouseEnter = () => toggleActionBar(true);
  const handleOnMouseLeave = () => toggleActionBar(false);
  const typeParsed = graphTypeParsed(type);
  const onClick = () => {
    if (id > 0) dispatch(redirect(`/analytics/${id}`));
  };

  return (
    <CartWrapper onMouseEnter={ handleOnMouseEnter } onMouseLeave={ handleOnMouseLeave } onClick={ onClick }>
      <Subtitle>{typeParsed}</Subtitle>
      <Title>{title}</Title>
      <Subtitle>{description}</Subtitle>
      <Time>{time}</Time>
      { showActionBar &&
        (
        <ActionBar>
          <Options id={ id } name={ title } />
        </ActionBar>
        )}
    </CartWrapper>
  );
};

GraphCard.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};
