import styled from 'styled-components';
import { ReactComponent as _Plus } from 'Images/icon-action-bar-plus.svg';

export const Input = styled.input`
border: none;
padding-right: 35px;
padding-left: 15px;
width: 100%;
height: 34px;
background: #edf2f7;
&:focus {
  background: #ffffff;
}
&::placeholder {
    font-size: 13px;
    color: #1c2243;
    font-weight: 300;
    opacity: 0.7;
  }
  font-size: 13px;
  font-weight: 300;
  color: #1c2243;
`;

export const Plus = styled(_Plus)`
  width: 12px;
  position: absolute;
  right: 0px;
  margin: 11.5px;
  & path {
    fill: #1c2243
  }
`;

export const Wrapper = styled.div`
position: relative;

`;
