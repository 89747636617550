import styled from 'styled-components';
import colors from 'Styles/colors';

import DateInput from './components/DateInput';

export const Wrapper = styled.div`
  user-select: none;
`;
Wrapper.displayName = 'Wrapper';

export const ContentWrapper = styled.div`
  margin-top: 20px;
`;
ContentWrapper.displayName = 'ContentWrapper';

export const DisabledWrapper = styled.div`
 ${({ disabled }) => disabled && 'pointer-events: none;'};
`;
DisabledWrapper.displayName = 'DisabledWrapper';

export const Row = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 20px;
  }
  &:nth-child(2) {
    align-items: unset;
  }
`;
Row.displayName = 'Row';

export const DateWrapper = styled.div`
  display: flex;
  flex: 1 1 0px;
  & > * {
    width: 50%;
  }
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;
Row.displayName = 'Row';

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  &:nth-child(odd) {
    margin-right: 20px;
  }
  ${({ disabled }) => disabled && `
    opacity: 0.5;
    cursor: not-allowed;
`};
`;
Field.displayName = 'Field';

export const ProgressWrapper = styled(Field)`
  flex: 0 0 63px;
`;
ProgressWrapper.displayName = 'ProgressWrapper';

export const Title = styled.div`
  font-size: 12.5px;
  font-weight: 400;
  margin-bottom: 1px;
  color: ${colors.grey17};
`;
Title.displayName = 'Title';

export const Asterisk = styled.span`
  font-size: 12.5px;
  line-height: 2.08;
  color: ${colors.redPink};
  margin-bottom: 1px;
`;
Asterisk.displayName = 'Asterisk';

export const EndDate = styled(DateInput)`
  max-width: 147px;
`;
EndDate.displayName = 'EndDate';
