/* eslint-disable import/prefer-default-export */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Skeleton from 'Common/Components/Skeleton';
import { useIntl } from 'react-intl';
import { setSelectedProject } from 'store/Projects/actions';
import { MODAL_TASKS_CREATE_EDIT } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTask } from 'store/Tasks/actions';
import DateFormatter from 'Common/Components/DateFormatter';
import { selectViewType } from 'store/Projects/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_TASKS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { Add } from '../styled';

export const AddTaskCell = ({
  isFetching, isPivot, rowInfo
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const project = get(rowInfo, 'original', null);
  const dependencies = get(rowInfo, 'original.dependencies', []);
  const addTaskLabel = intl.formatMessage({ id: 'planner.project.addTask' });
  const viewType = useSelector(selectViewType);
  const splitView = viewType.id === 'splitView';
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_TASKS, PERMISSION_TYPES.CREATE));

  const addTask = () => {
    dispatch(setSelectedProject(project));
    dispatch(setSelectedTask({
      id: 0,
      dependencies: [],
      assignees: []
    }));
    dispatch(openModal(MODAL_TASKS_CREATE_EDIT));
  };

  if (isFetching) return <Skeleton />;
  if (!isPivot && !splitView) return <div children={ dependencies.map((d) => d.name).join(', ') } />;
  if (!isPivot && splitView) return <DateFormatter date={ get(rowInfo, 'original.endDate', '') } midDate />;
  return canCreate && <Add title={ addTaskLabel } text={ addTaskLabel } onClick={ addTask } />;
};

AddTaskCell.propTypes = {
  isPivot: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  rowInfo: PropTypes.object.isRequired
};
