import { graphTemplates } from 'store/Analytics/selectors';
import { GRAPH_LIST_VIEW } from 'store/Analytics/viewMode';
import {
  GRAPHS_FAIL, GRAPHS_FETCHING, GRAPHS_GET_ALL, GRAPH_RESET_SELECTED, GRAPH_SAVE_SUCCESS,
  GRAPH_SELECTED, GRAPH_TOGGLE_VIEW, GRAPH_RESET_ERROR, GRAPH_RESET_STATE, GRAPH_SET_TYPE,
  GRAPH_SET_NAME, GRAPH_SET_DESCRIPTION, GRAPH_SET_NOT_CONFIRMED, GRAPH_SET_CLOSED,
  GRAPH_SET_WORKSPACES, GRAPH_SET_HOSTS, GRAPH_SET_FROM, GRAPH_SET_TO, GRAPH_REFRESH_SUCCESS,
  GRAPH_SET_ID, GRAPH_GET_HOSTS, GRAPH_CLOSE_NOTIFICATION, GRAPH_UPDATED, GRAPH_SET_SHOW_TABLE,
  GRAPH_REFRESH_FAIL, GET_CURRENT_WS_DATA, RESET_STATE_GRAPH_UPDATED
} from './constants';

export const initialState = {
  isFetching: false,
  error: false,
  success: false,
  errorMessage: '',
  graphsList: [],
  graphSelected: {
    id: 0,
    name: '',
    description: '',
    workspaces: [],
    hosts: [],
    from: '',
    to: '',
    type: '',
    notConfirmed: false,
    closed: false,
    allowHosts: false,
    allowMultipleWs: false,
    data: {}
  },
  currentViewMode: GRAPH_LIST_VIEW,
  hosts: [],
  graphUpdated: false
};

function analytics (state = initialState, action) {
  switch (action.type) {
    case GRAPHS_FETCHING: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GRAPHS_GET_ALL: {
      return {
        ...state,
        graphsList: action.graphs,
        isFetching: false
      };
    }
    case GRAPHS_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        isFetching: false
      };
    }
    case GRAPH_SELECTED: {
      const template = graphTemplates.find((t) => t.key === action.graph.type);
      return {
        ...state,
        graphSelected: {
          id: action.graph.id,
          name: action.graph.name,
          description: action.graph.description,
          workspaces: action.graph.filters.workspaces,
          hosts: action.graph.filters.hosts,
          from: action.graph.filters.date_from,
          to: action.graph.filters.date_to,
          type: action.graph.type,
          data: action.graph.data,
          notConfirmed: action.graph.filters.only_confirmed,
          closed: action.graph.filters.include_closed,
          allowHosts: template.allowHosts,
          allowMultipleWs: template.allowMultipleWs,
          refreshedAt: action.graph.update_date
        }
      };
    }
    case GRAPH_SAVE_SUCCESS: {
      return {
        ...state,
        success: true,
        isFetching: false
      };
    }
    case GRAPH_RESET_SELECTED: {
      return {
        ...state,
        graphSelected: {
          ...initialState.graphSelected
        }
      };
    }
    case GRAPH_RESET_ERROR: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }
    case GRAPH_TOGGLE_VIEW: {
      return {
        ...state,
        currentViewMode: action.viewMode
      };
    }
    case GRAPH_RESET_STATE: {
      return {
        ...initialState,
        currentViewMode: state.currentViewMode
      };
    }
    case GRAPH_SET_TYPE: {
      return {
        ...initialState,
        graphSelected: {
          ...initialState.graphSelected,
          type: action.graphType,
          allowMultipleWs: action.allowMultipleWs,
          allowHosts: action.allowHosts
        }
      };
    }
    case GRAPH_SET_NAME: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          name: action.name
        }
      };
    }
    case GRAPH_SET_DESCRIPTION: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          description: action.description
        }
      };
    }
    case GRAPH_SET_NOT_CONFIRMED: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          notConfirmed: action.notConfirmed
        }
      };
    }
    case GRAPH_SET_CLOSED: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          closed: action.closed
        }
      };
    }
    case GRAPH_SET_WORKSPACES: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          data: action.workspaces.length === 0 ? {} : state.graphSelected.data,
          workspaces: action.workspaces
        }
      };
    }
    case GRAPH_SET_HOSTS: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          data: action.hosts.length === 0 ? {} : state.graphSelected.data,
          hosts: action.hosts
        }
      };
    }
    case GRAPH_SET_ID: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          id: action.id,
          name: action.name
        }
      };
    }

    case GRAPH_SET_FROM: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          from: action.from
        }
      };
    }
    case GRAPH_SET_TO: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          to: action.to
        }
      };
    }
    case GRAPH_REFRESH_SUCCESS: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          data: {
            ...action.data,
            errors: state.graphSelected.data.errors
          },
          refreshedAt: action.refreshedAt
        },
        graphUpdated: true
      };
    }
    case GRAPH_REFRESH_FAIL: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          data: {
            ...state.graphSelected.data,
            errors: action.errors
          }
        }
      };
    }

    case GRAPH_GET_HOSTS: {
      return {
        ...state,
        hosts: action.hosts
      };
    }
    case GRAPH_CLOSE_NOTIFICATION: {
      if (action.dataType === 'dataNotFound') {
        return {
          ...state,
          graphSelected: {
            ...state.graphSelected,
            data: {
              ...state.graphSelected.data,
              errors: {
                wsErrors: [],
                hostErrors: []
              }
            }
          }

        };
      }
      if (action.dataType === 'error') {
        return {
          ...state,
          error: false,
          errorMessage: ''
        };
      }
      return {
        ...state
      };
    }
    case GRAPH_UPDATED: {
      return {
        ...state,
        graphUpdated: true
      };
    }
    case GRAPH_SET_SHOW_TABLE: {
      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          showTable: action.show
        }
      };
    }
    case GET_CURRENT_WS_DATA: {
      const currentMonthData = { label: action.currentDate, data: action.data };
      const graphData = [currentMonthData, ...state.graphSelected.data.linked_data];
      graphData.pop();

      return {
        ...state,
        graphSelected: {
          ...state.graphSelected,
          data: {
            linked_data: graphData,
            errors: state.graphSelected.data.errors
          }
        },
        graphUpdated: true
      };
    }
    case RESET_STATE_GRAPH_UPDATED: {
      return {
        ...state,
        graphUpdated: initialState.graphUpdated
      };
    }
    default: {
      return state;
    }
  }
}

export default analytics;
