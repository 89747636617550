/* eslint-disable multiline-ternary */
import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { StyledTextarea, StyledMarkdown } from './styled';
import { get } from 'lodash';

const EditableMarkdown = ({ onSave, comment, isEditing, setIsEditing, isCreator, allowedToEdit }) => {
  const parseNewLines = (text) => text.replace(/\n/gi, '\n &nbsp;');

  const [text, setText] = useState(parseNewLines(comment.text));
  const textareaRef = useRef(null);

  const handleClick = () => {
    if (isCreator && allowedToEdit) setIsEditing(true);
  };

  const handleChange = (e) => setText(e.target.value);

  const handleBlur = () => {
    setIsEditing(false);
    const actualCommentText = get(comment, 'text', '');
    if (text && actualCommentText !== text) {
      const newComment = { ...comment, text };
      onSave(newComment);
    }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      const length = textareaRef.current.value.length;
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(length, length);
      adjustTextareaHeight();
    }
  }, [isEditing]);

  useEffect(() => adjustTextareaHeight(), [text]);

  return (
    <>
      {isEditing ? (
        <StyledTextarea
          ref={ textareaRef }
          value={ text }
          onChange={ handleChange }
          onBlur={ handleBlur }
          onInput={ adjustTextareaHeight }
          autoFocus
        />
      ) : (
        <StyledMarkdown onClick={ handleClick }>
          <ReactMarkdown>{text}</ReactMarkdown>
        </StyledMarkdown>
      )}
    </>
  );
};

export default EditableMarkdown;
