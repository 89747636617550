import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import {
  NameCell, TypeCell, DescriptionCell, DateCell, ActionBarCell
} from './cells';

export function getColumns (isFetching) {
  const columns = [{
    Header: () => <FormattedMessage id="analytics.graph.name" />,
    accessor: 'name',
    id: 'name',
    resizable: false,
    Cell: (rowInfo) => <NameCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <FormattedMessage id="analytics.graph.type" />,
    accessor: 'type',
    id: 'type',
    resizable: false,
    width: 310,
    Cell: (rowInfo) => <TypeCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <FormattedMessage id="analytics.graph.description" />,
    accessor: 'description',
    id: 'description',
    resizable: false,
    maxWidth: 350,
    minWidth: 55,
    Cell: (rowInfo) => <DescriptionCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <FormattedMessage id="analytics.graph.date" />,
    accessor: 'date',
    id: 'date',
    resizable: false,
    width: 150,
    headerStyle: { borderRight: '1px dashed transparent' },
    Cell: (rowInfo) => <DateCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <></>,
    resizable: false,
    sortable: false,
    width: 120,
    id: 'actionBar',
    className: 'actionBar',
    headerStyle: { borderRight: '1px dashed transparent' },
    Cell: (rowInfo) => <ActionBarCell isFetching={ isFetching } rowInfo={ rowInfo } />
  }];
  return columns;
}

export function setOrder (sorted, data) {
  const desc = get(sorted, 'length', 0) > 0 && sorted[0].desc;
  const field = get(sorted, 'length', 0) > 0 && sorted[0].id;

  let graphsSorted = {};
  if (desc) {
    graphsSorted = data.sort((a, b) => b[field] - a[field]);
  } else {
    graphsSorted = data.sort((a, b) => a[field] - b[field]);
  }
  return graphsSorted;
}
