import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import TopButtons from './TopButtons';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { Wrapper, Row, Field, TextField, Label, FixedField } from './styled';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { FEATURE_SERVICES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';

const ID = 'technicalInfo';
const PORT_MIN = 0;
const PORT_MAX = 65535;

const TechnicalInfo = ({
  serviceId, selectedService, updateService, loadServiceDetail
}) => {
  const getService = (elem, defaultValue) => get(selectedService, elem, defaultValue);
  const [ports, setPorts] = useState(getService('ports', ''));
  const [protocol, setProtocol] = useState(getService('protocol', ''));
  const [version, setVersion] = useState(getService('version', ''));
  const [status, setStatus] = useState(getService('status', ''));
  const asset = getService('parent_name', '');
  const [validPorts, setValidPorts] = useState(true);
  const statusOptions = [{ name: 'open', desc: 'Open' }, { name: 'closed', desc: 'Closed' }, { name: 'filtered', desc: 'Filtered' }];
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'workspace.wsDetail.TechnicalInfo' });
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_SERVICES, PERMISSION_TYPES.UPDATE));
  const readOnly = useSelector(selectIsReadOnly);
  const canUpdate = !readOnly && allowedByRole;

  const isExpanded = useExpandable('general', ID, true);

  useEffect(() => {
    setPorts(getService('ports', ''));
    setProtocol(getService('protocol', ''));
    setVersion(getService('version', ''));
    setStatus(getService('status', ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService]);

  const validatePort = () => setValidPorts(PORT_MIN <= ports && ports <= PORT_MAX);

  const onChangePorts = (value) => {
    setPorts(value);
    if (value === '') setValidPorts(true);
    else validatePort(value);
  };

  const updatePorts = () => {
    if (validPorts) updateService(serviceId, { ports: ports.toString().split(',') });
  };

  return (
    <ExpandableWrapper id={ ID } title={ title } defaultValue={ isExpanded } tab="general" buttons={ <TopButtons serviceId={ serviceId } loadServiceDetail={ loadServiceDetail } /> }>
      <Wrapper>
        <Row>
          <Field>
            <Label>{ intl.formatMessage({ id: 'service.serviceDetail.asset' }) }</Label>
            <FixedField>
              { asset }
            </FixedField>
          </Field>
          <Field>
            <TextField type="number" min={ PORT_MIN } max={ PORT_MAX } title={ intl.formatMessage({ id: 'service.serviceDetail.port' }) } name="ports" value={ ports } onChange={ onChangePorts } onBlur={ updatePorts } disabled={ !canUpdate } />
          </Field>
        </Row>
        <Row>
          <Field>
            <TextField title={ intl.formatMessage({ id: 'service.serviceDetail.protocol' }) } name="protocol" value={ protocol } onChange={ (value) => setProtocol(value) } onBlur={ () => updateService(serviceId, { protocol }) } disabled={ !canUpdate } />
          </Field>
          <Field>
            <TextField title={ intl.formatMessage({ id: 'service.serviceDetail.version' }) } name="version" value={ version } onChange={ (value) => setVersion(value) } onBlur={ () => updateService(serviceId, { version }) } disabled={ !canUpdate } />
          </Field>
        </Row>
        <Row>
          <Field>
            <Label>{ intl.formatMessage({ id: 'service.serviceDetail.status' }) }</Label>
            <StandardDropdown
              field="status"
              options={ statusOptions }
              updateValue={ (field, value) => updateService(serviceId, { status: value }) }
              placeholder="Status"
              defaultValue={ status }
              width="160px"
              disabled={ !canUpdate }
            />
          </Field>
        </Row>
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default TechnicalInfo;
