/* eslint-disable react/jsx-key */
import React from 'react';
import startCase from 'lodash/startCase';
import {
  VictoryBar, VictoryChart, VictoryAxis, VictoryStack, VictoryLabel, VictoryVoronoiContainer, VictoryTooltip
} from 'victory';
import { max } from 'lodash';
import { Wrapper } from './styled';
import formatNumber from 'Common/Functions/FormatNumber';

const typeByHex = {
  '#a4247a': 'critical',
  '#ea3158': 'high',
  '#f59220': 'medium',
  '#88cc3f': 'low'
};

const hexByType = {
  critical: '#a4247a',
  high: '#ea3158',
  medium: '#f59220',
  low: '#88cc3f'
};

const VulnsPerSeverity = ({ data }) => {
  const height = 250 + (data.length * 25);
  const maxTicks = max(data.map((item) => item.data.total));
  const tickCount = maxTicks < 5 ? maxTicks : 5;

  const styles = {
    colorScale: ['#a4247a', '#ea3158', '#f59220', '#88cc3f'],
    VictoryTooltip: { fontSize: '10px', fill: '#63758d', fontFamily: 'Sequel Sans' },
    flyoutStyle: { stroke: '#d9e4ef', strokeWidth: 1, fill: '#fff' },
    none: { display: 'none' },
    VictoryAxis: {
      grid: {
        fill: '#bec8d2',
        stroke: '#bec8d2',
        strokeDasharray: '10,5',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        pointerEvents: 'painted',
        strokeWidth: tickCount ? '2' : '0'
      },
      tickLabels: {
        fontFamily: 'Sequel Sans',
        fill: '#63758d',
        fontSize: 12
      }
    },
    font1: {
      fontFamily: 'Sequel Sans',
      fill: '#63758d',
      fontSize: 12,
      stroke: '#63758d',
      strokeWidth: 0,
      fontWeight: 600
    },
    font2: {
      tickLabels: {
        fontFamily: 'Sequel Sans',
        fill: '#63758d',
        fontSize: 8,
        stroke: 'transparent',
        fontWeight: 600,
        strokeWidth: 0
      }

    },
    font3: {
      fontFamily: 'Sequel Sans',
      fill: '#63758d',
      fontSize: 12,
      stroke: '#63758d',
      strokeWidth: 0,
      fontWeight: 600
    }

  };

  const getLabels = ({ datum }) => {
    if (!datum) return null;
    const type = typeByHex[datum.style.data.fill];
    const quantity = datum.data[type];
    if (quantity === 0) return null;
    return `${quantity} ${startCase(type)} Vulnerabilities`;
  };
  const types = ['critical', 'high', 'medium', 'low'];

  if (data.length === 1) {
    const formatedData = Object.keys(data[0].data).filter((t) => t !== 'total').map((type) => ({
      label: type,
      value: data[0].data[type]
    }));

    return (
      <Wrapper>
        <VictoryChart
          height={ height }
          domainPadding={ 40 }
          padding={ {
            left: 100, top: 0, right: 0, bottom: 80
          } }
        >
          <VictoryAxis
            label="Vulnerabilities Severity"
            axisLabelComponent={ (
              <VictoryLabel
                dy={ -60 }
                style={ styles.font1 }
              />
          ) }
            style={ styles.font2 }
          />
          <VictoryAxis
            tickCount={ tickCount || 1 }
            tickFormat={ (t) => `${formatNumber(t)}` }
            label="Number of Vulnerabilities"
            axisLabelComponent={ (
              <VictoryLabel
                dy={ 10 }
                style={ styles.font3 }
              />
          ) }
            dependentAxis
            style={ styles.VictoryAxis }
          />

          <VictoryBar
            horizontal
            sortKey="data.total"
            labelComponent={ <VictoryLabel style={ styles.none } /> }
            data={ formatedData }
            x="label"
            y="value"
            barWidth="30"
            style={ { data: { width: 20, fill: ({ datum }) => hexByType[datum.label] } } }
          />
        </VictoryChart>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <VictoryChart
        height={ height }
        domainPadding={ 40 }
        padding={ {
          left: 120, top: 20, right: 0, bottom: 80
        } }
        containerComponent={ (
          <VictoryVoronoiContainer
            labels={ (datum) => getLabels(datum) }
            labelComponent={ (
              <VictoryTooltip
                constrainToVisibleArea
                flyoutStyle={ styles.flyoutStyle }
                flyoutPadding={ {
                  top: 10, right: 12, bottom: 10, left: 11
                } }
                style={ styles.VictoryTooltip }
                cornerRadius={ 2 }
                pointerLength={ 0 }
                centerOffset={ { x: 0, y: 25 } }
              />
            ) }
          />
      ) }
      >
        <VictoryAxis
          label="Vulnerabilities Severity"
          axisLabelComponent={ (
            <VictoryLabel
              dy={ -60 }
              style={ styles.font1 }
            />
          ) }
          style={ styles.font2 }
        />
        <VictoryAxis
          tickCount={ tickCount || 1 }
          tickFormat={ (t) => `${Math.round(t)}` }
          label="Number of Vulnerabilities"
          axisLabelComponent={ (
            <VictoryLabel
              style={ styles.font3 }
              dy={ 20 }
            />
          ) }
          dependentAxis
          style={ styles.VictoryAxis }
        />
        <VictoryStack
          horizontal
          colorScale={ styles.colorScale }
        >
          {types.map((type) => {
            const y = `data.${type}`;
            return (
              <VictoryBar
                sortKey="data.total"
                labelComponent={ <VictoryLabel style={ styles.none } /> }
                data={ data }
                y={ y }
                x="label"
                style={ { data: { width: 20 } } }
              />
            );
          })}
        </VictoryStack>
      </VictoryChart>
    </Wrapper>
  );
};

export default VulnsPerSeverity;
