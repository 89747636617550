import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import AllTemplates from './components/AllTemplates';
import Vulnerabilities from './components/Vulnerabilities';
import Assets from './components/Assets';
import Custom from './components/Custom';
import Default from './components/Default';
import Grouped from './components/Grouped';
import {
  Wrapper,
  Tabs,
  Title,
  Item,
  TabContent,
  Container,
  MenuList,
  Footer,
  FooterLink,
  QuestionMark,
  CollapsedIcon,
  ExpandedIcon
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectTemplates } from 'store/ExecutiveReportEditCreate/selectors';
import { setInputField } from 'store/ExecutiveReportEditCreate/actions';
import get from 'lodash/get';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import parseTemplate from 'store/ExecutiveReportEditCreate/mapping';

const SelectTemplateModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const listTemplates = useSelector(selectTemplates);
  const modalTitle = intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.title' });
  const all = intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.menuOption.all' });
  const vulns = intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.menuOption.vulns' });
  const assets = intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.menuOption.assets' });
  const custom = intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.menuOption.custom' });
  const vulnsDefault = intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.menuOption.vulns.default' });
  const vulnsGrouped = intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.menuOption.vulns.grouped' });
  const docsLabel = intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.docsLabel' });

  const onSelection = (t) => {
    let isTemplateAllowed = true;
    let templateParsed = [];
    if (t.pdfOption) {
      const temp = listTemplates.find((template) => template[1] === t.pdfOption);
      isTemplateAllowed = get(temp, '[2]', false);
      templateParsed = parseTemplate(temp);
    } else {
      const temp = listTemplates.find((template) => template[1] === t.docxOption);
      isTemplateAllowed = get(temp, '[2]', false);
      templateParsed = parseTemplate(temp);
    }

    if (isTemplateAllowed) {
      dispatch(setInputField('selectTemplateTemp', get(templateParsed, '[0]', [])));
    } else {
      dispatch(openModal(MODAL_UPGRADE_LICENSE));
    }
  };

  // eslint-disable-next-line no-use-before-define
  const [visibleComponent, setVisibleComponent] = useState(<AllTemplates title={ all } onClose={ onClose } onSelection={ onSelection } />);
  const [active, setActive] = useState('all');
  const [showSubTabs, toggleSubTabs] = useState(false);

  const handleVulnsTabClick = () => toggleSubTabs(!showSubTabs);

  const click = (key, component) => { setActive(key); setVisibleComponent(component); };

  const menuItems = [
    {
      key: 'all', title: all, canView: true, click: () => { click('all', <AllTemplates title={ all } onClose={ onClose } onSelection={ onSelection } />); }
    },
    {
      key: 'vulns', title: vulns, canView: true, click: () => { click('vulns', <Vulnerabilities title={ vulns } onClose={ onClose } onSelection={ onSelection } />); handleVulnsTabClick(); }
    },
    {
      key: 'vulnsDefault', title: vulnsDefault, canView: showSubTabs, isSubTab: true, click: () => { click('vulnsDefault', <Default title={ vulns } onClose={ onClose } onSelection={ onSelection } />); }
    },
    {
      key: 'vulnsGrouped', title: vulnsGrouped, canView: showSubTabs, isSubTab: true, click: () => { click('vulnsGrouped', <Grouped title={ vulns } onClose={ onClose } onSelection={ onSelection } />); }
    },
    {
      key: 'assets', title: assets, canView: true, click: () => { click('assets', <Assets title={ assets } onClose={ onClose } onSelection={ onSelection } />); }
    },
    {
      key: 'custom', title: custom, canView: true, click: () => { click('custom', <Custom title={ custom } onClose={ onClose } />); }
    }
  ];

  return (
    <Container>
      <Wrapper>
        <Tabs>
          <Title>{ modalTitle }</Title>
          <MenuList>
            { menuItems.filter((i) => i.canView).map((item) => (
              <Item key={ item.key } active={ active === item.key } onClick={ item.click } isSubTab={ item.isSubTab }>
                { item.title }
                { ((item.key === 'vulns') && !showSubTabs) ? <CollapsedIcon /> : null }
                { ((item.key === 'vulns') && showSubTabs) ? <ExpandedIcon /> : null }
              </Item>
            ))}
          </MenuList>
          <Footer>
            <QuestionMark />
            <FooterLink href="https://docs.faradaysec.com/Report-v4/" target="_blank">{ docsLabel }</FooterLink>
          </Footer>
        </Tabs>
        <TabContent>
          {visibleComponent}
        </TabContent>
      </Wrapper>
    </Container>
  );
};
export default SelectTemplateModal;
