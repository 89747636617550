import styled from 'styled-components';
import { ReactComponent as CommentsSVG } from 'Images/icon-comments.svg';

const CopyLinkIcon = styled(CommentsSVG)`
  & g > path {
    fill: #1c4566;
  }
`;
CopyLinkIcon.displayName = 'CopyLinkIcon';

export default CopyLinkIcon;
