
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { FEATURE_VULN_TEMPLATE, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { Trash } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';
import { showVulnTemplateModalDelete } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { selectTemplatesSelected, selectShowDetail } from 'store/KnowledgeBase/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import ModalWarning from 'Common/Components/ModalWarning';

const DeleteButton = () => {
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_VULN_TEMPLATE, PERMISSION_TYPES.DELETE));
  const [showWarning, setShowWarning] = useState(false);
  const vulnTemplatesSelected = useSelector(selectTemplatesSelected);
  const isVulnDetailVisible = useSelector(selectShowDetail);
  const canDelete = !isVulnDetailVisible && allowedByRole;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'vuln templates' });
  const dispatch = useDispatch();

  const handleClick = () => {
    if (canDelete) {
      if (vulnTemplatesSelected.length > 0) dispatch(showVulnTemplateModalDelete());
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } disabled={ !canDelete } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Delete" description={ message } /> }
    </>
  );
};

export default DeleteButton;
