import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as confirmedIcon } from 'Images/confirmed.svg';
import { ReactComponent as SendIcon } from 'Images/icon-action-bar-send.svg';

export const Wrapper = styled.div`
  
`;
Wrapper.displayName = 'Wrapper';

export const Blue = styled.div`
  color: ${colors.blueCerulean};
  display: inline-block;
  margin: 0px 5px;
`;
Blue.displayName = 'Blue';

export const ConfirmedIcon = styled(confirmedIcon)`
  width: 20px;
  height: 12px;
  margin: 0 5px 0 0px;
`;
ConfirmedIcon.displayName = 'ConfirmedIcon';

export const Send = styled(SendIcon)`
height: 17px;
`;

Send.displayName = 'Send';

export const Dropdown = styled.div`
  position: absolute;
  width: 127px;
  box-shadow: 2px 2px 10px 0 #d5dee6;
  background-color: ${colors.white};
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
  z-index: 3;
`;
Dropdown.displayName = 'Dropdown';
