/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  padding: 23px 0px;
`;
Wrapper.displayName = 'Wrapper';

export const Section = styled.div`
  width: 116px;
  height: 50px;
  padding: 17px;
  border-radius: 3px;
  font-size: 13.5px;
  color: ${colors.darkBlueGrey};
  background-color: #ededed5C;
`;
Section.displayName = 'Section';

export const Content = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  width: 100%;
  height: 500px;
  padding: 25px;
  background-color: #ededed5C;
`;
Content.displayName = 'Content';
