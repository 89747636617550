import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { saveJiraSettings } from 'store/Settings/jiraActions';
import { MODAL_JIRA_TEMPLATE } from 'store/modals/modals';
import { closeModal } from 'store/modals/actions';
import { sendTemplateToJira, setprojectConfig } from 'store/Settings/jiraActions';
import {
  ConfirmButton, CancelButton, ButtonContainer
} from './styled';

const Buttons = ({
  isTemplate, ticketConfig, projectKey, disabled
}) => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.settings.ticketingTools.jira);
  const close = () => dispatch(closeModal(MODAL_JIRA_TEMPLATE));
  const setProjectData = () => {
    if (isTemplate) {
      // set template action
      dispatch(sendTemplateToJira(ticketConfig));
    } else {
      // set project data to payload
      dispatch(setprojectConfig(ticketConfig, projectKey));
    }

    if (!error) close();
  };

  return (
    <ButtonContainer>
      <CancelButton onClick={ close }>Cancel</CancelButton>
      <ConfirmButton disabled={ disabled } margin="0 0 0 15px" onClick={ setProjectData }>Done</ConfirmButton>
    </ButtonContainer>
  );
};

export default Buttons;
