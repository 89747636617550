import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Title, dropdownStyle } from '../styled';

const createOption = (label) => ({
  label,
  value: label
});
const components = {
  DropdownIndicator: null
};

const CreatableInput = ({
  onChange, placeholder, defaultValue = [], ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (onChange) onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleKeyDown = (event) => {
    if (!inputValue || value.some((el) => el.label === inputValue)) return;
    if (event.key === 'Enter') {
      setInputValue('');
      setValue([...value, createOption(inputValue)]);
      event.preventDefault();
    }
  };
  const handleChange = (e) => setValue(e || []);

  const handleInputChange = (inputValue) => setInputValue(inputValue);

  return (
    <>
      {props.title && <Title>{props.title}</Title>}
      <CreatableSelect
        styles={ dropdownStyle }
        components={ components }
        inputValue={ inputValue }
        isClearable
        isMulti
        menuIsOpen={ false }
        onChange={ handleChange }
        onInputChange={ handleInputChange }
        onKeyDown={ handleKeyDown }
        placeholder={ placeholder || 'Type something and press enter...' }
        value={ value }
        { ...props }
      />
    </>
  );
};
export default CreatableInput;
