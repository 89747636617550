/* eslint-disable react/jsx-key */
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Wrapper,
  Title,
  CodeWrapper,
  Code
} from './styled';

const SecretCode = ({ onChange }) => {
  const intl = useIntl();
  const [codes, setCodes] = useState([]); // seis elementos vacios.
  const items = [1, 2, 3, 4, 5, 6];
  const refs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()]);

  const handleInputChange = (index) => (event) => {
    const { value } = event.target;
    const newArr = [...codes];
    newArr[index] = value;
    if (index < 5) refs.current[index + 1].current.focus();
    setCodes(newArr);

    if (onChange) onChange(newArr.join(''));
  };
  return (
    <Wrapper>
      <Title>{intl.formatMessage({ id: 'preferences.account.twoFactorAuthentication.secretCode.title' }) }</Title>
      <CodeWrapper>
        { items.map((e, i) => <Code ref={ refs.current[i] } name={ `secret-code-${i}` } onChange={ handleInputChange(i) } />) }
      </CodeWrapper>
    </Wrapper>
  );
};
export default SecretCode;
