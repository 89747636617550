import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import { setPage } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { selectIsReadonly, selectShowDeleteConfirmation, selectShowModalCreate, totalVulnTemplate } from 'store/KnowledgeBase/selectors';
import CreateActions from '../CreateActions';
import EditButton from './components/EditButton';
import DeleteButton from './components/DeleteButton';
import Pagination from 'Common/Components/Pagination';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import RefreshButton from 'Screens/Contextualization/KnowledgeBase/components/RefreshButton';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import AddRemoveColumnsButton from './components/AddRemoveColumnsButton';

const ActionBar = () => {
  const dispatch = useDispatch();
  const readonly = useSelector(selectIsReadonly);
  const showModalCreate = useSelector(selectShowModalCreate);
  const vulnTemplateCount = useSelector(totalVulnTemplate);
  const rowsPerPage = useSelector((state) => selectRowsPerPage('knowledgeBase', state));
  const page = useSelector((state) => selectPage('knowledgeBase', state));
  const hasTemplates = vulnTemplateCount > 0;
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);

  return (
    <Wrapper>
      { showDeleteConfirmation ? <DeleteConfirmationModal /> : null }
      <LeftGroup>
        {!readonly && <CreateActions showModalCreate={ showModalCreate } /> }
        <Separator />
        <Group>
          <EditButton />
          <DeleteButton />
        </Group>
        <Separator />
      </LeftGroup>
      { hasTemplates &&
        <RightGroup>
          <Pagination offset={ page } limit={ rowsPerPage } total={ vulnTemplateCount } onPrev={ onPrev } onNext={ onNext } />
          <Separator />
          <Group>
            <RefreshButton />
            <AddRemoveColumnsButton />
          </Group>
        </RightGroup>
      }
    </Wrapper>
  );
};

export default ActionBar;
