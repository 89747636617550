import api from 'services/api';
import { selectAllowMultipleSessions } from './instanceSettingsSelectors';
import { setError } from 'store/errors/actions';

export const SET_INSTANCE_SETTINGS_SUCCESS = 'SET_INSTANCE_SETTINGS_SUCCESS';
export const SET_INSTANCE_SETTINGS_START = 'SET_INSTANCE_SETTINGS_START';

export const allowMultipleSessions = () => async (dispatch, getState) => {
  const state = getState();
  dispatch({ type: SET_INSTANCE_SETTINGS_START });
  const isAllowed = selectAllowMultipleSessions(state);
  try {
    const response = await api.settings.allowMultipleSessions(!isAllowed);
    return dispatch({ type: SET_INSTANCE_SETTINGS_SUCCESS, response });
  } catch (e) {
    dispatch(setError('There was an error, please try again.'));
  }
};

export const getMultipleSessions = () => async (dispatch) => {
  dispatch({ type: SET_INSTANCE_SETTINGS_START });
  try {
    const response = await api.settings.getMultipleSessions();
    return dispatch({ type: SET_INSTANCE_SETTINGS_SUCCESS, response });
  } catch (e) {
    dispatch(setError('There was an error, please try again.'));
  }
};
