import React from 'react';
import { SplitIcon, ListIcon } from './styled';

const viewTypes = {
  listView: {
    id: 'listView',
    nextViewIntlId: 'planner.project.ganttView',
    nextViewId: 'ganttView',
    nextViewIcon: <SplitIcon />
  },
  ganttView: {
    id: 'ganttView',
    nextViewIntlId: 'planner.project.splitView',
    nextViewId: 'splitView',
    nextViewIcon: <SplitIcon />
  },
  splitView: {
    id: 'splitView',
    nextViewIntlId: 'planner.project.listView',
    nextViewId: 'listView',
    nextViewIcon: <ListIcon />
  }
};

export default viewTypes;
