/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserSelected } from 'store/Users/selectors';
import { selectWorkspaces } from 'store/Faraday/selectors';
import {
  Wrapper, WorkspacesSelectedContainer, WorkspacesUnSelectedContainer, UnSelectWs,
  CustomUnselectMenuItem, BottomSeparator, MultipleUsers
} from './styled';
import SelectedWorkspace from './SelectedWorkspace/index';
import WorkspaceAlreadyAssigned from './WorkspaceAlreadyAssigned/index';

const SubMenuWorkspaces = ({ showing, setWorkspaces }) => {
  const state = useSelector((s) => ({ workspaces: selectWorkspaces(s), userSelected: selectUserSelected(s) }));

  const [workspaceList, setWorkspaceList] = useState([]);
  const [workspacesSelected, setWorkspacesSelected] = useState([]);

  const workspacesAssignedOnAllUsers = state.workspaces.filter((ws) => state.userSelected.every((user) => user.workspaces.includes(ws.name)));

  useEffect(() => {
    setWorkspaceList(state.workspaces.filter((workspace) => !workspacesAssignedOnAllUsers.some((ws) => ws._id === workspace._id)));

    if (!showing) {
      setWorkspacesSelected([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.workspaces, showing]);

  useEffect(() => {
    setWorkspacesSelected([]);
    return () => {
      setWorkspaces([]);
    };
  }, [setWorkspaces, showing]);

  const selectWorkspace = (ws) => {
    const newWsSelected = [...workspacesSelected, ws];
    setWorkspaceList(workspaceList.filter((workspace) => workspace.name !== ws.name));
    setWorkspacesSelected(newWsSelected);
    setWorkspaces(newWsSelected);
  };

  const removeWorkspace = (ws) => {
    const wsSelected = workspacesSelected.filter((workspace) => workspace.name !== ws.name);
    const wsList = [...workspaceList, ws];
    setWorkspaceList(wsList);
    setWorkspacesSelected(wsSelected);
    setWorkspaces(wsSelected);
  };

  const amountOfUsersWithWsAssigned = (aWorkspace) => state.userSelected.filter((usr) => usr.workspaces.includes(aWorkspace.name)).length;
  const amountOfUsersSelected = state.userSelected.length;
  const hasSelectedWorkspaces = workspacesSelected.length > 0;
  const hasWorkspacesAssignedToAllUsers = workspacesAssignedOnAllUsers.length > 0;

  return (
    <Wrapper>
      {amountOfUsersSelected > 1 &&
      (
      <MultipleUsers>
        <p>{`This change will affect ${amountOfUsersSelected} users`}</p>
        <BottomSeparator />
      </MultipleUsers>
      )}

      { (hasSelectedWorkspaces || hasWorkspacesAssignedToAllUsers) &&
        (
          <WorkspacesSelectedContainer>
            {workspacesAssignedOnAllUsers.map((ws) => <WorkspaceAlreadyAssigned workspace={ ws } key={ ws.id } />)}
            {workspacesSelected.map((wsSelected) => (
              <SelectedWorkspace workspace={ wsSelected } onClose={ () => removeWorkspace(wsSelected) } key={ wsSelected.id } />))}
            {workspaceList.length > 0 && <BottomSeparator />}
          </WorkspacesSelectedContainer>
        )}

      {workspaceList.length > 0
        ? (
          <WorkspacesUnSelectedContainer>
            {workspaceList.map((wsUnSelect) => (
              <CustomUnselectMenuItem
                totalUsers={ amountOfUsersSelected }
                usersAsigned={ amountOfUsersWithWsAssigned(wsUnSelect) }
                onClick={ (e) => { e.stopPropagation(); selectWorkspace(wsUnSelect); } }
                key={ wsUnSelect.name }
              >
                <UnSelectWs>
                  {wsUnSelect.name}
                </UnSelectWs>
              </CustomUnselectMenuItem>
            ))}
          </WorkspacesUnSelectedContainer>
          )
        : null}
    </Wrapper>
  );
};

SubMenuWorkspaces.prototypes = {
};

export default SubMenuWorkspaces;
