import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as PrevIcon } from 'Images/prev.svg';
import { ReactComponent as NextIcon } from 'Images/next.svg';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: ${({ small }) => small ? 'baseline' : 'center'};
`;
Wrapper.displayName = 'Wrapper';

export const Summary = styled.div`
  user-select: none;
  margin-right: auto;
  font-size: ${({ small }) => small ? '11.5px' : '13.5px'};
  line-height: 1.04;
  letter-spacing: 0.1px;
  color: ${colors.grey19};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
`;
Summary.displayName = 'Summary';

export const WrapperButton = styled.button`
  border: 0;
  background: transparent;
  width: ${({ small }) => small ? '23px' : '37px'};
  height: ${({ small }) => small ? '23px' : '37px'};
  background-color: transparent;
  margin: 0 0 0 8px;
  &:disabled {
    & > svg {
      cursor: default;
    }
    & > svg circle {
      fill: transparent;
    }
    & > svg path {
      fill: ${colors.grey12};
    }
  }
`;
WrapperButton.displayName = 'WrapperButton';

export const Prev = styled(PrevIcon)`
  width: ${({ small }) => small ? '23px' : '37px'};
  height: ${({ small }) => small ? '23px' : '37px'};
`;
Prev.displayName = 'Prev';

export const Next = styled(NextIcon)`
  width: ${({ small }) => small ? '23px' : '37px'};
  height: ${({ small }) => small ? '23px' : '37px'};
`;
Next.displayName = 'Next';

export const Total = styled.div`

`;
Total.displayName = 'Total';
