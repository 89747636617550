import styled from 'styled-components';
import colors from 'Styles/colors';
import { CheckboxLabel } from 'Screens/Settings/common-styled';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-weight: 500;
  font-size: 14.5px;
  color: ${colors.darkBlueGrey};
  margin-bottom: 10px;
`;
Title.displayName = 'Title';

export const InputWrapper = styled.div`
  width: 122px;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;
InputWrapper.displayName = 'InputWrapper';

export const StyledLabel = styled(CheckboxLabel)`
  margin-left: 13px;
`;
StyledLabel.displayName = 'StyledLabel';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 22px;
`;
CheckboxWrapper.displayName = 'CheckboxWrapper';
