import get from 'lodash/get';
import filter from 'lodash/filter';
import api from 'services/api';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';

import {
  SCHEDULE_FETCHING, SCHEDULE_TABLE_GET_DATA, SCHEDULE_TABLE_SELECT_ROW, SCHEDULE_FAIL, SCHEDULE_TABLE_PAGING_PREVIOUS, SCHEDULE_TABLE_PAGING_NEXT, SCHEDULE_TABLE_SORT_DATA, SCHEDULE_TABLE_RESET, GET_TOTAL_SCHEDULES,
  RUN_SCHEDULE, SCHEDULE_SET_TAGS_ENTITY, SCHEDULE_RESET_TAGS_FIELDS
} from './constants';
import { selectHasAssetsTags, selectHasServicesTags, selectHasVulnTags, selectIsFetching } from './selectors';

export function fetching () {
  return async (dispatch, getState) => {
    const isFetching = selectIsFetching(getState());
    if (!isFetching) dispatch({ type: SCHEDULE_FETCHING, isFetching: true });
  };
}

export function somethingWentWrong () {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_FAIL, message: 'There was an error, please try again.' });
  };
}

export function selectScheduleRow (rowSelectedInfo) {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_TABLE_SELECT_ROW, rowSelectedInfo });
  };
}

export function resetTable () {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_TABLE_RESET });
  };
}

export function getTotalSchedules () {
  return async (dispatch) => {
    const schedules = await api.schedule.fetchTotalSchedules();
    const totalSchedules = filter(schedules.rows, (s) => s.value.active);
    dispatch({ type: GET_TOTAL_SCHEDULES, totalSchedules: get(totalSchedules, 'length', 0) });
  };
}

export function getSchedules () {
  return async (dispatch, getState) => {
    try {
      dispatch(fetching());
      const {
        page, pageSize, sortBy, sortDirection
      } = getState().schedule.table.list;
      const schedulers = await api.schedule.fetchSchedules(page, pageSize, sortBy, sortDirection);
      const cloudSchedulers = await api.schedule.fetchCloudSchedules(page, pageSize, sortBy, sortDirection);
      const allSchedulers = schedulers.rows.concat(cloudSchedulers.rows);
      const data = { rows: allSchedulers.sort((a, b) => a.value.id - b.value.id) };
      dispatch(getTotalSchedules()); // calcula el total de schedules
      dispatch({ type: SCHEDULE_TABLE_GET_DATA, data });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function nextPage () {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_TABLE_PAGING_NEXT });
    dispatch(getSchedules());
  };
}

export function previousPage () {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_TABLE_PAGING_PREVIOUS });
    dispatch(getSchedules());
  };
}

export function sortBy (field, direction) {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_TABLE_SORT_DATA, field, direction });
    dispatch(getSchedules());
  };
}

export function removeSchedule () {
  return async (dispatch, getState) => {
    try {
      dispatch(fetching());
      const { rowsSelected } = getState().schedule.table.selected;

      await Promise.all(rowsSelected.map(async (row) => {
        await api.schedule.removeSchedule(row.id);
        dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.deleteSchedule.name, ACTIONS.deleteSchedule.label, row.id));
      }));

      dispatch(resetTable());
      dispatch(getSchedules());
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function saveSchedule (scheduleId, agentId, crontabName, active, crontab, description, executorId, parameters, workspaces, timezone, tags, type) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(resetTable());
      dispatch(fetching());
      const assetsTags = selectHasAssetsTags(state);
      const vulnsTags = selectHasVulnTags(state);
      const servicesTags = selectHasServicesTags(state);
      const parsedTags = tags ? tags.split(',').map((t) => t.trim()) : [];
      const newTags = [...new Set(parsedTags)];
      const host_tag = assetsTags ? newTags : [];
      const vuln_tag = vulnsTags ? newTags : [];
      const service_tag = servicesTags ? newTags : [];

      if (type === 'cloud_agent') {
        if (scheduleId === 0) {
          const response = await api.schedule.createCloudSchedule(agentId, crontabName, crontab, active, description, parameters, workspaces, timezone, host_tag, vuln_tag, service_tag);
          dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.createSchedule.name, ACTIONS.createSchedule.label, response.id));
          dispatch(getTotalSchedules());
        } else {
          await api.schedule.updateCloudSchedule(scheduleId, agentId, crontabName, crontab, active, description, parameters, workspaces, timezone, host_tag, vuln_tag, service_tag, type);
          dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.editSchedule.name, ACTIONS.editSchedule.label, scheduleId));
        }
      } else {
        if (scheduleId === 0) {
          const response = await api.schedule.createSchedule(agentId, crontabName, active, crontab, description, executorId, JSON.stringify(parameters), workspaces, timezone, host_tag, vuln_tag, service_tag);
          dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.createSchedule.name, ACTIONS.createSchedule.label, response.id));
          dispatch(getTotalSchedules());
        } else {
          await api.schedule.updateSchedule(scheduleId, agentId, crontabName, active, crontab, description, executorId, JSON.stringify(parameters), workspaces, timezone, host_tag, vuln_tag, service_tag);
          dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.editSchedule.name, ACTIONS.editSchedule.label, scheduleId));
        }
      }

      dispatch(getSchedules());
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function changeStatus (scheduleId, status, type) {
  return async (dispatch) => {
    try {
      // if (status && selectLimitReached(getState())) { comento esto por ahora porque no deberia validar el limite cuando se activa/desactiva
      // dispatch(openModal(MODAL_UPGRADE_LICENSE));
      // } else {
      dispatch(fetching());
      if (type === 'cloud_agent') await api.schedule.changeStatusCloud(scheduleId, status);
      else await api.schedule.changeStatus(scheduleId, status);

      dispatch(getTotalSchedules());
      dispatch(getSchedules());
      // }
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function runSchedule (id, type) {
  return async (dispatch) => {
    try {
      if (type === 'cloud_agent') {
        await api.schedule.runCloudSchedule(id);
      } else {
        await api.schedule.runSchedule(id);
      }
      dispatch({ type: RUN_SCHEDULE, id });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function setTagsEntity (entity, value) {
  return (dispatch) => {
    dispatch({ type: SCHEDULE_SET_TAGS_ENTITY, entity, value });
  };
}

export function resetTagsFields () {
  return (dispatch) => {
    dispatch({ type: SCHEDULE_RESET_TAGS_FIELDS });
  };
}
