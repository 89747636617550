import React from 'react';
import Checkbox from 'Common/Components/Checkbox';
import useFilterToggle from 'Hooks/useFilterToggle';
import {
  Text, CheckboxOption, Severity, TagOption
} from '../styled';

export const SeverityFilter = ({ option, filter, entityName }) => {
  const [isFilterActive, toggleFilter] = useFilterToggle(filter, entityName);

  return (
    <Severity type={ isFilterActive ? option : 'disable' } onClick={ toggleFilter }>
      {option}
    </Severity>
  );
};

export const CheckBoxFilter = ({ option, filter, entityName }) => {
  const [isFilterActive, toggleFilter] = useFilterToggle(filter, entityName);

  return (
    <CheckboxOption>
      <Checkbox onChange={ toggleFilter } state={ isFilterActive } />
      <Text onClick={ toggleFilter }>{option}</Text>
    </CheckboxOption>
  );
};

export const TagFilter = ({ name, filter, entityName }) => {
  const [isFilterActive, toggleFilter] = useFilterToggle(filter, entityName);
  return (
    <TagOption state={ isFilterActive } onClick={ toggleFilter }>
      {name}
    </TagOption>
  );
};
