/* eslint-disable indent */
import styled from 'styled-components';

import { ReactComponent as PlusSVG } from 'Images/icon-action-bar-plus.svg';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 34px;
    margin-bottom: 17px;
`;
Wrapper.displayName = 'Wrapper';

export const InputWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 34px;
`;
InputWrapper.displayName = 'Title';

export const PlusIconWrapper = styled.div`
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')} ;
    opacity: ${({ disabled }) => (disabled ? '0.62' : '1')};
    background-color: ${({ isFocused, disabled }) => {
        if (isFocused) return '#fff';
        if (disabled) return '#f5f7f9';
        return '#edf2f7';
    }};
    box-shadow: inset 0 -1px 0 0 #afafaf;
    margin: auto 0px auto auto;
    height: 34px;
    width: 58px;
    >:nth-child(1){
        cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')} ;
    }
`;
InputWrapper.displayName = 'Title';

export const PlusIcon = styled(PlusSVG)`
    margin-top: 11px;
    margin-bottom: 11px;
    height: 12px;
    & > path {
        fill: #afb1bc;
    }
`;
PlusIcon.displayName = 'PlusIcon';
