import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Checkbox from 'Common/Components/Checkbox/styled';
import validate from 'Common/Components/Password/functions';
import { Accept } from 'Common/Components/Button/styled';
import {
  selectStatus, selectChecked, selectMessage, selectError
} from 'store/Settings/twoFactorSelectors';
import {
  getTwoFactor, changeStatusTwoFactor, confirmTwoFactor, disableTwoFactor
} from 'store/Settings/twoFactorActions';
import Error from 'Common/Components/Error';
import Success from 'Common/Components/Success';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_2FA, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import SecretCode from './components/SecretCode';
import {
  Wrapper,
  Title,
  CurrentPassword,
  StyledCol,
  Content
} from './styled';
import QrCode from './components/QrCode';

const TwoFactor = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [secretCode, setSecretCode] = useState('');

  useEffect(() => {
    dispatch(getTwoFactor());
  }, [dispatch]);

  const props = useSelector((state) => ({
    status: selectStatus(state),
    checked: selectChecked(state),
    message: selectMessage(state),
    error: selectError(state),
    allowedByRole: selectAllowedByRole(state, FEATURE_2FA, PERMISSION_TYPES.CREATE) && selectAllowedByFeature(state, FEATURE_2FA, PERMISSION_TYPES.CREATE)
  }));

  const onStatusChange = (event) => {
    dispatch(changeStatusTwoFactor(event.target.checked));
  };

  const onSecretCodeChange = (v) => {
    setSecretCode(v);
  };

  const confirm = () => {
    if (props.status === 'requested') {
      dispatch(confirmTwoFactor(currentPassword, secretCode));
    } else if (props.status === 'confirmed' && currentPassword) {
      dispatch(disableTwoFactor(currentPassword));
    }
  };

  const showAccept = () => {
    if (!props.checked && props.status === 'disabled') return false; // no se muestra
    if (props.checked && props.status === 'disabled') return true; // se muestra
    if (!props.checked && props.status === 'requested') return false; // no se muestra
    if (props.checked && props.status === 'requested') return true; // se muestra
    if (props.checked && props.status === 'confirmed') return false; // no se muestra
    if (!props.checked && props.status === 'confirmed') return true; // se muestra
    return false;
  };

  const showPassword = () => showAccept();

  const showSecretCode = () => props.checked && props.status === 'requested';

  const showQrCode = () => showSecretCode();

  return props.allowedByRole
    ? (
    <Wrapper>
      <Title>
        {intl.formatMessage({ id: 'preferences.account.twoFactorAuthentication.title' }) }
        <Checkbox onChange={ onStatusChange } checked={ props.checked } />
      </Title>
      <Content>
        <StyledCol>
          {showPassword() ? <CurrentPassword title={ intl.formatMessage({ id: 'preferences.account.twoFactorAuthentication.password.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.account.twoFactorAuthentication.password.placeholder' }) } onChange={ (v) => { setCurrentPassword(v); } } valid={ validate(currentPassword) } /> : null }
          {showSecretCode() ? <SecretCode onChange={ onSecretCodeChange } /> : null}
        </StyledCol>
        {showQrCode() ? <QrCode /> : null}
      </Content>
      { props.message && props.error ? <Error error={ props.error }>{props.message}</Error> : null}
      { props.message && !props.error ? <Success error={ props.error }>{props.message}</Success> : null}
      { showAccept() ? <Accept onClick={ confirm }>{intl.formatMessage({ id: 'preferences.account.twoFactorAuthentication.confirm' }) }</Accept> : null}
    </Wrapper>
      )
    : null;
};
export default TwoFactor;
