import styled from 'styled-components';
import { ReactComponent as MainDuplicateSVG } from 'Images/main-duplicate.svg';
import { ReactComponent as DuplicateSVG } from 'Images/duplicate.svg';

export const NameWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
NameWrapper.displayName = 'NameWrapper';

export const Name = styled.div`
  font-weight: ${({ confirmed }) => (confirmed ? '600' : 'normal')}; 
  color: ${({ confirmed }) => (confirmed ? '#1c4566' : '#53686f')}; 
  font-size: 14.5px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;
Name.displayName = 'Name';

export const IconWrapper = styled.div`
  width: 19px;
  height: 19px;
  margin-left: auto;
  cursor: default;
  margin-right: 14px;
`;
IconWrapper.displayName = 'IconWrapper';

export const MainDuplicateIcon = styled(MainDuplicateSVG)`
  cursor: default;
  `;
MainDuplicateIcon.displayName = 'DuplicateIcon';

export const DuplicateIcon = styled(DuplicateSVG)`
  cursor: default;
`;
DuplicateIcon.displayName = 'DuplicateIcon';
