import get from 'lodash/get';
import SAVE_CONFIG from './constants';

// eslint-disable-next-line import/prefer-default-export
export function saveConfig (config) {
  return async (dispatch) => {
    const maxWorkspacesLimit = get(config, 'mws', 0);
    const maxUsersLimit = get(config, 'mus', 0);
    const maxHostsLimit = get(config, 'total_assets', 0);
    const licenseType = get(config, 'type', '');
    const cloudInstance = get(config, 'cloud_instance', false);
    const maxJobsLimit = get(config, 'pipeline_jobs_limit', null); // when nulls => no limit
    const maxSchedulesLimit = get(config, 'agent_scheduler_limit', null); // when nulls => no limit
    const ssoEnabled = get(config, 'sso_enabled', false);
    const ldapUseLocalRoles = get(config, 'ldap_use_local_roles', true);
    const apiVersion = get(config, 'ver', '');
    dispatch({
      type: SAVE_CONFIG, maxWorkspacesLimit, maxUsersLimit, maxHostsLimit, licenseType, cloudInstance, maxJobsLimit, maxSchedulesLimit, ssoEnabled, ldapUseLocalRoles, apiVersion
    });
  };
}
