import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { FEATURE_TAGS, FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import IconButton from 'Common/Components/IconButton';
import { Tag } from '../../styled';
import { setCreationTagModal } from 'Screens/Contextualization/Tags/actions/Actions';
import { useIntl } from 'react-intl';
import ModalWarning from 'Common/Components/ModalWarning';

const TagButton = ({ hasItems, vulnsSelected, tagsEntity }) => {
  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useDispatch();
  const readonly = useSelector((state) => get(state, 'faraday.readonly', false));
  const canCreateTags = useSelector((state) => selectAllowedByRole(state, FEATURE_TAGS, PERMISSION_TYPES.CREATE));
  const canPatchVulns = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.PATCH));
  const canUpdate = !readonly && canCreateTags && canPatchVulns && hasItems;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'vulnerabilities' });

  const handleClick = () => {
    if (canUpdate) {
      if (vulnsSelected.length > 0) dispatch(setCreationTagModal(true, tagsEntity));
      else setShowWarning(true);
    }
  };
  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Tag /> } title="Tags" onClick={ handleClick } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Tags" description={ message } /> }
    </>
  );
};

export default TagButton;
