import React from 'react';
import { PropTypes } from 'prop-types';
import {
  IconWrapper, Trash
} from './styled';

const RemoveItem = ({ removeItem, id }) => (
  <IconWrapper onClick={ () => removeItem(id) }>
    <Trash />
  </IconWrapper>
);

RemoveItem.propTypes = {
  removeItem: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

export default RemoveItem;
