import colors from 'Styles/colors';

export const COLORS_BY_TYPE = {
  project: {
    backgroundColor: colors.lightGrey, progressColor: colors.grey12, backgroundSelectedColor: colors.lightGrey, progressSelectedColor: colors.grey12, color: colors.blueGrey2
  },
  milestone: {
    backgroundColor: colors.purple5, backgroundSelectedColor: colors.purple5, color: colors.blueGrey2
  },
  noTask: {
    backgroundColor: colors.iceBlue, backgroundSelectedColor: colors.iceBlue, color: colors.blueGrey2
  }
};

export const COLORS_BY_PROGRESS = {
  'new': {
    backgroundColor: colors.lightOrange, progressColor: colors.tangerine, backgroundSelectedColor: colors.lightOrange, progressSelectedColor: colors.tangerine, color: colors.blueGrey2
  },
  'in progress': {
    backgroundColor: colors.lightBlue, progressColor: colors.grey2, backgroundSelectedColor: colors.lightBlue, progressSelectedColor: colors.grey2, color: colors.blueGrey2
  },
  'review': {
    backgroundColor: colors.lightYellow, progressColor: colors.paleGold, backgroundSelectedColor: colors.lightYellow, progressSelectedColor: colors.paleGold, color: colors.blueGrey2
  },
  'completed': {
    backgroundColor: colors.apple, progressColor: colors.apple, backgroundSelectedColor: colors.apple, progressSelectedColor: colors.apple, color: colors.blueGrey2
  }
};

export const PERCENTAGE_BY_STATUS = {
  'new': 9,
  'in progress': 50,
  'review': 75,
  'completed': 100
};
