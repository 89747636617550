import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  margin: 14px 0 19px 0;
  max-height: 99px;
  overflow-y: auto;
  display: flex;
  flex-flow: row wrap;
  ::-webkit-scrollbar-track {
    background-color: #d8d8d8;
    border-radius: 3px;
}

`;
Wrapper.displayName = 'Wrapper';

export const Tag = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.06);
  border: solid 1px ${colors.grey12};
  background-color: ${colors.paleGrey};
  padding: 3px 8px 5px 8px;
  height: 25px;
  font-size: 11.5px;
  font-weight: 500;
  color: ${colors.greyBlue};
  margin-right: 12px;
  cursor: pointer;
  margin-bottom: 8px;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
  pointer-events: ${({ isDisable }) => (isDisable ? 'none' : 'auto')};
  &:hover {
    background-color: ${colors.iceBlue};
    color: ${colors.greyBlue};
    border: solid 1px ${colors.grey12};
  }

  &:active {
    background-color: ${colors.grey12};
    border: solid 1px ${colors.blueGrey};
  }

  ${({ added, partialSelected }) => {
    if (added && !partialSelected) {
 return `
        background: ${colors.greyBlue};
        color: ${colors.white};
        border: solid 1px ${colors.greyBlue};`;
} else if (added && partialSelected) {
 return `
        background: ${colors.blueGrey};
        color: ${colors.paleGrey};
        border:solid 1px ${colors.blueGrey};`;
}
  }}
`;
Tag.displayName = 'Tag';
