import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { BlueButton, GreyButton } from './styled';

export const CancelButton = ({ onClick }) => (
  <GreyButton onClick={ onClick }> Cancel </GreyButton>
);

CancelButton.defaultProps = {
  onClick: null
};

CancelButton.propTypes = {
  onClick: PropTypes.func
};

export const CreateButton = ({ disabled, onClick, isEditing }) => {
  const intl = useIntl();
  const text = intl.formatMessage({ id: isEditing ? 'reports.editCreate.header.edit.button' : 'reports.editCreate.header.create.button' });
  return (
    <BlueButton onClick={ onClick } disabled={ disabled }>
      { text }
    </BlueButton>
  );
};

CreateButton.defaultProps = {
  disabled: false,
  onClick: null,
  isEditing: false
};

CreateButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isEditing: PropTypes.bool
};
