import React from 'react';
import Background from './styled';

const ModalWrapper = ({ children, z, className }) => (
  <Background z={ z } className={ className }>
    {children}
  </Background>
);

export default ModalWrapper;
