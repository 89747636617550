import styled from 'styled-components';
import icons from 'Images/cvssicons.svg';
import colors from 'Styles/colors';

const Wrapper = styled.div`
  margin-bottom: 25px;

  #cvssboard {
    text-align: center;
    align-content: center;
  }

  .cvssjs i {
    width: 1em;
    height: 1em;
    display: inline-block;
    margin-right: 4px;
    margin: 0 2px;
    font-size: 25px;
    background-size: cover;
  }

  .cvssjs i.AVN {
    background-position: -3em 0;
  }

  .cvssjs i.AVA {
    background-position: -2em 0;
  }

  .cvssjs i.AVL {
    background-position: -1em 0;
  }

  .cvssjs i.AVP {
    background-position: 0 0;
  }

  .cvssjs i.ACL {
    background-position: -4em 0;
  }

  .cvssjs i.ACH {
    background-position: -5em 0;
  }

  .cvssjs i.PRN {
    background-position: -6em 0;
  }

  .cvssjs i.PRL {
    background-position: -7em 0;
  }

  .cvssjs i.PRH {
    background-position: -8em 0;
  }

  .cvssjs i.UIN {
    background-position: -10em 0;
  }

  .cvssjs i.UIR {
    background-position: -9em 0;
  }

  .cvssjs i.SC {
    background-position: -11em 0;
  }

  .cvssjs i.SU {
    background-position: -10em 0;
  }

  .cvssjs i.CH {
    background-position: -14em 0;
  }

  .cvssjs i.CL {
    background-position: -13em 0;
  }

  .cvssjs i.CN {
    background-position: -12em 0;
  }

  .cvssjs i.IH {
    background-position: -16em 0;
  }

  .cvssjs i.IL {
    background-position: -17em 0;
  }

  .cvssjs i.IN {
    background-position: -15em 0;
  }

  .cvssjs i.AH {
    background-position: -20em 0;
  }

  .cvssjs i.AL {
    background-position: -19em 0;
  }

  .cvssjs i.AN {
    background-position: -18em 0;
  }

  .metrics {
    display: flex;
    flex-wrap: wrap;
    max-width: 696px;
    margin: 0 auto;
  }

  dl {
    padding: 16px 0 21px 0;
    background-color: ${colors.iceBlue};
    width: 164px;
    height: max-content;
    margin: 5px;
    transition: background-color 0.3s ease-in-out;
  }

  dt {
    color: ${colors.darkBlueGrey};
    font-size: 13.5px;
    font-weight: 400;
    font-family: 'CentraNo2';
    background-color: ${colors.iceBlue};
    padding: 0 0 9px 0;
    overflow-x: hidden;
    text-transform: uppercase;
  }

  dd {
    margin: 0 18px;
    padding: 0px;
    text-align: left;
  }

  .cvssjs i {
    background-repeat: no-repeat;
    background-image: url(${icons});
    margin-right: 11px;
  }

  input[type=radio] + label:before {
    display: none;
  }

  input[type=radio]:checked + label:after {
    display: none;
  }

  label {
    color: ${colors.darkBlueGrey};
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    background-color: ${colors.iceBlue};
    margin: 0;
    padding: 7px 0 8px 11px;
    cursor: pointer;
    min-width: 7.5em;
    margin-top: 0px;
    transition: background-color 0.3s ease-in-out;
  }

  label:hover {
    color: ${colors.white};
  }
  .cvssjs dd:nth-child(2) input:checked + label,
  .cvssjs dd:nth-child(2) label:hover {
    background-color: ${colors.warmPurple};
  }

  .cvssjs dd input:checked + label,
  .cvssjs dd label:hover 
    {
    background-color: ${colors.redPink};
    transition: background-color 0.1s ease-in-out;
    color: #FFF;
  }

  .cvssjs dd:last-child input:checked + label,
  .cvssjs dl.AV dd:nth-child(4) input:checked + label,
  .cvssjs dl.PR dd:last-child label:hover,
  .cvssjs dd:last-child label:hover,
  .cvssjs dl.AV dd:nth-child(4) label:hover {
    background-color: ${colors.tangerine};
  }

  .cvssjs dl.C dd:last-child input:checked + label,
  .cvssjs dl.I dd:last-child input:checked + label,
  .cvssjs dl.A dd:last-child input:checked + label,
  .cvssjs dl.C dd:last-child label:hover,
  .cvssjs dl.I dd:last-child label:hover,
  .cvssjs dl.A dd:last-child label:hover {
    background-color: ${colors.apple}
  }

  .old-results {
    display: none;
  }

  .severity-score-vector {
    padding: 0;
  }

  .results {
    display:flex;
  }

  .cvssjs dd small {
    display: none;
  }

  .cvssjs dd:hover small {
    position: absolute;
    max-width: 220px;
    border: solid 1px ${colors.lightGrey};
    display: block;
    color: ${colors.greyBlue};
    background-color: ${colors.white};
    border-radius: 2px;
    margin-top: .7em;
    margin-left: 5em;
    padding: 10px;
    z-index: 1;
  }

  ul {
    margin-left: 20px;
  }
`;
Wrapper.displayName = 'Wrappper';

export default Wrapper;
