import React from 'react';
import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';
import { TableWrapper, TabWrapper, Tab } from './styled';
import ActionBar from './components/ActionBar';
import getColumns from './getColumns';
import { useDispatch, useSelector } from 'react-redux';
import { selectActionToConfirm, selectIsFetching, selectShowActionModal, selectTab, selectUserTokens } from 'store/Settings/accessTokens/selectors';
import ActionModal from './components/ActionModal';
import { setOrderByUserTokens, setSelectedTab } from 'store/Settings/accessTokens/actions';

const Table = () => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(selectTab);
  const tokens = useSelector(selectUserTokens);
  const isFetching = useSelector(selectIsFetching);
  const showActionModal = useSelector(selectShowActionModal);
  const actionToConfirm = useSelector(selectActionToConfirm);
  const mockData = [...Array(8).keys()];

  const handleTabClick = (tabName) => dispatch(setSelectedTab(tabName));

  const fetchData = (tableState) => !isFetching && dispatch(setOrderByUserTokens(tableState.sorted));

  const getThProps = (column) => {
    let border = '1px dashed #b7b7b7';

    if (column.id === 'actions') border = 'none';
    return {
      style: {
        borderRight: border
      }
    };
  };
  return (
    <TableWrapper>
      <TabWrapper>
        <Tab active={ selectedTab === 'active' } onClick={ () => handleTabClick('active') }>Active</Tab>
        <Tab active={ selectedTab === 'inactive' } onClick={ () => handleTabClick('inactive') }>Inactive</Tab>
      </TabWrapper>
      <ActionBar tab={ selectedTab } />
      <CustomReactTable
        data={ isFetching ? mockData : tokens }
        columns={ getColumns(selectedTab, isFetching) }
        minRows={ 0 }
        manual
        onFetchData={ fetchData }
        style={ { height: '80%', boxShadow: '-8px 14px 20px 0px rgba(0, 0, 0, 0.03)' } }
        getTheadThProps={ (_, __, column) => getThProps(column) }
        showPagination={ false }
      />
      { showActionModal && <ActionModal action={ actionToConfirm } /> }
    </TableWrapper>
  );
};

export default Table;
