import React from 'react';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_REPORTING, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectIsFetching,
  selectPage, selectReportList, selectReportsCount, selectRowsPerPage
} from 'store/ExecutiveReport/selectors';
import { setPage } from 'store/ExecutiveReport/actions';
import Pagination from 'Common/Components/Pagination';
import CreateButton from './components/CreateButton';
import Wrapper from './styled';

const ActionBar = () => {
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_REPORTING, PERMISSION_TYPES.CREATE));
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);
  const data = useSelector(selectReportList);
  const rowsPerPage = useSelector(selectRowsPerPage);
  const page = useSelector(selectPage);
  const reportsCount = useSelector(selectReportsCount);
  const hasData = data.length > 0;
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));

  return (
    <Wrapper>
      {canCreate && <CreateButton />}
      <Pagination offset={ (hasData || isFetching) ? page : null } limit={ rowsPerPage } total={ reportsCount } onPrev={ onPrev } onNext={ onNext } />
    </Wrapper>
  );
};
export default ActionBar;
