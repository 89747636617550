import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as FilterSVG } from 'Images/shape.svg';
import { ReactComponent as DeleteSVG } from 'Images/icon-trash-red.svg';
import { ReactComponent as EditSVG } from 'Images/icon-edit-enabled.svg';
import { ReactComponent as EvidenceSVG } from 'Images/icon-evidence.svg';
import { ReactComponent as TemplateSVG } from 'Images/template.svg';
import { ReactComponent as StatusSVG } from 'Images/status.svg';
import { ReactComponent as SeveritySVG } from 'Images/icon_severity.svg';
import { ReactComponent as TagSVG } from 'Images/icon-tag.svg';
import { ReactComponent as CommentsSVG } from 'Images/icon-comments.svg';
import { ReactComponent as CopyLinkSVG } from 'Images/copy_link.svg';
import { ReactComponent as CopyApiLinkSVG } from 'Images/api_link.svg';
import { ReactComponent as SaveTemplateSVG } from 'Images/save_template.svg';
import SeverityComponent from 'Common/Components/SeverityIcon';

export const SeverityLetter = styled(SeverityComponent)`
    margin: auto 13px auto 0px;
    & > path {
        fill: ${colors.blueGrey};
    }
`;
SeverityLetter.displayName = 'SeverityLetter';

export const FilterIcon = styled(FilterSVG)`
    margin: auto 13px auto 0px;
    & > path {
        fill: ${colors.blueGrey};
    }
`;
FilterIcon.displayName = 'FilterIcon';

export const DeleteIcon = styled(DeleteSVG)`
    margin: auto 13px auto 0px;
    width: 20px;
    height: 20px;
`;
DeleteIcon.displayName = 'DeleteIcon';

export const EditIcon = styled(EditSVG)`
    margin: auto 14px auto 0px;
    & > path {
        fill: ${colors.blueGrey};
    }
`;
EditIcon.displayName = 'EditIcon';

export const StatusIcon = styled(StatusSVG)`
    margin: auto 15px auto 0px;
    & > path {
        fill: ${colors.blueGrey};
    }
`;
StatusIcon.displayName = 'StatusIcon';

export const SeverityIcon = styled(SeveritySVG)`
    margin: auto 12px auto 0px;
    & > path {
        fill: ${colors.blueGrey};
    }
`;
SeverityIcon.displayName = 'SeverityIcon';

export const EvidenceIcon = styled(EvidenceSVG)`
    margin: auto 12px auto 0px;
    & > path {
        fill: ${colors.blueGrey};
        stroke: ${colors.blueGrey};
    }
`;
EvidenceIcon.displayName = 'EvidenceIcon';

export const TemplateIcon = styled(TemplateSVG)`
    margin: auto 13px auto 0px;
    & g {
        & path{
            fill: ${colors.blueGrey};
        }  
    }
`;
TemplateIcon.displayName = 'TemplateIcon';

export const TagIcon = styled(TagSVG)`
    margin: auto 13px auto 0px;
    & g {
        & path{
            fill: ${colors.blueGrey};
        }  
    }
`;
TagIcon.displayName = 'TagIcon';

export const CommentsIcon = styled(CommentsSVG)`
    margin: auto 13px auto 0px;
    & g {
        & path{
            fill: ${colors.blueGrey};
        }  
    }
`;
CommentsIcon.displayName = 'CommentsIcon';

export const CopyLinkIcon = styled(CopyLinkSVG)`
    margin: auto 10px auto 3px;
    & > path {
        fill: ${colors.blueGrey};
    }
`;
CopyLinkIcon.displayName = 'CopyLinkIcon';

export const CopyApiLinkIcon = styled(CopyApiLinkSVG)`
    margin: auto 10px auto 0px;
    & > path {
        fill: ${colors.blueGrey};
    }
`;
CopyApiLinkIcon.displayName = 'CopyApiLinkIcon';

export const SaveTemplateIcon = styled(SaveTemplateSVG)`
    margin: 3px 10px auto 0;
`;
SaveTemplateIcon.displayName = 'SaveTemplateIcon';
