/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import DragFile from 'Common/Components/DragFile';
import FileUploader from 'Common/Components/FileUploader';
import { FaPlus as Plus } from 'react-icons/fa';
import {
  Button,
  ButtonsContainer,
  PullLeft,
  BackButton
} from 'Common/Components/MainContainer/styled';
import './styles.scss';
import FilesList from 'Common/Components/FilesList';
import UploadSummary from 'Common/Components/UploadSummary';
import ModalWrapper from '../ModalWrapper';
import { Wrapper, Title, BackBtn, CLink, AddMore } from './styled';
import { FormattedMessage } from 'react-intl';

class ImportFileModal extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      files: [],
      step: 'DRAG',
      dragCount: 0
    };
    this.onHandleClose = this.onHandleClose.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.removeFileFromList = this.removeFileFromList.bind(this);
    this.uploadFileManage = this.uploadFileManage.bind(this);
  }

  componentDidMount () {
    this.props.setUploadState('');
  }

  componentDidUpdate () {
    if (this.state.step === 'UPLOAD' && this.props.uploadState === 'PROCESSED' && this.props.fileIndex >= 0) {
      this.props.uploadFile(this.state.files[this.props.fileIndex]);
    }

    if (this.state.step === 'UPLOAD' && this.props.uploadState === 'FINISHED' && this.props.fileIndex === -1 && (this.props.currentUploadError === '' || this.state.files.length > 1)) {
      this.setState({ step: 'SUMMARY' });
    }
  }

  handleDragIn () {
    const { dragCount } = this.state;
    this.setState({ dragCount: dragCount + 1 });
  }

  handleDragOut () {
    const { dragCount } = this.state;
    this.setState({ dragCount: dragCount + 1 });
  }

  onHandleClose () {
    this.setState({ files: [] });
    this.setState({ step: 'DRAG' });
    this.props.resetFileUploadState();
    this.props.handleClose();
  }

  handleDrop (files) {
    const fileList = this.state.files;
    for (let i = 0; i < files.length; i += 1) {
      if (!files[i].name) return;
      if (fileList.findIndex((item) => (item.name === files[i].name && item.size === files[i].size)) === -1) {
        fileList.push(files[i]);
      }
    }
    this.setState({ files: [...fileList] });
    if (fileList.length > 0) {
      this.setState({ step: 'LIST' });
    }

    this.props.setFileIndex(fileList.length - 1);
    this.props.setUploadState('');
  }

  removeFileFromList (file) {
    const fileList = [];
    const files = this.state.files;
    for (let i = 0; i < files.length; i += 1) {
      if (files[i].name !== file.name || files[i].size !== file.size) {
        fileList.push(files[i]);
      }
    }
    this.setState({ files: fileList });
    if (fileList.length === 0) {
      this.setState({ step: 'DRAG' });
    }
  }

  uploadFileManage () {
    this.props.setFilesCount(this.state.files);
    if (this.props.fileIndex >= 0) {
      this.props.uploadFile(this.state.files[this.props.fileIndex]);
    }
  }

  render () {
    const {
      show, errorFilesList, title, transactional
    } = this.props;
    const { files, step } = this.state;

    let child = null;
    switch (step) {
      case 'DRAG':
        child = <DragFile onManage handleDrop={ this.handleDrop } multiple />;
        break;
      case 'LIST':
        child = <FilesList files={ files } removeFile={ this.removeFileFromList } handleDrop={ this.handleDrop } />;
        break;
      case 'UPLOAD':
        child = (
          <FileUploader
            files={ files }
            uploadFile={ this.uploadFileManage }
            currentUploadState={ this.props.currentUploadState }
            currentUploadPercent={ this.props.currentUploadPercent }
            currentUploadError={ this.props.currentUploadError }
            filenames={ files.map((f) => (f.name)).join(', ') }
          />
        );
        break;
      case 'SUMMARY':
        child = <UploadSummary transactional={ transactional } files={ files } errorFilesList={ errorFilesList } />;
        break;
      default:
        child = <DragFile onManage handleDrop={ this.handleDrop } multiple />;
        break;
    }

    if (!show) return null;
    return (
      <ModalWrapper>
        <Wrapper>
          <PullLeft children={ <Title>{ title }</Title> } />
          { child }
          { step === 'DRAG' && <CLink children={ <a href="https://docs.faradaysec.com/Vulnerability-Templates-KB/" target="_black" children={ <FormattedMessage id="importFileCreationModal.info" /> } /> } /> }
          { step === 'LIST' &&
          (
          <AddMore onClick={ () => { this.setState({ step: 'DRAG' }); } }>
            <Plus />
            Add more files
          </AddMore>
          ) }
          <ButtonsContainer>
            { step === 'UPLOAD' && <BackButton className="margin-top-22" onClick={ () => { this.setState({ step: 'LIST' }); } }>Go Back</BackButton> }
            { step === 'SUMMARY' ? <Button className="margin-top-22" onClick={ () => { this.onHandleClose(); } }>Done</Button> : <Button className="margin-top-22" onClick={ () => { this.setState({ step: 'UPLOAD' }); } } disabled={ step !== 'LIST' || files.length === 0 }>Upload</Button> }
            <BackBtn className="margin-top-22" onClick={ () => { this.onHandleClose(); } }>Cancel</BackBtn>
          </ButtonsContainer>
        </Wrapper>
      </ModalWrapper>
    );
  }
}

ImportFileModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  errorFilesList: PropTypes.array.isRequired
};

export default withRouter(ImportFileModal);
