import colors from 'Styles/colors';
import styled from 'styled-components';
import { Row, Col } from 'Common/Components/Grid';

export const Title = styled.label`
  user-select: none;
  color: ${colors.dark2};
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
  margin-top: 9px;
`;
Title.displayName = 'Title';

export const Text = styled.span`
  display: inline;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => (props.isSysComment ? colors.grey19 : colors.black2)};
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  h1 {
    font-size: 21px;
  }
  h2 {
    font-size: 17px;
  }
  h3 {
    font-size: 15px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 13px;
  }
  h6 {
    font-size: 12px;
  }
`;
Text.displayName = 'Text';

export const StyledRow = styled(Row)`
  & > *:not(:last-child) {
    margin-right: 4px;
  }
`;
StyledRow.displayName = 'StyledRow';

export const Comments = styled(Col)`
  & > *:not(:last-child) {
    border-bottom: dashed 1px #dee5ed;
    margin-bottom: 14px;
    padding-bottom: 14px;
  }
`;
Comments.displayName = 'Comments';

export const Comment = styled(Col)`
  margin-bottom: 4px;
  cursor: pointer;
  display: flex;
`;
Comment.displayName = 'Comment';

export const Time = styled.span`
  color: ${colors.grey10};
  font-size: 12px;
  font-weight: 400;
`;
Time.displayName = 'Time';

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

Texts.displayName = 'Texts';

export const Empty = styled.div`
  user-select: none;
  color: ${colors.grey5};
  display: inline;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
`;
Empty.displayName = 'Empty';
