import styled from 'styled-components';
import colors from 'Styles/colors';
import { SubMenu } from 'react-contextmenu';

export const Wrapper = styled.div`

    .react-contextmenu {
      width: 269px;
      box-shadow: 2px 2px 10px 0 #d5dee6;
      border-radius: 2px;
      box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.14);
      border: solid 1px #cdd5dd;
      background-color: #ffffff;
    }
`;
Wrapper.displayName = 'Wrapper';

export const MenuItemContent = styled.div`
`;
MenuItemContent.displayName = 'MenuItemContent';

export const Text = styled.div`
  display: inline-block;
  font-size: 13.5px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
`;
Text.displayName = 'Text';

export const Icon = styled.img`
  width: 18px;
  height: 17px;
  display: inline-block;
  margin-right: 13px;
`;
Icon.displayName = 'Icon';

export const IconActiveInactive = styled(Icon)`
  margin-bottom: 2px;
`;
IconActiveInactive.displayName = 'IconActiveInactive';

export const IconEdit = styled(Icon)`
  margin-bottom: 2px;
`;
IconEdit.displayName = 'IconEdit';

export const ArrowIcon = styled.div`
  width: 10px;
  height: 6px;
  margin-right: 34px;
  margin-top: 3px;
  display: inline-block;
  float: right;
  color: ${colors.blueGrey};
`;
ArrowIcon.displayName = 'ArrowIcon';

export const SubMenuStatus = styled(SubMenu)`
    width: 118px !important;
    height: 85px !important;    
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

SubMenuStatus.displayName = 'SubMenuStatus';

export const StatusText = styled.div`
  color: ${colors.grey19};
  padding-left: 13px;
  font-weight: 300;
  font-size: 13px;
  display: inline-block;
`;
StatusText.displayName = 'StatusText';
