import React, { useState } from 'react';
import {
  Section, MetricGroup, MetricGroupTitle,
  Metrics, MetricWrapper, Metric, MetricTitle, Options, OptionWrapper, Option, Tooltip
} from './styled';

const OptionItem = ({ m, i, handleClick, option, isSelected }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const onMouseEnter = () => {
    if (option.title) setShowTooltip(true);
  };

  const onMouseLeave = () => {
    if (option.title) setShowTooltip(false);
  };
  return (
    <OptionWrapper>
      <Option
        onClick={ () => handleClick(m.short, option.value) }
        position={ option.title ? i : '' }
        isSelected={ isSelected }
        onMouseEnter={ onMouseEnter }
        onMouseLeave={ onMouseLeave }
      >
        {option.title}
      </Option>
      { showTooltip ? <Tooltip>{ option.tooltip }</Tooltip> : null }
    </OptionWrapper>

  );
};

const CalculatorSection = ({ info, handleClick, vectorMetrics }) => {
  return (
    <Section key={ info.metricType }>
        { info.data.metric_groups.map((metricGroup) => (
            <MetricGroup key={ `${info.metricType}_${metricGroup.title}` }>
              <MetricGroupTitle>{metricGroup.title}</MetricGroupTitle>
              <Metrics>
                { metricGroup.metricGroupData.map((m) =>
                <MetricWrapper key={ `${info.metricType}_${metricGroup.title}_${m.metric}` }>
                <Metric><MetricTitle>{m.metric}</MetricTitle></Metric>
                  <Options>
                    { m.options.map((option, i) =>
                      <OptionItem
                        m={ m }
                        i={ i }
                        handleClick={ handleClick }
                        option={ option }
                        key={ `${info.metricType}_${metricGroup.title}_${m.short}-${option.value}` }
                        isSelected={ vectorMetrics[0]?.[m.short] === option.value }
                      />
                    )}
                  </Options>
                </MetricWrapper>
                )
                }
              </Metrics>
            </MetricGroup>
        )
        )
      }
    </Section>
  );
};

export default CalculatorSection;
