import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addTag, removeTag, setVulnsTags } from 'store/Manage/actions';
import { selectTags, orderedTags, selectTagsWithFilter } from 'store/Tags/selectors';
import { selectSelectAll, selectVulnsSelected } from 'store/Manage/selectors';
import { selectIsFiltering } from 'store/Filters/selectors';
import TagsModal from 'Common/Components/TagsModal';

const CreateEditTagsModal = ({ vulnsTags, entity }) => {
  const wsTags = useSelector(selectTags);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const tags = useSelector((state) => orderedTags(state, vulnsTags, vulnsSelected));
  const dispatch = useDispatch();
  const [tempTags, setTempTags] = useState([]);
  const filterTags = useSelector((state) => selectTagsWithFilter(state, tempTags));
  const selectAll = useSelector(selectSelectAll);
  const isFiltering = useSelector(state => selectIsFiltering('vulns', state));
  const tagsList = (selectAll || (selectAll && isFiltering)) ? filterTags : tags;

  const onClickTag = (tag) => {
    if (tag.partialSelected) {
      dispatch(addTag(tag.name));
    } else {
      if (tag.addedToVuln) dispatch(removeTag(tag.name));
      else dispatch(addTag(tag.name));
    }
  };

  const addTags = (newTags) => {
    setTempTags(newTags);
    dispatch(setVulnsTags(newTags));
  };
  return (
    <TagsModal tags={ tagsList } onClickTag={ onClickTag } addTags={ addTags } allTags={ wsTags } entity={ entity } />
  );
};

export default CreateEditTagsModal;
