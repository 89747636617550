import styled from 'styled-components';
import colors from 'Styles/colors';

export const WorkspacesSelectedContainer = styled.div`
`;
WorkspacesSelectedContainer.displayName = 'WorkspacesSelectedContainer';

export const WorkspacesUnSelectedContainer = styled.div`
`;
WorkspacesUnSelectedContainer.displayName = 'WorkspacesUnSelectedContainer';

export const UnSelectWs = styled.div`
`;
UnSelectWs.displayName = 'UnSelectWs';

export const SelectedWs = styled.div`
  color: ${colors.primaryColor};
  font-weight: 300;
`;
SelectedWs.displayName = 'SelectedWs';

const CustomMenuItem = styled.div`
  width: fit-content;
  border-radius: 2px;
  border: solid 1px ${colors.veryLightPink};
  color: ${colors.grey19};
  margin-top: 3px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 3px;
  margin-left: 12px;
  height: 29px;
  background: 0 0;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  text-align: inherit;
  white-space: nowrap;
  outline: none;
  font-size: 14px;

  &:hover {
    background-color: ${colors.iceBlue};
  }
`;
CustomMenuItem.displayName = 'CustomMenuItem';

export const CustomUnselectMenuItem = styled(CustomMenuItem)`
  background-color: ${({ usersAsigned, totalUsers }) => {
    if (usersAsigned === 0) return colors.primaryColor;
    if (totalUsers === usersAsigned) return colors.grey19;
    return colors.grey12;
  }};

  color: ${({ usersAsigned, totalUsers }) => {
    if (usersAsigned === 0) return '';
    if (totalUsers === usersAsigned) return '#ffffff';
    return '';
  }};

  &:hover {
    color: ${({ usersAsigned, totalUsers }) => {
    if (usersAsigned === 0) return '';
    if (totalUsers === usersAsigned) return colors.grey19;
    return '';
  }};
  }

  font-weight: 300;
`;
CustomUnselectMenuItem.displayName = 'CustomUnselectMenuItem';

export const CustomSelectedMenuItem = styled(CustomMenuItem)`
  background-color: ${colors.grey19};
  display: inline-block;

  &:hover {
    background-color: ${colors.grey19}
  }
`;
CustomSelectedMenuItem.displayName = 'CustomSelectedMenuItem';

export const WorkspaceAlreadyAssignedStyles = styled(CustomMenuItem)`
  background-color: ${colors.grey19};
  display: inline-block;
  pointer-events: none;
  cursor: auto;
`;
WorkspaceAlreadyAssignedStyles.displayName = 'WorkspaceAlreadyAssignedStyles';

export const Wrapper = styled.div`
  max-height: 250px;
  height: fit-content;
  padding-bottom: 4px !important;
  overflow: scroll;

  .react-contextmenu{
    width: 230px !important;
  }

  .react-contextmenu-item{
    height: 29px !important;
    padding-bottom: 0px !important;
    padding-top: 3px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const MultipleUsers = styled.div`
  margin-top: 8px;
  text-align: center;
  p {
      font-size: 13px;
      color: ${colors.greyBlue};
  }

`;
MultipleUsers.displayName = 'MultipleUsers';

export const BottomSeparator = styled.div`
  border-bottom: 1px solid ${colors.grey23};
  width: 183px;
  margin-left: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
`;
BottomSeparator.displayName = 'BottomSeparator';

export const IconContainer = styled.div`
  color: ${colors.grey19};
  display: inline-block;
  float: right;
  margin-right: 16px;
  margin-top: 6px;
  width: 23px;
  height: 23px;
  padding-left: 3.5px;
  padding-top: 3px;

  &:hover{
    background-color: ${colors.iceBlue};
    border-radius: 50%;
  }

  svg {
    height: 12px;
  }
`;
IconContainer.displayName = 'IconContainer';

export const Container = styled.div`
  display: block;
`;
Container.displayName = 'Container';
