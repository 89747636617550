import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Label, ExpandedIcon, CollapsedIcon,
  RemoveIcon, ChildContainer, SubtitleLabel
} from './styled';

export const Title = ({ label }) => (
  <Label>
    { label }
  </Label>
);

export const Subtitle = ({ label }) => (
  <SubtitleLabel>
    { label }
  </SubtitleLabel>
);

export const Icons = ({
  canExpand, isExpanded, isRemovable, onRemove
}) => (
  <>
    { canExpand && (isExpanded ? <ExpandedIcon /> : <CollapsedIcon />)}
    { canExpand && isRemovable && <RemoveIcon onClick={ onRemove } />}
  </>
);

export const Child = ({ isExpanded, children }) => (
  <ChildContainer isExpanded={ isExpanded }>
    { children }
  </ChildContainer>
);

Title.propTypes = { label: PropTypes.string };
Title.defaultProps = { label: '' };

Subtitle.propTypes = { label: PropTypes.string };
Subtitle.defaultProps = { label: '' };

Icons.propTypes = {
  isRemovable: PropTypes.bool,
  canExpand: PropTypes.bool,
  onRemove: PropTypes.func,
  isExpanded: PropTypes.bool
};

Icons.defaultProps = {
  isRemovable: true,
  canExpand: true,
  onRemove: null,
  isExpanded: false
};

Child.propTypes = { isExpanded: PropTypes.bool, children: PropTypes.instanceOf(Object) };
Child.defaultProps = { isExpanded: false, children: null };
