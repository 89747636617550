/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useIntl } from 'react-intl';
import ContextSubMenu from 'Common/Components/ContextMenu/components/ContextSubMenu';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnsSelected } from 'store/Manage/selectors';
import {
  setBulkUpdateField, setBulkUpdateValue, showContextMenu, updateVuln
} from 'store/Manage/actions';
import { PropTypes } from 'prop-types';
import { selectGroupByField } from 'store/Filters/selectors';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission';
import { MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import { SeverityIcon, SeverityLetter } from './styled';

const SeverityItem = ({ title }) => {
  const dispatch = useDispatch();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const oneVulnSelected = vulnsSelected.length === 1;
  const isBulk = vulnsSelected.length > 1;
  const selectedVuln = vulnsSelected[0];
  const capitalizedSeverity = title.charAt(0).toUpperCase() + title.slice(1);
  const letter = capitalizedSeverity.charAt(0);

  const applySeverity = () => {
    if (isBulk) {
      dispatch(setBulkUpdateField('severity'));
      dispatch(setBulkUpdateValue(title));
      dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    } else dispatch(updateVuln(selectedVuln, 'severity', title, true));
    dispatch(showContextMenu(false));
  };

  return (
    <MenuItem
      isSelected={ oneVulnSelected && selectedVuln.severity === title }
      title={ capitalizedSeverity }
      icon={ (
        <SeverityLetter title={ capitalizedSeverity } type={ letter }>
          { letter }
        </SeverityLetter>
      ) }
      onClick={ applySeverity }
    />
  );
};

SeverityItem.propTypes = { title: PropTypes.string.isRequired };

export const Severity = () => {
  const groupBy = useSelector((state) => selectGroupByField('vulns', state));
  const intl = useIntl();

  if (groupBy === 'severity') return null;
  return (
    <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
      <ContextSubMenu
        title={ intl.formatMessage({ id: 'manage.cm.severity' }) }
        icon={ <SeverityIcon /> }
      >
        <SeverityItem title="critical" />
        <SeverityItem title="high" />
        <SeverityItem title="medium" />
        <SeverityItem title="low" />
        <SeverityItem title="informational" />
        <SeverityItem title="unclassified" />
      </ContextSubMenu>
    </WithPermission>
  );
};
