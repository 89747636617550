import React from 'react';
import { useDragLayer } from 'react-dnd';
import ItemTypes from './ItemTypes';
import Vuln from './itemsPreview';

function getItemStyles (initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
};

const CustomDragLayer = () => {
  const {
    itemType, isDragging, item, initialOffset, currentOffset
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }));
  function renderItem () {
    switch (itemType) {
      case ItemTypes.VULN:
        return <Vuln data={ item } />;
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={ layerStyles }>
      <div
        style={ getItemStyles(initialOffset, currentOffset) }
      >
        {renderItem()}
      </div>
    </div>
  );
};

export default CustomDragLayer;
