import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useClickOutside from 'Hooks/useClickOutside';
import IconButton from 'Common/Components/IconButton';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import {
  setBulkUpdateField, setBulkUpdateValue, updateVuln
} from 'store/Manage/actions';
import { MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import {
  setBulkUpdateField as setBulkUpdateFieldVulnsAsset,
  setBulkUpdateValue as setBulkUpdateValueVulnsAsset,
  updateVulnFromAsset
} from 'store/Contextualization/AssetDetail/actions';
import { Wrapper, StatusIcon, Dropdown } from './styled';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import {
  setBulkUpdateField as setBulkUpdateFieldGeneral,
  setBulkUpdateValue as setBulkUpdateValueGeneral,
  updateVulnGeneral
} from 'store/ManageGeneral/actions';

const StatusItem = ({ type, handleDropdown, vulnsSelected, entity }) => {
  const selectedVuln = vulnsSelected[0];
  const dispatch = useDispatch();
  const intl = useIntl();
  const oneVulnSelected = vulnsSelected.length === 1;
  const isBulk = vulnsSelected.length > 1;

  const applyStatusFromAsset = () => {
    if (isBulk) {
      dispatch(setBulkUpdateFieldVulnsAsset('status'));
      dispatch(setBulkUpdateValueVulnsAsset(type));
      dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    } else dispatch(updateVulnFromAsset(selectedVuln, 'status', type));
    handleDropdown();
  };

  const applyStatus = () => {
    if (isBulk) {
      dispatch(setBulkUpdateField('status'));
      dispatch(setBulkUpdateValue(type));
      dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    } else dispatch(updateVuln(selectedVuln, 'status', type, true));
    handleDropdown();
  };

  const applyStatusGeneral = () => {
    if (isBulk) {
      dispatch(setBulkUpdateFieldGeneral('status'));
      dispatch(setBulkUpdateValueGeneral(type));
      dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    } else dispatch(updateVulnGeneral(selectedVuln, 'status', type));
    handleDropdown();
  };

  const update = {
    vulns: () => applyStatus(),
    vulnsAssets: () => applyStatusFromAsset(),
    vulnsGeneral: () => applyStatusGeneral()
  };

  return (
    <MenuItem
      isSelected={ oneVulnSelected && selectedVuln.status === type }
      title={ intl.formatMessage({ id: `manage.cm.status.${type}` }) }
      onClick={ update[entity] }
    />
  );
};

const Status = ({ hasVulns, groupBy, vulnsSelected, entity }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const intl = useIntl();
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'vulnerabilities' });
  const canUpdate = !readOnly && (groupBy !== 'status') && allowedByRole && hasVulns;

  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setShowDropdown(false));

  const handleDropdown = () => setShowDropdown(!showDropdown);

  const handleClick = () => {
    if (canUpdate) {
      if (vulnsSelected.length > 0) handleDropdown();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <Wrapper ref={ dropdownRef }>
      <IconButton icon={ <StatusIcon /> } title={ intl.formatMessage({ id: 'manage.cm.status' }) } onClick={ handleClick } disabled={ !canUpdate } />
      {showDropdown && (
      <Dropdown>
        <StatusItem type="open" handleDropdown={ handleDropdown } entity={ entity } vulnsSelected={ vulnsSelected } />
        <StatusItem type="closed" handleDropdown={ handleDropdown } entity={ entity } vulnsSelected={ vulnsSelected } />
        <StatusItem type="re-opened" handleDropdown={ handleDropdown } entity={ entity } vulnsSelected={ vulnsSelected } />
        <StatusItem type="risk-accepted" handleDropdown={ handleDropdown } entity={ entity } vulnsSelected={ vulnsSelected } />
      </Dropdown>
      )}
       { showWarning && <ModalWarning onCloseCallback={ onClose } title={ intl.formatMessage({ id: 'manage.cm.status' }) } description={ message } /> }
    </Wrapper>

  );
};

export default Status;
