import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { selectMaxSchedulesLimit } from 'store/Config/selector';

export const selectFields = (state) => get(state, 'schedule.table.fields', []); // obtiene el listado de campos para la tabla

export const selectIsFetching = (state) => get(state, 'schedule.isFetching', false);

export const selectData = (state) => get(state, 'schedule.table.list.data', []);

export const selectTotal = (state) => get(state, 'schedule.table.list.total', 0);

export const selectRowsSelected = (state) => get(state, 'schedule.table.selected.rowsSelected', []);

export const selectLastRowSelected = (state) => get(state, 'schedule.table.selected.lastRowSelected', {});

export const selectLastIndexForRange = (state) => get(state, 'schedule.table.selected.lastIndexForRange', {});

export const selectLastSelected = (state) => get(state, 'schedule.table.selected.lastSelected', {});

export const selectWorkspaceSelected = (state) => get(state, 'faraday.workspaceSelected', {});

// selecciono el primer item del array, corresponde al ultimo row seleccionado en la tabla
export const selectAgentExecutors = (state) => get(state, 'schedule.table.selected.rowsSelected[0].value.agent.executors', {});

// devuelve el total de schedules activos
export const selectTotalSchedules = (state) => {
  const schedules = get(state, 'schedule.table.list.data.rows', []);
  return schedules.length;
};

export const selectLimitReached = (state) => {
  const total = selectTotalSchedules(state);
  const limit = selectMaxSchedulesLimit(state);
  if (!limit) return false;
  return (total >= limit);
};

export const selectHasVulnTags = (state) => {
  const vulnTags = get(state, 'schedule.table.selected.lastRowSelected.value.vuln_tag', []);
  const vulnTagsChecked = get(state, 'schedule.vulnsTagsChecked', false);
  return !isEmpty(vulnTags) || vulnTagsChecked;
};

export const selectHasAssetsTags = (state) => {
  const assetTags = get(state, 'schedule.table.selected.lastRowSelected.value.host_tag', []);
  const assetsTagsChecked = get(state, 'schedule.assetsTagsChecked', false);
  return !isEmpty(assetTags) || assetsTagsChecked;
};

export const selectHasServicesTags = (state) => {
  const serviceTags = get(state, 'schedule.table.selected.lastRowSelected.value.service_tag', []);
  const serviceTagsChecked = get(state, 'schedule.servicesTagsChecked', false);
  return !isEmpty(serviceTags) || serviceTagsChecked;
};
