import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 21px 24px;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.06);
  border: solid 1px ${colors.grey32};
  background-color: ${colors.white};
  margin-top: 8px; 

  &:hover {
  border: solid 1px ${colors.blueCerulean};
  cursor: pointer;
  background-color: ${colors.white1};
  }
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 13.5px;
  font-weight: 600;
  color: ${colors.black};
  margin-bottom: 13px;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
font-size: 12.5px;
line-height: 1.28;
color: ${colors.grey19};
`;
Subtitle.displayName = 'Subtitle';
