import get from 'lodash/get';

export const selectError = (state) => get(state, 'settings.ticketingTools.gitLab.error', false);

export const selectMessage = (state) => get(state, 'settings.ticketingTools.gitLab.message', '');

export const selectUrl = (state) => get(state, 'settings.ticketingTools.gitLab.url', '');

export const selectProject = (state) => get(state, 'settings.ticketingTools.gitLab.project', '');

export const selectAccessToken = (state) => get(state, 'settings.ticketingTools.gitLab.accessToken', '');

export const selectSslVerify = (state) => get(state, 'settings.ticketingTools.gitLab.sslVerify', false);

export const selectSelfSignedCertificate = (state) => get(state, 'settings.ticketingTools.gitLab.selfSignedCertificate', false);

export const selectTemplate = (state) => get(state, 'settings.ticketingTools.gitLab.template', '');
