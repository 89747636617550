import filter from 'lodash/filter';
import get from 'lodash/get';
import size from 'lodash/size';
import cloneDeep from 'lodash/cloneDeep';
import defaultFields from 'store/Host/fields';
import { updatePreferences } from 'store/Preferences/actions';
import { selectFields } from './selectors';

export function setFieldVisibility (field, value) {
  return async (dispatch, getState) => {
    let newAssetTable = cloneDeep(selectFields(getState()));
    let host_table = selectFields(getState());

    const enrichmentFields = ['risk'];
    const isEnrichmentField = enrichmentFields.includes(field);

    const enrichmentFieldsOrder = { risk: 3 };

    if (!host_table) host_table = { ...defaultFields };
    if (!host_table[field].locked) {
      host_table[field].visible = value;

      let fieldOrder;

      if (value) {
        if (isEnrichmentField) {
          fieldOrder = enrichmentFieldsOrder[field];
        } else {
          fieldOrder = Object.keys(host_table).filter((key) => host_table[key].visible).length;
        }
      } else {
        let cont = 1;
        Object.keys(host_table).filter((f) => host_table[f].visible).sort((a, b) => (host_table[a].order < host_table[b].order)).forEach((f2) => {
          host_table[f2].order = cont;
          cont += 1;
        });
      }

      newAssetTable = {
        ...newAssetTable,
        [field]: {
          order: fieldOrder,
          visible: value
        }
      };

      dispatch(updatePreferences({ host_table_new: newAssetTable }));
    }
  };
}

export function setNewOrderColumns (columns) {
  return async (dispatch, getState) => {
    let host_table = selectFields(getState());
    if (!host_table) host_table = { ...defaultFields };

    const fixedColumns = size(filter(host_table, { locked: true }));

    columns.forEach((column) => {
      if (column) {
        if (!get(host_table, `${column}.locked`, false)) {
          host_table[column].order = columns.indexOf(column) + 1 + fixedColumns;
        }
      }
    });

    dispatch(updatePreferences({ host_table_new: host_table }));
  };
}

export function resetDefault () {
  return async (dispatch) => {
    dispatch(updatePreferences({ host_table_new: defaultFields }));
  };
}
