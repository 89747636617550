import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import head from 'lodash/head';
import { useIntl } from 'react-intl';
import {
  FEATURE_COMMENTS, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { selectVulnsSelected } from 'store/Manage/selectors';
import { showVulnerabilityDetail, showBulkUpdateModal } from 'store/Manage/actions';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import IconButton from 'Common/Components/IconButton';
import CommentsIcon from './styled';
import ModalWarning from 'Common/Components/ModalWarning';

const AddComment = ({ hasVulns }) => {
  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.cm.addComment' });
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'vulnerability' });
  const vulnsSelected = useSelector(selectVulnsSelected);
  const selectedVulnID = get(head(vulnsSelected), '_id', null);
  const isBulk = vulnsSelected.length > 1;
  const canReadComments = useSelector((state) => selectAllowedByRole(state, FEATURE_COMMENTS, PERMISSION_TYPES.READ));
  const canUpdateComment = useSelector((state) => selectAllowedByRole(state, FEATURE_COMMENTS, PERMISSION_TYPES.UPDATE));
  const readOnly = useSelector(selectIsReadOnly);
  const canShowModal = !readOnly && canReadComments && hasVulns;

  const openModal = () => {
    if (selectedVulnID) {
      if (isBulk && canUpdateComment) dispatch(showBulkUpdateModal('comment', ''));
      if (!isBulk) dispatch(showVulnerabilityDetail(selectedVulnID, 'comment'));
    }
  };

  const handleClick = () => {
    if (canShowModal) {
      if (vulnsSelected.length > 0) openModal();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <CommentsIcon /> } title={ title } onClick={ handleClick } disabled={ !canShowModal } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title={ title } description={ message } /> }
    </>
  );
};

export default AddComment;
