import TABS from 'Screens/Contextualization/KnowledgeBase/components/VulnTemplateDetail/components/Tabs/components/TabSelector/tabs';
import get from 'lodash/get';
import selectRoutes from 'store/Workspace/selectors';

export const selectKnowledgeBaseProps = (state) => {
  const showDeleteConfirmation = get(state, 'knowledgeBase.showDeleteConfirmation', {});
  const templatesSelected = get(state, 'knowledgeBase.templatesSelected', {});
  const readonly = get(state, 'faraday.readonly', false);
  const showModalCreate = get(state, 'knowledgeBase.showModalCreate', false);
  const routeList = selectRoutes(state);
  const totalVulnTemplate = get(state, 'knowledgeBase.totalVulnTemplate', 0);
  const rowsPerPage = get(state, 'filters.knowledgeBase.rowsPerPage', 0);
  const page = get(state, 'filters.knowledgeBase.page', 0);
  const updated = get(state, 'knowledgeBase.updated', false);
  return {
    showDeleteConfirmation,
    templatesSelected,
    readonly,
    showModalCreate,
    routeList,
    totalVulnTemplate,
    rowsPerPage,
    page,
    updated
  };
};

export const selectKnowledgeBaseTableProps = (state) => {
  const templatesSelected = get(state, 'knowledgeBase.templatesSelected', {});
  const data = get(state, 'knowledgeBase.vulnTemplateList', []);
  const lastTemplateSelected = get(state, 'knowledgeBase.vulnTemplateList', {});
  const lastIndexForRange = get(state, 'knowledgeBase.lastIndexForRange', -1);
  const lastSelected = get(state, 'knowledgeBase.lastSelected', -1);
  const isFetching = get(state, 'knowledgeBase.isFetching', false);
  return {
    templatesSelected, data, lastTemplateSelected, lastIndexForRange, lastSelected, isFetching
  };
};

export const selectTemplateEditCreate = (state) => {
  const name = get(state, 'knowledgeBaseEditCreate.name', '');
  const description = get(state, 'knowledgeBaseEditCreate.description', '');
  const exploitation = get(state, 'knowledgeBaseEditCreate.exploitation', '');
  const policyviolations = get(state, 'knowledgeBaseEditCreate.policyviolations', []);
  const accountability = get(state, 'knowledgeBaseEditCreate.accountability', false);
  const availability = get(state, 'knowledgeBaseEditCreate.availability', false);
  const confidentiality = get(state, 'knowledgeBaseEditCreate.confidentiality', false);
  const integrity = get(state, 'knowledgeBaseEditCreate.integrity', false);
  const errorMessage = get(state, 'knowledgeBaseEditCreate.errorMessage', false);
  const external_id = get(state, 'knowledgeBaseEditCreate.external_id', false);
  const references = get(state, 'knowledgeBaseEditCreate.references', false);
  const resolution = get(state, 'knowledgeBaseEditCreate.resolution', false);
  const easeofresolution = get(state, 'knowledgeBaseEditCreate.easeofresolution', '');
  const data = get(state, 'knowledgeBaseEditCreate.data', false);
  const id = get(state, 'knowledgeBaseEditCreate.id', 0);
  const customfields = get(state, 'knowledgeBaseEditCreate.customfields', {});
  const cve = get(state, 'knowledgeBaseEditCreate.cve', []);
  const cvss2 = get(state, 'knowledgeBaseEditCreate.cvss2', '');

  return {
    name,
    description,
    exploitation,
    policyviolations,
    accountability,
    availability,
    confidentiality,
    integrity,
    errorMessage,
    external_id,
    references,
    resolution,
    easeofresolution,
    data,
    id,
    customfields,
    cve,
    cvss2
  };
};

export const selectReadonly = (state) => {
  const readonly = get(state, 'faraday.readonly', false);
  return { readonly };
};

export const selectRowsPerPage = (state) => get(state, 'knowledgeBase.rowsPerPage', 0);

export const selectFilters = (state) => get(state, 'knowledgeBase.filters', '');

export const selectField = (state, field) => get(state, `knowledgeBaseEditCreate.customfields.${field.field_name}`, '');

export const templatesSelected = (state) => get(state, 'knowledgeBaseEditCreate', {});

export const selectTemplatesSelected = (state) => get(state, 'knowledgeBase.templatesSelected', []);

export const vulnTemplatesList = (state) => get(state, 'knowledgeBase.vulnTemplateList', []);

export const totalVulnTemplate = (state) => get(state, 'knowledgeBase.totalVulnTemplate', 0);

export const lastSelected = (state) => get(state, 'knowledgeBase.lastSelected', -1);

export const allVulnTemplatesAreSelected = (state) => {
  const vulnTemplates = vulnTemplatesList(state);
  const vulnTemplatesSelected = selectTemplatesSelected(state);
  return vulnTemplates.every((testTemplate) => vulnTemplatesSelected.some((template) => template._id === testTemplate._id));
};

export const someVulnTemplateIsSelected = (state) => {
  return selectTemplatesSelected(state).length > 0;
};

export const selectShowDeleteConfirmation = (state) => get(state, 'knowledgeBase.showDeleteConfirmation', {});
export const selectShowModalCreate = (state) => get(state, 'knowledgeBase.showModalCreate', false);
export const selectRouteList = (state) => selectRoutes(state);
export const selectPage = (state) => get(state, 'filters.knowledgeBase.page', 0);
export const selectUpdated = (state) => get(state, 'knowledgeBase.updated', false);
export const selectIsReadonly = (state) => get(state, 'faraday.readOnly', false);
export const selectVulnTemplatePermissions = (state) => get(state, 'faraday.permissions.vulnerability_template', {});

export const selectLastIndexForRange = (state) => get(state, 'knowledgeBase.lastIndexForRange', -1);
export const selectIsFetching = (state) => get(state, 'knowledgeBase.isFetching', false);
export const selectShowDetail = (state) => get(state, 'knowledgeBase.showVulnTemplateDetail', false);
export const selectVulnTemplateDetail = (state) => get(state, 'knowledgeBase.vulnTemplateDetail', []);
export const selectVulnTemplateDetailId = (state) => get(state, 'knowledgeBase.vulnTemplateDetail.id', 0);
export const selectVulnTemplateDetailImpacts = (state) => get(state, 'knowledgeBase.vulnTemplateDetail.impact', []);
export const selectTemplateDetailSelectedTabId = (state) => get(state, 'knowledgeBase.vulnTemplateDetailSelectedTab', 'general');
export const selectTemplateDetailSelectedTab = (state) => {
  const selectedTabId = selectTemplateDetailSelectedTabId(state);
  const defaultTab = TABS.find((tab) => tab.id === 'general');
  const selectedTab = TABS.find((tab) => tab.id === selectedTabId) || defaultTab;
  return selectedTab;
};
export const selectError = (state) => get(state, 'knowledgeBase.error', false);
export const selectErrorMessage = (state) => get(state, 'knowledgeBase.errorMessage', '');
