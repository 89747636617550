/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetState } from 'Screens/Wizard/actions/Actions';
import {
  selectIsFetching, selectRememberMe, selectErrorMessage, selectRequest2fa
} from 'store/Sesion/selectors';
import { selectApiVersion, selectIsSsoEnabled } from 'store/Config/selector';
import { login, loginStart, setRememberMe, getApiConfig, resetLogin } from 'Screens/Login/actions/Actions';
import StringField from 'Common/Components/StringField';
import 'Screens/Login/styles/loginStyles.scss';
import MainContainer from 'Common/Components/MainContainer';
import { LoginLogoContainer, Logo } from 'Common/Components/MainContainer/styled';
import logo from 'Images/faraday_logo.svg';
import CustomCheckbox from 'Common/Components/CustomCheckbox';
import { redirect } from 'store/Router/actions';
import SecondFactor from './SecondFactor';
import SsoButton from './SsoButton';
import {
  OptionsContainer, RememberMe, ForgotPassword, RememberMeLabel,
  InputTitle, ErrorMessage, WrapperField, AcceptButton
} from './styled';

class Login extends Component {
  constructor (props) {
    super(props);
    this.state = {
      user: '',
      password: '',
      error: false,
      fetched: false,
      isRedirecting: false
    };
    this.submit = this.submit.bind(this);
  }

  componentDidMount () {
    this.props.resetState();
    this.props.getApiConfig();
    this.setState({ fetched: false });
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.errorMessage && nextProps.errorMessage.length > 0 && this.state.fetched) {
      this.setState({ error: true });
    }
    if (nextProps.loggedIn) {
      this.setState({
        user: '', password: '', error: false, fetched: false
      });
    }
    if (!nextProps.request2fa && this.props.request2fa) {
      // If the request2fa goes from true to false, it indicates that the 2FA was successful
      this.setState({ isRedirecting: true });
    }
  }

  // Chrome only sets the autofill value once the user interacts with the app by cliking somewhere.
  // Because of that, we need to delay the submit of the login until state is updated with autofill values
  // This can be fixed by using a login with form and login url

  submit () {
    this.props.loginStart();
    setTimeout(() => {
      this.props.login(this.state.user, this.state.password);
      this.setState({
        error: false, fetched: true
      });
    }, 500);
  }

  handleForgotPass () {
    this.props.redirect('/forgotpass');
    this.props.resetLogin();
  }

  render () {
    const {
      isFetching,
      request2fa,
      errorMessage,
      user,
      rememberMe,
      isSsoEnabled,
      apiVersion
    } = this.props;
    const frontVersion = process.env.REACT_APP_VERSION;

    if (request2fa || this.state.isRedirecting) return <SecondFactor resetLogin={ this.props.resetLogin } errorMessage={ errorMessage } hideErrorMessage={ this.props.hideErrorMessage } user={ user } />;

    return (
      <MainContainer isSsoEnabled={ isSsoEnabled }>
        <LoginLogoContainer>
          <Logo src={ logo } alt="Faraday" title={ `Server: ${apiVersion}\nClient: ${frontVersion}` } />
        </LoginLogoContainer>

        <div>
          <div className="login-input-container-title margin-bottom-33">
            <p style={ { userSelect: 'none' } } className="width-340">Sign in</p>
          </div>
          <>
            <WrapperField>
              <InputTitle error={ this.state.error }>Username*</InputTitle>
            </WrapperField>
            <WrapperField>
              <StringField id="username" width="340px" height="34px" onChange={ (v) => { this.setState({ user: v, error: false, fetched: false }); } } submit={ () => { this.submit(); } } error={ this.state.error } placeholder="johndoe" login autocomplete="username" />
            </WrapperField>
          </>

          <div className="margin-top-22">
            <WrapperField>
              <InputTitle error={ this.state.error }>Password*</InputTitle>
            </WrapperField>
            <WrapperField>
              <StringField id="password" width="340px" height="34px" type="password" onChange={ (v) => { this.setState({ password: v, error: false, fetched: false }); } } submit={ () => { this.submit(); } } error={ this.state.error } placeholder="********" login autocomplete="password" />
            </WrapperField>
          </div>
          {this.state.error ? <ErrorMessage>Invalid username or password</ErrorMessage> : null}
          <OptionsContainer>
            <ForgotPassword onClick={ () => this.handleForgotPass() }>Forgot password?</ForgotPassword>
            <RememberMe>
              <CustomCheckbox label="" handleCheckboxChange={ () => { this.props.setRememberMe(!rememberMe); } } key="rememberMe" isChecked={ rememberMe } id="test_id_remember_me" />
              <RememberMeLabel>Remember Me</RememberMeLabel>
            </RememberMe>
          </OptionsContainer>
          <WrapperField>
            <AcceptButton onClick={ () => this.submit() } label="Login" isLoading={ isFetching } />
            <SsoButton />
          </WrapperField>
        </div>
      </MainContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: selectIsFetching(state),
  rememberMe: selectRememberMe(state),
  errorMessage: selectErrorMessage(state),
  request2fa: selectRequest2fa(state),
  isSsoEnabled: selectIsSsoEnabled(state),
  apiVersion: selectApiVersion(state)
});

const mapDispatchToProps = (dispatch) => ({
  getApiConfig: () => {
    dispatch(getApiConfig());
  },
  loginStart: () => {
    dispatch(loginStart());
  },
  login: (user, password) => {
    dispatch(login(user, password));
  },
  resetState: () => {
    dispatch(resetState());
  },
  setRememberMe: (value) => {
    dispatch(setRememberMe(value));
  },
  redirect: (pathname) => {
    dispatch(redirect(pathname));
  },
  resetLogin: () => {
    dispatch(resetLogin());
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
