
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createRef } from 'react';
import { useKeyPress } from 'Common/Components/SearchBar/components/BasicFilter/functions';
import get from 'lodash/get';
import { Wrapper, DataItem } from './styled';

const DataContainer = ({ filteredData, onSelectHandler, customStyles = false }) => {
  const [cursor, setCursor] = useState(0);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [hovered, setHovered] = useState(undefined);
  const [elRefs, setElRefs] = React.useState([]);

  useEffect(() => {
    setElRefs((elRefs) => (
      Array(filteredData.length).fill().map((_, i) => elRefs[i] || createRef())
    ));
  }, [filteredData.length]);

  const executeScroll = (cursor) => {
    const ref = get(elRefs, `[${cursor}].current`, null);
    if (ref) ref.scrollIntoView(false);
  };

  useEffect(() => {
    const maxIndex = filteredData.length - 1;
    if (cursor > maxIndex) {
      setCursor(maxIndex);
      executeScroll(maxIndex);
    }
    if (filteredData.length === 1) {
      setCursor(0);
    }
  }, [filteredData]);

  useEffect(() => {
    if (downPress) {
      const maxIndex = filteredData.length - 1;
      if (cursor < maxIndex) {
        const newCursor = cursor + 1;
        setCursor(newCursor);
        executeScroll(newCursor);
      } else if (cursor === maxIndex) {
        setCursor(0);
        executeScroll(0);
      }
    }
  }, [downPress]);

  useEffect(() => {
    if (upPress) {
      if (cursor > 0) {
        const newCursor = cursor - 1;
        setCursor(newCursor);
        executeScroll(newCursor);
      } else if (cursor === 0) {
        const newCursor = filteredData.length - 1;
        setCursor(newCursor);
        executeScroll(newCursor);
      }
    }
  }, [upPress]);

  useEffect(() => {
    if (enterPress) {
      onSelectHandler(filteredData[cursor]);
    }
  }, [enterPress]);

  useEffect(() => {
    if (hovered && filteredData.length) {
      setCursor(filteredData.indexOf(hovered));
    }
  }, [hovered]);

  return (
    <Wrapper customStyles={ customStyles }>
    { filteredData.map((item, i) =>
      <DataItem
        key={ item.id }
        onMouseDown={ (e) => { onSelectHandler(item); e.stopPropagation(); e.preventDefault(); } }
        ref={ elRefs[i] }
        isActive={ i === cursor }
        onMouseEnter={ () => setHovered(item) }
        onMouseLeave={ () => setHovered(undefined) }
        customStyles={ customStyles }
      >
        {item.name}
      </DataItem>
    ) }
    </Wrapper>
  );
};

export default DataContainer;
