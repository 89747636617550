import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
  height: 100%;
  & >:nth-child(n){
    width: 50%;
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
