import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import isValidPassword from 'Common/Components/Password/functions';
import { clearLoginSettings } from 'store/Settings/loginSettingsActions';
import { selectError, selectMessage } from 'store/Settings/changePasswordSelectors';
import Error from 'Common/Components/Error';
import Success from 'Common/Components/Success';
import {
  Wrapper, Title, CustomPassword, PasswordSection, Form
} from './styled';

const ChangePassword = ({ data, setData, errors, setErrors }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { password: { currentPassword, newPassword, repeatNewPassword } } = data;
  const passwordInvalid = intl.formatMessage({ id: 'resetpassword.rule' });
  const passwordDoesntMatch = intl.formatMessage({ id: 'resetpassword.doesntmatch' });

  useEffect(() => dispatch(clearLoginSettings()), [dispatch]);

  const props = useSelector((state) => ({
    error: selectError(state),
    message: selectMessage(state)
  }));

  const validatePassword = () => {
    if (newPassword === '') setErrors({ ...errors, passwordInvalid: false });
    else if (isValidPassword(newPassword)) setErrors({ ...errors, passwordInvalid: false });
    else setErrors({ ...errors, passwordInvalid: true });
  };

  const isPasswordMatch = () => {
    if (newPassword === repeatNewPassword) setErrors({ ...errors, passwordDoesntMatch: false });
    else setErrors({ ...errors, passwordDoesntMatch: true });
  };

  return (
    <Wrapper>
      <Form>
        <Title>{intl.formatMessage({ id: 'preferences.account.changePassword.title' }) }</Title>
        <CustomPassword
          title={ intl.formatMessage({ id: 'preferences.account.changePassword.password.title' }) }
          placeholder={ intl.formatMessage({ id: 'preferences.account.changePassword.password.placeholder' }) }
          onChange={ (v) => { setData({ ...data, password: { ...data.password, currentPassword: v } }); } }
          defaultValue={ currentPassword }
          autocomplete="current-password"
          name="currentPassword"
          id="currentPassword"
          type="password"
        />
        <PasswordSection>
          <CustomPassword
            title={ intl.formatMessage({ id: 'preferences.account.changePassword.newPassword.title' }) }
            placeholder={ intl.formatMessage({ id: 'preferences.account.changePassword.newPassword.placeholder' }) }
            onChange={ (v) => { setData({ ...data, password: { ...data.password, newPassword: v } }); } }
            defaultValue={ newPassword }
            autocomplete="new-password"
            id="newPassword"
            name="newPassword"
            type="password"
            onBlur={ validatePassword }
            error={ errors.passwordInvalid ? passwordInvalid : '' }
          />
          <CustomPassword
            title={ intl.formatMessage({ id: 'preferences.account.changePassword.repeatNewPassword.title' }) }
            placeholder={ intl.formatMessage({ id: 'preferences.account.changePassword.repeatNewPassword.placeholder' }) }
            onChange={ (v) => { setData({ ...data, password: { ...data.password, repeatNewPassword: v } }); } }
            defaultValue={ repeatNewPassword }
            autocomplete="new-password"
            id="repeatNewPassword"
            name="repeatNewPassword"
            type="password"
            onBlur={ isPasswordMatch }
            error={ errors.passwordDoesntMatch ? passwordDoesntMatch : '' }
          />
        </PasswordSection>

        { props.error && props.message ? <Error>{props.message}</Error> : null}
        { !props.error && props.message ? <Success>{props.message}</Success> : null}

      </Form>
    </Wrapper>
  );
};
export default ChangePassword;
