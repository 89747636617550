import api from 'services/api';
import { updateHostListRow, appendHostListRow, toggleModalEditCreate, appendAssetListRow } from 'store/Host/actions';
import { showHostDetail } from 'store/HostDetail/actions';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import { trackEvent } from 'tracking/GA';
import * as types from 'store/HostEditCreate/types';
import { selectHostsSelected } from 'store/Host/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import get from 'lodash/get';
import { selectShowHostDetail } from 'store/HostDetail/selectors';
import { UPDATE_HOST_SELECTED } from 'store/Host/types';

export function resetState () {
  return (dispatch) => {
    dispatch({
      type: types.RESET_STATE_HOST_CREATE_UPDATE
    });
  };
}

export function setField (fieldName, value) {
  return (dispatch) => {
    dispatch({
      type: types.SET_FIELD_NAME_HOST_CREATE_UPDATE, fieldName, value
    });
  };
}

export function removeHostname (index) {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_HOST_NAME_HOST_CREATE_UPDATE, index
    });
  };
}

export function addHostname (value) {
  return (dispatch, getState) => {
    const { hostnames } = getState().hostEditCreate;

    if (hostnames.filter((hname) => hname === value).length === 0) dispatch({ type: types.ADD_HOST_NAME_HOST_CREATE_UPDATE, value });
  };
}

export function setHostName (value) {
  return (dispatch) => {
    dispatch({
      type: types.SET_HOST_NAME_HOST_CREATE_UPDATE, value
    });
  };
}

export function createUpdateHost () {
  return async (dispatch, getState) => {
    const { hostEditCreate } = getState();
    const { workspaceSelected } = getState().faraday;
    const isHostDetailVisible = selectShowHostDetail(getState());

    const host = {
      id: hostEditCreate.id,
      ip: hostEditCreate.ip,
      owned: hostEditCreate.owned,
      os: hostEditCreate.os,
      mac: hostEditCreate.mac,
      description: hostEditCreate.description,
      hostnames: hostEditCreate.hostnames,
      owner: hostEditCreate.owner,
      default_gateway: hostEditCreate.default_gateway
    };

    try {
      if (hostEditCreate.id) {
        const response = await api.host.updateHost(workspaceSelected, host);
        dispatch(trackEvent(CATEGORIES.asset, ACTIONS.editAsset.name, ACTIONS.editAsset.label, hostEditCreate.id));
        // if the update was succesful, updates the host list and host detail on the store.
        dispatch(updateHostListRow(response));
        if (isHostDetailVisible) {
          dispatch(showHostDetail(response));
          dispatch({ type: UPDATE_HOST_SELECTED, host: [response] });
        }
      } else {
        const response = await api.host.createHost(workspaceSelected, host);
        dispatch(trackEvent(CATEGORIES.asset, ACTIONS.createAsset.name, ACTIONS.createAsset.label, response.id));
        // if the creation was succesful, append the new host to the host list.
        dispatch(appendHostListRow(response));
        // dispatch(getData());
      }

      dispatch({ type: types.HOST_CREATE_UPDATE_SUCCESS });
      dispatch(resetState());
    } catch (e) {
      dispatch({ type: types.HOST_CREATE_UPDATE_FAIL, error: e.message });
    }
  };
}

export function showEditModal () {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    const hostSelected = selectHostsSelected(getState());
    const hostId = get(hostSelected, '[0].id', 0);

    try {
      const host = await api.hostDetail.fetchById(workspaceSelected, hostId);
      dispatch(toggleModalEditCreate(true, host));
      return dispatch({ type: types.SET_HOST_FOR_EDIT, host });
    } catch (e) {
      return dispatch({ type: types.HOST_CREATE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

// context

export function createAsset (host) {
  return async (dispatch, getState) => {
    const { workspaceSelected } = getState().faraday;
    const isHostDetailVisible = selectShowHostDetail(getState());
    const newHost = { ...host };
    delete newHost.workspaces;
    try {
      if (host.id) {
        const response = await api.host.updateHost(workspaceSelected, newHost);
        dispatch(trackEvent(CATEGORIES.asset, ACTIONS.editAsset.name, ACTIONS.editAsset.label, host.id));
        // if the update was succesful, updates the host list and host detail on the store.
        dispatch(updateHostListRow(response));
        if (isHostDetailVisible) {
          dispatch(showHostDetail(response));
          dispatch({ type: UPDATE_HOST_SELECTED, host: [response] });
        }
      } else {
        const response = await api.host.createHost(workspaceSelected, newHost);
        dispatch(trackEvent(CATEGORIES.asset, ACTIONS.createAsset.name, ACTIONS.createAsset.label, response.id));
        dispatch(appendAssetListRow(response));
      }

      dispatch({ type: types.HOST_CREATE_UPDATE_SUCCESS });
      dispatch(resetState());
    } catch (e) {
      dispatch({ type: types.HOST_CREATE_UPDATE_FAIL, error: e.message });
    }
  };
}
