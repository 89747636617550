import * as dashboard from './dashboard';
import * as manage from './manage';
import * as faraday from './faraday';
import * as report from './report';
import * as workspace from './workspace';
import * as users from './users';
import * as host from './host';
import * as preferences from './preferences';
import * as hostDetail from './hostDetail';
import * as knowledgeBase from './knowledgeBase';
import * as service from './services';
import * as fileUploaderContext from './fileUploaderContext';
import * as settings from './settings';
import * as customAttributes from './customAttributes';
import * as agents from './agents';
import * as schedule from './schedule';
import * as attachments from './attachments';
import * as filters from './filters';
import * as license from './license';
import * as auth from './auth';
import * as pipelines from './pipelines';
import * as jobs from './jobs';
import * as analytics from './analytics';
import * as projects from './projects';
import * as tasks from './tasks';
import * as tags from './tags';
import * as notifications from './notifications';
import * as hostsGeneral from './hostsGeneral';
import * as vulnsGeneral from './vulnsGeneral';
import * as servicesGeneral from './servicesGeneral';

export default {
  dashboard,
  manage,
  faraday,
  report,
  workspace,
  users,
  host,
  preferences,
  hostDetail,
  knowledgeBase,
  service,
  fileUploaderContext,
  settings,
  customAttributes,
  agents,
  schedule,
  attachments,
  filters,
  license,
  auth,
  pipelines,
  jobs,
  analytics,
  projects,
  tasks,
  tags,
  notifications,
  hostsGeneral,
  vulnsGeneral,
  servicesGeneral
};
