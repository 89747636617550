import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { Edit } from '../../styled';
import { redirectToServiceDetail, selectRow, unSelectAll } from 'store/Services/actions';
import { redirectToServiceDetailGeneral, selectServiceRow, unSelectAllServices } from 'store/ServicesGeneral/actions';
import {
  redirectToServiceDetail as redirectToDetail,
  selectServiceRow as selectService,
  unSelectAll as unSelectAllServicesFromAsset
} from 'store/Contextualization/AssetDetail/actions';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_SERVICES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const EditServiceButton = ({ hasServices, servicesSelected, entity }) => {
  const [showWarning, setShowWarning] = useState(false);
  const serviceId = get(servicesSelected, '[0].id', 0);
  const service = get(servicesSelected, '[0]', null);
  const dispatch = useDispatch();
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'service' });
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_SERVICES, PERMISSION_TYPES.UPDATE));

  const showServiceDetail = (e) => {
    if (entity === 'services') {
      dispatch(unSelectAll());
      dispatch(redirectToServiceDetail(serviceId));
      dispatch(selectRow(e, service));
    } else if (entity === 'servicesGeneral') {
      dispatch(unSelectAllServices());
      dispatch(redirectToServiceDetailGeneral(serviceId));
      dispatch(selectServiceRow(e, service));
    } else {
      dispatch(unSelectAllServicesFromAsset('services'));
      dispatch(redirectToDetail(serviceId));
      dispatch(selectService(e, service));
    }
  };

  const handleClick = (e) => {
    if (hasServices && canUpdate) {
      if (servicesSelected.length === 1) showServiceDetail(e);
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } disabled={ !hasServices || !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Edit" description={ message } /> }
    </>
  );
};

export default EditServiceButton;
