import styled from 'styled-components';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';

export const IconWrapper = styled.div`
  display: none;
  flex: 0 0 16px;
  margin: auto;
  padding-bottom: 3px;
`;
IconWrapper.displayName = 'IconWrapper';

export const Trash = styled(TrashIcon)`
  width: 16px;
  svg{
    fill: #1c4566
  }
`;
Trash.displayName = 'Tash';
