/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { getCustomAttributes } from 'store/CustomAttributes/actions';
import {
  removeComment,
  resetErrorValue,
  resetState,
  showManageLeftFilters,
  hideVulnerabilityDetail,
  loadVulnerabilityDetail
} from 'store/Manage/actions';
import {
  selectSelectedComment, selectError, selectErrorMessage,
  selectShowDetail
} from 'store/Manage/selectors';
import {
  clearAdvancedFilter, getCustomFilters, resetFilters
} from 'store/Filters/actions';
import { getSelectedEntity } from 'store/Filters/selectors';
import { selectShowHostLeftFilters } from 'store/Host/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { getTags, getHostsTags } from 'Screens/Contextualization/Tags/actions/Actions';
import get from 'lodash/get';
import ConfirmationDialog from 'Common/Components/ConfirmationDialog';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE, MODAL_MOVE_VULN_HOST, MODAL_CANT_CREATE } from 'store/modals/modals';
import { closeModal } from 'store/modals/actions';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { ACTIONS } from 'tracking/GA/constants';
import selectModal from 'store/modals/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import QuickPlanner from 'Screens/Contextualization/Planner/QuickPlanner';
import CustomDragLayer from 'Common/Components/DragAndDrop/CustomDragLayer';
import { Wrapper, TableContainer } from './styled';
import 'Common/styles/markdownStyles.scss';
import ManageTable from './components/ManageTable';
import VulnerabilityDetail from './components/VulnerabilityDetail';
import CustomActionBar from './components/CustomActionBar';
import FilterSideBar from './components/FilterSideBar';
import TemplateModal from './components/ManageTable/components/ContextMenu/components/TemplateModal';
import 'Screens/Contextualization/Manage/styles/manageStyles.scss';
import { removeCurrentHost } from 'store/Host/actions';
import { VulnsTour } from 'Common/Components/Contextualization/Tour';
import { MoveVulnModal } from './components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/HostData';
import { getHostsCountInWs, getVulnsCountInWs } from 'Screens/Contextualization/Faraday/actions/Actions';
import WarningModal from 'Screens/Contextualization/ManageEditCreate/components/WarningModal';

const ManageContext = ({ params }) => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const selectedEntity = useSelector(getSelectedEntity);
  const showHostFilters = useSelector(selectShowHostLeftFilters);
  const showCommentDeletionModal = useSelector((state) => selectModal(state, 'commentDeletion'));
  const showModal = useSelector((state) => selectModal(state, MODAL_UPGRADE_LICENSE));
  const selectedComment = useSelector((state) => selectSelectedComment(state));
  const showDetail = useSelector(selectShowDetail);
  const showMoveVulnModal = useSelector((state) => selectModal(state, MODAL_MOVE_VULN_HOST));
  const showModalCantCreate = useSelector((state) => selectModal(state, MODAL_CANT_CREATE));

  const handleParamId = useCallback(() => {
    const id = get(params, 'id', 0);
    if (parseInt(id, 10) > 0) dispatch(loadVulnerabilityDetail(id, true));
    else dispatch(hideVulnerabilityDetail());
  }, [params]);

  useEffect(() => {
    dispatch(getCustomAttributes());
    dispatch(getCustomFilters());
    if (showHostFilters) dispatch(showManageLeftFilters(true));
    else dispatch(showManageLeftFilters(false));
  }, [showHostFilters, selectedEntity, dispatch]);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => {
    dispatch(getTags());
    dispatch(getHostsTags());
    dispatch(getVulnsCountInWs());
    dispatch(getHostsCountInWs());
  }, [dispatch, workspaceSelected]);

  useEffect(() => () => {
    dispatch(resetState());
    dispatch(resetFilters('vulns'));
    dispatch(clearAdvancedFilter('vulns'));
    dispatch(closeModal(MODAL_CANT_CREATE));
  }, [dispatch]);

  useEffect(() => {
    dispatch(removeCurrentHost());
  }, [dispatch]);

  return (
    <Wrapper>
      <CustomActionBar />
      <TableContainer>
        <CustomDragLayer />
        <ManageTable />
        <FilterSideBar entity="vulns" />
        { showDetail && <VulnerabilityDetail /> }
        <QuickPlanner />
        <TemplateModal />
      </TableContainer>
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(resetErrorValue()) } /> : null }
      { showModal && (
        <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showVulns } trackingClick={ ACTIONS.clickVulns } />
        </ReduxModal>
      ) }
      <ConfirmationDialog
        show={ showCommentDeletionModal }
        title="Delete Comment"
        submitText="Delete"
        message="A comment will be deleted. This action cannot be undone. Are you sure you want to proceed?"
        handleClose={ () => dispatch(closeModal('commentDeletion')) }
        handleSubmit={ () => {
          dispatch(removeComment(selectedComment.id));
          dispatch(closeModal('commentDeletion'));
        } }
      />
      <VulnsTour />
      {showMoveVulnModal && <MoveVulnModal />}
      { showModalCantCreate && <WarningModal entity="Vulnerability" /> }
    </Wrapper>
  );
};

ManageContext.propTypes = {
  params: PropTypes.object.isRequired
};

export default ManageContext;
