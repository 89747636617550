import React from 'react';
import { useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import Button from 'Common/Components/BlueButton/styled';
import {
  Wrapper, Label, Image, ButtonLink
} from './styled';

const EmptyVulns = ({ toggleModalUploadVulns, toggleModalCreateVuln, image }) => {
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.CREATE));
  return (
    <Wrapper>
      <Image src={ image } />
      <Label>You don’t have any vulnerabilities yet.</Label>
      { canCreate &&
        <>
          <Button onClick={ toggleModalUploadVulns }>Upload Vulnerabilities</Button>
          <Label>
            Or
            <ButtonLink onClick={ toggleModalCreateVuln }>Create vulnerabilities manually</ButtonLink>
          </Label>
        </>
      }
    </Wrapper>
  );
};

export default EmptyVulns;
