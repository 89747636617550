import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import RefreshData from 'Screens/Analytics/components/CreateEdit/components/Preview/components/RefreshData';
import { setGraphFrom, setGraphTo, setShowTable } from 'store/Analytics/actions';
import {
  selectFrom, selectTo, selectShowTable, selectRefreshedAt, selectType, selectData, selectWorkspaces
} from 'store/Analytics/selectors';
import DateInput from 'Screens/Planner/Tasks/CreateEdit/components/Tabs/General/components/DateInput';
import {
  Wrapper, TopRow, DateWrapper, HelpIcon, Label, CheckboxWrapper, WrapperHelpIcon
} from './styled';
import isEmpty from 'lodash/isEmpty';

const Header = () => {
  const dispatch = useDispatch();
  const type = useSelector(selectType);
  const from = useSelector(selectFrom);
  const to = useSelector(selectTo);
  const intl = useIntl();
  const showTable = useSelector(selectShowTable);
  const showRefreshData = useSelector(selectRefreshedAt);
  const data = useSelector(selectData);
  const workspaces = useSelector(selectWorkspaces);

  const showDates = () => !(type === 'monthly_evolution_by_status' || type === 'monthly_evolution_by_severity');

  const onChangeFrom = (v) => {
    dispatch(setGraphFrom(v));
  };

  const onChangeTo = (v) => {
    dispatch(setGraphTo(v));
  };

  if (isEmpty(data.linked_data) || workspaces.length === 0) return null;

  return (
    <Wrapper>
      <TopRow>
        { showDates()
          ? (
          <DateWrapper>
            <DateInput
              max={ to }
              onChange={ (v) => onChangeFrom(v) }
              title={ intl.formatMessage({ id: 'analytics.graph.from' }) }
              value={ from }
              required={ false }
            />
            <DateInput
              min={ from }
              onChange={ (v) => onChangeTo(v) }
              title={ intl.formatMessage({ id: 'analytics.graph.to' }) }
              value={ to }
              required={ false }
            />
            <WrapperHelpIcon><HelpIcon title={ intl.formatMessage({ id: 'analytics.preview.help' }) } /></WrapperHelpIcon>
          </DateWrapper>
            )
          : null }
        { showRefreshData ? <RefreshData /> : null }
      </TopRow>
      <CheckboxWrapper>
        <Checkbox state={ showTable } onChange={ () => dispatch(setShowTable(!showTable)) } />
        <Label>{ intl.formatMessage({ id: 'analytics.preview.showTable' }) }</Label>
      </CheckboxWrapper>
    </Wrapper>
  );
};

export default Header;
