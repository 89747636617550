import React, { useState } from 'react';
import Select from 'react-select';
import { InputDrowndown, CustomWrapper, CustomInput, Label, customDropdownStyle } from './styled';
import moment from 'moment';

const ExpiresDropdown = ({ options, onChange, defaultValue }) => {
  const [showCustom, setShowCustom] = useState(false);
  const [customValue, setCustomValue] = useState('');

  const getDaysInAMonth = () => {
    const today = moment();
    const day = Number(moment(today).format('DD'));
    const month = moment(today).format('M');
    const currentMonth = Number(month) - 1;
    const year = Number(moment(today).format('YYYY'));
    const nextYear = year + 1;

    const currentDate = moment([year, currentMonth, day]);
    let inOneMonth;
    const months = [0, 2, 4, 6, 10];
    const leapYear = moment().isLeapYear();

    if (leapYear && (currentMonth === 0) && (day === 29)) {
      inOneMonth = moment([year, 1, day]);
    } else if (!leapYear && (currentMonth === 0) && (day === 29)) {
      inOneMonth = moment([year, 2, 1]);
    } else if ((day > 29) && months.includes(currentMonth)) {
      const twoMonths = currentMonth + 2;
      inOneMonth = moment([year, twoMonths, 1]);
    } else if (currentMonth === 11) {
      inOneMonth = moment([nextYear, 0, day]);
    } else {
      inOneMonth = moment([year, currentMonth + 1, day]);
    }

    const diffInDays = inOneMonth.diff(currentDate, 'days');
    return diffInDays;
  };

  const onSelect = (field, e) => {
    if (e.label === 'in 1 month') {
      const days = getDaysInAMonth();
      setShowCustom(false);
      onChange({ value: days });
      setCustomValue('');
    } else if (e.label === 'Custom') {
      setShowCustom(true);
    } else {
      setShowCustom(false);
      onChange(e);
      setCustomValue('');
    }
  };

  const handleChange = (e) => {
    setCustomValue(e.target.value);
    onChange({ value: e.target.value });
  };

  return (
    <InputDrowndown>
      <Select
        options={ options }
        onChange={ (e) => onSelect('expires', e) }
        styles={ customDropdownStyle }
        defaultValue={ defaultValue }
        isSearchable={ false }
      />
      <CustomWrapper showCustom={ showCustom }>
        <Label>in</Label>
        <CustomInput
          name="custom"
          type="number"
          min={ 0 }
          value={ customValue }
          onChange={ (e) => handleChange(e) }
        />
        <Label>days</Label>
      </CustomWrapper>
    </InputDrowndown>

  );
};

export default ExpiresDropdown;
