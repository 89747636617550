import React from 'react';
import { useIntl } from 'react-intl';
import {
  createEditTask, closeTaskDetail
} from 'store/Tasks/actions';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsEditing, selectTaskType, selectTaskName, selectTaskStartDate,
  selectTaskEndDate, selectTaskStatus, selectTaskDescription
} from 'store/Tasks/selectors';
import { setHasPendingChanges } from 'store/Router/actions';
import { Wrapper, CancelButton, CustomBlueButton } from './styled';
import SidebarTitle from './SidebarTitle';

const ActionBar = () => {
  const intl = useIntl();
  const isEditing = useSelector(selectIsEditing);
  const dispatch = useDispatch();
  const type = useSelector(selectTaskType);
  const name = useSelector(selectTaskName);
  const startDate = useSelector(selectTaskStartDate);
  const endDate = useSelector(selectTaskEndDate);
  const progress = useSelector(selectTaskStatus);
  const description = useSelector(selectTaskDescription);

  const typeTask = type === 'task' || type === '';

  const allowSaveTask = !isEmpty(name) &&
    !isEmpty(type) &&
    !!startDate &&
    !!endDate &&
    !isEmpty(progress) &&
    !isEmpty(description);

  const allowSaveMilestone = !isEmpty(name) &&
    !isEmpty(type) &&
    !!endDate &&
    !isEmpty(description);

  const handleCancel = () => {
    dispatch(setHasPendingChanges(false));
    dispatch(closeTaskDetail());
  };

  const handleSave = () => dispatch(createEditTask());

  return (
    <Wrapper>
      <SidebarTitle />
      <CancelButton onClick={ handleCancel }>{ intl.formatMessage({ id: 'planner.task.cancelButton' }) }</CancelButton>
      <CustomBlueButton onClick={ handleSave } label={ intl.formatMessage({ id: isEditing ? 'planner.task.editButton' : 'planner.task.createButton' }) } disabled={ typeTask ? !allowSaveTask : !allowSaveMilestone } />
    </Wrapper>
  );
};

export default ActionBar;
