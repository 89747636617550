import styled, { css } from 'styled-components';

export const Text = styled.p`
`;

Text.displayName = 'Text';

export const Wrapper = styled.div`
  margin-top: 27px;
  margin-bottom: 20px;

  > ${Text} {
    font-size: 13.5px;
    letter-spacing: normal;
    text-align: left;
    color: #1c2243;
    margin-bottom: 8px !important; //It's hardcoded in the main css file for p to have 0px
  }

  ${(props) => props.isMarkdown &&
    css`
      > :nth-child(1) {
        font-size: 17px;
        font-weight: bold;
      }

      > :nth-child(2) {
          font-size: 13.5px;
          font-weight: bold;
      }

      > :nth-child(3) {
          font-size: 13.5px;
          font-style: italic;
      }
    `}
`;

Wrapper.displayName = 'Wrapper';
