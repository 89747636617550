/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Wrapper, Title, CustomInput } from './styled';

class StringField extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.debouncedOnChangeHandler = debounce((text) => this.props.onChange(text), 300);
    this.validateEnter = this.validateEnter.bind(this);

    this.state = {
      value: ''
    };
  }

  componentDidMount () {
    if (this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }

  onChangeHandler (v) {
    this.setState({ value: v });
    this.debouncedOnChangeHandler(v);
  }

  validateEnter (e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (this.props.onBlur) {
        this.props.onBlur();
      } else if (this.props.submit) {
        this.props.submit();
      }
    }
  }

  render () {
    const { value } = this.state;
    const {
      placeholder,
      title,
      width,
      height,
      disabled,
      type,
      error,
      id,
      readOnly,
      className,
      login,
      name,
      autocomplete
    } = this.props;

    return (
      <Wrapper className={ className }>
        <Title disabled={ disabled }>{ title }</Title>
        <CustomInput readOnly={ readOnly || false } id={ id } value={ value } placeholder={ placeholder } type={ type || 'text' } width={ width } height={ height } onChange={ (e) => this.onChangeHandler(e.target.value) } disabled={ disabled } onBlur={ () => (this.props.onBlur ? this.props.onBlur() : () => {}) } onKeyPress={ this.validateEnter } error={ error } login={ login } name={ name } autoComplete={ autocomplete } />
      </Wrapper>
    );
  }
}

StringField.propTypes = {
  placeholder: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  error: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
  onBlur: PropTypes.func,
  submit: PropTypes.func,
  login: PropTypes.bool,
  name: PropTypes.string,
  autocomplete: PropTypes.string
};

StringField.defaultProps = {
  placeholder: '',
  title: '',
  width: '224px',
  height: '34px',
  disabled: false,
  type: 'text',
  error: false,
  id: '',
  className: '',
  readOnly: false,
  defaultValue: '',
  onBlur: null,
  submit: null,
  login: false,
  name: '',
  autocomplete: 'off'
};

export default StringField;
