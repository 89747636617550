import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { selectRowsPerPage } from 'store/Filters/selectors';
import { selectVulnsCount } from 'store/ExecutiveReportEditCreate/selectors';
import { useSelector } from 'react-redux';
import {
  Wrapper, Summary, Prev, Next, WrapperButton
} from './styled';

const Button = ({
  icon, tooltip, onClick, disabled
}) => <WrapperButton type="button" title={ tooltip } onClick={ onClick } disabled={ disabled }>{ icon }</WrapperButton>;

Button.propTypes = {
  icon: PropTypes.element.isRequired,
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};
function Pagination ({ page, setPage }) {
  const intl = useIntl();
  const limit = useSelector((state) => selectRowsPerPage('reports', state));
  const total = useSelector((state) => selectVulnsCount(state));
  const isFirstPage = page === 0;
  const isLastPage = limit * page > total;
  const totalPages = Math.ceil(total / limit);

  useEffect(() => {
    if (page > totalPages) setPage(totalPages);
  }, [page, setPage, total, totalPages]);

  return (
    <Wrapper>
      <Summary>{ `${page + 1} ${intl.formatMessage({ id: 'common.pagination.of' })} ${totalPages + 1}` }</Summary>
      <Button disabled={ isFirstPage || total === 0 } icon={ <Prev /> } tooltip={ intl.formatMessage({ id: 'common.pagination.prev' }) } onClick={ () => setPage(page - 1) } />
      <Button disabled={ isLastPage || total === 0 } icon={ <Next /> } tooltip={ intl.formatMessage({ id: 'common.pagination.next' }) } onClick={ () => setPage(page + 1) } />
    </Wrapper>
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired
};

export default Pagination;
