import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from 'Images/faraday_logo.svg';
import { useIntl } from 'react-intl';
import { selectLimitReached } from 'store/Host/selectors';
import { selectIsFetching } from 'store/License/selectors';
import { openUpgradeLicense } from 'store/License/actions';
import {
  Wrapper, Close, Title, Text, Container, Logo
} from './styled';
import BlueButton from '../BlueButton';

const HostsLimitReached = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [close, setClose] = useState(false);
  const limitReached = useSelector((state) => selectLimitReached(state));
  const isFetching = useSelector((state) => selectIsFetching(state));

  const onClick = () => {
    if (!isFetching) dispatch(openUpgradeLicense());
  };

  return limitReached && !close
    ? (
    <Wrapper>
      <Container>
        <Logo src={ logo } alt="Faraday" />
        <Title>Your have reached your Assets limit.</Title>
        <Text>Add more upgrading your license today!</Text>
        <Close onClick={ () => setClose(true) }>x</Close>
        <BlueButton isLoading={ isFetching } label={ intl.formatMessage({ id: 'upgradeLicense.upgradeNow' }) } onClick={ onClick } />
      </Container>
    </Wrapper>
      )
    : null;
};

export default HostsLimitReached;
