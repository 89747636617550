import styled from 'styled-components';
import colors from 'Styles/colors';

export const TagsWrapper = styled.div`
  max-height: 103px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
  overflow-y: auto;
`;
TagsWrapper.displayName = 'TagsWrapper';

export const Tag = styled.div`
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  background-color: ${colors.greyBlue};
  border-radius: 2px;
  padding: 4px 6px;
  height: 25px;
  line-height: normal;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  color: ${colors.white};
  font-size: 11.5px;
  font-weight: 500;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
Tag.displayName = 'Tag';
