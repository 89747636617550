import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { selectParsedTemplateName, selectTemplates } from 'store/ExecutiveReportEditCreate/selectors';
import { setInputField } from 'store/ExecutiveReportEditCreate/actions';
import { Wrapper, Button, WordIcon, PDFIcon, Options, FormatOption, Text } from './styled';

const SelectFormat = () => {
  const dispatch = useDispatch();
  const [showOptions, setShowOptions] = useState(false);
  const optionsElement = useRef(null);

  const listTemplates = useSelector(selectTemplates);
  const selectedTemplate = useSelector(selectParsedTemplateName);
  const parsedTemplate = selectedTemplate.length > 0 && selectedTemplate[0];
  const getOptions = () => {
    let isPdfAllowed = false;
    let isDocxllowed = false;
    let temp = [];
    if (parsedTemplate.pdfOption) {
      temp = listTemplates.find((template) => template[1] === parsedTemplate.pdfOption);
      isPdfAllowed = get(temp, '[2]', false);
    }
    if (parsedTemplate.docxOption) {
      temp = listTemplates.find((template) => template[1] === parsedTemplate.docxOption);
      isDocxllowed = get(temp, '[2]', false);
    }

    let arr = [];
    if (temp) {
      if (isPdfAllowed && isDocxllowed) {
        arr = ['.PDF Document', '.DOCX Document'];
      } else if ((isPdfAllowed && !isDocxllowed)) {
        arr = ['.PDF Document'];
      } else {
        arr = ['.DOCX Document'];
      }
    }
    return arr;
  };

  const options = getOptions(parsedTemplate);
  const format = parsedTemplate?.isPdf ? '.PDF Document' : '.DOCX Document';

  const toggle = () => {
    if (options && options.length > 1) setShowOptions(!showOptions);
  };

  const handleSelect = (f) => {
    const isGrouped = parsedTemplate.isGrouped;
    const templateValue = f.includes('PDF') ? parsedTemplate.pdfOption : parsedTemplate.docxOption;
    dispatch(setInputField('selectedTemplate', [isGrouped, templateValue]));
    setShowOptions(!showOptions);
  };

  const handleClickOutside = (e) => {
    if (optionsElement.current && !optionsElement.current.contains(e.target)) toggle();
  };

  useEffect(() => {
    if (showOptions) document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClickOutside]);

  const parsedFormat = format === '.DOCX Document' ? 'DOCX' : 'PDF';
  const tooltip = options?.length === 1 ? `This template is only available in ${parsedFormat} format` : 'Select template\'s format';

  return (
    <Wrapper ref={ optionsElement }>
      <Button onClick={ toggle } title={ tooltip }>
      { format === '.DOCX Document' ? <WordIcon /> : <PDFIcon /> }
      </Button>
      { showOptions &&
        <Options>
          { options.map((f) =>
            <FormatOption key={ f } onClick={ () => handleSelect(f) }>
              { f.includes('.DOCX Document') ? <WordIcon /> : <PDFIcon /> }
              <Text>{f}</Text>
            </FormatOption>)
            }
        </Options>
      }
    </Wrapper>
  );
};

export default SelectFormat;
