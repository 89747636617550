/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Dropdown, DateTimePicker, CreatableInput, DatePicker } from 'Common/Components/Inputs';
import { CustomInput } from '../styled';

// Type A
const ArrayIssueField = ({
  options, setValue, title, value
}) => {
  const onChange = useCallback((e) => {
    setValue(e && e.map((option) => option.value));
  }, [setValue]);

  const onChangeWithOutSpace = useCallback((e) => {
    setValue(e && e.map((option) => option.value.replaceAll('-', '')));
  }, [setValue]);

  if (options) {
    const parsedOptions = options.map((option) => ({
      label: option,
      value: option
    }));

    return (
      <>
        <Dropdown
          isMulti
          options={ parsedOptions }
          onChange={ onChange }
          title={ title }
          defaultValue={ value && value.map((option) => ({
            label: option,
            value: option
          })) }
        />
      </>
    );
  }
  return (
    <>
      <CreatableInput
        title={ title }
        placeholder={ `Type ${title} and press enter...` }
        onChange={ onChangeWithOutSpace }
        defaultValue={ value && value.map((option) => ({
          label: option,
          value: option
        })) }
      />
    </>
  );
};

// Type B
const UserIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));
  const onChange = (e) => setValue(e.value);

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};
const ProjectIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));
  const onChange = (e) => setValue(e.value);

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};
const VersionIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));
  const onChange = (e) => setValue(e.value);

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};

const GroupIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));
  const onChange = (e) => setValue(e.value);
  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};

const OptionIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));
  const onChange = (e) => setValue(e.value);

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};

// Type C
const PriorityIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));

  const onChange = (e) => setValue(e.value);

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};

const SecurityLevelIssueField = ({
  options, title, setValue, value
}) => {
  const parsedOptions = options.map((option) => ({
    label: option,
    value: option
  }));
  const onChange = (e) => setValue(e.value);

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <>
      <Dropdown
        options={ parsedOptions }
        onChange={ onChange }
        title={ title }
        defaultValue={ {
          label: value,
          value
        } }
      />
    </>
  );
};

// Type D
const StringIssueField = ({ title, setValue, value }) => {
  useEffect(() => {
    setValue(value);
  }, []);
  return (
    <>
      <CustomInput
        title={ title }
        defaultValue={ value }
        onChange={ setValue }
        type="text"
      />
    </>
  );
};
const NumberIssueField = ({ title, setValue, value }) => {
  useEffect(() => {
    setValue(value);
  }, []);
  return (
    <>
      <CustomInput
        title={ title }
        defaultValue={ value }
        onChange={ setValue }
        type="number"
      />
    </>
  );
};

// Type E
const DateIssueField = ({ title, setValue, value }) => {
  useEffect(() => {
    setValue(value);
  }, []);
  return (<DatePicker onChange={ setValue } title={ title } defaultValue={ value } />);
};

const OptionWithChildIssueField = ({ title, setValue, options_cascading }) => {
  const parsedOptions = (options) => options.map((option) => ({
    label: option,
    value: option
  }));

  const parsedNestedOptions = options_cascading.map(({ children, parent }) => ({
    label: parent,
    options: parsedOptions(children)
  }));
  const onChange = (e) => setValue(
    {
      child: e.value,
      parent: options_cascading.find((option) => option.children.some((child) => child === e.value)).parent
    }
  );

  return (
    <>
      <Dropdown
        options={ parsedNestedOptions }
        onChange={ onChange }
        title={ title }
      />
    </>
  );
};

const DateTimeIssueField = ({ title, setValue, value }) => {
  useEffect(() => {
    setValue(`${value}:00.000+0000`);
  }, []);
  return (
    <DateTimePicker onChange={ (date) => setValue(`${date}:00.000+0000`) } title={ title } defaultValue={ value } />
  );
};

const AnyIssueField = ({ title }) => (<Dropdown isDisabled title={ title } />);

const fieldTypeComponent = {
  array: ArrayIssueField,
  option: OptionIssueField,
  optionwithchild: OptionWithChildIssueField,
  datetime: DateTimeIssueField,
  date: DateIssueField,
  user: UserIssueField,
  string: StringIssueField,
  number: NumberIssueField,
  version: VersionIssueField,
  project: ProjectIssueField,
  group: GroupIssueField,
  priority: PriorityIssueField,
  securitylevel: SecurityLevelIssueField,
  any: AnyIssueField
};

export default fieldTypeComponent;
