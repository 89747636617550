export const SETTINGS_USER_TOKENS_FAIL = 'SETTINGS_USER_TOKENS_FAIL';
export const SETTINGS_USER_TOKENS_RESET_ERROR = 'SETTINGS_USER_TOKENS_RESET_ERROR';
export const SETTINGS_GET_USER_TOKENS_REQUEST = 'SETTINGS_GET_USER_TOKENS_REQUEST';
export const SETTINGS_GET_USER_TOKENS_SUCCESS = 'SETTINGS_GET_USER_TOKENS_SUCCESS';
export const SETTINGS_USER_TOKENS_SHOW_ACTION_MODAL = 'SETTINGS_USER_TOKENS_SHOW_ACTION_MODAL';
export const SETTINGS_USER_TOKENS_SHOW_ADD_TOKEN_MODAL = 'SETTINGS_USER_TOKENS_SHOW_ADD_TOKEN_MODAL';
export const SETTINGS_USER_TOKENS_CREATE_SUCCESS = 'SETTINGS_USER_TOKENS_CREATE_SUCCESS';
export const ADD_USER_TOKEN_TO_TABLE = 'ADD_USER_TOKEN_TO_TABLE';
export const SETTINGS_USER_TOKENS_SET_SELECTED_TAB = 'SETTINGS_USER_TOKENS_SET_SELECTED_TAB';
export const RESET_LAST_ADDED_TOKEN = 'RESET_LAST_ADDED_TOKEN';
export const SETTINGS_USER_TOKENS_GET_SCOPES = 'SETTINGS_USER_TOKENS_GET_SCOPES';
