import React from 'react';
import PropTypes from 'prop-types';
import Menu from './components/Menu';
import { Wrapper } from './common-styled';

const Settings = ({ onClose, defaultTab }) => (
  <Wrapper>
    <Menu onClose={ onClose } defaultTab={ defaultTab } />
  </Wrapper>
);

Settings.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default Settings;
