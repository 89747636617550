import BlueButton from 'Common/Components/BlueButton';
import styled from 'styled-components';
import { ReactComponent as Icon } from 'Images/csv_icon_large.svg';

export const Wrapper = styled.div`
    
`;
Wrapper.displayName = 'Wrapper';

export const CustomBlueButton = styled(BlueButton)`
  float: right;
  font-size: 12.5px;
  cursor: pointer;
  font-weight: 300;
  width: 89px;
  height: 34px;
`;
CustomBlueButton.displayName = 'CustomBlueButton';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`;
Title.displayName = 'Title';

export const CsvIcon = styled(Icon)`
  width: 44px;
  height: 41px;

  & g {
    fill: black;
  }
`;
export const LoginLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
  width: 348px;
  display: flex;
  justify-content: left;
`;
LoginLogoContainer.displayName = 'LoginLogoContainer';
