import React from 'react';
import { selectIsEmpty } from 'store/Analytics/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { redirect } from 'store/Router/actions';
import {
  Wrapper, EmptyImage, Title, Button
} from './styled';

// empty list/grid view
const Empty = () => {
  const isEmpty = useSelector(selectIsEmpty);
  const dispatch = useDispatch();
  return isEmpty
    ? (
    <Wrapper>
      <EmptyImage />
      <Title>You haven’t generated any graphs yet. </Title>
      <Button label="Create New Graph" onClick={ () => dispatch(redirect('/analytics/new')) } />
    </Wrapper>
      )
    : null;
};

export default Empty;
