/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-empty */
import React, { useEffect } from 'react';
import { ReactComponent as PlusIcon } from 'Images/icon-action-bar-plus.svg';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import UserCreationModal from 'Screens/Users/components/CreationModal';
import { Wrapper, AddUserBtn } from './styled';
import { selectShowModalCreate } from 'store/Users/selectors';
import { hideCreateEditModal, setVisibilityCreateModal } from 'Screens/Users/actions/Actions';
import { selectEnabled } from 'store/Settings/ldap/selectors';
import { getLdap } from 'store/Settings/ldap/actions';
import { FEATURE_SETTINGS_LDAP, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';

const CreateActions = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const showModalCreate = useSelector(selectShowModalCreate);
  const showCreateModal = (value) => dispatch(setVisibilityCreateModal(value));
  const ldapTooltip = intl.formatMessage({ id: 'users.creationModal.disabled.ldap' });
  const isLdapFeatureEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_SETTINGS_LDAP, PERMISSION_TYPES.READ) && selectAllowedByFeature(state, FEATURE_SETTINGS_LDAP, PERMISSION_TYPES.READ));
  const ldapEnabled = useSelector(selectEnabled);

  useEffect(() => {
    if (isLdapFeatureEnabled) dispatch(getLdap());
  }, [isLdapFeatureEnabled, dispatch]);

  return (
    <Wrapper title={ ldapEnabled ? ldapTooltip : '' } disabled={ ldapEnabled }>
      <AddUserBtn disabled={ ldapEnabled } id="add_user_button" onClick={ () => !ldapEnabled && showCreateModal(true) }>
        <PlusIcon />
        <FormattedMessage id="users.addUser" />
      </AddUserBtn>
      { showModalCreate && <UserCreationModal show={ showModalCreate } handleClose={ () => dispatch(hideCreateEditModal()) } /> }
    </Wrapper>
  );
};

export default CreateActions;
