import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { selectTasksByProjectId } from 'store/Tasks/selectors';
import { selectViewType, selectIsHidingTasks, selectIsFetching } from 'store/Projects/selectors';
import Empty from 'Screens/Planner/Tasks/Empty';
import { StyledTable } from 'Screens/Planner/Projects/Content/components/Table/styled';
import getColumns from 'Screens/Planner/Projects/Content/components/Table/Columns';
import getSplitViewColumns from 'Screens/Planner/Projects/Content/components/Table/SplitViewColumns';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

const SubComponent = ({ row }) => {
  const projectId = get(row, 'original.id', 0);
  const isFetching = useSelector(selectIsFetching);
  const isHidingCompletedTasks = useSelector(selectIsHidingTasks);
  const projectTasks = useSelector((state) => selectTasksByProjectId(state, projectId));
  const withoutCompletedTasks = isHidingCompletedTasks && projectTasks.filter((task) => task.status !== 'completed');
  const viewType = useSelector(selectViewType);

  if (isEmpty(projectTasks)) return <Empty />;
  if (isHidingCompletedTasks && isEmpty(withoutCompletedTasks)) return <Empty filtered />;

  return (
    <StyledTable
      data={ isHidingCompletedTasks ? withoutCompletedTasks : projectTasks }
      columns={ viewType.id === 'splitView' ? getSplitViewColumns(isFetching, false) : getColumns(isFetching, false) }
      manual
      minRows={ 0 }
      showPagination={ false }
      TheadComponent={ () => null }
      noDataText={ !isFetching && <FormattedMessage id="app.nodata" /> }
    />
  );
};

SubComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired
};

export default SubComponent;
