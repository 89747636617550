import styled from 'styled-components';
import StringField from 'Common/Components/StringField';
import { Title as CustomTitle } from 'Common/Components/StringField/styled';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  font-size: 21.5px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  line-height: 0.84;
  color: ${colors.dark2};
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  margin: 27px 0 19px 0;
  font-size: 12.5px;
  line-height: 1.12;
  text-align: left;
  color: ${colors.dark2};
`;
Subtitle.displayName = 'Subtitle';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
Content.displayName = 'Content';

export const CustomInput = styled(StringField)`
  margin-bottom: 16px;
  max-width: 48%;
  width: 100%;
  ${CustomTitle} {
    font-weight: 400;
    color: #afb1bc;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
  }
`;
CustomInput.displayName = 'CustomInput';

export const Hostname = styled(CustomInput).attrs({
  width: '95%'
})`
`;
Hostname.displayName = 'Hostname';

export const Sender = styled(CustomInput).attrs({
  width: '95%'
})`
`;
Sender.displayName = 'Sender';

export const Username = styled(CustomInput).attrs({
  width: '95%'
})`
`;
Username.displayName = 'Username';

export const Password = styled(CustomInput).attrs({
  width: '179px'
})`
`;
Password.displayName = 'Password';

export const Port = styled(CustomInput).attrs({
  width: '88px'
})`
`;
Port.displayName = 'Port';
