import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import logo from 'Images/faraday_logo.svg';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { selectLocation } from 'store/Router/selectors';
import {
  Wrapper, Close, Title, Text, Link, Container, Logo
} from './styled';

const LicenseValidator = () => {
  const [close, setClose] = useState(false);
  const edate = useSelector((state) => state.faraday.edate);
  const expired = useSelector((state) => state.faraday.expired);
  const location = useSelector(selectLocation);
  const validateLicense = () => {
    if (expired === undefined) {
      const now = new Date();
      const endDate = new Date(edate);

      return now.getTime() > endDate.getTime();
    }
    return expired;
  };

  useEffect(() => {
    setClose(false);
  }, [setClose, location.pathname]);

  return validateLicense() && !close
    ? (
    <Wrapper>
      <Container>
        <Logo src={ logo } alt="Faraday" />
        <Title>Your license has expired.</Title>
        <Text>
          Please visit our
          <Link href="https://portal.faradaysec.com/" target="_blank"><FormattedMessage id="license.customer_portal" /></Link>
          <FormattedMessage id="license.to_extend_your_license" />
        </Text>
        <Close onClick={ () => setClose(true) }>x</Close>
      </Container>
    </Wrapper>
      )
    : null;
};

export default withRouter(LicenseValidator);
