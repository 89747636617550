import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  padding: 0 1rem;

  table {
    margin: 0 auto;
    border-spacing: 0;
    border-radius: 2px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #d9e4ef;
    thead {
      background: #edf2f7;
      tr {
        th {
          font-size: 10px;
          font-weight: 500;
          padding: 15px 19px;
        }
      }
    }
    tr {
      #black {
        font-weight: 600;
      }

      #light {
        color: #1c2243;
      }
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      text-align: center;
      margin: 0;
      border-bottom: 1px solid #edf2f7;
      font-size: 10px;
      letter-spacing: 0.07px;
      color: #1c2243;
      padding: 9px;
      :last-child {
        border-right: 0;
      }
    }
    th {
      color: #90a9c0;
      font-weight: 400;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  margin: 10px 0;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  color: #264d6d;
`;
