import React from 'react';
import moment from 'moment';

const DateFormatter = ({ date, midDate }) => {
  const today = moment();
  const isToday = (d) => moment(d).isSame(today, 'day');

  const shortDateFormat = (d) => moment(d).format('LT');
  const midDateFormat = (d) => moment(d).format('ll');
  const longDateFormat = (d) => moment(d).format('lll');

  // eslint-disable-next-line no-nested-ternary
  const dateFormatted = midDate
    ? midDateFormat(date)
    : isToday(date) ? `Today, ${shortDateFormat(date)}` : longDateFormat(date);

  return (
    <p>{dateFormatted}</p>
  );
};

export default DateFormatter;
