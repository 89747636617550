import React from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'Common/Components/Skeleton';
import { enableDisable } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { Wrapper, ActiveIcon, ArchivedIcon, Text } from './styled';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const EnableDisableWs = ({ isFetching, ws, setShowConfirmation }) => {
  const isWsActive = get(ws, 'active', false);
  const name = get(ws, 'name', '');
  const dispatch = useDispatch();
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.UPDATE));

  const changeWsStatus = (e, checked) => {
    if (canUpdate) {
      e.stopPropagation();
      if (isWsActive) {
        setShowConfirmation({ show: true, ws: name, isMassive: false });
      } else {
        dispatch(enableDisable(name, true));
      }
    }
  };

  if (isFetching) return <Skeleton />;
  return (
    <Wrapper onClick={ (e, isWsActive) => { changeWsStatus(e, isWsActive); } }>
      {isWsActive
        ? <>
          <ActiveIcon />
          <Text>Active</Text>
          </>
        : <>
          <ArchivedIcon />
          <Text>Archived</Text>
          </>
      }

    </Wrapper>
  );
};

export default EnableDisableWs;
