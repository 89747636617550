import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1 1 0;
  max-width: 600px;
  width: 80%;
  max-width: 44rem;
`;

export const StyledTick = styled.div`
  font-size: 8px;
  color: #63758d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: end;
`;
StyledTick.displayName = 'StyledTick';
