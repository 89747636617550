import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { selectEmail } from 'store/Faraday/selectors';
import { useSelector } from 'react-redux';
import BlueButton from 'Common/Components/BlueButton';
import {
  FormWrapper, CloseForm, FormCloseWrapper,
  ContentWrapper, SuccessWrapper, Success
} from './styled';

const Form = ({ handleClose, showForm }) => {
  const formRef = useRef();
  const email = useSelector((state) => selectEmail(state));
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const s = document.createElement('script');
    s.async = true;
    s.defer = true;
    s.src = 'https://formfacade.com/include/117107464181297733657/form/1FAIpQLScPD7yc2Lf0dSypEJPd_YmM07gXZkMVfkurXOezurRhBZ3Vzg/bootstrap.js?div=ff-compose';
    formRef.current.appendChild(s);
    const config = { childList: true, subtree: true };

    const callback = function (mutationsList) {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const emailField = formRef.current.querySelector('#ff-id-1021307804');
          const sendButton = formRef.current.querySelector('#ff-submit-root');
          const successPage = formRef.current.querySelector('#ff-success');
          if (sendButton) {
            if (sendButton.innerText === 'Submit') sendButton.innerText = 'Send';
          }
          if (emailField) {
            const emailInput = emailField.querySelector('input');
            if (emailInput.value === '') emailInput.value = email;
            if (emailField.style.display !== 'none') emailField.style.display = 'none';
          }
          if (successPage) {
            if (successPage.innerText === 'Your response has been recorded') {
              successPage.innerHTML = '<h3>Thank you!</h3> <p>Your feedback will help us to improve your experience with Faraday</p>';
              setFormSubmitted(true);
            }
          }
        }
      });
    };
    const observer = new MutationObserver(callback);
    observer.observe(formRef.current, config);
    return () => observer.disconnect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    handleClose();
    showForm(false);
  };

  return (
    <FormWrapper ref={ formRef }>
      <FormCloseWrapper onClick={ onClose }>
        <CloseForm />
      </FormCloseWrapper>
      <ContentWrapper>
        <SuccessWrapper success={ formSubmitted }>
          <Success />
          <BlueButton onClick={ onClose } label="Done!" className="btn-form" />
        </SuccessWrapper>
        <div id="ff-compose" />
      </ContentWrapper>
    </FormWrapper>
  );
};

Form.propTypes = {
  handleClose: PropTypes.func.isRequired,
  showForm: PropTypes.func.isRequired
};

export default Form;

// <div class="container" id="ff-compose"></div>
// <script async defer src="https://formfacade.com/include/117107464181297733657/form/1FAIpQLScPD7yc2Lf0dSypEJPd_YmM07gXZkMVfkurXOezurRhBZ3Vzg/bootstrap.js?div=ff-compose"></script>
