import React from 'react';
import { useSelector } from 'react-redux';
import AddTokenButton from './components/AddTokenButton';
import Wrapper from './styled';
import Table from './components/Table';
import { selectShowAddTokenModal } from 'store/Settings/accessTokens/selectors';
import AddTokenModal from './components/AddTokenModal';

const Content = () => {
  const showAddTokenModal = useSelector(selectShowAddTokenModal);
  return (
    <Wrapper>
      <AddTokenButton />
      <Table />
      { showAddTokenModal && <AddTokenModal /> }
    </Wrapper>
  );
};

export default Content;
