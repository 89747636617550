/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */
import { Accept, Cancel } from 'Common/Components/Button/styled';
import CheckBox from 'Common/Components/Checkbox';
import StandardDropdown from 'Common/Components/StandarDropdown';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { addService } from 'store/Contextualization/AssetDetail/actions';
import {
  Wrapper, Title, WrapperRow, Name, Port, Protocol, Version, Description,
  WrapperButtons, Error, WrapperField, Label, GearIcon, Column, Field,
  BottomRow, AssetsField
} from './styled';
import { selectAllHosts } from 'store/ManageEditCreate/selectors';
import { getHosts } from 'Screens/Contextualization//ManageEditCreate/actions/Actions';
import { selectCurrentHost } from 'store/Host/selectors';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import { createServiceOutsideWs, getAssetsByWorkspace } from 'store/ServicesGeneral/actions';
import { selectAssetsList } from 'store/ServicesGeneral/selectors';
import InputSearch from 'Screens/Contextualization/ManageEditCreate/components/ManualCreationModal/components/General/InputSearch';
import InputFilterGrouped from 'Common/Components/Contextualization/InputFilterGrouped';
import { createService, setCreateServiceError } from 'store/Services/actions';
import { selectErrorCreateService } from 'store/Services/selectors';
import CreationWarning from './CreationWarning';

const PORT_MIN = 0;
const PORT_MAX = 65535;

const CreateService = ({ onClose, entity }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [service, setService] = useState({
    name: '',
    ports: '',
    protocol: '',
    version: '',
    description: '',
    status: '',
    owned: false,
    assets: [],
    workspaces: []
  });
  const statusOptions = [{ name: 'open', desc: 'Open' }, { name: 'closed', desc: 'Closed' }, { name: 'filtered', desc: 'Filtered' }];
  const assets = useSelector(selectAllHosts);
  const validatePort = () => !(PORT_MIN <= service.ports && service.ports <= PORT_MAX) && setService({ ...service, ports: '' });
  const currentHost = useSelector(selectCurrentHost);
  const workspacesOptions = useSelector(selectActiveWorkspaces);
  const assetsByWorkspace = useSelector(selectAssetsList);
  const assetsOptions = entity === 'services' ? assets : !isEmpty(service.workspaces) ? assetsByWorkspace : null;
  const showErrorCreateService = useSelector(selectErrorCreateService);

  const onChange = (field, value) => setService({ ...service, [field]: value });

  const canCreate = () => {
    if (entity === 'services') {
      return service.name && service.ports && service.protocol && service.status && !isEmpty(service.assets);
    } else if (entity === 'servicesAsset') {
      return service.name && service.ports && service.protocol && service.status;
    } else return service.name && service.ports && service.protocol && service.status && !isEmpty(service.workspaces) && !isEmpty(service.assets);
  };

  const texts = {
    title: intl.formatMessage({ id: 'host.detail.services.addEditService.createTitle' }),
    cancel: intl.formatMessage({ id: 'host.detail.services.addEditService.cancel' }),
    save: intl.formatMessage({ id: 'host.detail.services.addEditService.save' }),
    name: intl.formatMessage({ id: 'host.detail.services.addEditService.name' }),
    port: intl.formatMessage({ id: 'host.detail.services.addEditService.port' }),
    protocol: intl.formatMessage({ id: 'host.detail.services.addEditService.protocol' }),
    version: intl.formatMessage({ id: 'host.detail.services.addEditService.version' }),
    description: intl.formatMessage({ id: 'host.detail.services.addEditService.description' }),
    assetsErrorTitle: intl.formatMessage({ id: 'modal.createVuln.missingWs.title' }),
    assetsErrorMessage: intl.formatMessage({ id: 'modal.createVuln.missingWs.message' })
  };

  const props = useSelector((state) => ({
    host: state.hostDetail.host,
    errorMessage: get(state, 'assetDetail.services.errorMessage', '')
  }));

  useEffect(() => () => {
    setService({ name: '', ports: '', protocol: '', version: '', description: '', status: '', owned: false, assets: [], workspace: '' });
  }, []);

  const onSave = () => {
    if (canCreate) {
      if (entity === 'services') {
        dispatch(createService(service));
      } else if (entity === 'servicesAsset') {
        dispatch(addService(service));
      } else {
        dispatch(createServiceOutsideWs(service));
      }
    }
  };

  useEffect(() => {
    if (entity === 'services' || entity === 'servicesAsset') dispatch(getHosts());
    if (entity === 'servicesAsset') {
      setService({ ...service, assets: [...service.assets, currentHost] });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(service.workspaces) && (entity === 'servicesGeneral')) dispatch(getAssetsByWorkspace(service.workspaces));
  }, [service.workspaces]);

  const setWorkspace = (value) => {
    const temp = service.workspaces;
    const found = temp.find((item) => item.id === value.id);
    if (!found) onChange('workspaces', [...service.workspaces, value]);
  };

  const onRemoveWorkspace = (value) => {
    const temp = service.workspaces;
    const filteredWorkspaces = temp.filter((item) => item.id !== value.id);
    if (!isEmpty(service.assets)) {
      const filteredAssets = service.assets.filter((asset) => asset.value.workspace_name !== value.name);
      setService({ ...service, assets: filteredAssets, workspaces: filteredWorkspaces });
    } else {
      onChange('workspaces', filteredWorkspaces);
    }
  };

  const setAsset = (value) => {
    const temp = service.assets;
    const found = temp.find((item) => item.id === value.id);
    if (!found) onChange('assets', [...service.assets, value]);
  };

  const setAssetGeneral = (element) => {
    if (element) {
      const temp = service.assets;
      const found = temp.find((item) => item.value.id === element[element.length - 1].value.id);
      if (!found) onChange('assets', [...service.assets, element[element.length - 1]]);
    }
  };

  return (
    <Wrapper>
      <WrapperRow>
        <Title>{ texts.title }</Title>
        <WrapperButtons>
          <Cancel onClick={ onClose }>{ texts.cancel }</Cancel>
          <Accept onClick={ onSave } disabled={ !canCreate() }>{ texts.save }</Accept>
        </WrapperButtons>
        <GearIcon />
      </WrapperRow>
      <WrapperRow>
        <Name title={ texts.name } name="name" value={ service.name } onChange={ (value) => onChange('name', value) } />
        <StandardDropdown
          field="status"
          options={ statusOptions }
          updateValue={ (field, value) => onChange('status', value) }
          placeholder="Status"
          defaultValue={ service.status }
          width="160px"
        />
      </WrapperRow>
      <WrapperRow>
        <Port type="number" min={ PORT_MIN } max={ PORT_MAX } title={ texts.port } name="port" value={ service.ports } onChange={ (value) => onChange('ports', value) } onBlur={ validatePort } />
        <Protocol title={ texts.protocol } name="protocol" value={ service.protocol } onChange={ (value) => onChange('protocol', value) } />
        <Version title={ texts.version } name="version" value={ service.version } onChange={ (value) => onChange('version', value) } />
      </WrapperRow>
      <WrapperRow>
        <Description title={ texts.description } name="description" value={ service.description } onChange={ (value) => onChange('description', value) } />
      </WrapperRow>
      <BottomRow>
      { ((entity !== 'services') && (entity !== 'servicesAsset')) &&
        <Column>
          <Field>
            <InputSearch
              onSelect={ setWorkspace }
              data={ workspacesOptions }
              placeholder="Add a Workspace"
              id="service-add-workpaces"
              title="Workspace(s)"
              required
              addedItems={ service.workspaces }
              removeItem={ onRemoveWorkspace }
              fullWidth
              height="185px"
            />
          </Field>
        </Column>
      }
        <Column>
        { (entity === 'services') &&
          <Field>
              <Label>Asset*</Label>
              <StandardDropdown field="assets" placeholder="Select asset" options={ isEmpty(assetsOptions) ? [] : assetsOptions.map((el) => ({ name: el.ip, desc: el.ip })) } updateValue={ (field, value) => setAsset(value, field) } width="250px" isSearchable />
          </Field>
        }
        { (entity === 'servicesGeneral') &&
          <AssetsField>
            <InputFilterGrouped
              options={ assetsOptions }
              required
              title="Asset(s)"
              id="service-add-assets"
              placeholder="Add Asset"
              groupNameField="workspace_name"
              addedItems={ service.assets }
              addItems={ (value) => setAssetGeneral(value) }
              setItems={ (elements) => onChange('assets', elements) }
              width="238px"
              cantSelect={ isEmpty(service.workspaces) }
              setError={ () => dispatch(setCreateServiceError(true, texts.assetsErrorMessage, texts.assetsErrorTitle)) }
            />
          </AssetsField>
        }
        </Column>
      </BottomRow>
      <WrapperField>
          <CheckBox onChange={ () => onChange('owned', !service.owned) } state={ service.owned } />
          <Label>Owned</Label>
      </WrapperField>
      <WrapperRow>
        { props.errorMessage && <Error>{ props.errorMessage }</Error> }
        { showErrorCreateService && <CreationWarning /> }
      </WrapperRow>
    </Wrapper>
  );
};

export default CreateService;
