import styled from 'styled-components';
import RadioButton from 'Common/Components/RadioButton/index';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  margin-left: 27px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  margin-bottom: 21px;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  text-align: left;
  user-select: none;
`;
Title.displayName = 'Title';

export const StyledRadio = styled(RadioButton)`
  color: ${({ checked }) => (checked ? '#000' : '#1c2243')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')} ;
  opacity: ${({ disabled }) => (disabled ? '0.62' : '1')};
`;
StyledRadio.displayName = 'StyledRadio';
