import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch, useSelector } from 'react-redux';
import { getVulnsPreview } from 'store/ExecutiveReportEditCreate/actions';
import { selectSelectedTemplate, selectWorkspaces } from 'store/ExecutiveReportEditCreate/selectors';
import { useIntl } from 'react-intl';
import sectionComponents from './components/sections';

import {
  Wrapper, Title, EditSubtitle, AddSubtitle
} from './styled';

const Sections = () => {
  const dispatch = useDispatch();
  const workspaces = useSelector(selectWorkspaces);
  const filteredWs = workspaces ? workspaces.filter((ws) => ws !== '') : null;
  const hasWorkspaces = workspaces.length > 0;
  const selectedTemplate = useSelector(selectSelectedTemplate);
  const showSections = selectedTemplate.length > 0;
  const [sections, setSections] = useState(cloneDeep(sectionComponents));
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'reports.editCreate.sections.title' });
  const editSubtitle = intl.formatMessage({ id: 'reports.editCreate.sections.edit' });
  const addSubtitle = intl.formatMessage({ id: 'reports.editCreate.sections.add' });
  const canGetVulns = hasWorkspaces && !workspaces.some((ws) => ws === '');

  const setSection = (enable, id) => {
    const section = sections.find((s) => s.id === id);
    section.added = enable;
    setSections([
      ...sections.filter((s) => s.id !== id),
      section
    ]);
  };
  const deleteSection = (id) => setSection(false, id);
  const addSection = (id) => setSection(true, id);

  const added = (s) => s.added === true;
  const notAdded = (s) => s.added === false;
  const sortById = (a, b) => a.id - b.id;
  const hasSectionsNotAdded = sections.filter(notAdded).length > 0;

  useEffect(() => {
    if (canGetVulns) dispatch(getVulnsPreview(workspaces));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filteredWs]);

  return showSections
    ? (
    <Wrapper>
      <Title label={ title } />
      <EditSubtitle label={ editSubtitle } />
      {sections.filter(added).sort(sortById).map((s) => s.component(deleteSection, addSection, s.added))}
      { hasSectionsNotAdded && <AddSubtitle label={ addSubtitle } /> }
      {sections.filter(notAdded).sort(sortById).map((s) => s.component(deleteSection, addSection, s.added))}
    </Wrapper>
      )
    : null;
};
export default Sections;
