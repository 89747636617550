import api from 'services/api';
import * as types from './types';
import get from 'lodash/get';
import { redirect } from 'store/Router/actions';
import {
  selectLastSelected, selectSelectAllServices, selectServiceDetailId,
  selectServiceWorkspace,
  selectServices, selectServicesCount, selectServicesSelected,
  selectShowDetail
} from './selectors';
import { selectAdvancedFilterQueryParam, selectQueryParam, selectRowsPerPage } from 'store/Filters/selectors';
import { setFilterError, setOrderBy, setPageNumber } from 'store/Filters/actions';
import { HOST_ADD_EDIT_SERVICE_FAIL } from 'store/HostDetail/types';
import { closeModal } from 'store/modals/actions';
import { MODAL_CREATE_SERVICE } from 'store/modals/modals';
import { isEmpty } from 'lodash';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { getGeneralHostsTags, getGeneralServicesTags, getGeneralTags } from 'Screens/Contextualization/Tags/actions/Actions';

export const somethingWentWrong = (errorMessage) => async (dispatch) => dispatch({ type: types.GENERAL_SERVICES_FAIL, errorMessage: errorMessage || 'There was an error, please try again.' });

export const clearError = () => async (dispatch) => dispatch({ type: types.GENERAL_SERVICES_CLEAR_ERROR });

export const fetchingStart = () => async (dispatch) => dispatch({ type: types.GENERAL_SERVICES_FETCHING });

const setServicesFilterError = () => async (dispatch) => dispatch({ type: types.SET_GENERAL_SERVICES_FILTER_ERROR });

export const getServicesGeneral = () => async (dispatch, getState) => {
  dispatch(fetchingStart());
  let advancedFilterQueryParam = [];
  try {
    advancedFilterQueryParam = selectAdvancedFilterQueryParam(getState(), 'servicesGeneral');
  } catch (e) {
    dispatch(setFilterError('servicesGeneral', 'Syntax error. Please try again. For further help check our documentation'));
    return dispatch(setServicesFilterError());
  }

  const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
  const standardQueryParam = selectQueryParam('servicesGeneral', getState());
  const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

  try {
    const response = await api.servicesGeneral.fetchServices(queryParam);
    const data = response.services.map((service) => service.value);
    return dispatch({ type: types.GENERAL_SERVICES_GET_SUCCESS, data, count: response.count });
  } catch (error) {
    return dispatch(somethingWentWrong(error.message));
  }
};

export const redirectToServices = () => (dispatch) => {
  dispatch(redirect('/services'));
};

export const redirectToServiceDetailGeneral = (id) => (dispatch) => {
  dispatch(redirect(`/services/${id}`));
};

export const showServiceDetail = (service) => (dispatch) => {
  dispatch({ type: types.GENERAL_SERVICES_SHOW_DETAIL, service });
};

export const loadServiceDetail = (id) => async (dispatch) => {
  if (id > 0) {
    try {
      const service = await api.servicesGeneral.fetchServiceById(id);
      dispatch(showServiceDetail(service));
    } catch (e) {
      dispatch(redirectToServices());
    }
  }
};

export const hideServiceDetail = () => (dispatch, getState) => {
  const showServiceDetail = selectShowDetail(getState());
  if (showServiceDetail) dispatch({ type: types.GENERAL_SERVICES_HIDE_DETAIL });
};

export const unselectedRowFromSelectAll = (value) => (dispatch) => {
  dispatch({ type: types.GENERAL_SERVICES_UNSELECTED_ROW_FROM_SELECT_ALL, value });
};

export const selectTotalServices = () => (dispatch) => {
  return dispatch({ type: types.GENERAL_SERVICES_SELECT_TOTAL });
};

export const unselectTotalServices = () => (dispatch) => {
  return dispatch({ type: types.GENERAL_SERVICES_UNSELECT_TOTAL });
};

export const autoSelectService = (service) => (dispatch) => {
  dispatch({ type: types.GENERAL_SERVICES_AUTOSELECT, service });
};

const addDeleteController = (services, serviceList) => {
  const allServicesAreSelected = services.every((testService) => serviceList.some((service) => service._id === testService._id));

  if (allServicesAreSelected) return [types.GENERAL_SERVICES_UNSELECTED, services];
  return [types.GENERAL_SERVICES_SELECTED, services];
};

const selectCalculator = (e, service, serviceList, areServiceSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = serviceList.findIndex((el) => el._id === service._id);
  const servicesSelected = selectServicesSelected(getState());

  dispatch({ type: types.GENERAL_SERVICES_NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areServiceSelected) {
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const services = serviceList.slice(start, end);
    const [type, payload] = addDeleteController(services, servicesSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([service], servicesSelected);
  return dispatch({ type, payload });
};

export const selectServiceRow = (e, service) => (dispatch, getState) => {
  const allService = selectServices(getState());
  const serviceSelected = selectServicesSelected(getState());
  const selectAll = selectSelectAllServices(getState());
  dispatch(selectCalculator(e, service, allService, serviceSelected.length > 0));
  if (selectAll) {
    dispatch(unselectedRowFromSelectAll(true));
    dispatch(unselectTotalServices());
  }
};

export const unSelectAllServices = () => (dispatch) => dispatch({ type: types.GENERAL_SERVICES_UNSELECT_ALL });

export const selectAllService = () => (dispatch, getState) => {
  const servicesList = selectServices(getState());
  const servicesSelected = selectServicesSelected(getState());
  const serviceCount = selectServicesCount(getState());
  const pageSize = selectRowsPerPage('services', getState());

  if (servicesSelected.length === serviceCount || servicesSelected.length >= pageSize) return dispatch(unSelectAllServices());

  return dispatch({ type: types.GENERAL_SERVICES_SELECT_ALL, servicesList });
};

export const showServiceModalDelete = () => (dispatch) => {
  dispatch({ type: types.GENERAL_SERVICES_SHOW_MODAL_DELETE });
};

export const hideServiceModalDelete = () => (dispatch) => {
  dispatch({ type: types.GENERAL_SERVICES_HIDE_MODAL_DELETE });
};

export const deleteServicesGeneralSelected = () => async (dispatch, getState) => {
  const state = getState();
  const servicesSelected = selectServicesSelected(state);
  const servicesSelectedID = servicesSelected.map(service => service._id);
  const selectAll = selectSelectAllServices(state);

  const advancedFilterQueryParam = selectAdvancedFilterQueryParam(getState(), 'servicesGeneral');
  const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
  const standardQueryParam = selectQueryParam('servicesGeneral', getState());
  const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

  try {
    if (selectAll) {
      await api.servicesGeneral.deleteAllServices({ filters: queryParam.filters });
    } else {
      const response = await api.servicesGeneral.deleteService(servicesSelectedID);
      dispatch({ type: types.GENERAL_SERVICES_DELETE_SUCCESS, response });
    }
    dispatch(hideServiceModalDelete());
    return dispatch(getServicesGeneral());
  } catch (err) {
    dispatch({ type: types.GENERAL_SERVICES_DELETE_FAIL });
  }
};

export const updateService = (serviceId, valueObj) => async (dispatch, getState) => {
  const serviceList = selectServices(getState());
  const serviceCount = selectServicesCount(getState());
  const serviceDetailId = selectServiceDetailId(getState());
  const workspace = selectServiceWorkspace(getState());
  const newService = await api.service.updateService(workspace, { id: serviceId, ...valueObj });
  const index = serviceList.findIndex((x) => serviceId === x.id || serviceId === x._id);
  serviceList[index] = { ...newService, severity_counts: serviceList[index].severity_counts };
  dispatch({ type: types.GENERAL_SERVICES_UPDATE_SUCCESS, data: serviceList, serviceCount });
  if (serviceDetailId && newService._id === serviceDetailId) dispatch(showServiceDetail(newService));
};

export const resetServiceDetail = () => async (dispatch) => {
  dispatch({ type: types.GENERAL_SERVICES_RESET_EDIT });
};

export const setPageNumberServices = (pageNumber) => (dispatch) => {
  dispatch(setPageNumber('servicesGeneral', pageNumber));
  dispatch(getServicesGeneral());
};

export const resetState = () => (dispatch) => {
  dispatch({ type: types.GENERAL_SERVICES_RESET_STATE });
};

export const createServiceOutsideWs = (service) => async (dispatch) => {
  try {
    const { name, assets, status, ports, protocol, version, description, owned } = service;
    const portsParsed = ports.toString().split(',');

    const promises = assets.map((asset) => {
      const serviceObject = {
        name,
        parent: asset.value.id,
        status,
        ports: portsParsed,
        protocol,
        type: 'Service',
        version,
        description,
        owned,
        owner: ''
      };
      return api.servicesGeneral.createService(asset.value.workspace_name, serviceObject);
    });
    const response = await Promise.all(promises);
    dispatch({ type: types.GENERAL_SERVICES_ADD_SERVICE_SUCCESS, services: response });

    dispatch(closeModal(MODAL_CREATE_SERVICE));
  } catch (e) {
    const message = get(e, 'message', 'There was an error, please try again.');
    return dispatch({ type: HOST_ADD_EDIT_SERVICE_FAIL, message });
  }
};

export const getAssetsByWorkspace = (workspaces, stats = false) => async (dispatch) => {
  dispatch({ type: types.GET_ASSETS_BY_WORKSPACE_REQUEST });
  try {
    const promises = workspaces.map((ws) => api.host.getHosts(ws.name, stats));
    const response = await Promise.all(promises);
    let newData = [];
    if (!isEmpty(response)) {
      response.forEach((element) => {
        const workspace = get(element, 'rows[0].value.workspace_name', '');
        const items = element.rows.map((item) => item.value);
        newData = [...newData, { groupName: workspace, items }];
      });
      return dispatch({ type: types.GET_ASSETS_BY_WORKSPACE_SUCCESS, data: newData });
    }
  } catch (error) {
    return dispatch({ type: types.GET_ASSETS_BY_WORKSPACE_FAIL, error: error || 'An error has occurred.' });
  }
};

export const setServicesGeneralTags = (tagsToAdd = [], tagsToRemove = []) => async (dispatch, getState) => {
  try {
    const state = getState();
    const servicesSelected = selectServicesSelected(state);
    const servicesList = selectServices(state);
    const selectedServicesIds = servicesSelected.map((service) => (service._id));
    const data = {
      service_ids: selectedServicesIds,
      tags_to_add: tagsToAdd,
      tags_to_remove: tagsToRemove
    };

    await api.servicesGeneral.setServicesTags(data);

    const newTags = tagsToAdd.filter((tag) => !tagsToRemove.includes(tag));

    selectedServicesIds.forEach((id) => {
      const service = find(servicesSelected, { _id: id });
      const newTagsList = newTags.filter((tag) => !service.tags.includes(tag));
      service.tags = [
        ...service.tags.filter((tag) => !tagsToRemove.includes(tag)),
        ...newTagsList
      ];

      const indexServicesList = findIndex(servicesList, { _id: service._id });
      if (indexServicesList > -1 && !isEmpty(servicesList)) servicesList.splice(indexServicesList, 1, service);

      const indexVulnsSelected = findIndex(servicesSelected, { _id: id });
      if (indexServicesList > -1 && !isEmpty(servicesSelected)) servicesSelected.splice(indexVulnsSelected, 1, service);
    });

    dispatch({ type: types.GENERAL_SERVICES_SET_TAGS_SUCCESS });
    dispatch(getGeneralTags());
    dispatch(getGeneralHostsTags());
    dispatch((getGeneralServicesTags()));
  } catch (e) {
    dispatch({ type: types.GENERAL_SERVICES_SET_TAGS_FAIL, error: e.message });
  }
};

export const addServiceTag = (tag) => {
  return (dispatch) => {
    dispatch(setServicesGeneralTags([tag], []));
  };
};

export const removeServiceTag = (tag) => {
  return (dispatch) => {
    dispatch(setServicesGeneralTags([], [tag]));
  };
};

export const setOrderByServicesGeneral = (sorting) => (dispatch) => {
  dispatch(setOrderBy('servicesGeneral', sorting));
  dispatch(getServicesGeneral());
};
