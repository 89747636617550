import React from 'react';
import { Accept, Cancel } from 'Common/Components/Button/styled';
import { useIntl } from 'react-intl';
import {
  Center, Description, ButtonContainer, Title
} from './styled';

const AuthorizedModal = ({ onUnAuthorized, onAuthorized }) => {
  const intl = useIntl();
  return (
    <Center>
      <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.jira.oauth.authorizedModal.title' }) }</Title>
      <Description>{ intl.formatMessage({ id: 'preferences.ticketingTools.jira.oauth.authorizedModal.description' }) }</Description>
      <ButtonContainer>
        <Cancel onClick={ onUnAuthorized }>{ intl.formatMessage({ id: 'preferences.ticketingTools.jira.oauth.authorizedModal.unauthorized' }) }</Cancel>
        <Accept onClick={ onAuthorized }>{ intl.formatMessage({ id: 'preferences.ticketingTools.jira.oauth.authorizedModal.authorized' }) }</Accept>
      </ButtonContainer>
    </Center>
  );
};
export default AuthorizedModal;
