import React from 'react';
import FieldTypeComponents from 'Common/Components/JiraTemplateModal/Components/FieldTypeComponents';

const IssueType = ({
  type, title, options, onChange, issueType, ...data
}) => {
  // Se usa replace para pasar de option-with-child a optionwithchild
  const SelectedComponent = FieldTypeComponents[type.replaceAll('-', '')]; // FieldTypeComponent es un objeto con todos los componentes
  if (!SelectedComponent) return <div />;

  return <SelectedComponent setValue={ onChange } title={ title } options={ options } key={ `${issueType}-${title}` } { ...data } />;
};

export default IssueType;
