import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  ConfirmButton, CancelButton, Warning, ModalBody
} from './styled';
import useKeyPress from 'Hooks/useKeyPress';

const ModalConfirmation = ({
  handleSubmit, handleClose, entity, count, message, loading
}) => {
  const upperCaseTitle = entity.charAt(0).toUpperCase() + entity.slice(1);
  const modalText = message || `You’re deleting ${count} ${upperCaseTitle}${count > 1 ? '(s)' : ''}. This action cannot be undone. Are you sure you want to proceed?`;
  useKeyPress(() => {
    handleSubmit();
  }, ['Enter']);

  useKeyPress(() => {
    handleClose();
  }, ['Escape']);

  useKeyPress(() => {
    if (handleSubmit) handleSubmit();
  }, ['Enter']);

  useKeyPress(() => {
    if (handleClose) handleClose();
  }, ['Escape']);

  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>{`Delete ${upperCaseTitle}${count > 1 ? '(s)' : ''}`}</Title>
        </ModalTitle>
        <ModalBody>
          <BodyText>{modalText}</BodyText>
        </ModalBody>
        <ButtonsContainer>
          <CancelButton onClick={ handleClose }>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={ handleSubmit } disabled={ loading }>
            Delete
          </ConfirmButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ModalConfirmation;
