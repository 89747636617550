/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectVulnDetail, selectDetailDuplicates } from 'store/ManageGeneral/selectors';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_DUPLICATES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { Wrapper, ListWrapper, Subtitle, DuplicatesWrapper } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/Duplicates/styled';
import DuplicateItem from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/Duplicates/DuplicateItem';
import ExpandableWrapper from '../ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { getDuplicates, markAsMainDuplicate, removeDuplicate } from 'store/ManageGeneral/duplicateActions';
import { redirectToVulnDetailGeneral, unSelectAllVulns } from 'store/ManageGeneral/actions';

const ID = 'general';

const Duplicates = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id, name } = useSelector(selectVulnDetail);
  const duplicates = useSelector(selectDetailDuplicates);
  const canUseDuplicates = useSelector((state) => selectAllowedByFeature(state, FEATURE_DUPLICATES, PERMISSION_TYPES.READ) && selectAllowedByRole(state, FEATURE_DUPLICATES, PERMISSION_TYPES.READ));
  const isExpanded = useExpandable(ID, 'duplicates', duplicates);

  useEffect(() => {
    if (canUseDuplicates) dispatch(getDuplicates(id));
  }, [dispatch, canUseDuplicates, id, name]);

  if (duplicates.length === 0) return null;
  return canUseDuplicates && (
    <DuplicatesWrapper>
      <ExpandableWrapper
        title={ intl.formatMessage({ id: 'vuln.detail.duplicates.title' }) }
        count={ duplicates.length }
        defaultValue={ isExpanded }
      >
        <Wrapper>
          <Subtitle children={ intl.formatMessage({ id: 'vuln.detail.duplicates.subtitle' }) } />
          <ListWrapper>
            { duplicates.map((vuln) => <DuplicateItem key={ vuln.id } vuln={ vuln } isDetailVuln={ vuln.id === id } markAsMainDuplicate={ markAsMainDuplicate } removeDuplicate={ removeDuplicate } redirectToVulnDetail={ redirectToVulnDetailGeneral } unSelectAll={ () => dispatch(unSelectAllVulns()) } />)}
          </ListWrapper>
        </Wrapper>
      </ExpandableWrapper>
    </DuplicatesWrapper>
  );
};

export default Duplicates;
