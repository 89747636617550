import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  viewJob, existsEmptyTasks, existsEmptyRules, saveJob
} from 'store/Jobs/actions';
import {
  selectJobName, selectJobModel, selectJobTasks, selectJobRules, selectJobId, selectJobPipelines, selectLimitReached
} from 'store/Jobs/selectors';
import { closeModal, openModal } from 'store/modals/actions';
import { MODAL_SAVE_JOB, MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import SaveJobAlert from 'Screens/Automation/Jobs/components/Detail/components/SaveJobAlert';
import ReduxModal from 'Common/Components/ReduxModal';
import { redirect } from 'store/Router/actions';
import { setRedirectTo } from 'store/Pipelines/actions';
import { selectRedirectTo } from 'store/Pipelines/selectors';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import { ACTIONS } from 'tracking/GA/constants';
import {
  Wrapper, WrapperTitle, Title, Id, WrapperButtons, CancelButton, CustomBlueButton
} from './styled';

const Header = () => {
  const id = useSelector(selectJobId);
  const name = useSelector(selectJobName);
  const model = useSelector(selectJobModel);
  const tasks = useSelector(selectJobTasks);
  const rules = useSelector(selectJobRules);
  const pipelines = useSelector(selectJobPipelines);
  const redirectToPipeline = useSelector(selectRedirectTo);
  const limitReached = useSelector(selectLimitReached);

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => () => {
    dispatch(closeModal(MODAL_SAVE_JOB));
  }, [dispatch]);

  const allowSaveJob = () => !isEmpty(name) &&
    !isEmpty(model) &&
    !isEmpty(tasks.filter((t) => !t.deleted)) &&
    !existsEmptyTasks(tasks) &&
    !isEmpty(rules) &&
    !existsEmptyRules(rules);

  const handleSaveJob = () => {
    if (id === 0) {
      if (limitReached) dispatch(openModal(MODAL_UPGRADE_LICENSE));
      else dispatch(saveJob(true));
    } else if (isEmpty(pipelines)) dispatch(saveJob(false));
    else dispatch(openModal(MODAL_SAVE_JOB));
  };

  const handleCancel = () => {
    if (isEmpty(redirectToPipeline)) {
      dispatch(viewJob(null));
      dispatch(redirect('/automation/jobs'));
    } else {
      dispatch(redirect(redirectToPipeline));
      dispatch(setRedirectTo(''));
    }
  };

  return (
    <Wrapper>
      <WrapperTitle>
        <Title>Job Editor</Title>
        <Id>{ `Job ID = ${id}`}</Id>
      </WrapperTitle>
      <WrapperButtons>
        <CancelButton onClick={ handleCancel }>{ intl.formatMessage({ id: 'pipelines.detail.header.cancelButton' }) }</CancelButton>
        <CustomBlueButton disabled={ !allowSaveJob() } onClick={ handleSaveJob } label={ intl.formatMessage({ id: 'pipelines.detail.header.saveButton' }) } />
      </WrapperButtons>
      <ReduxModal id={ MODAL_SAVE_JOB }>
        <SaveJobAlert />
      </ReduxModal>
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.jobs.description" trackingShow={ ACTIONS.showAddJobs } trackingClick={ ACTIONS.clickAddJobs } />
      </ReduxModal>
    </Wrapper>
  );
};
export default Header;
