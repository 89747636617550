import styled from 'styled-components';
import colors from 'Styles/colors';

export const BlueText = styled.p`
  font-size: 12.5px;
  font-weight: 500;
  color: ${colors.blueCerulean};
  text-align: center;
  padding: 0 15px;
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
BlueText.displayName = 'BlueText';

export const Text = styled.p`
  font-size: 12.5px;
  font-weight: 500;
  color: ${colors.dark2};
  text-align: center;
  margin-top: 5px;
`;
Text.displayName = 'Text';
