import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 0 0 300px;
  min-height: 25px;
  flex: 0 0 310px;
  height: 25px;
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  color: #90a9c0;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
Label.displayName = 'Label';
