import fetchApi from 'services/api/connector';

// eslint-disable-next-line import/prefer-default-export
export const getTags = (ws) => fetchApi(`ws/${ws}/tags`);

export const getHostsTags = (ws) => fetchApi(`ws/${ws}/tags/host_tags`);

export const getServicesTags = (ws) => fetchApi(`ws/${ws}/tags/service_tags`);

export const getContextTags = () => fetchApi('tags');

export const getContextHostsTags = () => fetchApi('tags/host_tags');

export const getContextServicesTags = () => fetchApi('tags/service_tags');
