import styled from 'styled-components';
import { ReactComponent as MoreIcon } from 'Images/icon-action-bar-more.svg';

const More = styled(MoreIcon).attrs({
  id: 'qa-more-ws'
})`
      height: 17px;
      width: 21px;
    `;

More.displayName = 'More';

export default More;
