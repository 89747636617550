import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const Button = styled.div`
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;
  cursor: pointer;
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
  color: #1c2243;
  height: 34px;
  width: 155px;
  border-radius: 2px;
  border: solid 1px ${colors.grey12};
  background-color: ${colors.iceBlue};
  & > svg {
    height: 11px;
    margin-right: 10px;
     & > path {
      fill: #1c2243;
    }
  }
  &:hover {
    background-color: #ffffff;
  }

  &:active {
    background-color: #d9e4ef;
  }
  
`;
