import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    opacity: 0.4;
  `};

  line-height: initial;

  .dropdown {

    .dropdown-toggle {
      -webkit-appearance: unset;
      
      &:after {
        display: none !important;
      }
    }

    .dropdown-menu {
      width: 244px;
      height: 76px;
      border-radius: 3px;
      box-shadow: 2px 2px 30px 0 #d5dee6;
      border: solid 1px #cdd5dd;
      background-color: #ffffff;
      padding: 3px 4px;

      .dropdown-item {
        height: 34px;
        padding: 10px 17px;

        svg {
          position: relative;
          top: -3px;
          margin-right: 15px;
        }

        &:hover {
          background-color: #f1f4f7;
          font-weight: 600;
        }
      }

      .nested-dropdown {
        #dropdown-button-drop-right {
          background-color: #ffffff;
          height: 31px;
          color: #1c2243;
          border: none;
          width: 100%;
          text-align: left;
          padding: 6px 26px;

          &:hover {
            background-color: #f1f4f7;

            span {
              font-weight: 600 !important;
            }
          }
        }
      }

      .dropdown-menu {
        position: relative !important;
        width: 426px;
        height: 432px;
        border-radius: 3px;
        box-shadow: 2px 2px 30px 0 #d5dee6;
        border: solid 1px #cdd5dd;
        background-color: #ffffff;
        transform: translate3d(242px, -131px, 0px) !important;
      }
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export const AddUserBtn = styled.div`
  ${({ disabled }) => disabled && 'pointer-events: none'};
  padding: 0px 10px;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(144, 169, 192, 0.3);
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    height: 11px;
    margin-right: 10px;
     & > path {
      fill: ${colors.dark2};
    }
  }
  &:hover {
    border-radius: 3px;
    border: solid 1px #d8dee5;
    background-color: #f1f4f7;
  }

  &:active {
    border: solid 1px #bcccd9;
    background-color: #dde5ec;
  }
  
`;
AddUserBtn.displayName = 'AddUserBtn';
