import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectSelectAll } from 'store/Manage/selectors';
import { selectIsFiltering } from 'store/Filters/selectors';
import { setCreationTagModal } from 'Screens/Tags/actions/Actions';
import {
  Wrapper, InputWrapper, Input,
  Label, AddButton
} from './styled';

const InputTag = ({ addTags, allTags, hasItems }) => {
  const [tags, setTags] = useState('');
  const intl = useIntl();
  const addLabel = intl.formatMessage({ id: 'manage.detail.tab.general.tags.add' });
  const inputLabel = intl.formatMessage({ id: 'manage.input.label' });
  const dispatch = useDispatch();
  const selectAll = useSelector(selectSelectAll);
  const isFiltering = useSelector(state => selectIsFiltering('vulns', state));

  const handleClick = () => {
    if (tags) {
      const parsedTags = tags.split(',').map((t) => t.trim());
      if (selectAll || (selectAll && isFiltering)) {
        const hasText = parsedTags.some((t) => t !== '');
        if (hasText) {
          addTags(parsedTags);
          dispatch(setCreationTagModal(false, 'menu'));
        }
      } else {
        const newTags = parsedTags.filter((tag) => !(allTags.some((t) => (t.name === tag) || t === tag)));
        const hasText = newTags.some((t) => t !== '');
        if (newTags && hasText) {
          addTags(newTags);
        }
      }

      setTags('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <Wrapper hasItems={ hasItems }>
      <InputWrapper>
        <Input value={ tags } onChange={ (e) => setTags(e.target.value) } onKeyDown={ (e) => { handleKeyDown(e); } } />
        <Label>{ inputLabel }</Label>
      </InputWrapper>
      <AddButton type="submit" onClick={ handleClick }>{ addLabel }</AddButton>
    </Wrapper>

  );
};

export default InputTag;
