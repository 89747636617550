import React from 'react';
import { useSelector } from 'react-redux';
import { selectVulnDetail } from 'store/Manage/selectors';
import SeverityPicker from './components/SeverityPicker';
import DisplayConfirmed from './components/DisplayConfirmed';
import EaseOfResolutionPicker from './components/EaseOfResolutionPicker';
import StatusPicker from './components/StatusPicker';
import Wrapper from './styled';
import ShowRiskScore from './components/ShowRiskScore';

const ActionBar = () => {
  const currentVuln = useSelector(selectVulnDetail);
  return (
    <Wrapper>
      <ShowRiskScore currentVuln={ currentVuln } />
      <SeverityPicker />
      <DisplayConfirmed />
      <EaseOfResolutionPicker />
      <StatusPicker />
    </Wrapper>
  );
};

export default ActionBar;
