import styled from 'styled-components';
import image from 'Images/empty_state_graph_list.png';
import colors from 'Styles/colors';
import BlueButton from 'Common/Components/BlueButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`;
Wrapper.displayName = 'Wrapper';

export const EmptyImage = styled.img.attrs({
  src: image
})`
  width: 100%;
  max-width: 515px;
  margin-bottom: 30px;
  height: auto;
`;
EmptyImage.displayName = 'EmptyImage';

export const Title = styled.div`
  font-size: 15px;
  text-align: center;
  color: ${colors.blueGrey};
  margin-bottom: 21px;
`;
Title.displayName = 'Title';

export const Button = styled(BlueButton)`
  padding: 7px 28px;
`;
BlueButton.displayName = 'BlueButton';
