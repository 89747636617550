import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modals/actions';
import { MODAL_REPORT_WARNING_LOGO } from 'store/modals/modals';
import { Cancel } from 'Common/Components/Button/styled';
import {
  Wrapper, Header, Warning, Title, Message,
  Table, Line, Property, Op, Value, Footer
} from './styled';

const ModalWarning = () => {
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(closeModal(MODAL_REPORT_WARNING_LOGO));
  }, [dispatch]);

  const close = () => dispatch(closeModal(MODAL_REPORT_WARNING_LOGO));

  return (
    <Wrapper>
      <Header>
        <Warning />
        <Title>Warning</Title>
      </Header>

      <Message>
      Required image format:
      <Table>
        <tbody>
          <Line>
            <Property>Dimension</Property>
            <Op>=</Op>
            <Value>500 x 500 pixels maximum</Value>

          </Line>
          <Line>
            <Property>File size</Property>
            <Op>=</Op>
            <Value>3.5MB max</Value>
          </Line>
          <Line>
            <Property>File type</Property>
            <Op>=</Op>
            <Value>.png and .jpeg</Value>

          </Line>
        </tbody>
      </Table>
      </Message>
      <Footer>
        <Cancel onClick={ close }>Close</Cancel>
      </Footer>
    </Wrapper>
  );
};

export default ModalWarning;
