import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import { updateNotificationType } from 'store/Notifications/actions';
import api from 'services/api';

const CheckColumn = ({ userId, initialState, type }) => {
  const [isActive, setIsActive] = useState(initialState);
  const dispatch = useDispatch();

  const handleClick = async () => {
    try {
      const notificationChannel = type.split('_')[1]; // Extract the channel from the type
      await api.notifications.updateNotificationTypeApi(userId, type, !isActive);
      dispatch(updateNotificationType(userId, type, notificationChannel, !isActive));
      setIsActive(!isActive);
    } catch (error) {
      console.error('Error updating notification type:', error); // eslint-disable-line no-console
    }
  };

  return (
    <Checkbox
      theme="green"
      state={ !!isActive }
      onChange={ handleClick }
    />
  );
};

export default CheckColumn;
