import React from 'react';
import PropTypes from 'prop-types';
import NotFound from 'Screens/Errores/NotFound';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_PIPELINES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { resetError } from 'store/Pipelines/actions';
import { selectError, selectErrorMessage } from 'store/Pipelines/selectors';
import ErrorDialog from 'Common/Components/ErrorDialog';
import List from './components/List';
import Detail from './components/Detail';
import { Wrapper } from './styled';

const Pipelines = ({ isNew, match }) => {
  const isEnabled = useSelector((state) => selectAllowedByFeature(state, FEATURE_PIPELINES, PERMISSION_TYPES.READ) && selectAllowedByRole(state, FEATURE_PIPELINES, PERMISSION_TYPES.READ));
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  if (!isEnabled) return <NotFound />;
  return (
    <Wrapper>
      <List />
      <Detail isNew={ isNew } match={ match } />
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(resetError()) } /> : null }
    </Wrapper>
  );
};

Pipelines.propTypes = {
  isNew: PropTypes.bool.isRequired,
  match: PropTypes.instanceOf(Object).isRequired
};

export default Pipelines;
