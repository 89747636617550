import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as ArrowCollapsed } from 'Images/wf_arrow_collapsed.svg';
import { ReactComponent as ArrowExpanded } from 'Images/wf_arrow_expand.svg';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    position: relative;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: #1c2243;
  user-select: none;
  text-align: left;
`;
Title.displayName = 'Title';

export const Header = styled.div`
  display: flex;
  flex: 0 0 44px;
  padding: 9px 20px;
  align-items: center;
  cursor: pointer;

`;
Header.displayName = 'Header';

export const Counter = styled.div`
  ${({ show }) => !show && 'display: none'};
  user-select: none;
  padding: 3px 12px 3px 12px;
  color: ${colors.darkBlueGrey};
  font-size: 10px;
  font-weight: 900;
  border-radius: 9px;
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.13);
  background-color: ${colors.iceBlue};
  margin-left: 14px;
`;
Counter.displayName = 'Counter';

const IconWrapper = css`
  position: absolute;
  top: 17px;
  right: 15px;
`;

export const ExpandedIcon = styled(ArrowExpanded)`
  ${IconWrapper};
  margin: auto 0px auto auto;
  width: 12px;
  color: #63758d;
  path {
    fill: ${colors.greyBlue};
  }
`;
ExpandedIcon.displayName = 'ExpandedIcon';

export const CollapsedIcon = styled(ArrowCollapsed)`
  ${IconWrapper};
  margin: auto 0px auto auto;
  height: 12px;
  path {
    fill: ${colors.greyBlue};
  }
`;
CollapsedIcon.displayName = 'CollapsedIcon';

export const ChildWrapper = styled.div`
  ${({ $isExpanded }) => (!$isExpanded && 'display: none')};
  overflow: visible;
`;
ChildWrapper.displayName = 'ChildWrapper';

export const WrapperButtons = styled.div`
  position: absolute;
  top: 11px;
  left: 143px;
`;
WrapperButtons.displayName = 'WrapperButtons';
