import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({ enabled }) => enabled && `
    opacity: 0.5;
    pointer-events: none;
  `};
`;
Wrapper.displayName = 'Wrapper';

export const Row = styled.div`
  display: flex;
  margin-top: 10px;
`;
Row.displayName = 'Row';
