import React from 'react';
import EvidenceTab from 'Common/Components/EvidenceTab';
import CommentTab from '../CommentTab';
import CustomFieldsTab from '../CustomFieldsTab';
import TechnicalTab from '../TechnicalTab';
import GeneralTab from '../GeneralTab';

const TABS_CONTEXT = [
  {
    id: 'general',
    intlId: 'manage.detail.tab.general',
    component: <GeneralTab />
  },
  {
    id: 'technicalDetails',
    intlId: 'manage.detail.tab.technicalDetails',
    component: <TechnicalTab />
  },
  {
    id: 'evidence',
    intlId: 'manage.detail.tab.evidence',
    component: <EvidenceTab />
  },
  {
    id: 'customFields',
    intlId: 'manage.detail.tab.customAttributes',
    component: <CustomFieldsTab />
  },
  {
    id: 'comment',
    intlId: 'manage.detail.tab.comment',
    component: <CommentTab />
  }
];

export default TABS_CONTEXT;
