import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import selectModal from 'store/modals/selectors';
import DeleteProject from 'Screens/Planner/Projects/Modals/components/DeleteProject';
import { closeModal } from 'store/modals/actions';
import { selectErrorMessage } from 'store/Projects/selectors';
import {
  MODAL_PROJECTS_CREATE_EDIT,
  MODAL_PROJECTS_DELETE,
  MODAL_TASKS_CREATE_EDIT,
  MODAL_TASKS_DELETE,
  MODAL_TASKS_REDIRECT_TO_MANAGE,
  MODAL_TASKS_COMMENT_DELETE
} from 'store/modals/modals';
import CreateEditProject from './components/CreateEditProject';
import CreateEditTask from '../../Tasks/CreateEdit';
import DeleteTask from './components/DeleteTask';
import DeleteTaskComment from './components/DeleteTaskComment';
import ErrorModal from './components/Error';
import ConfirmNavigation from './components/ConfirmNavigation';

const Modals = () => {
  const dispatch = useDispatch();
  const showCreateEditModal = useSelector((state) => selectModal(state, MODAL_PROJECTS_CREATE_EDIT));
  const showDeleteModal = useSelector((state) => selectModal(state, MODAL_PROJECTS_DELETE));
  const showCreateEditTask = useSelector((state) => selectModal(state, MODAL_TASKS_CREATE_EDIT));
  const showDeleteTask = useSelector((state) => selectModal(state, MODAL_TASKS_DELETE));
  const showRedirectToManageConfirmation = useSelector((state) => selectModal(state, MODAL_TASKS_REDIRECT_TO_MANAGE));
  const showDeleteTaskComment = useSelector((state) => selectModal(state, MODAL_TASKS_COMMENT_DELETE));
  const errorMessage = useSelector(selectErrorMessage);

  useEffect(() => () => {
    dispatch(closeModal(MODAL_PROJECTS_CREATE_EDIT));
    dispatch(closeModal(MODAL_PROJECTS_DELETE));
    dispatch(closeModal(MODAL_TASKS_CREATE_EDIT));
    dispatch(closeModal(MODAL_TASKS_DELETE));
    dispatch(closeModal(MODAL_TASKS_REDIRECT_TO_MANAGE));
  }, [dispatch]);

  return (
    <>
      { showCreateEditModal && <CreateEditProject /> }
      { showDeleteModal && <DeleteProject /> }
      { showCreateEditTask && <CreateEditTask /> }
      { showDeleteTask && <DeleteTask /> }
      { showRedirectToManageConfirmation && <ConfirmNavigation />}
      { showDeleteTaskComment && <DeleteTaskComment /> }
      { errorMessage && <ErrorModal /> }
    </>
  );
};

export default Modals;
