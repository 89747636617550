import styled from 'styled-components';
import colors from 'Styles/colors';
import BlueButton from 'Common/Components/BlueButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 13.5px;
  font-weight: 600;
  color: #264d6d;
  margin-bottom: 4px;
`;
Title.displayName = 'Title';

export const AddWorkspace = styled(BlueButton)`
  height: 34px;
`;
AddWorkspace.displayName = 'AddWorkspace';

export const Label = styled.div`
  font-size: 12.5px;
  line-height: 1.28;
  color: ${colors.blueGrey};
  margin-bottom: 17px;
`;
Label.displayName = 'Label';
