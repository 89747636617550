/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { clearError, hideVulnDetail, loadVulnDetail, removeComment, resetState } from 'store/ManageGeneral/actions';
import ManageTable from './components/VulnsTable';
import CustomActionBar from './components/CustomActionBar';
import { TableContainer, Wrapper } from './styled';
import { selectError, selectErrorMessage, selectSelectedComment, selectShowDetail, selectVulnWorkspace } from 'store/ManageGeneral/selectors';
import VulnerabilityDetail from './components/VulnerabilityDetail';
import { clearAdvancedFilter, resetFilters } from 'store/Filters/actions';
import FilterSideBar from 'Screens/Contextualization/Manage/components/FilterSideBar';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { getCustomAttributes } from 'store/CustomAttributes/actions';
import selectModal from 'store/modals/selectors';
import { MODAL_CANT_CREATE, MODAL_MOVE_VULN_HOST } from 'store/modals/modals';
import WarningModal from '../ManageEditCreate/components/WarningModal';
import { closeModal } from 'store/modals/actions';
import { selectTotalHostsCount } from 'store/Faraday/selectors';
import { selectEmptyActiveWorkspaces } from 'store/Workspace/selectors';
import TemplateModal from 'Screens/Contextualization/Manage/components/ManageTable/components/ContextMenu/components/TemplateModal';
import { MoveVulnModal } from './components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/HostData';
import { getGeneralHostsTags, getGeneralServicesTags, getGeneralTags } from '../Tags/actions/Actions';
import CustomDragLayer from 'Common/Components/DragAndDrop/CustomDragLayer';
import QuickPlanner from 'Screens/Planner/QuickPlanner';
import ConfirmationDialog from 'Common/Components/ConfirmationDialog';
import { getHostsCountInAllWorkspaces } from '../Faraday/actions/Actions';

const VulnsGeneral = ({ params }) => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const showDetail = useSelector(selectShowDetail);
  const showModalCantCreate = useSelector((state) => selectModal(state, MODAL_CANT_CREATE));
  const assetsCountAllWorkspaces = useSelector(selectTotalHostsCount);
  const noAssetsFromAllWorkspaces = assetsCountAllWorkspaces === 0;
  const noWorkspaces = useSelector(selectEmptyActiveWorkspaces);
  const showMoveVulnModal = useSelector((state) => selectModal(state, MODAL_MOVE_VULN_HOST));
  const showCommentDeletionModal = useSelector((state) => selectModal(state, 'commentDeletion'));
  const selectedComment = useSelector((state) => selectSelectedComment(state));
  const vulnWs = useSelector(selectVulnWorkspace);

  const handleParamId = useCallback(() => {
    const id = get(params, 'id', 0);
    if (parseInt(id, 10) > 0) dispatch(loadVulnDetail(id));
    else dispatch(hideVulnDetail());
  }, [params]);

  useEffect(() => {
    dispatch(getCustomAttributes());
    dispatch(getGeneralTags());
    dispatch(getGeneralHostsTags());
    dispatch(getGeneralServicesTags());
    dispatch(getHostsCountInAllWorkspaces());
  }, [dispatch]);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => () => {
    dispatch(resetState());
    dispatch(resetFilters('vulnsGeneral'));
    dispatch(clearAdvancedFilter('vulnsGeneral'));
    dispatch(closeModal(MODAL_CANT_CREATE));
  }, [dispatch]);

  return (
    <Wrapper>
      <CustomActionBar />
      <TableContainer>
        <CustomDragLayer />
        <ManageTable />
        { showDetail && <VulnerabilityDetail /> }
        <QuickPlanner />
        <FilterSideBar entity="vulnsGeneral" />
        <TemplateModal />
      </TableContainer>
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(clearError()) } /> : null }
      { showModalCantCreate && <WarningModal entity="Vulnerability" noAssetsInAllWorkspaces={ noAssetsFromAllWorkspaces } noWorkspaces={ noWorkspaces } /> }
      { showMoveVulnModal && <MoveVulnModal /> }
      <ConfirmationDialog
        show={ showCommentDeletionModal }
        title="Delete Comment"
        submitText="Delete"
        message="A comment will be deleted. This action cannot be undone. Are you sure you want to proceed?"
        handleClose={ () => dispatch(closeModal('commentDeletion')) }
        handleSubmit={ () => {
          dispatch(removeComment(vulnWs, selectedComment.id));
          dispatch(closeModal('commentDeletion'));
        } }
      />
    </Wrapper>
  );
};

export default VulnsGeneral;
