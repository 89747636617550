import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 13px;
  width: 155px;
  ${({ isDisabled }) => isDisabled && 'cursor: not-allowed'};
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
