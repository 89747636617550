import styled from 'styled-components';
import colors from 'Styles/colors';

export const TableWrapper = styled.div.attrs({
  id: 'workspaces-table'
})`
  width: 100%;
  height: 96%;
  position: relative;
`;
TableWrapper.displayName = 'TableWrapper';

export const Col = styled.div`
  color: ${(props) => (props.active ? '' : `${colors.grey12} !important`)};
`;
Col.displayName = 'Col';

export const ColWorkspaceName = styled(Col)`
  font-size: 14.5px;
  font-weight: 400;
  color: ${colors.dark5} !important;
  cursor: pointer;
  z-index: 10;
`;
ColWorkspaceName.displayName = 'ColWorkspaceName';

export const ColWorkspaceDescription = styled(Col)`
  font-size: 12.5px;
  font-weight: 300;
  color: ${colors.dark5};
  pointer-events: none;
`;
ColWorkspaceDescription.displayName = 'ColWorkspaceDescription';

export const ColWorkspaceNumeric = styled(ColWorkspaceDescription)`
  text-align: center;
  font-size: 12.5px;
  pointer-events: all;
  cursor: default;
`;
ColWorkspaceDescription.displayName = 'ColWorkspaceDescription';

export const SwitchContainer = styled.div`
  text-align: left;

  .react-switch-bg {
    border: 1px solid ${(props) => (props.checked ? colors.green3 : colors.grey16)};
  }

  .react-switch-handle {
    border: 1px solid ${(props) => (props.checked ? colors.green3 : colors.grey16)};
  }
`;
SwitchContainer.displayName = 'SwitchContainer';

export const ColWorkspaceStatus = styled(Col)`
  width: ${(props) => {
    const { readonly, active } = props;

    if (!active) return '64px';
    if (readonly) return '80px';
    return '63px';
  }};
  height: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.11px;
  cursor: pointer;
  color: white !important;
  font-size: 12.5px;
  border-radius: 10px;
  padding-top: 5px;
  padding-left: ${(props) => {
    const { readonly, active } = props;

    if (!active) return '9px';
    if (readonly) return '10px';
    return '8px';
  }};
  background-color: ${(props) => {
    const { readonly, active } = props;

    if (!active) return colors.grey26;
    if (readonly) return colors.warmPurple;
    return colors.blueCerulean;
  }};
`;
ColWorkspaceStatus.displayName = 'ColWorkspaceStatus';

export const ColWorkspaceBetweenTime = styled.div`
  font-size: 12.5px;
  color: ${colors.dark5};
`;
ColWorkspaceBetweenTime.displayName = 'ColWorkspaceBetweenTime';

export const ColWorkspaceCreated = styled(Col)`
  font-size: 11.5px;
  color: ${colors.blueGrey} !important;;
`;
ColWorkspaceCreated.displayName = 'ColWorkspaceCreated';
