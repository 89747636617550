import fetchApi from 'services/api/connector';

export const getUsers = () => fetchApi('users');

export const getUsersList = () => fetchApi('users/list_usernames');

export const updateSecondFactor = (id, params) => fetchApi(`users/${id}/${params}`, { method: 'POST', version: 'v3' });

export const getUserById = (id) => fetchApi(`users/${id}`);

export const updateUser = (id, data) => fetchApi(`users/${id}`, { method: 'PATCH', data, version: 'v3' });

export const saveUser = (data) => fetchApi('users', { method: 'POST', data });

export const assignMultiplesWorkspaces = (userId, data) => fetchApi(`users/${userId}/assign_multiple_workspaces`, { method: 'PATCH', data });

export const fetchUsers = (queryParam) => fetchApi(`users/filter?q=${escape(JSON.stringify(queryParam))}`);

export const fetchActiveUsers = () => fetchApi(`users/filter?q=${escape(JSON.stringify({ offset: 0, filters: [{ name: 'active', op: '==', val: 'true' }] }))}`);
