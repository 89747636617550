import get from 'lodash/get';
import { selectUserRole } from 'store/Faraday/selectors';

export const selectAllowGetUsers = (state) => {
  const role = selectUserRole(state);
  switch (role) {
    case 'admin':
      return true;
    default:
      return false;
  }
};

export const selectAllowGetCommands = (state) => {
  const role = selectUserRole(state);
  switch (role) {
    case 'admin':
      return true;
    default:
      return false;
  }
};

export const selectWorkspaceUsers = (state) => get(state, 'workspace.users', []);
