export const SERVICES_FETCHING = 'SERVICES_FETCHING';
export const SERVICES_FAIL = 'SERVICES_FAIL';
export const SERVICES_CLEAR_ERROR = 'SERVICES_CLEAR_ERROR';
export const SERVICES_GET_SUCCESS = 'SERVICES_GET_SUCCESS';
export const SERVICE_SELECTED = 'SERVICE_SELECTED';
export const SERVICE_UNSELECTED = 'SERVICE_UNSELECTED';
export const NEW_PIVOT = 'NEW_PIVOT';
export const SELECT_ALL_SERVICE = 'SELECT_ALL_SERVICE';
export const UNSELECT_ALL_SERVICE = 'UNSELECT_ALL_SERVICE';
export const SERVICES_SHOW_DETAIL = 'SERVICES_SHOW_DETAIL';
export const SERVICES_HIDE_DETAIL = 'SERVICES_HIDE_DETAIL';
export const SERVICES_SHOW_MODAL_DELETE = 'SERVICES_SHOW_MODAL_DELETE';
export const SERVICES_HIDE_MODAL_DELETE = 'SERVICES_HIDE_MODAL_DELETE';
export const SERVICES_DELETE_SUCCESS = 'SERVICES_DELETE_SUCCESS';
export const SERVICES_DELETE_FAIL = 'SERVICES_DELETE_FAIL';
export const SERVICES_DELETE_START = 'SERVICES_DELETE_START';
export const SERVICES_UPDATE_SUCCESS = 'SERVICES_UPDATE_SUCCESS';
export const SERVICES_RESET_EDIT = 'SERVICES_RESET_EDIT';
export const SERVICES_SET_TAGS_SUCCESS = 'SERVICES_SET_TAGS_SUCCESS';
export const SERVICES_TAGS_UPDATE_SERVICE_DETAIL = 'SERVICES_TAGS_UPDATE_SERVICE_DETAIL';
export const SERVICES_RESET_STATE = 'SERVICES_RESET_STATE';
export const SERVICES_ADD_SERVICE_SUCCESS = 'SERVICES_ADD_SERVICE_SUCCESS';
export const SET_ERROR_CREATE_SERVICE = 'SET_ERROR_CREATE_SERVICE';
export const SELECT_TOTAL_SERVICES = 'SELECT_TOTAL_SERVICES';
export const UNSELECT_TOTAL_SERVICES = 'UNSELECT_TOTAL_SERVICES';
export const SERVICES_UNSELECTED_ROW_FROM_SELECT_ALL = 'SERVICES_UNSELECTED_ROW_FROM_SELECT_ALL';
export const SERVICES_AUTOSELECT = 'SERVICES_AUTOSELECT';
export const SET_SERVICES_FILTER_ERROR = 'SET_SERVICES_FILTER_ERROR';
