import styled from 'styled-components';
import colors from 'Styles/colors';
import { Default } from 'Common/Components/Button/styled';

// eslint-disable-next-line import/prefer-default-export
export const CancelButton = styled(Default)`
  color: ${colors.darkBlueGrey};
  border: 1px solid ${colors.greyBlue};
`;
CancelButton.displayName = 'CancelButton';
