import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export const getFirstScanFileAmount = (state) => get(state, 'fileUploaderContext.firstScanFilesAmount', 0);

export const getFirstScanPercentage = (state) => get(state, 'fileUploaderContext.firstScanFilesPercentage', 0);

export const selectCommandError = (state) => get(state, 'fileUploaderContext.error', false);

export const selectCommands = (state) => get(state, 'fileUploaderContext.commandsInProcess', []);

export const selectShowProcessingQueue = (state) => get(state, 'fileUploaderContext.showProcessingQueue', false);
export const selectProcessingQueueIsExpanded = (state) => get(state, 'fileUploaderContext.processingQueueisExpanded', false);

export const selectIsProcessingFiles = (state) => !isEmpty(selectCommands(state).filter((c) => c.fileType === 'file'));
export const selectTenLatestCommands = (state) => {
  const commands = selectCommands(state);
  const tenLatestCommands = commands.slice(Math.max(commands.length - 10, 0));
  return tenLatestCommands.reverse();
};
export const selectUnfinishedCommands = (state) => selectTenLatestCommands(state).filter((c) => !c.finished);
export const selectFinishedCommands = (state) => selectTenLatestCommands(state).filter((c) => c.finished);
