import React, { useState } from 'react';

const useElementSizeUpdate = (elRef) => {
  const [refWidth, setRefWidth] = useState({ width: 0, height: 0 });
  const observer = React.useRef(
    new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;

      setRefWidth({ width, height });
    })
  );

  React.useEffect(() => {
    const observerRef = observer.current;
    const elRefCurrent = elRef.current;
    if (elRefCurrent) {
      observerRef.observe(elRefCurrent);
    }

    return () => {
      observerRef.unobserve(elRefCurrent);
    };
  }, [elRef, observer]);

  return refWidth;
};

export default useElementSizeUpdate;
