import styled from 'styled-components';
import colors from 'Styles/colors';

export const ItemWrapper = styled.div`
  background-color: ${colors.white};
`;
ItemWrapper.displayName = 'ItemWrapper';

export const CustomItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 41px;
  padding: 0 12px;
  &:hover {
    >:nth-child(2) {
      ${({ canDelete }) => canDelete && 'display: block;'};
    }
  }
`;
CustomItem.displayName = 'CustomItem';

export const Name = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-stretch: normal;
  font-style: normal;
  font-size: 13.5px;
  color: ${colors.darkBlueGrey};
  flex: 1 1 auto;
  margin: auto 0px;
  user-select: none;
`;
Name.displayName = 'Name';
