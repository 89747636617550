import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { wsConnect, wsDisconnect } from '../../store/websocket/actions';

const useWebsocket = (host) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(wsConnect(host)); // ws://0.0.0.0:5985/notification

    return () => dispatch(wsDisconnect());
  }, [dispatch, host]);
};

export default useWebsocket;
