import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceNowSettings, TOOL_NAME, setField } from 'store/Settings/serviceNowActions';
import { getTemplates } from 'store/Settings/settingsActions';
import IncidentConfiguration from './components/IncidentConfiguration';
import Oauth from './components/Oauth';
import Settings from './components/Settings';
import Template from './components/Template';
import { Wrapper, Content } from './styled';
import { selectTable, selectSelectedTable, selectCategory, selectSubcategory, selectUrl, selectTemplate } from 'store/Settings/serviceNowSelectors';
import StandardDropdown from 'Common/Components/StandarDropdown';

const ServiceNow = ({ setDisabled }) => {
  const dispatch = useDispatch();
  const tableOptions = useSelector(selectTable);
  const selectedTable = useSelector(selectSelectedTable);
  const isIncidentConfigSelected = selectedTable === 'Incidents';

  // campos obligatorios
  const url = useSelector(selectUrl);
  const selectedTemplate = useSelector(selectTemplate);
  const selectedCategory = useSelector(selectCategory);
  const selectedSubcategory = useSelector(selectSubcategory);

  useEffect(() => {
    let isDis = false;

    if (url && selectedTemplate) {
      if (selectedTable === 'Incidents') {
        if (selectedCategory && selectedSubcategory) {
          isDis = false;
        } else {
          isDis = true;
        }
      } else {
        isDis = false;
      }
    } else {
      isDis = true;
    }

    setDisabled(isDis);
  }, [url, selectedTemplate, selectedCategory, selectedSubcategory, selectedTable, setDisabled]);
  // obtengo los datos del servidor
  useEffect(() => {
    dispatch(getTemplates(TOOL_NAME));
    dispatch(getServiceNowSettings());
  }, [dispatch]);

  const onChange = (field, value) => {
    dispatch(setField(field, value));
  };

  return (
    <Wrapper>
      <Content>
        <Settings />
        <Oauth />
        <Template />
        <StandardDropdown field="table" options={ tableOptions } updateValue={ onChange } value={ selectedTable } defaultValue={ selectedTable } width="235px" />
        { isIncidentConfigSelected && <IncidentConfiguration /> }
      </Content>
    </Wrapper>
  );
};
export default ServiceNow;
