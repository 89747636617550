import styled from 'styled-components';
import Wrapper from '../../styled';

export const TextAreas = styled.div` 
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  width: 100%;
  min-height: 222px;
  &>:not(:last-child){
    padding-bottom: 17px;
  }
`;
TextAreas.displayName = 'TextAreas';

export const FormWrapper = styled(Wrapper)` 
`;
FormWrapper.displayName = 'FormWrapper';

export const Column = styled.div` 
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  &>:not(:last-child) {
    margin-right: 20px;
  }
`;
ColumnWrapper.displayName = 'ColumnWrapper';
