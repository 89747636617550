import React from 'react';
import { useSelector } from 'react-redux';
import { selectPathname } from 'store/Router/selectors';
import { selectVulnTemplateDetailId } from 'store/KnowledgeBase/selectors';
import { selectId } from 'store/Analytics/selectors';
import {
  Agents, AnalyticsGraphs, CreateReport, EditGraph, EditReport, KbTitle,
  NewGraph, Notifications, NotificationsSettings, Planner, Reports
} from './Titles';

const Title = () => {
  const pathname = useSelector((state) => selectPathname(state));
  const currentVulnTemplate = useSelector(selectVulnTemplateDetailId);
  const graphId = useSelector(selectId);
  const showKB = (pathname === '/knowledge_base') ||
  (pathname.includes('/knowledge_base/')) ||
  pathname === `/knowledge_base/${currentVulnTemplate}`;
  const analyticsList = pathname === '/analytics';
  const analyticsNew = pathname === '/analytics/new';
  const analyticsEdit = pathname === `/analytics/${graphId}`;
  const planner = pathname.startsWith('/planner');
  const reports = pathname === '/report';
  const reportsNew = pathname === '/report/new';
  const reportsEdit = pathname.startsWith('/report/edit');
  const notifications = pathname === '/notifications-log';
  const notificationsSettings = pathname === '/notifications-settings';
  const agents = pathname === '/automation/agents';

  if (showKB) return <KbTitle />;
  if (analyticsList) return <AnalyticsGraphs />;
  if (analyticsNew) return <NewGraph />;
  if (analyticsEdit) return <EditGraph />;
  if (planner) return <Planner />;
  if (reports) return <Reports />;
  if (reportsNew) return <CreateReport />;
  if (reportsEdit) return <EditReport />;
  if (notifications) return <Notifications />;
  if (notificationsSettings) return <NotificationsSettings />;
  if (agents) return <Agents />;
  return null;
};

export default Title;
