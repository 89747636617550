import React, { useEffect } from 'react';
import { Col, Row } from 'Common/Components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLatestTags
} from 'Screens/Contextualization/Dashboard/actions/Actions';
import { selectIsFetching, selectLatestTags } from 'store/Dashboard/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { DASHBOARD_FILTERS } from 'store/Filters/constants';
import { setFilter } from 'store/Filters/actions';
import { redirect } from 'store/Router/actions';
import {
  StyledCol, Tag, Title, Empty
} from './styled';
import SpinnerComponent from 'Common/Components/SpinnerContainer';

const LatestTags = () => {
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const data = useSelector(selectLatestTags);
  const isLoadingTags = useSelector(selectIsFetching);

  useEffect(() => {
    dispatch(getLatestTags());
  }, [dispatch, workspaceSelected]);

  const onTagSelection = (tag) => {
    dispatch(setFilter('vulns', DASHBOARD_FILTERS.vulnsByTag(tag)));
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  if (isLoadingTags) return <SpinnerComponent height={ '105px' } />;

  let content = <Empty>No tags has been found yet.</Empty>;
  if (data && data && data.length > 0) {
    content = (
      <StyledCol>
        {data.slice(0, 5).map((tag) => (
          <Tag onClick={ () => onTagSelection(tag.name) } key={ tag.id }>{tag.name}</Tag>
        ))}
      </StyledCol>
    );
  }

  return (
    <Col>
      <Row>
        <Title>Latest Tags</Title>
      </Row>
      { content }
    </Col>
  );
};

export default LatestTags;
