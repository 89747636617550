import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { fetchActiveConfig, resetWarning } from 'store/Settings/settingsActions';
import { openModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE, MODAL_JIRA_TEMPLATE, MODAL_SEND_TO_JIRA } from 'store/modals/modals';
import {
  PERMISSION_TYPES, FEATURE_TICKETING_GITLAB, FEATURE_TICKETING_JIRA, FEATURE_TICKETING_SERVICENOW, FEATURE_TICKETING_WHD
} from 'Hooks/usePermission/permissions';
import sendToToolLicenseImage from 'Images/sendtotool-license.png';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import useClickOutside from 'Hooks/useClickOutside';
import { ACTIONS } from 'tracking/GA/constants';
import selectModal from 'store/modals/selectors';
import { getServiceNowSettings } from 'store/Settings/serviceNowActions';
import JiraTemplateModal from 'Common/Components/JiraTemplateModal';
import { Wrapper, Blue, ConfirmedIcon, Send, Dropdown } from './styled';
import useModal from './hooks/useModal';
import Warning from './components/Warning';
import Jira from './components/Jira';
import ServiceNow from './components/ServiceNow';
import GitLab from './components/GitLab';
import SolarWinds from './components/SolarWinds';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import SetProject from './components/Jira/SetProject';
import IconButton from 'Common/Components/IconButton';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectActiveConfig } from 'store/Settings/settingsSelectors';

const SendToTool = ({ hasVulns, vulnsSelected, isVulnDetailVisable, vulnId, entity }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const dropdownRef = useRef();

  const [ModalVulnsNotConfirmed, showModalVulnsNotConfirmed, toggleModalVulnsNotConfirmed] = useModal(Warning);
  const [ModalJira, showModalJira, toggleModalJira] = useModal(Jira);
  const [ModalServiceNow, showModalServiceNow, toggleModalServiceNow] = useModal(ServiceNow);
  const [ModalGitLab, showModalGitLab, toggleModalGitLab] = useModal(GitLab);
  const [ModalSolarWinds, showModalSolarWinds, toggleModalSolarWinds] = useModal(SolarWinds);

  const error = useSelector((state) => get(state, 'settings.sendVulnToTool.payload.error', false));
  const message = useSelector((state) => get(state, 'settings.sendVulnToTool.payload.message', ''));
  const isEnabledJira = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const isEnabledGitlab = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const isEnabledServiceNow = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const isEnabledWhd = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));

  const isEnabledByRoleJira = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleGitlab = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleServiceNow = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleWhd = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));
  const showJiraTemplateModal = useSelector((state) => selectModal(state, MODAL_JIRA_TEMPLATE));

  const showSendToJiraModal = useSelector((state) => selectModal(state, MODAL_SEND_TO_JIRA));

  const showModal = useSelector((state) => selectModal(state, MODAL_UPGRADE_LICENSE));

  const vulnsNotConfirmed = () => (vulnsSelected.some((e) => e.confirmed === false));

  const warningMessage = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'vulnerabilities' });
  const isAllowedByRole = isEnabledByRoleJira || isEnabledByRoleGitlab || isEnabledByRoleServiceNow || isEnabledByRoleWhd;
  const canShowDropDown = isAllowedByRole && !isVulnDetailVisable && hasVulns;
  const configActive = useSelector(selectActiveConfig);

  const validate = (isEnabled, byRole) => {
    if (!isEnabled) {
      dispatch(openModal(MODAL_UPGRADE_LICENSE));
      return false;
    }
    if (!byRole) return false;
    if (vulnsNotConfirmed()) {
      toggleModalVulnsNotConfirmed(true);
      return false;
    }
    return true;
  };

  const sendVulnToJira = () => {
    if (validate(isEnabledJira, isEnabledByRoleJira)) dispatch(openModal(MODAL_SEND_TO_JIRA));
    handleDropdown();
  };

  const sendVulnToServicenow = () => {
    if (validate(isEnabledServiceNow, isEnabledByRoleServiceNow)) {
      dispatch(getServiceNowSettings());
      toggleModalServiceNow(true);
    }
    handleDropdown();
  };
  const sendVulnToGitlab = () => {
    if (validate(isEnabledGitlab, isEnabledByRoleGitlab)) toggleModalGitLab(true);
    handleDropdown();
  };
  const sendVulnToSolarWinds = () => {
    if (validate(isEnabledWhd, isEnabledByRoleWhd)) toggleModalSolarWinds(true);
    handleDropdown();
  };

  useClickOutside(dropdownRef, () => setShowDropdown(false));
  const handleDropdown = () => setShowDropdown(!showDropdown);

  const handleClick = () => {
    if (canShowDropDown) {
      dispatch(fetchActiveConfig());
      if ((vulnsSelected.length > 0)) handleDropdown();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <Wrapper ref={ dropdownRef }>
      <IconButton icon={ <Send /> } title="Send to Tools" onClick={ handleClick } disabled={ !canShowDropDown } />
      {showDropdown && (
        <Dropdown>
          <MenuItem disabled={ !configActive.jira.active } onClick={ sendVulnToJira } title={ intl.formatMessage({ id: 'sendToTool.menu.jira.title' }) } />
          <MenuItem disabled={ !configActive.servicenow.active } onClick={ sendVulnToServicenow } title={ intl.formatMessage({ id: 'sendToTool.menu.serviceNow.title' }) } />
          <MenuItem disabled={ !configActive.gitlab.active } onClick={ sendVulnToGitlab } title={ intl.formatMessage({ id: 'sendToTool.menu.gitLab.title' }) } />
          <MenuItem disabled={ !configActive.whd.active } onClick={ sendVulnToSolarWinds } title={ intl.formatMessage({ id: 'sendToTool.menu.solarWinds.title' }) } />
        </Dropdown>
      )}
      {showModalJira && <ModalJira onClose={ toggleModalJira } vulnsSelected={ vulnsSelected } vulnId={ vulnId } isVulnDetailOpen={ isVulnDetailVisable } entity={ entity } />}
      {showModalServiceNow && <ModalServiceNow onClose={ toggleModalServiceNow } vulnsSelected={ vulnsSelected } vulnId={ vulnId } isVulnDetailOpen={ isVulnDetailVisable } entity={ entity } />}
      {showModalGitLab && <ModalGitLab onClose={ toggleModalGitLab } vulnsSelected={ vulnsSelected } vulnId={ vulnId } isVulnDetailOpen={ isVulnDetailVisable } entity={ entity } />}
      {showModalSolarWinds && <ModalSolarWinds onClose={ toggleModalSolarWinds } vulnsSelected={ vulnsSelected } vulnId={ vulnId } isVulnDetailOpen={ isVulnDetailVisable } entity={ entity } />}

      {showModalVulnsNotConfirmed && (
      <ModalVulnsNotConfirmed title={ intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.title' }) } onClose={ () => toggleModalVulnsNotConfirmed(false) }>
        { intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.message.onlyConfirmedVulns' }) }
        <ConfirmedIcon />
        <Blue>{ intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.message.confirmed' }) }</Blue>
        { intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.message.tryAgain' }) }
      </ModalVulnsNotConfirmed>
      )}
      { error && message && <Warning title={ intl.formatMessage({ id: 'sendToTool.warnings.oops.title' }) } onClose={ () => dispatch(resetWarning()) }>{message}</Warning>}
      { showModal && (
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ sendToToolLicenseImage } descriptionIntlId="upgradeLicense.sendToTool.description" trackingShow={ ACTIONS.showSendToTool } trackingClick={ ACTIONS.clickSendToTool } />
      </ReduxModal>
      ) }
      {showJiraTemplateModal && <JiraTemplateModal />}
      {showSendToJiraModal && <SetProject vulnsSelected={ vulnsSelected } vulnId={ vulnId } isVulnDetailOpen={ isVulnDetailVisable } entity={ entity } />}
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Send To Tool" description={ warningMessage } /> }
    </Wrapper>
  );
};

export default SendToTool;
