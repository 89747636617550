import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as TrashSVG } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as CopySVG } from 'Images/copy.svg';
import { ReactComponent as EditSVG } from 'Images/icon-action-bar-edit.svg';

export const Wrapper = styled.div`
  width: 91px;
  height: 34px;
  display: flex;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const TrashIcon = styled(TrashSVG)`
  width: 16px;
  margin: auto 0px auto auto;
  * > path {
    fill: ${colors.blueGrey};
  }
`;
TrashIcon.displayName = 'TrashIcon';

export const DuplicateIcon = styled(CopySVG)`
  width: 16px;
  margin: auto 13px auto auto;
  * > path {
    fill: ${colors.blueGrey};
  }
`;
DuplicateIcon.displayName = 'DuplicateIcon';

export const EditIcon = styled(EditSVG)`
  width: 16px;
  margin: auto 13px auto auto;
  * > path {
    fill: ${colors.blueGrey};
  }
`;
EditIcon.displayName = 'EditIcon';
