import React from 'react';
import PropTypes from 'prop-types';
import {
  SelectedWs, CustomSelectedMenuItem,
  IconContainer, Container
} from 'Screens/Users/components/ContextMenuUsersMassiveUpdate/SubMenuWorkspaces/styled';
import {
  FaTimes as Close
} from 'react-icons/fa';

const SelectedWorkspace = ({ workspace, onClose }) => (
  <Container>
    <CustomSelectedMenuItem>
      <SelectedWs>
        {workspace.name}
      </SelectedWs>
    </CustomSelectedMenuItem>
    <IconContainer onClick={ onClose }>
      <Close />
    </IconContainer>
  </Container>
);

SelectedWorkspace.propTypes = {
  workspace: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default SelectedWorkspace;
