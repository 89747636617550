import React from 'react';
import { useDispatch } from 'react-redux';
import createLink from 'Screens/NavMenu/Notifications/functions/createLink';
import createLinkFunc from 'Screens/NavMenu/Notifications/functions/createLinkFunc';
import parseIncomingNotification from 'Screens/NavMenu/Notifications/functions/parseIncomingNotification';
import { markNotificationsAsReadAsync } from 'store/Notifications/actions';
import get from 'lodash/get';

const NotificationMessageColumn = ({ original }) => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(markNotificationsAsReadAsync([original.id]));
  const workspace = get(original, 'extra_data.ws_name', '');

  if (typeof original !== 'object' || original === null) {
    return null;
  }
  return <div onClick={ handleClick }>
    {parseIncomingNotification(
      original.message,
      createLink(original.links_to?.type, workspace, original.links_to?.id, dispatch),
      () => createLinkFunc(original, dispatch)
    )}
         </div>;
};

export default NotificationMessageColumn;
