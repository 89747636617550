export * from './ldap/types';
export * from './saml/types';

export const DEFAULT_ACTIVE_CONFIG = {
  whd: {
    active: false,
    available: false
  },
  gitlab: {
    active: false,
    available: false
  },
  servicenow: {
    active: false,
    available: false
  },
  jira: {
    active: false,
    available: false
  }
};
