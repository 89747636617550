import React, { useMemo } from 'react';
import ExpandableWrapper from '../ExpandableWrapper';
import { useIntl } from 'react-intl';
import {
  Risk, RefTag, Text, Line, Title, Link, RiskScoreInfo, RiskScore,
  IconButton, RefreshIcon, ButtonsWrapper
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFetchingVulnEnrichment, selectVulnDetail } from 'store/Manage/selectors';
import useExpandable from 'Hooks/useExpandable';
import CVE from '../CVE';
import { refreshVulnEnrichment } from 'store/Manage/actions';
import { selectRiskScoreFeatureFlag } from 'store/Faraday/selectors';
import CVSS from '../CVSS';
import SpinnerComponent from 'Common/Components/SpinnerContainer';

const TAB = 'general';
const ID = 'risk';

const RiskAndCVE = ({ scrollToReferences }) => {
  const intl = useIntl();
  const currentVuln = useSelector(selectVulnDetail);
  const isExpanded = useExpandable(TAB, ID, currentVuln.cve);
  const dispatch = useDispatch();
  const isAllowedByLicense = useSelector(selectRiskScoreFeatureFlag);
  const isFetching = useSelector(selectIsFetchingVulnEnrichment);

  const vulnCvss = () => {
    if (!currentVuln.cvss3) return;
    if (currentVuln.cvss3.vector_string) return { ...currentVuln.cvss3, isV3: true };
    else if (currentVuln.cvss2.vector_string) return { ...currentVuln.cvss2, isV3: false };
    else return null;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cvss = useMemo(() => vulnCvss(), [currentVuln.cvss3, currentVuln.cvss2]);
  const cwe = useMemo(() => currentVuln.cwe[0], [currentVuln.cwe]);
  const owasp = useMemo(() => currentVuln.owasp[0], [currentVuln.owasp]);
  const risk = useMemo(() => currentVuln.risk, [currentVuln.risk]);

  const title = (!cvss && !cwe && !owasp && !risk?.score) ? intl.formatMessage({ id: 'manage.detail.tab.general.cve' }) : intl.formatMessage({ id: 'manage.detail.tab.general.risk' });
  const hasExploitable = currentVuln.tags.includes('exploit-available');
  const hasPatch = currentVuln.tags.includes('patch-available');
  const showRiskInfo = cvss || cwe || owasp || (risk?.score !== 0);

  const cvssText = () => {
    if (!cvss) return 'v3.1';
    else if (cvss && cvss.isV3) return 'v3.1';
    else return 'v2.0';
  };

  const TopButtons = ({ loading }) => (
    <ButtonsWrapper>
      {loading
        ? (
        <SpinnerComponent size="16px" />
          )
        : (
        <IconButton title="Refresh" onClick={ () => dispatch(refreshVulnEnrichment(currentVuln)) }>
          <RefreshIcon />
        </IconButton>
          )}
      {/* <Link target="_blank" href="" rel="noopener noreferrer" tooltip="" ><HelpIcon /></Link> */}
    </ButtonsWrapper>
  );

  return (
    <ExpandableWrapper id={ ID } title={ title } defaultValue={ isExpanded } tab={ TAB } buttons={ (showRiskInfo && isAllowedByLicense) ? <TopButtons loading={ isFetching } /> : null }>
     { showRiskInfo &&
      <Risk>
        <table>
        {((risk.score !== 0) && isAllowedByLicense)
          ? <Line>
            <Title>Risk Score</Title>
            <RiskScoreInfo>
              <RiskScore color={ risk.severity }>{risk.score} ({risk.severity})</RiskScore>
              {hasExploitable && <RefTag onClick={ scrollToReferences }>Exploitable</RefTag>}
              {hasPatch && <RefTag onClick={ scrollToReferences } green>Patchable</RefTag>}
            </RiskScoreInfo>
            </Line>
          : null}
        <Line>
            <Text pb bold>CVSS { cvssText() } Base Score</Text>
            <Text pb bold color={ cvss && cvss.base_severity }>{(cvss && cvss.base_score) ? cvss.base_score : 'N/A' }</Text>
        </Line>
        {cwe && <Line >
            <Text pb bold>CWE Weakness Type</Text>
            <Text pb ><Link target="_blank" href={ `https://cwe.mitre.org/data/definitions/${cwe.split('-')[1]}.html` } rel="noopener noreferrer">{cwe}</Link></Text>
                </Line>}
        {(owasp && isAllowedByLicense) && <Line>
            <Text bold>OWASP Category</Text>
            <Text>{owasp || 'No owasp'}</Text>
                                          </Line>}
        </table>

      </Risk>}
      <CVE showRiskInfo={ showRiskInfo } />
      <CVSS showRiskInfo={ showRiskInfo } />
    </ExpandableWrapper>
  );
};

export default RiskAndCVE;
