import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  selectUseLocalRoles, selectDefaultLocalRole, selectAdminGroup, selectPentesterGroup,
  selectClientGroup, selectAssetOwnerGroup
} from 'store/Settings/ldap/selectors';
import { setLdapField } from 'store/Settings/ldap/actions';
import Input from 'Screens/Contextualization/Settings/components/Ldap/components/Input';
import { Row } from 'Screens/Contextualization/Settings/components/Ldap/styled';
import { Wrapper, CustomRadio, Role } from './styled';

const UserRoles = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const getIntlText = (id) => intl.formatMessage({ id: `preferences.ldap.${id}` });
  const useLocalRolesLabel = getIntlText('localRoles');
  const useLocalRolesTooltip = getIntlText('default_local_role.tooltip');
  const useLdapGroupsLabel = getIntlText('ldapGroups');
  const useLdapGroupsTooltip = getIntlText('ldapGroups.tooltip');
  const useLocalRoles = useSelector(selectUseLocalRoles);
  const defaultLocalRole = useSelector(selectDefaultLocalRole);
  const adminGroup = useSelector(selectAdminGroup);
  const pentesterGroup = useSelector(selectPentesterGroup);
  const clientGroup = useSelector(selectClientGroup);
  const assetOwnerGroup = useSelector(selectAssetOwnerGroup);

  return (
    <Wrapper>
      <CustomRadio label={ useLocalRolesLabel } checked={ useLocalRoles } onChange={ () => dispatch(setLdapField('use_local_roles', true)) } />
      <Row>
        <Role>
          <Input inputValue={ defaultLocalRole } field="default_local_role" type="string" isRequired disabled={ !useLocalRoles } tooltip={ useLocalRolesTooltip } />
        </Role>
      </Row>
      <CustomRadio label={ useLdapGroupsLabel } checked={ !useLocalRoles } onChange={ () => dispatch(setLdapField('use_local_roles', false)) } />
      <Row>
        <Role>
          <Input inputValue={ adminGroup } field="admin_group" type="string" isRequired disabled={ useLocalRoles } tooltip={ useLdapGroupsTooltip } />
        </Role>
        <Role>
          <Input inputValue={ pentesterGroup } field="pentester_group" type="string" isRequired disabled={ useLocalRoles } tooltip={ useLdapGroupsTooltip } />
        </Role>
        <Role>
          <Input inputValue={ assetOwnerGroup } field="asset_owner_group" type="string" isRequired disabled={ useLocalRoles } tooltip={ useLdapGroupsTooltip } />
        </Role>
        <Role>
          <Input inputValue={ clientGroup } field="client_group" type="string" isRequired disabled={ useLocalRoles } tooltip={ useLdapGroupsTooltip } />
        </Role>
      </Row>
    </Wrapper>
  );
};

export default UserRoles;
