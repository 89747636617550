import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setLdapField } from 'store/Settings/ldap/actions';
import { selectUseLdaps, selectUseStartTls } from 'store/Settings/ldap/selectors';
import Checkbox from 'Common/Components/Checkbox';
import { SubsectionTitle } from 'Screens/Settings/components/Ldap/styled';
import { Wrapper, StyledLabel, CheckboxWrapper } from './styled';

const Security = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const getIntlText = (id) => intl.formatMessage({ id: `preferences.ldap.${id}` });
  const security = getIntlText('security');
  const useLdapsLabel = getIntlText('useLdaps');
  const useStartTlsLabel = getIntlText('useStartTls');
  const useLdaps = useSelector(selectUseLdaps);
  const useStartTls = useSelector(selectUseStartTls);

  return (
    <Wrapper>
      <SubsectionTitle>{ security }</SubsectionTitle>
      <CheckboxWrapper>
        <Checkbox checked={ useLdaps } onChange={ () => dispatch(setLdapField('use_ldaps', !useLdaps)) } state={ useLdaps } />
        <StyledLabel>{ useLdapsLabel }</StyledLabel>
      </CheckboxWrapper>
      <CheckboxWrapper>
        <Checkbox checked={ useStartTls } onChange={ () => dispatch(setLdapField('use_start_tls', !useStartTls)) } state={ useStartTls } />
        <StyledLabel>{ useStartTlsLabel }</StyledLabel>
      </CheckboxWrapper>
    </Wrapper>
  );
};

export default Security;
