/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width: 153px;
  ${({ hasTitle }) => hasTitle && `
    margin-bottom: 2px;
  `}
`;
Wrapper.displayName = 'Wrapper';

export const OptionWrapper = styled.div`
  display: flex;
`;
OptionWrapper.displayName = 'OptionWrapper';

export const dropdownStyle = {
  control: (provided, { isDisabled }) => ({
    display: 'flex',
    height: '34px',
    borderRadius: '1px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: isDisabled ? '#edf2f7' : '#ffffff',
    border: 'solid 1px #fafbfc',
    borderBottomWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided, { isDisabled }) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    opacity: isDisabled ? 0.5 : 0.7,
    fontWeight: 300
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    fontWeight: 300

  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#505965',
    width: '30px'
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: isFocused ? '#edf2f7' : data.required ? 'rgba(255,86,48,0.1)' : 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: 'pointer',
    paddingLeft: '13px',
    height: '36px',
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginRight: 'auto'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '153px'
  }),
  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: '90px', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative'
    };
  }
};

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color:${(props) => (props.progress === 'New'
? `${colors.tangerine}`
    : props.progress === 'In progress'
? `${colors.grey2}`
      : props.progress === 'Review'
? `${colors.paleGold}`
        : `${colors.apple}`)};
  border-radius: 50%;
  margin: 6px 6px 0 0;
`;
Dot.displayName = 'Dot';

export const Placeholder = styled.div`
  color: ${colors.black};
  font-size: 14.5px;
  font-weight: 500;
`;
Placeholder.displayName = 'Placeholder';

export const Asterisk = styled.span`
  font-size: 12.5px;
  line-height: 2.08;
  color: ${colors.redPink};
  margin-bottom: 1px;
`;
Asterisk.displayName = 'Asterisk';
