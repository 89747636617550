import React from 'react';
import moment from 'moment';
import { colors } from '../../styled';
import { Wrapper, StringDate, RiskWrapper, Type, Average } from './styled';
import { Title } from '../../../Histogram/components/Flyout/styled';

const Flyout = ({
  data, datum, x, y
}) => {
  const dateFormatter = (d) => moment(d).format('ll');
  return (
    data && data.map((d) => (
      <g key={ d.date }>
        <foreignObject x={ x - 95 } y={ y - 140 } width="190" height="120">
          <Wrapper xmlns="http://www.w3.org/1999/xhtml">
            <StringDate>{dateFormatter(datum.date)}</StringDate>
            <Title>Average Risk Score</Title>
            <RiskWrapper>
              <Type color={ colors[datum.risk.severity] }>{ datum.risk.severity }</Type>
              <Average>{ datum.risk.avg }</Average>
            </RiskWrapper>
          </Wrapper>
        </foreignObject>
      </g>
    ))
  );
};

export default Flyout;
