import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHost } from 'store/Host/actions';
import { getTools, showHostDetailById } from 'store/Contextualization/AssetDetail/actions';
import Importance from 'Common/Components/Importance';
import { Wrapper, IconButton, RefreshIcon } from './styled';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAssetDetailId, selectHostDetailImportance, selectHostWorkspace } from 'store/Contextualization/AssetDetail/selectors';
import { loadHostDetail, updateHostGeneral } from 'store/HostsGeneral/actions';

const TopButtons = ({ assetsGeneral }) => {
  const dispatch = useDispatch();
  const hostId = useSelector(selectAssetDetailId);
  const importance = useSelector(selectHostDetailImportance);
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.UPDATE));
  const disabled = readOnly || !allowedByRole;
  const workspace = useSelector(selectHostWorkspace);

  const handleClickRefresh = () => {
    assetsGeneral ? dispatch(loadHostDetail(hostId)) : dispatch(showHostDetailById(hostId));
    dispatch(getTools(workspace));
  };

  const update = (importance) => assetsGeneral ? dispatch(updateHostGeneral(hostId, { importance }, workspace)) : dispatch(updateHost(hostId, { importance }));

  return (
    <Wrapper>
      <Importance importance={ importance } callback={ (importance) => update(importance) } disabled={ disabled } />
      <IconButton title="Refresh" onClick={ handleClickRefresh }>
        <RefreshIcon />
      </IconButton>
    </Wrapper>
  );
};

export default TopButtons;
