import React from 'react';
import DateFormatter from 'Common/Components/DateFormatter';
import Skeleton from 'Common/Components/Skeleton';
import { ColumnLastActivity } from 'Screens/ExecutiveReport/components/Table/components/cells/styled';
import { get } from 'lodash';

const LastActivityCell = ({ rowInfo, isFetching }) => {
  const date = get(rowInfo, 'original.event_date', '');
  if (isFetching) return <Skeleton />;
  return (<ColumnLastActivity>
      <DateFormatter date={ date } />
          </ColumnLastActivity>);
};

export default LastActivityCell;
