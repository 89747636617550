import React from 'react';
import { Input, Title } from '../styled';

const DateTimePicker = ({ onChange, title, defaultValue }) => (
  <>
    <Title>{title}</Title>
    <Input type="datetime-local" onChange={ (e) => onChange(e.target.value) } defaultValue={ defaultValue } />
  </>
);

export default DateTimePicker;
