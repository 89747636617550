import get from 'lodash/get';

export const selectIsFetching = (state) => get(state, 'settings.userTokens.isFetching', false);
export const selectError = (state) => get(state, 'settings.userTokens.error', false);
export const selectErrorMessage = (state) => get(state, 'settings.userTokens.errorMessage', '');
export const selectUserTokens = (state) => get(state, 'settings.userTokens.data.tokens', []);
export const selectUserTokensCount = (state) => get(state, 'settings.userTokens.data.count', 0);
export const selectShowActionModal = (state) => get(state, 'settings.userTokens.showActionModal', false);
export const selectActionToConfirm = (state) => get(state, 'settings.userTokens.actionToConfirm', '');
export const selectSelectedTokenId = (state) => get(state, 'settings.userTokens.selectedTokenId', 0);
export const selectShowAddTokenModal = (state) => get(state, 'settings.userTokens.showAddTokenModal', false);
export const selectAddTokenModalStep = (state) => get(state, 'settings.userTokens.addTokenModalStep', 1);
export const selectLastAddedToken = (state) => get(state, 'settings.userTokens.lastAddedToken', {});
export const selectTokenString = (state) => get(state, 'settings.userTokens.lastAddedToken.token', '');
export const selectTab = (state) => get(state, 'settings.userTokens.selectedTab', 'active');
export const selectScopes = (state) => {
  const scopes = get(state, 'settings.userTokens.scopes', []);
  const parsedScopes = scopes.map((scope) => {
    if (scope === 'gitlab') return { value: scope, label: 'GitLab' };
    else return { value: scope, label: scope.charAt(0).toUpperCase() + scope.slice(1) };
  });
  return parsedScopes;
};
