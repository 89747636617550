import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectTags, orderedTags } from 'store/Tags/selectors';
import { selectSelected } from 'store/Contextualization/AssetDetail/selectors';
import { addVulnTag, removeVulnTag, setVulnsTags } from 'store/Contextualization/AssetDetail/actions';
import TagsModal from 'Common/Components/TagsModal';

const CreateEditTagsModal = ({ vulnsTags, entity }) => {
  const wsTags = useSelector(selectTags);
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const tags = useSelector((state) => orderedTags(state, vulnsTags, vulnsSelected));
  const dispatch = useDispatch();

  const onClickTag = (tag) => {
    if (tag.partialSelected) {
      dispatch(addVulnTag(tag.name));
    } else {
      if (tag.addedToVuln) dispatch(removeVulnTag(tag.name));
      else dispatch(addVulnTag(tag.name));
    }
  };

  const addTags = (newTags) => {
    dispatch(setVulnsTags(newTags));
  };
  return (
    <TagsModal tags={ tags } onClickTag={ onClickTag } addTags={ addTags } allTags={ wsTags } entity={ entity } />
  );
};

export default CreateEditTagsModal;
