import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import { MODAL_ANALYTICS_CONFIRM_DELETE } from 'store/modals/modals';
import ConfirmNavigationModal from 'Common/Components/ConfirmNavigationModal';
import { selectId, selectName } from 'store/Analytics/selectors';
import { deleteGraph } from 'store/Analytics/actions';

const ConfirmDelete = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const id = useSelector(selectId);
  const name = useSelector(selectName);
  const del = intl.formatMessage({ id: 'analytics.delete.remove' });
  const message = intl.formatMessage({ id: 'analytics.delete.message' });
  const cancel = intl.formatMessage({ id: 'analytics.delete.cancel' });
  const title = `${del} "${name}"`;

  const onDone = () => {
    dispatch(closeModal(MODAL_ANALYTICS_CONFIRM_DELETE));
    dispatch(deleteGraph(id));
  };

  const onCancel = () => {
    dispatch(closeModal(MODAL_ANALYTICS_CONFIRM_DELETE));
  };

  return (
    <ConfirmNavigationModal
      title={ title }
      message={ message }
      onCancel={ onCancel }
      onDone={ onDone }
      cancel={ cancel }
      done={ del }
    />
  );
};

export default ConfirmDelete;
