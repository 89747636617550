/* eslint-disable react/no-children-prop */
import React from 'react';
import { useSelector } from 'react-redux';
import useExpandable from 'Hooks/useExpandable';
import { useIntl } from 'react-intl';
import ExpandableWrapper from '../ExpandableWrapper';
import AddTagButton from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/Tags/components/AddTagButton';
import { Wrapper } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/Tags/styled';
import { selectVulnDetailTags } from 'store/ManageGeneral/selectors';
import TagItems from './TagItems';

const TAB = 'general';
const ID = 'tags';

const Tags = () => {
  const intl = useIntl();
  const vulnTags = useSelector(selectVulnDetailTags);
  const isExpanded = useExpandable(TAB, ID, vulnTags);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } id="tags" title={ intl.formatMessage({ id: 'manage.detail.tab.general.tags' }) } tab={ TAB }>
      <Wrapper>
        <TagItems />
        <AddTagButton entity="vulnDetail" />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Tags;
