import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { setCreationTagModal } from 'Screens/Tags/actions/Actions';
import {
  Wrapper, Title, TagIcon, CloseIconWrapper,
  CloseIcon
} from './styled';

const Header = ({ entity }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  return (
    <Wrapper>
      <TagIcon />
      <Title children={ intl.formatMessage({ id: 'manage.tags' }) } />
      <CloseIconWrapper onClick={ () => dispatch(setCreationTagModal(false, entity)) }>
        <CloseIcon />
      </CloseIconWrapper>
    </Wrapper>

  );
};

export default Header;
