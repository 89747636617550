import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as WarningIcon } from 'Images/exclamation_error.svg';

const errorStyle = css`
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 6%), inset 0 -1px 0 0 ${colors.redPink};
`;

export const Input = styled.input`
  width: 100%;
  max-width: ${(props) => (props.max ? `${props.max}px` : 'none')};
  min-width: ${(props) => (props.min ? `${props.min}px` : 'none')};
  height: 34px;
  border: 1px solid transparent;
  border-bottom: none;
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 6%), inset 0 -1px 0 0 ${colors.grey30};
  background-color: ${(props) => {
    if (props.disabled) return '#edf2f7';
    return props.isEmpty ? colors.blue8 : colors.white;
  }
};
  ${(props) => (props.error ? errorStyle : '')};
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14.5px;
  font-weight: 300;
  line-height: 2.48;
  display: inline-block;
  opacity: ${(props) => props.disabled && 0.5};
  &::-webkit-input-placeholder {
    color: ${colors.grey17};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
  }

  &::placeholder {
    font-size: 13px;
    font-weight: 300;
  }

  ${({ hideArrows }) => hideArrows && `
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  `}
`;

Input.displayName = 'Input';

export const Title = styled.span`
  text-transform: capitalize;
  text-align: left;
  font-size: 12.5px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.08;
  letter-spacing: normal;
  font-weight: 600;
  margin-bottom: 1px;
  color: ${(props) => (props.boldTitle ? colors.darkBlueGrey : colors.grey17)};
  padding-left: ${(props) => (props.boldTitle ? '15px' : '')};
`;
Title.displayName = 'Title';

/* .input-list-title{
  @extend .text-black-13;
  color: #0b2128;
  font-weight: 600;
  margin-bottom: 12px;
} */

export const Asterisk = styled.span`
    text-align: left;
    font-size: 12.5px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    color: ${colors.redPink};
    margin-bottom: 1px;
`;
Asterisk.displayName = 'Asterisk';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 85px;
`;
Wrapper.displayName = 'Wrapper';

export const Texts = styled.div`
    display: flex;
    height: 26px;
`;
Texts.displayName = 'Texts';

export const ErrorMessage = styled.span`
  font-size: 10.5px;
  letter-spacing: 0.08px;
  color: ${colors.redPink};
  margin-left: 1px;
  margin-top: 8px;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const Warning = styled(WarningIcon)`
  cursor: default;
  position: absolute;
  right: 10px;
  top: 33px;
`;

Warning.displayName = 'Warning';
