import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Pagination from 'Common/Components/Pagination';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import { Wrapper, RightItems, TokenIcon, Description, Total } from './styled';
import { setPage } from 'store/Settings/accessTokens/actions';
import { selectUserTokensCount } from 'store/Settings/accessTokens/selectors';

const ActionBar = ({ tab }) => {
  const dispatch = useDispatch();
  const page = useSelector((state) => selectPage('userTokens', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('userTokens', state));
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));
  const intl = useIntl();
  const text = intl.formatMessage({ id: `preferences.accessTokens.${tab}.description` });
  const count = useSelector(selectUserTokensCount);

  return (
    <Wrapper>
      <RightItems>
        <TokenIcon />
        <Description>{ text }</Description>
        <Total>{ `(${count})` }</Total>
      </RightItems>
      <Pagination offset={ page } limit={ rowsPerPage } total={ count } onPrev={ onPrev } onNext={ onNext } small />
    </Wrapper>
  );
};

export default ActionBar;
