import styled from 'styled-components';
import { CheckboxLabel } from 'Screens/Settings/common-styled';

export const Wrapper = styled.div`
  border-right: 1px solid #d9e4ef;
  padding-right: 35px;
  margin-right: 32px;
  height: 180px;
`;
Wrapper.displayName = 'Wrapper';

export const StyledLabel = styled(CheckboxLabel)`
  margin-left: 13px;
`;
StyledLabel.displayName = 'StyledLabel';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
CheckboxWrapper.displayName = 'CheckboxWrapper';
