import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const MentionItem = styled.span`
   color: #0082ed;
`;
MentionItem.displayName = 'MentionItem';

export const Wrapper = styled.div`
  flex: 1 1 0px;
  width: 100%;
  overflow: auto;
  padding: 15px 18px 18px 21px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.69;
  color: #63758d;
  .DraftEditor-root {
    height: 100%;
    width: 100%;
  }
  .DraftEditor-editorContainer {
    height: 100%;
    width: 100%;
  }
  .public-DraftEditor-content {
    height: 100%;
    width: 100%;
  }
`;
Wrapper.displayName = 'Wrapper';
