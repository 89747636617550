import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import ActionBar from './components/ActionBar';
import { HostData } from './components/HostData';
import Description from './components/Description';
import Resolution from './components/Resolution';
import References from './components/References';
import Policies from './components/Policies';
import ImpactsTab from './components/ImpactsTab';
import Owner from './components/Owner';
import ExternalId from './components/ExternalId';
import Tags from './components/Tags';
import RiskAndCVE from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/RiskAndCVE';
import ToggleButtons from 'Screens/Contextualization/Manage/components/ToggleButtons';
import { Wrapper, Fields } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/styled';
import Duplicates from './components/Duplicates';
import { selectTempCVSSVector } from 'store/Manage/selectors';
import { resetCVSS } from 'store/Manage/actions';
import { updateVulnFromAsset, refreshVulnEnrichmentAsset } from 'store/Contextualization/AssetDetail/actions';
import { selectDetail, selectIsFetchingVulnEnrichment } from 'store/Contextualization/AssetDetail/selectors';
import IssueTrackers from './components/IssueTrackers';

const GeneralTab = () => {
  const refElement = useRef();
  const vulnDetail = useSelector((state) => selectDetail('vulns', state));
  const tempCVSS = useSelector(selectTempCVSSVector);
  const fetchingVulnEnrichment = useSelector(selectIsFetchingVulnEnrichment);

  const scrollToReferences = () => refElement.current.scrollIntoView({ behavior: 'smooth' });
  return (
    <Wrapper>
      <Duplicates />
      <ActionBar />
      <HostData />
      <ToggleButtons id="general" />
      <Fields>
        <RiskAndCVE
          scrollToReferences={ scrollToReferences }
          currentVuln={ vulnDetail }
          updateVuln={ updateVulnFromAsset }
          tempCVSS={ tempCVSS }
          refreshVulnEnrichment={ refreshVulnEnrichmentAsset }
          resetCVSS={ resetCVSS }
          entity="vulnsAsset"
          fetchingVulnEnrichment={ fetchingVulnEnrichment }
        />
        <Description />
        <Resolution />
        <Tags />
        <References refElement={ refElement } />
        <ImpactsTab />
        <IssueTrackers />
        <Policies />
        <ExternalId />
        <Owner />
      </Fields>
    </Wrapper>
  );
};

export default GeneralTab;
