import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateCvss } from 'store/Manage/actions';
import Wrapper from './styled';
import CVSS from './cvss';

const Calculator = ({ cvssData }) => {
  const dispatch = useDispatch();
  const cvssRef = useRef();

  useEffect(() => {
    const cvss = new CVSS('cvssboard', {
      onchange: function (e) {
        const newCvss = cvss.get();
        dispatch(updateCvss(newCvss));
      }
    });

    if (cvssData.vector_string) {
      cvss.set(`CVSS:3.1/${cvssData.vector_string}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper ref={ cvssRef }>
       <div id='cvssboard' />
    </Wrapper>
  );
};

export default Calculator;
