import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { createUserToken, hideAddTokenModal, showAddTokenModal } from 'store/Settings/accessTokens/actions';
import { selectAddTokenModalStep, selectScopes, selectTokenString } from 'store/Settings/accessTokens/selectors';
import { Wrapper, Title } from 'Common/Components/ConfirmNavigationModal/styled';
import { Cancel } from '../Table/components/ActionModal/styled';
import {
  ModalWrapperStyled, DocLink, Content, Done, Input,
  ButtonsWrapper, DropdownWrapper, InputTitle, Row,
  Message, Token, TokenBox, Text, CopyButton, Tooltip,
  TooltipText
} from './styled';
import { dropdownStyle } from 'Screens/Settings/components/Ldap/components/Form/components/BasicFields/styled';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as CopyIcon } from 'Images/copy.svg';
import ExpiresDropdown from './ExpiresDropdown';

const AddTokenModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const step = useSelector(selectAddTokenModalStep);
  const token = useSelector(selectTokenString);
  const scopeOptions = useSelector(selectScopes);
  const [data, setData] = useState({
    alias: '',
    scope: '',
    expires: { value: '0', label: 'Never' }
  });
  const [copied, setCopied] = useState(false);
  const onChange = (field, value) => setData({ ...data, [field]: value });
  const expiresOptions = [
    { value: '0', label: 'Never' },
    { value: '7', label: 'in 1 week' },
    { value: '30', label: 'in 1 month' },
    { value: '365', label: 'in 1 year' },
    { value: '0', label: 'Custom' }
  ];
  const filteredExpiresOptions = expiresOptions.filter((item) => item.label !== data.expires.label);

  const docLink = 'https://docs.faradaysec.com/integration-gitlab/';
  const title = intl.formatMessage({ id: 'preferences.accessTokens.addToken.title' });
  const firstSubtitle = intl.formatMessage({ id: 'preferences.accessTokens.addToken.step1.subtitle' });
  const doc = intl.formatMessage({ id: 'preferences.accessTokens.addToken.step1.doc' });
  const secondSubtitle = intl.formatMessage({ id: 'preferences.accessTokens.addToken.step2.message' });
  const cancel = intl.formatMessage({ id: 'common.components.button.cancel' });
  const done = intl.formatMessage({ id: 'preferences.accessTokens.addToken.step1.confirmButton' });
  const close = intl.formatMessage({ id: 'preferences.accessTokens.addToken.close' });

  const canCreate = () => data.alias && data.scope && data.expires.value;

  const onCancel = () => dispatch(showAddTokenModal(false, 0));

  const onDone = () => {
    if (canCreate()) {
      const obj = { alias: data.alias, scope: data.scope, total_duration: Number(data.expires.value) };
      dispatch(createUserToken(obj));
    }
  };

  const onClose = () => dispatch(hideAddTokenModal());

  const handleCopyClick = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };

  return (
    <Wrapper>
      <ModalWrapperStyled>
        <Title>{ title }</Title>

        { step === 1
          ? <Content>
              <Row>
                <Input mandatory title="Alias" name="alias" width="201px" value={ data.alias } onChange={ (value) => onChange('alias', value) } />
                <DropdownWrapper>
                  <InputTitle required>Scope</InputTitle>
                  <Select
                    options={ scopeOptions }
                    onChange={ (e) => onChange('scope', e.value) }
                    styles={ dropdownStyle }
                    defaultValue={ data.scope }
                    isSearchable={ false }
                  />
                </DropdownWrapper>
              </Row>
              <DropdownWrapper>
                <InputTitle>Expires</InputTitle>
                <ExpiresDropdown
                  options={ filteredExpiresOptions }
                  onChange={ (e) => onChange('expires', e) }
                  defaultValue={ data.expires }
                />
              </DropdownWrapper>
              <ButtonsWrapper>
                <Cancel onClick={ onCancel }>{ cancel }</Cancel>
                <Done onClick={ onDone } disabled={ !canCreate() }>{ done }</Done>
              </ButtonsWrapper>
            </Content>
          : <Content>
              <Message>
                { firstSubtitle }
                <DocLink href={ docLink } target="_blank">{ doc }</DocLink>
              </Message>
              <Message bold>{ secondSubtitle }</Message>
              <Token>
                <InputTitle>Access Token</InputTitle>
                <TokenBox>
                  <Text>{ token }</Text>
                  <CopyToClipboard text={ token } onCopy={ handleCopyClick }>
                    <CopyButton title="Copy"><CopyIcon /></CopyButton>
                  </CopyToClipboard>
                  { copied && <Tooltip><TooltipText>Copied</TooltipText></Tooltip> }
                </TokenBox>
              </Token>
              <ButtonsWrapper>
                <Cancel onClick={ onClose }>{ close }</Cancel>
              </ButtonsWrapper>
            </Content>
        }

      </ModalWrapperStyled>
    </Wrapper>
  );
};

export default AddTokenModal;
