import React from 'react';
import { Severity, Confirm } from 'Screens/Manage/components/ManageTable/components/Table/columns';
import { VulnWrapper, Text } from './styled';

const Vuln = ({ data }) => (
  <VulnWrapper>
    <Severity vuln={ data } />
    <Confirm vuln={ data } />
    <Text confirmed>{data.name}</Text>
  </VulnWrapper>
);

export default Vuln;
