import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../index';
import {
  Wrapper, ErrorIcon
} from './styled';

const StringInput = ({
  required, title, placeholder, defaultValue, onChange, disabled, error, ...props
}) => (
  <Wrapper>
    <Input
      required={ required }
      title={ title }
      placeholder={ placeholder }
      defaultValue={ defaultValue }
      onChange={ onChange }
      disabled={ disabled }
      error={ error }
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...props }
    />
    <ErrorIcon error={ error } />
  </Wrapper>
);

export default StringInput;

StringInput.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  required: PropTypes.bool
};

StringInput.defaultProps = {
  defaultValue: '',
  disabled: false,
  required: false,
  error: '',
  placeholder: '',
  title: ''
};
