/* eslint-disable react/no-children-prop */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { openModal } from 'store/modals/actions';
import { MODAL_PROJECTS_CREATE_EDIT } from 'store/modals/modals';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { PERMISSION_TYPES, FEATURE_PROJECTS } from 'Hooks/usePermission/permissions';
import {
  Wrapper, Title, Subtitle, PlannerIcon, SubtitleWrapper, NewProject
} from './styled';

const Empty = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const title = intl.formatMessage({ id: 'planner.project.noProjects.title' });
  const subtitle = intl.formatMessage({ id: 'planner.project.noProjects.subtitle' });
  const userSubtitle = intl.formatMessage({ id: 'planner.project.noProjects.userSubtitle' });
  const newProject = intl.formatMessage({ id: 'planner.project.noProjects.newProject' });
  const showCreateProjectModal = () => dispatch(openModal(MODAL_PROJECTS_CREATE_EDIT));
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_PROJECTS, PERMISSION_TYPES.CREATE));

  return (
    <Wrapper>
      <PlannerIcon />
      <Title children={ title } />
      <SubtitleWrapper>
        <Subtitle children={ canCreate ? subtitle : userSubtitle } />
        { canCreate && <NewProject children={ newProject } onClick={ showCreateProjectModal } /> }
      </SubtitleWrapper>
    </Wrapper>
  );
};
export default Empty;
