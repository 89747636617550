import api from 'services/api';
import {
  selectEnabled, selectServer, selectPort, selectDomain, selectDomainDn, selectBindFormat,
  selectBindDn, selectUserAttribute, selectUseLocalRoles, selectDefaultLocalRole,
  selectAdminGroup, selectPentesterGroup, selectClientGroup, selectAssetOwnerGroup,
  selectUseLdaps, selectUseStartTls, selectDisconnectTimeout, selectPaginatedFetch,
  selectUserClass, selectUserGroup
} from 'store/Settings/ldap/selectors';
import { setError } from 'store/errors/actions';
import get from 'lodash/get';
import * as types from './types';

export const resetLdap = () => ({ type: types.SETTINGS_LDAP_RESET });
export const setLdapError = (errorMessage) => (dispatch) => {
  dispatch(setError(errorMessage));
  dispatch({ type: types.SETTINGS_LDAP_FAIL });
};

export function getLdap () {
  return async (dispatch) => {
    try {
      const data = await api.settings.fetchLdap();
      return dispatch({ type: types.SETTINGS_GET_LDAP, data });
    } catch (e) {
      const errorMessage = get(e, 'messages.json.error', 'There was a problem, please try again');
      return dispatch(setLdapError(errorMessage));
    }
  };
}

export function saveLdap (onClose) {
  return async (dispatch, getState) => {
    const state = getState();
    const enabled = selectEnabled(state);
    const server = selectServer(state);
    const port = selectPort(state);
    const domain = selectDomain(state);
    const domain_dn = selectDomainDn(state);
    const bind_format = selectBindFormat(state);
    const bind_dn = selectBindDn(state);
    const user_attribute = selectUserAttribute(state);
    const use_local_roles = selectUseLocalRoles(state);
    const default_local_role = selectDefaultLocalRole(state);
    const admin_group = selectAdminGroup(state);
    const pentester_group = selectPentesterGroup(state);
    const client_group = selectClientGroup(state);
    const asset_owner_group = selectAssetOwnerGroup(state);
    const use_ldaps = selectUseLdaps(state);
    const use_start_tls = selectUseStartTls(state);
    const disconnect_timeout = selectDisconnectTimeout(state);
    const paginated_fetch = selectPaginatedFetch(state);
    const user_class = selectUserClass(state);
    const group_class = selectUserGroup(state);

    const ldapData = {
      enabled,
      server,
      port: Number(port),
      domain,
      domain_dn,
      bind_format,
      bind_dn,
      user_attribute,
      use_local_roles,
      default_local_role,
      admin_group,
      pentester_group,
      client_group,
      asset_owner_group,
      use_ldaps,
      use_start_tls,
      disconnect_timeout: Number(disconnect_timeout),
      paginated_fetch,
      user_class,
      group_class
    };

    try {
      const updatedLdap = await api.settings.saveLdap(ldapData);
      onClose();
      return dispatch({ type: types.SETTINGS_SAVE_LDAP, updatedLdap });
    } catch (e) {
      const errorMessage = get(e, 'messages.json.error', 'There was a problem, please try again');
      return dispatch(setLdapError(errorMessage));
    }
  };
}

export function setLdapField (field, value) {
  return async (dispatch) => {
    dispatch({ type: types.SET_SETTINGS_LDAP_FIELD, field, value });
  };
}
