import api from 'services/api';
import { resetTasksStore } from 'store/Tasks/actions';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import * as types from './types';
import {
  selectProjects,
  selectProjectId,
  selectViewType
} from './selectors';

export function clearError () {
  return async (dispatch) => {
    dispatch({ type: types.PROJECTS_CLEAR_ERROR });
  };
}

export function somethingWentWrong (errorMessage) {
  return async (dispatch) => {
    dispatch({ type: types.PROJECTS_FAIL, errorMessage: errorMessage || 'There was an error, please try again.' });
  };
}

export function fetchingStart () {
  return async (dispatch) => {
    dispatch({ type: types.PROJECTS_FETCHING });
  };
}

export function getProjects () {
  return async (dispatch) => {
    try {
      dispatch(fetchingStart());
      const projects = await api.projects.fetchProjects();
      return dispatch({ type: types.PROJECTS_GET_SUCCESS, projects });
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function createProject (name) {
  return async (dispatch) => {
    try {
      const newProject = await api.projects.createProject(name);
      dispatch(trackEvent(CATEGORIES.planner, ACTIONS.clickCreateProject.name, ACTIONS.clickCreateProject.label));
      return dispatch({ type: types.PROJECTS_CREATE_SUCCESS, newProject });
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function renameProject (name) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const id = selectProjectId(state);
      const updatedProject = await api.projects.updateProject(id, name);
      const projectList = selectProjects(state);
      const updatedProjectList = projectList.map((p) => {
        if (p.id === id) return updatedProject;
        return p;
      });
      return dispatch({ type: types.PROJECTS_EDIT_SUCCESS, updatedProjectList });
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function deleteProject () {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const idToRemove = selectProjectId(state);
      await api.projects.deleteProject(idToRemove);
      return dispatch({ type: types.PROJECTS_DELETE_SUCCESS, idToRemove });
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function toggleViewType () {
  return (dispatch, getState) => {
    const actualView = selectViewType(getState());
    dispatch({ type: types.PROJECTS_TOGGLE_VIEW_TYPE, viewType: actualView.nextViewId });
  };
}

export function setSelectedProject (selectedProject) {
  return (dispatch) => dispatch({ type: types.PROJECTS_SELECT_PROJECT, selectedProject });
}

export function resetProjectStore () {
  return (dispatch) => {
    dispatch(resetTasksStore());
    dispatch({ type: types.PROJECTS_RESET });
  };
}

export function hideCompletedTasks (value) {
  return (dispatch) => {
    dispatch({ type: types.PROJECTS_HIDE_COMPLETED_TASKS, value });
  };
}
