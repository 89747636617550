// SpinnerComponent.jsx
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes para la animación de giro
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Componente estilizado para el spinner
const Spinner = styled.div`
  width: ${props => props.size || '3em'};
  height: ${props => props.size || '3em'}; 
  border: ${props => props.size ? `calc(${props.size} * 0.1333)` : '0.4em'} solid transparent;
  border-top-color: #0082ed;
  border-bottom-color: #0082ed;
  border-radius: 50%;
  animation: ${spin} 1.5s ease-in-out infinite;
`;

// Componente estilizado para el contenedor del spinner
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height || '100%'};
`;

const SpinnerComponent = ({ height, size }) => {
  return (
    <SpinnerContainer height={ height }>
      <Spinner aria-hidden="true" size={ size } />
    </SpinnerContainer>
  );
};

export default SpinnerComponent;
