import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as _Resizer } from 'Images/resize_bottom_right.svg';

export const EditorContainer = styled.div.attrs(({ disabled, containerHeight }) => {
  let height = disabled ? '110px' : '190px';
  if (containerHeight) height = `${containerHeight}px`;
  return ({ style: { height } });
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: #EDF2F7;
  & > * {
    ${({ disabled }) => disabled && 'pointer-events: none;'};
  }
`;
EditorContainer.displayName = 'EditorContainer';

export const ButtonsWrapper = styled.div`
  background-color: ${colors.white};
  flex: 0 0 60px;
  width: 100%;
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';

export const Resizer = styled(_Resizer)`
  cursor: ns-resize;
  position: absolute;
  bottom: 70px;
  right: 10px;
`;
Resizer.displayName = 'Resizer';
