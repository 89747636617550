/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ReactComponent as TriggerButton } from 'Images/icon-action-bar-trigger.svg';
import { ReactComponent as DisabledTriggerButton } from 'Images/icon-action-bar-trigger-disabled.svg';
import { openModal } from 'store/modals/actions';
import { getAgentTools, setAgentId, setSelectedCloudAgent } from 'store/Agents/actions';
import { MODAL_RUN_USER_AGENT } from 'store/modals/modals';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from './styled';
import { SelectShowCloudAgents } from 'store/Agents/selectors';

const RunIcon = ({ agent }) => {
  const dispatch = useDispatch();
  const id = get(agent, 'id', null);
  const isOnline = get(agent, 'is_online', true);
  const status = get(agent, 'status', 'online');
  const disabled = !isOnline || (isOnline && status !== 'online');
  const showCloudAgents = useSelector(SelectShowCloudAgents);

  const onTriggerAgent = () => {
    if (id) {
      if (showCloudAgents) {
        dispatch(setAgentId(id));
        dispatch(setSelectedCloudAgent(agent));
      } else {
        dispatch(getAgentTools(id));
      }
      dispatch(openModal(MODAL_RUN_USER_AGENT));
    }
  };

  return (
    <Wrapper id="modal-user-agent-run-btn" enabled={ isOnline } title="Run agent" onClick={ onTriggerAgent }>
      { disabled ? <DisabledTriggerButton /> : <TriggerButton /> }
    </Wrapper>
  );
};

RunIcon.propTypes = {
  agent: PropTypes.object.isRequired
};

export default RunIcon;
