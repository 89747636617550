/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { setModalAssignUsersStatus, massiveUpdateUsers } from 'Screens/Contextualization/Workspaces/actions/Actions';
import InputFilter from 'Common/Components/Contextualization/InputFilter';
import { FiTrash2 as Trash } from 'react-icons/fi';
import {
  CustomModal, Title, CancelButton, CreateButton, Header, Body, ErrorMessage, SubTitle, BlueText, UserSelectorWraper,
  UserSelect, UserRow, NameRow, RoleRow, IconRow, SubTitleContainer
} from './styled';
import { selectWorkspaceUsers } from 'store/Session/selectors';
import uniq from 'lodash/uniq';
import head from 'lodash/head';
import { selectWsEditCreateErrorMessage } from 'store/WorkspaceEditCreate/selectors';
import { selectShowModalAssignUsers, selectWorkspacesSelected } from 'store/Workspace/selectors';

const ModalAssignUsers = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const errorMessage = useSelector(selectWsEditCreateErrorMessage);
  const show = useSelector(selectShowModalAssignUsers);
  const workspacesSelected = useSelector(selectWorkspacesSelected);

  const users = useSelector(selectWorkspaceUsers);
  const activeUsers = users.filter(({ active }) => active);
  const addedUsers = activeUsers.filter(({ username }) => selectedUsers.includes(username));
  const mappedAddedUsers = selectedUsers.map((username) => addedUsers.find((user) => user.username === username));
  const filteredMappedAddedUsers = mappedAddedUsers.filter((user) => user !== undefined);
  const notAddedUsers = activeUsers.filter(({ username }) => !selectedUsers.includes(username));

  useEffect(() => {
    const defaultSelectedUsers = activeUsers.filter(({ roles }) => head(roles) === 'admin').map(({ username }) => username);
    const assignedUsers = workspacesSelected.flatMap((ws) => ws.users);
    const newSelectedUsers = uniq([...defaultSelectedUsers, ...assignedUsers]);
    setSelectedUsers(newSelectedUsers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addUser = (username) => setSelectedUsers([...selectedUsers, username]);

  const removeUser = (username) => setSelectedUsers(selectedUsers.filter((usr) => usr !== username));

  return (
    <CustomModal show={ show } id="modal_assign_users_workspace" onHide={ () => dispatch(setModalAssignUsersStatus(false)) } centered dialogClassName="cv-modal" backdrop="static">
      <Header>
        <Title><FormattedMessage id="workspaces.contextMenu.assignUsers" /></Title>
        <CreateButton onClick={ () => dispatch(massiveUpdateUsers(selectedUsers)) }><FormattedMessage id="save" /></CreateButton>
        <CancelButton onClick={ () => dispatch(setModalAssignUsersStatus(false)) }>
          <FormattedMessage id="cancel" />
        </CancelButton>
      </Header>
      <Body>
        <SubTitleContainer>
          <FormattedMessage id="workspaces.contextMenu.assignUsers.subtitle.firstPart" />
          {workspacesSelected.map((ws, index) => {
            if (workspacesSelected.length > 1 && index + 2 < workspacesSelected.length) {
              return (
                <SubTitle>
                  <BlueText children={ `${ws.name}, ` } />
                </SubTitle>
              );
            }
            if (workspacesSelected.length > 1 && index + 1 < workspacesSelected.length) {
              return (
                <SubTitle>
                  <BlueText children={ `${ws.name} ` } />
                  <FormattedMessage id="and" />
                </SubTitle>
              );
            }
            return <BlueText children={ `${ws.name}` } />;
          })}
          {workspacesSelected.length > 1
            ? <FormattedMessage id="workspaces.contextMenu.assignUsers.subtitle.lastPartPlural" />
            : <FormattedMessage id="workspaces.contextMenu.assignUsers.subtitle.lastPartSingle" />}
        </SubTitleContainer>
        <UserSelectorWraper>
          <InputFilter
            getObjects={ () => { } }
            data={ notAddedUsers }
            placeholder={ intl.formatMessage({ id: 'username' }) }
            onSelect={ ({ username }) => addUser(username) }
            onChange={ () => { } }
            cleanOnSelect
            required={ false }
          />
        </UserSelectorWraper>
        <UserSelect>
          {filteredMappedAddedUsers.map(({ username, roles }) => (
            <UserRow>
              <NameRow children={ username } />
              <RoleRow role={ head(roles) } children={ <FormattedMessage id={ head(roles) } /> } />
              {head(roles) !== 'admin' &&
                (
                  <IconRow onClick={ () => removeUser(username) }>
                    <Trash color="#1c4566" />
                  </IconRow>
                )
              }
            </UserRow>
          ))}
        </UserSelect>
        {errorMessage.length > 0 ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      </Body>
    </CustomModal>
  );
};

export default ModalAssignUsers;
