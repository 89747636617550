import TABS from 'Screens/Planner/Tasks/CreateEdit/components/Tabs/Tabs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { selectQuery } from 'store/Router/selectors';

export const getFormattedDate = (unformattedDate) => {
  if (unformattedDate) {
    const date = new Date(unformattedDate);
    const year = date.getUTCFullYear().toString().padStart(4, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const parsedDate = `${year}-${month}-${day}`;
    return parsedDate;
  }
  return '';
};

const sortTasks = (taskA, taskB) => {
  const aId = get(taskA, 'id', 0);
  const bId = get(taskB, 'id', 0);
  const aType = get(taskA, 'type', '');
  const bType = get(taskB, 'type', '');
  const aDate = aType === 'milestone' ? get(taskA, 'endDate', 0) : get(taskA, 'startDate', 0);
  const bDate = bType === 'milestone' ? get(taskB, 'endDate', 0) : get(taskB, 'startDate', 0);
  if (aDate === bDate) return aId - bId;
  return aDate - bDate;
};

export const selectTasks = (state) => get(state, 'tasks.list', []);
export const selectTasksIsEmpty = (state) => isEmpty(selectTasks(state));
export const selectTaskLinkedVulns = (state) => get(state, 'tasks.selectedTask.linkedVulns', []);
export const selectTask = (state) => get(state, 'tasks.selectedTask', null);
export const selectTaskProjectId = (state) => get(state, 'tasks.selectedTask.projectId', 0);
export const selectTaskById = (state, taskId) => {
  const tasks = selectTasks(state);
  const flattenedTasks = tasks.flatMap((t) => t.tasks);
  const task = flattenedTasks.find((t) => t.id === taskId);
  if (task) return task;
  return null;
};
export const selectTaskId = (state) => get(state, 'tasks.selectedTask.id', 0);
export const selectTaskName = (state) => get(state, 'tasks.selectedTask.name', '');
export const selectTaskType = (state) => get(state, 'tasks.selectedTask.type', '');
export const selectTaskStartDate = (state) => get(state, 'tasks.selectedTask.startDate', '');
export const selectTaskEndDate = (state) => get(state, 'tasks.selectedTask.endDate', '');
export const selectTaskFormattedStartDate = (state) => {
  const startDate = selectTaskStartDate(state);
  return getFormattedDate(startDate);
};
export const selectTaskFormattedEndDate = (state) => {
  const endDate = selectTaskEndDate(state);
  return getFormattedDate(endDate);
};
export const selectTaskStatus = (state) => get(state, 'tasks.selectedTask.status', '');
export const selectTaskDescription = (state) => get(state, 'tasks.selectedTask.description', '');
export const selectTaskAssignees = (state) => get(state, 'tasks.selectedTask.assignees', []);
export const selectTaskDependencies = (state) => get(state, 'tasks.selectedTask.dependencies', []);
export const selectIsEditing = (state) => selectTaskId(state) > 0;
export const selectTasksByProjectId = (state, projectId) => {
  const tasksList = selectTasks(state);
  const projectIdTasks = tasksList.find((t) => t.projectId === projectId);
  if (!projectIdTasks) return [];
  const sortedTasks = projectIdTasks.tasks.sort(sortTasks);
  return sortedTasks;
};

export const selectTaskComments = (state) => get(state, 'tasks.selectedTask.comments', []);
export const selectIsFetchingTasks = (state) => get(state, 'tasks.isFetching', false);
export const selectSelectedLinkedVulnId = (state) => get(state, 'tasks.selectedLinkedVuln.id', 0);
export const selectSelectedLinkedVulnWs = (state) => get(state, 'tasks.selectedLinkedVuln.workspace', '');
export const selectHasPendingChanges = (state) => get(state, 'tasks.hasPendingChanges', false);
export const selectTaskComment = (state) => get((state), 'tasks.selectedComment', null);
export const selectTabId = (state) => get(state, 'tasks.selectedTab', 'general');
export const selectTaskToDelete = (state) => get((state), 'tasks.taskToDelete', null);
export const selectTab = (state) => {
  const query = selectQuery(state);
  const selectedTabId = get(query, 'tabId', 'general');
  const selectedTab = TABS.find((t) => t.id === selectedTabId);
  if (!selectedTab) return TABS[0];
  return selectedTab;
};
