import styled from 'styled-components';
import colors from 'Styles/colors';
import cellphone from 'Images/cellphone.svg';
import { CURRENT_API_VERSION } from 'services/api/connector';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

Wrapper.displayName = 'Wrapper';

export const QrImage = styled.img.attrs({
  src: `/_api/${CURRENT_API_VERSION}/2fa/qrcode`
})`
  max-width: 148px;
  height: auto;
  display: inline-block;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 0;
  justify-content: flex-end;
  border: solid 2.1px ${colors.grey17};
  padding: 0;
`;
QrImage.displayName = 'QrImage';

export const Cellphone = styled.img.attrs({
  src: cellphone
})`
  width: 10px;
  margin: 5px 0;
`;
Cellphone.displayName = 'Cellphone';

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.44;
  text-align: left;
  color: ${colors.grey17};
  max-width: 150px;
  padding: 0 20px;
`;
Label.displayName = 'Label';
