import styled from 'styled-components';
import RadioButton from 'Common/Components/RadioButton/index';
import { Indicator } from 'Common/Components/RadioButton/styled';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const CustomRadio = styled(RadioButton)`
  margin: 0px 15px 10px 0;

  ${Indicator} {
    width: 14px;
    height: 14px;
    background-color: #f7f7f7;
    top: 3px;
    &::after {
      width: 7px !important;
      height: 7px !important;
    }
  }
`;
CustomRadio.displayName = 'CustomRadio';

export const Role = styled.div`
  width: 145px;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;
Role.displayName = 'Role';
