import React, { useEffect } from 'react';
import { Col, Row } from 'Common/Components/Grid/index';
import { ViewAll } from 'Screens/Contextualization/Dashboard/components/ToolbarItems/Common/ViewAll/styled';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestVulns } from 'Screens/Contextualization/Dashboard/actions/Actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import get from 'lodash/get';
import { CONFIRMED_FLAG_FILTERS, DASHBOARD_FILTERS } from 'store/Filters/constants';
import { setFilter, setOrderBy } from 'store/Filters/actions';
import { redirect } from 'store/Router/actions';
import { isFilteringBy } from 'store/Filters/selectors';
import { selectIsFetching } from 'store/Dashboard/selectors';
import SpinnerComponent from 'Common/Components/SpinnerContainer';
import {
  Severity, StyledCol, Text, Service, Target, Title, LatestVulnEntry, TextWrapper, Empty
} from './styled';
import { BoxLatestVulns } from '../../Layout/styled';

const LatestVulns = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => get(state, 'dashboard.tools[5].data', []));
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const isLoadingVulns = useSelector(selectIsFetching);

  const { confirmed } = CONFIRMED_FLAG_FILTERS;
  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));

  useEffect(() => {
    dispatch(getLatestVulns(isFilteringByConfirm));
  }, [workspaceSelected, isFilteringByConfirm, dispatch]);

  const onClick = (id) => {
    dispatch(setFilter('vulns', DASHBOARD_FILTERS.vulnerabilityById(id)));
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  const viewAll = () => {
    dispatch(setOrderBy('vulns', [{ id: 'create_date', desc: 'desc' }]));
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  return (
    <>
      { isLoadingVulns
        ? <SpinnerComponent height={ '320px' } />
        : <BoxLatestVulns>
          <Col>
            <Row>
              <Title>Latest Vulnerabilities</Title>
            </Row>
            { (data && data.vulnerabilities && data.vulnerabilities.length > 0)
              ? (
              <StyledCol>
                {data.vulnerabilities.slice(0, 5).map((vuln) => (
                  <LatestVulnEntry key={ `dashboard_latest_vulns_${vuln.id}` }>
                    <Row onClick={ () => onClick(vuln.id) }>
                      <TextWrapper width="70%">
                        <Col>
                          <Severity level={ vuln.value.severity }>{vuln.value.severity.charAt(0)}</Severity>
                          <Text>{vuln.value.name}</Text>
                        </Col>
                      </TextWrapper>
                      <TextWrapper width="25%"><Col><Service>{get(vuln, 'value.service.name', 'service') }</Service></Col></TextWrapper>
                      <TextWrapper width="25%"><Col><Target>{get(vuln, 'value.target', '')}</Target></Col></TextWrapper>
                    </Row>
                  </LatestVulnEntry>
                ))}
              </StyledCol>
                )
              : <Empty>No vulnerabilities has been found yet.</Empty> }
            { (data && data.vulnerabilities && data.vulnerabilities.length > 0) && <Row><ViewAll onClick={ viewAll }>View All</ViewAll></Row> }
          </Col>
          </BoxLatestVulns>
      }
    </>
  );
};

export default LatestVulns;
