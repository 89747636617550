import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 34px;
  width: 100%;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  user-select: none;
  font-size: 21.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.darkBlueGrey};
`;
Title.displayName = 'Title';

export const ButtonsWrapper = styled.div`
  margin-left: auto;
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';
