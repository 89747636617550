import get from 'lodash/get';

export const selectIsShowingContextMenu = (state) => get(state, 'users.isShowingContextMenu', false);
export const selectRowsPerPage = (state) => get(state, 'users.rowsPerPage', 0);
export const selectUserSelected = (state) => get(state, 'users.userSelected', []);

export const selectedUser2FaStatus = (state) => get(state, 'users.userSelected[0].status_2fa', '');
export const selectedUserWorkspaces = (state) => get(state, 'users.userSelected[0].workspaces', []);
export const selectedUserCurrentLoginIp = (state) => get(state, 'users.userSelected[0].current_login_ip', '');
export const selectedUserActive = (state) => get(state, 'users.userSelected[0].active', '');
export const selectedUserName = (state) => get(state, 'users.userSelected[0].name', '');
export const selectedUserCurrentLoginDate = (state) => get(state, 'users.userSelected[0].current_login_at', '');
export const selectedUserId = (state) => get(state, 'users.userSelected[0].id', 0);
export const selectedUserRoles = (state) => get(state, 'users.userSelected[0].roles', '');
export const selectedUserLoginCount = (state) => get(state, 'users.userSelected[0].login_count', '');
export const selectedUserLastLoginDate = (state) => get(state, 'users.userSelected[0].last_login_at', '');
export const selectedUserLastLoginIp = (state) => get(state, 'users.userSelected[0].last_login_ip', '');
export const selectedUserEmail = (state) => get(state, 'users.userSelected[0].email', '');
export const selectedUserUsername = (state) => get(state, 'users.userSelected[0].username', '');
export const selectedUserType = (state) => get(state, 'users.userSelected[0].user_type', 'local');

export const selectUsersList = (state) => get(state, 'users.userList', []);
export const selectIsFetching = (state) => get(state, 'users.isFetching', false);
export const selectUserCount = (state) => get(state, 'users.userCount', 0);
export const selectShowDeleteConfirmation = (state) => get(state, 'users.showDeleteConfirmation', false);
export const selectSorting = (state) => get(state, 'users.sorting', []);
export const selectCurrentBreakpoint = (state) => get(state, 'users.currentBreakpoint', false);
export const selectMoreOptionsOpened = (state) => get(state, 'users.moreOptionsOpened', false);
export const selectLastUsersSelected = (state) => get(state, 'users.lastUsersSelected', {});
export const selectLastIndexForRange = (state) => get(state, 'users.lastIndexForRange', -1);
export const selectLastSelected = (state) => get(state, 'users.lastSelected', -1);
export const selectShowModalCreate = (state) => get(state, 'users.showModalCreate', false);
