/* eslint-disable react/no-children-prop */
import React from 'react';
import { DuplicateWrapper, MainDuplicateIcon, DuplicateLabel } from './styled';

const MainTag = () => (
  <DuplicateWrapper>
    <MainDuplicateIcon />
    <DuplicateLabel children="Main" />
  </DuplicateWrapper>
);

export default MainTag;
