import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import {
  selectAllNotifications,
  unselectAllNotifications,
  unselectTotalNotifications,
  unselectedRowFromSelectAll
} from 'store/Notifications/actions';
import {
  selectAllNotification, selectSelectedNotifications
} from 'store/Notifications/selectors';

const CheckHeader = () => {
  const dispatch = useDispatch();
  const allNotifications = useSelector(selectAllNotification);
  const selectedNotifications = useSelector(selectSelectedNotifications);
  const someNotificationsSelected = selectedNotifications.length > 0;
  const allNotificationsSelected =
  someNotificationsSelected &&
    allNotifications.length === selectedNotifications.length;

  const handleClick = () => {
    if (allNotificationsSelected) {
      dispatch(unselectAllNotifications());
      dispatch(unselectedRowFromSelectAll(true));
      dispatch(unselectTotalNotifications());
    } else {
      dispatch(selectAllNotifications());
    }
  };

  return (
    <Checkbox
      theme="grey"
      onChange={ handleClick }
      state={ allNotificationsSelected }
      partialChecked={ someNotificationsSelected && !allNotificationsSelected }
    />
  );
};

export default CheckHeader;
