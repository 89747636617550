import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeReadOnly, enableDisable, selectWorkspace, updateWS, workspaceSelectedFromTable
} from 'Screens/Contextualization/Workspaces/actions/Actions';
import TimeAgo from 'react-timeago';
import get from 'lodash/get';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { PERMISSION_TYPES, FEATURE_WORKSPACES } from 'Hooks/usePermission/permissions';
import {
  Wrapper, Title, Label, Text, FieldContainer, LockIcon, UnlockIcon,
  MoreActionsWrapper, MoreButton, MoreIcon, Dropdown, DropdownItem, DropdownText,
  ArchiveIcon, UnarchiveIcon
} from './styled';
import Stats from '../Stats';
import Histogram from '../Histogram';
import Importance from 'Common/Components/Importance';
import useClickOutside from 'Hooks/useClickOutside';
import RiskHistogram from '../RiskHistogram';

const WorkspaceItem = ({
  ws, selected, setShowConfirmation
}) => {
  const dispatch = useDispatch();
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.UPDATE));
  const updateDate = get(ws, 'update_date', null);
  const lastRunAgent = get(ws, 'last_run_agent_date', null);
  const [importance, setImportance] = useState();
  const [showDropdown, toggleDropdown] = useState(false);
  const riskHistoryAvg = get(ws, 'stats.risk_history_avg', null);

  useEffect(() => {
    if (ws) setImportance(ws.importance);
  }, [ws]);

  const callback = (importance) => {
    setImportance(importance);
    dispatch(updateWS(ws.name, { importance }));
  };

  const handleSelectWorkspace = () => {
    dispatch(selectWorkspace(selected ? [] : [ws]));
  };

  const handleSelect = () => (ws.active) && dispatch(workspaceSelectedFromTable(ws));

  const toggleActive = (e) => {
    if (canUpdate) {
      e.stopPropagation();
      if (ws.active) {
        setShowConfirmation({ show: true, ws: ws.name, isMassive: false });
      } else {
        dispatch(enableDisable(ws.name, true));
      }
      toggleDropdown(false);
    }
  };

  const toggleMoreActions = (e) => {
    e.stopPropagation();
    toggleDropdown(!showDropdown);
  };

  const toggleReadOnly = (e) => {
    if (canUpdate) {
      e.stopPropagation();
      dispatch(changeReadOnly(ws.name, !ws.readonly));
      toggleDropdown(false);
    }
  };

  const parseDate = (d) => {
    const today = moment();
    const isToday = moment(d).isSame(today, 'day');
    if (isToday) return 'Today';
    return moment(d).format('ddd D MMM YYYY');
  };

  const parsedDateRange = (ws) => {
    const startDate = get(ws, 'duration.start_date', null);
    const endDate = get(ws, 'duration.end_date', null);
    if (!startDate || !endDate) return 'No start or end date defined.';
    return `${parseDate(startDate)} - ${parseDate(endDate)}`;
  };

  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => toggleDropdown(false));

  const parsedRiskData = riskHistoryAvg?.slice(0, 19).map((item) => {
    const { risk, date } = item;
    const obj = { date, risk: {} };
    if ((risk >= 0) && (risk < 10)) obj.risk = { severity: 'info', avg: risk };
    else if ((risk >= 10) && (risk < 40)) obj.risk = { severity: 'low', avg: risk };
    else if ((risk >= 40) && (risk < 70)) obj.risk = { severity: 'medium', avg: risk };
    else if ((risk >= 70) && (risk < 90)) obj.risk = { severity: 'high', avg: risk };
    else obj.risk = { severity: 'critical', avg: risk };
    return obj;
  });

  return (
    <Wrapper selected={ selected } active={ ws.active } onClick={ handleSelectWorkspace } title={ ws.active ? null : 'This Workspace is archived' }>
      <FieldContainer>
        <Importance importance={ importance } callback={ callback } />
        <Title onClick={ handleSelect }>{ ws.name }</Title>
        { ws.readonly ? <LockIcon title="This Workspace is locked (read-only)" /> : <UnlockIcon title="This Workspace is unlocked" />}
        <MoreActionsWrapper ref={ dropdownRef }>
          <MoreButton onClick={ toggleMoreActions } title="More"><MoreIcon title="More" /></MoreButton>
          { showDropdown &&
            <Dropdown>
              { ws.readonly
                ? <DropdownItem onClick={ toggleReadOnly }>
                    <UnlockIcon />
                    <DropdownText>Unlock</DropdownText>
                  </DropdownItem>
                : <DropdownItem onClick={ toggleReadOnly }>
                    <LockIcon />
                    <DropdownText>Lock</DropdownText>
                  </DropdownItem>
              }
              { ws.active
                ? <DropdownItem onClick={ toggleActive }>
                    <ArchiveIcon />
                    <DropdownText>Archive</DropdownText>
                  </DropdownItem>
                : <DropdownItem onClick={ toggleActive }>
                    <UnarchiveIcon />
                    <DropdownText>Unarchive</DropdownText>
                  </DropdownItem>
              }
            </Dropdown>
          }
        </MoreActionsWrapper>
      </FieldContainer>
      <FieldContainer>
        <Text>{ parsedDateRange(ws) }</Text>
      </FieldContainer>
      <FieldContainer>
        <Label>Description:</Label>
        <Text>{ ws.description }</Text>
      </FieldContainer>
      <Stats totalVulns={ ws.stats.total_vulns } totalAssets={ ws.stats.hosts } totalServices={ ws.stats.services } totalConfirmed={ ws.stats.confirmed_vulns } totalCritical={ ws.stats.critical_vulns } totalOpen={ ws.stats.opened_vulns } />
      <FieldContainer>
        <Label>Last Modified:</Label>
        <Text>
          { updateDate ? <TimeAgo date={ updateDate } /> : <div children="Not available." /> }
        </Text>
      </FieldContainer>
      <FieldContainer>
        <Label>Last Agent Execution:</Label>
        <Text>
          { lastRunAgent ? <TimeAgo date={ lastRunAgent } /> : <div children="Never." /> }
        </Text>
      </FieldContainer>
      <Histogram data={ ws.histogram } />
      { riskHistoryAvg && <RiskHistogram data={ parsedRiskData } /> }
    </Wrapper>
  );
};

WorkspaceItem.propTypes = {
  ws: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool.isRequired
};

export default WorkspaceItem;
