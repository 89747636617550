/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import StringField from 'Common/Components/StringField';
import {
  Title, SubTitle, Button, ButtonsContainer, LoginLogoContainer, Logo
} from 'Common/Components/MainContainer/styled';
import { FILE_SELECTOR } from 'Screens/Wizard/constants';
import { addWorkspace } from 'Screens/Wizard/actions/Actions';
import logo from 'Images/faraday_logo.svg';
import get from 'lodash/get';
import { Wrapper, FieldTitle, ErrorMessage } from './styled';

class StepWorkspaceName extends Component {
  constructor (props) {
    super(props);
    this.state = {
      wsName: ''
    };

    this.createWorkspace = this.createWorkspace.bind(this);
  }

  componentDidUpdate () {
    const { wizard, onStep } = this.props;
    if (wizard.isSuccess) {
      this.setState({ wsName: '' });
      onStep(FILE_SELECTOR);
    }
  }

  createWorkspace () {
    if (!this.props.wizard.isFetching) {
      this.props.addWorkspace(this.state.wsName);
    }
  }

  render () {
    const { wsName } = this.state;
    const { wizard, user, onStep } = this.props;
    const createNewWorkspace = get(wizard, 'createNewWorkspace', false);
    const handleNextBtnClick = () => {
      if (createNewWorkspace) {
        this.createWorkspace();
      } else {
        onStep(FILE_SELECTOR);
      }
    };

    return (
      <Wrapper>
        <LoginLogoContainer>
          <Logo src={ logo } alt="Faraday" />
        </LoginLogoContainer>
        <Title>{`Welcome, ${user}!`}</Title>
        <SubTitle>Let&apos;s set up your Faraday instance</SubTitle>
        <FieldTitle>Your initial project name is:</FieldTitle>
        <StringField id="workspace_name" width="340px" height="34px" placeholder="Project Name" onChange={ (v) => { this.setState({ wsName: v }); } } value={ wsName } />
        <ButtonsContainer>
          <ErrorMessage>{ wizard.errorMessage }</ErrorMessage>
          <Button onClick={ handleNextBtnClick } disabled={ false }>Next</Button>
        </ButtonsContainer>
      </Wrapper>
    );
  }
}

StepWorkspaceName.propTypes = {
  onStep: PropTypes.func.isRequired,
  addWorkspace: PropTypes.func.isRequired,
  wizard: PropTypes.object.isRequired,
  user: PropTypes.string
};

StepWorkspaceName.defaultProps = {
  user: ''
};

const mapDispatchToProps = (dispatch) => ({
  addWorkspace: (wsName) => {
    dispatch(addWorkspace(wsName));
  }
});

const mapStateToProps = (state) => ({
  wizard: state.wizard,
  user: state.sesion.user
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepWorkspaceName));
