import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  margin-top: 21px;
  padding: 22px;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.07);
  border: solid 1px #dbdbdb;
  background-color: ${colors.paleGrey};
`;
Wrapper.displayName = 'Wrapper';

export const Summary = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.38;
  text-align: center;
  color: ${colors.dark2};
`;
Wrapper.displayName = 'Wrapper';

export const UpgradeLicense = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.38;
  text-align: center;
  color: ${colors.grey19};
`;
Wrapper.displayName = 'Wrapper';
