import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 94%;
  ${({ showNewUI }) => showNewUI && `
    margin-top: 21px;
  `}
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.h2`
font-size: 21.5px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #1c2243;
`;

export const Subtitle = styled.h3`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
color: #1c2243;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.27px;
  color: #53686f;
  padding-bottom:30px;
`;

export const TableHeader = styled.div`
  color: #a6bbce;
  font-size: 10px;
  `;
