/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  FEATURE_TAGS,
  PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission';
import { TagIcon } from 'Screens/Contextualization/Manage/components/ManageTable/components/ContextMenu/components/styled';
import { setCreationTagModal } from 'Screens/Contextualization/Tags/actions/Actions';
import { setShowContextMenu } from 'store/ManageGeneral/actions';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';

export const Tags = () => {
  const dispatch = useDispatch();
  const title = useIntl().formatMessage({ id: 'manage.cm.tag' });
  const onClick = () => {
    dispatch(setCreationTagModal(true, 'vulnsGeneralMenu'));
    dispatch(setShowContextMenu(false));
  };
  return (
    <WithPermission permission={ FEATURE_TAGS } type={ PERMISSION_TYPES.CREATE }>
      <MenuItem
        title={ title }
        icon={ <TagIcon /> }
        onClick={ onClick }
      />
    </WithPermission>
  );
};
