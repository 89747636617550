import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setLdapField } from 'store/Settings/ldap/actions';
import {
  selectDisconnectTimeout, selectPaginatedFetch, selectUserClass, selectUserGroup
} from 'store/Settings/ldap/selectors';
import Input from 'Screens/Contextualization/Settings/components/Ldap/components/Input';
import Checkbox from 'Common/Components/Checkbox';
import { Row } from 'Screens/Contextualization/Settings/components/Ldap/styled';
import {
  Wrapper, Title, InputWrapper, StyledLabel, CheckboxWrapper
} from './styled';

const AdvancedSettings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const getIntlText = (id) => intl.formatMessage({ id: `preferences.ldap.${id}` });
  const advanced = getIntlText('advancedSettings');
  const paginatedFetchLabel = getIntlText('paginatedFetch');
  const connectionTimeOut = useSelector(selectDisconnectTimeout);
  const paginatedFetch = useSelector(selectPaginatedFetch);
  const userClass = useSelector(selectUserClass);
  const userGroup = useSelector(selectUserGroup);

  return (
    <Wrapper>
      <Title>{ advanced }</Title>
      <Row>
        <InputWrapper><Input inputValue={ connectionTimeOut || '' } field="disconnect_timeout" type="number" isRequired /></InputWrapper>
        <CheckboxWrapper>
          <Checkbox checked={ paginatedFetch } onChange={ () => dispatch(setLdapField('paginated_fetch', !paginatedFetch)) } state={ paginatedFetch } />
          <StyledLabel>{ paginatedFetchLabel }</StyledLabel>
        </CheckboxWrapper>
      </Row>
      <Row>
        <InputWrapper><Input inputValue={ userClass } field="user_class" type="string" isRequired /></InputWrapper>
        <InputWrapper><Input inputValue={ userGroup } field="group_class" type="string" isRequired /></InputWrapper>
      </Row>

    </Wrapper>
  );
};

export default AdvancedSettings;
