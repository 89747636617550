import styled from 'styled-components';
import colors from 'Styles/colors';
import { Link } from 'react-router-dom';

import { ReactComponent as CloseSVG } from 'Images/icon-close-without-background.svg';

export const Wrapper = styled.div`
  user-select: none;
`;
Wrapper.displayName = 'Wrapper';

export const TitleWrapper = styled.div`
  display: flex;
`;
TitleWrapper.displayName = 'TitleWrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
  margin: 20px 0;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.44;
  color: ${colors.grey19};
  margin-bottom: 24px;
`;
Subtitle.displayName = 'Subtitle';

export const Blue = styled(Link)`
  color: ${colors.blueCerulean};
`;
Blue.displayName = 'Blue';

export const CloseIcon = styled(CloseSVG)`
  margin: auto;
  cursor: pointer;
  top: 9px;
  left: 9px;
  position: absolute;
  &>:hover{
    & g {
      & circle{
        background-color: #eeeeee;
      }  
    }
  }  
  & g {
    & path{
      fill: #515a65;
    }  
  }
`;
CloseIcon.displayName = 'CloseIcon';

export const CloseIconWrapper = styled.div`
  position: relative;
  padding-left: 3px;
  width: 37px;
  height: 37px;
  margin: auto 0 auto auto;
  cursor: pointer;
  &:hover{
    background-color: #eeeeee;
    border-radius: 50%;
  }  
`;
CloseIconWrapper.displayName = 'CloseIconWrapper';
