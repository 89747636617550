import uniq from 'lodash/uniq';

import {
  WORKSPACE_SELECTED_FOR_WORKING,
  GET_WORKSPACES_FOR_WORKING_STARTED,
  GET_WORKSPACES_FOR_WORKING_SUCCESS,
  GET_WORKSPACES_FOR_WORKING_FAILURE,
  STOP_REDIRECT,
  WORKSPACE_SELECTED,
  DELETE_WORKSPACES,
  GET_DATA_WORKSPACE_START,
  GET_DATA_WORKSPACE_FAIL,
  GET_DATA_WORKSPACE_SUCCESS,
  WORKSPACE_UPDATE_SUCCESS,
  SET_VISIBILITY_CREATE_MODAL_WORKSPACE,
  SET_USERS_WORKSPACES,
  WORKSPACE_MASSIVE_UPDATE_FAIL,
  WORKSPACE_MASSIVE_UPDATE_SUCCESS,
  MORE_OPTIONS_STATUS_CHANGE_WORKSPACE,
  SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE,
  TOGGLE_INACTIVE_FLAG,
  GET_DATA_WORKSPACES_SUCCESS,
  WORKSPACE_SHOW_GRID_VIEW,
  WORKSPACE_ENABLE_DISABLE,
  GET_WORKSPACE_STATS_SUCCESS,
  GET_WORKSPACE_STATS_FAIL,
  GET_WORKSPACES_FOR_SELECTOR_START,
  GET_WORKSPACES_FOR_SELECTOR_SUCCESS,
  GET_WORKSPACES_FOR_SELECTOR_FAIL,
  GET_WORKSPACE_STATS_START,
  GET_WORKSPACES_FOR_LIST_VIEW_SUCCESS
} from '../../Screens/Workspaces/actions/Actions';

import {
  UNSELECT_ALL_WORKSPACES,
  WORKSPACE_UNSELECTED,
  NEW_PIVOT,
  SELECT_ALL_WORKSPACES,
  WORKSPACE_SELECTED_FROM_GRID_VIEW,
  SHOW_WORKSPACE_DETAIL,
  HIDE_WORKSPACE_DETAIL,
  REFRESH_WS_START,
  REFRESH_WS,
  REFRESH_WS_FAIL,
  RESET_REFRESH_WS,
  SET_FETCHING_PREVIEW,
  CONTEXT_WORKSPACE_SELECTED,
  CONTEXT_WORKSPACE_UPDATE_SUCCESS,
  CONTEXT_WORKSPACE_CREATE_UPDATE_FAIL,
  RESET_ERROR_VALUE,
  CONTEXT_WORKSPACE_MASSIVE_UPDATE_SUCCESS,
  CONTEXT_GET_DATA_WORKSPACES_SUCCESS,
  SET_WORKSPACES_FILTER_ERROR
} from '../../Screens/Contextualization/Workspaces/actions/Actions';

import defaultFields from 'store/Workspace/fields';

const WS_KEY = 'workspaceSelected';

export const loadData = (key, defaultValue) =>
  localStorage.getItem(key) || defaultValue;
export const saveData = (key, value) => {
  localStorage.setItem(key, value);
};

const initialState = {
  isFetching: false,
  workspaces: [],
  workspaceSelected: loadData(WS_KEY, ''),
  workspaceList: [],
  error: false,
  errorMessage: '',
  redirect: false,
  mode: '',
  workspacesSelected: [],
  lastSelected: -1,
  lastWorkspaceSelected: {},
  lastIndexForRange: -1,
  showModalCreate: false,
  moreOptionsOpened: false,
  showModalAssignUsers: false,
  users: [],
  showInactive: false,
  workspaceCount: 0,
  showGridView: false,
  contextShowGridView: true,
  rowsPerPageListView: 50,
  workspacesForListView: [],
  fields: defaultFields
};

function faraday (state = initialState, action) {
  switch (action.type) {
    case GET_DATA_WORKSPACE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_DATA_WORKSPACE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }

    case GET_DATA_WORKSPACE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspaces: action.data
      };
    }

    case WORKSPACE_SELECTED_FOR_WORKING: {
      saveData(WS_KEY, action.workspace);
      return {
        ...state,
        workspaceSelected: action.workspace /* redirect: action.redirect */
      };
    }
    case STOP_REDIRECT:
      return { ...state, redirect: false };

    case GET_WORKSPACES_FOR_WORKING_STARTED:
      return { ...state, isFetching: true, loadingWsForDropdown: true };

    case GET_WORKSPACES_FOR_WORKING_SUCCESS:
      return {
        ...state,
        workspaces: action.data,
        workspaceCount: action.count,
        isFetching: false,
        loadingWsForDropdown: false
      };

    case GET_WORKSPACES_FOR_WORKING_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.data,
        isFetching: false,
        loadingWsForDropdown: false
      };

    case WORKSPACE_SELECTED: {
      const { newRowsSelected, lastSelected, indexForRange, rowSelected } =
        action.vulnsSelectedInfo;

      return {
        ...state,
        lastWorkspaceSelected: rowSelected,
        workspacesSelected: newRowsSelected,
        lastSelected: lastSelected || -1,
        lastIndexForRange: indexForRange || -1
      };
    }

    case DELETE_WORKSPACES: {
      return {
        ...state,
        isFetching: true
      };
    }

    case WORKSPACE_UPDATE_SUCCESS: {
      const { workspaceList, workspacesSelected, workspacesForListView, showGridView } = state;
      const workspaces = showGridView ? workspaceList : workspacesForListView;
      const indexWorkspaces = workspaceList.findIndex(
        (wspace) => wspace.id === action.ws.id
      );
      workspaces[indexWorkspaces] = { ...action.ws };

      const indexWorkspacesSelected = workspacesSelected.findIndex(
        (wspace) => wspace.id === action.ws.id
      );
      workspacesSelected[indexWorkspacesSelected] = { ...action.ws };

      return {
        ...state,
        workspaceList: showGridView ? workspaces : workspaceList,
        workspacesForListView: showGridView ? workspacesForListView : workspaces,
        workspacesSelected,
        workspaceDetail: action.ws,
        isFetching: false
      };
    }

    case SET_VISIBILITY_CREATE_MODAL_WORKSPACE: {
      return {
        ...state,
        showModalCreate: action.value
      };
    }

    case SET_USERS_WORKSPACES: {
      return {
        ...state,
        users: action.users
      };
    }

    case WORKSPACE_MASSIVE_UPDATE_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }

    case WORKSPACE_MASSIVE_UPDATE_SUCCESS: {
      return {
        ...state,
        workspaces: [...action.workspaces],
        showModalAssignUsers: false
      };
    }

    case MORE_OPTIONS_STATUS_CHANGE_WORKSPACE: {
      return {
        ...state,
        moreOptionsOpened: action.value
      };
    }

    case SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE: {
      return {
        ...state,
        showModalAssignUsers: action.value
      };
    }

    case TOGGLE_INACTIVE_FLAG: {
      return {
        ...state,
        showInactive: !state.showInactive
      };
    }

    case GET_DATA_WORKSPACES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspaceList: action.data,
        workspaceCount: action.count
      };
    }

    case CONTEXT_GET_DATA_WORKSPACES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspaceList: action.data,
        workspaceCount: action.count
      };
    }

    case WORKSPACE_SHOW_GRID_VIEW: {
      return {
        ...state,
        showGridView: action.showGridView,
        contextShowGridView: action.showGridView
      };
    }

    case WORKSPACE_ENABLE_DISABLE: {
      let readonly = state.readonly;

      if (action.ws === state.workspaceSelected) readonly = !readonly;

      return {
        ...state,
        isFetching: true,
        readonly
      };
    }

    case GET_WORKSPACE_STATS_START: {
      return {
        ...state,
        isFetching: true,
        currentWs: action.data
      };
    }

    case GET_WORKSPACE_STATS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        currentWs: action.data
      };
    }

    case GET_WORKSPACE_STATS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }

    case GET_WORKSPACES_FOR_SELECTOR_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_WORKSPACES_FOR_SELECTOR_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspacesForSelector: action.data
      };
    }

    case GET_WORKSPACES_FOR_SELECTOR_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }

    case WORKSPACE_SELECTED_FROM_GRID_VIEW: {
      return {
        ...state,
        workspacesSelected: action.workspaceSelected
      };
    }

    case GET_WORKSPACES_FOR_LIST_VIEW_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspacesForListView: action.data,
        workspaceCount: action.count
      };
    }

    case CONTEXT_WORKSPACE_SELECTED: {
      return {
        ...state,
        workspacesSelected: uniq([...state.workspacesSelected, ...action.payload])
      };
    }

    case UNSELECT_ALL_WORKSPACES: {
      return {
        ...state,
        workspacesSelected: []
      };
    }

    case WORKSPACE_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newWorkspacesSelected = action.payload.reduce((workspacesSelected, workspaceToDelete) => filterByID(workspacesSelected, workspaceToDelete._id), state.workspacesSelected);

      return {
        ...state,
        workspacesSelected: newWorkspacesSelected
      };
    }

    case NEW_PIVOT: {
      return {
        ...state,
        lastSelected: action.payload
      };
    }

    case SELECT_ALL_WORKSPACES: {
      return {
        ...state,
        workspacesSelected: [...action.workspacesList]
      };
    }

    case SET_FETCHING_PREVIEW: {
      return {
        ...state,
        isFetchingPreview: action.isFetchingPreview
      };
    }

    case SHOW_WORKSPACE_DETAIL: {
      return {
        ...state,
        workspaceDetail: action.workspace,
        showWsDetail: true,
        isFetchingPreview: false
      };
    }

    case HIDE_WORKSPACE_DETAIL: {
      return {
        ...state,
        workspaceDetail: undefined,
        showWsDetail: false
      };
    }

    case REFRESH_WS_START: {
      return {
        ...state,
        refreshingWs: true
      };
    }

    case REFRESH_WS: {
      return {
        ...state,
        workspaceDetail: action.workspace,
        refreshingWs: false,
        refreshWsSuccess: true
      };
    }

    case REFRESH_WS_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.error,
        refreshingWs: false
      };
    }

    case RESET_REFRESH_WS: {
      return {
        ...state,
        refreshWsSuccess: false
      };
    }

    case CONTEXT_WORKSPACE_UPDATE_SUCCESS: {
      const { workspaceList, workspacesSelected, workspacesForListView, contextShowGridView } = state;
      const workspaces = contextShowGridView ? workspaceList : workspacesForListView;

      const indexWorkspaces = workspaces.findIndex(
        (wspace) => wspace.id === action.ws.id
      );
      workspaces[indexWorkspaces] = { ...action.ws };

      const indexWorkspacesSelected = workspacesSelected.findIndex(
        (wspace) => wspace.id === action.ws.id
      );
      workspacesSelected[indexWorkspacesSelected] = { ...action.ws };

      return {
        ...state,
        workspaceList: workspaces,
        workspacesForListView: workspaces,
        workspacesSelected,
        workspaceDetail: action.ws,
        isFetching: false
      };
    }

    case CONTEXT_WORKSPACE_CREATE_UPDATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error,
        error: true,
        workspaceSaved: false
      };
    }

    case RESET_ERROR_VALUE: {
      return {
        ...state,
        errorMessage: '',
        error: false
      };
    }

    case CONTEXT_WORKSPACE_MASSIVE_UPDATE_SUCCESS: {
      const showGridView = state.contextShowGridView;
      let workspaceList = state.workspaceList;
      let workspacesForListView = state.workspacesForListView;
      if (showGridView) workspaceList = [...action.workspaces];
      else workspacesForListView = [...action.workspaces];

      return {
        ...state,
        workspaceList,
        workspacesForListView
      };
    }

    case SET_WORKSPACES_FILTER_ERROR: {
      return {
        ...state,
        isFetching: false
      };
    }

    default:
      return state;
  }
}

export default faraday;
