import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectTags, orderedTags, selectGeneralTags } from 'store/Tags/selectors';
import TagsModal from 'Common/Components/TagsModal';

const CreateEditTagsModal = ({
  servicesTags, entity, servicesSelected, addTag, removeTag, setServicesTags, allWorkspaces
}) => {
  const wsTags = useSelector(selectTags);
  const allTags = useSelector(selectGeneralTags);
  const tags = useSelector((state) => orderedTags(state, servicesTags, servicesSelected, allWorkspaces));
  const dispatch = useDispatch();

  const onClickTag = (tag) => {
    if (tag.partialSelected) {
      dispatch(addTag(tag.name));
    } else {
      if (tag.addedToVuln) dispatch(removeTag(tag.name));
      else dispatch(addTag(tag.name));
    }
  };

  const addTags = (newTags) => {
    dispatch(setServicesTags(newTags));
  };
  return (
    <TagsModal tags={ tags } onClickTag={ onClickTag } addTags={ addTags } allTags={ allWorkspaces ? allTags : wsTags } entity={ entity } />
  );
};

export default CreateEditTagsModal;
