import api from 'services/api';
import get from 'lodash/get';
import { selectBorderWidth, selectUseMarkdown } from './reportSelectors';

// **
// report section
// **
export const SET_SETTINGS_REPORT_FIELD = 'SET_SETTINGS_REPORT_FIELD';
export const SAVE_SETTINGS_REPORT_START = 'SAVE_SETTINGS_REPORT_START';
export const SAVE_SETTINGS_REPORT_SUCCESS = 'SAVE_SETTINGS_REPORT_SUCCESS';
export const SAVE_SETTINGS_REPORT_FAIL = 'SAVE_SETTINGS_REPORT_FAIL';
export const LOAD_SETTINGS_REPORT_SUCCESS = 'LOAD_SETTINGS_REPORT_SUCCESS';

//
// report section
//
export function setReportField (field, value) {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_REPORT_FIELD, field, value });
  };
}

export function saveReport () {
  return async (dispatch, getState) => {
    dispatch({ type: SAVE_SETTINGS_REPORT_START });

    const useMarkdown = selectUseMarkdown(getState());
    const borderWidth = selectBorderWidth(getState());

    try {
      await api.report.saveReportConfiguration(useMarkdown, borderWidth);
      dispatch({
        type: SAVE_SETTINGS_REPORT_SUCCESS, error: false, message: 'Report was saved successfully.', useMarkdown, borderWidth
      });
    } catch (e) {
      dispatch({ type: SAVE_SETTINGS_REPORT_FAIL, data: 'There was an error, please try again.' });
    }
  };
}

export function loadReport () {
  return async (dispatch) => {
    try {
      const response = await api.report.getReportConfiguration();

      dispatch({ type: LOAD_SETTINGS_REPORT_SUCCESS, borderWidth: get(response, 'border_size', 0), useMarkdown: get(response, 'markdown', false) });
    } catch (e) {
      dispatch({ type: SAVE_SETTINGS_REPORT_FAIL, data: 'There was an error, please try again.' });
    }
  };
}
