/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Severity } from 'Screens/Manage/components/ManageTable/components/Table/columns';
import FlagIcon from 'Common/Components/FlagIcon';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import TextLink from './TextLink/index';
import {
  Wrapper, Trash, NotAllowedWrapper, NotAllowedLabel, Info, IconWrapper
} from './styled';

const Vuln = ({
  isEditingTask, vuln, deleteVuln, bgColor
}) => {
  const intl = useIntl();
  const notAllowedLabel = intl.formatMessage({ id: 'planner.task.tab.linkedVulnNotAllowed' });
  const workspace = intl.formatMessage({ id: 'planner.task.tab.workspace' });
  const ws = get(vuln, 'workspace', '');
  const wsTooltip = ws ? `${workspace} "${ws}"` : '';

  if (vuln.allowed) {
    return (
      <Wrapper bgColor={ bgColor }>
        <Severity vuln={ vuln } />
        <FlagIcon theme={ vuln.confirmed ? 'confirmed' : 'notConfirmed' } />
        <TextLink isLinkEnabled={ isEditingTask } vuln={ vuln } />
        <IconWrapper>
          <Info title={ wsTooltip } />
          {!!deleteVuln && <Trash onClick={ deleteVuln } title="Remove" />}
        </IconWrapper>
      </Wrapper>
    );
  }

  return (
    <NotAllowedWrapper>
      <NotAllowedLabel children={ notAllowedLabel } />
    </NotAllowedWrapper>
  );
};

Vuln.propTypes = {
  isEditingTask: PropTypes.bool,
  vuln: PropTypes.object.isRequired,
  deleteVuln: PropTypes.func.isRequired,
  bgColor: PropTypes.string
};

Vuln.defaultProps = {
  isEditingTask: false
};

Vuln.defaultProps = {
  bgColor: '#fafbfc'
};

export default Vuln;
