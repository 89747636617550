import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  selectHasAssetsTags, selectHasServicesTags, selectHasVulnTags
} from 'store/Schedule/selectors';
import { setTagsEntity } from 'store/Schedule/actions';
import Entity from './Entity';
import { Wrapper, Title, Subtitle, Input, Entities } from './styled';

const Tags = ({ tags, setTags }) => {
  const dispatch = useDispatch();
  const hasAssetsTags = useSelector(selectHasAssetsTags);
  const hasVulnsTags = useSelector(selectHasVulnTags);
  const hasServicesTags = useSelector(selectHasServicesTags);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'agents.triggerAgent.tags.title' });
  const subtitle = intl.formatMessage({ id: 'agents.triggerAgent.tags.subtitle' });

  return (
    <Wrapper>
      <Title children={ title } />
      <Subtitle children={ subtitle } />
      <Input onChange={ (e) => setTags(e.target.value) } value={ tags } />
      <Entities>
         <Entity entity="assets" value={ hasAssetsTags } onChangeField={ (checked) => dispatch(setTagsEntity('assetsTags', checked)) } />
        <Entity entity="vulnerabilities" value={ hasVulnsTags } onChangeField={ (checked) => dispatch(setTagsEntity('vulnsTags', checked)) } />
        <Entity entity="services" value={ hasServicesTags } onChangeField={ (checked) => dispatch(setTagsEntity('servicesTags', checked)) } />
      </Entities>
    </Wrapper>
  );
};

export default Tags;
