import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectList } from 'store/Tags/selectors';
import TagsModal from 'Common/Components/TagsModal';
import { setTagsImportModal } from 'Screens/Contextualization/Tags/actions/Actions';

const CreateEditTagsModal = () => {
  const dispatch = useDispatch();
  const allTags = useSelector(selectList);

  const onClickTag = (tag) => {
    if (tag.addedToVuln) {
      const removedTag = {
        id: tag.id,
        key: tag.key,
        name: tag.name,
        addedToVuln: false
      };
      const tags = [...allTags.filter((t) => t.name !== tag.name), removedTag];
      dispatch(setTagsImportModal(tags));
    } else {
      const newTag = {
        id: tag.id,
        key: tag.key,
        name: tag.name,
        addedToVuln: true
      };
      const tags = [...allTags.filter((t) => t.name !== tag.name), newTag];
      dispatch(setTagsImportModal(tags));
    }
  };

  const addTags = (newTags) => {
    const parsedNewTags = newTags.map((t) => {
      return {
        id: t,
        key: t,
        name: t,
        addedToVuln: true
      };
    });
    const tags = [...allTags, ...parsedNewTags];
    dispatch(setTagsImportModal(tags));
  };

  return (
    <TagsModal tags={ allTags } onClickTag={ onClickTag } addTags={ addTags } allTags={ allTags } entity="import" />
  );
};

export default CreateEditTagsModal;
