/* eslint-disable react/no-children-prop */
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectIsSsoEnabled } from 'store/Config/selector';
import {
  SeparatorWrapper, Wrapper, OrSeparator, AnchorButton
} from './styled';

const SsoButton = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'preferences.saml.ssoButton.title' });
  const or = intl.formatMessage({ id: 'preferences.saml.ssoButton.or' });
  const isEnabled = useSelector(selectIsSsoEnabled);

  if (!isEnabled) return null;
  return (
    <Wrapper>
      <SeparatorWrapper>
        <OrSeparator children={ or } />
      </SeparatorWrapper>
      <AnchorButton href="_api/saml/login/" children={ title } />
    </Wrapper>
  );
};

export default SsoButton;
