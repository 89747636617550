import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import Modal from './styled';
import Header from './components/Header';
import Content from './components/Content';

const TagsModal = ({ tags, onClickTag, addTags, allTags, entity }) => {
  return (
    <ModalWrapper>
      <Modal>
        <Header entity={ entity } />
        <Content tags={ tags } onClickTag={ onClickTag } addTags={ addTags } allTags={ allTags } />
      </Modal>
    </ModalWrapper>

  );
};

export default TagsModal;
