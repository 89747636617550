import get from 'lodash/get';
import api from 'services/api';
import { selectVulnsSelected, selectShowDetail, selectVulnDetailId } from 'store/Manage/selectors';
import { selectPassword, selectUserName, selectSelectedTable } from 'store/Settings/serviceNowSelectors';
import { SEND_VULN_TO_TOOL } from './settingsActions';
import { loadVulnerabilityDetail } from 'store/Manage/actions';
import { UPDATE_VULN_CLIENT_SIDE } from 'store/Manage/types';
import { loadManageDetail } from 'store/Contextualization/AssetDetail/actions';
import { loadVulnDetail } from 'store/ManageGeneral/actions';
import { VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE } from 'store/Contextualization/AssetDetail/types';
import { GENERAL_MANAGE_UPDATE_VULN_CLIENT_SIDE } from 'store/ManageGeneral/types';

export const TOOL_NAME = 'servicenow';

// obtiene datos de ticketing tools
export const GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START = 'GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START';
export const GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL = 'GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL';
export const GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS = 'GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS';

// guarda los datos
export const SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START = 'SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START';
export const SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS = 'SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS';
export const SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL = 'SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL';

export const SET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FIELD = 'SET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FIELD'; // acutaliza el estado de un campo

export const AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START = 'AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START';
export const AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL = 'AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL';
export const AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS = 'AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS';
export const DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START = 'DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START';
export const DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS = 'DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS';
export const DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL = 'DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL';

export function getServiceNowSettings () {
  return async (dispatch) => {
    dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START });
    try {
      const response = await api.settings.fetchTicketingTools(TOOL_NAME);
      return dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function saveServiceNowSettings () {
  return async (dispatch, getState) => {
    dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START });
    const selectedTable = selectSelectedTable(getState());
    const isIncidentConfigSelected = selectedTable === 'Incidents';

    try {
      const {
        url, template, category, subcategory
      } = getState().settings.ticketingTools.serviceNow;

      let data = {};
      if (isIncidentConfigSelected) {
        data = {
          tool: TOOL_NAME,
          url,
          ticket_config: {
            category,
            subcategory,
            template,
            table: 'incident'
          }
        };
      } else {
        data = {
          tool: TOOL_NAME,
          url,
          ticket_config: {
            template,
            table: 'sn_vul_app_vulnerable_item'
          }
        };
      }

      const response = await api.settings.saveTicketingToolsServiceNow(data);
      return dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function setField (field, value) {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FIELD, field, value });
  };
}
export function sendVulnToServiceNow (basicUsername, basicPassword) {
  return async (dispatch, getState) => {
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: true, error: false, message: '' } });
    try {
      const isVulnDetailOpen = selectShowDetail(getState());
      const vulnId = selectVulnDetailId(getState());
      const vulnsSelected = selectVulnsSelected(getState());
      const username = selectUserName(getState());
      const password = selectPassword(getState());
      const data = { vulns: vulnsSelected };
      data.username = basicUsername || username;
      data.password = basicPassword || password;

      const updatedVulns = await api.settings.sendVulnToServiceNow(data);
      updatedVulns.forEach(vuln => {
        dispatch({ type: UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
      });

      if (isVulnDetailOpen) dispatch(loadVulnerabilityDetail(vulnId, true));

      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: false, message: 'Operation was executed successfully.' } });
    } catch (e) {
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: true, message: get(e, 'message', 'There was an error, please try again.') } });
    }
  };
}

export function authenticate (clientId, clientSecret, username, password) {
  return async (dispatch) => {
    dispatch({ type: AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START });
    try {
      const response = await api.settings.authenticate('servicenow', {
        client_id: clientId, client_secret: clientSecret, username, password
      });
      dispatch({ type: AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS, response });
    } catch (e) {
      dispatch({ type: AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL, error: true, message: get(e, 'message', 'There was an error, please try again.') });
    }
  };
}

export function deauthenticate () {
  return async (dispatch) => {
    try {
      dispatch({ type: DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START });
      await api.settings.deauthenticate('servicenow');
      dispatch({ type: DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS });
      // dispatch(getServiceNowSettings());
    } catch (e) {
      dispatch({ type: DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL, error: true, message: get(e, 'message', 'There was an error, please try again.') });
    }
  };
}

export function sendVulnToServiceNowContext (vulnsSelected, vulnId, isVulnDetailOpen, entity, basicUsername, basicPassword) {
  return async (dispatch, getState) => {
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: true, error: false, message: '' } });
    try {
      const username = selectUserName(getState());
      const password = selectPassword(getState());
      const data = { vulns: vulnsSelected };
      data.username = basicUsername || username;
      data.password = basicPassword || password;

      const updatedVulns = await api.settings.sendVulnToServiceNow(data);
      updatedVulns.forEach(vuln => {
        if (entity === 'vulns') dispatch({ type: UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
        else if (entity === 'vulnsAssets') dispatch({ type: VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
        else dispatch({ type: GENERAL_MANAGE_UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
      });

      if (isVulnDetailOpen) {
        if (entity === 'vulns') dispatch(loadVulnerabilityDetail(vulnId, true));
        else if (entity === 'vulnsAssets') dispatch(loadManageDetail(vulnId));
        else dispatch(loadVulnDetail(vulnId));
      }

      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: false, message: 'Operation was executed successfully.' } });
    } catch (e) {
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: true, message: get(e, 'message', 'There was an error, please try again.') } });
    }
  };
}
