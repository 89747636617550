import styled from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';
import { ReactComponent as QuestionIcon } from 'Images/help-sysreq.svg';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
Wrapper.displayName = 'Wrapper';

export const Tabs = styled.div`
  background: #ebeff3;
  //width: 243px;
  width: 333px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
Tabs.displayName = 'Tabs';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 500;
  line-height: 1.16;
  text-align: left;
  color: ${colors.softBlue};
  padding: 35px 34px 32px 34px;
`;
Title.displayName = 'Title';

export const Dashed = styled.div`
    position: absolute;
    border-left: 2px dashed #90a9c0;
    height: 22px;
    left: 39.5px;
    top: 23px;
  `;
Dashed.displayName = 'Dashed';

export const Item = styled.div`
  display:flex;
  align-items: center;
  font-size: 13.5px;
  line-height: 1.04;
  text-align: left;
  padding: 10px 34px;
  position:relative;
  &::before {
    content: "";
    background-color: ${(props) => (props.active ? '#90a9c0' : 'transparent')};
    border: solid 2px #90a9c0;
    border-radius: 50%;
    width: 13px;
    height:13px;
    margin-right: 22px;
  }

  &:last-child ${Dashed} {
    display:none;
  }
  `;
Item.displayName = 'Item';

export const TabContent = styled.div`
  overflow: auto;
  width:100%;
`;
TabContent.displayName = 'TabContent';

export const Container = styled.div`
  width: 900px;
  height: 650px;
  background-color: ${colors.white};
  & > :first-child {
    box-shadow: 18px 43px 50px 0 rgba(167, 167, 167, 0.5);
    border-top: 10px solid ${colors.softBlue};
    border-radius: 2px 2px 0 0;
    overflow: auto;
  }
  ${fadeIn(0, 1)}
`;
Container.displayName = 'Container';

export const MenuList = styled.div`

`;

export const Footer = styled.div`
  display: flex;
  margin-top: auto;
  padding: 0 34px;
`;

export const FooterLink = styled.div`
font-size: 13.5px;
font-weight: 500;
color: #0082ed;
margin: auto 0 34px 0px;
cursor: pointer
`;

export const QuestionMark = styled(QuestionIcon)`
margin-right: 8px;
`;
