import React from 'react';
import { BlueStyle, Bold, Text } from './styled';
import { useDispatch } from 'react-redux';
import { redirect } from 'store/Router/actions';

const Blue = ({ children, link, onClick }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(redirect(link));
    if (onClick) onClick();
  };
  return <BlueStyle onClick={ handleClick }>{children}</BlueStyle>;
};

const parseIncomingNotification = (str, link, onClick) => {
  if (!str) return null;
  const parts = str.split(/(%blue%|%bold%)/);
  const result = [];
  let currentStyle = null;

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];

    // If the part is a style marker
    if (part === '%blue%' || part === '%bold%') {
      // If there is no current style, it means this marker is opening a new style
      if (!currentStyle) {
        currentStyle = part === '%blue%' ? Blue : Bold;
      } else {
        // Otherwise, this marker is closing the current style
        currentStyle = null;
      }
    } else if (currentStyle) {
      // If there is a current style, create an element with the current style
      result.push(React.createElement(currentStyle, { key: i, link, onClick }, part));
    } else {
      // If there is no current style, add the part as plain text
      result.push(part);
    }
  }

  return <Text>{result}</Text>;
};

export default parseIncomingNotification;
