import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { redirect } from 'store/Router/actions';
import { addFilter } from 'store/Filters/actions';
import { CLOSED_FILTERS, CONFIRMED_FLAG_FILTERS, DASHBOARD_FILTERS } from 'store/Filters/constants';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { isFilteringBy } from 'store/Filters/selectors';
import {
  Wrapper, Title, AverageWrapper, Avarage, Text,
  Graph, References, Reference, AverageLine, Vulns
} from './styled';
import { Empty } from '../Threats/styled';
import colors from 'Styles/colors';
import formatNumber from 'Common/Functions/FormatNumber';

const AverageRisk = () => {
  const { confirmed } = CONFIRMED_FLAG_FILTERS;
  const { closed } = CLOSED_FILTERS;
  const dispatch = useDispatch();
  const stats = useSelector((state) => get(state, 'dashboard.tools[3].data.stats', {}));
  const workspaceSelected = useSelector((state) => selectCurrentWorkspace(state));
  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));
  const isFilteringByClosed = useSelector((state) => isFilteringBy(state, 'vulns', closed));
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'dashboard.averageRisk.title' });
  const averageText = intl.formatMessage({ id: 'dashboard.averageRisk.average' });

  const {
    above_risk_avg_count,
    below_risk_avg_count,
    total_risk_avg,
    total_vulns
  } = stats;

  const data = [];
  if (stats.below_risk_avg_count > 0) data.push({ id: 'below', name: 'Below Average', value: stats.below_risk_avg_count });
  if (stats.above_risk_avg_count > 0) data.push({ id: 'above', name: 'Above Average', value: stats.above_risk_avg_count });

  const averageColor = (avg) => {
    if ((avg >= 90) && (avg < 100)) return colors.purple6;
    else if ((avg >= 70) && (avg < 90)) return colors.red5;
    else if ((avg >= 40) && (avg < 70)) return colors.orange4;
    else if ((avg >= 10) && (avg < 40)) return colors.green7;
    else return colors.grey2;
  };

  const handleClick = (averageLabel) => {
    const operators = {
      below: '<',
      above: '>='
    };
    const filterByRiskAvg = DASHBOARD_FILTERS.vulnsByRiskAvg(operators[averageLabel], total_risk_avg);
    const filterByConfirmed = isFilteringByConfirm ? CONFIRMED_FLAG_FILTERS.confirmed : CONFIRMED_FLAG_FILTERS.notConfirmed;

    dispatch(addFilter('vulns', filterByRiskAvg));
    if (isFilteringByConfirm) dispatch(addFilter('vulns', filterByConfirmed));
    if (isFilteringByClosed) dispatch(addFilter('vulns', CLOSED_FILTERS.closed));
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  return (
    <Wrapper>
      <Title>{ title }</Title>
        {(isEmpty(stats) || !total_risk_avg || isNaN(total_risk_avg))
          ? <Empty>No vulnerabilities has been found yet.</Empty>
          : <AverageWrapper>
              <Avarage average={ averageColor(total_risk_avg) }>{ total_risk_avg }</Avarage>
              <Text>{ averageText }</Text>
            </AverageWrapper>
        }
        { (data && total_risk_avg)
          ? <>
            <Graph>
            { (above_risk_avg_count && below_risk_avg_count) ? <Vulns side="left">{ formatNumber(below_risk_avg_count) }</Vulns> : null }
            { data.map((average) => <AverageLine average={ average.id } onClick={ () => { handleClick(average.id); } } key={ average.id } width={ (average.value / total_vulns) * 100 } />)}
            { (above_risk_avg_count && !below_risk_avg_count) ? <Vulns side="right">{ formatNumber(above_risk_avg_count) }</Vulns> : null }
            { (above_risk_avg_count && below_risk_avg_count) ? <Vulns side="right">{ formatNumber(above_risk_avg_count) }</Vulns> : null }
            </Graph>
            <References>
              { data.map((average) => <Reference onClick={ () => { handleClick(average.id); } } average={ average.id } key={ average.id }>{ intl.formatMessage({ id: `dashboard.averageRisk.${average.id}` }) }</Reference>)}
            </References>
            </>
          : null }
    </Wrapper>
  );
};

export default AverageRisk;
