import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import selectModal from 'store/modals/selectors';
import { MODAL_MANAGE_BULK_UPDATE } from 'store/modals/modals';
import { selectBulkUpdateCustomAttribute, selectModalBulkUpdateField, selectVulnsSelected } from 'store/ManageGeneral/selectors';
import { setBulkUpdateField, setBulkUpdateValue } from 'store/ManageGeneral/actions';
import get from 'lodash/get';
import BulkTitle from './components/BulkTitle';
import SubmitButtons from './components/SubmitButtons';
import { ModalContent, Wrapper, EditorWrapper } from './styled';
import BULK_UPDATE_COMPONENTS from './components/components';
import { BulkDescription } from 'Screens/Contextualization/Manage/components/ManageTable/components/BulkUpdateModal/styled';
import { useIntl } from 'react-intl';

const BulkUpdateModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const bulkUpdateField = useSelector(selectModalBulkUpdateField);
  const showModal = useSelector((state) => selectModal(state, MODAL_MANAGE_BULK_UPDATE));
  const vulnsSelected = useSelector(selectVulnsSelected);
  const selectedVulnsCount = vulnsSelected.length;
  const customAttribute = useSelector(selectBulkUpdateCustomAttribute);
  const field = bulkUpdateField === 'custom_fields' ? get(customAttribute, 'field_type', '') : bulkUpdateField;
  const bulkUpdateComponent = get(BULK_UPDATE_COMPONENTS, field, null);
  const showSubmitButtons = bulkUpdateField !== 'comment';

  useEffect(() => () => {
    dispatch(setBulkUpdateValue(''));
    dispatch(setBulkUpdateField(''));
  }, [dispatch]);

  if (!showModal) return null;
  return (
    <Wrapper>
      <ModalContent>
        <BulkTitle />
        { selectedVulnsCount > 1 && <BulkDescription>{ intl.formatMessage({ id: 'vulns.edit.description' }) }</BulkDescription> }
        <EditorWrapper isBulk={ selectedVulnsCount > 1 }>
          { bulkUpdateComponent }
        </EditorWrapper>
        { showSubmitButtons && <SubmitButtons /> }
      </ModalContent>
    </Wrapper>
  );
};

export default BulkUpdateModal;
