import styled from 'styled-components';
import colors from 'Styles/colors';
import { SubMenu } from 'react-contextmenu';

export const Wrapper = styled.div`
  .react-contextmenu {
    width: 269px;
    box-shadow: 2px 2px 10px 0 #d5dee6;
    border-radius: 2px;
    box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px #cdd5dd;
    background-color: #ffffff;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Icon = styled.img`
  width: 18px;
  height: 17px;
  display: inline-block;
  margin-left: 25px;
  margin-right: 13px;
`;
Icon.displayName = 'Icon';

export const IconActiveInactive = styled(Icon)`
  margin-bottom: 2px;
`;
IconActiveInactive.displayName = 'IconActiveInactive';

export const MenuItemContent = styled.div`
`;
MenuItemContent.displayName = 'MenuItemContent';

export const Text = styled.div`
  display: inline-block;
  font-size: 13.5px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
`;
Text.displayName = 'Text';

export const SubMenuUsers = styled(SubMenu)`
  width: 210px !important;
`;
SubMenuUsers.displayName = 'SubMenuUsers';

export const SubMenuRol = styled(SubMenu)`
  width: 118px !important;
  border-radius: 2px;
  box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.14);
`;
SubMenuRol.displayName = 'SubMenuRol';

export const ArrowIcon = styled.div`
  width: 10px;
  height: 6px;
  margin-right: 34px;
  margin-top: 3px;
  display: inline-block;
  float: right;
  color: ${colors.blueGrey};
`;
ArrowIcon.displayName = 'ArrowIcon';

export const RoleText = styled.div`
  color: ${({ role }) => {
    if (role === 'admin') return colors.warmPurple;
    if (role === 'pentester') return colors.redPink;
    if (role === 'client') return colors.blueCerulean;
    if (role === 'asset_owner') return colors.softBlue;
    return colors.softBlue;
  }};
  font-weight: 300;
`;
RoleText.displayName = 'RoleText';
