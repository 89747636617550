import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import {
  AUTOCOMPLETE_LABELS_MAP, GROUP_BY, KEY_LABELS_MAP, OPERATOR_LABELS_MAP
} from './constants';
import { ENTITIES } from './entitiesConstants';
import { selectRowsPerPageListView, selectShowGridViewContext } from 'store/Workspace/selectors';
// import { selectShowLegacyUI } from 'store/Preferences/selectors';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';

export const selectModel = (state, model) => get(state, `filters.modelFields.${model}`, []);

export const selectFieldNames = (state, model) => {
  const fields = get(state, `filters.modelFields.${model}`, []);
  const displayNames = fields.map((m) => m.display_name);
  const mapNames = fields.reduce((acc, obj) => {
    acc[obj.display_name] = obj.name;
    return acc;
  }, {});

  displayNames.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  return {
    displayNames,
    mapNames
  };
};

export const selectFieldType = (state, model, field) => get(selectModel(state, model).find((m) => m.name === field), 'type', '');

export const selectFieldOperators = (state, model, field) => get(selectModel(state, model).find((m) => m.name === field), 'operators', []);

export const selectFieldValidValues = (state, model, field) => get(selectModel(state, model).find((m) => m.name === field), 'valid', []);

export const getSelectedEntity = (state) => get(state, 'filters.selectedEntity', '');

export const selectSelectedEntityLabel = (state) => get(ENTITIES, `${getSelectedEntity(state)}.name.label`, '');
export const selectSelectedEntityPathname = (state) => get(ENTITIES, `${getSelectedEntity(state)}.pathname`, '');
export const selectSearchFunction = (state) => get(ENTITIES, `${getSelectedEntity(state)}.searchFunction`, null);

export const selectIsAdvancedMode = (state) => get(state, 'filters.isAdvancedMode', false);

export const selectSaveEnabled = (state) => get(state, 'filters.saveEnabled', false);

export const selectFilterHistory = (entity, state) => get(state, `filters.${entity}.filterHistory`, []);

const searchKeyLabel = (name) => get(KEY_LABELS_MAP.find((key) => key.value === name), 'label', name);

const searchOperatorLabel = (op) => get(OPERATOR_LABELS_MAP.find((operator) => operator.value === op), 'label', op);

const searchAutocompleteLabel = (val) => get(AUTOCOMPLETE_LABELS_MAP.find((autocomplete) => autocomplete.value === val), 'label', val);

export const selectLabeledHistory = (entity, state) => {
  const filterHistory = selectFilterHistory(entity, state);
  const fieldsParsedAsListNested = ['cve_instances', 'tag_instances', 'hostnames'];
  const fieldsParsedAsListSimple = ['target', 'ip'];
  const groupFiltersByName = () => filterHistory.reduce(
    (result, item, index) => {
      const isFieldNestedList = fieldsParsedAsListNested.includes(item.name) && (item.op === 'any') && (item.val?.op === '==');
      const isFieldSimpleList = fieldsParsedAsListSimple.includes(item.name) && (item.op === '==');
      if (isFieldNestedList || isFieldSimpleList) {
        return ({
          ...result,
          [item.name]: {
            items: [...(result[item.name]?.items || []), item],
            index,
            name: item.name
          }
        });
      } else {
        return ({
          ...result,
          [`${item.name}__${index}`]: { items: item, index, name: `${item.name}__${index}` }
        });
      }
    },
    {}
  );
  const orderedFilters = !isEmpty(filterHistory) ? Object.values(groupFiltersByName()).sort((a, b) => (a.index < b.index ? -1 : 1)) : [];

  const labeledFilters = orderedFilters.map((filter) => {
    if (!filter.name.includes('__')) {
      const nestedFilter = isObject(filter.items[0].val);
      const values = nestedFilter ? filter.items.map((f) => f.val.val).join(', ') : filter.items.map((f) => f.val).join(', ');
      const filterName = nestedFilter ? `${filter.name}__${filter.items[0].val.name}` : filter.name;
      return {
        name: searchKeyLabel(filterName),
        op: 'is one of',
        val: values,
        originalName: filter.items[0].name,
        originalOp: filter.items[0].op,
        originalOpVal: nestedFilter ? filter.items[0].val.op : ''
      };
    } else {
      const { name, op, val } = filter.items;
      if (op === 'is_null') {
        return {
          name: searchKeyLabel(name),
          op: searchOperatorLabel(op),
          val: '',
          originalName: filter.items.name,
          originalOp: filter.items.op,
          originalOpVal: isObject(filter.items.val) ? filter.items.val.op : ''
        };
      }
      if (isObject(val)) {
        const subKeyName = `${name}__${val.name}`;
        return {
          name: searchKeyLabel(subKeyName),
          op: searchOperatorLabel(val.op),
          val: searchAutocompleteLabel(val.val).toString().replace(/%/g, ''),
          originalName: filter.items.name,
          originalOp: filter.items.op,
          originalOpVal: isObject(filter.items.val) ? filter.items.val.op : ''
        };
      } else if (filter.label) {
        return {
          name: searchKeyLabel(name),
          op: searchOperatorLabel(op),
          val: searchAutocompleteLabel(val).toString().replace(/%/g, ''),
          label: filter.label,
          originalName: filter.items.name,
          originalOp: filter.items.op,
          originalOpVal: isObject(filter.items.val) ? filter.items.val.op : ''
        };
      }
      return {
        name: searchKeyLabel(name),
        op: searchOperatorLabel(op),
        val: searchAutocompleteLabel(val).toString().replace(/%/g, ''),
        originalName: filter.items.name,
        originalOp: filter.items.op,
        originalOpVal: isObject(filter.items.val) ? filter.items.val.op : ''
      };
    }
  });

  return labeledFilters?.length > 0 ? labeledFilters : [];
};

export const selectRowsPerPage = (entity, state) => get(state, `filters.${entity}.rowsPerPage`, 50);

export const selectPage = (entity, state) => get(state, `filters.${entity}.page`, 1);

export const selectOrderBy = (entity, state) => get(state, `filters.${entity}.order_by`, []);

export const selectGroupBy = (entity, state) => get(state, `filters.${entity}.group_by`, GROUP_BY.none.key.value);

export const selectGroupByField = (entity, state) => get(state, `filters.${entity}.group_by[0].field`, '');

export const selectFilters = (entity, state) => get(state, `filters.${entity}.filters`, []);

export const selectFilterError = (entity, state) => get(state, `filters.${entity}.filterError`, false);

export const selectExpandedFilter = (entity, state) => get(state, `filters.${entity}.expandedFilter`, []);

export const selectAdvancedFilter = (state, entity) => get(state, `filters.${entity}.advancedFilter`, '');

export const selectEntity = (entity, state) => get(state, `filters.${entity}`, '');

export const selectIsFiltering = (entity, state) => selectFilters(entity, state).length > 0;

export const isFilteringBy = (state, entity, filter) => {
  const history = selectFilterHistory(entity, state);
  return !!history.find((el) => el.name === filter.name && el.op === filter.op && isEqual(el.val, filter.val));
};

export const selectIsGrouping = (entity, state) => {
  const group_by = selectGroupBy(entity, state);
  return !isEmpty(group_by) && group_by !== GROUP_BY.none.key.value;
};

export const selectQueryParam = (entity, state, isDownloadQuery = false) => {
  const entityFilters = get(state, `filters.${entity}`, null);
  // const showGridView = selectShowGridView(state);
  const showGridViewContext = selectShowGridViewContext(state);
  // const showLegacyUI = selectShowLegacyUI(state);
  // const gridView = showLegacyUI ? showGridView : showGridViewContext;
  const gridView = showGridViewContext;
  const rowsPerPageListView = selectRowsPerPageListView(state);

  if (entityFilters) {
    const {
      page, rowsPerPage, order_by, group_by, filters
    } = entityFilters;
    let rows = rowsPerPage;
    if ((entity === 'workspaces') && !gridView) rows = rowsPerPageListView;
    const offset = (page - 1) * rows;

    let parsedFilter = isDownloadQuery ? {} : { offset, limit: rows };
    const isOrdering = !isEmpty(order_by);
    const isGrouping = !isEmpty(group_by) && group_by !== GROUP_BY.none.key.value;
    const isFiltering = !isEmpty(filters);

    if (isOrdering) parsedFilter = Object.assign(parsedFilter, { order_by });
    if (isGrouping && !isDownloadQuery) parsedFilter = isOrdering ? { group_by, order_by } : { group_by };
    if (isFiltering) parsedFilter = Object.assign(parsedFilter, { filters });

    return parsedFilter;
  }
  return [];
};

export const selectAdvancedFilterQueryParam = (state, entity, isDownloadQuery = false) => {
  const {
    page, rowsPerPage, group_by, order_by
  } = selectEntity(entity, state);
  const advancedFilter = selectAdvancedFilter(state, entity);
  const orderBy = JSON.stringify(order_by);
  const groupBy = JSON.stringify(group_by);
  const offset = (page - 1) * rowsPerPage;
  const isGrouping = !isEmpty(group_by) && group_by !== GROUP_BY.none.key.value;

  let queryToParse = '';
  if (isDownloadQuery) queryToParse = `{"order_by":${orderBy},"filters":[${advancedFilter}]}`;
  else if (isGrouping) queryToParse = `{"order_by":${orderBy},"group_by":${groupBy},"filters":[${advancedFilter}]}`;
  else queryToParse = `{"offset":${offset},"limit":${rowsPerPage},"order_by":${orderBy},"filters":[${advancedFilter}]}`;
  const parsedQuery = JSON.parse(queryToParse);
  return parsedQuery;
};

export const selectExpandedQueryParam = (entity, state) => {
  const filters = selectFilters(entity, state);
  const expandedFilters = selectExpandedFilter(entity, state);

  if (filters.length > 0) {
    const concatenatedFilter = [
      {
        and: [
          ...filters,
          ...expandedFilters
        ]
      }
    ];
    return { filters: concatenatedFilter };
  }

  return { filters: expandedFilters };
};

export const selectAdvancedExpandedQueryParam = (state, entity) => {
  const advancedFilter = selectAdvancedFilter(state, entity);
  const expandedFilter = selectExpandedFilter(entity, state);

  let filters = [];
  if (advancedFilter) {
    try {
      const filtersToParse = `{"filters":[${advancedFilter}]}`;
      const parsedFilter = JSON.parse(filtersToParse);
      filters = parsedFilter.filters;
    } catch (e) {
      return { filters: [] };
    }

    if (filters.length > 0) {
      const concatenatedFilter = [
        {
          and: [
            ...filters,
            ...expandedFilter
          ]
        }
      ];
      return { filters: concatenatedFilter };
    }
    return { filters: expandedFilter };
  }

  return { filters };
};

export const selectCustomFilters = (state, entity) => get(state, `filters.${entity}.customFilters`, []);

export const selectCustomFilter = (state, entity, id) => {
  const customFilters = selectCustomFilters(state, entity);
  const customFilter = customFilters.find((customFilter) => (customFilter.id === id));
  if (customFilter) return customFilter;
  return {};
};

export const selectStoredDashboardFilter = (state) => {
  return get(state, 'filters.dashboard', {});
};

export const selectCustomFieldsKey = (state) => {
  const customFields = selectCustomAttributes(state);
  const parsedCustomFields = customFields.map((cf) => {
    return { name: { value: cf.field_name, label: cf.field_display_name }, type: cf.field_type };
  });
  return {
    autocomplete: parsedCustomFields,
    name: { label: 'Custom Attribute', value: 'custom_fields->' },
    operators: []
  };
};
