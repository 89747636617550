import { copy } from '../../Common/Functions/Copy';
import * as types from './types';
import initialState from './initialState';
import { MANAGE_CREATE_UPDATE_SUCCESS } from 'Screens/ManageEditCreate/actions/Actions';
import { uniqBy } from 'lodash';
import { CONTEXT_MANAGE_CREATE_UPDATE_SUCCESS } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';

function vulns (state = initialState, action) {
  switch (action.type) {
    case types.SET_IMAGE_PREVIEW: {
      return {
        ...state,
        imagePreview: action.imagePreview
      };
    }

    case types.DUPLICATES_GET_SUCCESS: {
      return {
        ...state,
        error: false,
        vulnDetailDuplicates: action.duplicates
      };
    }
    case types.DUPLICATES_MARK_AS_MAIN_SUCCESS: {
      return {
        ...state,
        error: false
      };
    }
    case types.DUPLICATES_MARK_SUCCESS: {
      return {
        ...state,
        error: false,
        vulnsList: action.updatedVulnList
      };
    }
    case types.DUPLICATES_REMOVE_SUCCESS: {
      return {
        ...state,
        error: false
      };
    }
    case types.DUPLICATES_ERROR: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }
    case types.SET_SELECTED_COMMENT: {
      return {
        ...state,
        selectedComment: action.selectedComment
      };
    }

    case types.SEARCH_VULNS_START: {
      return {
        ...state,
        error: false,
        isFetching: true
      };
    }

    case types.SEARCH_VULNS_FAIL: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: true,
        errorMessage: action.data
      };
    }

    case types.SET_VULNS_FILTER_ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false
      };
    }

    case types.VULN_SELECTED: {
      return {
        ...state,
        vulnsSelected: uniqBy([...state.vulnsSelected, ...action.payload], '_id')
      };
    }

    case types.NEW_PIVOT: {
      return {
        ...state,
        lastSelected: action.payload
      };
    }

    case types.VULN_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newVulnsSelected = action.payload.reduce((vulnsSelected, vulnToDelete) => filterByID(vulnsSelected, vulnToDelete._id), state.vulnsSelected);

      return {
        ...state,
        vulnsSelected: newVulnsSelected
      };
    }

    case types.SELECT_ALL_VULN: {
      return {
        ...state,
        vulnsSelected: [...action.vulnsList]
      };
    }

    case types.UNSELECT_ALL_VULN: {
      return {
        ...state,
        vulnsSelected: []
      };
    }

    case types.SHOW_MODAL_DELETE_CONFIRMATION_VULNS: {
      return {
        ...state,
        showDeleteConfirmation: true
      };
    }

    case types.HIDE_MODAL_DELETE_CONFIRMATION_VULNS: {
      return {
        ...state,
        showDeleteConfirmation: false,
        isFetching: false,
        isFetchingExpandedData: false
      };
    }

    case types.DELETE_VULNS: {
      return {
        ...state,
        isFetching: true,
        lastVulnSelected: initialState.lastVulnSelected,
        vulnsSelected: initialState.vulnsSelected,
        lastSelected: initialState.lastSelected,
        lastIndexForRange: initialState.lastIndexForRange
      };
    }

    case types.HIDE_MODAL_DELETE_CONFIRMATION_VULNS_SUCCEED: {
      return {
        ...state,
        showDeleteConfirmation: false
      };
    }

    case types.RESET_STATE_MANAGE: {
      return {
        ...initialState
      };
    }

    case types.CONFIRMATION_CHANGE_START: {
      return {
        ...state,
        isFetching: true,
        isFetchingExpandedData: true,
        error: false,
        errorMessage: ''
      };
    }

    case types.MANAGE_SET_BULK_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: false,
        errorMessage: ''
      };
    }

    case types.SET_ERROR_MESSAGE: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage
      };
    }

    case types.CONFIRMATION_CHANGE_SUCCESS: {
      const vulnsList = state.vulnsList;

      if (action.isGroupingBy) {
        const vulnGroup = vulnsList && vulnsList.find((g) => g.groupData && g.groupData.find((el) => el._id === action.vuln._id));
        const vulnGroupIndex = vulnsList && vulnsList.findIndex((g) => g.groupData && g.groupData.find((el) => el._id === action.vuln._id));
        const vulnSelected = vulnGroup.groupData && vulnGroup.groupData.findIndex((el) => el._id === action.vuln._id);
        vulnsList[vulnGroupIndex].groupData[vulnSelected] = copy(action.vuln);
      } else {
        const index = vulnsList.findIndex((x) => action.vuln._id === x._id);
        vulnsList[index] = copy(action.vuln);
      }

      const vulnDetail = state.vulnDetail ? copy(state.vulnDetail) : state.vulnDetail;

      if (state.vulnDetail && vulnDetail._id === action.vuln._id) vulnDetail.confirmed = action.vuln.confirmed;

      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        vulnsList,
        vulnDetail
      };
    }

    case types.CONFIRMATION_CHANGE_FAIL: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: true,
        errorMessage: action.error
      };
    }

    case types.GET_GROUPED_DATA_START: {
      return {
        ...state,
        isFetchingExpandedData: true
      };
    }

    case types.GET_GROUPED_DATA_SUCCESS: {
      const vulnsList = state.vulnsList;
      vulnsList[action.index].groupData = action.data;

      return {
        ...state,
        vulnsList,
        isFetchingExpandedData: false
      };
    }

    case types.RESET_ERROR_VALUE: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }

    case types.SHOW_MANAGE_LEFT_FILTERS: {
      return {
        ...state,
        showLeftFilters: action.visible,
        showVulnDetail: action.visible && action.breakpoint !== 'fourth' ? false : state.showVulnDetail
      };
    }

    case types.LOAD_VULNERABILITY_DETAIL_REQUEST: {
      return {
        ...state,
        isFetchingVulnDetails: true,
        showVulnDetail: true
      };
    }

    case types.LOAD_VULNERABILITY_DETAIL_SUCCESS: {
      return {
        ...state,
        vulnDetail: action.vuln,
        vulnDetailSelectedTab: action.vulnDetailSelectedTab || state.vulnDetailSelectedTab,
        isFetchingVulnDetails: false
      };
    }

    case types.LOAD_VULNERABILITY_DETAIL_FAILURE: {
      return {
        ...state,
        isFetchingVulnDetails: false,
        showVulnDetail: false
      };
    }

    case types.HIDE_VULNERABILITY_DETAIL: {
      return {
        ...state,
        vulnDetail: undefined,
        showVulnDetail: false,
        vulnDetailSelectedTab: initialState.vulnDetailSelectedTab
      };
    }

    case types.RESET_VULN_DETAIL: {
      return {
        ...state,
        vulnDetail: undefined,
        showVulnDetail: false
      };
    }

    case types.SET_VULNERABILITY_DETAIL_TAB: {
      return {
        ...state,
        vulnDetailSelectedTab: action.vulnDetailSelectedTab
      };
    }

    case types.UPDATE_VULN_MANAGE_PREVIEW_SUCCESS: {
      const vulnsSelectedArray = state.vulnsSelected;
      const vulnUpdatedId = vulnsSelectedArray.findIndex((vuln) => vuln._id === action.vuln._id);
      vulnsSelectedArray.splice(vulnUpdatedId, 1, action.vuln);
      return {
        ...state,
        isFetchingPreview: false,
        isFetching: false,
        vuln: action.vuln,
        vulnDetail: { ...action.vuln },
        vulnsList: action.vulnsList,
        vulnsSelected: vulnsSelectedArray
      };
    }

    case types.UPDATE_VULN_MANAGE_PREVIEW_FAILURE: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }

    case types.SELECT_GROUP_MANAGE: {
      let vulnsSelected = state.vulnsSelected;

      if (action.selected) {
        state.vulnsList[action.index].groupData.forEach((vuln) => {
          vulnsSelected.push(vuln);
        });
      } else {
        const groupVulns = state.vulnsList[action.index].groupData.map((x) => x._id);
        vulnsSelected = vulnsSelected.filter((x) => !groupVulns.includes(x._id));
      }

      return {
        ...state,
        vulnsSelected
      };
    }

    case types.SELECT_VULN_GROUP_MANAGE: {
      let vulnsSelected = state.vulnsSelected;

      if (action.selected) vulnsSelected.push(action.vuln);
      else vulnsSelected = vulnsSelected.filter((vuln) => vuln._id !== action.vuln._id);

      return {
        ...state,
        vulnsSelected
      };
    }

    case types.GET_COMMENTS_REQUEST:
      return { ...state, comment: undefined /* isFetching: true */ };
    case types.GET_COMMENTS_SUCCESS:
      return {
        ...state, isFetching: false, error: false, errorMessage: '', comments: action.data
      };
    case types.GET_COMMENTS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };

    case types.ADD_COMMENT_REQUEST:
      return { ...state, comment: undefined };
    case types.ADD_COMMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
        comments: [
          ...(state.comments.filter((item) => (item.id !== action.data.id))),
          action.data
        ]
      };
    case types.ADD_COMMENT_FAIL:
      return {
        ...state,
        isFetching: false,
        comment: undefined,
        error: true,
        errorMessage: action.data
      };

    case types.REMOVE_COMMENT_REQUEST:
      return { ...state/* isFetching: true */ };
    case types.REMOVE_COMMENT_SUCCESS:
      return {
        ...state, isFetching: false, error: false, errorMessage: '', comments: [...(state.comments.filter((item) => (item.id !== action.commentId)))]
      };
    case types.REMOVE_COMMENT_FAIL:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };

    case types.MANAGE_SET_BULK_UPDATE_FIELD: {
      return {
        ...state,
        bulkUpdateField: action.field,
        bulkUpdateValue: ''
      };
    }

    case types.MANAGE_SET_BULK_UPDATE_VALUE: {
      return {
        ...state,
        bulkUpdateValue: action.value
      };
    }

    case types.MANAGE_ADD_BULK_UPDATE_VALUE: {
      const bulkUpdateValue = state.bulkUpdateValue;
      bulkUpdateValue.push(action.value);

      return {
        ...state,
        bulkUpdateValue
      };
    }

    case types.MANAGE_REMOVE_BULK_UPDATE_VALUE: {
      let bulkUpdateValue = state.bulkUpdateValue;
      bulkUpdateValue = bulkUpdateValue.filter((element) => element !== action.value);

      return {
        ...state,
        bulkUpdateValue
      };
    }

    case types.MANAGE_SET_BULK_CUSTOM_ATTRIBUTE: {
      return {
        ...state,
        bulkUpdateCustomAttribute: action.customAttribute
      };
    }
    case types.MANAGE_BULK_UPDATE_FINISHED: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        bulkUpdateField: '',
        bulkUpdateValue: ''
      };
    }

    case types.MANAGE_BULK_SAVE_TEMPLATE_FINISHED: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false
      };
    }

    case types.GET_TEMPLATES_BULK_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.GET_TEMPLATES_BULK_FAIL: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: true,
        errorMessage: 'Failed to get templates'
      };
    }

    case types.GET_TEMPLATES_BULK_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        templates: action.data
      };
    }

    case types.UPDATE_COMMENT_REQUEST: {
      return {
        ...state
      };
    }

    case types.UPDATE_COMMENT_SUCCESS: {
      const index = state.comments.findIndex(({ id }) => id === Number(action.data.id));
      if (index > -1) {
        return {
          ...state,
          isFetching: false,
          error: false,
          errorMessage: '',
          comments: [
            ...state.comments.slice(0, index),
            action.data,
            ...state.comments.slice(index + 1)]
        };
      }
      return state;
    }

    case types.UPDATE_COMMENT_FAIL:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };

    case types.GET_VULNS_SUCCESS_MANAGE: {
      return {
        ...state,
        error: false,
        vulnsList: action.data,
        vulnsCount: action.vulnsCount,
        isFetching: false,
        isFetchingExpandedData: false
      };
    }

    case types.REFRESH_VULNS: {
      return {
        ...state,
        vulnsList: action.vulnsList,
        vulnsCount: action.vulnsCount,
        vulnsSelected: action.vulnsSelected,
        vulnDetail: action.vulnDetail
      };
    }

    case types.UPDATE_VULNS_LIST: {
      return {
        ...state,
        vulnsList: action.vulnsList
      };
    }

    case types.SET_TAGS_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.SET_TAGS_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }

    case types.SET_TAGS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.error
      };
    }
    case types.GET_USERS_SUCCESS: {
      return {
        ...state,
        userMentions: action.users
      };
    }

    case types.ADD_MOCK_VULN: {
      return {
        ...state,
        vulnsList: action.data,
        vulnsCount: action.vulnsCount,
        isFetching: false,
        isFetchingExpandedData: false,
        usesMockVulns: true
      };
    }

    case types.REMOVE_MOCK_VULN: {
      return {
        ...state,
        vulnsList: action.vulnsList,
        vulnsCount: action.vulnsCount,
        usesMockVulns: false,
        vulnsSelected: initialState.vulnsSelected
      };
    }

    case types.MANAGE_VULN_ERROR: {
      return {
        ...state,
        bulkUpdateValue: '',
        error: true,
        errorMessage: action.errorMessage
      };
    }

    case types.READ_ONLY_ERROR: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }
    case types.SHOW_CONTEXT_MENU: {
      return {
        ...state,
        showContextMenu: action.show,
        contextMenuXPos: action.contextMenuXPos,
        contextMenuYPos: action.contextMenuYPos
      };
    }
    case types.SAVE_TEMPLATE_MANAGE: {
      return {
        ...state,
        templatesCreated: action.templatesCreated,
        failedTemplates: action.errors
      };
    }
    case types.CLOSE_TEMPLATE_MODAL_MESSAGE: {
      return {
        ...state,
        templatesCreated: [],
        failedTemplates: []
      };
    }
    case types.SHOW_QUICK_PLANNER: {
      return {
        ...state,
        showQuickPlanner: action.show
      };
    }

    case MANAGE_CREATE_UPDATE_SUCCESS: {
      const vulnsList = [action.vulns, ...state.vulnsList];
      return {
        ...state,
        vulnsList,
        vulnsCount: vulnsList.length
      };
    }

    case CONTEXT_MANAGE_CREATE_UPDATE_SUCCESS: {
      const vulnsList = state.vulnsList;

      return {
        ...state,
        vulnsList: [...action.vulns, ...vulnsList]
      };
    }

    case types.TAGS_UPDATE_VULN_DETAIL: {
      return {
        ...state,
        isFetchingPreview: false,
        isFetching: false,
        vulnDetail: { ...action.vuln }
      };
    }

    case types.VULN_REFRESH_ENRICHMENT: {
      return {
        ...state,
        vulnDetail: action.vuln
      };
    }
    case types.SELECT_TOTAL_VULNS: {
      return {
        ...state,
        selectAll: true
      };
    }

    case types.UNSELECT_TOTAL_VULNS: {
      return {
        ...state,
        selectAll: false
      };
    }

    case types.SET_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }

    case types.MANAGE_SET_TEMP_CVSS: {
      return {
        ...state,
        tempCVSS: action.tempCVSS
      };
    }
    case types.UPDATE_VULN_CLIENT_SIDE: {
      const { updatedVuln, toolName } = action.payload;
      const id = updatedVuln.vuln_id;

      const vulnsListArray = state.vulnsList.map(vuln => {
        if (vuln.id === id) {
          return {
            ...vuln,
            issuetracker: {
              ...vuln.issuetracker,
              [toolName]: vuln.issuetracker[toolName] ? [...vuln.issuetracker[toolName], updatedVuln] : [updatedVuln]
            }
          };
        }
        return vuln;
      });

      return {
        ...state,
        vulnsList: vulnsListArray
      };
    }
    case types.REFRESH_VULN_ENRICHMENT_REQUEST: {
      return {
        ...state,
        isFetchingVulnEnrichment: true
      };
    }
    case types.REFRESH_VULN_ENRICHMENT_SUCCESS: {
      return {
        ...state,
        isFetchingVulnEnrichment: false
      };
    }
    case types.REFRESH_VULN_ENRICHMENT_FAILURE: {
      return {
        ...state,
        isFetchingVulnEnrichment: false,
        bulkUpdateValue: '',
        error: true,
        errorMessage: action.errorMessage
      };
    }

    case types.LOAD_EVIDENCE_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.LOAD_EVIDENCE_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }

    case types.GET_VULNS_TOTAL_COUNT: {
      return {
        ...state,
        vulnsCount: action.count
      };
    }

    case types.EXPORT_CSV_MANAGE_REQUEST: {
      return {
        ...state,
        isExportingCSV: true
      };
    }

    case types.EXPORT_CSV_MANAGE_FAILURE: {
      return {
        ...state,
        error: true,
        errorMessage: 'There was an error, please try again.',
        isExportingCSV: false
      };
    }

    case types.EXPORT_CSV_MANAGE_SUCCESS: {
      return {
        ...state,
        isExportingCSV: false
      };
    }

    default: {
      return state;
    }
  }
}

export default vulns;
