import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { FEATURE_VULN_TEMPLATE, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { Edit } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';
import get from 'lodash/get';
import { selectRow, redirectToVulnTemplateDetail, unSelectAll } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { selectTemplatesSelected } from 'store/KnowledgeBase/selectors';
import ModalWarning from 'Common/Components/ModalWarning';

const EditButton = () => {
  const [showWarning, setShowWarning] = useState(false);
  const vulnTemplatesSelected = useSelector(selectTemplatesSelected);
  const vulnTemplate = get(vulnTemplatesSelected, '[0]', null);
  const vulnTemplateId = get(vulnTemplatesSelected, '[0].id', 0);
  const dispatch = useDispatch();
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULN_TEMPLATE, PERMISSION_TYPES.UPDATE));
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'vuln template' });

  const openTemplateDetail = (e) => {
    dispatch(unSelectAll());
    dispatch(redirectToVulnTemplateDetail(vulnTemplateId));
    dispatch(selectRow(e, vulnTemplate));
  };

  const handleClick = (e) => {
    if ((vulnTemplatesSelected.length === 1) && canUpdate) openTemplateDetail(e);
    else setShowWarning(true);
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Edit" description={ message } /> }
    </>
  );
};

export default EditButton;
