import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { setSelectedProject, createProject, renameProject } from 'store/Projects/actions';
import { closeModal } from 'store/modals/actions';
import { MODAL_PROJECTS_CREATE_EDIT } from 'store/modals/modals';
import Wrapper from 'Common/Components/ReduxModal/styled';
import { selectProject } from 'store/Projects/selectors';
import TitleBar from './TitleBar';
import { ModalWrapper, TextField } from './styled';

const CreateEditProject = () => {
  const [nameValue, setNameValue] = useState('');
  const intl = useIntl();
  const dispatch = useDispatch();
  const placeholder = intl.formatMessage({ id: 'planner.project.create.placeholder' });
  const title = intl.formatMessage({ id: 'planner.project.create.nameField' });
  const selectedProject = useSelector(selectProject);
  const projectName = get(selectedProject, 'name', '');

  const onDone = () => {
    if (selectedProject) {
      dispatch(renameProject(nameValue));
      dispatch(setSelectedProject(null));
    } else {
      dispatch(createProject(nameValue));
    }
    dispatch(closeModal(MODAL_PROJECTS_CREATE_EDIT));
  };

  const onCancel = () => {
    if (selectedProject) {
      dispatch(closeModal(MODAL_PROJECTS_CREATE_EDIT));
      dispatch(setSelectedProject(null));
    } else dispatch(closeModal(MODAL_PROJECTS_CREATE_EDIT));
  };

  useEffect(() => {
    if (selectedProject) setNameValue(projectName);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, setNameValue]);

  return (
    <Wrapper>
      <ModalWrapper>
        <TitleBar isEditing={ !!selectedProject } disabled={ !nameValue } onCancel={ onCancel } onDone={ onDone } />
        <TextField mandatory title={ title } name="name" placeholder={ placeholder } value={ nameValue } onChange={ setNameValue } />
      </ModalWrapper>
    </Wrapper>
  );
};

export default CreateEditProject;
