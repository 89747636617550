/* eslint-disable react/no-children-prop */
import React from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { ButtonsContainer, CancelButton, ConfirmButton } from './styled';

const Buttons = ({ onCancelClick, disabled, onConfirmClick }) => {
  const intl = useIntl();
  return (
    <ButtonsContainer>
      <CancelButton onClick={ onCancelClick } children={ intl.formatMessage({ id: 'vuln.duplicates.modal.cancel' }) } />
      <ConfirmButton disabled={ disabled } onClick={ onConfirmClick } children={ intl.formatMessage({ id: 'vuln.duplicates.modal.confirm' }) } />
    </ButtonsContainer>
  );
};

Buttons.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onConfirmClick: PropTypes.func.isRequired
};

export default Buttons;
