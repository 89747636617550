import get from 'lodash/get';
import initialState from './initialState';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_GLOBAL_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

export const selectMapGroupByIndex = (state) => get(state, 'host.mapGroupByIndex', initialState.mapGroupByIndex);
export const selectHostList = (state) => get(state, 'host.hostList', initialState.hostList);
export const selectHostCount = (state) => get(state, 'host.hostCount', initialState.hostCount);
export const selectHostsSelected = (state) => get(state, 'host.hostsSelected', initialState.hostsSelected);
export const selectIsFetching = (state) => get(state, 'host.isFetching', initialState.isFetching);
export const selectShowDeleteConfirmation = (state) => get(state, 'host.showDeleteConfirmation', initialState.showDeleteConfirmation);
export const selectShowModalEditCreate = (state) => get(state, 'host.showModalEditCreate', initialState.showModalEditCreate);
export const selectTotalHosts = (state) => get(state, 'host.totalHosts', initialState.totalHosts);
export const selectLimitReached = (state) => get(state, 'host.limitReached', false);
export const selectAddColumnsOpened = (state) => get(state, 'host.addColumnsOpened', initialState.addColumnsOpened);
export const selectShowHostLeftFilters = (state) => get(state, 'host.showLeftFilters', false);
export const selectLastSelected = (state) => get(state, 'host.lastSelected', -1);
export const allHostsAreSelected = (state) => {
  const hosts = selectHostList(state);
  const hostList = selectHostsSelected(state);
  return hosts.every((testHost) => hostList.some((host) => host._id === testHost._id)) && hostList.length > 0;
};
export const someHostIsSelected = (state) => (selectHostsSelected(state).length > 0);
export const selectPermissions = (state) => get(state, 'faraday.permissions.hosts', []);

export const selecHostsTags = (state) => {
  const hostsSelected = selectHostsSelected(state);
  const hostsTags = hostsSelected.flatMap(host => host.tags);
  return hostsTags;
};

export const fetchGlobalHostsIsAllowed = (state) => {
  const canGetGlobalHosts = selectAllowedByRole(state, FEATURE_GLOBAL_HOSTS, PERMISSION_TYPES.READ);
  return canGetGlobalHosts;
};

export const selectCurrentHost = (state) => get(state, 'host.hostSelectedForWorking[0]', {});
export const selectModalBulkUpdateField = (state) => get(state, 'host.bulkUpdateField', '');
export const selectModalBulkUpdateValue = (state) => get(state, 'host.bulkUpdateValue', '');
export const selectShowContextMenu = (state) => get(state, 'host.showContextMenu', false);
export const selectContextMenuXPos = (state) => get(state, 'host.contextMenuXPos', 0);
export const selectContextMenuYPos = (state) => get(state, 'host.contextMenuYPos', 0);
export const selectSelectAllHosts = (state) => get(state, 'host.selectAll', false);
