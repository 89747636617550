import React from 'react';
import { Title } from 'Screens/Contextualization/Settings/common-styled';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setField } from 'store/Settings/gitLabActions';
import {
  selectAccessToken, selectProject, selectSelfSignedCertificate, selectSslVerify, selectUrl
} from 'store/Settings/gitLabSelectors';
import CheckBox from 'Common/Components/Checkbox/styled';
import {
  Url, Wrapper, Content, WrapperCustomField, AccessToken, Project
} from './styled';
import { Label } from '../IssuesConfiguration/styled';

const Settings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const props = useSelector((state) => ({
    url: selectUrl(state),
    project: selectProject(state),
    accessToken: selectAccessToken(state),
    sslVerify: selectSslVerify(state),
    selfSignedCertificate: selectSelfSignedCertificate(state)
  }));

  const change = (field, value) => {
    dispatch(setField(field, value));
  };

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.title' }) }</Title>
      <Content>
        <Url title={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.url.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.url.placeholder' }) } onChange={ (v) => { change('url', v); } } value={ props.url } defaultValue={ props.url } autocomplete="url" id="url" name="url" />
        <AccessToken title={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.accessToken.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.accessToken.placeholder' }) } onChange={ (v) => { change('accessToken', v); } } value={ props.accessToken } defaultValue={ props.accessToken } type="password" autocomplete="one-time-code" id="accessToken" name="accessToken" />
        <WrapperCustomField>
          <Label>{ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.sslVerify.title' }) }</Label>
          <CheckBox checked={ props.sslVerify } onChange={ (e) => change('sslVerify', e.target.checked) } />
        </WrapperCustomField>
        <WrapperCustomField>
          <Label>{ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.selfSignedCertificate.title' }) }</Label>
          <CheckBox checked={ props.selfSignedCertificate } onChange={ (e) => change('selfSignedCertificate', e.target.checked) } />
        </WrapperCustomField>
        <Project title={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.project.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.gitLab.settings.project.placeholder' }) } onChange={ (v) => { change('project', v); } } value={ props.project } defaultValue={ props.project } />
      </Content>
    </Wrapper>
  );
};
export default Settings;
