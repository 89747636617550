import styled from 'styled-components';
import {
  Wrapper as _Wrapper,
  StringDate as _StringDate,
  VulnType,
  VulnCount
} from '../../../Histogram/components/Flyout/styled';

export const Wrapper = styled(_Wrapper)`
  height: 120px;
`;
Wrapper.displayName = 'Wrapper';

export const StringDate = styled(_StringDate)`
  padding-bottom: 5px;
`;
StringDate.displayName = 'StringDate';

export const RiskWrapper = styled.div`
  display: flex;
  padding: 7px 0 5px 0;
  justify-content: space-between;
`;
RiskWrapper.displayName = 'RiskWrapper';

export const Type = styled(VulnType)`
  text-transform: capitalize;
  margin-right: 0;
`;
Type.displayName = 'Type';

export const Average = styled(VulnCount)`
  margin-right: 15px;
`;
Average.displayName = 'Average';
