import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { showContextMenu } from 'store/Manage/actions';
import { openModal } from 'store/modals/actions';
import { MODAL_APPLY_TEMPLATE } from 'store/modals/modals';
import { selectUserRole } from 'store/Faraday/selectors';
import {
  FEATURE_VULNS, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission';
import { TemplateIcon } from './styled';

const ApplyTemplate = () => {
  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);

  if (role === 'client' || role === 'asset_owner') return null;
  return (
    <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
      <MenuItem
        title="Apply Template"
        icon={ <TemplateIcon /> }
        onClick={ () => {
          dispatch(openModal(MODAL_APPLY_TEMPLATE));
          dispatch(showContextMenu(false));
        } }
      />
    </WithPermission>
  );
};

export default ApplyTemplate;
