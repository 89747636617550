// import { toggleAutoSelectWs } from 'Screens/Wizard/actions/Actions';
import api from 'services/api';
import { selectCurrentUserId } from 'store/Faraday/selectors';
import { setError } from 'store/errors/actions';
// import { selectLogMeLastWorkspaceUsed } from 'store/Settings/loginSettingsSelectors';

// **
// login section
// **
export const SET_SETTINGS_CHANGE_LOGIN_SETTINGS_FIELD = 'SET_SETTINGS_CHANGE_LOGIN_SETTINGS_FIELD';
export const SAVE_SETTINGS_LOGIN_SETTINGS_START = 'SAVE_SETTINGS_LOGIN_SETTINGS_START';
export const SAVE_SETTINGS_LOGIN_SETTINGS_CLEAR = 'SAVE_SETTINGS_LOGIN_SETTINGS_CLEAR';
export const SAVE_SETTINGS_LOGIN_SETTINGS_SUCCESS = 'SAVE_SETTINGS_LOGIN_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_LOGIN_SETTINGS_FAIL = 'SAVE_SETTINGS_LOGIN_SETTINGS_FAIL';
export const LOAD_SETTINGS_LOGIN_SETTINGS_SUCCESS = 'LOAD_SETTINGS_LOGIN_SETTINGS_SUCCESS';

//
// login section
//
export function setLoginSettingsField (field, value) {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_CHANGE_LOGIN_SETTINGS_FIELD, field, value });
  };
}

export function saveLoginSettings (changeEmail, email) {
  return async (dispatch, getState) => {
    dispatch({ type: SAVE_SETTINGS_LOGIN_SETTINGS_START });
    const userId = selectCurrentUserId(getState());

    try {
      // if (changeLogMeLastWs) dispatch(toggleAutoSelectWs(logMeLastWs));
      if (changeEmail) {
        const userData = await api.users.updateUser(userId, { email });
        dispatch({ type: SAVE_SETTINGS_LOGIN_SETTINGS_SUCCESS, email: userData.email, error: false, message: 'User has been successfully updated.' });
      }
    } catch (e) {
      dispatch(setError('There was an error, please try again.'));
    }
  };
}

export function clearLoginSettings () {
  return (dispatch) => {
    dispatch({ type: SAVE_SETTINGS_LOGIN_SETTINGS_CLEAR });
  };
}

export function loadLoginSettings () {
  return async (dispatch) => {
    const response = await api.preferences.fetch();
    const autoSelectWs = response.preferences.autoSelectWs;
    dispatch({ type: LOAD_SETTINGS_LOGIN_SETTINGS_SUCCESS, autoSelectWs });
  };
}
