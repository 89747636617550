import React from 'react';
import PropTypes from 'prop-types';
import { CUSTOM_ATTRIBUTE_TYPES } from 'Screens/Manage/components/CustomAttributesModal/constants';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import Wrapper from './styled';

const MdCustomField = ({
  field, vuln, onSave, initialValue
}) => {
  const handleSave = (finalValue) => onSave(vuln, field.field_name, finalValue);

  return (
    <Wrapper>
        <MarkdownEditor
          value={ initialValue }
          onBlur={ handleSave }
        />
    </Wrapper>
  );
};

MdCustomField.defaultProps = {
  initialValue: ''
};

MdCustomField.propTypes = {
  field: PropTypes.shape({
    field_display_name: PropTypes.string,
    field_type: PropTypes.oneOf([CUSTOM_ATTRIBUTE_TYPES.MARKDOWN])
  }).isRequired,
  vuln: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  initialValue: PropTypes.string
};

export default MdCustomField;
