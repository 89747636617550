/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import { selectDetailTags } from 'store/Contextualization/AssetDetail/selectors';
import { TagsWrapper, Tag } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/Tags/components/TagItems/styled';

const TagItems = () => {
  const vulnTags = useSelector((state) => selectDetailTags('vulns', state));

  return (
    <TagsWrapper>
      {
        vulnTags.map((tag, i) => (
          <Tag key={ `${tag}_${i}` }>
            { tag }
          </Tag>
        ))
      }
    </TagsWrapper>
  );
};

export default TagItems;
