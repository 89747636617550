import get from 'lodash/get';

export const selectActiveFeeds = (state) => {
  const tools = get(state, 'dashboard.tools', []);
  return tools.length >= 5 ? tools[4].data : null;
};

export const selectLatestTags = (state) => {
  const tools = get(state, 'dashboard.tools', []);
  return tools.length >= 7 ? get(tools[7], 'data.rows', []) : null;
};

export const selectLatestComments = (state) => {
  const tools = get(state, 'dashboard.tools', []);
  return tools.length >= 8 ? get(tools[8], 'data', []) : null;
};
export const selectCommandHistory = (state) => get(state, 'dashboard.commandHistory', []);
export const selectIsFetching = (state) => get(state, 'dashboard.isFetching', false);
export const selectIsLoadingState = (state, type) => get(state, `dashboard.loadingState.${type}`, false);
export const selectTools = (state) => get(state, 'dashboard.tools', []);
export const selectReloadVulnerabilities = (state) => get(state, 'dashboard.reloadVulnerabilities', false);
export const selectTotalVulns = (state) => get(state, 'dashboard.currentWs.stats.total_vulns', 0);
