import get from 'lodash/get';

export const mappedTemplates = [
  {
    label: 'Vulns Legacy (PDF)',
    docxOption: 'generic_default.docx (generic) (Word)',
    pdfOption: 'generic_default.html (generic) (PDF)'
  },
  {
    label: 'Vulns Default',
    docxOption: 'generic_vulnerabilities.docx (generic) (Word)',
    pdfOption: ''
  },
  {
    label: 'Vulns Light',
    docxOption: 'generic_vulnerabilities_light.docx (generic) (Word)',
    pdfOption: ''
  },
  {
    label: 'Vulns Dark',
    docxOption: 'generic_vulnerabilities_dark.docx (generic) (Word)',
    pdfOption: ''
  },
  {
    label: 'Vulns Default',
    docxOption: 'group_default.docx (grouped) (Word)',
    pdfOption: 'group_default.html (grouped) (PDF)'
  },
  {
    label: 'Assets (default palette)',
    docxOption: 'generic_assets.docx (generic) (Word)',
    pdfOption: ''
  },
  {
    label: 'Assets (light palette)',
    docxOption: 'generic_assets_light.docx (generic) (Word)',
    pdfOption: ''
  },
  {
    label: 'Assets (dark palette)',
    docxOption: 'generic_assets_dark.docx (generic) (Word)',
    pdfOption: ''
  },
  {
    label: 'Vulns Modern WebApps',
    docxOption: 'group_webapps_default.docx (grouped) (Word)',
    pdfOption: ''
  },
  {
    label: 'Vulns PCI Scope',
    docxOption: 'group_pci_default.docx (grouped) (Word)',
    pdfOption: ''
  },
  {
    label: 'Vulns Coverage',
    docxOption: 'group_wmapps_default.docx (grouped) (Word)',
    pdfOption: ''
  },
  {
    label: 'Vulns Modern WebApps',
    docxOption: 'generic_webapps_default.docx (generic) (Word)',
    pdfOption: ''
  },
  {
    label: 'Vulns PCI Scope',
    docxOption: 'generic_pci_default.docx (generic) (Word)',
    pdfOption: ''
  },
  {
    label: 'Vulns Coverage',
    docxOption: 'generic_wmapps_default.docx (generic) (Word)',
    pdfOption: ''
  }
];

const parseTemplate = (template) => {
  const templateName = get(template, '[1]', '');
  const isGrouped = get(template, '[0]', false);
  let temp = [];
  if (templateName.includes('(Word)')) {
    if (templateName.includes('(custom)')) {
      temp[0] = { label: templateName, isPdf: false, isGrouped };
    } else {
      temp = mappedTemplates.filter((t) => t.docxOption === templateName);
      const savedTemp = temp[0];
      temp[0] = { ...savedTemp, isPdf: false, isGrouped };
    }
  } else {
    if (templateName.includes('(custom)')) {
      temp[0] = { label: templateName, isPdf: true };
    } else {
      temp = mappedTemplates.filter((t) => t.pdfOption === templateName);
      const savedTemp = temp[0];
      temp[0] = { ...savedTemp, isPdf: true, isGrouped };
    }
  }

  return temp;
};

export default parseTemplate;
