import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as DownloadImage } from 'Images/download-dropdown.svg';

export const Wrapper = styled.div`
  visibility: ${({ keepVisible }) => keepVisible && 'visible !important'};
  position: relative;
  overflow: visible;
  cursor: pointer;
  display: flex;
  width: 60px;
  height: 38px;
  align-items: center;
  justify-content: center;
    
  & > svg {
    cursor: pointer;
    min-width: 17px;
  }

  &:hover {
    background-color: ${colors.iceBlue};
  }

  &:active {
    background-color: ${colors.blueGrey};
    & > svg {
      g, path {
        fill: ${colors.white};
      }
    }
  } 
`;
Wrapper.displayName = 'Wrapper';

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  visibility: visible !important;
  position: absolute;
  z-index: 3;
  top: 38px;
  right: 0px;
  min-height: 54px;
  max-height: 110px;
  width: 257px;
  border-radius: 2px;
  box-shadow: 0 2px 20px 0 #d1d1d1;
  border: solid 1px #d8d8d8;
  background-color: #fff;
  `;
Dropdown.displayName = 'Dropdown';

export const Backdrop = styled.div`
  cursor: pointer;
  visibility: visible !important;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;
Backdrop.displayName = 'PopupBackdrop';

export const DownloadIcon = styled(DownloadImage)`
    margin: auto;
`;
DownloadIcon.displayName = 'DownloadIcon';
