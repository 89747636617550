import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { updateWS } from 'Screens/Contextualization/Workspaces/actions/Actions';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { selectWsDetail } from 'store/Workspace/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const ID = 'description';

const Description = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentWs = useSelector(selectWsDetail);
  const description = get(currentWs, ID, '');
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.UPDATE));
  const disabled = !canUpdate;
  const isExpanded = useExpandable('general', ID, description);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.description' }) } tab="general" id={ ID }>
      <MarkdownEditor
        value={ description }
        onBlur={ (value) => dispatch(updateWS(currentWs.name, { description: value })) }
        disabled={ disabled }
        minHeight={ 57 }
      />
    </ExpandableWrapper>
  );
};

export default Description;
