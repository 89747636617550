import get from 'lodash/get';
import api from 'services/api';
import { SEND_VULN_TO_TOOL } from './settingsActions';
import { selectShowDetail, selectVulnDetailId } from 'store/Manage/selectors';
import { loadVulnerabilityDetail } from 'store/Manage/actions';
import { UPDATE_VULN_CLIENT_SIDE } from 'store/Manage/types';
import { loadManageDetail } from 'store/Contextualization/AssetDetail/actions';
import { loadVulnDetail } from 'store/ManageGeneral/actions';
import { VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE } from 'store/Contextualization/AssetDetail/types';
import { GENERAL_MANAGE_UPDATE_VULN_CLIENT_SIDE } from 'store/ManageGeneral/types';

export const TOOL_NAME = 'whd';

// obtiene datos de ticketing tools
export const GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START = 'GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START';
export const GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL = 'GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL';
export const GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS = 'GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS';

// guarda los datos
export const SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START = 'SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START';
export const SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS = 'SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS';
export const SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL = 'SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL';

export const SET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FIELD = 'SET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FIELD'; // acutaliza el estado de un campo

export function getSolarWindsSettings () {
  return async (dispatch) => {
    dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START });
    try {
      const response = await api.settings.fetchTicketingTools(TOOL_NAME);
      return dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function saveSolarWindsSettings () {
  return async (dispatch, getState) => {
    dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START });
    try {
      const {
        url, apiKey, template
      } = getState().settings.ticketingTools.solarWinds;
      const response = await api.settings.saveTicketingToolsSolarWinds(TOOL_NAME, url, apiKey, template);
      return dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function setField (field, value) {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FIELD, field, value });
  };
}

export function sendVulnToSolarWinds (clientId, categoryId, locationId) {
  return async (dispatch, getState) => {
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: true, error: false, message: '' } });
    try {
      const isVulnDetailOpen = selectShowDetail(getState());
      const vulnId = selectVulnDetailId(getState());
      const { vulnsSelected } = getState().manage;
      const data = { vulns: vulnsSelected };
      if (clientId) data.client_id = Number(clientId);
      if (categoryId) data.category = Number(categoryId);
      if (locationId) data.location = Number(locationId);

      const updatedVulns = await api.settings.sendVulnToSolarWinds(data);
      updatedVulns.forEach(vuln => {
        dispatch({ type: UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
      });

      if (isVulnDetailOpen) dispatch(loadVulnerabilityDetail(vulnId, true));

      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: false, message: 'Operation was executed successfully.' } });
    } catch (e) {
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: true, message: get(e, 'message', 'There was an error, please try again.') } });
    }
  };
}

export function sendVulnToSolarWindsContext (clientId, categoryId, locationId, vulnsSelected, vulnId, isVulnDetailOpen, entity) {
  return async (dispatch) => {
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: true, error: false, message: '' } });
    try {
      const data = { vulns: vulnsSelected };
      if (clientId) data.client_id = Number(clientId);
      if (categoryId) data.category = Number(categoryId);
      if (locationId) data.location = Number(locationId);

      const updatedVulns = await api.settings.sendVulnToSolarWinds(data);
      updatedVulns.forEach(vuln => {
        if (entity === 'vulns') dispatch({ type: UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
        else if (entity === 'vulnsAssets') dispatch({ type: VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
        else dispatch({ type: GENERAL_MANAGE_UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: TOOL_NAME } });
      });

      if (isVulnDetailOpen) {
        if (entity === 'vulns') dispatch(loadVulnerabilityDetail(vulnId, true));
        else if (entity === 'vulnsAssets') dispatch(loadManageDetail(vulnId));
        else dispatch(loadVulnDetail(vulnId));
      }

      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: false, message: 'Operation was executed successfully.' } });
    } catch (e) {
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: true, message: get(e, 'message', 'There was an error, please try again.') } });
    }
  };
}
