/* eslint-disable import/prefer-default-export */
import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch } from 'react-redux';
import { showContextMenu, showVulnModalDelete } from 'store/Manage/actions';
import { useIntl } from 'react-intl';
import {
  FEATURE_VULNS, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission';
import { DeleteIcon } from './styled';

export const Delete = () => {
  const dispatch = useDispatch();
  const title = useIntl().formatMessage({ id: 'manage.cm.delete' });

  const handleClick = () => {
    dispatch(showVulnModalDelete());
    dispatch(showContextMenu(false));
  };

  return (
    <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.DELETE }>
      <MenuItem
        title={ title }
        color="#ea3158"
        icon={ <DeleteIcon /> }
        onClick={ handleClick }
      />
    </WithPermission>
  );
};
