/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import head from 'lodash/head';
import debounce from 'lodash/debounce';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomTextArea from 'Common/Components/CustomTextArea';
import InputFilter from 'Common/Components/Contextualization/InputFilter';
import { createUpdateWorkspace, resetCreationState, setField, getUsers } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { FiTrash2 as Trash } from 'react-icons/fi';
import {
  selectWsEditCreateName, selectWsEditCreateDescription,
  selectWsEditCreateStart, selectWsEditCreateEnd, selectWsEditCreateLocale, selectWsEditCreateErrorMessage,
  selectWsEditCreateId,
  selectWorkspaceSaved,
  selectIsFetching,
  selectScope,
  selectWsEditCreateMakePublic,
  selectWsEditCreateCurrentUsers,
  selectWsEditCreateImportance,
  selectWsEditCustomer
} from 'store/WorkspaceEditCreate/selectors';
import BlueButton from 'Common/Components/BlueButton';
import ModalWrapper from 'Common/Components/ModalWrapper';
import CustomList from 'Common/Components/CustomList';
import {
  Modal, Title, CancelButton, Header, Body, FieldTitle, Field, FieldDescription,
  SecondTitle, SubTitle, UserSelectorWraper, UserSelect,
  TextAreaContainer, FieldTitleDescription, ErrorMessage, UserRow, NameRow, RoleRow, IconRow, TopRow, FieldScope, TextField, TextSpan, Flex
} from './styled';
import { selectWorkspaceUsers } from 'store/Session/selectors';
import Importance from 'Common/Components/Importance';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { selectLocation } from 'store/Router/selectors';
import { selectUsers } from 'store/Workspace/selectors';
import DateRangePicker from 'Common/Components/Contextualization/DateRangePicket';
import Checkbox from 'Common/Components/Checkbox';

const ModalCreation = ({ handleClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const name = useSelector(selectWsEditCreateName);
  const users = useSelector(selectWorkspaceUsers);
  const activeUsers = users.filter(({ active }) => active);
  const currentUsers = useSelector(selectWsEditCreateCurrentUsers);
  const addedUsers = activeUsers.filter(({ username }) => currentUsers.includes(username));
  const mappedAddedUsers = currentUsers.map((username) => addedUsers.find((user) => user.username === username));
  const filteredMappedAddedUsers = mappedAddedUsers.filter((user) => user !== undefined);
  const notAddedUsers = activeUsers.filter(({ username }) => !currentUsers.includes(username));
  const customer = useSelector(selectWsEditCustomer);
  // const publicWS = useSelector(selectWsEditCreatePublic);
  const description = useSelector(selectWsEditCreateDescription);
  const start = useSelector(selectWsEditCreateStart);
  const end = useSelector(selectWsEditCreateEnd);
  const locale = useSelector(selectWsEditCreateLocale);
  const errorMessage = useSelector(selectWsEditCreateErrorMessage);
  const id = useSelector(selectWsEditCreateId);
  const makePublic = useSelector(selectWsEditCreateMakePublic);
  const workspaceSaved = useSelector(selectWorkspaceSaved);
  const isFetching = useSelector(selectIsFetching);

  const scope = useSelector(selectScope);
  const importance = useSelector(selectWsEditCreateImportance);

  const location = useSelector(selectLocation);
  const usersAvailable = useSelector(selectUsers);

  const [isValidWsName, setIsValidWsName] = useState(true);

  const nameField = intl.formatMessage({ id: 'workspaces.modal.newWorkspace.name' });

  useEffect(() => () => dispatch(resetCreationState()), [dispatch]);

  useEffect(() => {
    if (workspaceSaved) handleClose();
  }, [handleClose, workspaceSaved]);

  useEffect(() => {
    if ((location.pathname !== '/workspaces')) dispatch(getUsers());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateWsName = (value) => {
    if (value && (value.match(/[^A-Za-z0-9@._-]/))) setIsValidWsName(false);
    else setIsValidWsName(true);
  };

  const handleChange = (field, value) => {
    dispatch(setField(field, value));

    if (field === 'name') validateWsName(value);
  };

  const onChangeDebounce = debounce(handleChange, 250);

  const onCloseCalendar = (startDate, endDate) => {
    dispatch(setField('start', new Date(startDate)));
    dispatch(setField('end', new Date(endDate)));
  };

  const cleanDates = () => {
    dispatch(setField('start', ''));
    dispatch(setField('end', ''));
  };

  const onAddScope = (value) => dispatch(setField('scope', [...scope, value]));

  const onRemoveScope = (indexToRemove) => {
    const newScope = scope.filter((item, index) => index !== indexToRemove);
    dispatch(setField('scope', newScope));
  };

  useEffect(() => {
    if (id) {
      const newUsers = users.filter(({ username, roles, active }) => currentUsers.includes(username) || (head(roles) === 'admin' && active));
      dispatch(setField('users', newUsers.map((newUser) => newUser.username)));
    } else {
      const newUsers = users.filter(({ roles, active }) => head(roles) === 'admin' && active);
      dispatch(setField('users', newUsers.map((newUser) => newUser.username)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, usersAvailable]);

  const addUser = (username) => dispatch(setField('users', [...currentUsers, username]));

  const removeUser = (username) => dispatch(setField('users', currentUsers.filter((usr) => usr !== username)));
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.CREATE));

  return (
    <ModalWrapper>
      <Modal>
        <Header>
          <Title>{id ? <FormattedMessage id="workspaces.modal.edit.title" /> : <FormattedMessage id="workspaces.modal.create.title" />}</Title>
          <CancelButton onClick={ handleClose } id="modal-ws-cancel-btn">
            <FormattedMessage id="cancel" />
          </CancelButton>
          <BlueButton id="modal-ws-accept-btn" onClick={ () => dispatch(createUpdateWorkspace()) } disabled={ !name || !isValidWsName } isLoading={ isFetching } label={ id ? intl.formatMessage({ id: 'save' }) : intl.formatMessage({ id: 'create' }) } />
        </Header>
        <Body id="modalBody">
          <TopRow>
            <TextField
              title={ nameField }
              name="name"
              placeholder="New Workspace"
              value={ name }
              onChange={ (value) => handleChange('name', value) }
              error={ !isValidWsName }
              errorMessage="No spaces and no special chars (except _ and -) are allowed"
            />
            <Field index={ 1 } id="dateSection">
              <FieldTitle children={ <FormattedMessage id="workspaces.modal.newWorkspace.StartAndEndDate" /> } />
              <DateRangePicker id="modal-ws-date" width="100%" height="34px" start={ start } end={ end } onClose={ onCloseCalendar } locale={ locale } cleanDates={ cleanDates } />
            </Field>
          </TopRow>

          <FieldScope>
          <TextField
            fullwidth
            title="Customer"
            name="customer"
            placeholder="Customer Name"
            value={ customer }
            onChange={ (value) => handleChange('customer', value) }
          />
          </FieldScope>

          <FieldDescription id="descriptionSection">
            <FieldTitleDescription><FormattedMessage id="workspaces.modal.newWorkspace.description" /></FieldTitleDescription>
            <TextAreaContainer><CustomTextArea id="modal-ws-description" width="420px" height="55px" title="" placeholder={ intl.formatMessage({ id: 'workspaces.modal.newWorkspace.usedFor' }) } updateValue={ (value) => onChangeDebounce('description', value) } field="" defaultValue={ description } /></TextAreaContainer>
          </FieldDescription>
          <Flex justify="space-between" align="center">

          <Flex align="center">
          <Importance small importance={ importance } callback={ (value) => handleChange('importance', value) } />
          <TextSpan>Importance</TextSpan>
          </Flex>
          <div>
          <Checkbox state={ makePublic } onChange={ (value) => handleChange('public', !value.currentTarget.checked) } />
          <TextSpan>Make Workspace Public</TextSpan>
          </div>
          </Flex>

          <FieldScope>
            <FieldTitleDescription children={ <FormattedMessage id="workspaces.modal.newWorkspace.scope" /> } />
            <CustomList
              key="MODAL_WS_CREATION"
              placeholder="Scope target"
              addItem={ onAddScope }
              removeItem={ onRemoveScope }
              listData={ scope }
              canUpdate={ canUpdate }
              responsive
            />
          </FieldScope>
          <SecondTitle children={ <FormattedMessage id="workspaces.modal.newWorkspace.secondTitle" /> } />
          <SubTitle children={ <FormattedMessage id="workspaces.modal.newWorkspace.subtitle" /> } />
          <UserSelectorWraper id="userSelectorSection">
            <InputFilter
              getObjects={ () => { } }
              data={ notAddedUsers }
              placeholder={ intl.formatMessage({ id: 'username' }) }
              onSelect={ ({ username }) => addUser(username) }
              onChange={ () => { } }
              cleanOnSelect
              required={ false }
              id="modal-ws-assign-users"
            />
          </UserSelectorWraper>
          <UserSelect id="userListSection">
            {filteredMappedAddedUsers.map(({ roles, username }) => (
              <UserRow key={ `user-${username}` }>
                <NameRow id="username" children={ username } />
                <RoleRow role={ head(roles) } id="userRole" children={ intl.formatMessage({ id: head(roles) }) } />
                { head(roles) !== 'admin' && <IconRow onClick={ () => removeUser(username) } id="userDeleteIcon" children={ <Trash color="#1c4566" /> } /> }
              </UserRow>
            ))}
          </UserSelect>
          <ErrorMessage children={ errorMessage } />
        </Body>
      </Modal>
    </ModalWrapper>
  );
};

ModalCreation.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ModalCreation;
