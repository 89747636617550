import { COMMANDS_FILTER } from 'store/Filters/constants';
import { newGetVulns } from 'store/Manage/filterActions';

const { clearFilters, addFilter, setFilter } = require('store/Filters/actions');

const LINK_TYPES = {
  COMMAND: 'command',
  VULNS: 'vulns',
  ADVANCED: 'advanced',
  ADV_RISK_SCORE_THRESHOLD: 'adv_risk_score_threshold',
  ADV_CRIT_VULN: 'adv_vuln_open_days_critical',
  ADV_HIGH_VULN: 'adv_vuln_open_days_high',
  ADV_MEDIUM_VULN: 'adv_vuln_open_days_medium',
  ADV_LOW_VULN: 'adv_vuln_open_days_low'
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

const addfilterDate = (data, dispatch) => {
  const days = data.extra_data.days;
  const date = new Date();
  date.setDate(date.getDate() - days);
  const formatedDate = formatDate(date);
  dispatch(addFilter(LINK_TYPES.VULNS, { name: 'create_date', op: '<=', val: formatedDate }));
};

const createLinkFunc = (data, dispatch) => {
  if (!data || typeof data !== 'object') {
    return;
  }
  const ws = data?.extra_data?.ws_name;
  if (data.links_to?.type === LINK_TYPES.COMMAND) {
    const commandId = data.links_to.id.toString();
    if (data.type === LINK_TYPES.ADVANCED) {
      dispatch(clearFilters(LINK_TYPES.VULNS));
      addfilterDate(data, dispatch);
      switch (data.subtype) {
        case data.subtype === LINK_TYPES.ADV_RISK_SCORE_THRESHOLD:
          dispatch(addFilter(LINK_TYPES.VULNS, COMMANDS_FILTER(commandId)));
          dispatch(addFilter(LINK_TYPES.VULNS, { name: 'risk', op: '>=', val: data.links_to?.extra_filters?.risk }));
          break;
        case data.subtype === LINK_TYPES.ADV_CRIT_VULN:

          dispatch(addFilter(LINK_TYPES.VULNS, COMMANDS_FILTER(commandId)));
          dispatch(addFilter(LINK_TYPES.VULNS, { name: 'severity', op: '==', val: 'critical' }));// {or:[{name:"severity",op:"=",val:"high"},{name:"severity",op:"=",val:"critical"}]}
          break;
        case data.subtype === LINK_TYPES.ADV_HIGH_VULN:
          dispatch(addFilter(LINK_TYPES.VULNS, COMMANDS_FILTER(commandId)));
          dispatch(addFilter(LINK_TYPES.VULNS, { name: 'severity', op: '==', val: 'high' }));// {or:[{name:"severity",op:"=",val:"high"},{name:"severity",op:"=",val:"critical"}]}
          break;
        case data.subtype === LINK_TYPES.ADV_MEDIUM_VULN:
          dispatch(addFilter(LINK_TYPES.VULNS, COMMANDS_FILTER(commandId)));
          dispatch(addFilter(LINK_TYPES.VULNS, { name: 'severity', op: '==', val: 'medium' }));// {or:[{name:"severity",op:"=",val:"high"},{name:"severity",op:"=",val:"critical"}]}
          break;
        case data.subtype === LINK_TYPES.ADV_LOW_VULN:
          dispatch(addFilter(LINK_TYPES.VULNS, COMMANDS_FILTER(commandId)));
          dispatch(addFilter(LINK_TYPES.VULNS, { name: 'severity', op: '==', val: 'low' }));// {or:[{name:"severity",op:"=",val:"high"},{name:"severity",op:"=",val:"critical"}]}
          break;
        default:
          dispatch(setFilter(LINK_TYPES.VULNS, COMMANDS_FILTER(commandId)));
      }
    } else {
      dispatch(setFilter(LINK_TYPES.VULNS, COMMANDS_FILTER(commandId)));
    }

    dispatch(newGetVulns(ws || ''));
  } else {
    if (data.type === LINK_TYPES.ADVANCED) {
      dispatch(clearFilters(LINK_TYPES.VULNS));

      addfilterDate(data, dispatch);
      if (data.subtype === LINK_TYPES.ADV_RISK_SCORE_THRESHOLD) {
        dispatch(addFilter(LINK_TYPES.VULNS, { name: 'risk', op: '>=', val: data.links_to?.extra_filters?.risk || data.extra_data?.risk_score }));
      } else if (data.subtype === LINK_TYPES.ADV_CRIT_VULN) {
        dispatch(addFilter(LINK_TYPES.VULNS, { name: 'severity', op: '==', val: 'critical' }));
      } else if (data.subtype === LINK_TYPES.ADV_HIGH_VULN) {
        dispatch(addFilter(LINK_TYPES.VULNS, { name: 'severity', op: '==', val: 'high' }));
      } else if (data.subtype === LINK_TYPES.ADV_MEDIUM_VULN) {
        dispatch(addFilter(LINK_TYPES.VULNS, { name: 'severity', op: '==', val: 'medium' }));
      } else if (data.subtype === LINK_TYPES.ADV_LOW_VULN) {
        dispatch(addFilter(LINK_TYPES.VULNS, { name: 'severity', op: '==', val: 'low' }));
      }
    }
    dispatch(newGetVulns(ws || ''));
  }
};

export default createLinkFunc;
