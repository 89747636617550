import React from 'react';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_PROJECTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'store/modals/actions';
import { MODAL_PROJECTS_CREATE_EDIT } from 'store/modals/modals';
import { selectIsAdmin } from 'store/Faraday/selectors';
import Wrapper from './styled';
import AddProject from './components/AddProject';
import HideTask from './components/HideTask';
import ViewSelector from './components/ViewSelector';

const ActionBar = () => {
  const dispatch = useDispatch();
  const showCreationModal = () => dispatch(openModal(MODAL_PROJECTS_CREATE_EDIT));
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_PROJECTS, PERMISSION_TYPES.CREATE));
  const isAdmin = useSelector(selectIsAdmin);

  return (
    <Wrapper>
      <AddProject show={ canCreate } onClick={ showCreationModal } />
      <HideTask />
      { isAdmin && <ViewSelector />}
    </Wrapper>
  );
};

export default ActionBar;
