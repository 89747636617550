import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import upgradeLicenseImage from 'Images/users-license.png';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import { PERMISSION_TYPES, FEATURE_USERS } from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { ACTIONS } from 'tracking/GA/constants';
import NotFound from 'Screens/Errores/NotFound';
import { resetFilters } from 'store/Filters/actions';
import { getData } from './actions/Actions';
import { TableWrapper } from './components/Table/styled';
import Table from './components/Table';
import ActionBar from './components/ActionBar';

const Users = () => {
  const isAllowedByFeature = useSelector((state) => selectAllowedByFeature(state, FEATURE_USERS, PERMISSION_TYPES.READ));
  const isAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.CREATE));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  useEffect(() => () => dispatch(resetFilters('users')), [dispatch]);

  const isAllowed = isAllowedByFeature && isAllowedByRole;

  if (!isAllowed) return <NotFound />;
  return (
    <div className="h-100">
      <ActionBar />
      <TableWrapper>
        <Table />
        <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.user.description" trackingShow={ ACTIONS.showUser } trackingClick={ ACTIONS.clickUser } />
        </ReduxModal>
      </TableWrapper>
    </div>
  );
};

export default Users;
