// export default CodeField;
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CodeInput } from './styled';

const CodeField = forwardRef(({ onChange, onEnter, value: propValue, onBackspace, ...props }, ref) => {
  const [value, setValue] = useState(propValue || '');

  useEffect(() => {
    setValue(propValue || '');
  }, [propValue]);

  const onChangeHandler = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (onEnter) {
        onEnter();
      }
      e.preventDefault();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && !value) {
      if (onBackspace) {
        onBackspace();
      }
    }
  };

  return (
    <CodeInput
      { ...props }
      ref={ ref }
      value={ value }
      onChange={ onChangeHandler }
      onKeyPress={ handleKeyPress }
      onKeyDown={ handleKeyDown }
    />
  );
});

CodeField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  onBackspace: PropTypes.func,
  value: PropTypes.string
};

export default CodeField;
