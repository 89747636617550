import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshGraph } from 'store/Analytics/actions';
import {
  selectRefreshedAt
} from 'store/Analytics/selectors';
import { LinkAsButton } from 'Common/Components/LinkAsButton/styled';
import moment from 'moment';
import { Wrapper, Label } from './styled';

const RefreshData = () => {
  const dispatch = useDispatch();

  const generatedAt = useSelector(selectRefreshedAt);

  const onRefreshGraph = () => {
    dispatch(refreshGraph());
  };

  return (
    <Wrapper>
      <Label>
        {`This report was generated on ${moment(generatedAt).format('d/M/YY')} at ${moment(generatedAt).format('h:mm:ss')} - `}
      </Label>
      <LinkAsButton onClick={ onRefreshGraph }>
        Refresh Graph
      </LinkAsButton>
    </Wrapper>
  );
};

export default RefreshData;
