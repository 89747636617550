import React from 'react';
import { Title, Table, Value, Row } from './styled';

const DetailsSection = ({ cvss4, details, macroVector }) => {
  const { vector_string } = cvss4;
  const { complexity, exploitability, exploitation, security_requirements, subsequent_system, vulnerable_system } = details;

  return (
    <Table>
      <Row>
        <Title>Macro Vector</Title>
        <Value>{ vector_string ? macroVector : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Exploitability</Title>
        <Value>{ vector_string ? exploitability : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Complexity</Title>
        <Value>{ vector_string ? complexity : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Vulnerable System</Title>
        <Value>{ vector_string ? vulnerable_system : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Subsequent System</Title>
        <Value>{ vector_string ? subsequent_system : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Exploitation</Title>
        <Value>{ vector_string ? exploitation : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Security Requirements</Title>
        <Value>{ vector_string ? security_requirements : 'N/A' }</Value>
      </Row>
    </Table>
  );
};

export default DetailsSection;
