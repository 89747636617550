import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Text, PlusIcon } from './styled';

const AddButton = ({
  title, onClick, text, className, disabled, icon
}) => (
  <Button disabled={ disabled } title={ title } onClick={ onClick } className={ className }>
    { icon || <PlusIcon />}
    <Text disabled={ disabled }>{ text }</Text>
  </Button>
);
export default AddButton;

AddButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.instanceOf(Object)
};

AddButton.defaultProps = {
  title: '',
  onClick: () => {},
  text: '',
  className: '',
  disabled: false,
  icon: null
};
