/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { addComment, updateComment } from 'store/Manage/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnDetail } from 'store/Manage/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_COMMENTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import CommentTextArea from 'Common/Components/CommentTextArea';
import Comments from './Comments';
import { Wrapper, CommentTextAreaWrapper, Title } from './styled';
import { selectIsReadOnly } from 'store/Faraday/selectors';

const CommentTab = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: 'manage.detail.tab.comment.placeholder' });
  const [commentToEdit, setCommentToEdit] = useState(null);
  const vuln = useSelector(selectVulnDetail);
  const titleLabel = intl.formatMessage({ id: 'manage.detail.tab.comment' });
  const readOnly = useSelector(selectIsReadOnly);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_COMMENTS, PERMISSION_TYPES.CREATE));
  const disabled = readOnly || !canUpdate;

  return (
    <Wrapper>
      <CommentTextAreaWrapper>
        <Title children={ titleLabel } />
        <CommentTextArea
          addComment={ (data) => dispatch(addComment(data)) }
          commentToEdit={ commentToEdit }
          disabled={ disabled }
          editComment={ (data) => dispatch(updateComment(data)) }
          entity="vulnerability"
          entityId={ vuln._id }
          placeholder={ placeholder }
          setCommentToEdit={ setCommentToEdit }
        />
      </CommentTextAreaWrapper>
      <Comments />
    </Wrapper>
  );
};

export default CommentTab;
