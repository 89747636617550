import React from 'react';
import { useSelector } from 'react-redux';
import { selectAllNotification, selectUnreadNotificationsCount } from 'store/Notifications/selectors';
import { NotificationList, Wrapper } from './styled';
import NotificationItem from './notificationItem';
import Footer from './notificationsFooter';
import Header from './notificationsHeader';

const NotificationsDropdown = () => {
  const notifications = useSelector(selectAllNotification);
  const unreadNotificationsCount = useSelector(selectUnreadNotificationsCount);
  const sortByDate = (a, b) => Date.parse(a.event_date) - Date.parse(b.event_date);
  const sortedNotifications = notifications.sort(sortByDate);
  const lastFourNotifications = sortedNotifications.slice(-4);
  return (
    <Wrapper>
      <Header lastFourNotifications={ lastFourNotifications } />
      <NotificationList>
        {lastFourNotifications.sort((a, b) => new Date(b.event_date) - new Date(a.event_date)).map((notification) => (
          <NotificationItem
            key={ notification.id }
            id={ notification.id }
            data={ notification }
            event={ notification.type }
            creationTime={ notification.event_date }
          />
        ))}
      </NotificationList>
      <Footer notificationCount={ unreadNotificationsCount } />
    </Wrapper>
  );
};

export default NotificationsDropdown;
