import React from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Wrapper from './styled';
import NonBooleanField from './components/NonBooleanField';
import BooleanField from './components/BooleanField';

const Content = ({ defaultParams, currentTool, onChangeField }) => {
  const parameters = get(currentTool, 'parameters_metadata', []);
  const booleanParams = Object.keys(parameters).filter((param) => parameters[param].base === 'boolean');
  const nonBooleanParams = Object.keys(parameters).filter((param) => parameters[param].base !== 'boolean');

  if (isEmpty(currentTool) || isEmpty(currentTool.parameters_metadata)) return null;
  return (
    <Wrapper>
      { nonBooleanParams.map((param) => <NonBooleanField key={ param } defaultValue={ get(defaultParams, param, '') } param={ param } parameters={ parameters } onChangeField={ onChangeField } />) }
      { booleanParams.map((param) => <BooleanField key={ param } defaultValue={ get(defaultParams, param, false) } param={ param } parameters={ parameters } onChangeField={ onChangeField } />)}
    </Wrapper>
  );
};

export default Content;
