/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectActiveWorkspaces, selectLoadingWsForDropdown } from 'store/Workspace/selectors';
import SearchList from 'Common/Components/SearchList';
import WorkspaceList from 'Common/Components/WorkspaceList';
import { selectTriggerAgentWs } from 'store/Agents/selectors';
import { addTriggerAgentWs, removeTriggerAgentWs, setTriggerAgentWs } from 'store/Agents/actions';
import { useIntl } from 'react-intl';
import {
  Wrapper, Title, WrapperField, Label
} from './styled';
import { getWorkspaces } from 'Screens/Workspaces/actions/Actions';

const WorkspaceSelector = ({ onRemove, onSelect }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const loadingWorkspaces = useSelector(selectLoadingWsForDropdown);
  const addedWorkspaces = useSelector(selectTriggerAgentWs);
  const allWorkspaces = useSelector(selectActiveWorkspaces);
  const notAddedWorkspaces = allWorkspaces.filter((ws) => addedWorkspaces.every((added) => ws.id !== added.id));
  const workspaceList = notAddedWorkspaces.map((ws) => ({ primary: ws.name, id: ws._id }));
  const enrichedAddedWorkspaces = addedWorkspaces.map(addedWs => {
    const fullWorkspace = allWorkspaces.find(allWs => allWs.id === addedWs.id);
    return {
      ...addedWs,
      stats: fullWorkspace?.stats
    };
  });

  const title = intl.formatMessage({ id: 'agents.triggerAgent.wsSelector.title' });
  const subtitle = intl.formatMessage({ id: 'agents.triggerAgent.wsSelector.subtitle' });

  const onRemoveWs = (ws) => {
    onSelect && onRemove(ws);
    dispatch(removeTriggerAgentWs(ws));
  };

  const onSelectWs = (ws) => {
    onRemove && onSelect(ws);
    dispatch(addTriggerAgentWs(ws));
  };

  useEffect(() => {
    dispatch(setTriggerAgentWs([]));
    dispatch(getWorkspaces(false, false));
  }, [dispatch]);

  return (
    <Wrapper>
      <Title children={ title } />
      <Label children={ subtitle } />
      <WrapperField>
        <SearchList onSelect={ onSelectWs } items={ workspaceList } loadingData={ loadingWorkspaces } />
        <WorkspaceList workspaces={ enrichedAddedWorkspaces } onRemove={ onRemoveWs } />
      </WrapperField>
    </Wrapper>
  );
};

export default WorkspaceSelector;
