import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { Trash } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';

const DeleteHostButton = ({ hasHosts, showHostModalDelete, hostsSelected, showHostDetail }) => {
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.DELETE));
  const [showWarning, setShowWarning] = useState(false);
  const canUpdate = !readOnly && !showHostDetail && allowedByRole && hasHosts;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'assets' });

  const handleClick = () => {
    if (canUpdate) {
      if (hostsSelected.length > 0) showHostModalDelete();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } disabled={ readOnly || showHostDetail || !allowedByRole || !hasHosts } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Delete" description={ message } /> }
    </>
  );
};

export default DeleteHostButton;
