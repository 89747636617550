import React from 'react';
import { PropTypes } from 'prop-types';
import { StyledMenuItem, ArrowIcon } from './styled';

const MenuItem = ({
  isSelected, onClick, title, icon, hasSubMenu, color, show, disabled
}) => {
  if (!show) return null;
  return (
    <StyledMenuItem isSelected={ isSelected } onClick={ onClick } color={ color } disabled={ disabled }>
      {icon}
      {title}
      {hasSubMenu && <ArrowIcon />}
    </StyledMenuItem>
  );
};
MenuItem.propTypes = {
  show: PropTypes.bool,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  title: PropTypes.string,
  color: PropTypes.string,
  hasSubMenu: PropTypes.bool,
  isSelected: PropTypes.bool
};

MenuItem.defaultProps = {
  isSelected: false,
  onClick: () => {},
  title: '',
  color: '',
  hasSubMenu: false,
  show: true,
  icon: null
};

export default MenuItem;
