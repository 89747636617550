import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserName } from 'store/Faraday/selectors';

const TriggerByColumn = ({ original }) => {
  const userName = useSelector(selectUserName);
  if (typeof original !== 'object' || original === null || !original.triggered_by) {
    return null;
  }

  if (original.triggered_by.type && original.triggered_by.type.toLowerCase() === 'system') return <div style={ { color: '#0082ed' } }>System</div>;

  let name = original.triggered_by.username;
  if (userName === name) name = 'You';
  return <div style={ { color: '#0082ed' } }>{name}</div>;
};

export default TriggerByColumn;
