import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectIsEditing } from 'store/Tasks/selectors';
import Title from './styled';

const SidebarTitle = () => {
  const intl = useIntl();
  const isEditing = useSelector(selectIsEditing);

  return (
    <Title>{ intl.formatMessage({ id: isEditing ? 'planner.task.edit.title' : 'planner.task.create.title' }) }</Title>
  );
};

export default SidebarTitle;
