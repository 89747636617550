import styled from 'styled-components';
import { ReactComponent as _Arrow } from 'Images/arrow_down_drop.svg';
import colors from 'Styles/colors';

export const VulnCount = styled.div`
  user-select: none;
  background-color: #edf2f7;
  border-radius: 2em;
  color: #1c2243;
  font-size: 9.5px;
  font-weight: 900;
  display: inline-block;
  line-height: 1;
  padding: 3px 8px;
  text-align: center;
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.13);
  margin-left: auto;
  margin-right: 19px;
`;
VulnCount.displayName = 'VulnCount';

export const Wrapper = styled.div`
  width: 100%;
  position:relative;
`;
Wrapper.displayName = 'Wrapper';

export const VulnsListWrapper = styled.div`
  user-select: none;
  background-color: #fafbfc;
  >:nth-child(n){
    margin: 0 15px;
  }
  >:not(:last-child) {
    border-bottom: solid 1px #edf2f7;
  }
  border-bottom: solid 1px #d6d6d6;
`;
VulnsListWrapper.displayName = 'VulnsListWrapper';

export const Header = styled.div`
  cursor: ${({ canClick }) => (canClick ? 'pointer' : 'default')};
  ${({ canClick }) => !canClick && 'pointer-events: none'};
  background-color: #ffffff;
  height: 49px;
  padding: 0 53px 0 69px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #d6d6d6 solid;
`;
Header.displayName = 'Header';

export const Name = styled.div`
  display: flex;
  font-size: 14.5px;
  font-weight: 500;
  color: #53686f;
  align-items: center;
  &:after {
    ${({ isMilestone }) => isMilestone && `
      margin-left: 10px;
      background-color: ${colors.blueGrey};
      content: 'milestone';
      font-size: 12.5px;
      font-weight: 700;
      color: ${colors.white};
      height: 25px;
      width: 79px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
  `}
  }
`;
Name.displayName = 'Name';

export const Arrow = styled(_Arrow)`
  visibility: ${({ showArrow }) => (showArrow ? 'visible' : 'hidden')};
  ${({ isOpen }) => !isOpen && 'transform: rotate(-90deg)'};
`;
Arrow.displayName = 'Arrow';

export const DropZone = styled.div`
  border-radius: 2px;
  border: dashed 2px #0082ed;
  background-color: ${({ canDrop }) => (canDrop ? 'rgba(179, 220, 253, 0.85)' : 'rgba(206, 221, 233, 0.85)')};
  position:absolute;
  width: 100%;
  height: 100%;
  color:${({ canDrop }) => (canDrop ? '#0082ed' : '#2D2F31')};
  font-size: 15px;
  font-weight: 600;
  display:flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;
DropZone.displayName = 'DropZone';
