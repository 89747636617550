import { fetchAllNotifications } from 'services/api/notifications';
import sendToWS from '../websocket/utils';
import {
  selectLastSelectedNotification, selectSelectedNotifications,
  selectAllNotification, selectQueryParam, selectSelectAll, selectPage, selectNotificationsCount
} from './selectors';
import api from 'services/api';

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const SELECT_NOTIFICATION = 'SELECT_NOTIFICATION';
export const NEW_PIVOT = 'NEW_PIVOT';
export const SELECT_ALL_NOTIFICATIONS = 'SELECT_ALL_NOTIFICATIONS';
export const UNSELECT_ALL_NOTIFICATIONS = 'UNSELECT_ALL_NOTIFICATIONS';
export const SET_LAST_SELECTED_NOTIFICATION = 'SET_LAST_SELECTED_NOTIFICATION';
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const MARK_NOTIFICATIONS_AS_READ = 'MARK_NOTIFICATIONS_AS_READ';
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
export const UPDATE_NOTIFICATION_TYPE = 'UPDATE_NOTIFICATION_TYPE';
export const SET_NOTIFICATION_SETTINGS = 'SET_NOTIFICATION_SETTINGS';
export const SET_NOTIFICATIONS_PAGE = 'SET_NOTIFICATIONS_PAGE';
export const DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS';
export const SELECT_TOTAL_NOTIFICATIONS = 'SELECT_TOTAL_NOTIFICATIONS';
export const UNSELECT_TOTAL_NOTIFICATIONS = 'UNSELECT_TOTAL_NOTIFICATIONS';
export const MARK_ALL_NOTIFICATIONS_AS_READ = 'MARK_ALL_NOTIFICATIONS_AS_READ';
export const UNSELECTED_ROW_FROM_SELECT_ALL = 'UNSELECTED_ROW_FROM_SELECT_ALL';
export const AUTOSELECT_NOTIFICATION = 'AUTOSELECT_NOTIFICATION';

// mark read notification
export const markNotification = (notification_id) => (dispatch) => {
  sendToWS(dispatch, 'mark_read_notification', { notification_id });
};
// mark read all notifications
export const markAllNotifications = () => (dispatch) => {
  sendToWS(dispatch, 'mark_read_all_notifications', {});
};

// Get Unread Notification
export const getUnreadNotifications = () => (dispatch) => {
  sendToWS(dispatch, 'get_unread_notifications');
};

export const getNotificationsRequest = () => ({
  type: GET_NOTIFICATIONS_REQUEST
});

export const getNotificationsSuccess = (notifications, unreadCount, count) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  notifications,
  unreadCount,
  count
});

export const getNotificationsFailure = (error) => ({
  type: GET_NOTIFICATIONS_FAILURE,
  error
});

export const getNotifications = () => async (dispatch, getState) => {
  dispatch(getNotificationsRequest());
  const queryParam = selectQueryParam(getState());
  try {
    const data = await fetchAllNotifications(queryParam);
    const notifications = data?.rows;
    dispatch(getNotificationsSuccess(notifications, data.unread_count, data.count));
  } catch (error) {
    dispatch(getNotificationsFailure(error));
  }
};

export const selectTotalNotifications = () => (dispatch) => {
  return dispatch({ type: SELECT_TOTAL_NOTIFICATIONS });
};

export const unselectTotalNotifications = () => (dispatch) => {
  return dispatch({ type: UNSELECT_TOTAL_NOTIFICATIONS });
};

export const unselectedRowFromSelectAll = (value) => (dispatch) => {
  dispatch({ type: UNSELECTED_ROW_FROM_SELECT_ALL, value });
};

export const selectNotificationRow = (e, notification) => (dispatch, getState) => {
  const allNotifications = selectAllNotification(getState());
  const selectedNotifications = selectSelectedNotifications(getState());
  const lastSelectedIndex = selectLastSelectedNotification(getState());
  const selectAll = selectSelectAll(getState());
  const notificationCount = selectNotificationsCount(getState());
  const areNotificationsSelected = selectedNotifications.length > 0;

  const index = allNotifications.findIndex((el) => el.id === notification.id);

  if (e.shiftKey && lastSelectedIndex !== -1 && areNotificationsSelected) {
    const start = Math.min(lastSelectedIndex, index);
    const end = Math.max(lastSelectedIndex, index) + 1;
    const notifications = allNotifications.slice(start, end);
    const newSelectedNotifications = [...selectedNotifications, ...notifications];
    dispatch(selectNotification(newSelectedNotifications));
  } else {
    const isSelected = selectedNotifications.some(
      (selected) => selected.id === notification.id
    );
    let newSelectedNotifications;
    if (isSelected) {
      newSelectedNotifications = selectedNotifications.filter(
        (selected) => selected.id !== notification.id
      );

      if (selectAll) {
        dispatch(unselectedRowFromSelectAll(true));
        dispatch(unselectTotalNotifications());
      }
    } else {
      newSelectedNotifications = [...selectedNotifications, notification];
    }
    dispatch(selectNotification(newSelectedNotifications));
    if (newSelectedNotifications.length === notificationCount) dispatch(selectTotalNotifications());
  }
  dispatch(setLastSelectedNotification(index));
};

export const updateNotifications = (data, unreadCount, payload) => ({
  type: UPDATE_NOTIFICATIONS,
  data,
  unreadCount,
  payload
});

export const newNotification = (data, unreadCount, payload) => ({
  type: NEW_NOTIFICATION,
  data,
  unreadCount,
  payload
});

export const selectNotification = (selectedNotifications) => ({
  type: SELECT_NOTIFICATION,
  selectedNotifications
});

export const deleteNotification = (notificationIds) => ({
  type: DELETE_NOTIFICATION,
  notificationIds
});

export const setLastSelectedNotification = (index) => ({
  type: SET_LAST_SELECTED_NOTIFICATION,
  index
});

export const selectAllNotifications = () => ({
  type: SELECT_ALL_NOTIFICATIONS
});

export const unselectAllNotifications = () => ({
  type: UNSELECT_ALL_NOTIFICATIONS
});

export const markNotificationsAsRead = (notificationIds, count) => ({
  type: MARK_NOTIFICATIONS_AS_READ,
  notificationIds,
  count
});

export const markAllNotificationsAsRead = (count) => ({
  type: MARK_ALL_NOTIFICATIONS_AS_READ,
  count
});

export const deleteNotificationsAsync = () => async (
  dispatch, getState
) => {
  const state = getState();
  const selectAll = selectSelectAll(state);
  const notificationsSelected = selectSelectedNotifications(state);
  const notificationIds = notificationsSelected.map((notification) => notification.id);
  const queryParam = selectQueryParam(state);
  const notisCountInCurrentPage = selectAllNotification(state).length;
  const page = selectPage(state);
  try {
    if (selectAll) {
      await api.notifications.deleteAllNotifications({ filters: queryParam.filters });
      dispatch(unselectTotalNotifications());
    } else {
      await api.notifications.deleteNotificationsApi(notificationIds);
      if ((notisCountInCurrentPage === notificationsSelected.length) && page !== 1) dispatch({ type: SET_NOTIFICATIONS_PAGE, page: 1 });
    }
    dispatch({ type: DELETE_NOTIFICATIONS });
    dispatch(getNotifications());
  } catch (error) {
  }
};

export const markNotificationsAsReadAsync = (notificationIds) => async (
  dispatch
) => {
  try {
    const data = await api.notifications.markNotificationsAsReadApi(notificationIds);
    dispatch(markNotificationsAsRead(notificationIds, data.unread_count));
  } catch (error) {
  }
};

export const updateNotificationType = (userId, type, channel, active) => ({
  type: UPDATE_NOTIFICATION_TYPE,
  payload: { userId, type, channel, active }
});

export const setNotificationSettings = (settings) => ({
  type: SET_NOTIFICATION_SETTINGS,
  payload: settings
});

export const getNotificationSettings = (userId) => async (dispatch) => {
  try {
    const settings = await api.notifications.fetchNotificationsSettings(userId);
    dispatch(setNotificationSettings(settings));
  } catch (error) {
    console.error('Error fetching notification settings:', error); // eslint-disable-line no-console
  }
};

export const setPage = (page) => async (dispatch) => {
  dispatch({ type: SET_NOTIFICATIONS_PAGE, page });
  dispatch(getNotifications());
};

export const markAllNotificationsAsReadAsync = () => async (
  dispatch
) => {
  try {
    const data = await api.notifications.markAllNotificationsAsRead();
    dispatch(markAllNotificationsAsRead(data.unread_count));
  } catch (error) {
  }
};

export const autoSelectNotification = (notification) => (dispatch) => {
  dispatch({ type: AUTOSELECT_NOTIFICATION, notification });
};
