import api from 'services/api';
import * as types from './types';
import get from 'lodash/get';
import { selectQueryParam } from 'store/Filters/selectors';
import { setOrderBy, setPageNumber } from 'store/Filters/actions';
import { selectLastAddedToken, selectTab, selectUserTokens, selectUserTokensCount } from './selectors';

export const somethingWentWrong = (error) => async (dispatch) => dispatch({ type: types.SETTINGS_USER_TOKENS_FAIL, errorMessage: error });
export const clearError = () => async (dispatch) => dispatch({ type: types.SETTINGS_USER_TOKENS_RESET_ERROR });

export const getUserTokens = () => async (dispatch, getState) => {
  dispatch({ type: types.SETTINGS_GET_USER_TOKENS_REQUEST });
  const selectedTab = selectTab(getState());
  const queryParam = selectQueryParam('userTokens', getState());
  const filters = selectedTab === 'active'
    ? [{ name: 'expired', op: '==', val: 'false' }, { name: 'revoked', op: '==', val: 'false' }, { name: 'hide', op: '==', val: 'false' }]
    : [{ and: [{ name: 'hide', op: '==', val: 'false' }, { or: [{ name: 'expired', op: '==', val: 'true' }, { name: 'revoked', op: '==', val: 'true' }] }] }];
  const queryWithFilters = { ...queryParam, filters };

  try {
    const data = await api.settings.fetchUserTokens(queryWithFilters);
    return dispatch({ type: types.SETTINGS_GET_USER_TOKENS_SUCCESS, data });
  } catch (e) {
    const errorMessage = get(e, 'message', 'There was a problem, please try again');
    return dispatch(somethingWentWrong(errorMessage));
  }
};

export const showAddTokenModal = (show, step) => (dispatch) => dispatch({ type: types.SETTINGS_USER_TOKENS_SHOW_ADD_TOKEN_MODAL, show, step });

export const createUserToken = (data) => async (dispatch) => {
  try {
    const response = await api.settings.createUserToken(data);
    dispatch({ type: types.SETTINGS_USER_TOKENS_CREATE_SUCCESS, token: response });
    dispatch(showAddTokenModal(true, 2));
  } catch (e) {
    dispatch(showAddTokenModal(false, 0));
    const errorMessage = get(e, 'messages.json.error', 'There was a problem, please try again');
    return dispatch(somethingWentWrong(errorMessage));
  }
};

export const hideAddTokenModal = () => (dispatch, getState) => {
  const selectedTab = selectTab(getState());
  const lastAddedToken = selectLastAddedToken(getState());
  const token = {
    alias: lastAddedToken.alias,
    expires_at: lastAddedToken.expires_at,
    hide: lastAddedToken.hide,
    id: lastAddedToken.id,
    revoked: lastAddedToken.revoked,
    scope: lastAddedToken.scope
  };

  if (selectedTab === 'active') dispatch({ type: types.ADD_USER_TOKEN_TO_TABLE, token });
  else dispatch({ type: types.RESET_LAST_ADDED_TOKEN });
  dispatch(showAddTokenModal(false, 0));
};

export const setPage = (pageNumber) => {
  return (dispatch) => {
    dispatch(setPageNumber('userTokens', pageNumber));
    dispatch(getUserTokens());
  };
};

export const setOrderByUserTokens = (sorting) => (dispatch) => {
  dispatch(setOrderBy('userTokens', sorting));
  dispatch(getUserTokens());
};

export const showActionModal = (show, action, id) => (dispatch) => dispatch({ type: types.SETTINGS_USER_TOKENS_SHOW_ACTION_MODAL, show, action, id });

export const updateUserToken = (id, field) => async (dispatch, getState) => {
  const tokens = selectUserTokens(getState());
  const tokensCount = selectUserTokensCount(getState());
  try {
    await api.settings.updateUserToken(id, field);
    const filteredTokens = tokens.filter((t) => t.id !== id);
    dispatch({ type: types.SETTINGS_GET_USER_TOKENS_SUCCESS, data: { user_tokens: filteredTokens, count: tokensCount - 1 } });
  } catch (e) {
    const errorMessage = get(e, 'message', 'There was a problem, please try again');
    return dispatch(somethingWentWrong(errorMessage));
  }
};

export const setSelectedTab = (selectedTab) => (dispatch) => {
  dispatch({ type: types.SETTINGS_USER_TOKENS_SET_SELECTED_TAB, selectedTab });
  dispatch(getUserTokens());
};

export const getScopes = () => async (dispatch) => {
  try {
    const data = await api.settings.fetchScopes();
    dispatch({ type: types.SETTINGS_USER_TOKENS_GET_SCOPES, scopes: data.scopes });
  } catch (e) {
    const errorMessage = get(e, 'message', 'There was a problem, please try again');
    return dispatch(somethingWentWrong(errorMessage));
  }
};
