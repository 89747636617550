import React from 'react';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { setInputField } from 'store/ExecutiveReportEditCreate/actions';
import { selectTemplateTemp, selectParsedTemplateName } from 'store/ExecutiveReportEditCreate/selectors';
import { FormattedMessage } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import { MODAL_REPORTS_SELECT_TEMPLATE } from 'store/modals/modals';
import { BlueButton } from '../../../../styled';
import { Cancel } from 'Common/Components/Button/styled';
import { Wrapper } from './styled';

const Header = ({ onClose }) => {
  const dispatch = useDispatch();
  const template = useSelector(selectParsedTemplateName);
  const savedTemplate = template.length > 0 && template[0];
  const templateTemp = useSelector(selectTemplateTemp);

  const onAccept = (templateTemp) => {
    if (savedTemplate !== templateTemp) {
      const isCustom = templateTemp.label.includes('(custom)');
      if (isCustom) {
        const templateValue = templateTemp.label;
        dispatch(setInputField('selectedTemplate', [templateTemp.isGrouped, templateValue]));
      } else {
        const templateValue = templateTemp.isPdf ? templateTemp.pdfOption : templateTemp.docxOption;
        dispatch(setInputField('selectedTemplate', [templateTemp.isGrouped, templateValue]));
      }

      dispatch(closeModal(MODAL_REPORTS_SELECT_TEMPLATE));
    }
  };

  const isDisabled = Object.keys(templateTemp).length === 0 || isEqual(savedTemplate, templateTemp);

  return (
    <Wrapper>
      <Cancel onClick={ onClose }>
        <FormattedMessage id="common.components.button.cancel" />
      </Cancel>
      <BlueButton disabled={ isDisabled } onClick={ () => onAccept(templateTemp) }>
        <FormattedMessage id="reports.editCreate.selectTemplateModal.accept" />
      </BlueButton>
    </Wrapper>
  );
};
export default Header;
