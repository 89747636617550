/* eslint-disable import/prefer-default-export */
import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnsSelected } from 'store/ManageGeneral/selectors';
import { setShowContextMenu, showVulnDetailGeneral, showBulkUpdateModal } from 'store/ManageGeneral/actions';
import { useIntl } from 'react-intl';
import {
  FEATURE_COMMENTS, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import get from 'lodash/get';
import head from 'lodash/head';
import { CommentsIcon } from 'Screens/Contextualization/Manage/components/ManageTable/components/ContextMenu/components/styled';

export const AddComment = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const selectedVulnID = get(head(vulnsSelected), '_id', null);
  const isBulk = vulnsSelected.length > 1;
  const canReadComments = useSelector((state) => selectAllowedByRole(state, FEATURE_COMMENTS, PERMISSION_TYPES.READ));

  if (!canReadComments) return null;
  return (
    <WithPermission permission={ FEATURE_COMMENTS } type={ PERMISSION_TYPES.CREATE }>
      <MenuItem
        title={ intl.formatMessage({ id: 'manage.cm.addComment' }) }
        icon={ <CommentsIcon /> }
        onClick={ () => {
          if (selectedVulnID) {
            if (isBulk) {
              dispatch(showBulkUpdateModal('comment', ''));
            } else {
              dispatch(showVulnDetailGeneral(selectedVulnID, 'comment'));
              dispatch(setShowContextMenu(false));
            }
          }
        } }
      />
    </WithPermission>
  );
};
