export const RESET_STATE_EXECUTVE_REPORT_CREATE_UPDATE = 'RESET_STATE_EXECUTVE_REPORT_CREATE_UPDATE';
export const GET_VULNS_EXECUTIVE_REPORT_CREATE_UPDATE_SUCCESS = 'GET_VULNS_EXECUTIVE_REPORT_CREATE_UPDATE_SUCCESS';
export const HIDE_WARNINGS_EXECUTIVE_REPORT_CREATE_UPDATE = 'HIDE_WARNINGS_EXECUTIVE_REPORT_CREATE_UPDATE';
export const EXECUTIVE_REPORT_CREATE_UPDATE_START = 'EXECUTIVE_REPORT_CREATE_UPDATE_START';
export const EXECUTIVE_REPORT_CREATE_UPDATE_VALIDATION_FAIL = 'EXECUTIVE_REPORT_CREATE_UPDATE_VALIDATION_FAIL';
export const EXECUTIVE_REPORT_CREATE_UPDATE_FAIL = 'EXECUTIVE_REPORT_CREATE_UPDATE_FAIL';
export const EXECUTIVE_REPORT_CREATE_UPDATE_SUCCESS = 'EXECUTIVE_REPORT_CREATE_UPDATE_SUCCESS';
export const SET_REPORT_CREATE_UPDATE = 'SET_REPORT_CREATE_UPDATE';
export const GET_PREVIEW_DATA_START_REPORT = 'GET_PREVIEW_DATA_START_REPORT';
export const GET_PREVIEW_DATA_SUCCESS_REPORT = 'GET_PREVIEW_DATA_SUCCESS_REPORT';
export const GET_PREVIEW_DATA_FAIL_REPORT = 'GET_PREVIEW_DATA_FAIL_REPORT';
export const GET_PREVIEW_DATA_CANCELED_REPORT = 'GET_PREVIEW_DATA_CANCELED_REPORT';
export const SET_PARSE_ADVANCED_FILTER_ERROR = 'SET_PARSE_ADVANCED_FILTER_ERROR';
export const CLEAR_PARSE_ADVANCED_FILTER_ERROR = 'CLEAR_PARSE_ADVANCED_FILTER_ERROR';
export const SET_INPUT_FIELD = 'SET_INPUT_FIELD';
export const SET_FILTER_ARRAY = 'SET_FILTER_ARRAY';
export const RESET_TEMP_TEMPLATE_EXECUTIVE_REPORT_CREATE_UPDATE = 'RESET_TEMP_TEMPLATE_EXECUTIVE_REPORT_CREATE_UPDATE';
export const SET_CUSTOM_LOGO = 'SET_CUSTOM_LOGO';
export const RESET_CUSTOM_LOGO = 'RESET_CUSTOM_LOGO';
export const GET_CUSTOM_LOGO_FAIL = 'GET_CUSTOM_LOGO_FAIL';
export const SET_ADD_TEMPLATE_STATE = 'SET_ADD_TEMPLATE_STATE';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const CUSTOM_TEMPLATE_FAIL = 'CUSTOM_TEMPLATE_FAIL';
export const RESET_ERROR_VALUE = 'RESET_ERROR_VALUE';
