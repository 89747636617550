import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { selectSelectedCloudAgentParams } from 'store/Agents/selectors';
import Wrapper from './styled';
import NonBooleanField from './NonBooleanField';
import BooleanField from './BooleanField';

const AgentParams = ({ onChangeField, targetError }) => {
  const parameters = useSelector(selectSelectedCloudAgentParams);
  const booleanParams = Object.keys(parameters).filter((param) => parameters[param].base === 'boolean');
  const nonBooleanParams = Object.keys(parameters).filter((param) => parameters[param].base !== 'boolean');

  if (isEmpty(parameters)) return null;
  return (
    <Wrapper>
      { nonBooleanParams.map((param) => <NonBooleanField key={ param } param={ param } parameters={ parameters } onChangeField={ onChangeField } targetError={ targetError } />) }
      { booleanParams.map((param) => <BooleanField key={ param } param={ param } parameters={ parameters } onChangeField={ onChangeField } />)}
    </Wrapper>
  );
};

export default AgentParams;
