/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getManifest, runCloudAgentTool, runUserAgentTool, setTagsEntity } from 'store/Agents/actions';
import { selectTriggerAgentTools, selectIsFetching, selectTriggerAgentWs, SelectShowCloudAgents, selectSelectedCloudAgentParams } from 'store/Agents/selectors';
import { Cancel } from 'Common/Components/Button/styled';
import BlueButton from 'Common/Components/BlueButton';
import ToolParams from './components/ToolParams';
import ExecutionParams from './components/ExecutionParams';
import { closeModal } from 'store/modals/actions';
import { MODAL_RUN_USER_AGENT } from 'store/modals/modals';
import StandardDropdown from 'Common/Components/StandarDropdown';
import {
  Wrapper, Title, Subtitle, WrapperButtons, ModalWrapper
} from './styled';
import WorkspaceSelector from './components/WorkspaceSelector';
import Tags from './components/Tags';
import AgentParams from '../../../CloudAgents/components/RunCloudAgentModal/AgentParams/components';
import useKeyPress from 'Hooks/useKeyPress';
import { selectUserRole } from 'store/Faraday/selectors';

const RunUserAgentModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isAllowedByRole = useSelector(selectUserRole) === 'admin';
  const tools = useSelector(selectTriggerAgentTools);
  const mappedTools = tools.map((t) => ({ name: t.name, desc: t.name }));
  const isFetching = useSelector(selectIsFetching);
  const workspaces = useSelector(selectTriggerAgentWs);
  const showCloudAgents = useSelector(SelectShowCloudAgents);
  const agentParameters = useSelector(selectSelectedCloudAgentParams);

  const title = intl.formatMessage({ id: 'agents.triggerAgent.title' });
  const subtitle = intl.formatMessage({ id: 'agents.triggerAgent.subtitle' });
  const cancelLabel = intl.formatMessage({ id: 'agents.triggerAgent.cancel' });
  const runLabel = intl.formatMessage({ id: 'agents.triggerAgent.run' });

  const [currentTool, setCurrentTool] = useState(null);
  const [toolParams, setToolParams] = useState({});
  const [wsSelected, setWsSelected] = useState([]);
  const [tags, setTags] = useState('');
  const [targetError, setTargetError] = useState('');

  const onChangeTool = (field, value) => {
    const tool = tools.find((t) => t.name === value);
    if (tool) {
      setToolParams({});
      setCurrentTool(tool);
    }
  };

  const validateUrlTarget = (domain) => {
    // eslint-disable-next-line prefer-regex-literals
    const re = new RegExp(/^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,63}$/);
    if (domain && (!re.test(domain))) setTargetError('Target should be a domain. e.g.: domain.com');
    else setTargetError('');
  };

  const onChangeField = (field, value, type) => {
    if (showCloudAgents && (type === 'url')) validateUrlTarget(value);
    const newParams = { ...toolParams, [field]: value };
    if (type === 'list') {
      const newValue = value.replace(/\s+/g, '');
      newParams[field] = newValue.split(',');
    }
    if (value === '') delete newParams[field];
    setToolParams(newParams);
  };

  const disableAccept = () => {
    if (isEmpty(currentTool)) return true;
    if (isEmpty(workspaces)) return true;
    const mandatoryParams = Object.keys(currentTool.parameters_metadata).filter((toolName) => currentTool.parameters_metadata[toolName].mandatory);
    return mandatoryParams.some((toolName) => isEmpty(get(toolParams, toolName, '')));
  };

  const disableAcceptCloudAgent = () => {
    if (isEmpty(workspaces)) return true;
    const mandatoryParams = Object.keys(agentParameters).filter((param) => agentParameters[param].mandatory);
    return mandatoryParams.some((toolName) => isEmpty(get(toolParams, toolName, ''))) || targetError;
  };

  const runTool = () => dispatch(runUserAgentTool(currentTool.name, toolParams, tags));
  const runCloudAgent = () => dispatch(runCloudAgentTool(wsSelected, toolParams, tags));
  const onClose = () => {
    dispatch(closeModal(MODAL_RUN_USER_AGENT));
    dispatch(setTagsEntity('assetsTags', false));
    dispatch(setTagsEntity('vulnsTags', false));
    dispatch(setTagsEntity('servicesTags', false));
  };

  useEffect(() => {
    if (isAllowedByRole) dispatch(getManifest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useKeyPress(() => {
    onClose();
  }, ['Escape']);

  useKeyPress(() => {
    runTool();
  }, ['Enter']);

  if (isEmpty(tools) && !showCloudAgents) return null;

  if (showCloudAgents) {
    return (
      <ModalWrapper>
      <Wrapper>
        <Title children={ title } />
        <AgentParams onChangeField={ onChangeField } targetError={ targetError } />
        <WorkspaceSelector
          currentTool
          onRemove={ (ws) => setWsSelected(wsSelected.filter((w) => w.id !== ws.id)) }
          onSelect={ (ws) => setWsSelected([...wsSelected, ws]) }
        />
        <Tags tags={ tags } setTags={ setTags } />
        <WrapperButtons>
          <Cancel onClick={ onClose } children={ cancelLabel } />
          <BlueButton onClick={ runCloudAgent } disabled={ disableAcceptCloudAgent() } isLoading={ isFetching } label={ runLabel } />
        </WrapperButtons>
      </Wrapper>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper>
      <Wrapper>
        <Title children={ title } />
        <Subtitle children={ subtitle } />
        <StandardDropdown width="170px" field="toolParams" options={ mappedTools } updateValue={ onChangeTool } />
        <ExecutionParams currentTool={ currentTool } />
        <ToolParams currentTool={ currentTool } onChangeField={ onChangeField } />
        { currentTool && <WorkspaceSelector currentTool={ currentTool } />}
        { currentTool && <Tags tags={ tags } setTags={ setTags } />}
        <WrapperButtons>
          <Cancel onClick={ onClose } children={ cancelLabel } />
          <BlueButton onClick={ runTool } disabled={ disableAccept() } label={ runLabel } />
        </WrapperButtons>
      </Wrapper>
    </ModalWrapper>
  );
};

export default RunUserAgentModal;
