import get from 'lodash/get';

export const selectEnabled = (state) => get(state, 'settings.ldap.enabled', false);
export const selectServer = (state) => get(state, 'settings.ldap.server', '');
export const selectPort = (state) => get(state, 'settings.ldap.port', null);
export const selectDomain = (state) => get(state, 'settings.ldap.domain', '');
export const selectDomainDn = (state) => get(state, 'settings.ldap.domain_dn', '');
export const selectBindFormat = (state) => get(state, 'settings.ldap.bind_format', '');
export const selectBindDn = (state) => get(state, 'settings.ldap.bind_dn', '');
export const selectUserAttribute = (state) => get(state, 'settings.ldap.user_attribute', '');
export const selectUseLocalRoles = (state) => get(state, 'settings.ldap.use_local_roles', true);
export const selectDefaultLocalRole = (state) => get(state, 'settings.ldap.default_local_role', '');
export const selectAdminGroup = (state) => get(state, 'settings.ldap.admin_group', '');
export const selectPentesterGroup = (state) => get(state, 'settings.ldap.pentester_group', '');
export const selectClientGroup = (state) => get(state, 'settings.ldap.client_group', '');
export const selectAssetOwnerGroup = (state) => get(state, 'settings.ldap.asset_owner_group', '');
export const selectUseLdaps = (state) => get(state, 'settings.ldap.use_ldaps', false);
export const selectUseStartTls = (state) => get(state, 'settings.ldap.use_start_tls', false);
export const selectDisconnectTimeout = (state) => get(state, 'settings.ldap.disconnect_timeout', null);
export const selectPaginatedFetch = (state) => get(state, 'settings.ldap.paginated_fetch', false);
export const selectUserClass = (state) => get(state, 'settings.ldap.user_class', '');
export const selectUserGroup = (state) => get(state, 'settings.ldap.group_class', '');
export const selectIsFetching = (state) => get(state, 'settings.ldap.isFetching', false);

export const selectAllowSave = (state) => {
  const server = !!selectServer(state);
  const port = !!selectPort(state);
  const domain = !!selectDomain(state);
  const domainDn = !!selectDomainDn(state);
  const bindFormat = !!selectBindFormat(state);
  const bindDn = !!selectBindDn(state);
  const userAttribute = !!selectUserAttribute(state);
  const useLocalRoles = !!selectUseLocalRoles(state);
  const defaultLocalRole = !!selectDefaultLocalRole(state);
  const adminGroup = !!selectAdminGroup(state);
  const pentesterGroup = !!selectPentesterGroup(state);
  const clientGroup = !!selectClientGroup(state);
  const assetOwnerGroup = !!selectAssetOwnerGroup(state);
  const useLdaps = !!selectUseLdaps(state);
  const useStartTls = !!selectUseStartTls(state);
  const connectionTimeOut = !!selectDisconnectTimeout(state);
  const userClass = !!selectUserClass(state);
  const userGroup = !!selectUserGroup(state);
  const bindFormatFields = bindFormat === 'DN' ? (bindDn && userAttribute) : bindFormat;
  const roles = useLocalRoles
    ? defaultLocalRole
    : adminGroup || pentesterGroup || clientGroup || assetOwnerGroup;

  const security = useLdaps && useStartTls;

  return server &&
  port &&
  domain &&
  domainDn &&
  bindFormat &&
  bindFormatFields &&
  roles &&
  security &&
  connectionTimeOut &&
  userClass &&
  userGroup;
};
