import api from 'services/api';
import { selectVulnDetail, selectVulns } from 'store/ManageGeneral/selectors';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import * as types from './types';
import { getVulnsGeneral, loadVulnDetail } from './actions';

export const markDuplicatesSuccess = (updatedVulnList) => async (dispatch) => dispatch({ type: types.GENERAL_MANAGE_DUPLICATES_MARK_SUCCESS, updatedVulnList });
export const markAsMainSuccess = () => async (dispatch) => dispatch({ type: types.GENERAL_MANAGE_DUPLICATES_MARK_AS_MAIN_SUCCESS });
export const removeDuplicatesSuccess = () => async (dispatch) => dispatch({ type: types.GENERAL_MANAGE_DUPLICATES_REMOVE_SUCCESS });
export const getDuplicatesSuccess = (duplicates) => async (dispatch) => dispatch({ type: types.GENERAL_MANAGE_DUPLICATES_GET_SUCCESS, duplicates });
export const duplicatesError = (error) => async (dispatch) => dispatch({ type: types.GENERAL_MANAGE_DUPLICATES_ERROR, error });

export function getDuplicates (id) {
  return async (dispatch) => {
    try {
      const duplicates = await api.vulnsGeneral.getVulnDuplicates(id);

      const orderedDuplicates = duplicates.sort((a, b) => {
        if (a.is_main && !b.is_main) return -1;
        if (!a.is_main && b.is_main) return 1;
        return a.id - b.id;
      });

      return dispatch(getDuplicatesSuccess(orderedDuplicates));
    } catch (error) {
      return dispatch(duplicatesError(error.message));
    }
  };
}

export function markDuplicates (duplicatesIDs, mainID = null) {
  return async (dispatch, getState) => {
    try {
      let main = null;
      let duplicates = [];

      if (mainID) main = mainID;
      else main = duplicatesIDs[0];

      duplicates = duplicatesIDs.filter((id) => id !== main);

      const vulnList = selectVulns(getState());

      const data = {
        main_vulnerability_id: main,
        associated_vulnerabilities_ids: duplicates
      };
      await api.vulnsGeneral.markDuplicates(data);
      dispatch(trackEvent(CATEGORIES.duplicates, ACTIONS.clickSetAsDuplicates.name, ACTIONS.clickSetAsDuplicates.label));

      const idsToUpdate = [main, ...duplicates];

      const updatedVulnsPromises = idsToUpdate.map((id) => api.vulnsGeneral.fetchVulnById(id));
      const updatedVulns = await Promise.all(updatedVulnsPromises);

      const newVulnList = vulnList.map((vuln) => {
        const updatedVuln = updatedVulns.find((updated) => updated._id === vuln._id);
        if (updatedVuln) return updatedVuln;
        return vuln;
      });

      return dispatch(markDuplicatesSuccess(newVulnList));
    } catch (error) {
      return dispatch(duplicatesError(error.message));
    }
  };
}

export function removeDuplicate (duplicateID) {
  return async (dispatch, getState) => {
    try {
      const { id } = selectVulnDetail(getState());
      const data = {
        vulnerabilities_to_disassociate_ids: [duplicateID]
      };
      await api.vulnsGeneral.removeDuplicates(data);
      dispatch(getVulnsGeneral());
      dispatch(getDuplicates(id));
      dispatch(loadVulnDetail(id));
      return dispatch(removeDuplicatesSuccess());
    } catch (error) {
      return dispatch(duplicatesError(error.message));
    }
  };
}

export function markAsMainDuplicate (newMainID) {
  return async (dispatch) => {
    try {
      const data = {
        new_main_vulnerability_id: newMainID
      };
      await api.vulnsGeneral.markAsMainDuplicate(data);

      dispatch(getVulnsGeneral());
      dispatch(getDuplicates(newMainID));
      dispatch(loadVulnDetail(newMainID));

      return dispatch(markAsMainSuccess());
    } catch (error) {
      return dispatch(duplicatesError(error.message));
    }
  };
}
