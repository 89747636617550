import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { showQuickPlanner, redirectToManage } from 'store/Manage/actions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_PROJECTS, FEATURE_TASKS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { Icon, Wrapper } from './styled';
import { redirectToManage as redirectToVulnsInAsset } from 'store/Contextualization/AssetDetail/actions';
import { redirectToVulnerabilities } from 'store/ManageGeneral/actions';

const QuickPlannerButton = ({ hasVulns, entity }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isProjectEnabled = useSelector((state) => selectAllowedByFeature(state, FEATURE_PROJECTS, PERMISSION_TYPES.UPDATE) && selectAllowedByRole(state, FEATURE_PROJECTS, PERMISSION_TYPES.UPDATE));
  const isTaskEnabled = useSelector((state) => selectAllowedByFeature(state, FEATURE_TASKS, PERMISSION_TYPES.UPDATE) && selectAllowedByRole(state, FEATURE_TASKS, PERMISSION_TYPES.UPDATE));
  const canUpdate = hasVulns && isProjectEnabled && isTaskEnabled;

  const open = () => {
    if (canUpdate) {
      if (entity === 'vulnsAssets') dispatch(redirectToVulnsInAsset());
      else if (entity === 'vulns') dispatch(redirectToManage());
      else dispatch(redirectToVulnerabilities());
      dispatch(showQuickPlanner(true));
    }
  };

  const title = intl.formatMessage({ id: 'quickPlanner.title' });

  return (
    <Wrapper onClick={ open } title={ title } disabled={ !canUpdate } >
      <Icon />
    </Wrapper>
  );
};

export default QuickPlannerButton;
