import React from 'react';
import Select from 'react-select';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnDetail } from 'store/ManageGeneral/selectors';
import { updateVulnGeneral } from 'store/ManageGeneral/actions';
import { statusSelect } from 'Common/styles/style';
import { capitalize } from 'utils/stringUtils';
import { status as statusList } from 'Screens/Constants';
import Wrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ActionBar/components/StatusPicker/styled';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const StatusPicker = () => {
  const dispatch = useDispatch();
  const vuln = useSelector(selectVulnDetail);

  const readOnly = useSelector(selectIsReadOnly);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const status = get(vuln, 'status', '');
  const value = {
    label: capitalize(status),
    value: status
  };

  return (
    <Wrapper isDisabled={ readOnly || !canUpdate }>
      <Select
        styles={ statusSelect }
        isSearchable={ false }
        isDisabled={ readOnly || !canUpdate }
        value={ value }
        onChange={ (e) => dispatch(updateVulnGeneral(vuln, 'status', e.value)) }
        options={ statusList.map((element) => ({ label: element.desc, value: element.name })) }
      />
    </Wrapper>
  );
};

export default StatusPicker;
