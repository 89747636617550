import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Text } from './styled';

const IconButton = ({
  onClick, title, icon, text, id, disabled
}) => {
  const handleClick = (e) => {
    if (!disabled) onClick(e);
  };

  return (
    <Wrapper title={ title } onClick={ handleClick } id={ id } disabled={ disabled } >
      {icon}
      { text && <Text>{ text }</Text> }
    </Wrapper>
  );
};

IconButton.defaultProps = {
  onClick: null,
  id: '',
  disabled: false
};

IconButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool
};

export default IconButton;
