import React from 'react';
import FlagIcon from 'Common/Components/FlagIcon/index';
import { updateVulnFromAsset } from 'store/Contextualization/AssetDetail/actions';
import { useSelector, useDispatch } from 'react-redux';
import { selectDetail } from 'store/Contextualization/AssetDetail/selectors';
import get from 'lodash/get';
import { Wrapper, Label } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ActionBar/components/DisplayConfirmed/styled';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const DisplayConfirmed = () => {
  const dispatch = useDispatch();
  const vuln = useSelector((state) => selectDetail('vulns', state));
  const confirmed = get(vuln, 'confirmed', false);
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const canUpdate = !readOnly && allowedByRole;
  const handleClick = () => {
    if (canUpdate) dispatch(updateVulnFromAsset(vuln, 'confirmed', !confirmed));
  };

  return (
    <Wrapper>
      <FlagIcon
        theme={ confirmed ? 'confirmed' : 'notConfirmed' }
        alt={ confirmed ? 'Confirmed' : 'Un-confirmed' }
        onClick={ handleClick }
        aria-label={ confirmed ? 'Confirmed' : 'Not confirmed' }
      />
      <Label>{confirmed ? 'Confirmed' : 'Not Confirmed'}</Label>
    </Wrapper>
  );
};

export default DisplayConfirmed;
