import React from 'react';
import { MarkAsReadIcon, SettingsIcon, Header as Wrapper } from './styled';
import { useDispatch } from 'react-redux';
import { redirect } from 'store/Router/actions';
import { markNotificationsAsReadAsync } from 'store/Notifications/actions';

const Header = ({ lastFourNotifications }) => {
  const dispatch = useDispatch();

  const handleSettingsClick = () => {
    dispatch(redirect('/notifications-settings'));
  };

  const handleMarkAsReadClick = () => {
    const selectedIds = lastFourNotifications.map((notification) => notification.id);
    dispatch(markNotificationsAsReadAsync(selectedIds));
  };
  return (
    <Wrapper>
      <p>Notifications</p>
      <MarkAsReadIcon onClick={ handleMarkAsReadClick } />
      <SettingsIcon onClick={ handleSettingsClick } />
    </Wrapper>
  );
};

export default Header;
