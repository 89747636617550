/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import Checkbox from 'Common/Components/Checkbox';
import { PropTypes } from 'prop-types';
import { removeFiltersByKey } from 'store/Filters/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getVulnsPreview } from 'store/ExecutiveReportEditCreate/actions';
import { selectFilterHistory } from 'store/Filters/selectors';
import { FormattedMessage } from 'react-intl';
import { selectWorkspaces } from 'store/ExecutiveReportEditCreate/selectors';
import {
  CheckboxWrapper, Title, Wrapper, Subtitle
} from './styled';
import AssetsInput from './components/AssetsInput';
import AssetsList from './components/AssetsList';

const AssetsFilter = ({ disabled }) => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const filterHistory = useSelector((state) => selectFilterHistory('reports', state));
  const assetsFilters = filterHistory.filter((f) => f.name === 'target');
  const workspaces = useSelector(selectWorkspaces);

  const toggleCheck = () => {
    if (checked) {
      dispatch(removeFiltersByKey('reports', 'target'));
      dispatch(getVulnsPreview(workspaces));
    }
    setChecked(!checked);
  };

  useEffect(() => {
    if (assetsFilters.length > 0) setChecked(true);
  }, [assetsFilters]);

  useEffect(() => {
    if (disabled) setChecked(false);
  }, [disabled]);

  return (
    <Wrapper>
      <CheckboxWrapper onClick={ disabled ? null : toggleCheck } disabled={ disabled }>
        <Checkbox state={ checked } disabled={ disabled } />
        <Title children={ <FormattedMessage id="reports.editCreate.fieldFilter.filterByAssetsTitle" /> } />
      </CheckboxWrapper>
      <Subtitle disabled={ disabled || !checked } children={ <FormattedMessage id="reports.editCreate.fieldFilter.filterByAssetsSubtitle" /> } />
      <AssetsInput disabled={ !checked } />
      <AssetsList disabled={ disabled || !checked } list={ assetsFilters } />
    </Wrapper>
  );
};

AssetsFilter.propTypes = {
  disabled: PropTypes.bool
};

AssetsFilter.defaultProps = {
  disabled: false
};
export default AssetsFilter;
