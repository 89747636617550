import api from 'services/api';
import { showModalImportDashboard } from 'Screens/Contextualization/Dashboard/actions/Actions';
import {
  selectWorkspaceList, selectWorkspacesSelected,
  selectLastSelected, selectShowInactive,
  selectShowWsDetail, selectShowGridView, selectIsFetchingPreview,
  selectShowGridViewContext, selectWorkspacesForListView,
  selectWorkspacesAdvancedFilterQueryParam,
  selectWorkspacesQueryParam
} from 'store/Workspace/selectors';
import { getVulnsCountInAllWorkspaces, updateCurrentWorkspaceOnEdit as setFaradayWorkspace } from 'Screens/Contextualization/Faraday/actions/Actions';
import { workspaceIsInWorkspaceActivity, removeWorkspaceFromWorkspaceActivity } from 'Screens/Contextualization/Faraday/functions';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import { trackEvent } from 'tracking/GA';
import { updatePreferences } from 'store/Preferences/actions';
import { selectWorkspaceActivity } from 'store/Preferences/workspace/selectors';
import get from 'lodash/get';
import { openModal, closeModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE, MODAL_WORKSPACE_CREATE } from 'store/modals/modals';
import { selectMaxWorkspacesLimit } from 'store/Config/selector';
import { selectCurrentWorkspace, selectCurrentWorkspaceData } from 'store/Faraday/selectors';
import {
  selectCurrentWsName,
  selectWsEditCreateName,
  selectWsEditCreateDescription,
  selectWsEditCreateStart,
  selectWsEditCreateEnd,
  selectWsEditCreateId,
  selectScope,
  selectWsEditActive,
  selectWsEditCustomer,
  selectWsEditReadonly,
  selectWsEditCreateMakePublic,
  selectWsEditCreateCurrentUsers,
  selectWsEditCreateImportance
} from 'store/WorkspaceEditCreate/selectors';
import { redirect } from 'store/Router/actions';
import {
  setPageNumber, clearFilters,
  setFilter, setOrderBy,
  setFilterError,
  removeFiltersByKey
} from 'store/Filters/actions';
import { selectAdvancedFilter } from 'store/Filters/selectors';
import { setError } from 'store/errors/actions';
import { GET_USERS_SUCCESS } from 'store/Manage/types';
import { selectPathname } from 'store/Router/selectors';
import filter from 'lodash/filter';

export const SET_FIELD = 'SET_FIELD';
export const GET_DATA_WORKSPACE_START = 'GET_DATA_WORKSPACE_START';
export const GET_DATA_WORKSPACE_FAIL = 'GET_DATA_WORKSPACE_FAIL';
export const GET_DATA_WORKSPACE_SUCCESS = 'GET_DATA_WORKSPACE_SUCCESS';
export const WORKSPACE_SELECTED_FOR_WORKING = 'WORKSPACE_SELECTED_FOR_WORKING';
export const GET_WORKSPACES_FOR_WORKING_STARTED = 'GET_WORKSPACES_FOR_WORKING_STARTED';
export const GET_WORKSPACES_FOR_WORKING_SUCCESS = 'GET_WORKSPACES_FOR_WORKING_SUCCESS';
export const GET_WORKSPACES_FOR_WORKING_FAILURE = 'GET_WORKSPACES_FOR_WORKING_FAILURE';
export const WORKSPACE_CREATE_UPDATE_START = 'WORKSPACE_CREATE_UPDATE_START';
export const WORKSPACE_CREATE_UPDATE_SUCCESS = 'WORKSPACE_CREATE_UPDATE_SUCCESS';
export const WORKSPACE_ENABLE_DISABLE = 'WORKSPACE_ENABLE_DISABLE';
export const STOP_REDIRECT = 'STOP_REDIRECT';
export const CONTEXT_WORKSPACE_SELECTED = 'CONTEXT_WORKSPACE_SELECTED';
export const DELETE_WORKSPACES = 'DELETE_WORKSPACES';
export const WORKSPACE_UPDATE_SUCCESS = 'WORKSPACE_UPDATE_SUCCESS';
export const SET_VISIBILITY_CREATE_MODAL_WORKSPACE = 'SET_VISIBILITY_CREATE_MODAL_WORKSPACE';
export const WORKSPACE_CREATION_ERROR = 'WORKSPACE_CREATION_ERROR';
export const SET_USERS_WORKSPACES = 'SET_USERS_WORKSPACES';
export const CONTEXT_WORKSPACE_UPDATE_SUCCESS = 'CONTEXT_WORKSPACE_UPDATE_SUCCESS';
export const SET_WORKSPACE_FOR_EDIT = 'SET_WORKSPACE_FOR_EDIT';
export const WORKSPACE_MASSIVE_UPDATE_FAIL = 'WORKSPACE_MASSIVE_UPDATE_FAIL';
export const WORKSPACE_MASSIVE_UPDATE_SUCCESS = 'WORKSPACE_MASSIVE_UPDATE_SUCCESS';
export const MORE_OPTIONS_STATUS_CHANGE_WORKSPACE = 'MORE_OPTIONS_STATUS_CHANGE_WORKSPACE';
export const SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE = 'SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE';
export const TOGGLE_INACTIVE_FLAG = 'TOGGLE_INACTIVE_FLAG';
export const GET_DATA_WORKSPACES_SUCCESS = 'GET_DATA_WORKSPACES_SUCCESS';
export const WORKSPACE_SHOW_GRID_VIEW = 'WORKSPACE_SHOW_GRID_VIEW';
export const CURRENT_WORKSPACE_EDITED_UPDATE = 'CURRENT_WORKSPACE_EDITED_UPDATE';
export const SET_CURRENT_WORKSPACE_AS_INVALID = 'SET_CURRENT_WORKSPACE_AS_INVALID';
export const SET_SHOW_WORKSPACE_VALIDATOR = 'SET_SHOW_WORKSPACE_VALIDATOR';

export const RESET_STATE_WORKSPACE_CREATE_UPDATE = 'RESET_STATE_WORKSPACE_CREATE_UPDATE';
export const WORKSPACE_CREATE_UPDATE_FAIL = 'WORKSPACE_CREATE_UPDATE_FAIL';
export const UNSELECT_ALL_WORKSPACES = 'UNSELECT_ALL_WORKSPACES';
export const WORKSPACE_UNSELECTED = 'WORKSPACE_UNSELECTED';
export const NEW_PIVOT = 'NEW_PIVOT';
export const SELECT_ALL_WORKSPACES = 'SELECT_ALL_WORKSPACES';
export const WORKSPACE_SELECTED_FROM_GRID_VIEW = 'WORKSPACE_SELECTED_FROM_GRID_VIEW';
export const SHOW_WORKSPACE_DETAIL = 'SHOW_WORKSPACE_DETAIL';
export const HIDE_WORKSPACE_DETAIL = 'HIDE_WORKSPACE_DETAIL';
export const REFRESH_WS = 'REFRESH_WS';
export const REFRESH_WS_FAIL = 'REFRESH_WS_FAIL';
export const REFRESH_WS_START = 'REFRESH_WS_START';
export const RESET_REFRESH_WS = 'RESET_REFRESH_WS';
export const CONTEXT_WORKSPACE_CREATE_UPDATE_FAIL = 'CONTEXT_WORKSPACE_CREATE_UPDATE_FAIL';

export const GET_WORKSPACE_STATS_START = 'GET_WORKSPACE_STATS_START';
export const GET_WORKSPACE_STATS_SUCCESS = 'GET_WORKSPACE_STATS_SUCCESS';
export const GET_WORKSPACE_STATS_FAIL = 'GET_WORKSPACE_STATS_FAIL';

export const GET_WORKSPACES_FOR_SELECTOR_START = 'GET_WORKSPACES_FOR_SELECTOR_START';
export const GET_WORKSPACES_FOR_SELECTOR_SUCCESS = 'GET_WORKSPACES_FOR_SELECTOR_SUCCESS';
export const GET_WORKSPACES_FOR_SELECTOR_FAIL = 'GET_WORKSPACES_FOR_SELECTOR_FAIL';
export const GET_WORKSPACES_FOR_LIST_VIEW_SUCCESS = 'GET_WORKSPACES_FOR_LIST_VIEW_SUCCESS';

export const SET_FETCHING_PREVIEW = 'SET_FETCHING_PREVIEW';
export const RESET_ERROR_VALUE = 'RESET_ERROR_VALUE';
export const CONTEXT_WORKSPACE_MASSIVE_UPDATE_SUCCESS = 'CONTEXT_WORKSPACE_MASSIVE_UPDATE_SUCCESS';
export const CONTEXT_GET_DATA_WORKSPACES_SUCCESS = 'CONTEXT_GET_DATA_WORKSPACES_SUCCESS';

export const SET_WORKSPACES_FILTER_ERROR = 'SET_WORKSPACES_FILTER_ERROR';

export function resetCreationState () {
  return (dispatch) => {
    dispatch({
      type: RESET_STATE_WORKSPACE_CREATE_UPDATE
    });
  };
}

export function getWorkspaces (histogram = true, stats = true, onlyTotalVulns = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_WORKSPACES_FOR_WORKING_STARTED });
      const response = await api.workspace.fetchWorkspaces({}, histogram, !stats, false, onlyTotalVulns);
      const data = response.rows;
      const count = response.count;
      dispatch({ type: GET_WORKSPACES_FOR_WORKING_SUCCESS, data, count });
    } catch (e) {
      dispatch({ type: GET_WORKSPACES_FOR_WORKING_FAILURE, data: e.message || 'Failed to obtain workspaces.' });
    }
  };
}

// Summary: select a workspace for user to work
export function workspaceSelectedForWorking (workspace) {
  return (dispatch) => {
    dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace, redirect: true });
  };
}

export function stopRedirect () {
  return (dispatch) => {
    dispatch({ type: STOP_REDIRECT });
  };
}

const setWorkspacesFilterError = () => async (dispatch) => dispatch({ type: SET_WORKSPACES_FILTER_ERROR });

export function getData (histogram = true, stats = true, onlyTotalVulns = false) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_DATA_WORKSPACE_START });
    const state = getState();
    const hasAdvancedFilter = selectAdvancedFilter(state, 'workspaces');
    let advancedFilterQueryParam = [];
    if (hasAdvancedFilter) {
      try {
        advancedFilterQueryParam = selectWorkspacesAdvancedFilterQueryParam(state);
      } catch (e) {
        dispatch(setFilterError('workspaces', 'Syntax error. Please try again. For further help check our documentation'));
        return dispatch(setWorkspacesFilterError());
      }
    }

    const standardQueryParam = selectWorkspacesQueryParam(state);

    const params = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;
    const showGridView = selectShowGridViewContext(getState());
    const showListView = !showGridView;
    try {
      const response = await api.workspace.fetchWorkspaces(params, histogram, !stats, showListView, onlyTotalVulns);
      const data = response.rows;
      const count = response.count;
      const pathname = selectPathname(getState());
      if ((pathname === '/workspaces') && showListView) return dispatch({ type: GET_WORKSPACES_FOR_LIST_VIEW_SUCCESS, data, count });
      else return dispatch({ type: CONTEXT_GET_DATA_WORKSPACES_SUCCESS, data, count });
    } catch (error) {
      return dispatch({ type: GET_DATA_WORKSPACE_FAIL, error });
    }
  };
}

export function enableDisable (ws, value) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { workspaceList, workspacesForListView } = state.workspace;
      const showGridView = selectShowGridViewContext(state);
      const showHistogram = !!showGridView;
      const workspaces = showGridView ? workspaceList : workspacesForListView;
      const activeWorkspaces = await api.workspace.getActiveWorkspaces(); // obtengo el total de workspaces activos.
      const maxWsLimit = selectMaxWorkspacesLimit(getState());
      if (!value && get(activeWorkspaces, 'length', 0) >= maxWsLimit) {
        dispatch(openModal(MODAL_UPGRADE_LICENSE));
      } else {
        dispatch({ type: WORKSPACE_ENABLE_DISABLE });

        await api.workspace.updateWorkspace(ws, { active: value });

        const newWs = workspaces.filter((workspace) => workspace.name === ws)[0];
        newWs.active = !value;

        dispatch({ type: CONTEXT_WORKSPACE_UPDATE_SUCCESS, ws: newWs });
        dispatch(getData(showHistogram));
      }
    } catch (e) {
      dispatch({ type: CONTEXT_WORKSPACE_CREATE_UPDATE_FAIL, data: 'An error has occurred.' });
    }
  };
}

export function changeReadOnly (ws, readonly) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: WORKSPACE_ENABLE_DISABLE, ws });
      const showGridView = selectShowGridViewContext(getState());
      const showHistogram = !!showGridView;
      await api.workspace.updateWorkspace(ws, { readonly });
      const newWs = await api.workspace.getWsStats(ws);

      if (newWs) dispatch({ type: CONTEXT_WORKSPACE_UPDATE_SUCCESS, ws: newWs });

      dispatch(getData(showHistogram));
    } catch (e) {
      dispatch({ type: CONTEXT_WORKSPACE_CREATE_UPDATE_FAIL, error: 'An error has occurred' });
    }
  };
}

// Summary: Add workspace selected to state. // se usa para la tabla
export function selectWorkspace (workspaceSelected) {
  return (dispatch) => {
    dispatch({
      type: WORKSPACE_SELECTED_FROM_GRID_VIEW, workspaceSelected
    });
  };
}

// Summary: delete workspace/s from database
export function deleteWorkspaceSelected (onClose) {
  return async (dispatch, getState) => {
    const state = getState();
    const workspacesSelected = selectWorkspacesSelected(state);
    const wsActivity = selectWorkspaceActivity(state);
    const showGridView = selectShowGridViewContext(state);
    const showHistogram = !!showGridView;
    dispatch({ type: DELETE_WORKSPACES });

    // TODO: Modify backend, so it should receive an array and return message
    // when some workspaces could not be deleted. Then, add request.
    try {
      const promises = workspacesSelected.map((ws) => {
        const promise = api.workspace.deleteWorkspace(ws.name);
        dispatch(trackEvent(CATEGORIES.workspace, ACTIONS.deleteWorkspace.name, ACTIONS.deleteWorkspace.label, ws.id));
        return promise;
      });

      await Promise.all(promises);
      const newWsActivity = wsActivity.filter((ws) => workspacesSelected.every((selected) => ws !== selected.name));
      dispatch(updatePreferences({ workspaceActivity: newWsActivity }));
      onClose && onClose();
      dispatch(getVulnsCountInAllWorkspaces());
    } catch (e) {
      dispatch(setError(e?.message || 'There was an error, please try again'));
      onClose && onClose();
    }
    dispatch({ type: UNSELECT_ALL_WORKSPACES });
    // await dispatch(getWorkspaces(showHistogram));
    await dispatch(getData(showHistogram, showGridView, !showGridView));
  };
}

export function getUsers () {
  return async (dispatch) => {
    try {
      const users = await api.users.getUsers();
      const mappedUsers = users.rows.map((user) => user.doc);
      const usernames = mappedUsers.map((user) => ({ name: user.username }));
      dispatch({ type: GET_USERS_SUCCESS, users: usernames });
      return dispatch({ type: SET_USERS_WORKSPACES, users: mappedUsers });
    } catch (e) {
      return dispatch({ type: '' });
    }
  };
}

function getUpdatedFields (existingObj, newObj) {
  return Object.keys(newObj).reduce((acc, key) => {
    if (typeof newObj[key] === 'object' && newObj[key] !== null && !Array.isArray(newObj[key])) {
      const updatedNestedObj = getUpdatedFields(existingObj[key], newObj[key]);
      if (Object.keys(updatedNestedObj).length > 0) {
        acc[key] = updatedNestedObj;
      }
    } else if (JSON.stringify(existingObj[key]) !== JSON.stringify(newObj[key])) {
      acc[key] = newObj[key];
    }
    return acc;
  }, {});
}

export function createUpdateWorkspace () {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const currentWsName = selectCurrentWsName(state);
      const name = selectWsEditCreateName(state);
      const description = selectWsEditCreateDescription(state);
      const start = selectWsEditCreateStart(state);
      const end = selectWsEditCreateEnd(state);
      const id = selectWsEditCreateId(state);
      const scope = selectScope(state);
      const currentWorkspace = selectCurrentWorkspace(state);
      const currentWorkspaceData = selectCurrentWorkspaceData(state);
      const active = selectWsEditActive(state);
      const customer = selectWsEditCustomer(state);
      const readonly = selectWsEditReadonly(state);
      const isPublic = selectWsEditCreateMakePublic(state);
      const currentUsers = selectWsEditCreateCurrentUsers(state);
      const importance = selectWsEditCreateImportance(state);
      const showGridView = selectShowGridViewContext(state);
      const showHistogram = !!showGridView;

      let wsActivity = selectWorkspaceActivity(state);

      dispatch({ type: WORKSPACE_CREATE_UPDATE_START });

      // eslint-disable-next-line no-useless-escape
      if (!/[^A-Za-z0-9@._-]/.test(name)) {
        let workspace = {
          name,
          description,
          scope,
          users: currentUsers,
          duration: {
            start_date: start ? new Date(start).getTime() : '',
            end_date: end ? new Date(end).getTime() : ''
          },
          importance,
          customer,
          public: isPublic
        };

        if (id) {
          workspace = {
            ...workspace,
            active,
            readonly
          };

          // Compare the new data with the existing data and include only the changed fields
          const updatedWorkspace = getUpdatedFields(currentWorkspaceData, workspace);

          // Include the ID in the updated workspace
          updatedWorkspace.id = id;

          // Send the PATCH request with only the changed fields
          const newWs = await api.workspace.updateWorkspace(currentWsName, updatedWorkspace);
          dispatch({ type: WORKSPACE_UPDATE_SUCCESS, ws: newWs });
          dispatch(trackEvent(CATEGORIES.workspace, ACTIONS.editWorkspace.name, ACTIONS.editWorkspace.label, id));
        } else {
          const response = await api.workspace.createWorkspace(workspace);
          dispatch(trackEvent(CATEGORIES.workspace, ACTIONS.createWorkspace.name, ACTIONS.createWorkspace.label, response.id));
        }

        dispatch({ type: WORKSPACE_CREATE_UPDATE_SUCCESS });

        if (id && workspaceIsInWorkspaceActivity(wsActivity, currentWorkspace)) {
          wsActivity = removeWorkspaceFromWorkspaceActivity(wsActivity, currentWorkspace);
          dispatch(updatePreferences({ workspaceActivity: wsActivity }));
        }

        if (currentWsName === currentWorkspace && currentWsName !== name) {
          dispatch(setFaradayWorkspace(name, currentWorkspace));
        }

        dispatch(setVisibilityCreateModal(false));
        dispatch(getUsers());
        // Remove when add pagination and filter functionality
        return dispatch(getData(showHistogram));
      }
      return dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: 'Workspace name can not contain white spaces, uppercase letters or special characters.' });
    } catch (e) {
      return dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function createWorkspaceFromSelector (users) {
  return async (dispatch, getState) => {
    const { name } = getState().workspaceEditCreate;
    const response = await dispatch(createUpdateWorkspace(users));

    if (!response.error) {
      await dispatch(getWorkspaces());
      dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace: name });
      dispatch(redirect(`/feed/${name}`));
    }
    return dispatch({ type: '' });
  };
}

export function resetErrorMessage () {
  return (dispatch) => dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: '' });
}

export function setVisibilityCreateModal (value) {
  return async (dispatch, getState) => {
    const workspaces = await api.workspace.getActiveWorkspaces(); // obtengo el total de workspaces activos.
    const maxWsLimit = selectMaxWorkspacesLimit(getState());
    if (get(workspaces, 'length', 0) >= maxWsLimit) {
      dispatch(openModal(MODAL_UPGRADE_LICENSE));
    } else {
      dispatch({ type: SET_VISIBILITY_CREATE_MODAL_WORKSPACE, value });
      if (!value) dispatch({ type: RESET_STATE_WORKSPACE_CREATE_UPDATE, value });
      if (value) dispatch(openModal(MODAL_WORKSPACE_CREATE));
      else dispatch(closeModal(MODAL_WORKSPACE_CREATE));
    }
  };
}

export function showEditModal () {
  return async (dispatch, getState) => {
    const { workspacesSelected } = getState().workspace;
    try {
      const workspace = await api.workspace.getWsStats(workspacesSelected[0].name);
      dispatch(setVisibilityCreateModal(true));
      return dispatch({ type: SET_WORKSPACE_FOR_EDIT, workspace });
    } catch (e) {
      return dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function massiveUpdateWorkspacesActive () {
  return async (dispatch, getState) => {
    const { workspacesSelected, workspaceList, workspacesForListView, contextShowGridView } = getState().workspace;
    const currentWorkspace = selectCurrentWorkspace(getState());
    try {
      const workspacesUpdated = contextShowGridView ? workspaceList : workspacesForListView;
      const activeWorkspaces = await api.workspace.getActiveWorkspaces();
      const maxWsLimit = selectMaxWorkspacesLimit(getState());

      if (get(activeWorkspaces, 'length', 0) >= maxWsLimit) {
        dispatch(openModal(MODAL_UPGRADE_LICENSE));
      } else {
        workspacesSelected.forEach(async (ws) => {
          if (currentWorkspace !== ws.name) {
            const index = workspacesUpdated.findIndex((workspace) => workspace.id === ws.id);
            const wsCopy = ws;
            wsCopy.active = !wsCopy.active;
            workspacesUpdated[index] = wsCopy;

            if (wsCopy.active) {
              await api.workspace.updateWorkspace(wsCopy.name, { active: true });
            } else {
              await api.workspace.updateWorkspace(wsCopy.name, { active: false });
            }
          }
        });
      }
      return dispatch({ type: CONTEXT_WORKSPACE_MASSIVE_UPDATE_SUCCESS, workspaces: workspacesUpdated });
    } catch (e) {
      return dispatch({ type: WORKSPACE_MASSIVE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function moreStatusChange (value) {
  return (dispatch) => {
    dispatch({ type: MORE_OPTIONS_STATUS_CHANGE_WORKSPACE, value });
  };
}

export function setModalAssignUsersStatus (value) {
  return async (dispatch, getState) => {
    const { workspacesSelected, workspaceList, workspacesForListView, contextShowGridView } = getState().workspace;

    try {
      const workspacesUpdated = contextShowGridView ? workspaceList : workspacesForListView;

      workspacesSelected.forEach(async (ws, indexP) => {
        const index = workspacesUpdated.findIndex((workspace) => workspace.id === ws.id);
        const wsCopy = ws;

        if (!ws.users) {
          const workspaceData = await api.workspace.getWsStats(ws.name);
          wsCopy.users = workspaceData.users;
          workspacesUpdated[index] = wsCopy;
        }

        if (indexP + 1 === workspacesSelected.length) {
          dispatch({ type: CONTEXT_WORKSPACE_MASSIVE_UPDATE_SUCCESS, workspaces: workspacesUpdated });
          return dispatch({ type: SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE, value });
        }
        return dispatch({ type: '' });
      });

      return dispatch({ type: '' });
    } catch (e) {
      return dispatch({ type: WORKSPACE_MASSIVE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function massiveUpdateUsers (selectedUsers) {
  return async (dispatch, getState) => {
    const { workspacesSelected } = getState().workspace;
    const showGridView = selectShowGridView(getState());
    const showHistogram = !!showGridView;

    try {
      const promises = workspacesSelected.map((ws) => api.workspace.updateWorkspace(ws.name, { users: selectedUsers }));
      await Promise.all(promises);

      dispatch(getWorkspaces(showHistogram));
      dispatch(getData(showHistogram));
      return dispatch({ type: SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE, value: false });
    } catch (e) {
      return dispatch({ type: WORKSPACE_MASSIVE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function toggleInactive (showAll) {
  return (dispatch, getState) => {
    const showGridView = selectShowGridViewContext(getState());
    const showHistogram = !!showGridView;
    dispatch({ type: TOGGLE_INACTIVE_FLAG });
    if (!showAll) dispatch(removeFiltersByKey('workspaces', 'active'));
    dispatch(getData(showHistogram));
  };
}

export function workspaceSelectedFromTable (ws) {
  return async (dispatch) => {
    const workspaceName = get(ws, 'name', '');
    const vulns = get(ws, 'stats.total_vulns', 0);
    dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace: workspaceName });
    dispatch(redirect(`/feed/${workspaceName}`));
    dispatch({ type: GET_WORKSPACE_STATS_SUCCESS, data: ws });
    if (vulns === 0) dispatch(showModalImportDashboard(true));
  };
}

export function setPage (page) {
  return (dispatch, getState) => {
    const showGridView = selectShowGridViewContext(getState());
    const showHistogram = !!showGridView;
    dispatch(setPageNumber('workspaces', page));
    dispatch(getData(showHistogram));
  };
}

export function setWorkspaceFilter (string) {
  return (dispatch, getState) => {
    const showGridView = selectShowGridViewContext(getState());
    const newFilter = { name: 'name', op: 'ilike', val: `%${string}%` };
    if (string) dispatch(setFilter('workspaces', newFilter));
    else dispatch(clearFilters('workspaces'));
    dispatch(getData(showGridView));
  };
}

export function setOrderByWorkspaces (sorting) {
  return (dispatch, getState) => {
    const state = getState();
    const isFetchingPreview = selectIsFetchingPreview(state);
    dispatch(setOrderBy('workspaces', sorting));
    if (!isFetchingPreview) dispatch(getData(false));
  };
}

export function toogleGridView (showGridView) {
  return (dispatch) => {
    dispatch({
      type: WORKSPACE_SHOW_GRID_VIEW, showGridView
    });
  };
}

export function setField (field, value) {
  return (dispatch) => {
    dispatch({
      type: SET_FIELD, field, value
    });
  };
}

export function updateWS (ws, value) {
  return async (dispatch, getState) => {
    try {
      const newWs = await api.workspace.updateWorkspace(ws, value);
      const { contextShowGridView } = getState().workspace;

      dispatch({ type: CONTEXT_WORKSPACE_UPDATE_SUCCESS, ws: newWs });
      if (contextShowGridView) dispatch(getData());
    } catch (e) {
      dispatch({ type: CONTEXT_WORKSPACE_CREATE_UPDATE_FAIL, data: 'An error has occurred.' });
    }
  };
}

const addDeleteController = (workspaces, workspacesList) => { // if all hosts are in hostList then unselect then if not
  const allWorkspacesAreSelected = workspaces.every((testWs) => workspacesList.some((ws) => ws._id === testWs._id));

  if (allWorkspacesAreSelected) return [WORKSPACE_UNSELECTED, workspaces];
  return [CONTEXT_WORKSPACE_SELECTED, workspaces];
};

const selectCalculator = (e, ws, workspacesList, areWorkspacesSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = workspacesList.findIndex((el) => el._id === ws._id);
  const workspacesSelected = selectWorkspacesSelected(getState());

  dispatch({ type: NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areWorkspacesSelected) {
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const workspaces = workspacesList.slice(start, end);
    const [type, payload] = addDeleteController(workspaces, workspacesSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([ws], workspacesSelected);
  return dispatch({ type, payload });
};

export const selectRow = (e, ws) => (dispatch, getState) => {
  const allWorkspaces = selectWorkspaceList(getState());
  const workspacesSelected = selectWorkspacesSelected(getState());
  dispatch(selectCalculator(e, ws, allWorkspaces, workspacesSelected.length > 0));
};

export const unSelectAll = () => (dispatch) => dispatch({ type: UNSELECT_ALL_WORKSPACES });

export const selectAllWorkspaces = () => (dispatch, getState) => {
  const showInactive = selectShowInactive(getState());
  const workspacesSelected = selectWorkspacesSelected(getState());
  const workspacesForListView = selectWorkspacesForListView(getState());
  if (workspacesForListView.length === workspacesSelected.length) return dispatch(unSelectAll());

  if (showInactive) {
    return dispatch({ type: SELECT_ALL_WORKSPACES, workspacesForListView });
  } else {
    const activeWorkspaces = filter(workspacesForListView, { active: true });
    return dispatch({ type: SELECT_ALL_WORKSPACES, workspacesList: activeWorkspaces });
  }
};

export function redirectToWsDetail (ws) {
  return (dispatch) => {
    dispatch(redirect(`/workspaces/${ws}`));
    dispatch({ type: SET_FETCHING_PREVIEW, isFetchingPreview: true });
  };
}

export function closeDetail () {
  return (dispatch) => {
    dispatch(redirect('/workspaces'));
    dispatch({ type: SET_FETCHING_PREVIEW, isFetchingPreview: true });
  };
}

export function loadWorkspaceDetail (ws) {
  return async (dispatch) => {
    try {
      const workspace = await api.workspace.getWsStats(ws);
      dispatch({ type: SHOW_WORKSPACE_DETAIL, workspace });
    } catch (e) {
      dispatch(redirect('/workspaces'));
    }
  };
}

export function hideWorkspaceDetail () {
  return (dispatch, getState) => {
    const showWorkspaceDetail = selectShowWsDetail(getState());
    if (showWorkspaceDetail) dispatch({ type: HIDE_WORKSPACE_DETAIL });
  };
}

export function refreshWs (ws) {
  return async (dispatch) => {
    dispatch({ type: REFRESH_WS_START });
    try {
      const workspace = await api.workspace.getWsStats(ws);
      dispatch({ type: REFRESH_WS, workspace });
    } catch (e) {
      dispatch({ type: REFRESH_WS_FAIL, error: e.message || 'An error has occurred.' });
    }
  };
}

export const resetRefreshWs = () => (dispatch) => {
  return dispatch({ type: RESET_REFRESH_WS });
};

export function getWorkspaceStats (data) {
  return async (dispatch) => {
    dispatch({ type: GET_WORKSPACE_STATS_SUCCESS, data });
  };
}

export function setWsStatsError (error) {
  return (dispatch) => {
    dispatch({ type: GET_WORKSPACE_STATS_FAIL, error });
  };
}

export function getWorkspacesForSelector () {
  return async (dispatch) => {
    dispatch({ type: GET_WORKSPACES_FOR_SELECTOR_START });
    const params = {
      offset: 0,
      limit: 10,
      filters: [{ name: 'active', op: '==', val: 'true' }],
      order_by: [{ field: 'update_date', direction: 'desc' }]
    };

    try {
      const response = await api.workspace.fetchWorkspacesForSelector(params, false, true, true);
      const data = response.rows;
      return dispatch({ type: GET_WORKSPACES_FOR_SELECTOR_SUCCESS, data });
    } catch (error) {
      return dispatch({ type: GET_WORKSPACES_FOR_SELECTOR_FAIL, error });
    }
  };
}

export function getTotalVulns (wsName) {
  return async (dispatch) => {
    dispatch({ type: GET_WORKSPACE_STATS_START });

    try {
      const response = await api.workspace.getWsStats(wsName);
      dispatch(getWorkspaceStats(response));
    } catch (error) {
      dispatch(setWsStatsError(error));
    }
  };
}

export function resetError () {
  return (dispatch) => {
    dispatch({ type: RESET_ERROR_VALUE });
  };
}

export function filterWs () {
  return (dispatch, getState) => {
    const showGridView = selectShowGridViewContext(getState());
    if (showGridView) dispatch(getData());
    else dispatch(getData(false));
  };
}
