import { css, keyframes } from 'styled-components';

const fadeInKeyframes = (from = 0, to = 1) => keyframes`
  from { opacity: ${from}; }
  to { opacity: ${to}; }
`;

const fadeOutKeyframes = (from = 1, to = 0) => keyframes`
  from { opacity: ${from}; }
  to { opacity: ${to}; }
`;

const slideInKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const slideInRightKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-30px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const slideInLeftKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translate3d(30px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
export const slideInRight = css`
  animation: ${slideInRightKeyframes} 0.5s forwards ease;
`;

export const slideInLeft = css`
  animation: ${slideInLeftKeyframes} 0.5s forwards ease;
`;

export const fadeIn = (from, to) => css`
  animation: ${fadeInKeyframes(from, to)} 0.5s forwards ease;
`;

export const fadeOut = (from, to) => css`
  animation: ${fadeOutKeyframes(from, to)} 0.5s forwards ease;
`;

const spinnerKeyframes = keyframes`
  to { transform: rotate(1turn); }
`;

export const spin = css`
  animation: ${spinnerKeyframes} 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) infinite;
`;

export const extend = css`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const slideIn = css`
  animation: ${slideInKeyframes} 0.2s forwards ease-out;
`;

export const overlay = (p = {}) => css`
  position: relative;

  * {
    pointer-events: none;
  }

  &::before {
    ${fadeIn}
    ${extend}
    content: '';
    position: absolute;
    background: ${p.background || 'hsla(0, 0%, 100%, 0.8)'};
    z-index: 1;
  }
`;

export const spinner = (p = {}) => css`
  ${overlay(p)}

  &::after {
    ${fadeIn};
    ${spin};
    content: '';
    position: absolute;
    left: calc(50% - ${p.diameter || '30px'} / 2);
    top: calc(50% - ${p.diameter || '30px'} / 2);
    width: ${p.diameter || '30px'};
    height: ${p.diameter || '30px'};
    border-radius: 50%;
    border: ${p.border || '2px'} solid ${p.foreground || 'hsla(0, 0%, 0%, 0.25)'};
    border-top-color: transparent;
    z-index: 2;
  }
`;

export const simpleSpinner = (p = {}) => css`
  &::after {
    ${fadeIn};
    ${spin};
    content: '';
    left: calc(50% - ${p.diameter || '30px'} / 2);
    top: calc(50% - ${p.diameter || '30px'} / 2);
    width: ${p.width || '30px'};
    height: ${p.with || '30px'};
    border-radius: 50%;
    border: ${p.border || '2px'} solid ${p.foreground || 'hsla(0, 0%, 0%, 0.25)'};
    border-top-color: transparent;
    z-index: 2;
    position: absolute;
  }
`;
