import styled from 'styled-components';
import { fadeIn } from 'Styles/effects';
import colors from 'Styles/colors';

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(25, 25, 25, 0.4);
  z-index: 500;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  & > :first-child {
    box-shadow: 18px 43px 50px 0 rgba(167, 167, 167, 0.5);
    border-radius: 2px 2px 0 0;
    overflow: auto;
    max-height: 85%;
    background: ${colors.white};
  }
  ${fadeIn(0, 1)}
`;
ModalWrapper.displayName = 'ModalWrapper';

export default ModalWrapper;
