import styled from 'styled-components';
import colors from 'Styles/colors';
import { Default } from 'Common/Components/Button/styled';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  justify-content: right;
  justify-content: space-between;
  margin-bottom: 19px;
`;
Wrapper.displayName = 'Wrapper';

export const CancelButton = styled(Default)`
  color: ${colors.darkBlueGrey};
  border: 1px solid ${colors.greyBlue};
  ${({ showNewUI }) => showNewUI && `
    margin-left: auto;
  `}
`;
CancelButton.displayName = 'CancelButton';
