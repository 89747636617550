import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContextMenu, MenuItem
} from 'react-contextmenu';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Filters from 'Images/shape.svg';
import Tag from 'Images/icon-tag.svg';
import Edit from 'Images/icon-edit-enabled.svg';
import { MdKeyboardArrowRight as Arrow } from 'react-icons/md';
import {
  moreStatusChange, update2faBulk, updateRoleBulk, saveUserWorkspaces
} from 'Screens/Users/actions/Actions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import {
  FEATURE_2FA, FEATURE_USERS, FEATURE_WORKSPACES, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { selectUserSelected } from 'store/Users/selectors';
import { selectUserName } from 'store/Faraday/selectors';
import find from 'lodash/find';
import SubMenuWorkspaces from './SubMenuWorkspaces';
import {
  Wrapper, IconActiveInactive, MenuItemContent, Text, SubMenuUsers, ArrowIcon, RoleText, SubMenuRol
} from './styled';

const ContextMenuUsersMassiveUpdate = ({
  onShow, trigger, onHide, disabled
}) => {
  const dispatch = useDispatch();
  const [showing, setShowing] = useState(false);
  const [workspaceSelected, setWorkspaces] = useState([]);
  const props = useSelector((state) => ({
    assignWorkspacesIsAllowed: selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_WORKSPACES, PERMISSION_TYPES.PATCH),
    changeRoleIsAllowed: selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_USERS, PERMISSION_TYPES.PATCH),
    twoFaIsAllowed: selectAllowedByRole(state, FEATURE_2FA, PERMISSION_TYPES.CREATE) && selectAllowedByFeature(state, FEATURE_2FA, PERMISSION_TYPES.CREATE),
    userName: selectUserName(state),
    userSelected: selectUserSelected(state)
  }));

  const disabledRol = () => (find(props.userSelected, { username: props.userName }));

  return (
    <ContextMenu id={ trigger } onHide={ () => { dispatch(saveUserWorkspaces(workspaceSelected)); setShowing(false); dispatch(moreStatusChange(false)); onHide(); } } onShow={ () => { setShowing(true); onShow(true); } }>
      { (!disabled) && (
      <Wrapper>
        { props.assignWorkspacesIsAllowed && (
        <SubMenuUsers
          preventCloseOnClick
          title={ (
            <MenuItemContent>
              <IconActiveInactive src={ Filters } />
              <Text><FormattedMessage id="users.contextMenu.assignWorkspaces" /></Text>
              <ArrowIcon><Arrow /></ArrowIcon>
            </MenuItemContent>
          ) }
        >
          <SubMenuWorkspaces showing={ showing } setWorkspaces={ setWorkspaces } />
        </SubMenuUsers>
        ) }
        { props.twoFaIsAllowed && (
        <MenuItem onClick={ () => { dispatch(update2faBulk()); } }>
          <MenuItemContent>
            <IconActiveInactive src={ Edit } />
            <Text><FormattedMessage id="users.contextMenu.enableDisable2fa" /></Text>
          </MenuItemContent>
        </MenuItem>
        ) }
        { props.changeRoleIsAllowed && !disabledRol() && (
        <SubMenuRol title={ (
          <MenuItemContent>
            <IconActiveInactive src={ Tag } />
            <Text><FormattedMessage id="users.contextMenu.changeRole" /></Text>
            <ArrowIcon><Arrow /></ArrowIcon>
          </MenuItemContent>
          ) }
        >
          <MenuItem onClick={ () => { dispatch(updateRoleBulk('admin')); } }><RoleText role="admin"><FormattedMessage id="admin" /></RoleText></MenuItem>
          <MenuItem onClick={ () => { dispatch(updateRoleBulk('pentester')); } }><RoleText role="pentester"><FormattedMessage id="pentester" /></RoleText></MenuItem>
          <MenuItem onClick={ () => { dispatch(updateRoleBulk('asset_owner')); } }><RoleText role="asset_owner"><FormattedMessage id="asset_owner" /></RoleText></MenuItem>
          <MenuItem onClick={ () => { dispatch(updateRoleBulk('client')); } }><RoleText role="client"><FormattedMessage id="client" /></RoleText></MenuItem>
        </SubMenuRol>
        ) }
      </Wrapper>
      ) }
    </ContextMenu>
  );
};

ContextMenuUsersMassiveUpdate.prototypes = {
  onHide: PropTypes.func.isRequired,
  trigger: PropTypes.string.isRequired
};

export default ContextMenuUsersMassiveUpdate;
