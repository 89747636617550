import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 27px;
    margin-right: 18px;
    >:nth-child(1){
        height: 34px;
    }
    opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
`;
Wrapper.displayName = 'Wrapper';

export const ParseErrorLabel = styled.div`
  height: 17px;
  color: ${colors.red1};
  font-size: 11.5px;
  text-align: left;
`;
ParseErrorLabel.displayName = 'ParseErrorLabel';
