import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateVulnTemplate } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { selectVulnTemplateDetail } from 'store/KnowledgeBase/selectors';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { Wrapper } from './styled';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULN_TEMPLATE, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const TAB = 'technical';

const TechnicalTab = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const vulnTemplate = useSelector(selectVulnTemplateDetail);
  const data = get(vulnTemplate, 'data', '');
  const id = get(vulnTemplate, '_id', 0);
  const isExpandedData = useExpandable(TAB, 'data', data);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULN_TEMPLATE, PERMISSION_TYPES.UPDATE));

  return (
    <Wrapper>
      <ExpandableWrapper defaultValue={ isExpandedData } id="data" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.data' }) } tab={ TAB }>
        <MarkdownEditor
          value={ data }
          onBlur={ (value) => dispatch(updateVulnTemplate(id, { data: value })) }
          disabled={ !canUpdate }
        />
      </ExpandableWrapper>
    </Wrapper>
  );
};

export default TechnicalTab;
