import styled from 'styled-components';
import colors from 'Styles/colors';

const sevColor = {
  C: colors.warmPurple,
  H: colors.redPink,
  M: colors.tangerine,
  L: colors.apple,
  I: colors.grey2,
  U: colors.grey4,
  disable: '#d6d6d6'
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 200px;
  padding: 20px;
  border: solid 1px #d9e4ef;
  background-color: #fff;
  margin: 0 0 auto 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  padding-bottom: 6px;
  font-size: 13px;
  font-weight: 500;
  color: #63758d;
`;
Title.displayName = 'Title';

export const Item = styled.div`
font-size: 12px;
color: #63758d;
display: flex;
align-items: center;
padding: 6px;
&::before {
  content: "";
  background-color:${({ type }) => sevColor[type]};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
`;
Item.displayName = 'Item';
