import styled from 'styled-components';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 12.5px;
  font-stretch: normal;
  line-height: 2.08;
  opacity: 1;
  font-weight: 400;
  color: #afb1bc;
  margin-bottom: 0px;
  margin-left: 0px;
`;
Title.displayName = 'Title';

export const CodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;
CodeWrapper.displayName = 'CodeWrapper';

export const Code = styled.input.attrs(({
  maxLength: 1,
  size: 1,
  type: 'password'
}))`
  display: inline-block;
  width: 100%;
  max-width: 34px;
  height: 34px;
  border: 1px solid transparent;
  box-shadow: 0 1px 0 0 #afafaf;
  border-bottom: none;
  background-color: #edf2f7;
  font-size: 14.5px;
  font-weight: 300;
  line-height: 2.48;
  color: #1c2243;
  border-radius: 3px;
  text-align: center;
`;
Code.displayName = 'Code';
