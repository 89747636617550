/* eslint-disable no-param-reassign */
const parseUrl = (string, prop) => {
  const a = document.createElement('a');
  a.setAttribute('href', string);
  return a[prop];
};
export default parseUrl;

export const normalizeProjects = (projectsObject) => {
  const projectList = Object.keys(projectsObject).map((project) => ({
    ...projectsObject[project],
    label: projectsObject[project].name,
    value: project
  }));

  return projectList;
};

// export const normalizeProjects = (projectsObject) => {
//   const projectList = Object.keys(projectsObject).map((project) => projectsObject[project]);
//   const normalizedProjects = projectList.map((project) => {
//     project.desc = project.name;
//     return project;
//   });

//   return normalizedProjects;
// };

const ObjectToArray = (data) => Object.keys(data).map((key) => ({ ...data[key], key }));

const continueParsing = (data) => {
  const parsedData = data.map((item) => {
    const { key, ...filteredData } = item;
    const options = ObjectToArray(filteredData);
    if (options.length) return { options, key };
    return false;
  });
  return parsedData.filter((field) => field);
};

export const parseProjectData = (project) => {
  project.issue_types = ObjectToArray(project.issue_types);
  project.issue_types.forEach((issueType) => {
    issueType.fields = ObjectToArray(issueType.fields);
    issueType.fields = continueParsing(issueType.fields);
  });
  return project;
};

export const parseProjectListData = (data) => {
  const projectList = ObjectToArray(data);
  projectList.forEach(parseProjectData);
  return projectList;
};

export const parseToLabel = (issueTypes) => issueTypes.map((issueType) => ({
  ...issueType,
  label: issueType.name || issueType.key
}));

export const parseToValue = (issueTypes) => issueTypes.map((issueType) => {
  const { label, ...data } = issueType;
  return {
    value: { ...data },
    label
  };
});

export const parseIssueField = (issueField) => {
  const issueFieldToEdit = issueField.filter((field) => field.key !== 'insight_custom_fields');
  const fieldWithLabel = parseToLabel(issueFieldToEdit);
  const parsedFields = fieldWithLabel.map((field) => ({ ...field, options: parseToLabel(field.options) }));
  return parsedFields;
};

export const parseIssueType = (issueType) => {
  const issueTypeList = ObjectToArray(issueType);
  const issueTypeToEdit = issueTypeList.find((field) => field.selected);
  if (!issueTypeToEdit) return null;
  issueTypeToEdit.fields = ObjectToArray(issueTypeToEdit.fields);
  // issueTypeToEdit.fields = continueParsing(issueTypeToEdit.fields);
  return issueTypeToEdit;
};

export const mockProjectData = {
  AX: {
    id: '10000',
    issue_types: {
      Task: {
        description: 'A task that needs to be done.',
        fields: {
          custom_fields: {},
          insight_custom_fields: {},
          standard_fields: {
            assignee: {
              key: 'assignee',
              name: 'Assignee',
              options: ['faraday@faradaysec.com'],
              required: false,
              schema: {
                items: '',
                type: 'user'
              },
              value: {
                name: ''
              }
            },
            duedate: {
              key: 'duedate',
              name: 'Due Date',
              required: false,
              schema: {
                items: '',
                type: 'date'
              },
              value: ''
            },
            labels: {
              key: 'labels',
              name: 'Labels',
              required: false,
              schema: {
                items: 'string',
                type: 'array'
              },
              value: []
            },
            priority: {
              key: 'priority',
              name: 'Priority',
              options: [
                {
                  1: 'Highest'
                },
                {
                  2: 'High'
                },
                {
                  3: 'Medium'
                },
                {
                  4: 'Low'
                },
                {
                  5: 'Lowest'
                }
              ],
              required: false,
              schema: {
                items: '',
                type: 'priority'
              },
              value: {
                id: ''
              }
            },
            reporter: {
              key: 'reporter',
              name: 'Reporter',
              options: ['faraday@faradaysec.com'],
              required: false,
              schema: {
                items: '',
                type: 'user'
              },
              value: {
                name: ''
              }
            }
          }
        },
        id: '10003'
      }
    },
    name: 'AXEL-Project-Management',
    ticket_config: {
      description: '',
      issue_type: '',
      issue_types: {
        Task: {
          fields: {},
          selected: false
        }
      },
      summary: ''
    }
  },
  FAR:
  {
    id: '10000',
    issue_types:
  {
    Bug: {
      description: 'A problem which impairs or prevents the functions of the product.',
      fields: {
        custom_fields: {
          customfield_10107: {
            key: 'customfield_10107',
            name: 'Labels (CF)',
            required: false,
            schema: { custom: 'labels', items: 'string', type: 'array' },
            value: []
          },
          customfield_10108: {
            key: 'customfield_10108',
            name: 'DatePickerField (CF)',
            required: false,
            schema: { custom: 'datepicker', items: '', type: 'date' },
            value: ''
          },
          customfield_10200: {
            key: 'customfield_10200',
            name: 'ProjectPicker (CF)',
            options: ['FAR', 'FAR2', 'FAR3'],
            required: true,
            schema: { custom: 'project', items: '', type: 'project' },
            value: { key: '' }
          },
          customfield_10300: {
            key: 'customfield_10300',
            name: 'Number (CF)',
            required: true,
            schema: { custom: 'float', items: '', type: 'number' },
            value: null
          },
          customfield_10301: {
            key: 'customfield_10301',
            name: 'CascadingSelectField (CF) ',
            options_cascading: [
              { children: ['Merida', 'Caracas', 'Valencia'], parent: 'Venezuela' },
              { children: ['Buenos Aires', 'Rosario'], parent: 'Argentina' }
            ],
            required: false,
            schema: { custom: 'cascadingselect', items: '', type: 'option-with-child' },
            value: {
              child: { value: '' },
              value: ''
            }
          },
          customfield_10400: {
            key: 'customfield_10400',
            name: 'SingleVersionPicker (CF)',
            options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
            required: true,
            schema: { custom: 'version', items: '', type: 'version' },
            value: { name: '' }
          },
          customfield_10401: {
            key: 'customfield_10401',
            name: 'DateTimeField',
            required: false,
            schema: { custom: 'datetime', items: '', type: 'datetime' },
            value: ''
          },
          customfield_10402: {
            key: 'customfield_10402',
            name: 'TextField (CF)',
            required: false,
            schema: { custom: 'textarea', items: '', type: 'string' },
            value: ''
          },
          customfield_10403: {
            key: 'customfield_10403',
            name: 'GroupPicker (CF)',
            options: ['jira-administrators', 'jira-software-users'],
            required: false,
            schema: { custom: 'grouppicker', items: '', type: 'group' },
            value: { name: '' }
          },
          customfield_10404: {
            key: 'customfield_10404',
            name: 'MultiGroupPicker (CF)',
            options: ['jira-administrators', 'jira-software-users'],
            required: false,
            schema: { custom: 'multigrouppicker', items: 'group', type: 'array' },
            value: []
          },
          customfield_10405: {
            key: 'customfield_10405',
            name: 'SelectList (CF)',
            options: ['option1', 'option2', 'option3'],
            required: false,
            schema: { custom: 'select', items: '', type: 'option' },
            value: { value: '' }
          },
          customfield_10406: {
            key: 'customfield_10406',
            name: 'MultiSelect (CF)',
            options: ['multi1', 'multi2', 'multi3', 'multi4'],
            required: false,
            schema: { custom: 'multiselect', items: 'option', type: 'array' },
            value: []
          },
          customfield_10407: {
            key: 'customfield_10407',
            name: 'UserPicker (CF)',
            options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
            required: false,
            schema: { custom: 'userpicker', items: '', type: 'user' },
            value: { name: '' }
          },
          customfield_10408: {
            key: 'customfield_10408',
            name: 'MultiUserPicker (CF)',
            options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
            required: false,
            schema: { custom: 'multiuserpicker', items: 'user', type: 'array' },
            value: []
          },
          customfield_10409: {
            key: 'customfield_10409',
            name: 'RadioButtons (CF)',
            options: ['radio1', 'radio2', 'radio3'],
            required: false,
            schema: { custom: 'radiobuttons', items: '', type: 'option' },
            value: { value: '' }
          },
          customfield_10410: {
            key: 'customfield_10410',
            name: 'VersionPicker (CF)',
            options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
            required: false,
            schema: { custom: 'multiversion', items: 'version', type: 'array' },
            value: []
          },
          customfield_10411: {
            key: 'customfield_10411',
            name: 'URLField (CF)',
            required: false,
            schema: { custom: 'url', items: '', type: 'string' },
            value: ''
          },
          customfield_10412: {
            key: 'customfield_10412',
            name: 'Checkbox (CF)',
            options: ['Box1', 'Box2', 'Box3'],
            required: false,
            schema: { custom: 'multicheckboxes', items: 'option', type: 'array' },
            value: []
          }
        },
        insight_custom_fields:
      {
        customfield_10413:
      {
        iql_string: '',
        key: 'customfield_10413',
        name: 'InsightObject (CF)',
        options: [],
        required: false,
        schema:
      { custom: 'com.riadalabs.jira.plugins.insight:rlabs-customfield-default-object', items: '', type: 'any' },
        value: []
      }
      },
        standard_fields:
      {
        assignee:
      {
        key: 'assignee',
        name: 'Assignee',
        options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
        required: false,
        schema:
      { items: '', type: 'user' },
        value:
      { name: '' }
      },
        attachment:
      {
        key: 'attachment',
        name: 'Attachment',
        required: false,
        schema:
      { items: 'attachment', type: 'array' },
        value: []
      },
        components:
      {
        key: 'components',
        name: 'Component/s',
        options: ['Component1', 'Component2', 'Component3', 'Component4'],
        required: false,
        schema:
      { items: 'component', type: 'array' },
        value: []
      },
        environment:
      {
        key: 'environment',
        name: 'Environment',
        required: false,
        schema:
      { items: '', type: 'string' },
        value: ''
      },
        fixVersions:
      {
        key: 'fixVersions',
        name: 'Fix Version/s',
        options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
        required: false,
        schema:
      { items: 'version', type: 'array' },
        value: []
      },
        labels:
      {
        key: 'labels',
        name: 'Labels',
        required: false,
        schema:
      { items: 'string', type: 'array' },
        value: []
      },
        priority:
      {
        key: 'priority',
        name: 'Priority',
        options: [
          { 1: 'Highest' },
          { 2: 'High' },
          { 3: 'Medium' },
          { 4: 'Low' },
          { 5: 'Lowest' }],
        required: false,
        schema:
      { items: '', type: 'priority' },
        value:
      { id: '' }
      },
        reporter:
      {
        key: 'reporter',
        name: 'Reporter',
        options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
        required: false,
        schema:
      { items: '', type: 'user' },
        value:
      { name: '' }
      },
        security:
      {
        key: 'security',
        name: 'Security Level',
        options: [
          { 10000: 'level 1' },
          { 10001: 'level 2' },
          { 10002: 'level 3' },
          { 10003: 'level 4' },
          { 10004: 'level 5' }],
        required: false,
        schema:
      { items: '', type: 'securitylevel' },
        value:
      { id: '' }
      },
        versions:
      {
        key: 'versions',
        name: 'Affects Version/s',
        options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
        required: false,
        schema:
      { items: 'version', type: 'array' },
        value: []
      }
      }
      },
      id: '10004'
    },
    Story:
  {
    description: 'Created by Jira Software - do not edit or delete. Issue type for a user story.',
    fields:
  {
    custom_fields:
  {
    customfield_10107:
  {
    key: 'customfield_10107',
    name: 'Labels (CF)',
    required: false,
    schema:
  { custom: 'labels', items: 'string', type: 'array' },
    value: []
  },
    customfield_10108:
  {
    key: 'customfield_10108',
    name: 'DatePickerField (CF)',
    required: false,
    schema:
  { custom: 'datepicker', items: '', type: 'date' },
    value: ''
  },
    customfield_10200:
  {
    key: 'customfield_10200',
    name: 'ProjectPicker (CF)',
    options: ['FAR', 'FAR2', 'FAR3'],
    required: false,
    schema:
  { custom: 'project', items: '', type: 'project' },
    value:
  { key: '' }
  },
    customfield_10300:
  {
    key: 'customfield_10300',
    name: 'Number (CF)',
    required: true,
    schema:
  { custom: 'float', items: '', type: 'number' },
    value: null
  },
    customfield_10301:
  {
    key: 'customfield_10301',
    name: 'CascadingSelectField (CF) ',
    options_cascading: [
      { children: ['Merida', 'Caracas', 'Valencia'], parent: 'Venezuela' },
      { children: ['Buenos Aires', 'Rosario'], parent: 'Argentina' }],
    required: false,
    schema:
  { custom: 'cascadingselect', items: '', type: 'option-with-child' },
    value:
  {
    child:

  { value: '' },
    value: ''
  }
  },
    customfield_10400:
  {
    key: 'customfield_10400',
    name: 'SingleVersionPicker (CF)',
    options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
    required: false,
    schema:
  { custom: 'version', items: '', type: 'version' },
    value:
  { name: '' }
  },
    customfield_10401:
  {
    key: 'customfield_10401',
    name: 'DateTimeField',
    required: false,
    schema:
  { custom: 'datetime', items: '', type: 'datetime' },
    value: ''
  },
    customfield_10402:
  {
    key: 'customfield_10402',
    name: 'TextField (CF)',
    required: false,
    schema:
  { custom: 'textarea', items: '', type: 'string' },
    value: ''
  },
    customfield_10403:
  {
    key: 'customfield_10403',
    name: 'GroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'grouppicker', items: '', type: 'group' },
    value:
  { name: '' }
  },
    customfield_10404:
  {
    key: 'customfield_10404',
    name: 'MultiGroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'multigrouppicker', items: 'group', type: 'array' },
    value: []
  },
    customfield_10405:
  {
    key: 'customfield_10405',
    name: 'SelectList (CF)',
    options: ['option1', 'option2', 'option3'],
    required: false,
    schema:
  { custom: 'select', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10406:
  {
    key: 'customfield_10406',
    name: 'MultiSelect (CF)',
    options: ['multi1', 'multi2', 'multi3', 'multi4'],
    required: false,
    schema:
  { custom: 'multiselect', items: 'option', type: 'array' },
    value: []
  },
    customfield_10407:
  {
    key: 'customfield_10407',
    name: 'UserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'userpicker', items: '', type: 'user' },
    value:
  { name: '' }
  },
    customfield_10408:
  {
    key: 'customfield_10408',
    name: 'MultiUserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'multiuserpicker', items: 'user', type: 'array' },
    value: []
  },
    customfield_10409:
  {
    key: 'customfield_10409',
    name: 'RadioButtons (CF)',
    options: ['radio1', 'radio2', 'radio3'],
    required: false,
    schema:
  { custom: 'radiobuttons', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10410:
  {
    key: 'customfield_10410',
    name: 'VersionPicker (CF)',
    options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
    required: false,
    schema:
  { custom: 'multiversion', items: 'version', type: 'array' },
    value: []
  },
    customfield_10411:
  {
    key: 'customfield_10411',
    name: 'URLField (CF)',
    required: false,
    schema:
  { custom: 'url', items: '', type: 'string' },
    value: ''
  },
    customfield_10412:
  {
    key: 'customfield_10412',
    name: 'Checkbox (CF)',
    options: ['Box1', 'Box2', 'Box3'],
    required: false,
    schema:
  { custom: 'multicheckboxes', items: 'option', type: 'array' },
    value: []
  }
  },
    insight_custom_fields:
  {
    customfield_10413:
  {
    iql_string: '',
    key: 'customfield_10413',
    name: 'InsightObject (CF)',
    options: [],
    required: false,
    schema:
  { custom: 'com.riadalabs.jira.plugins.insight:rlabs-customfield-default-object', items: '', type: 'any' },
    value: []
  }
  },
    standard_fields:
  {
    assignee:
  {
    key: 'assignee',
    name: 'Assignee',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    attachment:
  {
    key: 'attachment',
    name: 'Attachment',
    required: false,
    schema:
  { items: 'attachment', type: 'array' },
    value: []
  },
    components:
  {
    key: 'components',
    name: 'Component/s',
    options: ['Component1', 'Component2', 'Component3', 'Component4'],
    required: false,
    schema:
  { items: 'component', type: 'array' },
    value: []
  },
    fixVersions:
  {
    key: 'fixVersions',
    name: 'Fix Version/s',
    options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
    required: false,
    schema:
  { items: 'version', type: 'array' },
    value: []
  },
    labels:
  {
    key: 'labels',
    name: 'Labels',
    required: false,
    schema:
  { items: 'string', type: 'array' },
    value: []
  },
    priority:
  {
    key: 'priority',
    name: 'Priority',
    options: [
      { 1: 'Highest' },
      { 2: 'High' },
      { 3: 'Medium' },
      { 4: 'Low' },
      { 5: 'Lowest' }],
    required: false,
    schema:
  { items: '', type: 'priority' },
    value:
  { id: '' }
  },
    reporter:
  {
    key: 'reporter',
    name: 'Reporter',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    security:
  {
    key: 'security',
    name: 'Security Level',
    options: [
      { 10000: 'level 1' },
      { 10001: 'level 2' },
      { 10002: 'level 3' },
      { 10003: 'level 4' },
      { 10004: 'level 5' }],
    required: false,
    schema:
  { items: '', type: 'securitylevel' },
    value:
  { id: '' }
  }
  }
  },
    id: '10001'
  },
    Task:
  {
    description: 'A task that needs to be done.',
    fields:
  {
    custom_fields:
  {
    customfield_10107:
  {
    key: 'customfield_10107',
    name: 'Labels (CF)',
    required: false,
    schema:
  { custom: 'labels', items: 'string', type: 'array' },
    value: []
  },
    customfield_10108:
  {
    key: 'customfield_10108',
    name: 'DatePickerField (CF)',
    required: false,
    schema:
  { custom: 'datepicker', items: '', type: 'date' },
    value: ''
  },
    customfield_10200:
  {
    key: 'customfield_10200',
    name: 'ProjectPicker (CF)',
    options: ['FAR', 'FAR2', 'FAR3'],
    required: false,
    schema:
  { custom: 'project', items: '', type: 'project' },
    value:
  { key: '' }
  },
    customfield_10300:
  {
    key: 'customfield_10300',
    name: 'Number (CF)',
    required: true,
    schema:
  { custom: 'float', items: '', type: 'number' },
    value: null
  },
    customfield_10301:
  {
    key: 'customfield_10301',
    name: 'CascadingSelectField (CF) ',
    options_cascading: [
      { children: ['Merida', 'Caracas', 'Valencia'], parent: 'Venezuela' },
      { children: ['Buenos Aires', 'Rosario'], parent: 'Argentina' }],
    required: false,
    schema:
  { custom: 'cascadingselect', items: '', type: 'option-with-child' },
    value:
  {
    child:
  { value: '' },
    value: ''
  }
  },
    customfield_10400:
  {
    key: 'customfield_10400',
    name: 'SingleVersionPicker (CF)',
    options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
    required: false,
    schema:
  { custom: 'version', items: '', type: 'version' },
    value:
  { name: '' }
  },
    customfield_10401:
  {
    key: 'customfield_10401',
    name: 'DateTimeField',
    required: false,
    schema:
  { custom: 'datetime', items: '', type: 'datetime' },
    value: ''
  },
    customfield_10402:
  {
    key: 'customfield_10402',
    name: 'TextField (CF)',
    required: false,
    schema:
  { custom: 'textarea', items: '', type: 'string' },
    value: ''
  },
    customfield_10403:
  {
    key: 'customfield_10403',
    name: 'GroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'grouppicker', items: '', type: 'group' },
    value:
  { name: '' }
  },
    customfield_10404:
  {
    key: 'customfield_10404',
    name: 'MultiGroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'multigrouppicker', items: 'group', type: 'array' },
    value: []
  },
    customfield_10405:
  {
    key: 'customfield_10405',
    name: 'SelectList (CF)',
    options: ['option1', 'option2', 'option3'],
    required: false,
    schema:
  { custom: 'select', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10406:
  {
    key: 'customfield_10406',
    name: 'MultiSelect (CF)',
    options: ['multi1', 'multi2', 'multi3', 'multi4'],
    required: false,
    schema:
  { custom: 'multiselect', items: 'option', type: 'array' },
    value: []
  },
    customfield_10407:
  {
    key: 'customfield_10407',
    name: 'UserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'userpicker', items: '', type: 'user' },
    value:
  { name: '' }
  },
    customfield_10408:
  {
    key: 'customfield_10408',
    name: 'MultiUserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'multiuserpicker', items: 'user', type: 'array' },
    value: []
  },
    customfield_10409:
  {
    key: 'customfield_10409',
    name: 'RadioButtons (CF)',
    options: ['radio1', 'radio2', 'radio3'],
    required: false,
    schema:
  { custom: 'radiobuttons', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10410:
  {
    key: 'customfield_10410',
    name: 'VersionPicker (CF)',
    options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
    required: false,
    schema:
  { custom: 'multiversion', items: 'version', type: 'array' },
    value: []
  },
    customfield_10411:
  {
    key: 'customfield_10411',
    name: 'URLField (CF)',
    required: false,
    schema:
  { custom: 'url', items: '', type: 'string' },
    value: ''
  },
    customfield_10412:
  {
    key: 'customfield_10412',
    name: 'Checkbox (CF)',
    options: ['Box1', 'Box2', 'Box3'],
    required: false,
    schema:
  { custom: 'multicheckboxes', items: 'option', type: 'array' },
    value: []
  }
  },
    insight_custom_fields:
  {
    customfield_10413:
  {
    iql_string: '',
    key: 'customfield_10413',
    name: 'InsightObject (CF)',
    options: [],
    required: false,
    schema:
  { custom: 'com.riadalabs.jira.plugins.insight:rlabs-customfield-default-object', items: '', type: 'any' },
    value: []
  }
  },
    standard_fields:
  {
    assignee:
  {
    key: 'assignee',
    name: 'Assignee',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    attachment:
  {
    key: 'attachment',
    name: 'Attachment',
    required: false,
    schema:
  { items: 'attachment', type: 'array' },
    value: []
  },
    components:
  {
    key: 'components',
    name: 'Component/s',
    options: ['Component1', 'Component2', 'Component3', 'Component4'],
    required: false,
    schema:
  { items: 'component', type: 'array' },
    value: []
  },
    fixVersions:
  {
    key: 'fixVersions',
    name: 'Fix Version/s',
    options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
    required: false,
    schema:
  { items: 'version', type: 'array' },
    value: []
  },
    labels:
  {
    key: 'labels',
    name: 'Labels',
    required: false,
    schema:
  { items: 'string', type: 'array' },
    value: []
  },
    priority:
  {
    key: 'priority',
    name: 'Priority',
    options: [
      { 1: 'Highest' },
      { 2: 'High' },
      { 3: 'Medium' },
      { 4: 'Low' },
      { 5: 'Lowest' }],
    required: false,
    schema:
  { items: '', type: 'priority' },
    value:
  { id: '' }
  },
    reporter:
  {
    key: 'reporter',
    name: 'Reporter',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    security:
  {
    key: 'security',
    name: 'Security Level',
    options: [
      { 10000: 'level 1' },
      { 10001: 'level 2' },
      { 10002: 'level 3' },
      { 10003: 'level 4' },
      { 10004: 'level 5' }],
    required: false,
    schema:
  { items: '', type: 'securitylevel' },
    value:
  { id: '' }
  }
  }
  },
    id: '10002'
  }
  },
    name: 'faraday',
    ticket_config:
  {
    description: '',
    issue_type: '',
    issue_types:
  {
    Bug:
  {
    fields:
  {},
    selected: false
  },
    Story:
  {
    fields:
  {},
    selected: false
  },
    Task:
  {
    fields:
  {},
    selected: false
  }
  },
    summary: ''
  }
  },
  FAR2:
  {
    id: '10001',
    issue_types:
  {
    Bug:
  {
    description: 'A problem which impairs or prevents the functions of the product.',
    fields:
  {
    custom_fields:
  {
    customfield_10200:
  {
    key: 'customfield_10200',
    name: 'ProjectPicker (CF)',
    options: ['FAR', 'FAR2', 'FAR3'],
    required: false,
    schema:
  { custom: 'project', items: '', type: 'project' },
    value:
  { key: '' }
  },
    customfield_10300:
  {
    key: 'customfield_10300',
    name: 'Number (CF)',
    required: true,
    schema:
  { custom: 'float', items: '', type: 'number' },
    value: null
  },
    customfield_10301:
  {
    key: 'customfield_10301',
    name: 'CascadingSelectField (CF) ',
    options_cascading: [
      { children: ['Merida', 'Caracas', 'Valencia'], parent: 'Venezuela' },
      { children: ['Buenos Aires', 'Rosario'], parent: 'Argentina' }],
    required: false,
    schema:
  { custom: 'cascadingselect', items: '', type: 'option-with-child' },
    value:
  {
    child:
  { value: '' },
    value: ''
  }
  },
    customfield_10400:
  {
    key: 'customfield_10400',
    name: 'SingleVersionPicker (CF)',
    options: ['1.0'],
    required: false,
    schema:
  { custom: 'version', items: '', type: 'version' },
    value:
  { name: '' }
  },
    customfield_10401:
  {
    key: 'customfield_10401',
    name: 'DateTimeField',
    required: false,
    schema:
  { custom: 'datetime', items: '', type: 'datetime' },
    value: ''
  },
    customfield_10402:
  {
    key: 'customfield_10402',
    name: 'TextField (CF)',
    required: false,
    schema:
  { custom: 'textarea', items: '', type: 'string' },
    value: ''
  },
    customfield_10403:
  {
    key: 'customfield_10403',
    name: 'GroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'grouppicker', items: '', type: 'group' },
    value:
  { name: '' }
  },
    customfield_10404:
  {
    key: 'customfield_10404',
    name: 'MultiGroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'multigrouppicker', items: 'group', type: 'array' },
    value: []
  },
    customfield_10405:
  {
    key: 'customfield_10405',
    name: 'SelectList (CF)',
    options: ['option1', 'option2', 'option3'],
    required: false,
    schema:
  { custom: 'select', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10406:
  {
    key: 'customfield_10406',
    name: 'MultiSelect (CF)',
    options: ['multi1', 'multi2', 'multi3', 'multi4'],
    required: false,
    schema:
  { custom: 'multiselect', items: 'option', type: 'array' },
    value: []
  },
    customfield_10407:
  {
    key: 'customfield_10407',
    name: 'UserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'userpicker', items: '', type: 'user' },
    value:
  { name: '' }
  },
    customfield_10408:
  {
    key: 'customfield_10408',
    name: 'MultiUserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'multiuserpicker', items: 'user', type: 'array' },
    value: []
  },
    customfield_10409:
  {
    key: 'customfield_10409',
    name: 'RadioButtons (CF)',
    options: ['radio1', 'radio2', 'radio3'],
    required: false,
    schema:
  { custom: 'radiobuttons', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10410:
  {
    key: 'customfield_10410',
    name: 'VersionPicker (CF)',
    options: ['1.0'],
    required: false,
    schema:
  { custom: 'multiversion', items: 'version', type: 'array' },
    value: []
  },
    customfield_10411:
  {
    key: 'customfield_10411',
    name: 'URLField (CF)',
    required: false,
    schema:
  { custom: 'url', items: '', type: 'string' },
    value: ''
  },
    customfield_10412:
  {
    key: 'customfield_10412',
    name: 'Checkbox (CF)',
    options: ['Box1', 'Box2', 'Box3'],
    required: false,
    schema:
  { custom: 'multicheckboxes', items: 'option', type: 'array' },
    value: []
  }
  },
    insight_custom_fields:
  {
    customfield_10413:
  {
    iql_string: '',
    key: 'customfield_10413',
    name: 'InsightObject (CF)',
    options: [],
    required: false,
    schema:
  { custom: 'com.riadalabs.jira.plugins.insight:rlabs-customfield-default-object', items: '', type: 'any' },
    value: []
  }
  },
    standard_fields:
  {
    assignee:
  {
    key: 'assignee',
    name: 'Assignee',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    attachment:
  {
    key: 'attachment',
    name: 'Attachment',
    required: false,
    schema:
  { items: 'attachment', type: 'array' },
    value: []
  },
    components:
  {
    key: 'components',
    name: 'Component/s',
    required: false,
    schema:
  { items: 'component', type: 'array' },
    value: []
  },
    environment:
  {
    key: 'environment',
    name: 'Environment',
    required: false,
    schema:
  { items: '', type: 'string' },
    value: ''
  },
    fixVersions:
  {
    key: 'fixVersions',
    name: 'Fix Version/s',
    options: ['1.0'],
    required: false,
    schema:
  { items: 'version', type: 'array' },
    value: []
  },
    labels:
  {
    key: 'labels',
    name: 'Labels',
    required: false,
    schema:
  { items: 'string', type: 'array' },
    value: []
  },
    priority:
  {
    key: 'priority',
    name: 'Priority',
    options: [
      { 1: 'Highest' },
      { 2: 'High' },
      { 3: 'Medium' },
      { 4: 'Low' },
      { 5: 'Lowest' }],
    required: false,
    schema:
  { items: '', type: 'priority' },
    value:
  { id: '' }
  },
    reporter:
  {
    key: 'reporter',
    name: 'Reporter',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    versions:
  {
    key: 'versions',
    name: 'Affects Version/s',
    options: ['1.0'],
    required: false,
    schema:
  { items: 'version', type: 'array' },
    value: []
  }
  }
  },
    id: '10004'
  },
    Story:
  {
    description: 'Created by Jira Software - do not edit or delete. Issue type for a user story.',
    fields:
  {
    custom_fields:
  {
    customfield_10200:
  {
    key: 'customfield_10200',
    name: 'ProjectPicker (CF)',
    options: ['FAR', 'FAR2', 'FAR3'],
    required: false,
    schema:
  { custom: 'project', items: '', type: 'project' },
    value:
  { key: '' }
  },
    customfield_10300:
  {
    key: 'customfield_10300',
    name: 'Number (CF)',
    required: true,
    schema:
  { custom: 'float', items: '', type: 'number' },
    value: null
  },
    customfield_10301:
  {
    key: 'customfield_10301',
    name: 'CascadingSelectField (CF) ',
    options_cascading: [
      { children: ['Merida', 'Caracas', 'Valencia'], parent: 'Venezuela' },
      { children: ['Buenos Aires', 'Rosario'], parent: 'Argentina' }],
    required: false,
    schema:
  { custom: 'cascadingselect', items: '', type: 'option-with-child' },
    value:
  {
    child:
  { value: '' },
    value: ''
  }
  },
    customfield_10400:
  {
    key: 'customfield_10400',
    name: 'SingleVersionPicker (CF)',
    options: ['1.0'],
    required: false,
    schema:
  { custom: 'version', items: '', type: 'version' },
    value:
  { name: '' }
  },
    customfield_10401:
  {
    key: 'customfield_10401',
    name: 'DateTimeField',
    required: false,
    schema:
  { custom: 'datetime', items: '', type: 'datetime' },
    value: ''
  },
    customfield_10402:
  {
    key: 'customfield_10402',
    name: 'TextField (CF)',
    required: false,
    schema:
  { custom: 'textarea', items: '', type: 'string' },
    value: ''
  },
    customfield_10403:
  {
    key: 'customfield_10403',
    name: 'GroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'grouppicker', items: '', type: 'group' },
    value:
  { name: '' }
  },
    customfield_10404:
  {
    key: 'customfield_10404',
    name: 'MultiGroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'multigrouppicker', items: 'group', type: 'array' },
    value: []
  },
    customfield_10405:
  {
    key: 'customfield_10405',
    name: 'SelectList (CF)',
    options: ['option1', 'option2', 'option3'],
    required: false,
    schema:
  { custom: 'select', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10406:
  {
    key: 'customfield_10406',
    name: 'MultiSelect (CF)',
    options: ['multi1', 'multi2', 'multi3', 'multi4'],
    required: false,
    schema:
  { custom: 'multiselect', items: 'option', type: 'array' },
    value: []
  },
    customfield_10407:
  {
    key: 'customfield_10407',
    name: 'UserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'userpicker', items: '', type: 'user' },
    value:
  { name: '' }
  },
    customfield_10408:
  {
    key: 'customfield_10408',
    name: 'MultiUserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'multiuserpicker', items: 'user', type: 'array' },
    value: []
  },
    customfield_10409:
  {
    key: 'customfield_10409',
    name: 'RadioButtons (CF)',
    options: ['radio1', 'radio2', 'radio3'],
    required: false,
    schema:
  { custom: 'radiobuttons', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10410:
  {
    key: 'customfield_10410',
    name: 'VersionPicker (CF)',
    options: ['1.0'],
    required: false,
    schema:
  { custom: 'multiversion', items: 'version', type: 'array' },
    value: []
  },
    customfield_10411:
  {
    key: 'customfield_10411',
    name: 'URLField (CF)',
    required: false,
    schema:
  { custom: 'url', items: '', type: 'string' },
    value: ''
  },
    customfield_10412:
  {
    key: 'customfield_10412',
    name: 'Checkbox (CF)',
    options: ['Box1', 'Box2', 'Box3'],
    required: false,
    schema:
  { custom: 'multicheckboxes', items: 'option', type: 'array' },
    value: []
  }
  },
    insight_custom_fields:
  {
    customfield_10413:
  {
    iql_string: '',
    key: 'customfield_10413',
    name: 'InsightObject (CF)',
    options: [],
    required: false,
    schema:
  { custom: 'com.riadalabs.jira.plugins.insight:rlabs-customfield-default-object', items: '', type: 'any' },
    value: []
  }
  },
    standard_fields:
  {
    assignee:
  {
    key: 'assignee',
    name: 'Assignee',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    attachment:
  {
    key: 'attachment',
    name: 'Attachment',
    required: false,
    schema:
  { items: 'attachment', type: 'array' },
    value: []
  },
    components:
  {
    key: 'components',
    name: 'Component/s',
    required: false,
    schema:
  { items: 'component', type: 'array' },
    value: []
  },
    fixVersions:
  {
    key: 'fixVersions',
    name: 'Fix Version/s',
    options: ['1.0'],
    required: false,
    schema:
  { items: 'version', type: 'array' },
    value: []
  },
    labels:
  {
    key: 'labels',
    name: 'Labels',
    required: false,
    schema:
  { items: 'string', type: 'array' },
    value: []
  },
    priority:
  {
    key: 'priority',
    name: 'Priority',
    options: [
      { 1: 'Highest' },
      { 2: 'High' },
      { 3: 'Medium' },
      { 4: 'Low' },
      { 5: 'Lowest' }],
    required: false,
    schema:
  { items: '', type: 'priority' },
    value:
  { id: '' }
  },
    reporter:
  {
    key: 'reporter',
    name: 'Reporter',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  }
  }
  },
    id: '10001'
  },
    Task:
  {
    description: 'A task that needs to be done.',
    fields:
  {
    custom_fields:
  {
    customfield_10200:
  {
    key: 'customfield_10200',
    name: 'ProjectPicker (CF)',
    options: ['FAR', 'FAR2', 'FAR3'],
    required: false,
    schema:
  { custom: 'project', items: '', type: 'project' },
    value:
  { key: '' }
  },
    customfield_10300:
  {
    key: 'customfield_10300',
    name: 'Number (CF)',
    required: true,
    schema:
  { custom: 'float', items: '', type: 'number' },
    value: null
  },
    customfield_10301:
  {
    key: 'customfield_10301',
    name: 'CascadingSelectField (CF) ',
    options_cascading: [
      { children: ['Merida', 'Caracas', 'Valencia'], parent: 'Venezuela' },
      { children: ['Buenos Aires', 'Rosario'], parent: 'Argentina' }],
    required: false,
    schema:
  { custom: 'cascadingselect', items: '', type: 'option-with-child' },
    value:
  {
    child:
  { value: '' },
    value: ''
  }
  },
    customfield_10400:
  {
    key: 'customfield_10400',
    name: 'SingleVersionPicker (CF)',
    options: ['1.0'],
    required: false,
    schema:
  { custom: 'version', items: '', type: 'version' },
    value:
  { name: '' }
  },
    customfield_10401:
  {
    key: 'customfield_10401',
    name: 'DateTimeField',
    required: false,
    schema:
  { custom: 'datetime', items: '', type: 'datetime' },
    value: ''
  },
    customfield_10402:
  {
    key: 'customfield_10402',
    name: 'TextField (CF)',
    required: false,
    schema:
  { custom: 'textarea', items: '', type: 'string' },
    value: ''
  },
    customfield_10403:
  {
    key: 'customfield_10403',
    name: 'GroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'grouppicker', items: '', type: 'group' },
    value:
  { name: '' }
  },
    customfield_10404:
  {
    key: 'customfield_10404',
    name: 'MultiGroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'multigrouppicker', items: 'group', type: 'array' },
    value: []
  },
    customfield_10405:
  {
    key: 'customfield_10405',
    name: 'SelectList (CF)',
    options: ['option1', 'option2', 'option3'],
    required: false,
    schema:
  { custom: 'select', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10406:
  {
    key: 'customfield_10406',
    name: 'MultiSelect (CF)',
    options: ['multi1', 'multi2', 'multi3', 'multi4'],
    required: false,
    schema:
  { custom: 'multiselect', items: 'option', type: 'array' },
    value: []
  },
    customfield_10407:
  {
    key: 'customfield_10407',
    name: 'UserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'userpicker', items: '', type: 'user' },
    value:
  { name: '' }
  },
    customfield_10408:
  {
    key: 'customfield_10408',
    name: 'MultiUserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'multiuserpicker', items: 'user', type: 'array' },
    value: []
  },
    customfield_10409:
  {
    key: 'customfield_10409',
    name: 'RadioButtons (CF)',
    options: ['radio1', 'radio2', 'radio3'],
    required: false,
    schema:
  { custom: 'radiobuttons', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10410:
  {
    key: 'customfield_10410',
    name: 'VersionPicker (CF)',
    options: ['1.0'],
    required: false,
    schema:
  { custom: 'multiversion', items: 'version', type: 'array' },
    value: []
  },
    customfield_10411:
  {
    key: 'customfield_10411',
    name: 'URLField (CF)',
    required: false,
    schema:
  { custom: 'url', items: '', type: 'string' },
    value: ''
  },
    customfield_10412:
  {
    key: 'customfield_10412',
    name: 'Checkbox (CF)',
    options: ['Box1', 'Box2', 'Box3'],
    required: false,
    schema:
  { custom: 'multicheckboxes', items: 'option', type: 'array' },
    value: []
  }
  },
    insight_custom_fields:
  {
    customfield_10413:
  {
    iql_string: '',
    key: 'customfield_10413',
    name: 'InsightObject (CF)',
    options: [],
    required: false,
    schema:
  { custom: 'com.riadalabs.jira.plugins.insight:rlabs-customfield-default-object', items: '', type: 'any' },
    value: []
  }
  },
    standard_fields:
  {
    assignee:
  {
    key: 'assignee',
    name: 'Assignee',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    attachment:
  {
    key: 'attachment',
    name: 'Attachment',
    required: false,
    schema:
  { items: 'attachment', type: 'array' },
    value: []
  },
    components:
  {
    key: 'components',
    name: 'Component/s',
    required: false,
    schema:
  { items: 'component', type: 'array' },
    value: []
  },
    fixVersions:
  {
    key: 'fixVersions',
    name: 'Fix Version/s',
    options: ['1.0'],
    required: false,
    schema:
  { items: 'version', type: 'array' },
    value: []
  },
    labels:
  {
    key: 'labels',
    name: 'Labels',
    required: false,
    schema:
  { items: 'string', type: 'array' },
    value: []
  },
    priority:
  {
    key: 'priority',
    name: 'Priority',
    options: [
      { 1: 'Highest' },
      { 2: 'High' },
      { 3: 'Medium' },
      { 4: 'Low' },
      { 5: 'Lowest' }],
    required: false,
    schema:
  { items: '', type: 'priority' },
    value:
  { id: '' }
  },
    reporter:
  {
    key: 'reporter',
    name: 'Reporter',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  }
  }
  },
    id: '10002'
  }
  },
    name: 'faraday2',
    ticket_config:
  {
    description: '',
    issue_type: '',
    issue_types:
  {
    Bug:
  {
    fields:
  {},
    selected: false
  },
    Story:
  {
    fields:
  {},
    selected: false
  },
    Task:
  {
    fields:
  {},
    selected: false
  }
  },
    summary: ''
  }
  },
  FAR3:
  {
    id: '10100',
    issue_types:
  {
    'Bug':
  {
    description: 'A problem which impairs or prevents the functions of the product.',
    fields:
  {
    custom_fields:
  {
    customfield_10300:
  {
    key: 'customfield_10300',
    name: 'Number (CF)',
    required: true,
    schema:
  { custom: 'float', items: '', type: 'number' },
    value: null
  },
    customfield_10301:
  {
    key: 'customfield_10301',
    name: 'CascadingSelectField (CF) ',
    options_cascading: [
      { children: ['Merida', 'Caracas', 'Valencia'], parent: 'Venezuela' },
      { children: ['Buenos Aires', 'Rosario'], parent: 'Argentina' }],
    required: false,
    schema:
  { custom: 'cascadingselect', items: '', type: 'option-with-child' },
    value:
  {
    child:
  { value: '' },
    value: ''
  }
  },
    customfield_10400:
  {
    key: 'customfield_10400',
    name: 'SingleVersionPicker (CF)',
    required: false,
    schema:
  { custom: 'version', items: '', type: 'version' },
    value:
  { name: '' }
  },
    customfield_10401:
  {
    key: 'customfield_10401',
    name: 'DateTimeField',
    required: false,
    schema:
  { custom: 'datetime', items: '', type: 'datetime' },
    value: ''
  },
    customfield_10402:
  {
    key: 'customfield_10402',
    name: 'TextField (CF)',
    required: false,
    schema:
  { custom: 'textarea', items: '', type: 'string' },
    value: ''
  },
    customfield_10403:
  {
    key: 'customfield_10403',
    name: 'GroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'grouppicker', items: '', type: 'group' },
    value:
  { name: '' }
  },
    customfield_10404:
  {
    key: 'customfield_10404',
    name: 'MultiGroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'multigrouppicker', items: 'group', type: 'array' },
    value: []
  },
    customfield_10405:
  {
    key: 'customfield_10405',
    name: 'SelectList (CF)',
    options: ['option1', 'option2', 'option3'],
    required: false,
    schema:
  { custom: 'select', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10406:
  {
    key: 'customfield_10406',
    name: 'MultiSelect (CF)',
    options: ['multi1', 'multi2', 'multi3', 'multi4'],
    required: false,
    schema:
  { custom: 'multiselect', items: 'option', type: 'array' },
    value: []
  },
    customfield_10407:
  {
    key: 'customfield_10407',
    name: 'UserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'userpicker', items: '', type: 'user' },
    value:
  { name: '' }
  },
    customfield_10408:
  {
    key: 'customfield_10408',
    name: 'MultiUserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'multiuserpicker', items: 'user', type: 'array' },
    value: []
  },
    customfield_10409:
  {
    key: 'customfield_10409',
    name: 'RadioButtons (CF)',
    options: ['radio1', 'radio2', 'radio3'],
    required: false,
    schema:
  { custom: 'radiobuttons', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10410:
  {
    key: 'customfield_10410',
    name: 'VersionPicker (CF)',
    required: false,
    schema:
  { custom: 'multiversion', items: 'version', type: 'array' },
    value: []
  },
    customfield_10411:
  {
    key: 'customfield_10411',
    name: 'URLField (CF)',
    required: false,
    schema:
  { custom: 'url', items: '', type: 'string' },
    value: ''
  },
    customfield_10412:
  {
    key: 'customfield_10412',
    name: 'Checkbox (CF)',
    options: ['Box1', 'Box2', 'Box3'],
    required: false,
    schema:
  { custom: 'multicheckboxes', items: 'option', type: 'array' },
    value: []
  }
  },
    insight_custom_fields:
  {
    customfield_10413:
  {
    iql_string: '',
    key: 'customfield_10413',
    name: 'InsightObject (CF)',
    options: [],
    required: false,
    schema:
  { custom: 'com.riadalabs.jira.plugins.insight:rlabs-customfield-default-object', items: '', type: 'any' },
    value: []
  }
  },
    standard_fields:
  {
    assignee:
  {
    key: 'assignee',
    name: 'Assignee',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    attachment:
  {
    key: 'attachment',
    name: 'Attachment',
    required: false,
    schema:
  { items: 'attachment', type: 'array' },
    value: []
  },
    components:
  {
    key: 'components',
    name: 'Component/s',
    required: false,
    schema:
  { items: 'component', type: 'array' },
    value: []
  },
    duedate:
  {
    key: 'duedate',
    name: 'Due Date',
    required: false,
    schema:
  { items: '', type: 'date' },
    value: ''
  },
    environment:
  {
    key: 'environment',
    name: 'Environment',
    required: false,
    schema:
  { items: '', type: 'string' },
    value: ''
  },
    fixVersions:
  {
    key: 'fixVersions',
    name: 'Fix Version/s',
    required: false,
    schema:
  { items: 'version', type: 'array' },
    value: []
  },
    labels:
  {
    key: 'labels',
    name: 'Labels',
    required: false,
    schema:
  { items: 'string', type: 'array' },
    value: []
  },
    priority:
  {
    key: 'priority',
    name: 'Priority',
    options: [
      { 1: 'Highest' },
      { 2: 'High' },
      { 3: 'Medium' },
      { 4: 'Low' },
      { 5: 'Lowest' }],
    required: false,
    schema:
  { items: '', type: 'priority' },
    value:
  { id: '' }
  },
    reporter:
  {
    key: 'reporter',
    name: 'Reporter',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    versions:
  {
    key: 'versions',
    name: 'Affects Version/s',
    required: false,
    schema:
  { items: 'version', type: 'array' },
    value: []
  }
  }
  },
    id: '10004'
  },
    'Improvement':
  {
    description: 'An improvement or enhancement to an existing feature or task.',
    fields:
  {
    custom_fields:
  {
    customfield_10300:
  {
    key: 'customfield_10300',
    name: 'Number (CF)',
    required: true,
    schema:
  { custom: 'float', items: '', type: 'number' },
    value: null
  },
    customfield_10301:
  {
    key: 'customfield_10301',
    name: 'CascadingSelectField (CF) ',
    options_cascading: [
      { children: ['Merida', 'Caracas', 'Valencia'], parent: 'Venezuela' },
      { children: ['Buenos Aires', 'Rosario'], parent: 'Argentina' }],
    required: false,
    schema:
  { custom: 'cascadingselect', items: '', type: 'option-with-child' },
    value:
  {
    child:
  { value: '' },
    value: ''
  }
  },
    customfield_10400:
  {
    key: 'customfield_10400',
    name: 'SingleVersionPicker (CF)',
    required: false,
    schema:
  { custom: 'version', items: '', type: 'version' },
    value:
  { name: '' }
  },
    customfield_10401:
  {
    key: 'customfield_10401',
    name: 'DateTimeField',
    required: false,
    schema:
  { custom: 'datetime', items: '', type: 'datetime' },
    value: ''
  },
    customfield_10402:
  {
    key: 'customfield_10402',
    name: 'TextField (CF)',
    required: false,
    schema:
  { custom: 'textarea', items: '', type: 'string' },
    value: ''
  },
    customfield_10403:
  {
    key: 'customfield_10403',
    name: 'GroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'grouppicker', items: '', type: 'group' },
    value:
  { name: '' }
  },
    customfield_10404:
  {
    key: 'customfield_10404',
    name: 'MultiGroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'multigrouppicker', items: 'group', type: 'array' },
    value: []
  },
    customfield_10405:
  {
    key: 'customfield_10405',
    name: 'SelectList (CF)',
    options: ['option1', 'option2', 'option3'],
    required: false,
    schema:
  { custom: 'select', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10406:
  {
    key: 'customfield_10406',
    name: 'MultiSelect (CF)',
    options: ['multi1', 'multi2', 'multi3', 'multi4'],
    required: false,
    schema:
  { custom: 'multiselect', items: 'option', type: 'array' },
    value: []
  },
    customfield_10407:
  {
    key: 'customfield_10407',
    name: 'UserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'userpicker', items: '', type: 'user' },
    value:
  { name: '' }
  },
    customfield_10408:
  {
    key: 'customfield_10408',
    name: 'MultiUserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'multiuserpicker', items: 'user', type: 'array' },
    value: []
  },
    customfield_10409:
  {
    key: 'customfield_10409',
    name: 'RadioButtons (CF)',
    options: ['radio1', 'radio2', 'radio3'],
    required: false,
    schema:
  { custom: 'radiobuttons', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10410:
  {
    key: 'customfield_10410',
    name: 'VersionPicker (CF)',
    required: false,
    schema:
  { custom: 'multiversion', items: 'version', type: 'array' },
    value: []
  },
    customfield_10411:
  {
    key: 'customfield_10411',
    name: 'URLField (CF)',
    required: false,
    schema:
  { custom: 'url', items: '', type: 'string' },
    value: ''
  },
    customfield_10412:
  {
    key: 'customfield_10412',
    name: 'Checkbox (CF)',
    options: ['Box1', 'Box2', 'Box3'],
    required: false,
    schema:
  { custom: 'multicheckboxes', items: 'option', type: 'array' },
    value: []
  }
  },
    insight_custom_fields:
  {
    customfield_10413:
  {
    iql_string: '',
    key: 'customfield_10413',
    name: 'InsightObject (CF)',
    options: [],
    required: false,
    schema:
  { custom: 'com.riadalabs.jira.plugins.insight:rlabs-customfield-default-object', items: '', type: 'any' },
    value: []
  }
  },
    standard_fields:
  {
    assignee:
  {
    key: 'assignee',
    name: 'Assignee',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    attachment:
  {
    key: 'attachment',
    name: 'Attachment',
    required: false,
    schema:
  { items: 'attachment', type: 'array' },
    value: []
  },
    components:
  {
    key: 'components',
    name: 'Component/s',
    required: false,
    schema:
  { items: 'component', type: 'array' },
    value: []
  },
    duedate:
  {
    key: 'duedate',
    name: 'Due Date',
    required: false,
    schema:
  { items: '', type: 'date' },
    value: ''
  },
    fixVersions:
  {
    key: 'fixVersions',
    name: 'Fix Version/s',
    required: false,
    schema:
  { items: 'version', type: 'array' },
    value: []
  },
    labels:
  {
    key: 'labels',
    name: 'Labels',
    required: false,
    schema:
  { items: 'string', type: 'array' },
    value: []
  },
    priority:
  {
    key: 'priority',
    name: 'Priority',
    options: [
      { 1: 'Highest' },
      { 2: 'High' },
      { 3: 'Medium' },
      { 4: 'Low' },
      { 5: 'Lowest' }],
    required: false,
    schema:
  { items: '', type: 'priority' },
    value:
  { id: '' }
  },
    reporter:
  {
    key: 'reporter',
    name: 'Reporter',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  }
  }
  },
    id: '10100'
  },
    'New Feature':
  {
    description: 'A new feature of the product, which has yet to be developed.',
    fields:
  {
    custom_fields:
  {
    customfield_10300:
  {
    key: 'customfield_10300',
    name: 'Number (CF)',
    required: true,
    schema:
  { custom: 'float', items: '', type: 'number' },
    value: null
  },
    customfield_10301:
  {
    key: 'customfield_10301',
    name: 'CascadingSelectField (CF) ',
    options_cascading: [
      { children: ['Merida', 'Caracas', 'Valencia'], parent: 'Venezuela' },
      { children: ['Buenos Aires', 'Rosario'], parent: 'Argentina' }],
    required: false,
    schema:
  { custom: 'cascadingselect', items: '', type: 'option-with-child' },
    value:
  {
    child:
  { value: '' },
    value: ''
  }
  },
    customfield_10400:
  {
    key: 'customfield_10400',
    name: 'SingleVersionPicker (CF)',
    required: false,
    schema:
  { custom: 'version', items: '', type: 'version' },
    value:
  { name: '' }
  },
    customfield_10401:
  {
    key: 'customfield_10401',
    name: 'DateTimeField',
    required: false,
    schema:
  { custom: 'datetime', items: '', type: 'datetime' },
    value: ''
  },
    customfield_10402:
  {
    key: 'customfield_10402',
    name: 'TextField (CF)',
    required: false,
    schema:
  { custom: 'textarea', items: '', type: 'string' },
    value: ''
  },
    customfield_10403:
  {
    key: 'customfield_10403',
    name: 'GroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'grouppicker', items: '', type: 'group' },
    value:
  { name: '' }
  },
    customfield_10404:
  {
    key: 'customfield_10404',
    name: 'MultiGroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'multigrouppicker', items: 'group', type: 'array' },
    value: []
  },
    customfield_10405:
  {
    key: 'customfield_10405',
    name: 'SelectList (CF)',
    options: ['option1', 'option2', 'option3'],
    required: false,
    schema:
  { custom: 'select', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10406:
  {
    key: 'customfield_10406',
    name: 'MultiSelect (CF)',
    options: ['multi1', 'multi2', 'multi3', 'multi4'],
    required: false,
    schema:
  { custom: 'multiselect', items: 'option', type: 'array' },
    value: []
  },
    customfield_10407:
  {
    key: 'customfield_10407',
    name: 'UserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'userpicker', items: '', type: 'user' },
    value:
  { name: '' }
  },
    customfield_10408:
  {
    key: 'customfield_10408',
    name: 'MultiUserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'multiuserpicker', items: 'user', type: 'array' },
    value: []
  },
    customfield_10409:
  {
    key: 'customfield_10409',
    name: 'RadioButtons (CF)',
    options: ['radio1', 'radio2', 'radio3'],
    required: false,
    schema:
  { custom: 'radiobuttons', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10410:
  {
    key: 'customfield_10410',
    name: 'VersionPicker (CF)',
    required: false,
    schema:
  { custom: 'multiversion', items: 'version', type: 'array' },
    value: []
  },
    customfield_10411:
  {
    key: 'customfield_10411',
    name: 'URLField (CF)',
    required: false,
    schema:
  { custom: 'url', items: '', type: 'string' },
    value: ''
  },
    customfield_10412:
  {
    key: 'customfield_10412',
    name: 'Checkbox (CF)',
    options: ['Box1', 'Box2', 'Box3'],
    required: false,
    schema:
  { custom: 'multicheckboxes', items: 'option', type: 'array' },
    value: []
  }
  },
    insight_custom_fields:
  {
    customfield_10413:
  {
    iql_string: '',
    key: 'customfield_10413',
    name: 'InsightObject (CF)',
    options: [],
    required: false,
    schema:
  { custom: 'com.riadalabs.jira.plugins.insight:rlabs-customfield-default-object', items: '', type: 'any' },
    value: []
  }
  },
    standard_fields:
  {
    assignee:
  {
    key: 'assignee',
    name: 'Assignee',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    attachment:
  {
    key: 'attachment',
    name: 'Attachment',
    required: false,
    schema:
  { items: 'attachment', type: 'array' },
    value: []
  },
    components:
  {
    key: 'components',
    name: 'Component/s',
    required: false,
    schema:
  { items: 'component', type: 'array' },
    value: []
  },
    duedate:
  {
    key: 'duedate',
    name: 'Due Date',
    required: false,
    schema:
  { items: '', type: 'date' },
    value: ''
  },
    fixVersions:
  {
    key: 'fixVersions',
    name: 'Fix Version/s',
    required: false,
    schema:
  { items: 'version', type: 'array' },
    value: []
  },
    labels:
  {
    key: 'labels',
    name: 'Labels',
    required: false,
    schema:
  { items: 'string', type: 'array' },
    value: []
  },
    priority:
  {
    key: 'priority',
    name: 'Priority',
    options: [
      { 1: 'Highest' },
      { 2: 'High' },
      { 3: 'Medium' },
      { 4: 'Low' },
      { 5: 'Lowest' }],
    required: false,
    schema:
  { items: '', type: 'priority' },
    value:
  { id: '' }
  },
    reporter:
  {
    key: 'reporter',
    name: 'Reporter',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  }
  }
  },
    id: '10101'
  },
    'Task':
  {
    description: 'A task that needs to be done.',
    fields:
  {
    custom_fields:
  {
    customfield_10300:
  {
    key: 'customfield_10300',
    name: 'Number (CF)',
    required: true,
    schema:
  { custom: 'float', items: '', type: 'number' },
    value: null
  },
    customfield_10301:
  {
    key: 'customfield_10301',
    name: 'CascadingSelectField (CF) ',
    options_cascading: [
      { children: ['Merida', 'Caracas', 'Valencia'], parent: 'Venezuela' },
      { children: ['Buenos Aires', 'Rosario'], parent: 'Argentina' }],
    required: false,
    schema:
  { custom: 'cascadingselect', items: '', type: 'option-with-child' },
    value:
  {
    child:
  { value: '' },
    value: ''
  }
  },
    customfield_10400:
  {
    key: 'customfield_10400',
    name: 'SingleVersionPicker (CF)',
    required: false,
    schema:
  { custom: 'version', items: '', type: 'version' },
    value:
  { name: '' }
  },
    customfield_10401:
  {
    key: 'customfield_10401',
    name: 'DateTimeField',
    required: false,
    schema:
  { custom: 'datetime', items: '', type: 'datetime' },
    value: ''
  },
    customfield_10402:
  {
    key: 'customfield_10402',
    name: 'TextField (CF)',
    required: false,
    schema:
  { custom: 'textarea', items: '', type: 'string' },
    value: ''
  },
    customfield_10403:
  {
    key: 'customfield_10403',
    name: 'GroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'grouppicker', items: '', type: 'group' },
    value:
  { name: '' }
  },
    customfield_10404:
  {
    key: 'customfield_10404',
    name: 'MultiGroupPicker (CF)',
    options: ['jira-administrators', 'jira-software-users'],
    required: false,
    schema:
  { custom: 'multigrouppicker', items: 'group', type: 'array' },
    value: []
  },
    customfield_10405:
  {
    key: 'customfield_10405',
    name: 'SelectList (CF)',
    options: ['option1', 'option2', 'option3'],
    required: false,
    schema:
  { custom: 'select', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10406:
  {
    key: 'customfield_10406',
    name: 'MultiSelect (CF)',
    options: ['multi1', 'multi2', 'multi3', 'multi4'],
    required: false,
    schema:
  { custom: 'multiselect', items: 'option', type: 'array' },
    value: []
  },
    customfield_10407:
  {
    key: 'customfield_10407',
    name: 'UserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'userpicker', items: '', type: 'user' },
    value:
  { name: '' }
  },
    customfield_10408:
  {
    key: 'customfield_10408',
    name: 'MultiUserPicker (CF)',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { custom: 'multiuserpicker', items: 'user', type: 'array' },
    value: []
  },
    customfield_10409:
  {
    key: 'customfield_10409',
    name: 'RadioButtons (CF)',
    options: ['radio1', 'radio2', 'radio3'],
    required: false,
    schema:
  { custom: 'radiobuttons', items: '', type: 'option' },
    value:
  { value: '' }
  },
    customfield_10410:
  {
    key: 'customfield_10410',
    name: 'VersionPicker (CF)',
    required: false,
    schema:
  { custom: 'multiversion', items: 'version', type: 'array' },
    value: []
  },
    customfield_10411:
  {
    key: 'customfield_10411',
    name: 'URLField (CF)',
    required: false,
    schema:
  { custom: 'url', items: '', type: 'string' },
    value: ''
  },
    customfield_10412:
  {
    key: 'customfield_10412',
    name: 'Checkbox (CF)',
    options: ['Box1', 'Box2', 'Box3'],
    required: false,
    schema:
  { custom: 'multicheckboxes', items: 'option', type: 'array' },
    value: []
  }
  },
    insight_custom_fields:
  {
    customfield_10413:
  {
    iql_string: '',
    key: 'customfield_10413',
    name: 'InsightObject (CF)',
    options: [],
    required: false,
    schema:
  { custom: 'com.riadalabs.jira.plugins.insight:rlabs-customfield-default-object', items: '', type: 'any' },
    value: []
  }
  },
    standard_fields:
  {
    assignee:
  {
    key: 'assignee',
    name: 'Assignee',
    options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'],
    required: false,
    schema:
  { items: '', type: 'user' },
    value:
  { name: '' }
  },
    attachment:
  {
    key: 'attachment',
    name: 'Attachment',
    required: false,
    schema:
  { items: 'attachment', type: 'array' },
    value: []
  },
    components:
  {
    key: 'components',
    name: 'Component/s',
    required: false,
    schema:
  { items: 'component', type: 'array' },
    value: []
  },
    duedate:
  {
    key: 'duedate',
    name: 'Due Date',
    required: false,
    schema:
  { items: '', type: 'date' },
    value: ''
  },
    fixVersions:
  {
    key: 'fixVersions',
    name: 'Fix Version/s',
    required: false,
    schema:
  { items: 'version', type: 'array' },
    value: []
  },
    labels:
  {
    key: 'labels',
    name: 'Labels',
    required: false,
    schema:
  { items: 'string', type: 'array' },
    value: []
  },
    priority:
  {
    key: 'priority',
    name: 'Priority',
    options: [
      { 1: 'Highest' },
      { 2: 'High' },
      { 3: 'Medium' },
      { 4: 'Low' },
      { 5: 'Lowest' }],
    required: false,
    schema:
  { items: '', type: 'priority' },
    value:
  { id: '' }
  },
    reporter:
  {
    key: 'reporter', name: 'Reporter', options: ['pentester@faradaysec.com', 'asset_owner@faradaysec.com', 'client@faradaysec.com', 'test@gmail.com'], required: false, schema: { items: '', type: 'user' }, value: { name: '' }
  }
  }
  },
    id: '10002'
  }
  },
    name: 'faraday3',
    ticket_config: {
      description: '',
      issue_type: '',
      issue_types: {
        'Bug': { fields: {}, selected: false }, 'Improvement': { fields: {}, selected: false }, 'New Feature': { fields: {}, selected: false }, 'Task': { fields: {}, selected: false }
      },
      summary: ''
    }
  },
  FAR4: {
    id: '10000',
    issue_types: {
      Bug: {
        description:
          'A problem which impairs or prevents the functions of the product.',
        fields: {
          custom_fields: {
            customfield_10107: {
              key: 'customfield_10107',
              name: 'Aruba - Labels',
              required: false,
              schema: {
                custom: 'labels',
                items: 'string',
                type: 'array'
              },
              value: []
            },
            customfield_10108: {
              key: 'customfield_10108',
              name: 'Aruba - DatePickerField',
              required: false,
              schema: {
                custom: 'datepicker',
                items: '',
                type: 'date'
              },
              value: ''
            },
            customfield_10200: {
              key: 'customfield_10200',
              name: 'faradayCF Project picker',
              options: ['FAR', 'FAR2', 'FAR3'],
              required: false,
              schema: {
                custom: 'project',
                items: '',
                type: 'project'
              },
              value: {
                key: ''
              }
            },
            customfield_10300: {
              key: 'customfield_10300',
              name: 'Aruba - Number',
              required: false,
              schema: {
                custom: 'float',
                items: '',
                type: 'number'
              },
              value: null
            },
            customfield_10301: {
              key: 'customfield_10301',
              name: 'Aruba - Option with child',
              options_cascading: [
                {
                  children: ['Merida', 'Caracas', 'Valencia'],
                  parent: 'Venezuela'
                },
                {
                  children: ['Buenos Aires', 'Rosario'],
                  parent: 'Argentina'
                }
              ],
              required: false,
              schema: {
                custom: 'cascadingselect',
                items: '',
                type: 'option-with-child'
              },
              value: {
                child: {
                  value: ''
                },
                value: ''
              }
            }
          },
          insight_custom_fields: {},
          standard_fields: {
            assignee: {
              key: 'assignee',
              name: 'Assignee',
              options: [
                'pentester@faradaysec.com',
                'asset_owner@faradaysec.com',
                'client@faradaysec.com',
                'test@gmail.com'
              ],
              required: true,
              schema: {
                items: '',
                type: 'user'
              },
              value: {
                name: ''
              }
            },
            attachment: {
              key: 'attachment',
              name: 'Attachment',
              required: false,
              schema: {
                items: 'attachment',
                type: 'array'
              },
              value: []
            },
            components: {
              key: 'components',
              name: 'Component/s',
              options: ['Component1', 'Component2', 'Component3', 'Component4'],
              required: false,
              schema: {
                items: 'component',
                type: 'array'
              },
              value: []
            },
            environment: {
              key: 'environment',
              name: 'Environment',
              required: true,
              schema: {
                items: '',
                type: 'string'
              },
              value: ''
            },
            fixVersions: {
              key: 'fixVersions',
              name: 'Fix Version/s',
              options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
              required: false,
              schema: {
                items: 'version',
                type: 'array'
              },
              value: []
            },
            labels: {
              key: 'labels',
              name: 'Labels',
              required: false,
              schema: {
                items: 'string',
                type: 'array'
              },
              value: []
            },
            priority: {
              key: 'priority',
              name: 'Priority',
              options: [
                {
                  1: 'Highest'
                },
                {
                  2: 'High'
                },
                {
                  3: 'Medium'
                },
                {
                  4: 'Low'
                },
                {
                  5: 'Lowest'
                }
              ],
              required: true,
              schema: {
                items: '',
                type: 'priority'
              },
              value: {
                id: ''
              }
            },
            reporter: {
              key: 'reporter',
              name: 'Reporter',
              options: [
                'pentester@faradaysec.com',
                'asset_owner@faradaysec.com',
                'client@faradaysec.com',
                'test@gmail.com'
              ],
              required: false,
              schema: {
                items: '',
                type: 'user'
              },
              value: {
                name: ''
              }
            },
            security: {
              key: 'security',
              name: 'Security Level',
              options: [
                {
                  10000: 'level 1'
                },
                {
                  10001: 'level 2'
                },
                {
                  10002: 'level 3'
                },
                {
                  10003: 'level 4'
                },
                {
                  10004: 'level 5'
                }
              ],
              required: true,
              schema: {
                items: '',
                type: 'securitylevel'
              },
              value: {
                id: ''
              }
            },
            versions: {
              key: 'versions',
              name: 'Affects Version/s',
              options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
              required: false,
              schema: {
                items: 'version',
                type: 'array'
              },
              value: []
            }
          }
        },
        id: '10004'
      },
      Story: {
        description:
          'Created by Jira Software - do not edit or delete. Issue type for a user story.',
        fields: {
          custom_fields: {
            customfield_10107: {
              key: 'customfield_10107',
              name: 'Aruba - Labels',
              required: false,
              schema: {
                custom: 'labels',
                items: 'string',
                type: 'array'
              },
              value: []
            },
            customfield_10108: {
              key: 'customfield_10108',
              name: 'Aruba - DatePickerField',
              required: false,
              schema: {
                custom: 'datepicker',
                items: '',
                type: 'date'
              },
              value: ''
            },
            customfield_10200: {
              key: 'customfield_10200',
              name: 'faradayCF Project picker',
              options: ['FAR', 'FAR2', 'FAR3'],
              required: false,
              schema: {
                custom: 'project',
                items: '',
                type: 'project'
              },
              value: {
                key: ''
              }
            },
            customfield_10300: {
              key: 'customfield_10300',
              name: 'Aruba - Number',
              required: false,
              schema: {
                custom: 'float',
                items: '',
                type: 'number'
              },
              value: null
            },
            customfield_10301: {
              key: 'customfield_10301',
              name: 'Aruba - Option with child',
              options_cascading: [
                {
                  children: ['Merida', 'Caracas', 'Valencia'],
                  parent: 'Venezuela'
                },
                {
                  children: ['Buenos Aires', 'Rosario'],
                  parent: 'Argentina'
                }
              ],
              required: false,
              schema: {
                custom: 'cascadingselect',
                items: '',
                type: 'option-with-child'
              },
              value: {
                child: {
                  value: ''
                },
                value: ''
              }
            }
          },
          insight_custom_fields: {},
          standard_fields: {
            assignee: {
              key: 'assignee',
              name: 'Assignee',
              options: [
                'pentester@faradaysec.com',
                'asset_owner@faradaysec.com',
                'client@faradaysec.com',
                'test@gmail.com'
              ],
              required: false,
              schema: {
                items: '',
                type: 'user'
              },
              value: {
                name: ''
              }
            },
            attachment: {
              key: 'attachment',
              name: 'Attachment',
              required: false,
              schema: {
                items: 'attachment',
                type: 'array'
              },
              value: []
            },
            components: {
              key: 'components',
              name: 'Component/s',
              options: ['Component1', 'Component2', 'Component3', 'Component4'],
              required: false,
              schema: {
                items: 'component',
                type: 'array'
              },
              value: []
            },
            fixVersions: {
              key: 'fixVersions',
              name: 'Fix Version/s',
              options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
              required: false,
              schema: {
                items: 'version',
                type: 'array'
              },
              value: []
            },
            labels: {
              key: 'labels',
              name: 'Labels',
              required: false,
              schema: {
                items: 'string',
                type: 'array'
              },
              value: []
            },
            priority: {
              key: 'priority',
              name: 'Priority',
              options: [
                {
                  1: 'Highest'
                },
                {
                  2: 'High'
                },
                {
                  3: 'Medium'
                },
                {
                  4: 'Low'
                },
                {
                  5: 'Lowest'
                }
              ],
              required: false,
              schema: {
                items: '',
                type: 'priority'
              },
              value: {
                id: ''
              }
            },
            reporter: {
              key: 'reporter',
              name: 'Reporter',
              options: [
                'pentester@faradaysec.com',
                'asset_owner@faradaysec.com',
                'client@faradaysec.com',
                'test@gmail.com'
              ],
              required: false,
              schema: {
                items: '',
                type: 'user'
              },
              value: {
                name: ''
              }
            },
            security: {
              key: 'security',
              name: 'Security Level',
              options: [
                {
                  10000: 'level 1'
                },
                {
                  10001: 'level 2'
                },
                {
                  10002: 'level 3'
                },
                {
                  10003: 'level 4'
                },
                {
                  10004: 'level 5'
                }
              ],
              required: false,
              schema: {
                items: '',
                type: 'securitylevel'
              },
              value: {
                id: ''
              }
            }
          }
        },
        id: '10001'
      },
      Task: {
        description: 'A task that needs to be done.',
        fields: {
          custom_fields: {
            customfield_10107: {
              key: 'customfield_10107',
              name: 'Aruba - Labels',
              required: false,
              schema: {
                custom: 'labels',
                items: 'string',
                type: 'array'
              },
              value: []
            },
            customfield_10108: {
              key: 'customfield_10108',
              name: 'Aruba - DatePickerField',
              required: false,
              schema: {
                custom: 'datepicker',
                items: '',
                type: 'date'
              },
              value: ''
            },
            customfield_10200: {
              key: 'customfield_10200',
              name: 'faradayCF Project picker',
              options: ['FAR', 'FAR2', 'FAR3'],
              required: false,
              schema: {
                custom: 'project',
                items: '',
                type: 'project'
              },
              value: {
                key: ''
              }
            },
            customfield_10300: {
              key: 'customfield_10300',
              name: 'Aruba - Number',
              required: false,
              schema: {
                custom: 'float',
                items: '',
                type: 'number'
              },
              value: null
            },
            customfield_10301: {
              key: 'customfield_10301',
              name: 'Aruba - Option with child',
              options_cascading: [
                {
                  children: ['Merida', 'Caracas', 'Valencia'],
                  parent: 'Venezuela'
                },
                {
                  children: ['Buenos Aires', 'Rosario'],
                  parent: 'Argentina'
                }
              ],
              required: false,
              schema: {
                custom: 'cascadingselect',
                items: '',
                type: 'option-with-child'
              },
              value: {
                child: {
                  value: ''
                },
                value: ''
              }
            }
          },
          insight_custom_fields: {},
          standard_fields: {
            assignee: {
              key: 'assignee',
              name: 'Assignee',
              options: [
                'pentester@faradaysec.com',
                'asset_owner@faradaysec.com',
                'client@faradaysec.com',
                'test@gmail.com'
              ],
              required: false,
              schema: {
                items: '',
                type: 'user'
              },
              value: {
                name: ''
              }
            },
            attachment: {
              key: 'attachment',
              name: 'Attachment',
              required: false,
              schema: {
                items: 'attachment',
                type: 'array'
              },
              value: []
            },
            components: {
              key: 'components',
              name: 'Component/s',
              options: ['Component1', 'Component2', 'Component3', 'Component4'],
              required: false,
              schema: {
                items: 'component',
                type: 'array'
              },
              value: []
            },
            fixVersions: {
              key: 'fixVersions',
              name: 'Fix Version/s',
              options: ['Version 1.0', 'Version 2.0', 'Version 3.0'],
              required: false,
              schema: {
                items: 'version',
                type: 'array'
              },
              value: []
            },
            labels: {
              key: 'labels',
              name: 'Labels',
              required: false,
              schema: {
                items: 'string',
                type: 'array'
              },
              value: []
            },
            priority: {
              key: 'priority',
              name: 'Priority',
              options: [
                {
                  1: 'Highest'
                },
                {
                  2: 'High'
                },
                {
                  3: 'Medium'
                },
                {
                  4: 'Low'
                },
                {
                  5: 'Lowest'
                }
              ],
              required: false,
              schema: {
                items: '',
                type: 'priority'
              },
              value: {
                id: ''
              }
            },
            reporter: {
              key: 'reporter',
              name: 'Reporter',
              options: [
                'pentester@faradaysec.com',
                'asset_owner@faradaysec.com',
                'client@faradaysec.com',
                'test@gmail.com'
              ],
              required: false,
              schema: {
                items: '',
                type: 'user'
              },
              value: {
                name: ''
              }
            },
            security: {
              key: 'security',
              name: 'Security Level',
              options: [
                {
                  10000: 'level 1'
                },
                {
                  10001: 'level 2'
                },
                {
                  10002: 'level 3'
                },
                {
                  10003: 'level 4'
                },
                {
                  10004: 'level 5'
                }
              ],
              required: false,
              schema: {
                items: '',
                type: 'securitylevel'
              },
              value: {
                id: ''
              }
            }
          }
        },
        id: 10002
      }
    },
    name: 'faraday',
    ticket_config: {
      description:
        'Description: {{vuln.description}}\n\n{% if vuln.data %}Data: {{vuln.data}}{% endif %}',
      issue_types: {
        Bug: {
          fields: {},
          selected: true
        },
        Story: {
          fields: {},
          selected: false
        },
        Task: {
          fields: {},
          selected: false
        }
      },
      summary: '{{vuln.name}} - {{vuln.severity}}'
    }
  }
};
