import styled from 'styled-components';

export const colors = {
  info: '#82b8e5',
  low: '#88cc3f',
  medium: '#f59220',
  high: '#ea3158',
  critical: '#a4247a',
  none: '#63758d'
};

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';
