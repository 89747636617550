import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { showQuickPlanner } from 'store/Manage/actions';
import {
  CloseIconWrapper, CloseIcon, TitleWrapper, Title, Subtitle, Blue, Wrapper
} from './styled';

const Header = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const handleClose = () => dispatch(showQuickPlanner(false));

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{intl.formatMessage({ id: 'quickPlanner.project.title' })}</Title>
        <CloseIconWrapper onClick={ handleClose }>
          <CloseIcon />
        </CloseIconWrapper>
      </TitleWrapper>
      <Subtitle>
        {intl.formatMessage({ id: 'quickPlanner.project.subtitle1' })}
        {' '}
        <Blue to="/planner">{intl.formatMessage({ id: 'quickPlanner.project.empty.planner' })}</Blue>
        {intl.formatMessage({ id: 'quickPlanner.project.subtitle2' })}
      </Subtitle>
    </Wrapper>
  );
};

export default Header;
