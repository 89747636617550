/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BlueButton from 'Common/Components/BlueButton';
import OutlinedButton from 'Common/Components/OutlinedButton';
import CustomList from 'Common/Components/CustomList';
import { CUSTOM_ATTRIBUTE_TYPES } from 'Screens/Manage/components/CustomAttributesModal/constants';

import {
  Wrapper, Buttons, List, Item, CustomListWrapper
} from './styled';
import { useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const ListCustomField = ({
  field, vuln, onCancel, onSave, initialValue
}) => {
  const [options, setOptions] = useState(initialValue);
  const [editMode, setEditMode] = useState(!initialValue);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));

  useEffect(() => {
    if (options !== initialValue) {
      setOptions(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    onSave(vuln, field.field_name, options);
    setEditMode(false);
    if (!options) onCancel();
  };

  const handleCancel = () => {
    setEditMode(false);
    if (initialValue) {
      setOptions(initialValue);
    } else {
      onCancel();
    }
  };

  const onAddItem = (option) => {
    const newOptions = [...options];
    newOptions.push(option);
    setOptions(newOptions);
  };

  const onRemoveItem = (optionIndex) => {
    const newItems = [...options];
    newItems.splice(optionIndex, 1);
    setOptions(newItems);
  };

  return (
    <Wrapper>
      {editMode && (
        <CustomListWrapper>
          <CustomList
            key="LIST_CF_OPTIONS"
            placeholder="Add option"
            addItem={ onAddItem }
            removeItem={ onRemoveItem }
            setItem={ () => {} }
            listData={ options }
            width="430px"
            responsive
            canUpdate={ canUpdate }
          />
        </CustomListWrapper>
      )}
      {!editMode &&
          (
          <List onClick={ () => setEditMode(true) }>
            {options && options.map((option) => (<Item>{option}</Item>))}
          </List>
          )}
      {editMode &&
      (
      <Buttons>
        <OutlinedButton label="Cancel" onClick={ handleCancel } />
        <BlueButton label="Save" onClick={ handleSave } />
      </Buttons>
      )}
    </Wrapper>
  );
};

ListCustomField.defaultProps = {
  initialValue: []
};

ListCustomField.propTypes = {
  field: PropTypes.shape({
    field_display_name: PropTypes.string,
    field_type: PropTypes.oneOf([CUSTOM_ATTRIBUTE_TYPES.LIST])
  }).isRequired,
  vuln: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.string)
};

export default ListCustomField;
