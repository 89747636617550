/* eslint-disable react/no-children-prop */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { saveSaml } from 'store/Settings/saml/actions';
import { selectCanSubmit } from 'store/Settings/saml/selectors';
import Header from '../Header';
import Form from './components/Form';
import Title from './components/Title';
import Wrapper from './styled';

const Saml = ({ onClose }) => {
  const dispatch = useDispatch();
  const canSubmit = useSelector(selectCanSubmit);
  const onSave = () => dispatch(saveSaml(onClose));

  return (
    <Wrapper>
      <Header onClose={ onClose } disabled={ !canSubmit } onAccept={ onSave } />
      <Title />
      <Form />
    </Wrapper>
  );
};

Saml.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default Saml;
