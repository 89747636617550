import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  selectHostname, selectUsername, selectPassword, selectSender, selectPort, selectError, selectMessage, selectEnabled, selectSsl
} from 'store/Settings/smtpSelectors';
import {
  getSmtp,
  setSmtpField,
  saveSmtp
} from 'store/Settings/smtpActions';
import Error from 'Common/Components/Error';
import Success from 'Common/Components/Success';
import { CheckboxLabel, WrapperCheckbox } from 'Screens/Settings/common-styled';
import CustomCheckbox from 'Common/Components/CustomCheckbox';
import Header from '../Header';
import {
  Wrapper,
  Title,
  Subtitle,
  Content,
  Hostname,
  Sender,
  Username,
  Password,
  Port
} from './styled';

const Smtp = ({ onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSmtp());
  }, [dispatch]);

  const props = useSelector((state) => ({
    hostname: selectHostname(state),
    username: selectUsername(state),
    password: selectPassword(state),
    sender: selectSender(state),
    port: selectPort(state),
    error: selectError(state),
    message: selectMessage(state),
    enabled: selectEnabled(state),
    ssl: selectSsl(state)
  }));

  const change = (field, value) => {
    dispatch(setSmtpField(field, value));
  };

  const close = () => {
    onClose();
  };

  const accept = () => {
    dispatch(saveSmtp());
    close();
  };

  return (
    <Wrapper>
      <Header onAccept={ accept } onClose={ close } />
      <Title>{intl.formatMessage({ id: 'preferences.smtp.title' })}</Title>
      <Subtitle>{intl.formatMessage({ id: 'preferences.smtp.subtitle' })}</Subtitle>
      <Content>
        <Hostname id="hostname" value={ props.hostname } defaultValue={ props.hostname } title={ intl.formatMessage({ id: 'preferences.smtp.serverAdress.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.smtp.serverAdress.placeholder' }) } onChange={ (v) => change('hostname', v) } />
        <Port value={ props.port } defaultValue={ props.port } title={ intl.formatMessage({ id: 'preferences.smtp.port.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.smtp.port.placeholder' }) } onChange={ (v) => change('port', v) } />
      </Content>
      <Content>
        <Username value={ props.username } title={ intl.formatMessage({ id: 'preferences.smtp.username.title' }) } defaultValue={ props.username } placeholder={ intl.formatMessage({ id: 'preferences.smtp.username.placeholder' }) } onChange={ (v) => change('username', v) } />
        <Password value={ props.password } title={ intl.formatMessage({ id: 'preferences.smtp.password.title' }) } defaultValue={ props.password } placeholder={ intl.formatMessage({ id: 'preferences.smtp.password.placeholder' }) } onChange={ (v) => change('password', v) } />
      </Content>
      <Content>
        <Sender value={ props.sender } title={ intl.formatMessage({ id: 'preferences.smtp.sender.title' }) } defaultValue={ props.sender } placeholder={ intl.formatMessage({ id: 'preferences.smtp.sender.placeholder' }) } onChange={ (v) => change('sender', v) } />
      </Content>
      <WrapperCheckbox>
        <CustomCheckbox id="enabled" label="" handleCheckboxChange={ () => { change('enabled', !props.enabled); } } key="enabled" isChecked={ props.enabled } />
        <CheckboxLabel>{intl.formatMessage({ id: 'preferences.smtp.enabled.title' })}</CheckboxLabel>
      </WrapperCheckbox>
      <WrapperCheckbox>
        <CustomCheckbox id="ssl" label="" handleCheckboxChange={ () => { change('ssl', !props.ssl); } } key="ssl" isChecked={ props.ssl } />
        <CheckboxLabel>{intl.formatMessage({ id: 'preferences.smtp.ssl.title' })}</CheckboxLabel>
      </WrapperCheckbox>

      {props.error && props.message ? <Error>{props.message}</Error> : null }
      {!props.error && props.message ? <Success>{props.message}</Success> : null }
    </Wrapper>
  );
};
export default Smtp;
