import styled from 'styled-components';
import { ReactComponent as UsersIcon } from 'Images/icon_users.svg';

const Users = styled(UsersIcon).attrs({
  id: 'qa-users-ws'
})`
  cursor: ${({ disabled }) => { if (disabled) return 'not-allowed !important'; else return 'pointer'; }};
`;

Users.displayName = 'Users';

export default Users;
