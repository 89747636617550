import styled from 'styled-components';

import { ReactComponent as Icon } from 'Images/icon-action-bar-trash.svg';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 37px;
    max-height: 107px;
    border: solid 1px #e8e8e8;
    overflow-y: auto;
    `;
Wrapper.displayName = 'Wrapper';

export const ItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 0 0 35px;
    :hover{
        background-color: #edf2f7;
        >:nth-child(2){
            display: block;
        }
    }
`;
ItemWrapper.displayName = 'ItemWrapper';

export const AssetIP = styled.div`
    text-align: left;
    user-select: none;
    margin: auto 0px auto 20px;
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1c2243;
`;
AssetIP.displayName = 'AssetIP';

export const RemoveIcon = styled(Icon)`
  display: none;
  margin: auto 16px auto auto;
  width: 17px;
  height: 17px;
  background-color: #63758d;
`;
RemoveIcon.displayName = 'RemoveIcon';
