import React, { useState } from 'react';
import { TypeContainer, FragmentContainer } from '../styled';
import api from 'services/api';
import { useDispatch } from 'react-redux';
import { updateNotificationType } from 'store/Notifications/actions';

const preventNonNumericalInput = (e) => {
  const allowedKeys = ['Backspace', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End', '.'];

  if ((e.key && isNaN(e.key) && !allowedKeys.includes(e.key)) || (e.key === '.' && e.target.value.includes('.'))) {
    e.preventDefault();
  }
};

const TypeColumn = ({ original, userId, initialState, type }) => {
  const [inputValue, setInputValue] = useState(initialState || 0);
  const dispatch = useDispatch();
  let label = original.type;
  let showInput = false;

  if (label === 'hosts') {
    label = 'Assets';
  } else if (label === 'highCritVuln') {
    label = 'New High/Critical severity Vulnerability in Workspace';
  } else if (label === 'riskScoreThreshold') {
    label = 'Vulnerability with risk score greater than [INPUT]';
    showInput = true;
  } else if (label === 'vulnOpenDaysCritical') {
    label = 'Critical severity open vuln in Workspace for more than [INPUT] days';
    showInput = true;
  } else if (label === 'vulnOpenDaysHigh') {
    label = 'High severity open vuln in Workspace for more than [INPUT] days';
    showInput = true;
  } else if (label === 'vulnOpenDaysMedium') {
    label = 'Medium severity open vuln in Workspace for more than [INPUT] days';
    showInput = true;
  } else if (label === 'vulnOpenDaysLow') {
    label = 'Low severity open vuln in Workspace for more than [INPUT] days';
    showInput = true;
  }

  const handleInputChange = (e) => {
    let value = parseFloat(e.target.value);

    if (label === 'Vulnerability with risk score greater than [INPUT]') {
      value = Math.max(0, Math.min(100, value));
    } else if (label === 'High/Critical severity open vuln in Workspace for more than [INPUT] days') {
      value = Math.max(0, Math.min(999, value));
    }

    setInputValue(value.toString());
  };

  const handleInputUpdate = async () => {
    try {
      const notificationChannel = type.split('_')[1];
      await api.notifications.updateNotificationTypeApi(userId, type, inputValue);
      dispatch(updateNotificationType(userId, type, notificationChannel, inputValue));
    } catch (error) {
      // console.error('Error updating notification type:', error);
    }
  };

  const handleInputBlur = () => {
    handleInputUpdate();
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleInputUpdate();
    }
  };

  return (
    <TypeContainer>
      <FragmentContainer>
        {label.split('[INPUT]').map((segment, index) => (
            <React.Fragment key={ index }>
                {segment}
                {showInput && index === label.split('[INPUT]').length - 1 && (
                    <input
                      type='number'
                      pattern="\d*"
                      inputMode="numeric"
                      onKeyDown={ preventNonNumericalInput }
                      value={ inputValue }
                      onChange={ handleInputChange }
                      onBlur={ handleInputBlur }
                      onKeyPress={ handleInputKeyPress }
                    />
                )}
            </React.Fragment>
        ))}
      </FragmentContainer>
    </TypeContainer>
  );
};

export default TypeColumn;
