import styled from 'styled-components';
import colors from 'Styles/colors';

export const Title = styled.div`
  color: ${colors.greyBlue};
  font-size: 14.5px;
  cursor: pointer;
`;
Title.displayName = 'Title';

export const Text = styled.div`
  color: ${colors.dark5};
  font-size: 12.5px;
`;
Text.displayName = 'Text';

export const GraphDate = styled.div`
  color: ${colors.blueGrey};
  font-size: 11.5px;
`;
GraphDate.displayName = 'GraphDate';
