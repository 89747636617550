import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as WordImage } from 'Images/report-word.svg';
import { ReactComponent as PDFImage } from 'Images/report-pdf.svg';

export const Wrapper = styled.div`
  position: relative;
  
`;
Wrapper.displayName = 'Wrapper';

export const Button = styled.button`
  background-color: ${colors.white};
  border: 1px solid ${colors.veryLightPink};
  border-radius: 3px;
  width: 30px;
  height: 34px;
`;
Button.displayName = 'Button';

export const PDFIcon = styled(PDFImage)`
  height: 24px;
`;
PDFIcon.displayName = 'PDFIcon';

export const WordIcon = styled(WordImage)`
  height: 24px;
`;
WordIcon.displayName = 'WordIcon';

export const Options = styled.div`
  position: absolute;
  top: 0;
  left: 35px;
  background-color: ${colors.white};
  padding: 9px 0;
  width: 190px;
  cursor: pointer;
  box-shadow: 0 2px 15px 0 #e3e3e3;
  z-index: 1;
`;
Options.displayName = 'Options';

export const FormatOption = styled.div`
  padding: 5px 0 5px 17px;
  display: flex;
  &:hover {
    background-color: #edf2f7;
  }
`;
FormatOption.displayName = 'FormatOption';

export const Text = styled.p`
  color: #1c2243;
  font-size: 15px;
  margin-left: 10px;
`;
Text.displayName = 'Text';
