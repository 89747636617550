import React from 'react';
import { PropTypes } from 'prop-types';
import InputFilter from 'Common/Components/InputFilter';
import UserItem from './UserItem';
import TaskItem from './TaskItem';
import {
  Wrapper, Title, SubTitle, List, InputWrapper
} from './styled';

const SearchFilter = ({
  title, subtitle, data, placeholder, list, onAdd, users, readOnly
}) => (
  <Wrapper>
    <Title>{ title }</Title>
    {!readOnly && <SubTitle>{ subtitle }</SubTitle>}
    {!readOnly && (
      <InputWrapper>
        <InputFilter
          getObjects={ () => { } }
          data={ data }
          placeholder={ placeholder }
          onSelect={ (el) => onAdd(el) }
          onChange={ () => { } }
          cleanOnSelect
          required={ false }
          color="#90a9c0"
        />
      </InputWrapper>
    )}
    { list.length > 0
      ? (
        <List show={ list.length > 0 }>
          {users
            ? (
                list.map((item) => <UserItem canDelete={ !readOnly } key={ item.name } item={ item } />)
              )
            : list.map((item) => <TaskItem canDelete={ !readOnly } key={ item.name } item={ item } />)}
        </List>
        )
      : null}
  </Wrapper>
);

SearchFilter.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.instanceOf(Array).isRequired,
  placeholder: PropTypes.string,
  list: PropTypes.instanceOf(Array).isRequired,
  onAdd: PropTypes.func.isRequired,
  users: PropTypes.bool,
  readOnly: PropTypes.bool
};

SearchFilter.defaultProps = {
  title: '',
  subtitle: '',
  placeholder: '',
  users: false,
  readOnly: false
};

export default SearchFilter;
