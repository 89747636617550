import React, { useEffect, useState } from 'react';
import useSideBar from 'Hooks/useSideBar';
import {
  MODAL_SCHEDULE_CREATE_EDIT, MODAL_UPGRADE_LICENSE, MODAL_SCHEDULER_NO_WS_WARNING,
  MODAL_SCHEDULER_OFFLINE_AGENT_WARNING
} from 'store/modals/modals';
import upgradeLicenseImage from 'Images/scheduler-license.png';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_SCHEDULER, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { getCloudAgents, getUserAgents } from 'store/Agents/actions';
import { ACTIONS } from 'tracking/GA/constants';
import { selectIsFetching, selectData, selectLimitReached } from 'store/Schedule/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import { closeModal, openModal } from 'store/modals/actions';
import selectModal from 'store/modals/selectors';
import CreateEditSchedule from './components/CreateEditSchedule';
import TableContainer from './components/TableContainer';
import ActionBar from './components/ActionBar';
import NoWsWarning from './components/NoWsWarning';
import Wrapper from './styled';
import OfflineAgent from './components/OfflineAgent';
import { getWorkspaces } from 'Screens/Workspaces/actions/Actions';

const Schedule = () => {
  const [ModalCreateEdit, showCreateEdit, hideCreateEdit] = useSideBar(CreateEditSchedule, MODAL_SCHEDULE_CREATE_EDIT);
  const [isCreateSchedule, setIsCreateSchedule] = useState(true);
  const dispatch = useDispatch();
  const allowedByFeature = useSelector((state) => (selectAllowedByFeature(state, FEATURE_SCHEDULER, PERMISSION_TYPES.READ)));
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const data = useSelector((state) => (selectData(state)));
  const isFetching = useSelector((state) => (selectIsFetching(state)));
  const limitReached = useSelector(selectLimitReached);
  const noSchedule = data.rows && (data.rows.length === 0 && !isFetching);
  const isAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_SCHEDULER, PERMISSION_TYPES.READ));
  const isAllowed = allowedByFeature && isAllowedByRole;
  const showNoWsWarning = useSelector((state) => selectModal(state, MODAL_SCHEDULER_NO_WS_WARNING));
  const showOfflineAgentWarning = useSelector((state) => selectModal(state, MODAL_SCHEDULER_OFFLINE_AGENT_WARNING));

  useEffect(() => {
    if (!isAllowed) dispatch(redirect('/404'));
    dispatch(getUserAgents());
    dispatch(getCloudAgents());
  }, [dispatch, isAllowed, workspaceSelected]);

  useEffect(() => {
    dispatch(getWorkspaces(false, false));
  }, [dispatch]);

  useEffect(() => () => {
    dispatch(closeModal(MODAL_SCHEDULER_NO_WS_WARNING));
    dispatch(closeModal(MODAL_SCHEDULER_OFFLINE_AGENT_WARNING));
  }, [dispatch]);

  const showCreate = () => {
    if (limitReached) {
      dispatch(openModal(MODAL_UPGRADE_LICENSE));
    } else {
      setIsCreateSchedule(true);
      showCreateEdit();
    }
  };

  const showEdit = () => {
    setIsCreateSchedule(false);
    showCreateEdit(true);
  };

  return (
    <>
      <ModalCreateEdit isCreateSchedule={ isCreateSchedule } onAccept={ () => hideCreateEdit(false) } onClose={ () => hideCreateEdit(false) } />
      <Wrapper emptySchedule={ noSchedule }>
        <ActionBar toggleModalCreateSchedule={ showCreate } toggleModalEditSchedule={ showEdit } />
        <TableContainer toggleModalEditSchedule={ showEdit } />
        <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.schedule.description" trackingShow={ ACTIONS.showSchedule } trackingClick={ ACTIONS.clickSchedule } />
        </ReduxModal>
        { showNoWsWarning && <NoWsWarning /> }
        { showOfflineAgentWarning && <OfflineAgent /> }
      </Wrapper>
    </>
  );
};
export default Schedule;
