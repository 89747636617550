import styled from 'styled-components';
import BlueButton from 'Common/Components/BlueButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 30px;
`;

Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 13.5px;
  font-weight: 600;
  color: #0b2128;
  margin-bottom: 4px;
`;
Title.displayName = 'Title';

export const AddWorkspace = styled(BlueButton)`
  height: 34px;
  width: 243px;
`;
AddWorkspace.displayName = 'AddWorkspace';

export const Label = styled.div`
  font-size: 12.5px;
  line-height: 1.28;
  color: #0b2128;
  margin-bottom: 17px;
`;
Label.displayName = 'Label';

export const InputWrapper = styled.div`
  width: 243px;
`;
InputWrapper.displayName = 'InputWrapper';
