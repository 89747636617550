import styled from 'styled-components';

export const Header = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
  height: 34px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
Header.displayName = 'Header';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c2243;
`;
Title.displayName = 'Title';
