import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { saveLoginSettings } from 'store/Settings/loginSettingsActions';
import {
  Wrapper,
  Title
} from './styled';
import ChangePassword from './components/ChangePassword';
import LoginSettings from './components/LoginSettings';
import Header from '../Header';
import TwoFactor from './components/TwoFactor';
import Username from './components/Username';
import { selectUserEmail } from 'store/Settings/settingsSelectors';
import Email from './components/Email';
import { selectAutoSelectWs } from 'store/Preferences/workspace/selectors';
import { changePassword } from 'store/Settings/changePasswordActions';

const Account = ({ onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const email = useSelector(selectUserEmail);
  const logMeLastWorkspaceUsed = useSelector(selectAutoSelectWs);
  const [data, setData] = useState({ password: { currentPassword: '', newPassword: '', repeatNewPassword: '' }, logMeLastWorkspaceUsed });
  const [errors, setErrors] = useState({ passwordInvalid: false, passwordDoesntMatch: false });

  const close = () => {
    onClose();
  };

  const accept = () => {
    const { password: { currentPassword, newPassword, repeatNewPassword } } = data;
    const changeEmail = email !== data.email;
    const changeLogMeLastWs = logMeLastWorkspaceUsed !== data.logMeLastWorkspaceUsed;
    if (Object.values(data.password).every(item => item)) dispatch(changePassword(currentPassword, newPassword, repeatNewPassword));
    dispatch(saveLoginSettings(changeEmail, email, changeLogMeLastWs, data.logMeLastWorkspaceUsed));
    close();
  };

  return (
    <Wrapper>
      <Header disabled={ Object.values(errors).some(error => error) } onAccept={ accept } onClose={ close } />
      <Title>
        {intl.formatMessage({ id: 'preferences.account.title' }) }
      </Title>
      <Username />
      <Email email={ email } errors={ errors } setErrors={ setErrors } />
      <ChangePassword data={ data } setData={ setData }errors={ errors } setErrors={ setErrors } />
      <LoginSettings checked={ data.logMeLastWorkspaceUsed } setData={ (v) => setData({ ...data, logMeLastWorkspaceUsed: v }) } />
      <TwoFactor />
    </Wrapper>
  );
};

Account.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default Account;
