import React from 'react';
import { useIntl } from 'react-intl';

import { Section, Wrapper, Content } from './styled';
import Switch from './Switch';

const SectionSelector = ({ onClose }) => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Section>{intl.formatMessage({ id: 'preferences.userSessions.mainSettings.title' })}</Section>
      <Content>
        <Switch />
      </Content>
    </Wrapper>
  );
};
export default SectionSelector;
