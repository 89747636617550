import { useRef } from 'react';
import * as htmlToImage from 'html-to-image';
import saveAs from 'Common/Functions/SaveAs';

const useDownload = () => {
  const nodeRef = useRef(null);

  const downloadFunc = (fileName = 'faraday.png') => {
    htmlToImage.toPng(nodeRef.current, { width: nodeRef.current.scrollWidth, height: nodeRef.current.scrollHeight }).then((dataUrl) => {
      saveAs(dataUrl, fileName);
    });
  };

  return [nodeRef, downloadFunc];
};

export default useDownload;
