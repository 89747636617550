export const TASK_TYPE = [
  {
    value: 'task',
    label: 'Task'
  },
  {
    value: 'milestone',
    label: 'Milestone'
  }
];

export const TASK_PROGRESS = [
  {
    value: 'new',
    label: 'New'
  },
  {
    value: 'in progress',
    label: 'In progress'
  },
  {
    value: 'review',
    label: 'Review'
  },
  {
    value: 'completed',
    label: 'Completed'
  }
];

export const TASKS = [
  {
    name: 'task 1',
    progress: 'completed',
    id: 1
  },
  {
    name: 'task 2',
    progress: 'new',
    id: 2
  }
];
