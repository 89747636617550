import styled from 'styled-components';
import colors from 'Styles/colors';

const Wrapper = styled.div`
  height: 100%;
  padding-top: 8px;
  background-color: ${colors.white};
  overflow: hidden;
`;

export default Wrapper;
