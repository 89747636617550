import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as MoveVuln } from 'Images/moveVuln.svg';
import { slideIn } from 'Styles/effects';

export const MoveIcon = styled(MoveVuln)`
`;

export const ModalContent = styled.div`
  padding: 34px;
  width: 505px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: #fff;
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const Title = styled.h3`
font-size: 21.5px;
font-weight: 600;
line-height: 1.49;
letter-spacing: normal;
color: #1c2243;
margin: 0 0 0 14px;
`;

export const Subtitle = styled.h5`
font-size: 12.5px;
font-weight: 500;
line-height: 1.44;
color: #63758d;
margin-bottom: 12px;
`;

export const Flex = styled.div`
display:flex;
align-items: center;
margin-bottom: 17px;
`;

export const Footer = styled.div`
  display: flex;
  width: min-content;
  margin-top: 50px;
  margin-left: auto;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;
Footer.displayName = 'Footer';
