import {
  CREATION_TYPE, FILE_SELECTOR, INITIAL_MODE, SUMMARY, WORKSPACE_NAME, WORKSPACE_SELECTION
} from 'Screens/Wizard/constants';

import {
  LOGIN_TO_INACTIVE_WORKSPACE_ERROR,
  SET_INITIAL_STEP_WIZARD
} from '../../Screens/Login/actions/Actions';

import {
  RESET_INITIAL_STEP_WIZARD,
  ADD_WORKSPACE_REQUEST,
  ADD_WORKSPACE_SUCCESS,
  ADD_WORKSPACE_FAIL,
  RESET_STATE_WIZARD,
  SET_STEP_WIZARD,
  SET_WORKSPACE_NAME_WIZARD,
  UPLOAD_REPORT_ERROR_WIZARD,
  UPLOAD_REPORT_WIZARD_UPDATE_PERCENT,
  SET_WIZARD_COMMAND_ID,
  RESET_FILE_STATE_WIZARD,
  CANCEL_REQUEST_WIZARD,
  SET_USER_HAS_BEEN_IN_WIZARD,
  SET_SUMMARY_VALUES_IN_WIZARD,
  RESET_STATE_SUMMARY_WIZARD,
  HIDE_ERROR_MODAL_WIZARD,
  SET_USER_ROLE
} from '../../Screens/Wizard/actions/Actions';

const initialState = {
  initialStep: null,
  workspaces: [],
  isFetching: false,
  error: false,
  errorMessage: '',
  user: {},
  isSuccess: false,
  width: '450px',
  height: '413px',
  workspaceName: '',
  currentUploadState: '',
  currentUploadPercent: 0,
  currentUploadError: '',
  userHasBeenInWizard: false,
  summary: {
    filename: '',
    vulnCount: 0,
    hostsCount: 0,
    servicesCount: 0
  },
  createNewWorkspace: false
};

function wizard (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STEP_WIZARD: {
      return {
        ...state,
        step: action.step,
        workspaces: action.workspaces,
        width: action.width,
        height: action.height
      };
    }

    case RESET_INITIAL_STEP_WIZARD: {
      return {
        ...state,
        step: null,
        width: '450px',
        height: '413px'
      };
    }

    case RESET_STATE_WIZARD:
      return { ...initialState };

    case RESET_STATE_SUMMARY_WIZARD:
      return {
        ...state,
        summary: initialState.summary
      };

    case ADD_WORKSPACE_REQUEST:
      return { ...state, isFetching: true };

    case ADD_WORKSPACE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
        isSuccess: true,
        workspaces: [...state.workspaces, action.data],
        workspaceName: action.data.name
      };
    case ADD_WORKSPACE_FAIL:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };

    case SET_STEP_WIZARD: {
      const width = '450px';
      let height = '413px';
      switch (action.step) {
        case WORKSPACE_NAME:
          height = '413px';
          break;
        case CREATION_TYPE:
          height = '438px';
          break;
        case FILE_SELECTOR:
          height = '550px';
          break;
        case INITIAL_MODE:
          // height = '550px';
          height = '500px';
          break;
        case SUMMARY:
          height = '466px';
          break;
        case WORKSPACE_SELECTION:
          height = '506px';
          break;
        default:
          height = '413px';
          break;
      }

      return {
        ...state,
        step: action.step,
        createNewWorkspace: action.step === WORKSPACE_NAME ? action.createNew : false,
        width,
        height,
        isSuccess: false
      };
    }

    case SET_WORKSPACE_NAME_WIZARD: {
      return {
        ...state,
        workspaceName: action.ws
      };
    }

    case UPLOAD_REPORT_ERROR_WIZARD: {
      return {
        ...state,
        currentUploadState: 'Error',
        currentUploadPercent: 0,
        currentUploadError: action.error
      };
    }

    case UPLOAD_REPORT_WIZARD_UPDATE_PERCENT: {
      return {
        ...state,
        currentUploadState: 'Uploading',
        currentUploadPercent: Math.round((100 * action.progressEvent.loaded) / action.progressEvent.total),
        currentUploadError: ''
      };
    }

    case SET_WIZARD_COMMAND_ID: {
      return {
        ...state,
        commandId: action.commandId,
        currentUploadState: 'Success',
        currentUploadPercent: 100,
        currentUploadError: ''
      };
    }

    case RESET_FILE_STATE_WIZARD: {
      return {
        ...state,
        currentUploadState: '',
        currentUploadPercent: 0,
        currentUploadError: ''
      };
    }

    case CANCEL_REQUEST_WIZARD: {
      return {
        ...state,
        currentUploadState: 'Canceled',
        currentUploadPercent: 0,
        currentUploadError: ''
      };
    }

    case SET_USER_HAS_BEEN_IN_WIZARD: {
      return {
        ...state,
        userHasBeenInWizard: action.value
      };
    }

    case SET_SUMMARY_VALUES_IN_WIZARD: {
      return {
        ...state,
        summary: action.data
      };
    }

    case LOGIN_TO_INACTIVE_WORKSPACE_ERROR: {
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage
      };
    }

    case HIDE_ERROR_MODAL_WIZARD: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }

    case SET_USER_ROLE: {
      return {
        ...state,
        user: action.userRole
      };
    }

    default:
      return state;
  }
}

export default wizard;
