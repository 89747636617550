import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_TAGS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useIntl } from 'react-intl';
import { Wrapper, Button } from './styled';
import { setCreationTagModal } from 'Screens/Tags/actions/Actions';

const AddTagButton = () => {
  const intl = useIntl();
  const editLabel = intl.formatMessage({ id: 'manage.detail.tab.general.tags.edit' });
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_TAGS, PERMISSION_TYPES.CREATE));
  const dispatch = useDispatch();

  if (!canUpdate) return null;
  return (
    <Wrapper>
      <Button onClick={ () => dispatch(setCreationTagModal(true, 'vulnDetail')) }>{ editLabel }</Button>
    </Wrapper>
  );
};

export default AddTagButton;
