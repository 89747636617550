import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectVulnTemplateDetail, selectVulnTemplateDetailId } from 'store/KnowledgeBase/selectors';
import debounce from 'lodash/debounce';
import { updateVulnTemplate } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { Wrapper, InputTitle, IconKb } from './styled';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULN_TEMPLATE, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const Title = () => {
  const dispatch = useDispatch();
  const vulnTemplateDetail = useSelector(selectVulnTemplateDetail);
  const vulnTemplateId = useSelector(selectVulnTemplateDetailId);
  const [vulnTemplateName, setVulnTemplateName] = useState(vulnTemplateDetail.name);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULN_TEMPLATE, PERMISSION_TYPES.UPDATE));

  useEffect(() => {
    setVulnTemplateName(vulnTemplateDetail.name);
  }, [vulnTemplateDetail]);

  const updateName = useRef(debounce((name) => {
    if (name) {
      dispatch(updateVulnTemplate(vulnTemplateId, { name }));
    }
  }, 1000));

  const handleChange = (e) => {
    const name = e.target.value;
    setVulnTemplateName(name);
    updateName.current(name);
  };

  return (
    <Wrapper>
      <IconKb />
      <InputTitle value={ vulnTemplateName } onChange={ handleChange } disabled={ !canUpdate } />
    </Wrapper>
  );
};

export default Title;
