import get from 'lodash/get';
import handleLeftClick from 'Common/Components/CustomReactTable/leftClickHelper';

export default function getTrProps (rowInfo, data, setShowContextMenu, userSelected, lastUsersSelected, lastIndexForRange, lastSelected, selectUserAction) {
  const selected = userSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

  const isSelectable = (rowInfo) => get(rowInfo, 'active', true);

  return {
    onClick: (e) => {
      if (isSelectable(rowInfo.original)) setShowContextMenu(true);
      else setShowContextMenu(false);

      const leftClickResponse = handleLeftClick(e, rowInfo, '', selected, data, userSelected, lastUsersSelected, lastIndexForRange, lastSelected, isSelectable);
      if (leftClickResponse) selectUserAction(leftClickResponse);
      return true;
    },
    onContextMenu: (e) => {
      if (isSelectable(rowInfo.original)) {
        if (!selected) {
          selectUserAction({ newRowsSelected: [rowInfo.original] });
        }
        setShowContextMenu(true);
      } else {
        setShowContextMenu(false);
      }
      e.preventDefault();
      return false;
    },
    style: {
      background: selected ? '#e2f0fb' : 'transparent'
    }
  };
}
