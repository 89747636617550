import fetchApi from 'services/api/connector';

export const getVulnsCount = () => fetchApi('vulns/count?group_by=confirmed');

export const fetchVulns = (queryParam) => fetchApi(`vulns/filter?q=${escape(JSON.stringify(queryParam))}`);

export const fetchVulnById = (id) => fetchApi(`vulns/${id}`);

export const deleteVulns = (ids) => fetchApi('vulns', { method: 'DELETE', data: { ids } });

export const deleteAllVulns = (queryParam) => fetchApi(`vulns?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'DELETE', version: 'v3' });

export const updateVulns = (data) => fetchApi('vulns', { method: 'PATCH', data, version: 'v3' });

export const updateAllVulns = (queryParam, data) => fetchApi(`vulns?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'PATCH', data, version: 'v3' });

export const setVulnsTags = (data) => fetchApi('vulns/set_tags', { method: 'POST', data, version: 'v3' });

export const setAllVulnsTags = (queryParam, data) => fetchApi(`vulns/set_tags?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'POST', data, version: 'v3' });

export const getVulnDuplicates = (id) => fetchApi(`vulns/${id}/duplicates`);

export const markDuplicates = (data) => fetchApi('vulns/duplicates/associate', { method: 'POST', data });

export const removeDuplicates = (data) => fetchApi('vulns/duplicates/disassociate', { method: 'POST', data });

export const markAsMainDuplicate = (data) => fetchApi('vulns/duplicates/mark_as_main', { method: 'POST', data });

export const getComments = (id) => fetchApi(`comment/vulnerability/${id}`);

export const newComment = (data) => fetchApi('comment', { method: 'POST', data, version: 'v3' });

export const removeComment = (id) => fetchApi(`comment/${id}`, { method: 'DELETE', version: 'v3' });

export const saveComment = (id, data) => fetchApi(`comment/${id}`, { method: 'PATCH', data, version: 'v3' });
