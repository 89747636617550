import React from 'react';
import useKeypress from 'Hooks/useEscKey';
import { closeModal } from 'store/modals/actions';
import selectModal from 'store/modals/selectors';
import { useDispatch, useSelector } from 'react-redux';
import ModalWrapper from './styled';

const ReduxModal = ({ children, id }) => {
  const dispatch = useDispatch();
  const showModal = useSelector((state) => selectModal(state, id));

  useKeypress('Escape', () => {
    dispatch(closeModal(id));
  }, [id]);

  if (!showModal) return null;
  return (
    <ModalWrapper>
      { children }
    </ModalWrapper>
  );
};

export default ReduxModal;
