import React from 'react';
import LoadingGif from 'Images/faraday_loadingbar.gif';
import { Wrapper, FileName, LoadingIcon, Status } from './styled';

const TestingFile = ({ name }) => {
  return (
    <Wrapper>
      <FileName>{name}</FileName>
      <LoadingIcon src={ LoadingGif } />
      <Status>Uploading...</Status>
    </Wrapper>
  );
};

export default TestingFile;
