import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Help } from 'Images/help-sysreq.svg';
import { ReactComponent as _DeleteIcon } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as CloudIcon } from 'Images/icon_upload.svg';

export const Wrapper = styled.div`
  margin-left: 33px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  text-overflow: ellipsis;
  text-align: left;
  user-select: none;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: #8f94b2;
  margin-right: 10px;
`;
Title.displayName = 'Title';

export const UploadLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 82px;
  height: 34px;
  border-radius: 3px;
  border: none;
  color: ${colors.white};
  background-color: ${colors.blueCerulean};
  padding: 7px 0;
  margin: 7px 0 0 0;
  cursor: pointer;
  &:hover {
    background-color: ${colors.blue14};
  }
`;
UploadLabel.displayName = 'UploadLabel';

export const Upload = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
`;
Upload.displayName = 'Upload';

export const HelpIcon = styled(Help)`
  width: 18px;
  height: 18px;
`;
HelpIcon.displayName = 'HelpIcon';

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 17px;
`;
Header.displayName = 'Header';

export const Box = styled.div`
  width: 139px;
  height: 139px;
  background-color: ${colors.white};
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.07);
  border: solid 1px ${colors.grey32};
  padding: 19px 18px 20px 19px;
  cursor: pointer;
  position: relative;
`;
Box.displayName = 'Box';

export const InactiveBox = styled.div`
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.07);
  background-color: ${colors.iceBlue};
  width: 100px;
  height: 100px;
  padding: 8px 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
InactiveBox.displayName = 'InactiveBox';

export const ActiveDragBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border: 1px solid #0082ed;
  background-color: aliceblue;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
ActiveDragBox.displayName = 'ActiveDragBox';

export const Delete = styled.div`
  background-color: ${colors.white};
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 36px;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #d9e4ef;
  display: none;
`;
Delete.displayName = 'Delete';

export const LogoWrapper = styled.div`
  height: 100px;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.07);
  position: relative;
  user-select: none;
  &:hover{
    & ${Delete}{
      display:block;
    }
  }
`;
LogoWrapper.displayName = 'LogoWrapper';

export const DeleteIcon = styled(_DeleteIcon)`
  height: 17px;
  width: 16px;
  margin: 8px 9px;
  path {
    fill: ${colors.blueGrey};
  }
`;
DeleteIcon.displayName = 'DeleteIcon';

export const Logo = styled.img`
  object-fit: scale-down;
  border-radius: 3px;
`;
Logo.displayName = 'Logo';

export const Text = styled.p`
  color: #0082ed;
  font-size: 14px;
  user-select: none;
  margin-top: 10px;
`;
Text.displayName = 'Text';

export const TextInactive = styled(Text)`
  color: ${colors.greyBlue};
  font-weight: 600;
  margin: 3px 0 0 0;
`;
TextInactive.displayName = 'TextInactive';

export const Cloud = styled(CloudIcon)`
  display: block;
  cursor: default;
  width: 25px;
  height: 20px;
  & path{
    fill: ${({ color }) => (color || '#7a7e8f')};

  }
`;
