const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  hostList: [],
  hostCount: 0, // total de hosts filtrados
  addColumnsOpened: false,
  mapGroupByIndex: {},
  showModalEditCreate: false,
  hostsSelected: [],
  lastSelected: -1,
  lastHostSelected: {},
  lastIndexForRange: -1,
  totalHosts: 0, // total de hosts sin filtros
  limitReached: false, // valor que determina si se alcanzo el limit maximo de hosts que se pueden crear.
  showLeftFilters: false,
  showDeleteConfirmation: false,
  bulkUpdateField: '',
  bulkUpdateValue: ''
};

export default initialState;
