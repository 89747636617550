import fetchApi from 'services/api/connector';

export const fetchTasks = (id) => fetchApi(`projects/${id}/tasks`, { method: 'GET' });
export const fetchTask = (taskId) => fetchApi(`ptasks/${taskId}`);
export const createTask = (data) => fetchApi('ptasks', { method: 'POST', data });
export const editTask = (taskId, data) => fetchApi(`ptasks/${taskId}`, { method: 'PATCH', data });
export const deleteTask = (taskId) => fetchApi(`ptasks/${taskId}`, { method: 'DELETE' });

export const addComment = (data) => fetchApi('comment', { method: 'POST', data });
export const editComment = (commentId, data) => fetchApi(`comment/${commentId}`, { method: 'PATCH', data });
export const deleteComment = (commentId) => fetchApi(`comment/${commentId}`, { method: 'DELETE' });
export const fetchComments = (taskId) => fetchApi(`comment/project_task/${taskId}`, { method: 'GET' });
