import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { CUSTOM_ATTRIBUTE_TYPES as types, TYPE_TO_PLACEHOLDER as placeholders } from 'Screens/Contextualization/Manage/components/CustomAttributesModal/constants';
import { Wrapper, PlaceholderText } from './styled';

const CustomFieldContainer = ({ children, onShow, field, showContent }) => {
  const isChoiceField = field.field_type === types.CHOICE;
  const isMarkdownField = field.field_type === types.MARKDOWN;
  const canShowContent = isChoiceField || isMarkdownField || showContent;
  const showPlaceholder = !isChoiceField && !isMarkdownField && !showContent;

  return (
    <Wrapper>
      {showPlaceholder &&
                (
                <PlaceholderText onClick={ () => onShow() }>
                  <FormattedMessage id={ placeholders[field.field_type] } />
                </PlaceholderText>
                )}
      {canShowContent && children}
    </Wrapper>
  );
};
CustomFieldContainer.propTypes = {
  field: PropTypes.shape({
    field_type: PropTypes.string,
    field_metadata: PropTypes.string
  }).isRequired
};

export default CustomFieldContainer;
