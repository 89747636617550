import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { Trash } from 'Screens/Manage/components/CustomActionBar/styled';
import { someAgentIsSelected } from 'store/Agents/selectors';
import { removeSelectedAgents } from 'store/Agents/actions';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  &::before {
    width: 1px;
    content: ' ';
    background: transparent;
    border-right: 1px #B5BEC6;
    border-right-style: dashed;
    height: 14px;
    margin: 0px 8px 0px 18px;
  }
`;

const DeleteButton = () => {
  const canDelete = useSelector(someAgentIsSelected);
  const dispatch = useDispatch();
  const handleClick = () => dispatch(removeSelectedAgents());

  if (!canDelete) return null;
  return (
    <Wrapper>
    <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } />
    </Wrapper>
  );
};

export default DeleteButton;
