import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';

export const Wrapper = styled.div`
  cursor: ${({ disabled }) => { if (disabled) return 'not-allowed !important'; else return 'pointer'; }};
  display: flex;
  border-radius: 20px;
  width: 37px;
  height: 37px;
  align-items: center;
  justify-content: center;
  & > svg {
    cursor: pointer;
    min-width: 17px;
  }
  &:hover {
    background-color: ${colors.iceBlue};
  }

  ${({ disabled }) => !disabled &&
    css`&:active {
      ${fadeIn(0, 1)}
      background-color: ${colors.blueGrey};
      & > svg {
        g, path {
          fill: ${colors.white};
        }
      }
    }
  `}

  ${({ disabled }) => {
    if (!disabled) return null;
    return `
      opacity: 0.5;
      `;
  }}

`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.div`
`;
Text.displayName = 'Text';
