import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  position: relative;
  width: 100px;
`;
Wrapper.displayName = 'Wrapper';

export const Button = styled.button`
  border-radius: 2px;
  border: solid 1px #707070;
  background-color: ${colors.white};
  color: ${colors.darkBlueGrey};
  text-align: center;
  min-width: 89px;
  width: auto;
  height: 34px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px;
  cursor: pointer;

  ${({ disabled }) => {
    if (!disabled) return null;
    return `
      pointer-events: none;
      color: ${colors.grey27};
      border: solid 1px  ${colors.grey27};
      `;
  }}
`;
Button.displayName = 'Button';
