import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { Edit } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';
import { redirectToHostDetail } from 'store/Contextualization/AssetDetail/actions';
import { selectAssetRow, unSelectAll } from 'store/Host/actions';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { redirectToHostDetailGeneral, selectHostRow, unSelectAllHosts } from 'store/HostsGeneral/actions';

const EditHostButton = ({ hasHosts, entity, hostsSelected }) => {
  const dispatch = useDispatch();
  const hostId = get(hostsSelected, '[0].id', 0);
  const host = get(hostsSelected, '[0]', null);
  const [showWarning, setShowWarning] = useState(false);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.UPDATE));
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'asset' });

  const handleClick = (e) => {
    if (hasHosts && allowedByRole) {
      if ((hostsSelected.length > 0)) showHostDetail(e);
      else setShowWarning(true);
    }
  };

  const showHostDetail = (e) => {
    if (entity === 'assets') {
      dispatch(unSelectAll());
      dispatch(redirectToHostDetail(hostId));
      dispatch(selectAssetRow(e, host));
    } else {
      dispatch(unSelectAllHosts());
      dispatch(redirectToHostDetailGeneral(hostId));
      dispatch(selectHostRow(e, host));
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } disabled={ !hasHosts || !allowedByRole } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Edit" description={ message } /> }
    </>
  );
};

export default EditHostButton;
