import styled from 'styled-components';
import { ReactComponent as CommentImage } from 'Images/icon-comments.svg';
import { ReactComponent as EditImage } from 'Images/icon-edit.svg';
import { ReactComponent as DeleteImage } from 'Images/icon-trash.svg';
import AddButton from 'Common/Components/AddButton';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding-right: 14px;
  width: 74px;
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.div`
  padding-left: 45px;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  &:after {
    ${({ isMilestone }) => isMilestone && `
      margin-left: 10px;
      background-color: ${colors.blueGrey};
      content: 'Milestone';
      font-size: 12.5px;
      font-weight: 700;
      color: ${colors.white};
      height: 25px;
      width: 79px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
    }
`;
Text.displayName = 'Text';

export const Name = styled.div`
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ isMilestone }) => isMilestone && `
    max-width: 64%;
  `}
  &:hover {
    text-decoration: underline;
  }
`;
Name.displayName = 'Name';

export const EditIcon = styled(EditImage)`
    margin: auto;
    g, path {
      fill: #264d6d;
    }
`;
EditIcon.displayName = 'EditIcon';

export const DeleteIcon = styled(DeleteImage)`
    margin: auto;
    g, path {
      fill: #264d6d;
    }
`;
DeleteIcon.displayName = 'DeleteIcon';

export const CommentIcon = styled(CommentImage)`
    margin: auto;
    g, path {
      fill: #264d6d;
    }
`;
DeleteIcon.displayName = 'DeleteIcon';

export const Add = styled(AddButton)`
    height: 34px;
    width: 95px;
    background-color: #fafbfc;
`;
Add.displayName = 'Add';

export const CellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
CellWrapper.displayName = 'CellWrapper';

export const ActionButtonsWrapper = styled.div.attrs((props) => ({
  className: props.className
}))`
  display: flex;
`;
ActionButtonsWrapper.displayName = 'ActionButtonsWrapper';
