/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { Col, Row } from 'Common/Components/Grid/index';
import {
  VictoryPie, VictoryTooltip, Slice
} from 'victory';
import { VULNS_BY_RISK_TYPE_FILTERS, CLOSED_FILTERS, CONFIRMED_FLAG_FILTERS } from 'store/Filters/constants';
import { setAdvancedFilter, setAdvancedMode } from 'store/Filters/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import colors from 'Styles/colors';
import { redirect } from 'store/Router/actions';
import { isFilteringBy } from 'store/Filters/selectors';
import {
  Wrapper, Title, Empty, FlyoutWrapper, Name, Value, Data,
  PieWrapper, References, Reference
} from './styled';
import isEmpty from 'lodash/isEmpty';
import formatNumber from 'Common/Functions/FormatNumber';

const RiskChart = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { confirmed } = CONFIRMED_FLAG_FILTERS;
  const { closed } = CLOSED_FILTERS;
  const stats = useSelector((state) => get(state, 'dashboard.tools[3].data.stats', {}));
  const workspaceSelected = useSelector((state) => selectCurrentWorkspace(state));
  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));
  const isFilteringByClosed = useSelector((state) => isFilteringBy(state, 'vulns', closed));
  const title = intl.formatMessage({ id: 'dashboard.riskChart.title' });

  const data = [];

  if (!isEmpty(stats) && stats.critical_risk_count > 0) data.push({ id: 'critical_risk_count', name: 'Critical Risk', value: stats.critical_risk_count });
  if (!isEmpty(stats) && stats.high_risk_count > 0) data.push({ id: 'high_risk_count', name: 'High Risk', value: stats.high_risk_count });
  if (!isEmpty(stats) && stats.medium_risk_count > 0) data.push({ id: 'medium_risk_count', name: 'Medium Risk', value: stats.medium_risk_count });
  if (!isEmpty(stats) && stats.low_risk_count > 0) data.push({ id: 'low_risk_count', name: 'Low Risk', value: stats.low_risk_count });
  if (!isEmpty(stats) && stats.informational_risk_count > 0) data.push({ id: 'informational_risk_count', name: 'Info Risk', value: stats.informational_risk_count });

  const sliceColor = (name) => {
    if (name === 'Critical Risk') return colors.purple6;
    else if (name === 'High Risk') return colors.red5;
    else if (name === 'Medium Risk') return colors.orange4;
    else if (name === 'Low Risk') return colors.green7;
    else return colors.grey2;
  };

  const filterBy = () => {
    if (isFilteringByConfirm && isFilteringByClosed) return 'notClosedAndConfirmed';
    else if (isFilteringByConfirm && !isFilteringByClosed) return 'confirmed';
    else if (!isFilteringByConfirm && isFilteringByClosed) return 'notClosed';
    else return 'all';
  };

  const handleClick = (risk) => {
    const filter = filterBy();
    dispatch(setAdvancedFilter('vulns', VULNS_BY_RISK_TYPE_FILTERS[risk][filter]));
    dispatch(setAdvancedMode(true));
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  const CustomSlice = (props) => (
    <Slice
    /* eslint-disable react/jsx-props-no-spreading */
      { ...props }
      events={ {
        onClick: () => {
          handleClick(props.datum.id);
        },
        onMouseOver: (e) => {
          if (props.events.onMouseOver) {
            props.events.onMouseOver(e);
          }
        },
        onMouseOut: (e) => {
          if (props.events.onMouseOut) {
            props.events.onMouseOut(e);
          }
        }
      } }
    />
  );

  const Flyout = ({
    datum
  }) => (
    <g>
      <foreignObject x={ 130 } y={ 130 } width="140" height="140">
        <FlyoutWrapper xmlns="http://www.w3.org/1999/xhtml" risk={ datum.name }>
          <Data>
            <Value>{ formatNumber(datum.value) }</Value>
            <Name>{ datum.name }</Name>
          </Data>
        </FlyoutWrapper>
      </foreignObject>
    </g>
  );
  return (
    <Wrapper style={ { width: '100', height: 275 } }>
      <Row>
        <Col><Title>{ title }</Title></Col>
      </Row>
      { data.length > 0
        ? (
        <PieWrapper>
          <VictoryPie
            dataComponent={ <CustomSlice /> }
            data={ data }
            x="name"
            y="value"
            style={ {
              data: {
                fill: ({ datum }) => sliceColor(datum.name)
              }
            } }
            labels={ () => '' }
            labelComponent={
              <VictoryTooltip flyoutComponent={ <Flyout data={ data } /> } />
            }
            innerRadius={ 100 }
            events={ [
              {
                target: 'data',
                eventHandlers: {
                  onMouseOver: () => [
                    {
                      target: 'labels',
                      mutation: () => ({ active: true })
                    }
                  ],
                  onMouseOut: () => [
                    {
                      target: 'data',
                      mutation: () => {}
                    },
                    {
                      target: 'labels',
                      mutation: () => ({ active: false })
                    }
                  ]
                }
              }
            ] }
          />
           <References>
            {data.map((risk) => <Reference onClick={ () => handleClick(risk.id) } risk={ risk.name } key={ risk.id }>{ formatNumber(risk.value) }</Reference>)}
           </References>
        </PieWrapper>
          )
        : <Empty>No vulnerabilities has been found yet.</Empty> }
    </Wrapper>
  );
};

export default RiskChart;
