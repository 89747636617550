/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */
// Copyright FIRST, Red Hat, and contributors
// SPDX-License-Identifier: BSD-2-Clause

/**
* Class representing a CVSS (Common Vulnerability Scoring System) v4.0 vector.
*
* In mathematics and computer science, a vector is a collection of elements (often numbers) that can represent different dimensions of data.
* Similarly, in CVSS, the vector string represents various dimensions of a vulnerability's characteristics.
*
* The Vector class encapsulates the CVSS v4.0 metrics, allowing for the creation,
* manipulation, and validation of CVSS vectors. It supports generating a vector string
* dynamically based on current metric values, updating metrics from an input vector string,
* and computing equivalent classes for higher-level assessments.
*/
const Vector = function (vectorString) {
  // CVSS40 metrics with defaults values at first key
  const METRICS = {
    // Base (11 metrics)
    BASE: {
      AV: ['N', 'A', 'L', 'P'],
      AC: ['L', 'H'],
      AT: ['N', 'P'],
      PR: ['N', 'L', 'H'],
      UI: ['N', 'P', 'A'],
      VC: ['N', 'L', 'H'],
      VI: ['N', 'L', 'H'],
      VA: ['N', 'L', 'H'],
      SC: ['N', 'L', 'H'],
      SI: ['N', 'L', 'H'],
      SA: ['N', 'L', 'H']
    },
    // Threat (1 metric)
    THREAT: {
      E: ['X', 'A', 'P', 'U']
    },
    // Environmental (14 metrics)
    ENVIRONMENTAL: {
      CR: ['X', 'H', 'M', 'L'],
      IR: ['X', 'H', 'M', 'L'],
      AR: ['X', 'H', 'M', 'L'],
      MAV: ['X', 'N', 'A', 'L', 'P'],
      MAC: ['X', 'L', 'H'],
      MAT: ['X', 'N', 'P'],
      MPR: ['X', 'N', 'L', 'H'],
      MUI: ['X', 'N', 'P', 'A'],
      MVC: ['X', 'H', 'L', 'N'],
      MVI: ['X', 'H', 'L', 'N'],
      MVA: ['X', 'H', 'L', 'N'],
      MSC: ['X', 'H', 'L', 'N'],
      MSI: ['X', 'S', 'H', 'L', 'N'],
      MSA: ['X', 'S', 'H', 'L', 'N']
    },
    // Supplemental (6 metrics)
    SUPPLEMENTAL: {
      S: ['X', 'N', 'P'],
      AU: ['X', 'N', 'Y'],
      R: ['X', 'A', 'U', 'I'],
      V: ['X', 'D', 'C'],
      RE: ['X', 'L', 'M', 'H'],
      U: ['X', 'Clear', 'Green', 'Amber', 'Red']
    }
  };

  const ALL_METRICS = Object.keys(METRICS).reduce((order, category) => {
    return { ...order, ...METRICS[category] };
  }, {});

  // Nomenclature base constant
  const BASE_NOMENCLATURE = 'CVSS-B';

  /**
   * Validates a CVSS v4.0 vector string.
   *
   * This method checks the structure of a given CVSS v4.0 vector string to ensure it adheres to the expected format and values.
   * It verifies the presence of the "CVSS:4.0" prefix, the mandatory metrics, and their valid values.
   *
   * @param {string} vector - The CVSS v4.0 vector string to validate (e.g., "CVSS:4.0/AV:L/AC:L/AT:P/PR:N/UI:N/VC:H/VI:H/VA:H/SC:N/SI:N/SA:N/E:A/MAV:A/AU:N/R:A").
   * @returns {boolean} - Returns true if the vector is valid, otherwise false.
   */
  Vector.prototype.validateStringVector = function (vector) {
    const metrics = vector.split('/');

    // Check if the prefix is correct
    if (metrics.shift() !== 'CVSS:4.0') {
      console.error('Error: invalid vector, missing CVSS v4.0 prefix from vector: ' + vector);
      return false;
    }

    const expectedMetrics = Object.entries(ALL_METRICS);
    let mandatoryMetricIndex = 0;

    for (const metric of metrics) {
      const [key, value] = metric.split(':');

      // Check if there are too many metric values
      if (!expectedMetrics[mandatoryMetricIndex]) {
        console.error('Error: invalid vector, too many metric values');
        return false;
      }

      // Find the current expected metric
      while (expectedMetrics[mandatoryMetricIndex] && expectedMetrics[mandatoryMetricIndex][0] !== key) {
        // Check for missing mandatory metrics
        if (mandatoryMetricIndex < 11) {
          console.error('Error: invalid vector, missing mandatory metrics');
          return false;
        }
        mandatoryMetricIndex++;
      }

      // Check if the value is valid for the given metric
      if (!expectedMetrics[mandatoryMetricIndex][1].includes(value)) {
        console.error(`Error: invalid vector, for key ${key}, value ${value} is not in ${expectedMetrics[mandatoryMetricIndex][1]}`);
        return false;
      }

      mandatoryMetricIndex++;
    }

    return true;
  };

  /**
     * Updates the `metrics` object with values from a provided CVSS v4.0 vector string.
     *
     * This method parses a CVSS v4.0 vector string and updates the `metrics` object
     * with the corresponding metric values. The method validates the vector string
     * to ensure it adheres to the expected CVSS v4.0 format before processing.
     *
     * Example usage:
     * ```
     * vector.updateMetricsFromVectorString("CVSS:4.0/AV:L/AC:L/PR:N/UI:R/...");
     * ```
     *
     * @param {string} vectorString - The CVSS v4.0 vector string to be parsed and applied
     *                                (e.g., "CVSS:4.0/AV:L/AC:L/PR:N/UI:N/...").
     * @throws {Error} - Throws an error if the vector string is invalid or does not conform to the expected format.
     */
  Vector.prototype.updateMetricsFromVectorString = function (vector) {
    if (!vector) {
      throw new Error('The vector string cannot be null, undefined, or empty.');
    }

    // Validate the CVSS v4.0 string vector
    if (!this.validateStringVector(vector)) {
      throw new Error('Invalid CVSS v4.0 vector: ' + vector);
    }

    const metrics = vector.split('/');

    // Remove the "CVSS:4.0" prefix
    metrics.shift();

    // Iterate through each metric component and update the corresponding metric in the `metrics` object
    for (const metric of metrics) {
      const [key, value] = metric.split(':');
      this.metrics[key] = value;
    }
  };

  /**
   * Initializes a new Vector instance with optional CVSS vector string.
   *
   * This constructor initializes the metrics with their default values based on the CVSS v4.0 specification.
   * If a vector string is provided, it parses the string and updates the metrics accordingly.
   *
   * @param {string} [vectorString=""] - Optional CVSS v4.0 vector string to initialize the metrics (e.g., "CVSS:4.0/AV:L/AC:L/PR:N/UI:R/...").
   */
  // Initialize the metrics
  const selected = {};
  for (const category in METRICS) {
    for (const key in METRICS[category]) {
      // Use the first value in the array of allowed values as the default
      selected[key] = METRICS[category][key][0];
    }
  }

  this.metrics = selected;

  if (vectorString) {
    // Remove any leading '#' symbol
    if (vectorString.startsWith('#')) {
      vectorString = vectorString.slice(1);
    }
    this.updateMetricsFromVectorString(vectorString);
  }

  /**
   * Dynamically generates the `raw` CVSS vector string based on the current state of `metrics`.
   *
   * This getter constructs the vector string from the `metrics` object, including only those metrics
   * that are not set to "X". The string starts with "CVSS:4.0" followed by each metric and its value.
   *
   * @return {string} - The CVSS vector string in the format "CVSS:4.0/AV:N/AC:L/..."
   */
  Vector.prototype.raw = function () {
    // Construct the vector string dynamically based on the current state of `metrics`
    const baseString = 'CVSS:4.0';
    const metricEntries = Object.entries(this.metrics)
      .filter(([key, value]) => value !== 'X') // Filter out metrics with value "X"
      .map(([key, value]) => `/${key}:${value}`)
      .join('');
    return baseString + metricEntries;
  };

  /**
   * Computes the equivalent classes for the given CVSS metrics.
   *
   * This method aggregates multiple detailed security metrics into a higher-level
   * equivalent classes that represents the overall security posture.
   *
   * @returns {string} - The equivalent classes (e.g., "002201").
   */
  Vector.prototype.equivalentClasses = function () {
    // Helper function to compute EQ1
    const computeEQ1 = () => {
      const AV = this.getEffectiveMetricValue('AV');
      const PR = this.getEffectiveMetricValue('PR');
      const UI = this.getEffectiveMetricValue('UI');

      if (AV === 'N' && PR === 'N' && UI === 'N') {
        return '0';
      }
      if ((AV === 'N' || PR === 'N' || UI === 'N') &&
              !(AV === 'N' && PR === 'N' && UI === 'N') &&
              AV !== 'P') {
        return '1';
      }
      if (AV === 'P' || !(AV === 'N' || PR === 'N' || UI === 'N')) {
        return '2';
      }
    };

    // Helper function to compute EQ2
    const computeEQ2 = () => {
      const AC = this.getEffectiveMetricValue('AC');
      const AT = this.getEffectiveMetricValue('AT');

      return (AC === 'L' && AT === 'N') ? '0' : '1';
    };

    // Helper function to compute EQ3
    const computeEQ3 = () => {
      const VC = this.getEffectiveMetricValue('VC');
      const VI = this.getEffectiveMetricValue('VI');
      const VA = this.getEffectiveMetricValue('VA');

      if (VC === 'H' && VI === 'H') {
        return '0';
      }
      if (!(VC === 'H' && VI === 'H') && (VC === 'H' || VI === 'H' || VA === 'H')) {
        return '1';
      }
      if (!(VC === 'H' || VI === 'H' || VA === 'H')) {
        return '2';
      }
    };

    // Helper function to compute EQ4
    const computeEQ4 = () => {
      const MSI = this.getEffectiveMetricValue('MSI');
      const MSA = this.getEffectiveMetricValue('MSA');
      const SC = this.getEffectiveMetricValue('SC');
      const SI = this.getEffectiveMetricValue('SI');
      const SA = this.getEffectiveMetricValue('SA');

      if (MSI === 'S' || MSA === 'S') {
        return '0';
      }
      if (!(MSI === 'S' || MSA === 'S') && (SC === 'H' || SI === 'H' || SA === 'H')) {
        return '1';
      }
      return '2';
    };

    // Helper function to compute EQ5
    const computeEQ5 = () => {
      const E = this.getEffectiveMetricValue('E');
      if (E === 'A') return '0';
      if (E === 'P') return '1';
      if (E === 'U') return '2';
    };

    // Helper function to compute EQ6
    const computeEQ6 = () => {
      const CR = this.getEffectiveMetricValue('CR');
      const VC = this.getEffectiveMetricValue('VC');
      const IR = this.getEffectiveMetricValue('IR');
      const VI = this.getEffectiveMetricValue('VI');
      const AR = this.getEffectiveMetricValue('AR');
      const VA = this.getEffectiveMetricValue('VA');

      if ((CR === 'H' && VC === 'H') || (IR === 'H' && VI === 'H') || (AR === 'H' && VA === 'H')) {
        return '0';
      }
      return '1';
    };

    // Compute all equivalency values
    const eq1 = computeEQ1();
    const eq2 = computeEQ2();
    const eq3 = computeEQ3();
    const eq4 = computeEQ4();
    const eq5 = computeEQ5();
    const eq6 = computeEQ6();

    // Combine all EQ values into the equivalent classes
    return eq1 + eq2 + eq3 + eq4 + eq5 + eq6;
  };

  /**
   * Determines the CVSS nomenclature based on the metrics used in the vector.
   *
   * This method generates the nomenclature string by evaluating whether the vector includes
   * threat and/or environmental metrics. The nomenclature helps to categorize the type of vector
   * (e.g., "CVSS-B", "CVSS-BE", "CVSS-BT", "CVSS-BTE").
   *
   * @returns {string} - The CVSS nomenclature string.
   */
  Vector.prototype.nomenclature = function () {
    let nomenclature = BASE_NOMENCLATURE;

    const hasThreatMetrics = Object.keys(METRICS.THREAT).some(key => this.metrics[key] !== 'X');
    const hasEnvironmentalMetrics = Object.keys(METRICS.ENVIRONMENTAL).some(key => this.metrics[key] !== 'X');

    if (hasThreatMetrics) {
      nomenclature += 'T';
    }

    if (hasEnvironmentalMetrics) {
      nomenclature += 'E';
    }

    return nomenclature;
  };

  /**
   * Generates a detailed breakdown of equivalent classes with their associated severity levels.
   *
   * This method analyzes a vector string representing various dimensions of a vulnerability
   * (known as macrovectors) and maps them to their corresponding human-readable severity levels
   * ("High", "Medium", "Low").
   *
   * @example
   * const breakdown = vectorInstance.severityBreakdown();
   * console.log(breakdown["Exploitability"]); // Outputs: "Medium"
   * console.log(breakdown["Complexity"]); // Outputs: "High"
   *
   * @returns {Object} An object where each key is a metric description and each value is the corresponding severity level.
   */
  Vector.prototype.severityBreakdown = function () {
    const macroVector = this.equivalentClasses;
    // Define the macrovectors and their positions
    const macroVectorDetails = [
      'exploitability',
      'complexity',
      'vulnerable_system',
      'subsequent_system',
      'exploitation',
      'security_requirements'
    ];

    // Define which macrovectors have only two severity options
    const macroVectorsWithTwoSeverities = ['complexity', 'security_requirements'];

    // Lookup tables for macrovectors with two and three possible severity levels
    const threeSeverities = ['High', 'Medium', 'Low'];
    const twoSeverities = ['High', 'Low'];

    // Construct the detailed breakdown
    return Object.fromEntries(
      macroVectorDetails.map((description, index) => {
        // Determine which lookup table to use based on the macrovector description
        const macroVectorValueOptions = macroVectorsWithTwoSeverities.includes(description)
          ? twoSeverities
          : threeSeverities;

        return [description, macroVectorValueOptions[macroVector[index]]];
      })
    );
  };

  /**
   * Gets the effective value for a given CVSS metric.
   *
   * This method determines the effective value of a metric, considering any
   * modifications and defaults to the worst-case scenario for certain metrics.
   * It checks if the metric has been overridden by an environmental metric and
   * returns the appropriate value.
   *
   * @param {string} metric - The metric for which to get the effective value (e.g., "AV", "PR").
   * @returns {string} - The effective metric value.
   */
  Vector.prototype.getEffectiveMetricValue = function (metric) {
    // Default worst-case scenarios for specific metrics
    const worstCaseDefaults = {
      E: 'A', // If E=X, it defaults to E=A
      CR: 'H', // If CR=X, it defaults to CR=H
      IR: 'H', // If IR=X, it defaults to IR=H
      AR: 'H' // If AR=X, it defaults to AR=H
    };

    // Check if the metric has a worst-case default
    if (this.metrics[metric] === 'X' && worstCaseDefaults.hasOwnProperty(metric)) {
      return worstCaseDefaults[metric];
    }

    // Check for environmental metrics that overwrite score values
    const modifiedMetric = 'M' + metric;
    if (this.metrics.hasOwnProperty(modifiedMetric) && this.metrics[modifiedMetric] !== 'X') {
      return this.metrics[modifiedMetric];
    }

    // Return the selected value for the metric
    return this.metrics[metric];
  };

  /**
   * Updates the value of a specific CVSS metric and automatically refreshes the `raw` vector string.
   *
   * This method updates the value of the specified metric in the `metrics` object.
   * After updating the metric, it updates the `raw` string by replacing the corresponding
   * metric value in the existing string without reconstructing the entire string.
   *
   * Example usage:
   * ```
   * vector.updateMetric("AV", "L");
   * console.log(vector.raw); // Output: "CVSS:4.0/AV:L/AC:L/..."
   * ```
   *
   * @param {string} metric - The abbreviation of the metric to be updated (e.g., "AV", "AC").
   * @param {string} value - The new value to assign to the metric (e.g., "L", "H").
   */
  Vector.prototype.updateMetric = function (metric, value) {
    if (this.metrics.hasOwnProperty(metric)) {
      this.metrics[metric] = value;
    } else {
      console.error(`Metric ${metric} not found.`);
    }
  };
};

export default Vector;
