import styled from 'styled-components';

export const VulnWrapper = styled.div`
  background-color: #ffffff;
  height: 46px;
  width:305px;
  padding: 0 26px;
  display:flex;
  align-items: center;
  position:relative;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
`;

export const Text = styled.p`
  font-size: 13.5px;
  color: #53686f;
  font-weight: ${({ confirmed }) => (confirmed ? '600' : 'normal')}; 
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
`;
