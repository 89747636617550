import styled from 'styled-components';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const DigitsContainer = styled.div`
  padding-top: 25px;
  text-align: left;
`;
DigitsContainer.displayName = 'DigitsContainer';

export const ButtonsContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const WrapperError = styled.div`
  display: block;
  font-size: 14.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #dc4a4a;
  margin-top: 45px;
`;
WrapperError.displayName = 'WrapperError';
