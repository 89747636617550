import styled from 'styled-components';
import { ReactComponent as PlannerIcon } from 'Images/tasks_icon.svg';
import { Link } from 'react-router-dom';

export const Icon = styled(PlannerIcon)`
margin-top: 90px;
width: 50px;
height: 55px;
& g {
    fill: #edf2f7;
}
`;

export const Wrapper = styled.div`
  width: 490px;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 21.5px;
  color:#90a9c0;
  margin: 16px 0;
`;

export const SubTitle = styled(Link)`
font-size: 12.5px;
font-weight: 500;
color: #0082ed;
`;
