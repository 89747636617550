import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectAllNotification, selectUnreadNotificationsCount } from 'store/Notifications/selectors';
import useWebsocket from 'Hooks/useWebsocket';
import useClickOutside from 'Hooks/useClickOutside';
import NotificationsDropdown from 'Screens/Contextualization/NavMenu/Notifications/components/notificationsDropdown';
import {
  Bell, IconWrapper, Wrapper, Count
} from './styled';

const Notifications = () => {
  const websocket = useWebsocket;
  if (process.env.NODE_ENV === 'production') {
    websocket('/notifications');
  }

  const totalcount = useSelector((state) => selectAllNotification(state)).length;
  const count = useSelector(selectUnreadNotificationsCount);
  const [showNotifications, setShowNotifications] = useState(false);
  const dataRef = useRef();
  useClickOutside(dataRef, () => setShowNotifications(false));

  const handleClick = () => {
    if (totalcount === 0) {
      setShowNotifications(false);
      return;
    }
    setShowNotifications(!showNotifications);
  };
  return (
    <Wrapper ref={ dataRef }>
      <IconWrapper onClick={ handleClick }>
        <Bell title={ count ? '' : 'No notifications' } />
        {count ? <Count>{count}</Count> : null}
      </IconWrapper>
      {showNotifications ? <NotificationsDropdown /> : null}
    </Wrapper>
  );
};

export default Notifications;
