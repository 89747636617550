import React from 'react';
import {
  Title,
  Input as Url
} from 'Screens/Settings/common-styled';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setField } from 'store/Settings/serviceNowActions';
import { selectUrl } from 'store/Settings/serviceNowSelectors';
import {
  Wrapper, Content
} from './styled';

const Settings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const props = useSelector((state) => ({
    url: selectUrl(state)
  }));

  const change = (field, value) => {
    dispatch(setField(field, value));
  };

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.settings.title' }) }</Title>
      <Content>
        <Url title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.settings.url.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.settings.url.placeholder' }) } onChange={ (v) => { change('url', v); } } value={ props.url } defaultValue={ props.url } />
      </Content>
    </Wrapper>
  );
};
export default Settings;
