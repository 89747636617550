import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;

  ${({ showNewUI }) => showNewUI && `
    margin-top: 21px;
  `}
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
