import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f8;
  height: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const FileName = styled.p`
  font-size: 12.5px;
  font-weight: 500;
  color: ${colors.blueCerulean};
  text-align: center;
  padding: 0 15px;
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
FileName.displayName = 'FileName';

export const Status = styled.p`
  font-size: 12.5px;
  font-weight: 500;
  color: ${colors.dark2};
`;
Status.displayName = 'Status';

export const LoadingIcon = styled.img`
  height: 5px;
  width: 300px;
  margin: 12px 0;
`;
LoadingIcon.displayName = 'LoadingIcon';
