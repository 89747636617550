import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { openModal } from 'store/modals/actions';
import { MODAL_CANT_CREATE, MODAL_CREATE_SERVICE } from 'store/modals/modals';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_SERVICES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllHostsCountWs, selectIsReadOnly, selectTotalHostsCount } from 'store/Faraday/selectors';
import AddButton from 'Common/Components/Contextualization/AddButton';
import { selectEmptyActiveWorkspaces } from 'store/Workspace/selectors';

const CreateActions = ({ entity }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const addText = intl.formatMessage({ id: 'service.addService' });
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_SERVICES, PERMISSION_TYPES.CREATE));
  const canUpdate = !readOnly && allowedByRole;
  const assetsCount = useSelector(selectAllHostsCountWs);
  const noAssets = assetsCount === 0;
  const assetsCountAllWorkspaces = useSelector(selectTotalHostsCount);
  const noAssetsFromAllWorkspaces = assetsCountAllWorkspaces === 0;
  const noWorkspaces = useSelector(selectEmptyActiveWorkspaces);

  const handleShow = () => {
    if ((entity === 'servicesGeneral') && (noAssetsFromAllWorkspaces || noWorkspaces)) dispatch(openModal(MODAL_CANT_CREATE));
    else if ((entity === 'services') && noAssets) dispatch(openModal(MODAL_CANT_CREATE));
    else if (canUpdate) dispatch(openModal(MODAL_CREATE_SERVICE));
  };

  return (
    <AddButton
      onClick={ handleShow }
      title={ addText }
      text={ addText }
      disabled={ !canUpdate }
    />
  );
};

export default CreateActions;
