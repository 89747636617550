import colors from 'Styles/colors';
import styled from 'styled-components';
import BlueButton from 'Common/Components/BlueButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`;
Wrapper.displayName = 'Wrapper';

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const CancelButton = styled.button`
  border: 1px solid #d4d7e5;
  color: ${colors.darkBlueGrey};
  background-color: white;
  margin-right: 13px;
  font-size: 14.5px;
  padding: 5px 21px;
  border-radius: 3px;
  font-weight: 400;
  width: 89px;
`;
CancelButton.displayName = 'CancelButton';

export const CustomBlueButton = styled(BlueButton)`
  width: 89px;
`;
CancelButton.displayName = 'CancelButton';

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
`;
WrapperButtons.displayName = 'WrapperButtons';

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.black};
  margin-right: 12px;
  padding-left: 15px;
`;
Title.displayName = 'Title';

export const Id = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px ${colors.grey12};
  background-color: ${colors.paleGrey};
  font-size: 11.5px;
  font-weight: 500;
  color: ${colors.grey19};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;
  min-width: 78px;
`;
Id.displayName = 'Id';
