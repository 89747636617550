import api from 'services/api';
import { getSummary, refreshDashboard } from 'Screens/Contextualization/Dashboard/actions/Actions';
import { newGetVulns } from 'store/Manage/filterActions';
import isEmpty from 'lodash/isEmpty';
import { getFirstScanFileAmount, selectCommands, selectUnfinishedCommands } from 'store/FileUploaderContext/selector';
import moment from 'moment';
import { getHostsCountInWs, getVulnsCountInWs } from 'Screens/Contextualization/Faraday/actions/Actions';
import { hasFinishedProcessingFirstScanCommand, isProcessingAFirstScan } from '../functions';

export const ADD_COMMAND = 'ADD_COMMAND';
export const COMMAND_ERROR = 'COMMAND_ERROR';
export const UPDATE_COMMAND_LIST_STATUS = 'UPDATE_COMMAND_LIST_STATUS';
export const UPDATE_FIRST_SCAN_FILE_AMOUNT = 'UPDATE_FIRST_SCAN_FILE_AMOUNT';
export const UPDATE_FIRST_SCAN_FILE_PERCENTAGE = 'UPDATE_FIRST_SCAN_FILE_PERCENTAGE';

export const SHOW_PROCESSING_QUEUE = 'SHOW_PROCESSING_QUEUE';
export const REMOVE_COMMAND = 'REMOVE_COMMAND';
export const RESET_FILE_UPLOAD_CONTEXT = 'RESET_FILE_UPLOAD_CONTEXT';
export const EXPAND_PROCESSING_QUEUE = 'EXPAND_PROCESSING_QUEUE';
export const SET_ERROR_IN_COMMAND = 'SET_ERROR_IN_COMMAND';
export const REMOVE_UNFINISHED_COMMANDS = 'REMOVE_UNFINISHED_COMMANDS';

export function updateFirstScanFileAmount (value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FIRST_SCAN_FILE_AMOUNT, value });
  };
}

export function updateFirstScanFilePercentage (value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FIRST_SCAN_FILE_PERCENTAGE, value });
  };
}

export function updateCommandsStatus () {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const commands = selectCommands(state);
      const unfinishedCommands = selectUnfinishedCommands(state);
      const firstScanFileAmount = getFirstScanFileAmount(state);

      const uploadedUnfinishedCommands = unfinishedCommands.filter((c) => c.command_id);

      const promises = uploadedUnfinishedCommands.map((c) => api.workspace.getReportStatus(c.command_id));
      const results = await Promise.all(promises);

      const commandListUpdated = commands.map((command) => {
        if (command.finished) return command;
        else {
          const commandStatus = results.find((updated) => updated._id === command.command_id);
          const createTime = commandStatus.metadata.create_time;
          const currentTime = moment();
          const difference = currentTime.diff(createTime, 'minutes');
          const timeOut = difference >= 150;
          const finished = (commandStatus.duration !== 'In progress') || timeOut;
          const error = (commandStatus.duration === 'Error') || timeOut;
          const warning = commandStatus.warnings;
          return ({ ...command, finished, error, timeOut, warning });
        }
      });

      const updatedFirstScanCommands = commandListUpdated.filter((c) => c.fileType === 'firstScan');
      const updatedFSAmount = updatedFirstScanCommands.length;

      if (firstScanFileAmount < updatedFSAmount) {
        dispatch(updateFirstScanFileAmount(updatedFSAmount));
      }

      if (isProcessingAFirstScan(commandListUpdated)) {
        const finishedFirstScanAmount = firstScanFileAmount - updatedFSAmount;
        const percentage = (finishedFirstScanAmount * 100) / firstScanFileAmount;
        const finalValue = percentage || 0;
        dispatch(updateFirstScanFilePercentage(finalValue));
      }

      if (isEmpty(promises)) {
        if (window.location.href.includes('/manage')) {
          dispatch(newGetVulns());
          dispatch(getVulnsCountInWs());
          dispatch(getHostsCountInWs());
        } else if (window.location.href.includes('/feed')) {
          dispatch(refreshDashboard());
          dispatch(getSummary());
        }
        dispatch(updateFirstScanFileAmount(0));
      }

      if (window.location.href.includes('/manage') && hasFinishedProcessingFirstScanCommand(commandListUpdated)) {
        dispatch(newGetVulns());
      }

      return dispatch({ type: UPDATE_COMMAND_LIST_STATUS, data: commandListUpdated });
    } catch (error) {
      return dispatch({ type: COMMAND_ERROR, error });
    }
  };
}

export function showProcessingQueue (show) {
  return (dispatch) => {
    dispatch({ type: SHOW_PROCESSING_QUEUE, show });
  };
}

export function expandProcessingQueue (expand) {
  return (dispatch) => {
    dispatch({ type: EXPAND_PROCESSING_QUEUE, expand });
  };
}

export function removeCommand (commandId, mockCommandId) {
  return (dispatch) => {
    dispatch({ type: REMOVE_COMMAND, commandId, mockCommandId });
  };
}

export function resetUploadContextMenu () {
  return (dispatch) => {
    dispatch({ type: RESET_FILE_UPLOAD_CONTEXT });
  };
}

export function removeUnfinishedCommands () {
  return (dispatch) => {
    dispatch({ type: REMOVE_UNFINISHED_COMMANDS });
  };
}
