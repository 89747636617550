/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Table from './components/Table';
import { Wrapper } from './styled';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { ACTIONS } from 'tracking/GA/constants';
// import { useSelector } from 'react-redux';
// import { selectShowLegacyUI } from 'store/Preferences/selectors';

const NotificationCenter = () => {
  // const showLegacyUI = useSelector(selectShowLegacyUI);
  return (
    <Wrapper showNewUI>
      {/* {showLegacyUI &&
        <>
          <Title>Notifications</Title>
          <Subtitle>History</Subtitle>
          <Text>Logged Faraday events</Text>
        </>
      } */}
      <Table />
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showWorkspace } trackingClick={ ACTIONS.clickWorkspaces } />
      </ReduxModal>
    </Wrapper>
  );
};

export default NotificationCenter;
