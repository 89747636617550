import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';
import CustomField from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/CustomField/index';
import { Wrapper, NoFieldsText } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/styled';
import ToggleButtons from 'Screens/Contextualization/Manage/components/ToggleButtons';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import getCustomFieldValueFromVuln from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/functions';
import { selectField } from 'store/ManageEditCreate/selectors';
import { setVulnCustomAttribute } from 'store/CustomAttributes/actions';
import useExpandable from 'Hooks/useExpandable';
import isEmpty from 'lodash/isEmpty';
import { selectDetail } from 'store/Contextualization/AssetDetail/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const ID = 'customAttributes';

const CustomFieldWrapper = ({ field }) => {
  const vuln = useSelector((state) => selectDetail('vulns', state));
  const editValue = useSelector((state) => selectField(state, field));
  const initialValue = vuln ? getCustomFieldValueFromVuln(vuln, field, 'vuln') : editValue;
  const isExpanded = useExpandable(ID, field.field_name, initialValue);
  const dispatch = useDispatch();
  const onSaveCustomAttribute = (vuln, fieldName, fieldValue) => {
    if (vuln) dispatch(setVulnCustomAttribute(vuln, fieldName, fieldValue, true));
  };
  return (
    <ExpandableWrapper defaultValue={ isExpanded } key={ `expandible_custom_${field.id}` } id={ field.field_name } title={ field.field_display_name } tab="customAttributes">
      <CustomField field={ field } key={ `Custom_Field_${field.id}` } initialValue={ initialValue } dataSelected={ vuln } onSave={ onSaveCustomAttribute } entity="vuln" />
    </ExpandableWrapper>
  );
};

const CustomFieldsTab = () => {
  const intl = useIntl();
  const customAttributes = useSelector(selectCustomAttributes);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));

  return (
    <Wrapper canUpdate={ canUpdate }>
      <ToggleButtons id={ ID } />
      { customAttributes.map((ca) => <CustomFieldWrapper key={ `custom_field_wrapper_${ca.id}` } field={ ca } />) }
      { isEmpty(customAttributes) && <NoFieldsText children={ intl.formatMessage({ id: 'customAttributes.empty' }) } /> }
    </Wrapper>
  );
};

export default CustomFieldsTab;
