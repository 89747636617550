import React from 'react';
import { useIntl } from 'react-intl';
import Header from 'Screens/Contextualization/Settings/components/Header';
import Content from 'Screens/Contextualization/Settings/components/AccessTokens/components/Content';
import { Wrapper, Title } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from 'store/Settings/accessTokens/actions';
import { selectError, selectErrorMessage } from 'store/Settings/accessTokens/selectors';
import ErrorDialog from 'Common/Components/ErrorDialog';

const AccessTokens = ({ onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);

  return (
    <Wrapper>
      <Header onClose={ onClose } onAccept={ onClose } />
      <Title>{ intl.formatMessage({ id: 'preferences.accessTokens.title' }) }</Title>
      <Content />
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(clearError()) } /> : null }
    </Wrapper>
  );
};

export default AccessTokens;
