import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Wrapper, Title, Subtitle, DocumentIcon
} from './styled';

const EmptyReports = ({ show }) => (show
  ? (
  <Wrapper>
    <DocumentIcon />
    <Title> You don’t have any generated reports yet </Title>
    <Subtitle> Start by generating a New Report </Subtitle>
  </Wrapper>
    )
  : null);
export default EmptyReports;

EmptyReports.propTypes = {
  show: PropTypes.bool
};

EmptyReports.defaultProps = {
  show: true
};
