import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  height: 94%;
  ${({ showNewUI }) => showNewUI && `
    height: 91%;
  `}
`;
TableWrapper.displayName = 'TableWrapper';

export const TabWrapper = styled.div`
  display: flex;
`;

export const Tab = styled.div`
  padding: 16px 40px;
  background-color: ${({ active }) => (active ? '#fff' : '#edf2f7')};
  color: #264d6d;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export const TypeContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;

    input[type='number'] {
        margin: 0 5px;
        width: 57px;
        height: 30px;
        border-radius: 4px;
        border: solid 1px #bec8d2;
        background-color: #f6f6f6;
        padding-left: 7px;

        // Estilos para los números dentro del input
        opacity: 0.71;
        font-size: 14px;
        color: #292929;
        
        // Estilos específicos para las flechas en navegadores WebKit como Chrome y Safari
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            appearance: auto;
            margin: 0;
            opacity: 1;
            height: 30px;  // Establecemos la altura aquí
            font-size: 14px;  // Ajustamos el tamaño de la fuente para que las flechas se acomoden mejor
            line-height: 30px;  // Ajustamos el interlineado para que las flechas se centren
        }

        // En caso de que se haya establecido un hover o focus en algún otro lugar
        &:hover,
        &:focus {
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                opacity: 1;
            }
        }
    }
`;

export const FragmentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;
