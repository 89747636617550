import fetchApi from 'services/api/connector';

export const fetchAllNotifications = (queryParam) => fetchApi(`notifications_log?${queryParam}`, { method: 'GET' });
// api.js
export const deleteNotificationsApi = (notificationIds) =>
  fetchApi('notifications_log', {
    method: 'DELETE',
    data: { ids: notificationIds }
  });

export const markNotificationsAsReadApi = (notificationIds) =>
  fetchApi('notifications_log/mark_as_read', {
    method: 'POST',
    data: { ids: notificationIds }
  });

export const updateNotificationTypeApi = (userId, type, active) =>
  fetchApi(`notification_center/${userId}`, {
    method: 'PATCH',
    data: { [type]: active }
  });

export const fetchNotificationsSettings = (userId) => fetchApi(`notification_center/${userId}`);

export const deleteAllNotifications = (queryParam) =>
  fetchApi(`notifications_log?q=${encodeURI(JSON.stringify(queryParam))}`, {
    method: 'DELETE'
  });

export const markAllNotificationsAsRead = () =>
  fetchApi('notifications_log/mark_as_read', {
    method: 'POST'
  });
