import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  allNotificationsAreSelected, selectNotificationsCount,
  selectPage, selectRowsPerPage, selectSelectAll,
  selectSelectedNotifications
} from 'store/Notifications/selectors';
import {
  selectTotalNotifications, unselectAllNotifications,
  unselectTotalNotifications,
  unselectedRowFromSelectAll
} from 'store/Notifications/actions';
import { Text, Blue, Bold } from 'Screens/Manage/components/ManageTable/components/SelecAll/styled';
import Wrapper from './styled';

const SelectAll = () => {
  const dispatch = useDispatch();
  const allVisualNotificationsSelected = useSelector(allNotificationsAreSelected);
  const pageLimit = useSelector(selectRowsPerPage);
  const selectAll = useSelector(selectSelectAll);
  const notificationsSelected = useSelector(selectSelectedNotifications);
  const notificationsCount = useSelector(selectNotificationsCount);
  const page = useSelector(selectPage);

  const handleSelectAll = () => {
    dispatch(selectTotalNotifications());
  };
  const handleClear = () => {
    dispatch(unselectTotalNotifications());
    dispatch(unselectAllNotifications());
    dispatch(unselectedRowFromSelectAll(false));
  };

  const content = () => {
    if (selectAll) {
      return (
        <Text>
          All <Bold>{notificationsCount}</Bold> Notifications are selected. <Blue onClick={ handleClear }>Clear Selection</Blue>
        </Text>
      );
    } else {
      return (
        <Text>
          All <Bold>{notificationsSelected.length}</Bold> Notifications on this page are selected. <Blue onClick={ handleSelectAll }>Select all {notificationsCount} Notifications</Blue>
        </Text>
      );
    }
  };
  if (!allVisualNotificationsSelected) return null;
  if ((notificationsSelected.length < pageLimit && page === 1 && !allVisualNotificationsSelected) || notificationsCount <= pageLimit) return null;
  return <Wrapper> {content()} </Wrapper>;
};

export default SelectAll;
