import * as types from './types';

export const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  viewType: 'listView',
  selectedProject: null,
  list: [],
  isHidingTasks: false
};

function projects (state = initialState, action) {
  switch (action.type) {
    case types.PROJECTS_FETCHING: {
      return {
        ...state,
        isFetching: true
      };
    }
    case types.PROJECTS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.errorMessage
      };
    }
    case types.PROJECTS_CLEAR_ERROR: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }
    case types.PROJECTS_GET_SUCCESS: {
      return {
        ...state,
        list: action.projects.sort((a, b) => a.id - b.id),
        isFetching: false
      };
    }
    case types.PROJECTS_CREATE_SUCCESS: {
      return {
        ...state,
        list: [
          ...state.list,
          action.newProject
        ],
        isFetching: false
      };
    }
    case types.PROJECTS_EDIT_SUCCESS: {
      return {
        ...state,
        list: action.updatedProjectList
      };
    }
    case types.PROJECTS_DELETE_SUCCESS: {
      return {
        ...state,
        list: [...state.list.filter((project) => project.id !== action.idToRemove)],
        isFetching: false
      };
    }
    case types.PROJECTS_TOGGLE_VIEW_TYPE: {
      return {
        ...state,
        viewType: action.viewType
      };
    }
    case types.PROJECTS_SELECT_PROJECT: {
      return {
        ...state,
        selectedProject: action.selectedProject
      };
    }
    case types.PROJECTS_HIDE_COMPLETED_TASKS: {
      return {
        ...state,
        isHidingTasks: action.value
      };
    }
    case types.PROJECTS_RESET: {
      return {
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
}

export default projects;
