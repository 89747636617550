import get from 'lodash/get';

export const selectUnreadNotifications = (state) =>
  get(state, 'notifications.unreadNotifications', []);
export const selectNotificationsCount = (state) =>
  get(state, 'notifications.count', 0);
export const selectUnreadNotificationsCount = (state) =>
  get(state, 'notifications.unreadCount', 0);
export const selectSelectedNotifications = (state) =>
  get(state, 'notifications.selectedNotifications', []);
export const selectLastSelectedNotification = (state) =>
  get(state, 'notifications.lastSelectedNotificationIndex', -1);
export const selectNotificationsLoading = (state) =>
  get(state, 'notifications.loading', false);
export const selectNotificationsError = (state) =>
  get(state, 'notifications.error', null);
export const selectAllNotification = (state) =>
  get(state, 'notifications.allNotifications', []);
export const selectNotificationCenter = (state) =>
  get(state, 'notifications.notificationCenter', {});
export const selectNotificationAdvanced = (state) =>
  get(state, 'notifications.notificationAdvanced', []);
export const selectPage = (state) =>
  get(state, 'notifications.page', 1);
export const selectRowsPerPage = (state) =>
  get(state, 'notifications.rowsPerPage', 50);

export const selectQueryParam = (state) => {
  const page = selectPage(state);
  const rowsPerPage = selectRowsPerPage(state);

  const queryParam = `page=${page}&page_size=${rowsPerPage}`;
  return queryParam;
};

export const allNotificationsAreSelected = (state) => {
  const notifications = selectAllNotification(state);
  const notificationsSelected = selectSelectedNotifications(state);
  return notifications.every((testNotification) => notificationsSelected.some((notification) => notification.id === testNotification.id)) && notificationsSelected.length > 0;
};

export const selectSelectAll = (state) =>
  get(state, 'notifications.selectAll', false);

export const selectUnselectedRowFromSelectAll = (state) =>
  get(state, 'notifications.unselectedRowFromSelectAll', false);
