import api from 'services/api';

// **
// two factor - constants section
// **
export const GET_SETTINGS_TWO_FACTOR_START = 'GET_SETTINGS_TWO_FACTOR_START';
export const GET_SETTINGS_TWO_FACTOR_SUCCESS = 'GET_SETTINGS_TWO_FACTOR_SUCCESS';
export const GET_SETTINGS_TWO_FACTOR_FAIL = 'GET_SETTINGS_TWO_FACTOR_FAIL';

// permite al usuario habilitar two factor.
export const SET_SETTINGS_TWO_FACTOR_REQUEST_START = 'SET_SETTINGS_TWO_FACTOR_REQUEST_START';
export const SET_SETTINGS_TWO_FACTOR_REQUEST_SUCCESS = 'SET_SETTINGS_TWO_FACTOR_REQUEST_SUCCESS';
export const SET_SETTINGS_TWO_FACTOR_REQUEST_FAIL = 'SET_SETTINGS_TWO_FACTOR_REQUEST_FAIL';

// necesita el password + secret para confirmar activacion.
export const SET_SETTINGS_TWO_FACTOR_CONFIRM_START = 'SET_SETTINGS_TWO_FACTOR_CONFIRM_START';
export const SET_SETTINGS_TWO_FACTOR_CONFIRM_SUCCESS = 'SET_SETTINGS_TWO_FACTOR_CONFIRM_SUCCESS';
export const SET_SETTINGS_TWO_FACTOR_CONFIRM_FAIL = 'SET_SETTINGS_TWO_FACTOR_CONFIRM_FAIL';

// necesita el password para deshabilitar.
export const SET_SETTINGS_TWO_FACTOR_DISABLE_START = 'SET_SETTINGS_TWO_FACTOR_DISABLE_START';
export const SET_SETTINGS_TWO_FACTOR_DISABLE_SUCCESS = 'SET_SETTINGS_TWO_FACTOR_DISABLE_SUCCESS';
export const SET_SETTINGS_TWO_FACTOR_DISABLE_FAIL = 'SET_SETTINGS_TWO_FACTOR_DISABLE_FAIL';

// cambia el estado del checkbox status.
export const SET_SETTINGS_TWO_FACTOR_CHANGE_STATUS_SUCCESS = 'SET_SETTINGS_TWO_FACTOR_CHANGE_STATUS_SUCCESS';

// **
// two factor - actions section
// **

export function getTwoFactor () {
  return async (dispatch) => {
    dispatch({ type: GET_SETTINGS_TWO_FACTOR_START });
    try {
      const response = await api.settings.fetchTwoFactor();
      return dispatch({ type: GET_SETTINGS_TWO_FACTOR_SUCCESS, status: response.state_otp }); // disabled, requested, confirmed
    } catch (e) {
      return dispatch({ type: GET_SETTINGS_TWO_FACTOR_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function requestTwoFactor () {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_TWO_FACTOR_REQUEST_START });
    try {
      await api.settings.requestTwoFactor();
      return dispatch({ type: SET_SETTINGS_TWO_FACTOR_REQUEST_SUCCESS, status: 'requested' });
    } catch (e) {
      return dispatch({ type: SET_SETTINGS_TWO_FACTOR_REQUEST_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function confirmTwoFactor (password, code) {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_TWO_FACTOR_CONFIRM_START });
    try {
      await api.settings.confirmTwoFactor(password, code);
      return dispatch({ type: SET_SETTINGS_TWO_FACTOR_CONFIRM_SUCCESS, status: 'confirmed' });
    } catch (e) {
      return dispatch({ type: SET_SETTINGS_TWO_FACTOR_CONFIRM_FAIL, error: true, message: 'Invalid password or secret code, please try again.' });
    }
  };
}

export function disableTwoFactor (password) {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_TWO_FACTOR_DISABLE_START });
    try {
      await api.settings.disableTwoFactor(password);
      return dispatch({ type: SET_SETTINGS_TWO_FACTOR_DISABLE_SUCCESS, status: 'disabled' });
    } catch (e) {
      return dispatch({ type: SET_SETTINGS_TWO_FACTOR_DISABLE_FAIL, error: true, message: 'Invalid password, please try again.' });
    }
  };
}

export function changeStatusTwoFactor (value) {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_TWO_FACTOR_CHANGE_STATUS_SUCCESS, checked: value });
    if (value) dispatch(requestTwoFactor());
  };
}
