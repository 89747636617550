/* eslint-disable react/no-children-prop */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SystemComment from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CommentTab/Comments/components/SystemComment';
import UserComment from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CommentTab/Comments/components/UserComment';
import { Empty } from 'Screens/Planner/Tasks/CreateEdit/components/Tabs/Comments/components/Empty/styled';
import { selectActiveUser } from 'store/Sesion/selectors';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { Wrapper } from './styled';

const List = ({ comments, onClickEdit, onClickRemove }) => {
  const intl = useIntl();
  const noCommentsLabel = intl.formatMessage({ id: 'manage.detail.tab.comment.noComments' });
  const isSystemComment = (comment) => comment.comment_type === 'system';
  const currentUser = useSelector(selectActiveUser);

  if (isEmpty(comments)) return <Empty children={ noCommentsLabel } />;
  return (
    <Wrapper>
      {
        [...comments].reverse().map((comment) => {
          if (isSystemComment(comment)) return <SystemComment comment={ comment } key={ `sys_comment_${comment.id}` } />;
          return (
            <UserComment
              comment={ comment }
              key={ `user_comment_${comment.id}` }
              isCreator={ currentUser === comment.creator }
              onEdit={ onClickEdit }
              onRemove={ onClickRemove }
            />
          );
        })
      }
    </Wrapper>
  );
};

List.propTypes = {
  comments: PropTypes.instanceOf(Array).isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickRemove: PropTypes.func.isRequired
};

export default List;
