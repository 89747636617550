import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as CheckboxIcon } from 'Images/Checkbox.svg';

const checkboxTheme = {
  green: {
    on: {
      stroke: '#ffffff',
      body: '#94CC88',
      border: '#60ad54'
    },
    off: {
      stroke: '#ffffff',
      body: '#D3D3D3',
      border: '#a8a8a8'
    }
  },
  grey: {
    on: {
      stroke: '#90a9c0',
      body: 'transparent',
      border: '#90a9c0'
    },
    off: {
      stroke: '#90a9c0',
      body: 'transparent',
      border: '#90a9c0'
    }
  }
};

const selectColor = (theme, checked, atribute) => {
  const state = checked ? 'on' : 'off';
  return checkboxTheme[theme][state][atribute];
};

const CheckBox = styled.input.attrs(({
  type: 'checkbox'
}))`
  cursor: pointer;
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: ${(props) => (props.width ? props.width : '40px')};
  height: ${(props) => (props.height ? props.height : '12px')};
  background-color: ${colors.white};
  border: 2px solid ${colors.grey16};
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 ${colors.white};
  transition: background-color 0.25s ease;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  &:after {
    content: "";
    position: absolute;
    top: -6px;
    left: -2px;
    width: ${(props) => (props.diameterCircle ? props.diameterCircle : '21px')};
    height: ${(props) => (props.diameterCircle ? props.diameterCircle : '21px')};
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
    transition: left 0.25s ease;
    border: 2px solid ${colors.grey15};
    background: ${colors.grey13};
  }
  
  &:disabled {
    cursor: not-allowed;
  }

  &:checked {
    box-shadow: inset 20px 0 0 0 ${colors.green4};
    border-color: ${colors.green3};
  }
  
  &:checked:after {
    left:  ${(props) => (props.checkedPosition ? props.checkedPosition : '20px')};
    box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
    border: 2px solid ${colors.green3};
    background-color: ${colors.green1};
  }
`;
CheckBox.displayName = 'CheckBox';

export default CheckBox;

export const StyledCheckbox = styled(CheckboxIcon)`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  transition: all 150ms;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  & #checkbox-background {
    fill: ${({ theme, checked }) => selectColor(theme, checked, 'body')};
  }

  & #checkbox-border {
    fill: ${({ theme, checked }) => selectColor(theme, checked, 'border')};
  }

  & #checkbox-tick {
    fill: ${({ theme, checked }) => selectColor(theme, checked, 'stroke')};
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')}
  }

  & #checkbox-partial {
    fill: ${({ theme, checked }) => selectColor(theme, checked, 'stroke')};
    visibility: ${(props) => (props.$partialChecked ? 'visible' : 'hidden')}
  }

  &:disabled {
    cursor: not-allowed; 
    filter: grayscale(100%);
  }
`;

export const WrapperField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.div`
  font-size: 11px;
  line-height: 2.82;
  color: ${colors.grey19};
  margin-left: 12px;
`;
