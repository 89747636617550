import styled from 'styled-components';
import { ReactComponent as MainDuplicateSVG } from 'Images/main-duplicate.svg';
import { ReactComponent as DuplicateSVG } from 'Images/duplicate.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 25px;
  border-radius: 6px;
  background-color: #bec8d2;
  padding: 3px 8px;
  width: 100px;
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #264d6d;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
  user-select: none;
`;
Label.displayName = 'Label';

export const DuplicateIcon = styled(DuplicateSVG)`
  margin: auto 0 auto 0;
  cursor: default;
  & path{
    fill: #264d6d;
  }  
`;
DuplicateIcon.displayName = 'DuplicateIcon';

export const MainDuplicateIcon = styled(MainDuplicateSVG)`
  margin: auto 0 auto 0;
  cursor: default;
  & path{
    fill: #264d6d;
  }  
`;
MainDuplicateIcon.displayName = 'MainDuplicateIcon';
