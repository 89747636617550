import styled from 'styled-components';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index:${({ z }) => (z || '99999')};
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
`;

export default Background;
