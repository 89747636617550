import get from 'lodash/get';
import find from 'lodash/find';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';

export const selectError = (state) => get(state, 'services.error', false);
export const selectErrorMessage = (state) => get(state, 'services.errorMessage', '');
export const selectServices = (state) => get(state, 'services.list', []);
export const selectIsFetching = (state) => get(state, 'services.isFetching', false);
export const selectTotalServices = (state) => {
  const currentWorkspace = selectCurrentWorkspace(state);
  const activeWorkspaces = selectActiveWorkspaces(state);
  const ws = find(activeWorkspaces, { name: currentWorkspace });
  return get(ws, 'stats.services', 0);
};
export const selectShowDetail = (state) => get(state, 'services.showServiceDetail', false);
export const selectServiceDetail = (state) => get(state, 'services.serviceDetail', []);
export const selectServiceDetailId = (state) => get(state, 'services.serviceDetail._id', 0);
export const selectServiceDetailName = (state) => get(state, 'services.serviceDetail.name', '');
export const selectServiceDetailOwned = (state) => get(state, 'services.serviceDetail.owned', false);
export const selectServiceDetailPorts = (state) => get(state, 'services.serviceDetail.ports', '');
export const selectServiceDetailProtocol = (state) => get(state, 'services.serviceDetail.protocol', '');
export const selectServiceDetailVersion = (state) => get(state, 'services.serviceDetail.version', '');
export const selectServiceDetailDescription = (state) => get(state, 'services.serviceDetail.description', '');
export const selectServiceDetailStatus = (state) => get(state, 'services.serviceDetail.status', '');
export const selectServiceDetailTags = (state) => get(state, 'services.serviceDetail.tags', []);
export const selectServicesCount = (state) => get(state, 'services.count', 0);
export const selectServicesSelected = (state) => get(state, 'services.servicesSelected', []);
export const selectLastSelected = (state) => get(state, 'services.lastSelected', -1);

export const selectSelectAllServices = (state) => get(state, 'services.selectAll', false);

export const allServicesAreSelected = (state) => {
  const services = selectServices(state);
  const serviceList = selectServicesSelected(state);
  return services.every((testService) => serviceList.some((service) => service._id === testService._id)) && serviceList.length > 0;
};
export const someServiceIsSelected = (state) => {
  return selectServicesSelected(state).length > 0;
};
export const selectCurrentService = (state) => get(state, 'services.serviceSelectedForWorking[0]', {});
export const selectShowDeleteConfirmation = (state) => get(state, 'services.showDeleteConfirmation', false);
export const selectPermissions = (state) => get(state, 'faraday.permissions.services', []);

export const selectReadonly = (state) => {
  const readonly = get(state, 'faraday.readonly', false);
  return readonly;
};

export const selectOrderBy = (state) => get(state, 'services.order_by', '');

export const selectOrderByDir = (state) => get(state, 'services.order_by_dir', '');

export const selectQueryParam = (state) => {
  const page = selectPage('services', state);
  const rowsPerPage = selectRowsPerPage('services', state);
  const sort = selectOrderBy(state);
  const sort_dir = selectOrderByDir(state);

  const sortParam = sort ? `&sort=${sort}` : '';
  const sortDirParam = sort_dir ? `&sort_dir=${sort_dir}` : '';

  const queryParam = `page=${page}&page_size=${rowsPerPage}${sortParam}${sortDirParam}`;
  return queryParam;
};

export const selectServicesTags = (state) => {
  const servicesSelected = selectServicesSelected(state);
  const servicesTags = servicesSelected.flatMap(service => service.tags);
  return servicesTags;
};
export const selectHostsList = (state) => get(state, 'manageEditCreate.hosts', []);

export const selectErrorCreateService = (state) => get(state, 'services.errorModalFields', false);
export const selectErrorMessageCreateService = (state) => get(state, 'services.errorMessageModalFields', '');
export const selectErrorTitleCreateService = (state) => get(state, 'services.errorTitleModalFields', '');

export const selectUnselectedRowFromSelectAll = (state) => get(state, 'services.unselectedRowFromSelectAll', false);
