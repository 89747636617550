import React from 'react';
import { useIntl } from 'react-intl';
import { Title } from 'Screens/Contextualization/Settings/common-styled';
import Header from '../Header';
import { Wrapper } from './styled';
import SectionSelector from './SectionSelector';

const UserSessions = ({ onClose }) => {
  const intl = useIntl();
  const close = () => onClose();

  return (
    <Wrapper>
      <Header onAccept={ close } onClose={ close } />
      <Title>{intl.formatMessage({ id: 'preferences.userSessions.title' })}</Title>
      <SectionSelector onClose={ onClose } />
    </Wrapper>
  );
};
export default UserSessions;
