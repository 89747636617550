import React from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'Common/Components/Skeleton';
import { changeReadOnly } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { Wrapper, LockIcon, UnlockIcon, Text } from './styled';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const LockUnlockWs = ({ isFetching, ws }) => {
  const isWsReadOnly = get(ws, 'readonly', false);
  const name = get(ws, 'name', '');
  const dispatch = useDispatch();
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.UPDATE));

  const toggleReadOnly = (e) => {
    if (canUpdate) {
      e.stopPropagation();
      dispatch(changeReadOnly(name, !isWsReadOnly));
    }
  };

  if (isFetching) return <Skeleton />;
  return (
    <Wrapper onClick={ toggleReadOnly }>
      {isWsReadOnly
        ? <>
          <LockIcon />
          <Text>Locked</Text>
          </>
        : <>
          <UnlockIcon />
          <Text>Unlocked</Text>
          </>
      }

    </Wrapper>
  );
};

export default LockUnlockWs;
