import React, { useEffect, useRef, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { MenuWrapper, contextMenuWidth } from './styled';

const ContextMenu = ({
  children, show, xPos, yPos, displayFunction, disableOutsideClick
}) => {
  const contextMenuRef = useRef(null);

  const outsideClickHandler = useCallback((e) => {
    // if target is outside of the context menu, hide it.
    const node = contextMenuRef ? contextMenuRef.current : null;
    const containsTarget = node && node.contains(e.target);

    if (!containsTarget && !disableOutsideClick) {
      displayFunction(false);
    }
  }, [disableOutsideClick, displayFunction]);

  const insideRightClickHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    displayFunction(false);
  }, [displayFunction]);

  useEffect(() => {
    document.addEventListener('click', outsideClickHandler, { capture: true });
    if (contextMenuRef && contextMenuRef.current) contextMenuRef.current.addEventListener('contextmenu', insideRightClickHandler, { capture: true });
    return () => {
      document.removeEventListener('click', outsideClickHandler, { capture: true });
      if (contextMenuRef && contextMenuRef.current) contextMenuRef.current.removeEventListener('contextmenu', insideRightClickHandler, { capture: true });
    };
  }, [contextMenuRef, insideRightClickHandler, outsideClickHandler]);

  useEffect(() => {
    const totalWidth = document.body.clientWidth;
    const totalHeight = document.body.clientHeight;
    const contextMenuHeight = contextMenuRef.current ? contextMenuRef.current.clientHeight : 0;
    let newXPos = xPos;
    let newYPos = yPos;
    if (contextMenuRef.current) {
      if (xPos + contextMenuWidth > totalWidth) {
        newXPos = xPos - contextMenuWidth;
      }
      if (yPos + contextMenuHeight > totalHeight) {
        newYPos = yPos - contextMenuHeight;
      }
    }
    if (newXPos !== xPos || newYPos !== yPos) displayFunction(show, newXPos, newYPos);
  }, [displayFunction, show, xPos, yPos]);

  const setRef = useCallback((el) => {
    contextMenuRef.current = el;
  }, []);

  return (
    <MenuWrapper ref={ setRef } xPos={ xPos } yPos={ yPos } show={ show }>
      {children}
    </MenuWrapper>
  );
};

ContextMenu.propTypes = {
  disableOutsideClick: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  xPos: PropTypes.number.isRequired,
  yPos: PropTypes.number.isRequired,
  displayFunction: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

ContextMenu.defaultProps = {
  disableOutsideClick: false
};

export default ContextMenu;
