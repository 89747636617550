import * as types from './types';

export const initialState = {
  isFetching: false,
  list: [],
  error: false,
  errorMessage: '',
  selectedTask: null,
  selectedLinkedVuln: null,
  taskToDelete: null
};

function tasks (state = initialState, action) {
  switch (action.type) {
    case types.PROJECTS_TASKS_FETCHING: {
      return {
        ...state,
        error: false,
        errorMessage: '',
        isFetching: true
      };
    }

    case types.PROJECTS_TASKS_MARK_TO_DELETE: {
      return {
        ...state,
        taskToDelete: action.task
      };
    }

    case types.PROJECTS_TASKS_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage
      };
    }

    case types.PROJECTS_TASKS_GET_SUCCESS: {
      return {
        ...state,
        list: [
          ...state.list.filter((item) => item.projectId !== action.projectId),
          {
            projectId: action.projectId,
            tasks: action.tasks.map((t) => ({
              id: t.id,
              name: t.name,
              startDate: t.start_date,
              endDate: t.end_date,
              status: t.status,
              type: t.type,
              assignees: t.users_assigned,
              dependencies: t.task_dependencies,
              description: t.description,
              projectId: t.project_id,
              linkedVulns: t.vulnerabilities_related
            }))
          }
        ],
        error: false,
        errorMessage: '',
        isFetching: false
      };
    }

    case types.PROJECTS_TASKS_CREATE_EDIT_SUCCESS: {
      return {
        ...state,
        error: false,
        errorMessage: '',
        isFetching: false
      };
    }

    case types.PROJECTS_TASKS_DELETE_SUCCESS: {
      return {
        ...state,
        list: action.updatedTasksList,
        error: false,
        errorMessage: '',
        isFetching: false,
        selectedTask: null
      };
    }

    case types.PROJECTS_TASKS_GET_COMMENTS_SUCCESS: {
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          comments: action.comments
        },
        error: false,
        errorMessage: '',
        isFetching: false
      };
    }

    case types.PROJECTS_TASKS_ADD_COMMENT_SUCCESS: {
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          comments: [
            ...state.selectedTask.comments,
            action.taskComment
          ]
        },
        error: false,
        errorMessage: '',
        isFetching: false
      };
    }
    case types.PROJECTS_TASKS_DELETE_COMMENT_SUCCESS: {
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          comments: [...state.selectedTask.comments.filter((c) => c.id !== action.commentId)]
        },
        error: false,
        errorMessage: '',
        isFetching: false
      };
    }

    case types.PROJECTS_TASKS_EDIT_COMMENT_SUCCESS: {
      const index = state.selectedTask.comments.findIndex(({ id }) => id === action.taskComment.id);
      if (index > -1) {
        return {
          ...state,
          selectedTask: {
            ...state.selectedTask,
            comments: [
              ...state.selectedTask.comments.slice(0, index),
              action.taskComment,
              ...state.selectedTask.comments.slice(index + 1)
            ]
          },
          error: false,
          errorMessage: '',
          isFetching: false
        };
      }
      return state;
    }

    case types.PROJECTS_TASKS_SET_SELECTED_COMMENT: {
      return {
        ...state,
        selectedComment: action.selectedComment
      };
    }

    case types.PROJECTS_TASKS_SELECT: {
      return {
        ...state,
        selectedTask: action.selectedTask
      };
    }

    case types.PROJECTS_TASKS_RESET: {
      return {
        ...initialState
      };
    }

    case types.PROJECTS_TASKS_SET_FIELD: {
      if (action.field === 'type') {
        return {
          ...state,
          selectedTask: {
            ...state.selectedTask,
            type: action.value,
            startDate: null,
            endDate: action.value === 'task' ? null : state.selectedTask.endDate,
            status: null
          }
        };
      }
      if (action.field === 'assignees') {
        const newAssignees = [...state.selectedTask.assignees];
        newAssignees.push(action.value);
        return {
          ...state,
          selectedTask: {
            ...state.selectedTask,
            assignees: newAssignees
          }
        };
      } if (action.field === 'dependencies') {
        const newDependencies = [...state.selectedTask.dependencies];
        newDependencies.push(action.value);
        return {
          ...state,
          selectedTask: {
            ...state.selectedTask,
            dependencies: newDependencies
          }
        };
      }
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          [action.field]: action.value
        }
      };
    }

    case types.PROJECTS_TASKS_REMOVE_ITEM: {
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          [action.fieldName]: action.filteredData
        }
      };
    }

    case types.PROJECTS_TASKS_UPDATE_TASK_LIST: {
      return {
        ...state,
        list: action.list
      };
    }

    case types.PROJECTS_TASKS_CLEAR: {
      return {
        ...state,
        list: [
          ...state.list.filter((item) => item.projectId !== action.projectId)
        ]
      };
    }

    case types.PROJECTS_TASKS_CLEAR_LIST: {
      return {
        ...state,
        list: []
      };
    }

    case types.PROJECTS_TASKS_SET_SELECTED_LINKED_VULN: {
      return {
        ...state,
        selectedLinkedVuln: action.vuln
      };
    }

    default: {
      return state;
    }
  }
}

export default tasks;
