import React from 'react';
import { useDispatch } from 'react-redux';
import { FooterButtom, Footer as Wrapper } from './styled';
import { redirect } from 'store/Router/actions';

const Footer = ({ notificationCount }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(redirect('/notifications-log'));
  };

  return (
    <Wrapper>
      <FooterButtom onClick={ handleClick } >
        View All ({notificationCount} Unread)
      </FooterButtom>
    </Wrapper>
  );
};

export default Footer;
