/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsAdmin } from 'store/Faraday/selectors';
import { getUsers } from 'Screens/Workspaces/actions/Actions';

import ActionBar from './components/ActionBar';
import Tabs from './components/Tabs';
import Wrapper from './styled';

const CreateEditTask = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);

  useEffect(() => {
    if (isAdmin) dispatch(getUsers());
  }, [isAdmin, dispatch]);

  return (
  <Wrapper>
    <ActionBar />
    <Tabs />
  </Wrapper>
  );
};

export default CreateEditTask;
