import {
  GET_SETTINGS_SMTP_START,
  GET_SETTINGS_SMTP_FAIL,
  GET_SETTINGS_SMTP_SUCCESS,
  SAVE_SETTINGS_SMTP_START,
  SAVE_SETTINGS_SMTP_SUCCESS,
  SAVE_SETTINGS_SMTP_FAIL,
  SET_SETTINGS_SMTP_FIELD
} from 'store/Settings/smtpActions';

import {
  LOAD_SETTINGS_REPORT_SUCCESS,
  SAVE_SETTINGS_REPORT_SUCCESS,
  SET_SETTINGS_REPORT_FIELD
} from 'store/Settings/reportActions';

import {
  SAVE_SETTINGS_CHANGE_PASSWORD_START,
  SAVE_SETTINGS_CHANGE_PASSWORD_SUCCESS,
  SAVE_SETTINGS_CHANGE_PASSWORD_FAIL
} from 'store/Settings/changePasswordActions';

import {
  GET_SETTINGS_TWO_FACTOR_START,
  GET_SETTINGS_TWO_FACTOR_SUCCESS,
  GET_SETTINGS_TWO_FACTOR_FAIL,

  SET_SETTINGS_TWO_FACTOR_REQUEST_START,
  SET_SETTINGS_TWO_FACTOR_REQUEST_SUCCESS,
  SET_SETTINGS_TWO_FACTOR_REQUEST_FAIL,

  SET_SETTINGS_TWO_FACTOR_CONFIRM_START,
  SET_SETTINGS_TWO_FACTOR_CONFIRM_SUCCESS,
  SET_SETTINGS_TWO_FACTOR_CONFIRM_FAIL,

  SET_SETTINGS_TWO_FACTOR_DISABLE_START,
  SET_SETTINGS_TWO_FACTOR_DISABLE_SUCCESS,
  SET_SETTINGS_TWO_FACTOR_DISABLE_FAIL,

  SET_SETTINGS_TWO_FACTOR_CHANGE_STATUS_SUCCESS
} from 'store/Settings/twoFactorActions';

import get from 'lodash/get';
import {
  LOAD_SETTINGS_LOGIN_SETTINGS_SUCCESS,
  SAVE_SETTINGS_LOGIN_SETTINGS_FAIL,
  SAVE_SETTINGS_LOGIN_SETTINGS_START,
  SAVE_SETTINGS_LOGIN_SETTINGS_CLEAR,
  SAVE_SETTINGS_LOGIN_SETTINGS_SUCCESS,
  SET_SETTINGS_CHANGE_LOGIN_SETTINGS_FIELD
} from './loginSettingsActions';
import {
  GET_SETTINGS_TEMPLATES_FAIL, GET_SETTINGS_TEMPLATES_START, GET_SETTINGS_TEMPLATES_SUCCESS, SET_SETTINGS_SHOW_MODAL,

  SEND_VULN_TO_TOOL,
  GET_ACTIVE_CONFIG_REQUEST,
  GET_ACTIVE_CONFIG_SUCCESS,
  GET_ACTIVE_CONFIG_FAILURE,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAIL,
  SET_USER_EMAIL
} from './settingsActions';
import {
  ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_FAIL,
  ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_START,
  ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS,
  AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_FAIL,
  AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_START,
  AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS,
  GET_SETTINGS_TICKETING_TOOLS_JIRA_FAIL,
  GET_SETTINGS_TICKETING_TOOLS_JIRA_START,
  GET_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS,
  SAVE_SETTINGS_TICKETING_TOOLS_JIRA_FAIL,
  SAVE_SETTINGS_TICKETING_TOOLS_JIRA_START,
  SAVE_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS,
  SET_SETTINGS_TICKETING_TOOLS_JIRA_FIELD,
  GET_PROJECTS_TICKETING_TOOLS_JIRA_START,
  GET_PROJECTS_TICKETING_TOOLS_JIRA_SUCCESS,
  GET_PROJECTS_TICKETING_TOOLS_JIRA_FAIL,
  ADD_TO_JIRA_PAYLOAD,
  SET_JIRA_PAYLOAD,
  SET_JIRA_TEMPLATE,
  RESET_JIRA_PAYLOAD,
  ADD_PROJECT_JIRA_PAYLOAD,
  DELETE_PROJECT_JIRA_PAYLOAD,
  SET_PROJECT_CONFIG_JIRA_PAYLOAD,
  SEND_TEMPLATES_TICKETING_TOOLS_JIRA_SUCCESS,
  SEND_TEMPLATES_TICKETING_TOOLS_JIRA_START,
  SEND_TEMPLATES_TICKETING_TOOLS_JIRA_FAIL,
  GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_SUCCESS,
  GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_START,
  GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_FAIL,
  GET_PROJECT_TICKETING_TOOLS_JIRA_START,
  GET_PROJECT_TICKETING_TOOLS_JIRA_SUCCESS,
  GET_PROJECT_TICKETING_TOOLS_JIRA_FAIL,
  SET_IS_TEMPLATE
} from './jiraActions';
import {
  AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL,
  AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START,
  AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS,
  DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL,
  DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START,
  DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS,
  GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL, GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START, GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS, SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL, SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START, SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS, SET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FIELD
} from './serviceNowActions';
import {
  GET_SETTINGS_TICKETING_TOOLS_GITLAB_FAIL, GET_SETTINGS_TICKETING_TOOLS_GITLAB_START, GET_SETTINGS_TICKETING_TOOLS_GITLAB_SUCCESS, SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_FAIL, SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_START, SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_SUCCESS, SET_SETTINGS_TICKETING_TOOLS_GITLAB_FIELD
} from './gitLabActions';
import {
  GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL, GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START, GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS, SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL, SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START, SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS, SET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FIELD
} from './solarWindsActions';
import * as types from './types';
import { ADD_USER_TOKEN_TO_TABLE, RESET_LAST_ADDED_TOKEN, SETTINGS_GET_USER_TOKENS_REQUEST, SETTINGS_GET_USER_TOKENS_SUCCESS, SETTINGS_USER_TOKENS_CREATE_SUCCESS, SETTINGS_USER_TOKENS_FAIL, SETTINGS_USER_TOKENS_GET_SCOPES, SETTINGS_USER_TOKENS_RESET_ERROR, SETTINGS_USER_TOKENS_SET_SELECTED_TAB, SETTINGS_USER_TOKENS_SHOW_ACTION_MODAL, SETTINGS_USER_TOKENS_SHOW_ADD_TOKEN_MODAL } from './accessTokens/types';
import { SET_INSTANCE_SETTINGS_START, SET_INSTANCE_SETTINGS_SUCCESS } from './instanceSettingsActions';

const initialState = {
  smtp: {
    hostname: '',
    sender: '',
    port: '',
    username: '',
    password: '',
    error: false,
    message: '',
    isFetching: false
  },
  saml: {
    isFetching: false,
    enabled: false,
    userRole: '',
    displayName: '',
    attributeIdentifier: '',
    spCertificate: '',
    idpCertificate: '',
    identityId: '',
    ssoUrl: '',
    spPrivateKey: ''
  },
  changePassword: {
    isFetching: false,
    error: false,
    message: ''
  },
  twoFactor: {
    status: 'disabled', // disabled, requested, confirmed
    checked: false
  },
  loginSettings: {
    checked: false
  },
  report: {
    useMarkdown: false,
    borderWidth: 0
  },
  ticketingTools: {
    isFetching: false,
    templates: [],
    activeConfig: { ...types.DEFAULT_ACTIVE_CONFIG, isLoading: false, error: null },

    solarWinds: {
      url: '',
      apiKey: '',
      template: '',
      error: false,
      message: ''
    },
    gitLab: {
      url: '',
      accessToken: '',
      project: '',
      sslVerify: false,
      selfSignedCertificate: false,
      template: '',
      error: false,
      message: ''
    },
    serviceNow: {
      authenticated: false,
      category: '',
      subcategory: '',
      template: '',
      url: '',
      isFetching: false,
      error: false,
      message: ''
    },
    jira: {
      authenticated: false,
      authorized: false,
      customFields: {},
      issueType: '',
      template: {},
      templates: [],
      users: [],
      isTemplate: false,
      consumerKey: '',
      projectKey: '',
      url: '',
      isFetching: false,
      error: false,
      message: '',
      publicKey: '',
      payload: {
        merge_vulns: false,
        issue_name: '',
        projects_selected: [],
        projects_changed: {}
      }
    }
  },
  ldap: {
    enabled: false,
    server: '',
    port: null,
    domain: '',
    domain_dn: '',
    bind_format: '',
    bind_dn: '',
    user_attribute: '',
    use_local_roles: true,
    default_local_role: '',
    admin_group: '',
    pentester_group: '',
    client_group: '',
    asset_owner_group: '',
    use_ldaps: false,
    use_start_tls: false,
    disconnect_timeout: null,
    paginated_fetch: false,
    user_class: '',
    group_class: '',
    error: false,
    message: '',
    isFetching: false
  },
  instanceSettings: {
    allowMultipleSessions: false
  }
};

function settings (state = initialState, action) {
  switch (action.type) {
    // SMTP section
    case GET_SETTINGS_SMTP_START: {
      return {
        ...state,
        smtp: {
          ...initialState.smtp,
          isFetching: true
        }
      };
    }

    case GET_SETTINGS_SMTP_FAIL: {
      return {
        ...state,
        smtp: {
          ...state.smtp,
          isFetching: false,
          error: true,
          message: action.data
        }
      };
    }

    case GET_SETTINGS_SMTP_SUCCESS: {
      return {
        ...state,
        smtp: {
          isFetching: false,
          hostname: action.response.host,
          sender: action.response.sender,
          port: action.response.port,
          password: action.response.password,
          username: action.response.username,
          enabled: action.response.enabled,
          ssl: action.response.ssl
        }
      };
    }

    case SAVE_SETTINGS_SMTP_START: {
      return {
        ...state,
        smtp: {
          ...state.smtp,
          isFetching: true
        }
      };
    }

    case SAVE_SETTINGS_SMTP_SUCCESS: {
      return {
        ...state,
        smtp: {
          ...state.smtp,
          isFetching: false,
          error: false,
          message: 'Your data was saved successfully.'
        }
      };
    }

    case SAVE_SETTINGS_SMTP_FAIL: {
      return {
        ...state,
        smtp: {
          ...state.smtp,
          isFetching: false,
          error: false,
          message: action.message
        }
      };
    }

    case SET_SETTINGS_SMTP_FIELD: {
      return {
        ...state,
        smtp: {
          ...state.smtp,
          [action.field]: action.value
        }
      };
    }

    //
    // REPORT section
    //
    case SET_SETTINGS_REPORT_FIELD: {
      return {
        ...state,
        report: {
          ...state.report,
          [action.field]: action.value
        }
      };
    }

    //
    // CHANGE PASSWORD section
    //
    case SAVE_SETTINGS_CHANGE_PASSWORD_START: {
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isFetching: true
        }
      };
    }

    case SAVE_SETTINGS_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePassword: {
          ...initialState.changePassword,
          error: false,
          message: 'Your password was saved successfully.',
          isFetching: false
        }
      };
    }

    case SAVE_SETTINGS_CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isFetching: false,
          error: true,
          message: action.message
        }
      };
    }

    //
    // TWO FACTOR section
    //
    case GET_SETTINGS_TEMPLATES_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          templates: state.ticketingTools.templates,
          isFetching: true
        }
      };
    }

    case GET_SETTINGS_TEMPLATES_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          templates: action.templates,
          isFetching: false
        }
      };
    }

    case GET_SETTINGS_TEMPLATES_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          templates: state.ticketingTools.templates,
          isFetching: false
        }
      };
    }

    case GET_SETTINGS_TWO_FACTOR_START: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isFetching: true
        }
      };
    }

    case GET_SETTINGS_TWO_FACTOR_SUCCESS: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          status: action.status,
          checked: action.status === 'requested' || action.status === 'confirmed',
          isFetching: false
        }
      };
    }

    case GET_SETTINGS_TWO_FACTOR_FAIL: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isFetching: false,
          error: true,
          message: action.message
        }
      };
    }

    case SET_SETTINGS_TWO_FACTOR_REQUEST_START: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isFetching: true
        }
      };
    }

    case SET_SETTINGS_TWO_FACTOR_REQUEST_SUCCESS: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          status: action.status,
          isFetching: false,
          error: false,
          message: 'Two factor authentication request has been started successfully'
        }
      };
    }

    case SET_SETTINGS_TWO_FACTOR_REQUEST_FAIL: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isFetching: false,
          error: true,
          message: action.message
        }
      };
    }

    case SET_SETTINGS_TWO_FACTOR_CONFIRM_START: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          error: false,
          message: '',
          confirmed: false,
          isFetching: true
        }
      };
    }

    case SET_SETTINGS_TWO_FACTOR_CONFIRM_SUCCESS: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          status: 'confirmed',
          error: false,
          message: 'Two factor authentication has been activated successfully!',
          isFetching: false
        }
      };
    }

    case SET_SETTINGS_TWO_FACTOR_CONFIRM_FAIL: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          confirmed: false,
          isFetching: false,
          error: true,
          message: action.message
        }
      };
    }

    case SET_SETTINGS_TWO_FACTOR_DISABLE_START: {
      return {
        ...state,
        twoFactor: {
          ...initialState.twoFactor,
          isFetching: true,
          error: false,
          message: ''
        }
      };
    }

    case SET_SETTINGS_TWO_FACTOR_DISABLE_SUCCESS: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          status: 'disabled',
          isFetching: false,
          error: false,
          message: 'Two factor authentication has been disabled successfully!'
        }
      };
    }

    case SET_SETTINGS_TWO_FACTOR_DISABLE_FAIL: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isFetching: false,
          status: 'confirmed',
          error: true,
          message: action.message
        }
      };
    }

    case SET_SETTINGS_TWO_FACTOR_CHANGE_STATUS_SUCCESS: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          checked: action.checked,
          error: false,
          message: ''
        }
      };
    }

    //
    // LOGIN SETTINGS section
    //
    case SET_SETTINGS_CHANGE_LOGIN_SETTINGS_FIELD: {
      return {
        ...state,
        loginSettings: {
          ...state.loginSettings,
          [action.field]: action.value
        }
      };
    }

    case SAVE_SETTINGS_LOGIN_SETTINGS_START: {
      return {
        ...state,
        loginSettings: {
          ...state.loginSettings,
          isFetching: true
        }
      };
    }

    case SAVE_SETTINGS_LOGIN_SETTINGS_CLEAR: {
      return {
        ...state,
        changePassword: {
          ...initialState.changePassword
        }
      };
    }

    case SAVE_SETTINGS_LOGIN_SETTINGS_SUCCESS: {
      return {
        ...state,
        loginSettings: {
          ...state.loginSettings,
          logMeLastWorkspaceUsed: action.logMeLastWorkspaceUsed,
          isFetching: false,
          error: false,
          message: action.message
        },
        userData: {
          ...state.userData,
          email: action.email
        }
      };
    }

    case SAVE_SETTINGS_LOGIN_SETTINGS_FAIL: {
      return {
        ...state,
        loginSettings: {
          ...state.loginSettings,
          isFetching: false,
          error: true,
          message: action.message
        }
      };
    }

    case LOAD_SETTINGS_LOGIN_SETTINGS_SUCCESS: {
      return {
        ...state,
        loginSettings: {
          ...state.loginSettings,
          logMeLastWorkspaceUsed: action.autoSelectWs,
          isFetching: false
        }
      };
    }

    // COMMON SETTINGS section
    case SET_SETTINGS_SHOW_MODAL: {
      return {
        ...state,
        showModal: action.showModal
      };
    }

    // REPORT section
    case SAVE_SETTINGS_REPORT_SUCCESS: {
      return {
        ...state,
        report: {
          ...state.report,
          useMarkdown: action.useMarkdown,
          borderWidth: action.borderWidth
        }
      };
    }

    case LOAD_SETTINGS_REPORT_SUCCESS: {
      return {
        ...state,
        report: {
          ...state.report,
          useMarkdown: action.useMarkdown,
          borderWidth: action.borderWidth
        }
      };
    }

    // TICKETING TOOLS
    // solarwinds
    case GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          solarWinds: {
            ...initialState.ticketingTools.solarWinds,
            isFetching: true
          }
        }
      };
    }

    case GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          solarWinds: {
            ...state.ticketingTools.solarWinds,
            url: action.response.url,
            apiKey: action.response.apikey,
            template: action.response.ticket_config.template,
            isFetching: false
          }
        }
      };
    }

    case GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          solarWinds: {
            ...state.ticketingTools.solarWinds,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          solarWinds: {
            ...state.ticketingTools.solarWinds,
            isFetching: true,
            error: false,
            message: ''
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          activeConfig: {
            ...state.ticketingTools.activeConfig,
            whd: {
              ...state.ticketingTools.activeConfig.whd,
              available: true
            }
          },
          solarWinds: {
            ...state.ticketingTools.solarWinds,
            isFetching: false,
            error: false
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          solarWinds: {
            ...state.ticketingTools.solarWinds,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    // gitlab
    case GET_SETTINGS_TICKETING_TOOLS_GITLAB_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          gitLab: {
            ...initialState.ticketingTools.gitLab,
            isFetching: true
          }
        }
      };
    }

    case GET_SETTINGS_TICKETING_TOOLS_GITLAB_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          gitLab: {
            ...state.ticketingTools.gitLab,
            url: get(action, 'response.url', ''),
            accessToken: get(action, 'response.access_token', ''),
            project: get(action, 'response.project', ''),
            sslVerify: get(action, 'response.ssl_config.ssl_verify', false),
            selfSignedCertificate: get(action, 'response.ssl_config.self_signed_cert', false),
            template: get(action, 'response.ticket_config.template', ''),
            isFetching: false
          }
        }
      };
    }

    case GET_SETTINGS_TICKETING_TOOLS_GITLAB_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          gitLab: {
            ...state.ticketingTools.gitLab,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          gitLab: {
            ...state.ticketingTools.gitLab,
            isFetching: true,
            error: false,
            message: ''
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          activeConfig: {
            ...state.ticketingTools.activeConfig,
            gitlab: {
              ...state.ticketingTools.activeConfig.gitlab,
              available: true
            }
          },
          gitLab: {
            ...state.ticketingTools.gitLab,
            isFetching: false,
            error: false
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_GITLAB_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          gitLab: {
            ...state.ticketingTools.gitLab,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    // servicenow
    case GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...initialState.ticketingTools.serviceNow,
            isFetching: true
          }
        }
      };
    }

    case GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS: {
      const table = (action.response.ticket_config.table && (action.response.ticket_config.table === 'sn_vul_app_vulnerable_item')) ? 'Application Vulnerable Items' : 'Incidents';
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            authenticated: get(action, 'response.oauth.authenticated', false),
            url: get(action, 'response.url', ''),
            category: get(action, 'response.ticket_config.category', ''),
            subcategory: get(action, 'response.ticket_config.subcategory', ''),
            template: get(action, 'response.ticket_config.template', ''),
            isFetching: false,
            table
          }
        }
      };
    }

    case GET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            isFetching: true,
            error: false,
            message: ''
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          activeConfig: {
            ...state.ticketingTools.activeConfig,
            servicenow: {
              ...state.ticketingTools.activeConfig.servicenow,
              available: true
            }
          },
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            isFetching: false,
            error: false
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    case AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            isFetching: true,
            error: false,
            message: '',
            authenticated: false
          }
        }
      };
    }

    case AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            authenticated: true,
            isFetching: false,
            error: false,
            message: 'ServiceNow was authenticated successfully',
            username: action.response.username,
            password: action.response.password
          }
        }
      };
    }

    case AUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            authenticated: false,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    case DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            isFetching: true,
            error: false,
            message: ''
          }
        }
      };
    }

    case DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            authenticated: false,
            isFetching: false,
            error: false,
            message: 'ServiceNow was deauthenticated successfully'
          }
        }
      };
    }

    case DEAUTHENTICATE_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    // jira
    case GET_SETTINGS_TICKETING_TOOLS_JIRA_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...initialState.ticketingTools.jira,
            isFetching: true
          }
        }
      };
    }

    case GET_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            authenticated: get(action, 'response.oauth.authenticated', false),
            authorized: get(action, 'response.oauth.authenticated', false),
            url: get(action, 'response.url', ''),
            consumerKey: get(action, 'response.oauth.consumer_key', ''),
            projectKey: get(action, 'response.project_key', ''),
            issueType: get(action, 'response.ticket_config.issue_type', ''),
            template: get(action, 'response.ticket_config.template', ''),
            users: get(action, 'response.jira_users', []),
            isFetching: false
          }
        }
      };
    }

    case GET_SETTINGS_TICKETING_TOOLS_JIRA_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_JIRA_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: true,
            error: false,
            message: ''
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          activeConfig: {
            ...state.ticketingTools.activeConfig,
            jira: {
              ...state.ticketingTools.activeConfig.jira,
              available: true
            }
          },
          jira: {
            ...state.ticketingTools.jira,
            isFetching: false,
            error: false
          }
        }
      };
    }

    case SAVE_SETTINGS_TICKETING_TOOLS_JIRA_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    case AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: true,
            error: false,
            message: '',
            authorized: false
          }
        }
      };
    }

    case AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            // authenticated: true,
            authorized: true,
            isFetching: false
          }
        }
      };
    }

    case AUTHORIZE_SETTINGS_TICKETING_TOOLS_JIRA_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            // authesnticated: false,
            authorized: false,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    //
    // ACCESS TOKEN
    //
    case ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: true,
            error: false,
            message: ''
          }
        }
      };
    }

    case ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            authenticated: true,
            authorized: true,
            isFetching: false,
            error: false,
            message: 'Jira was authorized successfully'
          }
        }
      };
    }

    case ACCESS_SETTINGS_TICKETING_TOOLS_JIRA_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            authenticated: false,
            authorized: false,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    //
    // SETTINGS FIELDS
    //
    case SET_SETTINGS_TICKETING_TOOLS_JIRA_FIELD: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            [action.field]: action.value
          }
        }
      };
    }

    case SET_SETTINGS_TICKETING_TOOLS_SERVICE_NOW_FIELD: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          serviceNow: {
            ...state.ticketingTools.serviceNow,
            [action.field]: action.value
          }
        }
      };
    }

    case SET_SETTINGS_TICKETING_TOOLS_GITLAB_FIELD: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          gitLab: {
            ...state.ticketingTools.gitLab,
            [action.field]: action.value
          }
        }
      };
    }

    case SET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FIELD: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          solarWinds: {
            ...state.ticketingTools.solarWinds,
            [action.field]: action.value
          }
        }
      };
    }

    case SEND_VULN_TO_TOOL: {
      return {
        ...state,
        sendVulnToTool: {
          ...state.sendVulnToTool,
          payload: action.payload
        }
      };
    }

    case GET_PROJECTS_TICKETING_TOOLS_JIRA_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: true,
            error: false,
            message: ''
          }
        }
      };
    }

    case GET_PROJECTS_TICKETING_TOOLS_JIRA_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            templates: action.response,
            isFetching: false,
            error: false
          }
        }
      };
    }

    case GET_PROJECTS_TICKETING_TOOLS_JIRA_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    case ADD_TO_JIRA_PAYLOAD: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            payload: {
              [action.payload.key]: action.payload
            }
          }
        }
      };
    }

    case SET_JIRA_PAYLOAD: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            payload: action.payload
          }
        }
      };
    }
    case SET_JIRA_TEMPLATE: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            template: action.template
          }
        }
      };
    }

    case RESET_JIRA_PAYLOAD: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            payload: initialState.ticketingTools.jira.payload
          }
        }
      };
    }
    case ADD_PROJECT_JIRA_PAYLOAD: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            payload: {
              ...state.ticketingTools.jira.payload,
              projects_selected: [...state.ticketingTools.jira.payload.projects_selected, Object.keys(action.project)[0]],
              projects_changed: {
                ...state.ticketingTools.jira.payload.projects_changed,
                [Object.keys(action.project)[0]]: action.project
              }
            }
          }
        }
      };
    }

    case DELETE_PROJECT_JIRA_PAYLOAD: {
      // eslint-disable-next-line no-unused-vars
      const { [action.project]: del, ...projects } = state.ticketingTools.jira.payload.projects_changed;

      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            payload: {
              ...state.ticketingTools.jira.payload,
              projects_selected: state.ticketingTools.jira.payload.projects_selected.filter((project) => project !== action.project),
              projects_changed: projects
            }
          }
        }
      };
    }

    case SET_PROJECT_CONFIG_JIRA_PAYLOAD: {
      const projectsSelected = [...state.ticketingTools.jira.payload.projects_selected, action.key];
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            payload: {
              ...state.ticketingTools.jira.payload,
              projects_changed: {
                ...state.ticketingTools.jira.payload.projects_changed,
                [action.key]: {
                  ...state.ticketingTools.jira.payload.projects_changed[action.key],
                  ticket_config: action.config
                }
              },
              projects_selected: [...new Set(projectsSelected)]
            }
          }
        }
      };
    }

    case SEND_TEMPLATES_TICKETING_TOOLS_JIRA_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: true
          }
        }
      };
    }

    case SEND_TEMPLATES_TICKETING_TOOLS_JIRA_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: false,
            error: false,
            message: 'Your data was saved successfully.',
            template: {}
          }
        }
      };
    }

    case SEND_TEMPLATES_TICKETING_TOOLS_JIRA_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: false,
            error: false,
            message: action.message
          }
        }
      };
    }

    case GET_PROJECT_TICKETING_TOOLS_JIRA_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: true,
            error: false,
            message: ''
          }
        }
      };
    }

    case GET_PROJECT_TICKETING_TOOLS_JIRA_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            template: action.response,
            isFetching: false,
            error: false
          }
        }
      };
    }

    case GET_PROJECT_TICKETING_TOOLS_JIRA_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: false,
            error: true,
            message: action.message
          }
        }
      };
    }

    case GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_START: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: true
          }
        }
      };
    }

    case GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_SUCCESS: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: false,
            error: false,
            message: 'Your data was saved successfully.',
            publicKey: action.response
          }
        }
      };
    }

    case GET_PUBLIC_KEY_TICKETING_TOOLS_JIRA_FAIL: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isFetching: false,
            error: false,
            message: action.message
          }
        }
      };
    }

    case SET_IS_TEMPLATE: {
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          jira: {
            ...state.ticketingTools.jira,
            isTemplate: action.payload
          }
        }
      };
    }

    case types.SETTINGS_GET_LDAP: {
      return {
        ...state,
        ldap: action.data
      };
    }

    case types.SETTINGS_SAVE_LDAP: {
      return {
        ...state,
        ldap: action.updatedLdap
      };
    }

    case types.SET_SETTINGS_LDAP_FIELD: {
      return {
        ...state,
        ldap: {
          ...state.ldap,
          [action.field]: action.value
        }
      };
    }

    case types.SETTINGS_LDAP_FAIL: {
      return {
        ...state,
        ldap: {
          ...state.ldap,
          isFetching: false
        }
      };
    }

    case types.SETTINGS_LDAP_RESET: {
      return {
        ...state,
        ldap: {
          ...initialState.ldap
        }
      };
    }

    case types.SETTINGS_SAML_FETCH: {
      return {
        ...state,
        saml: {
          ...state.saml,
          isFetching: true,
          errorMessage: ''
        }
      };
    }

    case types.SETTINGS_SAML_FAIL: {
      return {
        ...state,
        saml: {
          ...state.saml,
          isFetching: false
        }
      };
    }

    case types.SETTINGS_SAML_SUCCESS: {
      return {
        ...state,
        saml: {
          ...state.saml,
          isFetching: false,
          ...action.response
        }
      };
    }

    case types.SET_SETTINGS_SAML_FIELD: {
      return {
        ...state,
        saml: {
          ...state.saml,
          [action.field]: action.value
        }
      };
    }

    case types.SET_SETTINGS_SAML_RESET: {
      return {
        ...state,
        saml: {
          ...initialState.saml
        }
      };
    }
    case GET_ACTIVE_CONFIG_REQUEST:
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          isLoading: true,
          error: null
        }
      };
    case GET_ACTIVE_CONFIG_SUCCESS:
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          activeConfig: action.payload,
          isLoading: false,
          error: null
        }
      };
    case GET_ACTIVE_CONFIG_FAILURE:
      return {
        ...state,
        ticketingTools: {
          ...state.ticketingTools,
          isLoading: false,
          error: action.payload
        }
      };

    case GET_USER_DATA_SUCCESS: {
      return {
        ...state,
        userData: action.userData
      };
    }

    case GET_USER_DATA_FAIL: {
      return {
        ...state,
        userData: {
          ...state.userData,
          error: true,
          errorMessage: action.error
        }
      };
    }

    case SET_USER_EMAIL: {
      return {
        ...state,
        userData: {
          ...state.userData,
          email: action.value
        }
      };
    }

    case SETTINGS_USER_TOKENS_FAIL: {
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          error: true,
          errorMessage: action.errorMessage
        }
      };
    }

    case SETTINGS_USER_TOKENS_RESET_ERROR: {
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          error: false,
          errorMessage: ''
        }
      };
    }

    case SETTINGS_GET_USER_TOKENS_REQUEST: {
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          isFetching: true
        }
      };
    }

    case SETTINGS_GET_USER_TOKENS_SUCCESS: {
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          isFetching: false,
          data: {
            tokens: action.data.user_tokens,
            count: action.data.count
          }
        }
      };
    }

    case SETTINGS_USER_TOKENS_SHOW_ACTION_MODAL: {
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          showActionModal: action.show,
          actionToConfirm: action.action,
          selectedTokenId: action.id
        }
      };
    }

    case SETTINGS_USER_TOKENS_SHOW_ADD_TOKEN_MODAL: {
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          showAddTokenModal: action.show,
          addTokenModalStep: action.step
        }
      };
    }

    case SETTINGS_USER_TOKENS_CREATE_SUCCESS: {
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          lastAddedToken: action.token
        }
      };
    }

    case ADD_USER_TOKEN_TO_TABLE: {
      const tokensList = state.userTokens.data.tokens;
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          data: {
            tokens: [action.token, ...tokensList],
            count: state.userTokens.data.count + 1
          },
          lastAddedToken: null
        }
      };
    }

    case RESET_LAST_ADDED_TOKEN: {
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          lastAddedToken: null
        }
      };
    }

    case SETTINGS_USER_TOKENS_SET_SELECTED_TAB: {
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          selectedTab: action.selectedTab
        }
      };
    }

    case SETTINGS_USER_TOKENS_GET_SCOPES: {
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          scopes: action.scopes
        }
      };
    }

    case SET_INSTANCE_SETTINGS_START: {
      return {
        ...state,
        isLoading: true
      };
    }

    case SET_INSTANCE_SETTINGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        instanceSettings: {
          allowMultipleSessions: action.response.allow_multiple_sessions
        }
      };
    }

    default: {
      return state;
    }
  }
}

export default settings;
