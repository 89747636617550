import React from 'react';
import {
  ModalWrapper, Wrapper, Title, Footer, Close, WarningIcon
} from './styled';
import useKeyPress from 'Hooks/useKeyPress';

const Warning = ({ title, children, onClose }) => {
  useKeyPress(() => {
    onClose();
  }, ['Escape', 'Enter']);

  return (
    <ModalWrapper>
      <Wrapper>
        <Title>
          <WarningIcon />
          {title}
        </Title>
        { children }
        <Footer>
          <Close onClick={ onClose }>Close</Close>
        </Footer>
      </Wrapper>
    </ModalWrapper>
  );
};

export default Warning;
