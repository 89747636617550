import React, { useState } from 'react';
import IconButton from 'Common/Components/IconButton';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import DuplicateIcon from 'Screens/Contextualization/Manage/components/CustomActionBar/components/DuplicateButton/styled';
import ModalWarning from 'Common/Components/ModalWarning';

const DuplicateButton = () => {
  const [showWarning, setShowWarning] = useState();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'vuln.detail.duplicates.markAsDuplicates' });
  const warningMessage = intl.formatMessage({ id: 'vuln.duplicates.modalWarning.message' });

  const onCloseWarning = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <DuplicateIcon /> } title={ title } disabled />
      { showWarning && <ModalWarning onCloseCallback={ onCloseWarning } title={ 'Mark as Duplicate' } description={ warningMessage } /> }
    </>
  );
};

DuplicateButton.propTypes = {
  showModal: PropTypes.func.isRequired
};

export default DuplicateButton;
