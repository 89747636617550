import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  max-width: 600px;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 21px;
  padding-bottom: 48px;
  text-align: center;
  max-width: 460px;
`;

Content.displayName = 'Content';

export const Title = styled.div`
  font-size: 21px;
  letter-spacing: 0.16px;
  color: ${colors.dark2};
  text-align: center;
`;

Title.displayName = 'Title';

export const Description = styled.div`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: ${colors.grey19};
  text-align: center;
  padding-top: 23px;
  padding-bottom: 21px;
`;

Description.displayName = 'Description';

export const FeatureWrapper = styled.div`
  padding: 21px 21px 21px 0;
`;

FeatureWrapper.displayName = 'FeatureWrapper';

export const FeatureImage = styled.img`
  width: 300px;
`;

FeatureImage.displayName = 'FeatureImage';
