import styled from 'styled-components';
import colors from 'Styles/colors';
import { Title } from 'Screens/Settings/common-styled';

export const Wrapper = styled.div`
  padding: 20px 0 20px 0;
  ${Title} {
    font-size: 14.5px;
    font-weight: 500;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  padding: 0;
  & > *:not(:last-child) {
    padding-bottom: 20px;
  }
`;
Content.displayName = 'Content';

export const Label = styled.div`
  font-size: 12.5px;
  line-height: 1.12;
  font-weight: 400;
  color: ${colors.grey17};
  padding-bottom: 20px;
  display: inline;
`;
Label.displayName = 'Label';

export const WrapperTemplate = styled.div`
  width: calc(92% / 2);
`;
WrapperTemplate.displayName = 'WrapperTemplate';
