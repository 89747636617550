import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import image from 'Images/ticketing-license.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFetching } from 'store/License/selectors';
import { openUpgradeLicense } from 'store/License/actions';
import BlueButton from 'Common/Components/BlueButton';
import { trackEvent } from 'tracking/GA';
import { CATEGORIES } from 'tracking/GA/constants';
import get from 'lodash/get';
import {
  Wrapper, Content, Title, Description, FeatureWrapper, FeatureImage
} from './styled';

const UpgradeLicense = ({ trackingShow, trackingClick }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => selectIsFetching(state));

  const onClick = () => {
    if (!isFetching) {
      if (trackingClick) dispatch(trackEvent(CATEGORIES.upgrade, get(trackingClick, 'name', ''), get(trackingClick, 'label', '')));
      dispatch(openUpgradeLicense());
    }
  };

  useEffect(() => {
    dispatch(trackEvent(CATEGORIES.upgrade, get(trackingShow, 'name', ''), get(trackingShow, 'label', '')));
  }, [dispatch, trackingShow]);

  return (
    <Wrapper>
      <FeatureWrapper>
        <FeatureImage src={ image } />
      </FeatureWrapper>
      <Content>
        <Title>{ intl.formatMessage({ id: 'upgradeLicense.sendToTool.title' }) }</Title>
        <Description>{ intl.formatMessage({ id: 'upgradeLicense.sendToTool.description' }) }</Description>
        <BlueButton isLoading={ isFetching } label={ intl.formatMessage({ id: 'upgradeLicense.upgradeNow' }) } onClick={ onClick } />
      </Content>
    </Wrapper>
  );
};
UpgradeLicense.propTypes = {
  trackingShow: PropTypes.instanceOf(Object).isRequired,
  trackingClick: PropTypes.instanceOf(Object).isRequired
};
export default UpgradeLicense;
