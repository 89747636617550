import fetchApi from 'services/api/connector';

export const fetchApiConfig = () => fetchApi('config', { version: 'v1' });

export const getSession = () => fetchApi('session', { version: 'v1' });

export const forgotPassword = (email) => fetchApi('auth/forgot_password', { version: 'v1', method: 'POST', data: { email } }, null, false);

export const resetPassword = (token, password, repeatPassword) => fetchApi(`auth/reset_password/${token}`, { version: 'v1', method: 'POST', data: { password, password_confirm: repeatPassword } }, null, false);

export const getLicense = ({
  ver, client_key, cloud_instance, dev_environment, stage_environment
}) => {
  let host = '';
  if (!cloud_instance) host = 'portal.faradaysec.com';
  else if (dev_environment) host = 'cloud.dev.faradaysec.com';
  else if (stage_environment) host = 'cloud-staging.dev.faradaysec.com';
  else host = 'cloud.faradaysec.com';

  fetchApi(`https://${host}/api/v1/license_check?version=${ver}&key=${client_key}`, {}, null, true, true);
};
