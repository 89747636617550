import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_AGENT_CREATE, MODAL_AGENT_TOKEN } from 'store/modals/modals';
import { closeModal, openModal } from 'store/modals/actions';
import TokenButton from './components/TokenButton';
import { Wrapper } from './styled';
import Token from './components/TokenButton/components/Token';
import CreateButton from './components/CreateButton';
import CreateAgentMenu from '../CreateNewAgent';
import { PERMISSION_TYPES, FEATURE_AGENTS } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import DeleteButton from './components/DeleteButton';

const ActionBar = ({ showCloudAgents }) => {
  const dispatch = useDispatch();
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_AGENTS, PERMISSION_TYPES.CREATE));
  const canDelete = useSelector((state) => selectAllowedByRole(state, FEATURE_AGENTS, PERMISSION_TYPES.DELETE));
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_AGENTS, PERMISSION_TYPES.UPDATE));

  const canSeeToken = canCreate && canDelete && canUpdate;
  return (
    <Wrapper>
        {
          !showCloudAgents &&
          <>
            { canCreate && <CreateButton /> }
            { canSeeToken && <TokenButton onClick={ () => dispatch(openModal(MODAL_AGENT_TOKEN)) } /> }
            { canDelete && <DeleteButton /> }
          </>
        }
      <ReduxModal id={ MODAL_AGENT_TOKEN }>
        { canSeeToken && <Token /> }
      </ReduxModal>
      <ReduxModal id={ MODAL_AGENT_CREATE }>
        { canCreate && <CreateAgentMenu onClose={ () => dispatch(closeModal(MODAL_AGENT_CREATE)) } /> }
      </ReduxModal>
    </Wrapper>
  );
};
export default ActionBar;
