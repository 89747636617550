import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectGraphs, selectIsFetching, selectCurrentViewMode, selectIsEmpty
} from 'store/Analytics/selectors';
import { FormattedMessage } from 'react-intl';
import { GRAPH_LIST_VIEW } from 'store/Analytics/viewMode';
import { getColumns, setOrder } from './Columns';
import { Wrapper, StyledTable } from './styled';

const Table = () => {
  const isEmpty = useSelector(selectIsEmpty);
  const viewMode = useSelector(selectCurrentViewMode);
  const graphs = useSelector(selectGraphs);
  const isFetching = useSelector(selectIsFetching);
  const mockData = [...Array(15).keys()];

  const fetchData = (state) => {
    const { sorted } = state;
    if (!isFetching) {
      setOrder(sorted, graphs);
    }
  };

  if (isEmpty) return null;
  if (viewMode === GRAPH_LIST_VIEW) {
    return (
      <Wrapper>
        <StyledTable
          data={ isFetching ? mockData : graphs }
          columns={ getColumns(isFetching) }
          minRows={ 0 }
          showPagination={ false }
          noDataText={ !isFetching && <FormattedMessage id="app.nodata" /> }
          onFetchData={ fetchData }
          manual
        />
      </Wrapper>
    );
  }
  return null;
};

export default Table;
