import {
  GET_TAGS_LIST_START,
  GET_TAGS_LIST_SUCCESS,
  GET_TAGS_LIST_ERROR,
  SHOW_CREATE_TAG_MODAL,
  SET_TAGS_IMPORT_MODAL,
  GET_HOSTS_TAGS_LIST_SUCCESS
} from '../../Screens/Tags/actions/Actions';

import { GET_GENERAL_HOSTS_TAGS_LIST_SUCCESS, GET_GENERAL_SERVICES_TAGS_LIST_SUCCESS, GET_GENERAL_TAGS_LIST_SUCCESS, GET_SERVICES_TAGS_LIST_SUCCESS } from 'Screens/Contextualization/Tags/actions/Actions';

const initialState = {
  isFetching: false,
  tagsList: [],
  error: false,
  errorMessage: '',
  showCreateTagModal: false,
  importTagsFullList: []
};

function tags (state = initialState, action) {
  switch (action.type) {
    case GET_TAGS_LIST_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_TAGS_LIST_SUCCESS: {
      return {
        ...state,
        tagsList: action.data,
        isFetching: false
      };
    }

    case GET_TAGS_LIST_ERROR: {
      return {
        ...state,
        isFetching: false,
        errorMessage: 'An error has occurred',
        error: true
      };
    }

    case SHOW_CREATE_TAG_MODAL: {
      return {
        ...state,
        [action.entity]: {
          showCreateTagModal: action.show
        }
      };
    }

    case SET_TAGS_IMPORT_MODAL: {
      return {
        ...state,
        importTagsFullList: action.tags
      };
    }

    case GET_HOSTS_TAGS_LIST_SUCCESS: {
      return {
        ...state,
        hostsTagsList: action.data,
        isFetching: false
      };
    }

    case GET_SERVICES_TAGS_LIST_SUCCESS: {
      return {
        ...state,
        servicesTagsList: action.data,
        isFetching: false
      };
    }

    case GET_GENERAL_TAGS_LIST_SUCCESS: {
      return {
        ...state,
        generalTagsList: action.data,
        isFetching: false
      };
    }

    case GET_GENERAL_HOSTS_TAGS_LIST_SUCCESS: {
      return {
        ...state,
        generalHostsTagsList: action.data,
        isFetching: false
      };
    }

    case GET_GENERAL_SERVICES_TAGS_LIST_SUCCESS: {
      return {
        ...state,
        generalServicesTagsList: action.data,
        isFetching: false
      };
    }

    default: {
      return state;
    }
  }
}

export default tags;
