import styled from 'styled-components';
import { ReactComponent as _Error } from 'Images/error-mark.svg';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
`;
Wrapper.displayName = 'Wrapper';

export const ErrorIcon = styled(_Error)`
  display: ${({ error }) => (error ? 'block' : 'none')};
  position: absolute;
  right: 14px;
  top: 28px;
`;
ErrorIcon.displayName = 'ErrorIcon';
