import styled from 'styled-components';
import { Accept, Cancel } from 'Common/Components/Button/styled';

export const ConfirmButton = styled(Accept)`
    //margin-left: 10px;
    width: fit-content;
    margin: ${({ margin }) => (margin || '')};
`;
ConfirmButton.displayName = 'ConfirmButton';

export const CancelButton = styled(Cancel)`
`;
CancelButton.displayName = 'CancelButton';

export const ButtonContainer = styled.div`
align-self: flex-end;
margin-bottom: 16px;
`;
