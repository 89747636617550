import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    max-width: 700px;
    flex-direction: column;
    margin-left: 19px;
    margin-right: auto;
    width: 50%;
`;
Wrapper.displayName = 'FormWrapper';

export default Wrapper;
