import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Header from './components/Header';
import Tabs from './components/Tabs';
import {
  Wrapper, DetailWrapper, ResizerWrapper,
  Resizer, Transparency
} from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/styled';
import { useResizeX } from 'Hooks/useResize';
import useWindowDimensions from 'Hooks/useWindowSize';
import ImagePreviewModal from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/ImagePreviewModal';
import TabSelector from './components/Tabs/components/TabSelector';
import { selectShowTagsModal } from 'store/Tags/selectors';
import CreateEditTagsModal from './components/CreateEditTagsModal';
import { selectDetailTags, selectSelected, selectShowDetail, selectVulnDetailId } from 'store/Contextualization/AssetDetail/selectors';
import IntegrationsModals from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/IntegrationsModals';

const VulnerabilityDetail = () => {
  const [showTransparency, setShowTransparency] = useState(false);
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const resizerRef = useRef(null);
  const { width } = useWindowDimensions();
  const minWidth = width < 3000 ? width * 0.55 : width * 0.35;
  const maxWidth = width < 3000 ? width * 0.75 : width * 0.55;
  const wrapperWidth = useResizeX(wrapperRef, resizerRef, minWidth, minWidth, maxWidth);
  const showTagsModal = useSelector((state) => selectShowTagsModal('vulnAssetDetail', state));
  const vulnsTags = useSelector((state) => selectDetailTags('vulns', state));
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const vulnDetailId = useSelector(selectVulnDetailId);
  const isVulnDetailOpen = useSelector((state) => selectShowDetail('vulns', state));

  const onScroll = () => {
    const scrollTop = get(ref, 'current.scrollTop', 0);
    if (scrollTop === 0) setShowTransparency(false);
    else setShowTransparency(true);
  };

  return (
    <>
      { showTagsModal && <CreateEditTagsModal vulnsTags={ vulnsTags } entity="vulnAssetDetail" /> }
      <ImagePreviewModal />
      <IntegrationsModals vulnsSelected={ vulnsSelected } vulnId={ vulnDetailId } isVulnDetailOpen={ isVulnDetailOpen } entity="vulnsAssets" />
      <Wrapper ref={ wrapperRef } wrapperWidth={ wrapperWidth }>
        <ResizerWrapper ref={ resizerRef } children={ <Resizer /> } />
        <DetailWrapper>
          <Header />
          <TabSelector />
          { showTransparency && <Transparency /> }
          <Tabs tabRef={ ref } onScroll={ onScroll } showTransparency={ showTransparency } />
        </DetailWrapper>

      </Wrapper>
    </>
  );
};

export default VulnerabilityDetail;
