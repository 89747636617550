import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectReadOnly, selectVulnDetail } from 'store/Manage/selectors';
import debounce from 'lodash/debounce';
import { updateVuln } from 'store/Manage/actions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import CopyLink from './components/CopyLink';
import CopyApiLink from './components/CopyApiLink';
import { Wrapper, InputTitle } from './styled';

const Title = () => {
  const dispatch = useDispatch();
  const vulnDetail = useSelector(selectVulnDetail);
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const disabled = readOnly || !canUpdate;

  const [vulnName, setVulnName] = useState(vulnDetail.name);

  useEffect(() => {
    setVulnName(vulnDetail.name);
  }, [vulnDetail]);

  const updateName = useRef(debounce((name, vuln) => {
    if (name) {
      dispatch(updateVuln(vuln, 'name', name));
    }
  }, 1000));

  const handleChange = (e) => {
    const name = e.target.value;
    setVulnName(name);
    updateName.current(name, vulnDetail);
  };

  return (
    <Wrapper>
      <InputTitle value={ vulnName } onChange={ handleChange } disabled={ disabled } />
      <CopyLink />
      <CopyApiLink />
    </Wrapper>
  );
};

export default Title;
