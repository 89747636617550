import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import selectModal from 'store/modals/selectors';
import { closeModal } from 'store/modals/actions';
import {
  MODAL_ANALYTICS_CONFIRM_DELETE,
  MODAL_ANALYTICS_CONFIRM_NAVIGATION,
  MODAL_ANALYTICS_NOTIFICATION_MESSAGE
} from 'store/modals/modals';
import { selectWsErrors, selectHostErrors } from 'store/Analytics/selectors';
import ConfirmNavigation from './components/ConfirmNavigation';
import ConfirmDelete from './components/ConfirmDelete';
import Errors from './components/Errors';

// TODO: delete modal / warning modal
const Modals = () => {
  const dispatch = useDispatch();
  const showConfirmationModal = useSelector((state) => selectModal(state, MODAL_ANALYTICS_CONFIRM_NAVIGATION));
  const showDeleteModal = useSelector((state) => selectModal(state, MODAL_ANALYTICS_CONFIRM_DELETE));
  const wsNotFound = useSelector(selectWsErrors);
  const hostNotFound = useSelector(selectHostErrors);

  useEffect(() => () => {
    dispatch(closeModal(MODAL_ANALYTICS_CONFIRM_NAVIGATION));
    dispatch(closeModal(MODAL_ANALYTICS_CONFIRM_DELETE));
    dispatch(closeModal(MODAL_ANALYTICS_NOTIFICATION_MESSAGE));
  }, [dispatch]);

  return (
    <>
      { showConfirmationModal && <ConfirmNavigation /> }
      { showDeleteModal && <ConfirmDelete /> }
      { (!isEmpty(wsNotFound) || !isEmpty(hostNotFound)) ? <Errors /> : null}
    </>
  );
};

export default Modals;
