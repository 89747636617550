import styled from 'styled-components';

export const SeparatorWrapper = styled.div`
  width: 100%;
  height: 30px;
`;
SeparatorWrapper.displayName = 'SeparatorWrapper';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const AnchorButton = styled.a`
  display: block;
  text-align: center;
  cursor: pointer;
  height: 30.5px;
  width: 100%;
  border-radius: 3px;
  border: solid 1px #d4d7e5;
  background-color: #fff;
  text-decoration: none;
  padding: 4px 6px 2px 6px;
  user-select: none;
  &:hover {
    text-decoration: none;
    color: #1c2243;
    background-color: #edf2f7;
  }
  &:active {
    background-color: #d9e4ef;
  }
  font-size: 14.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c2243;
`;
AnchorButton.displayName = 'AnchorButton';

export const OrSeparator = styled.div`
  user-select: none;
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  width: 115%;
  transform: translate(-50%, -50%);
  color: #7a7e8f;

  &::before {
    content: '';
    position: absolute;
    width: calc((100% - 60px) /2);
    height: 1px;
    top: 50%;
    left: 0;
    z-index: -1;
    background: #bec8d2;
  }

  &::after {
    content: '';
    position: absolute;
    width: calc((100% - 60px) /2);
    height: 1px;
    top: 50%;
    right: 0;
    z-index: -1;
    background: #bec8d2;
  }

  &>span {
    background-color: lightblue;
    padding: 1rem;
    display: inline-block;
  }
`;
OrSeparator.displayName = 'OrSeparator';
