import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectDetail } from 'store/Contextualization/AssetDetail/selectors';
import { updateVulnFromAsset } from 'store/Contextualization/AssetDetail/actions';
import Impacts from 'Common/Components/Impacts';
import ExpandableWrapper from '../ExpandableWrapper';
import Wrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ImpactsTab/styled';
import useExpandable from 'Hooks/useExpandable';
import { selectIsReadOnly } from 'store/Faraday/selectors';

const TAB = 'general';
const ID = 'impact';

const ImpactsTab = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentVuln = useSelector((state) => selectDetail('vulns', state));
  const initialValue = get(currentVuln, 'impact', []);
  const [impacts, setImpacts] = useState(initialValue);
  const readOnly = useSelector(selectIsReadOnly);

  const getDefaultValue = () => {
    return (
      impacts.accountability ||
      impacts.availability ||
      impacts.integrity ||
      impacts.accountability
    );
  };

  const isExpanded = useExpandable(TAB, ID, getDefaultValue());

  useEffect(() => {
    setImpacts(initialValue);
  }, [initialValue]);

  const onSelectCheckbox = (impactChanged) => {
    const newImpacts = { ...impacts, [impactChanged]: !impacts[impactChanged] };
    setImpacts(newImpacts);
    dispatch(updateVulnFromAsset(currentVuln, 'impact', newImpacts));
  };

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.impact' }) } tab={ TAB } id={ ID }>
      <Wrapper>
        <Impacts
          selectCheckbox={ onSelectCheckbox }
          hideTitle
          impacts={ impacts }
          disabled={ readOnly }
        />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default ImpactsTab;
