import styled from 'styled-components';
import { ReactComponent as Cloud } from 'Images/miniCloud.svg';

export const Wrapper = styled.div`
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const LabelWrapper = styled.div`
  display: flex;
`;
LabelWrapper.displayName = 'LabelWrapper';

export const CloudIcon = styled(Cloud)`
  margin-right: 9px;
`;
CloudIcon.displayName = 'CloudIcon';

export const Option = styled.div`
`;
Option.displayName = 'Option';

export const dropdownStyle = {
  control: () => ({
    height: '35px',
    width: 'auto',
    borderRadius: '1px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: '#ffffff',
    border: 'solid 1px #fafbfc',
    borderBottomWidth: '0px',
    borderRightWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: () => ({
    fontSize: '13px',
    color: '#1c2243',
    position: 'relative',
    fontWeight: 400
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    position: 'absolute',
    top: '0px',
    right: '4px',
    color: '#505965',
    width: '30px'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#edf2f7' : 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    fontWeight: state.isSelected ? 600 : 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    height: '33px',
    display: 'flex',
    alignItems: 'center'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),
  menuList: () => ({
    maxHeight: '110px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: 'fit-content', height: '22px', fontSize: '14px', fontWeight: 400, top: '11px', position: 'relative', maxWidth: 'calc(100% - 24px)'
    };
  }
};
