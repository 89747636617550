import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { Dropdown } from 'Common/Components/Inputs';
import { useDispatch, useSelector } from 'react-redux';
import { selectTemplates } from 'store/ManageEditCreate/selectors';
import { getTemplates } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import { applyTemplate, applyTemplateFromAsset, applyTemplateFromOutsideWs } from 'Screens/Contextualization/KnowledgeBase/actions/Actions';
import { closeModal } from 'store/modals/actions';
import { MODAL_APPLY_TEMPLATE } from 'store/modals/modals';
import { inputDropdownStyle } from 'Common/Components/Inputs/styled';
import {
  ModalContent, Title, Subtitle, ButtonsContainer, ConfirmButton, CancelButton, Fields
} from './styled';
import ExternalId from './components/ExternalId';
import Policies from './components/Policies';
import ImpactsTab from './components/ImpactsTab';
import Resolution from './components/Resolution';
import Description from './components/Description';
import Severity from './components/Severity';
import Data from './components/Data';
import { isEmpty } from 'lodash';
import CustomAttributes from './components/CustomAttributes';
import CVE from './components/CVE';

const ApplyTemplateModal = ({ entity, vulnsSelected }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const savedTemplates = useSelector(selectTemplates);
  const vulnsCount = vulnsSelected.length;
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedFields, setSelectedFields] = useState(null);
  const vulnsText = vulnsCount > 1 ? 'vulnerabilities' : 'vulnerability';

  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  const formatTemplates = (templatesList) => templatesList.map((template) => ({
    label: template.name,
    value: template
  }));

  const options = useMemo(() => formatTemplates(savedTemplates), [savedTemplates]);

  const onChange = (template) => {
    setSelectedTemplate(template);
    setSelectedFields({
      name: !!template.value.name,
      exploitation: !!template.value.exploitation,
      desc: !!template.value.desc,
      resolution: !!template.value.resolution,
      easeofresolution: !!template.value.easeofresolution,
      policyviolations: template.value.policyviolations.length !== 0,
      impact: template.value.impact,
      data: !!template.value.data,
      external_id: !!template.value.external_id,
      refs: !!template.value.refs.length !== 0,
      cve: !!template.value.cve.length !== 0,
      cvss: !!(template.value.cvss3.vector_string !== null ? template.value.cvss3.vector_string : template.value.cvss2.vector_string),
      customfields: !isEmpty(template.value.customfields)
    });
  };

  const toggleFn = (field) => (e) => {
    e.stopPropagation();
    setSelectedFields({ ...selectedFields, [field]: !selectedFields[field] });
  };

  const removeCustomAttribute = (customAttribute) => {
    setSelectedTemplate({
      ...selectedTemplate,
      value: {
        ...selectedTemplate.value,
        customfields: {
          ...selectedTemplate.value.customfields,
          [customAttribute]: null
        }
      }
    });
  };

  const close = () => dispatch(closeModal(MODAL_APPLY_TEMPLATE));

  const accept = () => {
    if (entity === 'vulns') dispatch(applyTemplate(selectedTemplate?.value, selectedFields));
    else if (entity === 'vulnsGeneral') dispatch(applyTemplateFromOutsideWs(selectedTemplate?.value, selectedFields));
    else dispatch(applyTemplateFromAsset(selectedTemplate?.value, selectedFields));
    close();
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <Title>Select template from Vulnerability DB</Title>
        <Subtitle>Search for a template or select it from the list. </Subtitle>
        <Dropdown
          options={ options }
          onChange={ onChange }
          style={ inputDropdownStyle }
          value={ selectedTemplate }
        />
        <Title>Preview template and choose fields</Title>
        {selectedTemplate?.value
          ? (
          <>
            <Subtitle>{ intl.formatMessage({ id: 'manage.applyTemplate.subtitle' }, { vulnsCount, vulnsText }) }</Subtitle>
            <TemplateField template={ selectedTemplate?.value } toggleFn={ toggleFn } selectedFields={ selectedFields } removeCustomAttribute={ removeCustomAttribute } />
          </>
            )
          : <Subtitle>Select a template above to load the preview.</Subtitle>}
        <ButtonsContainer>
          <CancelButton onClick={ close }>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={ accept } disabled={ !selectedTemplate?.value }>
            Apply
          </ConfirmButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ApplyTemplateModal;

const TemplateField = ({ template, toggleFn, selectedFields, removeCustomAttribute }) => {
  const {
    name, exploitation, desc, resolution, easeofresolution, policyviolations, impact, data, external_id, refs, customfields, cve, cvss
  } = selectedFields;
  return (
    <Fields>
      { name ? <ExternalId expanded selected={ name } onChange={ toggleFn('name') } value={ template.name } title="Vulnerability Name" id="name" /> : null }
      { exploitation ? <Severity expanded selected={ exploitation } onChange={ toggleFn('exploitation') } value={ template.exploitation } title="Severity" id="severity" /> : null }
      { desc ? <Description expanded selected={ desc } onChange={ toggleFn('desc') } value={ template.desc } /> : null }
      { resolution ? <Resolution expanded selected={ resolution } onChange={ toggleFn('resolution') } value={ template.resolution } /> : null }
      { easeofresolution ? <ExternalId selected={ easeofresolution } onChange={ toggleFn('easeofresolution') } value={ template.easeofresolution } title="Ease of Resolution" id="ease_of_resolution" /> : null }
      { policyviolations ? <Policies selected={ policyviolations } onChange={ toggleFn('policyviolations') } value={ template.policyviolations } title="Policy Violations" id="policies" /> : null }
      { !!impact.accountability || !!impact.availability || !!impact.confidentiality || !!impact.integrity
        ? <ImpactsTab selected={ impact } onChange={ toggleFn('impact') } value={ template.impact } />
        : null }
      { data ? <Data selected={ data } onChange={ toggleFn('data') } value={ template.data } /> : null }
      { external_id ? <ExternalId selected={ external_id } onChange={ toggleFn('external_id') } value={ template.external_id } title="External ID" id="externalID" /> : null }
      { refs ? <Policies selected={ refs } onChange={ toggleFn('refs') } value={ template.refs } title="References" id="references" /> : null }
      { customfields ? <CustomAttributes expanded fields={ template.customfields } onChange={ (field) => removeCustomAttribute(field) } /> : null }
      { cve ? <CVE expanded value={ template.cve } selected={ cve } onChange={ toggleFn('cve') } /> : null }
      { cvss ? <ExternalId expanded selected={ cvss } onChange={ toggleFn('cvss') } value={ template.cvss3.vector_string !== null ? template.cvss3.vector_string : template.cvss2.vector_string } title="CVSS" id="cvss" /> : null }
    </Fields>
  );
};
