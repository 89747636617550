import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJiraSettings, setIsTemplate, TOOL_NAME } from 'store/Settings/jiraActions';
import { getTemplates } from 'store/Settings/settingsActions';
import BlueButton from 'Common/Components/BlueButton';
import selectModal from 'store/modals/selectors';
import { MODAL_JIRA, MODAL_JIRA_TEMPLATE } from 'store/modals/modals';
import { closeModal, openModal } from 'store/modals/actions';
import ProjectsDropdown from 'Screens/Settings/components/TicketingTools/components/Jira/components/ProjectsDropdown';
import { selectConsumerKey } from 'store/Settings/jiraSelectors';
import JiraTemplateModal from 'Common/Components/JiraTemplateModal';
import Oauth from './components/Oauth';
import ConfigurationModal from './components/ConfigurationModal';
import {
  Wrapper, Content
} from './styled';
import JiraUserAssign from './components/JiraUserAssign';

const Jira = ({ setDisabled }) => {
  const dispatch = useDispatch();
  const consumerKey = useSelector(selectConsumerKey);

  // obtengo los datos del servidor
  useEffect(() => {
    dispatch(getTemplates(TOOL_NAME));
    dispatch(getJiraSettings());
    setDisabled(false);
  }, [dispatch, setDisabled]);

  useEffect(() => {
    dispatch(setIsTemplate(true));
    return () => {
      dispatch(setIsTemplate(false));
      dispatch(closeModal(MODAL_JIRA_TEMPLATE));
    };
  }, [dispatch]);

  const showConfigurationModal = useSelector((state) => selectModal(state, MODAL_JIRA));
  const showJiraTemplateModal = useSelector((state) => selectModal(state, MODAL_JIRA_TEMPLATE));

  return (
    <Wrapper>
      <Content>
        {showConfigurationModal && <ConfigurationModal />}
        {showJiraTemplateModal && <JiraTemplateModal isTemplate />}
        <BlueButton onClick={ () => dispatch(openModal(MODAL_JIRA)) } label="Configure Jira" />
        <Oauth />
        <ProjectsDropdown canShow={ !!consumerKey } />
        {!!consumerKey && <JiraUserAssign />}
      </Content>
    </Wrapper>
  );
};
export default Jira;
