import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import { MODAL_TASKS_REDIRECT_TO_MANAGE } from 'store/modals/modals';
import ConfirmNavigationModal from 'Common/Components/ConfirmNavigationModal';
import { setSelectedLinkedVuln } from 'store/Tasks/actions';
import { selectSelectedLinkedVulnId, selectSelectedLinkedVulnWs } from 'store/Tasks/selectors';
import { redirect } from 'store/Router/actions';

const ConfirmNavigation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const linkedVulnId = useSelector(selectSelectedLinkedVulnId);
  const linkedVulnWs = useSelector(selectSelectedLinkedVulnWs);
  const title = intl.formatMessage({ id: 'planner.task.confirmNavigation.title' });
  const message = intl.formatMessage({ id: 'planner.task.confirmNavigation.message' });
  const done = intl.formatMessage({ id: 'planner.task.confirmNavigation.leave' });
  const cancel = intl.formatMessage({ id: 'planner.task.confirmNavigation.stay' });

  const onDone = () => {
    dispatch(redirect(`/manage/${linkedVulnWs}/${linkedVulnId}`));
    dispatch(setSelectedLinkedVuln(null));
    dispatch(closeModal(MODAL_TASKS_REDIRECT_TO_MANAGE));
  };

  const onCancel = () => {
    dispatch(setSelectedLinkedVuln(null));
    dispatch(closeModal(MODAL_TASKS_REDIRECT_TO_MANAGE));
  };

  return (
    <ConfirmNavigationModal
      title={ title }
      message={ message }
      onCancel={ onCancel }
      onDone={ onDone }
      cancel={ cancel }
      done={ done }
    />
  );
};

export default ConfirmNavigation;
