import styled from 'styled-components';
import colors from 'Styles/colors';
import Checkbox from 'Common/Components/Checkbox/styled';

export const Wrapper = styled.div`
  user-select: none;
`;
Wrapper.displayName = 'Wrapper';

export const Subtitle = styled.div`
  padding: 17px 5px 0 0;
  font-size: 12.5px;
  line-height: 1.12;
  text-align: left;
  color: ${colors.grey11};
  margin-bottom: 30px;
`;
Subtitle.displayName = 'Subtitle';

export const SubsectionTitle = styled.div`
  font-weight: 500;
  font-size: 14.5px;
  color: ${colors.darkBlueGrey};
  margin: 0 23px 17px 0;
`;
SubsectionTitle.displayName = 'SubsectionTitle';

export const Row = styled.div`
  display: flex;
`;
Row.displayName = 'Row';

export const Toggle = styled(Checkbox)`
  margin-top: 5px;
`;
Toggle.displayName = 'Toggle';

export const ConnectionTimeout = styled.div`
  width: 117px;
  margin-right: 20px;
`;
ConnectionTimeout.displayName = 'ConnectionTimeout';

export const ClassInput = styled.div`
  width: 117px;
  margin-right: 20px;
`;
ClassInput.displayName = 'ClassInput';
