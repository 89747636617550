import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import { MODAL_ANALYTICS_CONFIRM_NAVIGATION } from 'store/modals/modals';
import { redirect } from 'store/Router/actions';
import { resetState } from 'store/Analytics/actions';
import ConfirmNavigationModal from 'Common/Components/ConfirmNavigationModal';

const ConfirmNavigation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const title = intl.formatMessage({ id: 'analytics.confirmNavigation.title' });
  const message = intl.formatMessage({ id: 'analytics.confirmNavigation.message' });
  const done = intl.formatMessage({ id: 'analytics.confirmNavigation.leave' });
  const cancel = intl.formatMessage({ id: 'analytics.confirmNavigation.stay' });

  const onDone = () => {
    dispatch(closeModal(MODAL_ANALYTICS_CONFIRM_NAVIGATION));
    dispatch(redirect('/analytics'));
    dispatch(resetState());
  };

  const onCancel = () => {
    dispatch(closeModal(MODAL_ANALYTICS_CONFIRM_NAVIGATION));
  };

  return (
    <ConfirmNavigationModal
      title={ title }
      message={ message }
      onCancel={ onCancel }
      onDone={ onDone }
      cancel={ cancel }
      done={ done }
    />
  );
};

export default ConfirmNavigation;
