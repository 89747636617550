import styled from 'styled-components';
import colors from 'Styles/colors';

const Link = styled.a`
  color: ${colors.blueCerulean};
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.44;
`;
Link.displayName = 'Link';

export default Link;
