import React from 'react';
import { Wrapper } from '../TestingFile/styled';
import { BlueText, Text } from './styled';
import { useIntl } from 'react-intl';

const getFileType = (fileName) => {
  if (fileName.endsWith('.pdf')) return 'pdf';
  if (fileName.endsWith('.docx')) return 'docx';
  return null;
};

const Success = ({ name }) => {
  const intl = useIntl();
  const fileType = getFileType(name);
  const messageId = `reports.editCreate.selectTemplateModal.addTemplate.success.${fileType}`;

  return (
    <Wrapper>
      <BlueText>{ name }</BlueText>
      <Text>{ intl.formatMessage({ id: messageId }, { br: <br /> }) }</Text>
    </Wrapper>
  );
};

export default Success;
