import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { selectHostDetailDescription, selectAssetDetailId, selectReadonly, selectHostWorkspace } from 'store/Contextualization/AssetDetail/selectors';
import { updateHost } from 'store/Host/actions';
import { updateHostGeneral } from 'store/HostsGeneral/actions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const ID = 'description';

const Description = ({ assetsGeneral }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const hostId = useSelector(selectAssetDetailId);
  const description = useSelector(selectHostDetailDescription);
  const readOnly = useSelector(selectReadonly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.UPDATE));
  const disabled = readOnly || !allowedByRole;
  const isExpanded = useExpandable('general', ID, description);

  const workspace = useSelector(selectHostWorkspace);

  const update = (value) => assetsGeneral ? dispatch(updateHostGeneral(hostId, { description: value }, workspace)) : dispatch(updateHost(hostId, { description: value }));

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.description' }) } tab="general" id={ ID }>
      <MarkdownEditor
        value={ description }
        onBlur={ (value) => update(value) }
        disabled={ disabled }
        minHeight={ 49 }
      />
    </ExpandableWrapper>
  );
};

export default Description;
