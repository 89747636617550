/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { removeFieldItem } from 'store/Tasks/actions';
import RemoveItem from '../RemoveItem';
import {
  ItemWrapper, CustomItem, Name, Email, Role
} from './styled';

const UserItem = ({
  item, canDelete
}) => {
  const dispatch = useDispatch();
  const removeItem = (id) => {
    dispatch(removeFieldItem('assignees', id));
  };

  return (
    <ItemWrapper>
      <CustomItem user canDelete={ canDelete }>
        <Name>{item.name}</Name>
        <Email>{item.email}</Email>
        <Role role={ item.roles[0] }>
          {
        item
          ? (item.roles[0] === 'admin'
              ? 'Administrator'
              : (item.roles[0] === 'pentester'
                  ? 'Pentester'
                  : (item.roles[0] === 'client' ? 'Client' : 'Asset Owner')))
          : ''
        }
        </Role>
        <RemoveItem removeItem={ removeItem } id={ item.id } />
      </CustomItem>
    </ItemWrapper>
  );
};

UserItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  canDelete: PropTypes.bool
};

UserItem.defaultProps = {
  canDelete: false
};
export default UserItem;
