import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/CustomActionBar/styled';
import { setPage } from 'Screens/Users/actions/Actions';
import {
  selectUserSelected, selectCurrentBreakpoint,
  selectUserCount,
  selectShowDeleteConfirmation
} from 'store/Users/selectors';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import CreateActions from '../CreateActions';
import Edit from './components/Edit';
import Delete from './components/Delete';
import { MoreOptionsButton } from './components/MoreOptionsButton';
import Pagination from 'Common/Components/Pagination';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';

const ActionBar = () => {
  const dispatch = useDispatch();
  const userSelected = useSelector(selectUserSelected);
  const totalUsersCount = useSelector(selectUserCount);
  const currentBreakpoint = useSelector(selectCurrentBreakpoint);
  const page = useSelector((state) => selectPage('users', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('users', state));
  const hasUsers = totalUsersCount > 0;
  const hasUsersSelected = userSelected.length > 0;
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);

  return (
    <Wrapper>
      { showDeleteConfirmation ? <DeleteConfirmationModal /> : null }
      <LeftGroup>
        { <CreateActions /> }
        { hasUsersSelected &&
          <>
            <Separator />
            <Group>
              <Edit currentBreakpoint={ currentBreakpoint } />
              <Delete />
              <MoreOptionsButton currentBreakpoint={ currentBreakpoint } />
            </Group>
            <Separator />
          </>
        }
      </LeftGroup>
      { hasUsers &&
        <RightGroup>
          <Pagination offset={ page } limit={ rowsPerPage } total={ totalUsersCount } onPrev={ onPrev } onNext={ onNext } />
        </RightGroup>
      }
    </Wrapper>
  );
};

export default ActionBar;
