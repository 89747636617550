import colors from 'Styles/colors';

const ColorRole = {
  admin: colors.warmPurple,
  pentester: '#EA3158',
  client: '#0082ED',
  system: '#8da6be',
  user: '#f59220',
  asset_owner: colors.grey2
};

const getBackgroundColor = (role, colors = ColorRole) => {
  if (!role) return '#67AE3F';

  return colors[role];
};

export default getBackgroundColor;
