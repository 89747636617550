import React from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { selectProjects, selectIsFetching, selectViewType } from 'store/Projects/selectors';
import Table from './components/Table';
import Gantt from './components/Gantt';
import Empty from './components/Empty';
import Wrapper from './styled';

const Content = () => {
  const viewType = useSelector(selectViewType);
  const isGantt = viewType.id === 'ganttView';
  const isList = viewType.id === 'listView';
  const projects = useSelector(selectProjects);
  const isFetching = useSelector(selectIsFetching);

  if (isEmpty(projects) && !isFetching) return <Empty />;
  if (isGantt) return <Wrapper children={ <Gantt /> } />;
  if (isList) return <Wrapper children={ <Table /> } />;

  return (
    <Wrapper>
      <Table />
      <Gantt />
    </Wrapper>
  );
};

export default Content;
