import React from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  MentionItem,
  MentionName
} from './styled';

const Entry = ({
  mention,
  searchValue, // eslint-disable-line no-unused-vars
  isFocused, // eslint-disable-line no-unused-vars
  ...parentProps
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Wrapper { ...parentProps }>
      <MentionItem>
        <MentionName>{mention.name}</MentionName>
      </MentionItem>
    </Wrapper>
  );
};

Entry.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  mention: PropTypes.object.isRequired,
  searchValue: PropTypes.string,
  isFocused: PropTypes.bool
};

Entry.defaultProps = {
  searchValue: '',
  isFocused: false
};

export default Entry;
