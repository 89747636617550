import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTagsForExecutiveReports } from 'Screens/Contextualization/Tags/actions/Actions';
import { useIntl } from 'react-intl';
import ReportInput from './components/Input';
import UploadLogo from './components/UploadLogo';
import SelectTemplateSection from './components/SelectTemplateSection';
import Sections from './components/Sections';
import { Wrapper, EnterpriseWrapper, TitleWrapper, ColumnWrapper } from './styled';
import ModalWarning from './components/ModalWarning';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_REPORT_WARNING_LOGO } from 'store/modals/modals';
import { selectWorkspaces } from 'store/ExecutiveReportEditCreate/selectors';
import { getWorkspaces } from 'Screens/Contextualization/Workspaces/actions/Actions';

const ReportForm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'reports.editCreate.inputTitle.title' });
  const enterprise = intl.formatMessage({ id: 'reports.editCreate.inputTitle.enterprise' });
  const workspaces = useSelector(selectWorkspaces);
  const hasWorkspaces = workspaces.length > 0;
  const canGetTags = hasWorkspaces && !workspaces.some((ws) => ws === '');

  useEffect(() => {
    dispatch(getWorkspaces(false, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (canGetTags) dispatch(getTagsForExecutiveReports(workspaces));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, canGetTags]);

  return (
    <Wrapper>
      <TitleWrapper>
        <ReportInput inputType="title" inputTitle={ title } />
      </TitleWrapper>
      <EnterpriseWrapper>
        <ReportInput inputType="enterprise" inputTitle={ enterprise } />
      </EnterpriseWrapper>
      <ColumnWrapper>
        <SelectTemplateSection />
        <UploadLogo />
      </ColumnWrapper>
      <Sections />
      <ReduxModal id={ MODAL_REPORT_WARNING_LOGO }>
        <ModalWarning />
      </ReduxModal>
    </Wrapper>
  );
};

export default ReportForm;
