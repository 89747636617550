import React, { useEffect } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToVulnDetailGeneral, selectVulnRow, unSelectAllVulns } from 'store/ManageGeneral/actions';
import { NameWrapper, Name } from './styled';
import Skeleton from 'Common/Components/Skeleton';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_DUPLICATES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectShowQuickPlanner } from 'store/Manage/selectors';
import { MainDuplicateIcon, DuplicateIcon, IconWrapper } from 'Screens/Manage/components/ManageTable/components/Table/columns/Name/styled';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import itemTypes from 'Common/Components/DragAndDrop/ItemTypes';

const NameCellVulnsGeneral = ({ vuln, showSkeleton, groupBy, isPivot }) => {
  const vulnName = get(vuln, 'name', '');
  const vulnId = get(vuln, '_id', 0);
  const confirmed = get(vuln, 'confirmed', false);

  const dispatch = useDispatch();
  const canUseDuplicates = useSelector((state) => selectAllowedByFeature(state, FEATURE_DUPLICATES, PERMISSION_TYPES.READ) && selectAllowedByRole(state, FEATURE_DUPLICATES, PERMISSION_TYPES.READ));
  const isQuickPlannerOpen = useSelector(selectShowQuickPlanner);

  const DuplicateMark = () => {
    const isMainDuplicate = get(vuln, 'duplicates_associated', []).length > 0;
    const isDuplicate = !isEmpty(get(vuln, 'duplicates_main', {}));
    if (!isDuplicate && !isMainDuplicate) return null;
    return (
      <IconWrapper>
        { isMainDuplicate ? <MainDuplicateIcon /> : <DuplicateIcon /> }
      </IconWrapper>
    );
  };

  const handleClick = (e) => {
    if (isQuickPlannerOpen) return;
    dispatch(unSelectAllVulns());
    dispatch(selectVulnRow(e, vuln));
    dispatch(redirectToVulnDetailGeneral(vulnId));
  };

  const [, drag, preview] = useDrag(() => ({
    type: itemTypes.VULN,
    item: vuln,
    collect: () => ({
      canDrag: isQuickPlannerOpen
    }),
    canDrag: isQuickPlannerOpen
  }), [vuln]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showSkeleton) return <Skeleton />;
  if (groupBy && isPivot) return null;
  return (
    <NameWrapper ref={ drag }>
      <Name confirmed={ confirmed } onClick={ handleClick }>{ vulnName }</Name>
      { canUseDuplicates && <DuplicateMark /> }
    </NameWrapper>
  );
};

export default NameCellVulnsGeneral;
