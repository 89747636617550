import React, { useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { Wrapper, InputTitle, IconHost } from './styled';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { FEATURE_SERVICES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';

const Title = ({ serviceDetail, serviceId, updateService }) => {
  const [serviceName, setServiceName] = useState(serviceDetail.name);
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_SERVICES, PERMISSION_TYPES.UPDATE));
  const disabled = readOnly || !allowedByRole;

  useEffect(() => {
    setServiceName(serviceDetail.name);
  }, [serviceDetail]);

  const updateName = useRef(debounce((name) => {
    if (name) {
      updateService(serviceId, { name });
    }
  }, 1000));

  const handleChange = (e) => {
    const name = e.target.value;
    setServiceName(name);
    updateName.current(name);
  };

  return (
    <Wrapper>
      <IconHost />
      <InputTitle value={ serviceName } onChange={ handleChange } disabled={ disabled } />
    </Wrapper>
  );
};

export default Title;
