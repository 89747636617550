import styled from 'styled-components';
import BlueButton from 'Common/Components/BlueButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

Wrapper.displayName = 'Wrapper';

export const AddHost = styled(BlueButton)`
  height: 34px;
`;
AddHost.displayName = 'AddHost';
