import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { unSelectAll, selectRow, redirectToWsDetail } from 'Screens/Contextualization/Workspaces/actions/Actions';
import IconButton from 'Common/Components/IconButton';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import Edit from './styled';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';

// eslint-disable-next-line import/prefer-default-export
export const WsEditButton = () => {
  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useDispatch();
  const workspacesSelected = useSelector(selectWorkspacesSelected);
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'workspace' });
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.UPDATE));

  const showEditionModal = (e) => {
    dispatch(unSelectAll());
    dispatch(selectRow(e, workspacesSelected[0]));
    dispatch(redirectToWsDetail(workspacesSelected[0].name));
  };

  const handleClick = (e) => {
    if (canUpdate) {
      if (workspacesSelected.length === 1) showEditionModal(e);
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Edit disabled={ !canUpdate } /> } title="Edit" onClick={ handleClick } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Edit" description={ message } /> }
    </>
  );
};
