export const PROJECTS_RESET = 'PROJECTS_RESET';
export const PROJECTS_FAIL = 'PROJECTS_FAIL';
export const PROJECTS_CLEAR_ERROR = 'PROJECTS_CLEAR_ERROR';
export const PROJECTS_FETCHING = 'PROJECTS_FETCHING';

export const PROJECTS_GET_SUCCESS = 'PROJECTS_GET_SUCCESS';
export const PROJECTS_CREATE_SUCCESS = 'PROJECTS_CREATE_SUCCESS';
export const PROJECTS_EDIT_SUCCESS = 'PROJECTS_EDIT_SUCCESS';
export const PROJECTS_DELETE_SUCCESS = 'PROJECTS_DELETE_SUCCESS';

export const PROJECTS_TOGGLE_VIEW_TYPE = 'PROJECTS_TOGGLE_VIEW_TYPE';
export const PROJECTS_SELECT_PROJECT = 'PROJECTS_SELECT_PROJECT';

export const PROJECTS_HIDE_COMPLETED_TASKS = 'PROJECTS_HIDE_COMPLETED_TASKS';
