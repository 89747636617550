import React, { useState } from 'react';
import ActionBar from './components/ActionBar';
import MarkAsDuplicateModal from './components/MarkAsDuplicateModal';

const CustomActionBar = () => {
  const [showDuplicateModal, toggleDuplicateModal] = useState(false);
  return (
    <>
      { showDuplicateModal ? <MarkAsDuplicateModal hideModal={ () => toggleDuplicateModal(false) } /> : null }
      <ActionBar entity="vulnsGeneral" showDuplicateModal={ () => toggleDuplicateModal(true) } />
    </>
  );
};

export default CustomActionBar;
