import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  height: 100%;
`;
Content.displayName = 'Content';
