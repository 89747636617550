import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'store/modals/actions';
import { selectIsFetching } from 'store/License/selectors';
import { openUpgradeLicense } from 'store/License/actions';
import { trackEvent } from 'tracking/GA';
import { CATEGORIES } from 'tracking/GA/constants';
import get from 'lodash/get';
import {
  Wrapper, Content, Description, BoldDescription, FeatureWrapper, FeatureImage, CloseIcon, TitleWrapper, TitleBold
} from './styled';
import BlueButton from '../BlueButton';

const UpgradeLicense = ({
  id, image, descriptionIntlId, trackingShow, trackingClick
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => selectIsFetching(state));

  const onClick = () => {
    if (!isFetching) {
      if (trackingClick) dispatch(trackEvent(CATEGORIES.upgrade, get(trackingClick, 'name', ''), get(trackingClick, 'label', '')));
      dispatch(openUpgradeLicense(trackingClick));
    }
  };

  const close = () => dispatch(closeModal(id));

  useEffect(() => {
    dispatch(trackEvent(CATEGORIES.upgrade, get(trackingShow, 'name', ''), get(trackingShow, 'label', '')));
  }, [dispatch, trackingShow]);

  if (!id || !image || !descriptionIntlId) return null;
  return (
    <Wrapper>
      <CloseIcon onClick={ close } />
      <FeatureWrapper>
        <FeatureImage src={ image } />
      </FeatureWrapper>
      <Content>
        <TitleWrapper>
            <FormattedMessage id="upgradeLicense.sidebar.titleForMoreFeatures" values={ { b: (chunks) => <TitleBold children={ chunks } /> } } />
        </TitleWrapper>
        <Description>
          <FormattedMessage
            id={ descriptionIntlId }
            values={ {
              b: (chunks) => <BoldDescription children={ chunks } />,
              br: <br />
            } }
          />
        </Description>
        <BlueButton isLoading={ isFetching } label={ intl.formatMessage({ id: 'upgradeLicense.upgradeNow' }) } onClick={ onClick } />
      </Content>
    </Wrapper>
  );
};

export default UpgradeLicense;
