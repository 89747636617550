import styled from 'styled-components';
import colors from 'Styles/colors';
import { Cancel } from 'Common/Components/Button/styled';
import { Trash } from 'Screens/Contextualization/Manage/components/CustomActionBar/styled';

export const Cell = styled.div`
  font-size: 11.5px;
  color: ${colors.blueGrey};
`;
Cell.displayName = 'Cell';

export const AliasCellStyled = styled(Cell)`
  color: ${colors.darkBlueGrey};
  padding: 0 0 0 10px;
`;
AliasCellStyled.displayName = 'AliasCellStyled';

export const RevokeButton = styled(Cancel)`
  height: 24px;
  min-width: 64px;
  font-size: 11.5px;
  line-height: 1.2;
`;
RevokeButton.displayName = 'RevokeButton';

export const TrashIcon = styled(Trash).attrs({
  id: 'qa-delete-token'
})`
  height: 17px;
`;
TrashIcon.displayName = 'TrashIcon';
