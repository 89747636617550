import get from 'lodash/get';

export const selectHostname = (state) => get(state, 'settings.smtp.hostname', '');

export const selectUsername = (state) => get(state, 'settings.smtp.username', '');

export const selectPassword = (state) => get(state, 'settings.smtp.password', '');

export const selectSender = (state) => get(state, 'settings.smtp.sender', '');

export const selectPort = (state) => get(state, 'settings.smtp.port', '');

export const selectEnabled = (state) => get(state, 'settings.smtp.enabled', false);

export const selectSsl = (state) => get(state, 'settings.smtp.ssl', false);

export const selectError = (state) => get(state, 'settings.smtp.error', false);

export const selectMessage = (state) => get(state, 'settings.smtp.message', '');
