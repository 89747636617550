import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetching,
  selectShowWsDetail, selectWorkspacesOrdered, selectWorkspacesSelected
} from 'store/Workspace/selectors';
import some from 'lodash/some';
import WorkspaceItem from './components/WorkspaceItem';
import WorkspaceDetail from '../WorkspaceDetail';
import { View, Wrapper } from './styled';
import EmptyState from '../EmptyState';
import { getData } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { selectIsLoggedIn } from 'store/Sesion/selectors';
// import { resetFilters, setFilter } from 'store/Filters/actions';

const GridView = ({ setShowConfirmation }) => {
  const workspaceList = useSelector((state) => selectWorkspacesOrdered(state));
  const workspacesSelected = useSelector((state) => selectWorkspacesSelected(state));
  const showDetail = useSelector(selectShowWsDetail);
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  // const showInactive = useSelector(selectShowInactive);

  useEffect(() => {
    // if (!showInactive) dispatch(setFilter('workspaces', { name: 'active', op: '==', val: 'true' }));
    // else dispatch(resetFilters());
    if (isLoggedIn) dispatch(getData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if ((workspaceList.length === 0) && !isFetching) return <EmptyState />;
  return (
    <View>
      <Wrapper>
        { workspaceList.map((ws) => <WorkspaceItem key={ ws.id } ws={ ws } selected={ some(workspacesSelected, { name: ws.name }) } setShowConfirmation={ setShowConfirmation } />) }
      </Wrapper>
      { showDetail && <WorkspaceDetail /> }
    </View>
  );
};

export default GridView;
