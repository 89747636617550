import React, { useEffect } from 'react';
import StringInput from 'Common/Components/v2/Input/components/StringInput';
import Wrapper from './styled';
import { useDispatch } from 'react-redux';
import { getUserData, setEmailField } from 'store/Settings/settingsActions';

const Email = ({ email, errors, setErrors }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData());
  }, [dispatch]);

  const validateEmail = () => {
    if (email === '') return setErrors({ ...errors, email: '' });
    // eslint-disable-next-line prefer-regex-literals
    const re = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
    const isValid = re.test(email);
    if (isValid) return setErrors({ ...errors, email: '' });
    else return setErrors({ ...errors, email: 'Invalid Email' });
  };

  return (
    <Wrapper>
      <StringInput
        title="Email"
        onChange={ (v) => dispatch(setEmailField(v)) }
        width="264px"
        height="34px"
        id="account-email"
        defaultValue={ email }
        onBlur={ validateEmail }
        error={ errors.email }
        autoComplete="email"
      />
    </Wrapper>
  );
};

export default Email;
