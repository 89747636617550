import React from 'react';
import PropTypes from 'prop-types';
import MonthlyEvolutionBySeverity from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Graphs/components/MonthlyEvolutionBySeverity';
import MonthlyEvolutionByStatus from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Graphs/components/MonthlyEvolutionByStatus';
import TopTenMostAffected from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Graphs/components/TopTenMostAffected';
import TopTenMostRepeated from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Graphs/components/TopTenMostRepeated';
import VulnsPerHosts from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Graphs/components/VulnsPerHosts';
import VulnsPerSeverity from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Graphs/components/VulnsPerSeverity';
import VulnsPerStatus from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Graphs/components/VulnsPerStatus';
import VulnsByRiskScore from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Graphs/components/VulnsByRiskScore';
import {
  MONTHLYEVOLUTIONBYSEVERITY, MONTHLYEVOLUTIONBYSTATUS, TOPTENMOSTAFFECTED, TOPTENMOSTREPEATED, VULNSPERHOSTS, VULNSPERSEVERITY, VULNSPERSTATUS, VULNSBYRISKSCORE
} from 'store/Analytics/graphs';
import SideNote from 'Screens/Analytics/components/CreateEdit/components/Preview/components/SideNote';
import { Wrapper } from './styled';
import { parseData } from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Graphs/components/utils';

const Graphs = ({ type, data, sideNoteType }) => (
  <Wrapper>
    { type === MONTHLYEVOLUTIONBYSEVERITY && <MonthlyEvolutionBySeverity data={ parseData(data) } /> }
    { type === MONTHLYEVOLUTIONBYSTATUS && <MonthlyEvolutionByStatus data={ parseData(data) } /> }
    { type === TOPTENMOSTAFFECTED && <TopTenMostAffected data={ data } /> }
    { type === TOPTENMOSTREPEATED && <TopTenMostRepeated data={ data } />}
    { type === VULNSPERHOSTS && <VulnsPerHosts data={ data } /> }
    { type === VULNSPERSEVERITY && <VulnsPerSeverity data={ data } /> }
    { type === VULNSPERSTATUS && <VulnsPerStatus data={ data } /> }
    { type === VULNSBYRISKSCORE && <VulnsByRiskScore data={ data } /> }
    <SideNote type={ sideNoteType } />
  </Wrapper>
);

Graphs.propTypes = {
  type: PropTypes.string.isRequired,
  sideNoteType: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired
};

export default Graphs;
