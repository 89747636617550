import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { setInputField } from 'store/ExecutiveReportEditCreate/actions';
import { selectInputField } from 'store/ExecutiveReportEditCreate/selectors';
import {
  Input, TextArea, InputTitle, Wrapper
} from './styled';

const ReportInput = ({
  inputType, type, disabled, inputTitle
}) => {
  const dispatch = useDispatch();
  const inputValue = useSelector((state) => selectInputField(state, inputType));
  const [value, setValue] = useState(inputValue);
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: `reports.editCreate.inputPlaceholder.${inputType}` });

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const onChangeDebounce = debounce((value) => {
    dispatch(setInputField(inputType, value));
  }, 300);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeCallBack = useCallback((value) => onChangeDebounce(value), []);

  const onChange = (e) => {
    setValue(e.target.value);
    onChangeCallBack(e.target.value);
  };

  if (type === 'textarea') {
    return (
      <TextArea
        disabled={ disabled }
        placeholder={ placeholder }
        onChange={ onChange }
        value={ value }
      />
    );
  }
  return (
    <Wrapper>
      <InputTitle>
        { inputTitle }
      </InputTitle>
      <Input
        disabled={ disabled }
        placeholder={ placeholder }
        onChange={ onChange }
        value={ value }
      />
    </Wrapper>
  );
};

ReportInput.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  inputType: PropTypes.string.isRequired,
  inputTitle: PropTypes.string
};

ReportInput.defaultProps = {
  disabled: false,
  type: 'input',
  inputTitle: ''
};

export default ReportInput;
