import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1230px;
`;
Wrapper.displayName = 'Wrapper';

export const CartWrapper = styled.div`
  display: flex;
  width: 369px;
  height: 204px;
  flex-direction: column;
  padding: 21px;
  border-radius: 3px;
  box-shadow: 0 2px 20px 0 rgb(28 34 67 / 5%);
  border: solid 1px ${colors.iceBlue};
  background-color: ${colors.white};
  margin: 16px; 
  justify-content: space-around;
  cursor: pointer;
  position: relative;
`;
CartWrapper.displayName = 'CartWrapper';

export const ActionBar = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  box-shadow: 0 2px 8px 0 rgba(28, 34, 67, 0.03);
  border: solid 1px #d9e4ef;
  border-radius: 4px;
  padding: 0 8px;
  background: #fff;
`;
ActionBar.displayName = 'ActionBar';

export const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
  color: ${colors.dark2};
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  font-size: 15px;
  line-height: 1.28;
  color: ${colors.greyBlue};
`;
Subtitle.displayName = 'Subtitle';

export const Time = styled(Subtitle)`
  margin-left: auto;
  font-size: 13px;
`;
Time.displayName = 'Time';
