import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearGroupByVulns, setGroupByVulns, setPage } from 'store/Manage/filterActions';
import { deleteSelectedVulns, downloadVulns, hideVulnModalDelete, redirectToVulnDetail, showVulnModalDelete, showVulnerabilityDetail } from 'store/Manage/actions';
import { selectPage, selectRowsPerPage, selectGroupByField, selectGroupBy } from 'store/Filters/selectors';
import {
  selectVulnsSelected,
  selectShowDetail,
  selectSelectAll,
  selectIsExportingCSV,
  selectVulnsCount,
  selectShowDeleteConfirmation,
  selectIsFetching,
  selectVulnDetailId
} from 'store/Manage/selectors';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import Pagination from 'Common/Components/Pagination';
import CreateActions from 'Screens/Contextualization/Manage/components/CreateActions';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import GroupFieldSelector from '../GroupFieldSelector';
import FilterSideBarIcon from '../FilterSidebarIcon';
import DuplicateButton from '../DuplicateButton';
import TagButton from '../TagButton';
import RefreshButton from '../RefreshButton';
import AddRemoveColumnsButton from '../AddRemoveColumnsButton';
import { Download, DownloadButton } from '../../styled';
import QuickPlannerButton from 'Screens/Contextualization/Planner/QuickPlanner/QuickPlannerButton';
import { selectIsAdmin } from 'store/Faraday/selectors';
import CopyLink from '../CopyLink';
import CopyApiLink from '../CopyApiLink';
import Severity from '../Severity';
import Status from '../Status';
import AddNewEvidence from '../AddNewEvidence';
import SaveAsTemplate from '../SaveAsTemplate';
import ApplyTemplate from '../ApplyTemplate';
import AddComment from '../AddComment';
import SendToTool from 'Common/Components/Contextualization/SendToTool';
import { selectManageFields } from 'store/Preferences/manage/contextSelectors';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/vulnsGeneral/actions';

const ActionBar = ({ entity, showDuplicateModal }) => {
  const dispatch = useDispatch();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const vulnsCount = useSelector(selectVulnsCount);
  const showVulnDetail = useSelector(selectShowDetail);
  const page = useSelector((state) => selectPage('vulns', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('vulns', state));
  const isGrouping = useSelector((state) => selectGroupByField('vulns', state));
  const isAdmin = useSelector(selectIsAdmin);
  const hasVulns = vulnsCount > 0;
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));
  const selectAll = useSelector(selectSelectAll);
  const isExportingCSV = useSelector(selectIsExportingCSV);
  const fields = useSelector(selectManageFields);
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);
  const isFetching = useSelector(selectIsFetching);
  const groupBy = useSelector((state) => selectGroupBy('vulns', state));
  const vulnDetailId = useSelector(selectVulnDetailId);

  return (
    <Wrapper data-tour="Toolbar Vulnerabilities">
      { showDeleteConfirmation
        ? <DeleteConfirmationModal
            showDeleteConfirmation={ showDeleteConfirmation }
            selectAll={ selectAll }
            isFetching={ isFetching }
            vulnsSelected={ vulnsSelected }
            deleteVulns={ () => dispatch(deleteSelectedVulns()) }
            hideModalDelete={ () => dispatch(hideVulnModalDelete()) }
            vulnsCount={ vulnsCount }
        />
        : null
      }
      <LeftGroup>
        <CreateActions />
        <Separator />
        <Group>
          <EditButton hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } openVulnDetail={ (vulnId) => dispatch(redirectToVulnDetail(vulnId)) } />
          <SendToTool hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } isVulnDetailVisable={ showVulnDetail } vulnId={ vulnDetailId } entity="vulns" />
          {!selectAll && <TagButton hasItems={ hasVulns } vulnsSelected={ vulnsSelected } tagsEntity="menu" />}
          <CopyLink hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } />
          <CopyApiLink hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } />
          <Severity hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } />
          <Status hasVulns={ hasVulns } groupBy={ isGrouping } vulnsSelected={ vulnsSelected } entity={ entity } />
          <AddComment hasVulns={ hasVulns } />
          <AddNewEvidence hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } openEvidenceTab={ (vulnId) => dispatch(showVulnerabilityDetail(vulnId, 'evidence')) } />
          <SaveAsTemplate hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } />
          <ApplyTemplate hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } />
          <DeleteButton hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } showVulnDetail={ showVulnDetail } showModalDelete={ () => dispatch(showVulnModalDelete()) } />
          <DuplicateButton hasVulns={ hasVulns } showModal={ showDuplicateModal } />
        </Group>
        { isAdmin &&
          <Group>
            <Separator />
            <QuickPlannerButton hasVulns={ hasVulns } entity={ entity } />
          </Group>
        }
      </LeftGroup>

      <RightGroup>

        { ((hasVulns) && (vulnsSelected.length === 0 && !showVulnDetail)) && <GroupFieldSelector groupBy={ groupBy } setGroupBy={ (groupBy) => dispatch(setGroupByVulns(groupBy)) } clearGroupBy={ () => dispatch(clearGroupByVulns()) } /> }

        { <Pagination offset={ (hasVulns && !isGrouping) ? page : null } limit={ rowsPerPage } total={ vulnsCount } onPrev={ onPrev } onNext={ onNext } />}

        { hasVulns && <Separator /> }

        <Group>
          { hasVulns && (
            <DownloadButton disabled={ isExportingCSV } title={ isExportingCSV ? 'Exporting CSV' : 'Download Filtered Workspace' } onClick={ () => dispatch(downloadVulns()) }>
              <Download />
            </DownloadButton>
          )}
          <RefreshButton entity={ entity } />
          <FilterSideBarIcon entity="vulns" />
          <AddRemoveColumnsButton
            fields={ fields }
            setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
            setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
            resetDefault={ () => dispatch(resetDefault()) }
          />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default ActionBar;
