/* eslint-disable import/prefer-default-export */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Skeleton from 'Common/Components/Skeleton';
import { openTaskDetail, clearTasks, getTasks } from 'store/Tasks/actions';
import { selectProjectById, selectProjects } from 'store/Projects/selectors';
import { useSelector, useDispatch } from 'react-redux';
import {
  Title, NameWrapper, Text, Name, ExpanderWrapper
} from './styled';
import TaskActions from '../TaskActions';
import { Expander } from './Expander';

export const NameCell = ({
  isFetching, isPivot, rowInfo
}) => {
  const dispatch = useDispatch();
  const isExpanded = get(rowInfo, 'isExpanded', false);
  const task = get(rowInfo, 'original', null);
  const projectId = get(task, 'projectId', null);
  const project = useSelector((state) => selectProjectById(state, projectId));
  const name = get(rowInfo, 'original.name', '');
  const type = get(rowInfo, 'original.type', null);
  const isMilestone = type === 'milestone';

  const index = get(rowInfo, 'index', -1);
  const projects = useSelector(selectProjects);

  const onExpand = () => {
    if (isExpanded) dispatch(clearTasks(projects[index].id));
    else dispatch(getTasks(projects[index].id));
  };

  const openDetail = () => task && project && dispatch(openTaskDetail(project.id, task.id, 'general'));

  if (isFetching) return <Skeleton />;

  if (!isPivot) {
    return (
      <NameWrapper>
        <Text isMilestone={ isMilestone }><Name onClick={ openDetail } isMilestone={ isMilestone }>{name}</Name></Text>
        <TaskActions isMilestone={ isMilestone } isFetching={ isFetching } rowInfo={ rowInfo } />
      </NameWrapper>
    );
  }

  return (
    <ExpanderWrapper onClick={ onExpand }>
      <Title children={ name } />
      <Expander isExpanded={ !!isExpanded } />
    </ExpanderWrapper>
  );
};

NameCell.propTypes = {
  rowInfo: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isPivot: PropTypes.bool.isRequired
};
