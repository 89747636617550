import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Title, Subtitle
} from 'Screens/Settings/common-styled';
import {
  deauthenticate, authenticate, accessToken
} from 'store/Settings/jiraActions';
import {
  selectAuthenticated, selectConsumerKey, selectError, selectMessage, selectIsFetching, selectAuthorized
} from 'store/Settings/jiraSelectors';
import { useIntl } from 'react-intl';
import Error from 'Common/Components/Error';
import Success from 'Common/Components/Success';
import useModal from 'Hooks/useModal';
import {
  GreenCheckbox,
  Wrapper
} from './styled';
import AuthorizedModal from './components/AuthorizedModal';

const Oauth = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [AuthModal, showAuthModal, toggleModal] = useModal(AuthorizedModal);

  const props = useSelector((state) => ({
    error: selectError(state),
    message: selectMessage(state),
    authenticated: selectAuthenticated(state),
    consumerKey: selectConsumerKey(state),
    isFetching: selectIsFetching(state),
    authorized: selectAuthorized(state)
  }));

  useEffect(() => {
    if (!props.authenticated && props.authorized && !showAuthModal) toggleModal();
  }, [props.authenticated, props.authorized]);

  const onAuthenticateChange = (e) => {
    if (e.target.checked) {
      dispatch(authenticate());
    } else {
      dispatch(deauthenticate());
    }
  };

  const onUnAuthorized = () => {
    toggleModal();
  };

  const onAuthorized = () => {
    toggleModal();
    dispatch(accessToken());
  };

  return (
    <Wrapper disabled={ !props.consumerKey }>
      <Title>
        { intl.formatMessage({ id: 'preferences.ticketingTools.jira.oauth.title' }) }
        <GreenCheckbox checked={ props.authenticated } onChange={ onAuthenticateChange } />
      </Title>
      {/* <OAuthKey placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.jira.oauth.consumerKey.placeholder' }) } title={ intl.formatMessage({ id: 'preferences.ticketingTools.jira.oauth.consumerKey.title' }) } value={ props.consumerKey } defaultValue={ props.consumerKey } onChange={ (v) => dispatch(setField('consumerKey', v)) } /> */}
      {props.error && props.message ? <Error>{props.message}</Error> : null}
      {!props.error && props.message ? <Success>{props.message}</Success> : null}
      {showAuthModal && <AuthModal onUnAuthorized={ () => onUnAuthorized() } onAuthorized={ () => onAuthorized() } /> }
      {props.isFetching ? <Subtitle>{ intl.formatMessage({ id: 'preferences.ticketingTools.wait' }) }</Subtitle> : null}
    </Wrapper>
  );
};
export default Oauth;
