/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setSamlField } from 'store/Settings/saml/actions';
import {
  selectUserRole, selectIsEnabled, selectAttributeName,
  selectUseAttributeName, selectAdminRole, selectPentesterRole,
  selectAssetOwnerRole, selectClientRole
} from 'store/Settings/saml/selectors';
import { StyledDropdown, Roles, StyledInput, InputsWrapper } from './styled';
import { Row } from 'Screens/Contextualization/Settings/components/Ldap/styled';
import { Wrapper, CustomRadio } from 'Screens/Contextualization/Settings/components/Ldap/components/Form/components/UserRoles/styled';
import StringInput from 'Common/Components/v2/Input/components/StringInput';

export const UserRole = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const enabled = useSelector(selectIsEnabled);
  const value = useSelector(selectUserRole);
  const attributeName = useSelector(selectAttributeName);
  const useAttributeName = useSelector(selectUseAttributeName);

  const roles = [
    { label: 'Administrator', value: 'admin' },
    { label: 'Pentester', value: 'pentester' },
    { label: 'Asset Owner', value: 'asset_owner' },
    { label: 'Client', value: 'client' }
  ];

  const selectedOption = roles.find((r) => r.value === value);
  const useLocalRolesLabel = intl.formatMessage({ id: 'preferences.saml.userRole' });
  const useSamlGroupsLabel = intl.formatMessage({ id: 'preferences.saml.attribute' });

  const adminGroup = useSelector(selectAdminRole);
  const pentesterGroup = useSelector(selectPentesterRole);
  const clientGroup = useSelector(selectClientRole);
  const assetOwnerGroup = useSelector(selectAssetOwnerRole);

  const onChange = (v) => dispatch(setSamlField('userRole', v.value));

  return (
    <Wrapper>
      <CustomRadio label={ useLocalRolesLabel } checked={ !useAttributeName } onChange={ () => dispatch(setSamlField('useAttributeRole', false)) } disabled={ !enabled } />
      <Row>
        <StyledDropdown
          value={ selectedOption }
          options={ roles }
          onChange={ onChange }
          isClearable={ false }
          isDisabled={ useAttributeName || !enabled }
          required
        />
      </Row>
      <CustomRadio label={ useSamlGroupsLabel } checked={ useAttributeName } onChange={ () => dispatch(setSamlField('useAttributeRole', true)) } disabled={ !enabled } />
      <InputsWrapper>
        <StyledInput value={ attributeName } disabled={ !useAttributeName || !enabled } isEmpty={ !attributeName } onChange={ (e) => dispatch(setSamlField('attributeIdentifierRole', e.target.value)) } />
        <Roles>
          <StringInput defaultValue={ adminGroup } title={ intl.formatMessage({ id: 'preferences.ldap.admin_group' }) } disabled={ !useAttributeName || !enabled } onChange={ (v) => dispatch(setSamlField('admin', v)) } />
          <StringInput defaultValue={ pentesterGroup } title={ intl.formatMessage({ id: 'preferences.ldap.pentester_group' }) } disabled={ !useAttributeName || !enabled } onChange={ (v) => dispatch(setSamlField('pentester', v)) } />
          <StringInput defaultValue={ assetOwnerGroup } title={ intl.formatMessage({ id: 'preferences.ldap.asset_owner_group' }) } disabled={ !useAttributeName || !enabled } onChange={ (v) => dispatch(setSamlField('assetOwner', v)) } />
          <StringInput defaultValue={ clientGroup } title={ intl.formatMessage({ id: 'preferences.ldap.client_group' }) } disabled={ !useAttributeName || !enabled } onChange={ (v) => dispatch(setSamlField('client', v)) } />
        </Roles>
      </InputsWrapper>
    </Wrapper>

  );
};
