import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGitLabSettings, TOOL_NAME } from 'store/Settings/gitLabActions';
import { getTemplates } from 'store/Settings/settingsActions';
import IssuesConfiguration from './components/IssuesConfiguration';
import Settings from './components/Settings';
import { Wrapper, Content } from './styled';
import {
  selectAccessToken, selectProject, selectUrl, selectTemplate
} from 'store/Settings/gitLabSelectors';

const GitLab = ({ setDisabled }) => {
  const dispatch = useDispatch();

  // obtengo los datos del servidor
  useEffect(() => {
    dispatch(getTemplates(TOOL_NAME));
    dispatch(getGitLabSettings());
  }, [dispatch]);

  const url = useSelector(selectUrl);
  const project = useSelector(selectProject);
  const accessToken = useSelector(selectAccessToken);
  const template = useSelector(selectTemplate);

  useEffect(() => {
    let isDis = false;
    if (url && project && template && accessToken) {
      isDis = false;
    } else {
      isDis = true;
    }
    setDisabled(isDis);
  }, [url, project, template, accessToken, setDisabled]);
  return (
    <Wrapper>
      <Content>
        <Settings />
        <IssuesConfiguration />
      </Content>
    </Wrapper>
  );
};
export default GitLab;
