/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { TagsWrapper, Tag } from './styled';

const Tags = ({ hostTags }) => {
  return (
    <TagsWrapper>
      {
        hostTags.map((tag, i) => (
          <Tag key={ `${tag}_${i}` }>
            { tag }
          </Tag>
        ))
      }
    </TagsWrapper>
  );
};

export default Tags;
