import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  background: white;
  text-align: left;
  line-height: 1.44;
  border: solid 1px ${colors.blue12};
  max-width: ${(props) => props.maxWidth};
  padding: 55px !important;
  display: flex;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const PrimaryTitle = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  margin-bottom: 28px;
  color: ${colors.dark2};
`;
PrimaryTitle.displayName = 'PrimaryTitle';

export const SecondaryTitle = styled.div`
  font-size: 17.5px;
  font-weight: 500;
  margin-bottom: 12px;
  color: ${colors.dark2};
`;
SecondaryTitle.displayName = 'SecondaryTitle';

export const TertiaryTitle = styled.a`
  cursor: pointer;
  font-size:15px;
  font-weight: 500;
  color: ${colors.blueCerulean};
`;
TertiaryTitle.displayName = 'TertiaryTitle';

export const Text = styled.div`
  font-size:13.5px;
  color: ${colors.grey19}
`;
Text.displayName = 'Text';

export const Logo = styled.img`
  width: fit-content;
  height: 35px;
  margin-bottom: 18px;
`;
Logo.displayName = 'Logo';

export const Row = styled.div`
  margin-bottom: 12px;
`;
Row.displayName = 'Row';

export const Container = styled.div`
  overflow: auto;
  width:auto;
  max-height: 22vw;
`;
Container.displayName = 'Container';

export const Button = styled.div`
  border: 0;
  color: white;
  text-align: center;
  min-width: 89px;
  max-width: fit-content;
  width: auto;
  height: 34px;
  line-height: 24px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  cursor: pointer;
  display: inline-block;
  background-color: ${colors.blueCerulean};
  align-self: flex-end;
  margin-top: 34px;
`;
Button.displayName = 'Button';

export const UserContainer = styled.div`
  position: relative;
  cursor: pointer;
`;
Container.displayName = 'Container';
