import styled from 'styled-components';
import colors from 'Styles/colors';

export const NextBtn = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  padding: 9px 20px;
  border: none;
  border-radius: 2px;
  background-color: ${colors.blueCerulean};
`;
NextBtn.displayName = 'NextBtn';

export const PrevBtn = styled.div`
  color: #1c2243;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 20px;

  border: #63758d solid 1px;
  border-radius: 2px;
  background-color: #ffffff;
`;
PrevBtn.displayName = 'PrevBtn';
