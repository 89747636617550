import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { addCustomAttribute, editCustomAttribute, setCustomAttributeToEdit } from 'store/CustomAttributes/actions';
import { selectCAToEdit, selectCustomAttributes } from 'store/CustomAttributes/selectors';
import get from 'lodash/get';
import { parseOptions, formatOptions, newField } from '../../functions';
import { TYPE_TO_NAME } from '../../constants';
import DisplayAddCustomAttribute from './DisplayAddCustomAttribute';

const AddCustomAttribute = () => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [options, setOptions] = useState([]);
  const currentCustomAttributes = useSelector(selectCustomAttributes);
  const dispatch = useDispatch();
  const caToEdit = useSelector(selectCAToEdit);

  const clearFields = () => {
    setOptions([]);
    setName('');
    setType('');
  };

  const onSubmit = () => {
    const fieldName = name.toLowerCase().trim().replaceAll(' ', '_');
    if (caToEdit) {
      const newField = {
        ...caToEdit,
        field_name: fieldName,
        field_display_name: name,
        field_metadata: formatOptions(options)
      };
      dispatch(editCustomAttribute(newField));
    } else {
      dispatch(addCustomAttribute(newField(currentCustomAttributes, name, fieldName, type, options)));
    }
    clearFields();
  };

  const onCancel = () => {
    dispatch(setCustomAttributeToEdit(null));
    clearFields();
  };

  const handleChangeType = (field, type) => {
    setType(type.value);
    setOptions([]);
  };

  const onAddItem = (val) => {
    if (val && options.every((o) => o !== val)) setOptions([...options, val]);
  };

  const onRemoveItem = (index) => {
    const selectedOption = get(options, `${[index]}`, '');
    const newOptions = options.filter((o) => o !== selectedOption);
    setOptions(newOptions);
  };

  useEffect(() => () => {
    dispatch(setCustomAttributeToEdit(null));
  }, [dispatch]);

  useEffect(() => {
    if (caToEdit) {
      const options = get(caToEdit, 'field_metadata', []);
      const displayName = get(caToEdit, 'field_display_name', '');
      const type = get(TYPE_TO_NAME, `${caToEdit.field_type}`, '');
      const parsedOptions = parseOptions(options);
      setName(displayName);
      setType(type);
      setOptions(parsedOptions);
    } else clearFields();
  }, [caToEdit]);

  return (
    <DisplayAddCustomAttribute
      onSubmit={ onSubmit }
      onCancel={ onCancel }
      handleChangeName={ setName }
      handleChangeType={ handleChangeType }
      name={ name }
      type={ type }
      isEditing={ !!caToEdit }
      options={ options }
      onAddItem={ onAddItem }
      onRemoveItem={ onRemoveItem }
    />
  );
};

export default AddCustomAttribute;
