import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Refresh } from 'Images/reload.svg';
import { ReactComponent as Help } from 'Images/noun-help.svg';

const sevColor = {
  critical: colors.warmPurple,
  high: colors.redPink,
  medium: colors.tangerine,
  low: colors.apple,
  info: colors.grey2,
  disable: '#d6d6d6'
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Risk = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
`;
Risk.displayName = 'Risk';

export const RiskScoreInfo = styled.td`
  display: flex;
  padding-bottom: 5px;
`;
RiskScoreInfo.displayName = 'RiskScoreInfo';

export const RiskScore = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${({ color }) => color ? sevColor[color] : '#1c4566'};
  width: 130px;
`;
RiskScore.displayName = 'RiskScore';

export const Text = styled.td`
  font-weight: ${({ bold }) => bold ? '600' : 'normal'};
  font-size: ${({ big }) => big ? '16px' : '13.5px'};
  padding-bottom: ${({ pb }) => pb ? '5px' : '0px'};
  color: ${({ color }) => color ? sevColor[color] : '#1c4566'};
`;
Text.displayName = 'Text';

export const Title = styled.td`
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 10px;
  color: #1c4566;
`;

export const Line = styled.tr`
  font-size: 13px;
  & >:first-child {
    width: 180px;
  }
`;
Line.displayName = 'Line';

export const RefTag = styled.div`
  color: #fff;
  background-color: ${({ green }) => green ? colors.green1 : colors.red1};
  width: 88px;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  padding: 2px 0;
  cursor:pointer;
  margin-right: 10px;
`;
Line.displayName = 'Line';

export const Link = styled.a`
  font-weight: normal;
  font-size: 13.5px;
  color: #1c4566;
`;

export const IconButton = styled.div`
  cursor: pointer;
`;
IconButton.displayName = 'IconButton';

export const RefreshIcon = styled(Refresh)`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  g {
    fill: ${colors.darkBlueGrey};
  }
`;
RefreshIcon.displayName = 'RefreshIcon';

export const HelpIcon = styled(Help)`
  width: 18px;
  height: 18px;
`;
HelpIcon.displayName = 'HelpIcon';

export const ButtonsWrapper = styled.div`
  margin-left: 54px;
  display: flex;
  align-items: center;
  & >*:not(:first-child) {
    margin-left: 8px;
  }
  & >:last-child {
    margin-bottom: 2px;
  }

`;
ButtonsWrapper.displayName = 'ButtonsWrapper';
