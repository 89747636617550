import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendVulnToServiceNowContext } from 'store/Settings/serviceNowActions';
import first from 'lodash/first';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { selectAuthenticated } from 'store/Settings/serviceNowSelectors';
import {
  Wrapper, Title, Subtitle, Form, WrapperField, Label, Input, Footer, Close, Save, Blue, Password, PleaseFill
} from './styled';

const ServiceNow = ({ onClose, vulnsSelected, vulnId, isVulnDetailOpen, entity }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const authenticated = useSelector(selectAuthenticated);

  const onSave = () => {
    if (!authenticated && username && password) {
      dispatch(sendVulnToServiceNowContext(vulnsSelected, vulnId, isVulnDetailOpen, entity, username, password));
      onClose();
    } else if (authenticated) {
      dispatch(sendVulnToServiceNowContext(vulnsSelected, vulnId, isVulnDetailOpen, entity));
      onClose();
    }
  };

  const getText = () => ((vulnsSelected.length === 1) ? first(vulnsSelected).name : `${vulnsSelected.length} vulnerabilities`);

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'sendToTool.serviceNow.title' }) }</Title>
      <Subtitle>
        { intl.formatMessage({ id: 'sendToTool.serviceNow.message.youAreSending' }) }
        <Blue>{ getText() }</Blue>
        { intl.formatMessage({ id: 'sendToTool.serviceNow.message.to' }) }
        { !authenticated && <PleaseFill>{ intl.formatMessage({ id: 'sendToTool.serviceNow.message.pleaseFill' }) }</PleaseFill> }
        { !authenticated && (
        <Form>
          <WrapperField>
            <Label>{ intl.formatMessage({ id: 'sendToTool.serviceNow.form.username.title' }) }</Label>
            <Input placeholder={ intl.formatMessage({ id: 'sendToTool.serviceNow.form.username.placeholder' }) } value={ username } onChange={ (e) => setUsername(e.target.value) } valid={ username } required />
          </WrapperField>
          <WrapperField>
            <Label>{ intl.formatMessage({ id: 'sendToTool.serviceNow.form.password.title' }) }</Label>
            <Password autoComplete="new-password" placeholder={ intl.formatMessage({ id: 'sendToTool.serviceNow.form.password.placeholder' }) } value={ password } onChange={ (e) => setPassword(e.target.value) } valid={ password } required />
          </WrapperField>
        </Form>
        ) }
      </Subtitle>
      <Footer>
        <Close onClick={ onClose }>{ intl.formatMessage({ id: 'sendToTool.serviceNow.form.close' }) }</Close>
        <Save onClick={ onSave }>{ intl.formatMessage({ id: 'sendToTool.serviceNow.form.save' }) }</Save>
      </Footer>
    </Wrapper>
  );
};

ServiceNow.propTypes = {
  onClose: PropTypes.func.isRequired
};
export default ServiceNow;
