import React from 'react';
import Button from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { getScopes, showAddTokenModal } from 'store/Settings/accessTokens/actions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_USER_TOKENS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const AddTokenButton = () => {
  const dispatch = useDispatch();
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_USER_TOKENS, PERMISSION_TYPES.CREATE) &&
    selectAllowedByFeature(state, FEATURE_USER_TOKENS, PERMISSION_TYPES.CREATE));

  const handleClick = () => {
    if (canCreate) {
      dispatch(showAddTokenModal(true, 1));
      dispatch(getScopes());
    }
  };
  return (
    <Button text="Add Token" onClick={ handleClick } disabled={ !canCreate } />
  );
};

export default AddTokenButton;
