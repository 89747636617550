import React from 'react';
import {
  ContainerBox, Box
} from './styled';

const Container = ({
  children, width, height, isSsoEnabled
}) => (
  <ContainerBox>
    <Box width={ width } height={ height } isSsoEnabled={ isSsoEnabled }>
      {children}
    </Box>
  </ContainerBox>
);

export default Container;
