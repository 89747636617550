import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import DateFormatter from 'Common/Components/DateFormatter';
import {
  Wrapper, TaskDate, Progress, Circle, ProgressText,
  Separator, AssignedToWrapper, Assignees, Dash
} from './styled';

const CustomTooltip = ({ task }) => {
  const intl = useIntl();
  const isProject = task.type === 'project';
  const isTask = task.type === 'task';
  const isMilestone = !isProject && !isTask;

  if (isMilestone) {
    return (
      <Wrapper isMilestone>
        <TaskDate><DateFormatter date={ task.end } midDate /></TaskDate>
      </Wrapper>
    );
  }
  return (
    !isProject && isTask && !task.isDisabled
      ? (
      <Wrapper>
        <TaskDate>
          <DateFormatter date={ task.start } midDate />
          <Dash>-</Dash>
          <DateFormatter date={ task.end } midDate />
        </TaskDate>
        <Progress>
          <Circle progress={ task.progressName } />
          <ProgressText>{task.progressName}</ProgressText>
        </Progress>
        <Separator />
        <AssignedToWrapper>
          { intl.formatMessage({ id: 'planner.gantt.customTooltip' }) }
          <Assignees>{task.assignees.length ? task.assignees.map((d) => d.name).join(', ') : '-'}</Assignees>
        </AssignedToWrapper>
      </Wrapper>
        )
      : null
  );
};

CustomTooltip.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired
};

export default CustomTooltip;
