import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Wrapper, Title, Input, Asterisk
} from './styled';

const DateInput = ({
  className, min, max, onChange, title, value, required
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (e) => setInputValue(e.target.value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onBlur = () => {
    const epochMinDate = new Date(min).getTime();
    const epochMaxDate = new Date(max).getTime();
    const utcDate = new Date(`${inputValue}T00:00:00`);
    const epochUtcDate = utcDate.getTime();
    if ((min && (epochUtcDate < epochMinDate)) || (max && (epochUtcDate > epochMaxDate)) || !utcDate) {
      setInputValue('');
      onChange('');
    } else {
      onChange(epochUtcDate);
    }
  };

  return (
    <Wrapper className={ className }>
      <Title>
        { title }
        { required && <Asterisk>*</Asterisk> }
      </Title>
      <Input type="date" min={ min } max={ max } onBlur={ onBlur } onChange={ handleChange } value={ inputValue } />
    </Wrapper>
  );
};

DateInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  required: PropTypes.bool
};

DateInput.defaultProps = {
  className: '',
  min: null,
  max: null,
  value: '',
  required: true
};

export default DateInput;
