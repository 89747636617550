import React from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { removeReport } from 'store/ExecutiveReport/actions';
import Skeleton from 'Common/Components/Skeleton';
import { ColumnName } from '../../styled';
import { Wrapper, CancelIcon } from './styled';

const NameCell = ({ rowInfo, isFetching }) => {
  const name = get(rowInfo, 'original.name', '');
  const reportId = get(rowInfo, 'original.id', 0);
  const isProcessing = (rowInfo) => get(rowInfo, 'original.status', '') === 'processing';
  const hasError = (rowInfo) => get(rowInfo, 'original.status', '') === 'error';
  const reportName = hasError(rowInfo) ? `"${name}" could not be generated.` : name;
  const dispatch = useDispatch();

  if (isFetching) return <Skeleton />;
  return (
    <Wrapper>
      <ColumnName isProcessing={ isProcessing(rowInfo) } hasError={ hasError(rowInfo) } children={ reportName } />
      { isProcessing(rowInfo) && <CancelIcon className="actionButton" title="Cancel report generation" onClick={ () => dispatch(removeReport(reportId)) } />}
    </Wrapper>
  );
};

export default NameCell;
