/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setSamlField } from 'store/Settings/saml/actions';
import { selectDisplayName, selectIsEnabled } from 'store/Settings/saml/selectors';
import StringInput from 'Common/Components/v2/Input/components/StringInput';

export const DisplayName = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const enabled = useSelector(selectIsEnabled);
  const value = useSelector(selectDisplayName);
  const displayName = intl.formatMessage({ id: 'preferences.saml.displayName' });
  const displayNamePh = intl.formatMessage({ id: 'preferences.saml.displayName.placeholder' });
  const onChange = (v) => dispatch(setSamlField('displayName', v));

  return <StringInput defaultValue={ value } disabled={ !enabled } required title={ displayName } placeholder={ displayNamePh } onChange={ onChange } />;
};
