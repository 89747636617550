import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { WithPermission } from 'Common/Components/WithPermission';
import { PERMISSION_TYPES, FEATURE_USERS } from 'Hooks/usePermission/permissions';
import EditButton from 'Common/Components/EditButton';
import { showEditModal } from 'Screens/Users/actions/Actions';
import { selectUserSelected } from 'store/Users/selectors';

const Edit = ({ currentBreakpoint }) => {
  const userSelected = useSelector(selectUserSelected);
  const dispatch = useDispatch();
  return (
    <WithPermission permission={ FEATURE_USERS } type={ PERMISSION_TYPES.UPDATE }>
      <EditButton editFunction={ () => dispatch(showEditModal()) } breakpoint={ currentBreakpoint } selectedEntities={ userSelected } display={ userSelected.length === 1 } />
    </WithPermission>
  );
};

Edit.propTypes = {
  currentBreakpoint: PropTypes.bool.isRequired
};

export default Edit;
