import colors from 'Styles/colors';
import styled from 'styled-components';

const color = {
  below: colors.green7,
  above: colors.red5
};

const margin = {
  left: '0 5px 0 0',
  right: '0 0 0 5px'
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.label`
  user-select: none;
  color: #122d46;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 15px;
`;
Title.displayName = 'Title';

export const AverageWrapper = styled.div`
  text-align: center;
`;
AverageWrapper.displayName = 'AverageWrapper';

export const Avarage = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${({ average }) => average};
`;
Avarage.displayName = 'Avarage';

export const Text = styled.p`
  font-size: 10px;
  color: ${colors.blueGrey};
`;
Text.displayName = 'Text';

export const Graph = styled.div`
  margin: 16px 0 20px 0;
  display: flex;
  width: 100%;
  border-radius: 3px;
  align-items: center;
`;
Graph.displayName = 'Graph';

export const AverageLine = styled.div`
  cursor: pointer;
  width: ${({ width }) => width}%;
  height: 6px;
  background-color: ${({ average }) => color[average]};
  ${({ width, average }) => {
    if ((width !== 100) && (average === 'above')) { return 'border-radius: 0 3px 3px 0'; } else if ((width !== 100) && (average === 'below')) { return 'border-radius: 3px 0 0 3px'; } else { return 'border-radius: 3px'; }
  }}

`;
AverageLine.displayName = 'AverageLine';

export const Vulns = styled.p`
  color: #122d46;
  font-size: 10px;
  font-weight: 600;
  margin: ${({ side }) => margin[side]};
`;
Vulns.displayName = 'Vulns';

export const References = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
    & >:first-child {
      margin-right: 11px;
    }

`;
References.displayName = 'References';

export const Reference = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: ${colors.blueGrey};
  cursor: pointer;
  
  &::before {
    width: 11px;
    height: 11px;
    margin-right: 6px;
    content: "";
    background-color:${({ average }) => color[average]};
  }
`;
Reference.displayName = 'Reference';
