import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectIsFetchingTasks } from 'store/Tasks/selectors';
import Skeleton from 'Common/Components/Skeleton';
import Wrapper from './styled';

const Empty = ({ filtered }) => {
  const isFetching = useSelector(selectIsFetchingTasks);
  return (
    <Wrapper>
      { isFetching ? <Skeleton /> : <FormattedMessage id={ filtered ? 'planner.project.noFilteredTasks' : 'planner.project.noTasks' } /> }
    </Wrapper>
  );
};

Empty.propTypes = {
  filtered: PropTypes.bool
};

Empty.defaultProps = {
  filtered: false
};

export default Empty;
