import React from 'react';
import { useDispatch } from 'react-redux';
import { sendVulnToGitLabContext } from 'store/Settings/gitLabActions';
import first from 'lodash/first';
import { useIntl } from 'react-intl';
import {
  Wrapper, Title, Subtitle, Footer, Close, Save, Blue
} from './styled';

const GitLab = ({ onClose, vulnsSelected, vulnId, isVulnDetailOpen, entity }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const onSave = () => {
    dispatch(sendVulnToGitLabContext(vulnsSelected, vulnId, isVulnDetailOpen, entity));
    onClose();
  };

  const getText = () => ((vulnsSelected.length === 1) ? first(vulnsSelected).name : `${vulnsSelected.length} vulnerabilities`);

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'sendToTool.gitlab.title' }) }</Title>
      <Subtitle>
        { intl.formatMessage({ id: 'sendToTool.gitlab.message.youAreSending' }) }
        <Blue>{ getText() }</Blue>
        { intl.formatMessage({ id: 'sendToTool.gitlab.message.toGitLab' }) }
      </Subtitle>
      <Footer>
        <Close onClick={ onClose }>{ intl.formatMessage({ id: 'sendToTool.gitlab.form.close' }) }</Close>
        <Save onClick={ onSave }>{ intl.formatMessage({ id: 'sendToTool.gitlab.form.save' }) }</Save>
      </Footer>
    </Wrapper>
  );
};

export default GitLab;
