import LogOutForced from 'Common/Components/LogOutForced/index';
import Wizard from 'Screens/Wizard/components/Main';
import ForgotPassword from 'Screens/ForgotPassword';
import ResetPassword from 'Screens/ResetPassword';
import Schedule from 'Screens/Automation/Schedule';
import Pipelines from 'Screens/Automation/Pipelines';
import Jobs from 'Screens/Automation/Jobs';
import Analytics from 'Screens/Analytics';
import NewGraph from 'Screens/Analytics/components/CreateEdit/components/NewGraph';
import EditGraph from 'Screens/Analytics/components/CreateEdit/components/EditGraph';
import NotFound from '../Screens/Errores/NotFoundContainer'; // 404
import Login from '../Screens/Login/components/Login';
import Users from '../Screens/Users/index';
import Projects from '../Screens/Planner/Projects/index';
import Swagger from 'Screens/Swagger';
import NotificationCenter from 'Screens/NotificationCenter';
import NotificationSettings from 'Screens/NotificationSettings';
import Agents from 'Screens/Automation/Agents';
import ExecutiveReportContext from 'Screens/Contextualization/ExecutiveReport';
import ExecutiveReportEditCreateContext from 'Screens/Contextualization/ExecutiveReportEditCreate';
// import WorkspacesWrapper from 'Screens/Wrappers/WorkspacesWrapper';
// import KnowledgeBaseWrapper from 'Screens/Wrappers/KnowledgeBaseWrapper';
// import ManageWrapper from 'Screens/Wrappers/ManageWrapper';
// import HostWrapper from 'Screens/Wrappers/HostWrapper';
// import DashboardWrapper from 'Screens/Wrappers/DashboardWrapper';
// import ServicesWrapper from 'Screens/Wrappers/ServicesWrapper';
// import VulnsAssetWrapper from 'Screens/Wrappers/VulnsAssetWrapper';
// import ServicesAssetWrapper from 'Screens/Wrappers/ServicesAssetWrapper';
// import PickVersion from 'Screens/PickVersion';
import HostsGeneral from 'Screens/Contextualization/HostsGeneral';
import VulnsGeneral from 'Screens/Contextualization/VulnsGeneral';
import ServicesGeneral from 'Screens/Contextualization/ServicesGeneral';
import WorkspaceContext from 'Screens/Contextualization/Workspaces';
import KnowledgeBaseContext from 'Screens/Contextualization/KnowledgeBase/components';
import ManageContext from 'Screens/Contextualization/Manage';
import HostContext from 'Screens/Contextualization/Host';
import DashboardContext from 'Screens/Contextualization/Dashboard/components/Dashboard/index';
import Services from 'Screens/Contextualization/Services';
import HostVulnerabilities from 'Screens/Contextualization/Host/components/HostVulnerabilities';
import HostServices from 'Screens/Contextualization/Host/components/HostServices';

const ApplicationRoutes = {
  Routes: [
    {
      path: '/automation/agents', exact: true, component: Agents, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/automation/schedule', exact: true, component: Schedule, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/login', exact: true, component: Login, isPrivate: false
    },
    {
      path: '/forgotpass', exact: true, component: ForgotPassword, isPrivate: false
    },
    {
      path: '/resetpass/:hash', exact: true, component: ResetPassword, isPrivate: false
    },
    {
      path: '/', exact: true, component: WorkspaceContext, isPrivate: true
    },
    {
      path: '/users', exact: true, component: Users, isPrivate: true, requireWorkspace: false, edit: false
    },
    {
      path: '/knowledge_base/:id?', exact: false, component: KnowledgeBaseContext, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/manage/:ws/:id?', exact: false, component: ManageContext, isPrivate: true, requireWorkspace: true
    },
    {
      path: '/host/:ws/:id?', exact: true, component: HostContext, isPrivate: true, requireWorkspace: true
    },
    {
      path: '/workspaces', exact: true, component: WorkspaceContext, isPrivate: true
    },
    {
      path: '/report', exact: true, component: ExecutiveReportContext, isPrivate: true, requireWorkspace: false, edit: false
    },
    {
      path: '/report/new', exact: true, component: ExecutiveReportEditCreateContext, isPrivate: true, requireWorkspace: false, edit: false
    },
    {
      path: '/report/edit/:id', exact: true, component: ExecutiveReportEditCreateContext, isPrivate: true, requireWorkspace: false, edit: true
    },
    {
      path: '/feed/:ws', exact: true, component: DashboardContext, isPrivate: true, requireWorkspace: true, edit: false
    },
    {
      path: '/wizard', exact: true, component: Wizard, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/wizard/force-first-scan', exact: true, component: Wizard, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/api-definitions', exact: true, component: Swagger, isPrivate: true, requireWorkspace: false, edit: false
    },
    {
      path: '/logout', exact: true, component: LogOutForced, isPrivate: true, requireWorkspace: false, edit: false
    },
    {
      path: '/404', exact: true, component: NotFound, isPrivate: false, requireWorkspace: false, edit: false
    },
    {
      path: '/automation/pipelines/new', exact: true, component: Pipelines, isPrivate: true, requireWorkspace: false, isNew: true
    },
    {
      path: '/automation/pipelines/:id?', exact: true, component: Pipelines, isPrivate: true, requireWorkspace: false, edit: false, isNew: false
    },
    {
      path: '/automation/jobs/new', exact: true, component: Jobs, isPrivate: true, requireWorkspace: false, isNew: true
    },
    {
      path: '/automation/jobs/new', exact: true, component: Jobs, isPrivate: true, requireWorkspace: false, isNew: true
    },
    {
      path: '/automation/jobs/:id?', exact: true, component: Jobs, isPrivate: true, requireWorkspace: false, isNew: false
    },
    {
      path: '/analytics', exact: true, component: Analytics, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/analytics/new', exact: true, component: NewGraph, isPrivate: true, requireWorkspace: false, isNew: true
    },
    {
      path: '/analytics/:id', exact: true, component: EditGraph, isPrivate: true, requireWorkspace: false, edit: false, isNew: false
    },
    {
      path: '/planner', exact: true, component: Projects, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/notifications-log', exact: true, component: NotificationCenter, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/notifications-settings', exact: true, component: NotificationSettings, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/service/:ws/:id?', exact: false, component: Services, isPrivate: true, requireWorkspace: true
    },
    {
      path: '/workspaces/:wsname?', exact: false, component: WorkspaceContext, isPrivate: true
    },
    {
      path: '/host/:ws/:id?/vulns/:vulnId?', exact: true, component: HostVulnerabilities, isPrivate: true, requireWorkspace: true
    },
    {
      path: '/host/:ws/:id?/services/:serviceId?', exact: true, component: HostServices, isPrivate: true, requireWorkspace: true
    },
    // {
    //   path: '/pick-version', exact: true, component: PickVersion, isPrivate: true, requireWorkspace: false
    // },
    {
      path: '/hosts/:id?', exact: false, component: HostsGeneral, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/vulnerabilities/:id?', exact: false, component: VulnsGeneral, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/services/:id?', exact: false, component: ServicesGeneral, isPrivate: true, requireWorkspace: false
    },
    { component: NotFound }
  ]
};

export default ApplicationRoutes;
