import React from 'react';
import ContextMenu from 'Common/Components/ContextMenu';
import { Separator } from 'Common/Components/ContextMenu/styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectContextMenuXPos, selectContextMenuYPos, selectVulnsSelected, selectShowContextMenu } from 'store/ManageGeneral/selectors';
import { setShowContextMenu } from 'store/ManageGeneral/actions';
import {
  AddNewEvidence, Delete, Edit, Filter, Severity, Status, CopyLink, CopyApiLink, SaveAsTemplate
} from './components';
import ApplyTemplate from './components/ApplyTemplate';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { Tags } from './components/Tags';
import { AddComment } from './components/AddComment';

const VulnsContextMenu = () => {
  const dispatch = useDispatch();
  const displayContextMenu = useSelector(selectShowContextMenu);
  const xPos = useSelector(selectContextMenuXPos);
  const yPos = useSelector(selectContextMenuYPos);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const isBulk = vulnsSelected.length > 1;
  const areVulnsSelected = vulnsSelected.length > 0;
  const readOnly = useSelector(selectIsReadOnly);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));

  if (readOnly || !canUpdate || !displayContextMenu || !areVulnsSelected) return null;
  return (
    <ContextMenu
      show={ displayContextMenu }
      xPos={ xPos }
      yPos={ yPos }
      displayFunction={ (show, x, y) => dispatch(setShowContextMenu(show, x, y)) }
    >
      <Filter />
      <Separator show={ !isBulk && areVulnsSelected } />
      <CopyLink />
      <CopyApiLink />
      <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
        <Edit />
      </WithPermission>
      <Tags />
      <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
        <Severity />
      </WithPermission>
      <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
        <Status />
      </WithPermission>
      <AddComment />
      <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
        <AddNewEvidence />
        <Separator show />
        <SaveAsTemplate />
        <ApplyTemplate />
      </WithPermission>
      <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.DELETE }>
        <Separator show />
        <Delete />
      </WithPermission>
    </ContextMenu>
  );
};

export default VulnsContextMenu;
