import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import head from 'lodash/head';
import { updateWS } from 'Screens/Contextualization/Workspaces/actions/Actions';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { selectUsers, selectWsDetail } from 'store/Workspace/selectors';
import InputFilter from 'Common/Components/Contextualization/InputFilter';
import {
  Wrapper, SubTitle, UserSelectorWraper, UserRow,
  Name, Role
} from './styled';
import { UserSelect, IconRow } from 'Screens/Contextualization/Workspaces/components/ModalCreation/styled';
import { FiTrash2 as Trash } from 'react-icons/fi';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const ID = 'users';

const Users = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentWs = useSelector(selectWsDetail);
  const wsName = get(currentWs, 'name', '');
  const currentUsers = get(currentWs, ID, []);
  const users = useSelector(selectUsers);
  const isExpanded = useExpandable('general', ID, users);
  const activeUsers = users.filter(({ active }) => active);
  const addedUsers = activeUsers.filter(({ username }) => currentUsers.includes(username));
  const notAddedUsers = activeUsers.filter(({ username }) => !currentUsers.includes(username));
  const addUser = (username) => dispatch(updateWS(wsName, { users: [...currentUsers, username] }));

  const removeUser = (username) => dispatch(updateWS(wsName, { users: currentUsers.filter((usr) => usr !== username) }));

  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.UPDATE));

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'workspace.wsDetail.users' }) } tab="general" id={ ID }>
      <Wrapper>
        <SubTitle>{ intl.formatMessage({ id: 'workspace.wsDetail.users.description' }) }</SubTitle>
        <UserSelectorWraper id="userSelectorSection">
          <InputFilter
            getObjects={ () => { } }
            data={ notAddedUsers }
            placeholder={ intl.formatMessage({ id: 'username' }) }
            onSelect={ ({ username }) => addUser(username) }
            onChange={ () => { } }
            cleanOnSelect
            required={ false }
            id="modal-ws-assign-users"
            fullWidth
            disabled={ !canUpdate }
          />
        </UserSelectorWraper>
        <UserSelect id="userListSection">
          {addedUsers.map(({ roles, username }) => (
            <UserRow key={ `user-${username}` }>
              <Name id="username" children={ username } />
              <Role role={ head(roles) } id="userRole" children={ intl.formatMessage({ id: head(roles) }) } />
              { head(roles) !== 'admin' && <IconRow onClick={ () => { if (canUpdate) removeUser(username); } } id="userDeleteIcon" children={ <Trash color="#1c4566" /> } /> }
            </UserRow>
          ))}
        </UserSelect>
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Users;
