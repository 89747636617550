import styled from 'styled-components';
import colors from 'Styles/colors';
import {
  NameRow, RoleRow
} from 'Screens/Contextualization/Workspaces/components/ModalCreation/styled';

export const Wrapper = styled.div`
  padding: 0px 20px 20px 20px;
`;
Wrapper.displayName = 'Wrapper';

export const SubTitle = styled.div`
  font-size: 13px;
  color: ${colors.blue11};
  margin-bottom: 10px;
`;
SubTitle.displayName = 'SubTitle';

export const UserSelectorWraper = styled.div`
  input {
    width: 100%;
  }

  ${Wrapper} {
    width: 100%;
    max-height: 125px;
    overflow-x: hidden;
  }
`;
UserSelectorWraper.displayName = 'UserSelectorWraper';

export const UserRow = styled.div`
  height: 33px;
  width: 100%;

  &:hover {
    background-color: ${colors.iceBlue};

    svg {
      color: ${colors.blue8};
    }
  }
`;
UserRow.displayName = 'UserRow';

export const Name = styled(NameRow)`
  width: 45%;
`;
Name.displayName = 'Name';

export const Role = styled(RoleRow)`
  width: 40%;
`;
Role.displayName = 'Role';
