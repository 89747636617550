import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { selectVulnsSelected } from 'store/ManageGeneral/selectors';
import { getUsersList } from 'store/Manage/actions';
import { setBulkUpdateValue } from 'store/ManageGeneral/actions';
import { MODAL_MANAGE_BULK_UPDATE, MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { closeModal, openModal } from 'store/modals/actions';
import CommentTextArea from 'Common/Components/CommentTextArea';
import { CommentTextAreaWrapper, Title, Subtitle } from './styled';

const BulkComment = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const subtitle = intl.formatMessage({ id: 'vulns.edit.bulk.comment.subtitle' });
  const title = intl.formatMessage({ id: 'vulns.edit.bulk.comment.title' });
  const placeholder = intl.formatMessage({ id: 'manage.detail.tab.comment.placeholder' });
  const vulnsSelected = useSelector(selectVulnsSelected);
  const isBulk = vulnsSelected.length > 1;
  const handleClose = () => dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));

  useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch]);

  const onAddComment = (text) => {
    dispatch(setBulkUpdateValue(text));
    dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
  };

  return (
    <>
      <Subtitle>{ subtitle }</Subtitle>
      <CommentTextAreaWrapper>
        <Title children={ title } />
        <CommentTextArea
          addComment={ (text) => onAddComment(text) }
          entity="vulnerability"
          placeholder={ placeholder }
          isBulk={ isBulk }
          handleClose={ handleClose }
        />
      </CommentTextAreaWrapper>
    </>
  );
};

export default BulkComment;
