import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as PrevIcon } from 'Images/prev.svg';
import { ReactComponent as NextIcon } from 'Images/next.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 589px;
  margin: 0px auto 10px auto;
`;
Wrapper.displayName = 'Wrapper';

export const Summary = styled.div`
  user-select: none;
  font-size: 13.5px;
  line-height: 1.04;
  letter-spacing: 0.1px;
  color: ${colors.grey19};
`;
Summary.displayName = 'Summary';

export const WrapperButton = styled.button`
  border: 0;
  background: transparent;
  width: 37px;
  height: 37px;
  background-color: transparent;
  margin: 0 0 0 8px;
  &:disabled {
    & > svg {
      cursor: default;
    }
    & > svg circle {
      fill: transparent;
    }
    & > svg path {
      fill: ${colors.grey12};
    }
  }
`;
WrapperButton.displayName = 'WrapperButton';

export const Prev = styled(PrevIcon)`
`;
Prev.displayName = 'Prev';

export const Next = styled(NextIcon)`
`;
Next.displayName = 'Next';
