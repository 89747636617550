import React from 'react';
import { PropTypes } from 'prop-types';
import 'Common/styles/commonStyles.scss';
import { AiOutlineWarning as Warning } from 'react-icons/ai';
import { FaTimes as Close } from 'react-icons/fa';
import colors from 'Styles/colors';
import ModalWrapper from 'Common/Components/ModalWrapper';
import {
  Wrapper, Body, Header, Title, Button, Text
} from './styled';
import useKeyPress from 'Hooks/useKeyPress';

const ErrorDialog = ({
  resetError, isFetching, message
}) => {
  const handleClose = () => resetError();

  useKeyPress(() => {
    if (handleClose) handleClose();
  }, ['Escape', 'Enter']);

  return (
    <ModalWrapper>
      <Wrapper>
        <Header>
          <Warning color={ colors.redPink } className="warn-icon" style={ { cursor: 'default' } } />
          <Title children="Faraday" />
          <Close color="Close" onClick={ handleClose } />
        </Header>
        <Body>
          {isFetching
            ? (
            <div className="spinner-border" role="status" />)
            : (<Text>{message}</Text>)}
        </Body>
        <Button onClick={ handleClose } children="Ok" />
      </Wrapper>
    </ModalWrapper>
  );
};

ErrorDialog.propTypes = {
  resetError: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  message: PropTypes.string.isRequired
};
ErrorDialog.defaultProps = {
  isFetching: false
};

export default ErrorDialog;
