import styled from 'styled-components';
import colors from 'Styles/colors';

export const Label = styled.div`
  font-size: 11px;
  font-weight: normal;
  color: ${colors.grey17};
  padding-bottom: 5px;
  padding-left: 9px;
  text-transform: capitalize;
`;
Label.displayName = 'Label';

export const CheckboxWrapper = styled.div`
  display: flex;
  margin: 23px 21px 0 0;
`;
CheckboxWrapper.displayName = 'FieldWrapper';
