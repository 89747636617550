import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlagIcon from 'Common/Components/FlagIcon';
import { updateVuln } from 'store/Manage/actions';
import { updateVulnFromAsset } from 'store/Contextualization/AssetDetail/actions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { updateVulnGeneral } from 'store/ManageGeneral/actions';

export const Confirm = ({ vuln, entity }) => { // eslint-disable-line import/prefer-default-export
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const canUpdate = !readOnly && allowedByRole;
  const dispatch = useDispatch();
  const confirm = (value) => {
    if (canUpdate) {
      if (entity === 'vulnsAssets') {
        dispatch(updateVulnFromAsset(vuln, 'confirmed', value));
      } else if (entity === 'vulns') {
        dispatch(updateVuln(vuln, 'confirmed', value));
      } else {
        dispatch(updateVulnGeneral(vuln, 'confirmed', value));
      }
    }
  };

  return (vuln.status && vuln.name && vuln.confirmed
    ? (
      <FlagIcon
        theme="confirmed"
        alt="Confirmed"
        title="Confirmed"
        onClick={ () => confirm(false) }
        aria-label="Confirmed"
      />
      )
    : (
      <FlagIcon
        theme="notConfirmed"
        alt="Not-Confirmed"
        title="Not Confirmed"
        onClick={ () => { confirm(true); } }
        aria-label="Not Confirmed"
      />
      )
  );
};
