/* eslint-disable import/prefer-default-export */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FaAngleDown as Expand,
  FaAngleUp as Unexpand
} from 'react-icons/fa';

export const Expander = ({ isExpanded }) => {
  if (isExpanded) return <Unexpand />;
  return <Expand />;
};

Expander.propTypes = {
  isExpanded: PropTypes.bool.isRequired
};
