import styled from 'styled-components';
import colors from 'Styles/colors';
import { Title } from 'Screens/Contextualization/Settings/common-styled';

export const TitleWrapper = styled.div` 
  display: flex;
  align-items: center;
`;
TitleWrapper.displayName = 'TitleWrapper';

export const TitleLabel = styled(Title)` 
  margin-right: 10px;
`;
TitleLabel.displayName = 'TitleLabel';

export const BetaLabel = styled.div` 
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  height: 27px;
  width: 55px;
  border-radius: 3px;
  background-color: #5e6f86;
  color: ${colors.white};
  font-size: 12.5px;
  font-weight: 700;
  margin-right: 20px;
`;
BetaLabel.displayName = 'BetaLabel';
