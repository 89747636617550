import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideInLeft } from 'Styles/effects';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 548px;
  background-color: ${colors.white1};
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  box-shadow: 18px 43px 50px 0 rgba(167, 167, 167, 0.5);
  padding: 25px 0 0 34px;
  ${slideInLeft};
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
