import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Wrapper } from './styled';
import { GreenCheckbox } from '../../../TicketingTools/components/Jira/components/Oauth/styled';
import { useDispatch, useSelector } from 'react-redux';
import { allowMultipleSessions, getMultipleSessions } from 'store/Settings/instanceSettingsActions';
import { selectAllowMultipleSessions } from 'store/Settings/instanceSettingsSelectors';

const Switch = () => {
  const dispatch = useDispatch();
  const isAllowed = useSelector(selectAllowMultipleSessions);
  const intl = useIntl();

  const onAuthenticateChange = (e) => dispatch(allowMultipleSessions());

  useEffect(() => {
    dispatch(getMultipleSessions());
  }, [dispatch]);

  return (
    <Wrapper>
      { intl.formatMessage({ id: 'preferences.userSessions.mainSettings.allowMultipleSessions' }) }
      <GreenCheckbox checked={ isAllowed } onChange={ onAuthenticateChange } />
    </Wrapper>
  );
};
export default Switch;
