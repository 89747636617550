/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Label, LabelContainer,
  StyledRadio, MainDuplicateIcon
} from './styled';

const RadioLabel = ({ label }) => (
  <LabelContainer>
    <MainDuplicateIcon />
    <Label>
      {label}
    </Label>
  </LabelContainer>
);

RadioLabel.propTypes = {
  label: PropTypes.string.isRequired
};

const RadioItem = ({ vuln, onRadioClick, checked }) => <StyledRadio label={ <RadioLabel label={ vuln.name } /> } value={ vuln.id } checked={ checked } onChange={ onRadioClick } />;

RadioItem.propTypes = {
  vuln: PropTypes.object.isRequired,
  onRadioClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};
export default RadioItem;
