import styled from 'styled-components';
import colors from 'Styles/colors';
import StandardTextField from 'Common/Components/StandardTextField';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  min-width: 430px;
  width: 505px;
  background-color: #fff;
  padding: 34px;
  border-top-style: solid;
  border-top-width: 10px;
  border-top-color: ${colors.softBlue};
`;
ModalWrapper.displayName = 'ModalWrapper';

export const TextField = styled(StandardTextField)`
  margin-top: 21px;
  height: 34px;
  width: 100%;
  color: ${colors.grey17};
  font-weight: 400;
  font-size: 12.5px;
  margin-bottom: 0;

  span {
    font-weight: 400;
  }
  input {
    box-shadow: inset 0 -1px 0 0 #afafaf;
  }`;
TextField.displayName = 'TextField';
