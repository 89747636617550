import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomAttributesList } from 'store/CustomAttributes/selectors';
import { toggleExpandCollapse } from 'store/Global/actions';
import { Wrapper, CustomButton } from './styled';

const ToggleButtons = ({ id }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const customAttributes = useSelector(selectCustomAttributesList);

  const fieldGroups = {
    general: [
      'tags', 'description', 'resolution', 'references', 'impact', 'policies', 'externalID', 'owner', 'duplicates', 'risk', 'cve', 'issuetracker'
    ],
    technical: [
      'data', 'website', 'request', 'response', 'method', 'path', 'queryString', 'statusCode', 'parameterName', 'parameter'
    ],
    customAttributes
  };

  const toggleAll = (value) => {
    const fields = fieldGroups[id];
    if (fields) {
      const fieldValues = Object.assign({}, ...fields.map((field) => ({
        [field]: {
          expanded: value
        }
      })));
      dispatch(toggleExpandCollapse(id, fieldValues));
    }
  };

  const onExpand = () => {
    toggleAll(true);
  };

  const onCollapse = () => {
    toggleAll(false);
  };

  return id && (
    <Wrapper>
      <CustomButton onClick={ onExpand }>{intl.formatMessage({ id: 'app.expand' })}</CustomButton>
      <CustomButton onClick={ onCollapse } >{intl.formatMessage({ id: 'app.collapse' })}</CustomButton>
    </Wrapper>
  );
};

export default ToggleButtons;
