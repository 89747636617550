import get from 'lodash/get';
import initialState from './initialState';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';

export const selectHostList = (state) => get(state, 'hostsGeneral.hostsList', initialState.hostsList);
export const selectIsFetching = (state) => get(state, 'hostsGeneral.isFetching', initialState.isFetching);
export const selectHostGeneralCount = (state) => get(state, 'hostsGeneral.hostCount', initialState.hostCount);
export const selectHostsSelected = (state) => get(state, 'hostsGeneral.hostsSelected', initialState.hostsSelected);
export const selectLastSelected = (state) => get(state, 'hostsGeneral.lastSelected', -1);
export const allHostsAreSelected = (state) => {
  const hosts = selectHostList(state);
  const hostList = selectHostsSelected(state);
  return hosts.every((testHost) => hostList.some((host) => host._id === testHost._id));
};

export const selectHostDetail = (state) => get(state, 'hostsGeneral.hostDetail', null);
export const selectHostDetailId = (state) => get(state, 'hostsGeral.hostDetail._id', null);
export const selectCurrentHostGeneral = (state) => get(state, 'hostsGeneral.hostSelectedForWorking[0]', {});
export const selectShowRightFilters = (state) => get(state, 'hostsGeneral.showRightFilters', false);
export const selectError = (state) => get(state, 'hostsGeneral.error', false);
export const selectErrorMessage = (state) => get(state, 'hostsGeneral.errorMessage', '');

export const selectHostCountAllWorkspaces = (state) => {
  const activeWorkspaces = selectActiveWorkspaces(state);
  const hostsCountArray = activeWorkspaces.map((host) => host.stats.hosts);
  const hostsCount = hostsCountArray.reduce((prev, cur) => prev + cur, 0);
  return hostsCount;
};

export const selectModalBulkUpdateField = (state) => get(state, 'hostsGeneral.bulkUpdateField', '');
export const selectModalBulkUpdateValue = (state) => get(state, 'hostsGeneral.bulkUpdateValue', '');
export const selectShowContextMenu = (state) => get(state, 'hostsGeneral.showContextMenu', false);
export const selectContextMenuXPos = (state) => get(state, 'hostsGeneral.contextMenuXPos', 0);
export const selectContextMenuYPos = (state) => get(state, 'hostsGeneral.contextMenuYPos', 0);
export const selectSelectAllHosts = (state) => get(state, 'hostsGeneral.selectAll', false);
export const someHostIsSelected = (state) => (selectHostsSelected(state).length > 0);

export const selectGeneralHostsTags = (state) => {
  const hostsSelected = selectHostsSelected(state);
  const hostsTags = hostsSelected.flatMap(host => host.tags);
  return hostsTags;
};
