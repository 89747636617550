import styled from 'styled-components';

export const Wrapper = styled.div`
  
`;
Wrapper.displayName = 'Wrapper';

export const SubTitle = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63758d;
  margin-top: 20px;
  text-align: initial;
`;
SubTitle.displayName = 'SubTitle';
