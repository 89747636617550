import styled from 'styled-components';
import colors from 'Styles/colors';

import { ReactComponent as _Configure } from 'Images/cog.svg';
import { Accept, Cancel } from 'Common/Components/Button/styled';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 505px;
  max-height: 705px;
  height: ${({ height }) => (height || 'auto')};
  overflow: auto;
  background: ${colors.white};
  text-align: left;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.44;
  color: ${colors.grey19};
  margin-bottom: 10px;
`;
Subtitle.displayName = 'Subtitle';

export const WrapperField = styled.div`
  flex: 1 1 auto;
  margin-bottom: 10px;
`;
WrapperField.displayName = 'WrapperField';

export const Form = styled.div`
  display: flex;
  padding: 10px 0;
  & > ${WrapperField}:not(:last-child) {
    padding-right: 15px;
  }
`;
Form.displayName = 'Form';

export const Label = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  letter-spacing: 0.09px;
  color: ${colors.grey17};
  padding-bottom: 8px;
`;
Label.displayName = 'Label';

export const Input = styled.input.attrs({
  type: 'text'
})`
  width: 100%;
  padding: 9px 15px 8px 15px;
  box-shadow: inset 0 -1px 0 0 #afafaf;
  background: ${colors.iceBlue};
  border: 0;
  font-size: 14.5px;
  letter-spacing: 0.11px;
  color: ${colors.grey17};
  ::placeholder {
    color: ${colors.grey17};
    opacity: 1;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
Footer.displayName = 'Footer';

export const DefaultButton = styled.div`
  cursor: pointer;
  width: 89px;
  line-height: 34px;
  margin: 0 8px 0 0;
  border-radius: 2px;
  border: 0px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;
DefaultButton.displayName = 'DefaultButton';

export const Close = styled(DefaultButton)`
  border: solid 1px ${colors.dark6};
  color: ${colors.dark2};
`;
Close.displayName = 'Close';

export const Save = styled(DefaultButton)`
  background: ${colors.blueCerulean};
  color: ${colors.white};
`;
Save.displayName = 'Save';

export const Blue = styled.span`
  color: ${colors.blueCerulean};
  padding: 0 5px;
`;
Blue.displayName = 'Blue';

/// Project //////

export const ProjectWrapper = styled.div`
  margin-top: 15px;
  padding: 13px;
  border: 1px solid #DBDBDB;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
`;

export const ProjectName = styled.div`
font-size: 12px;
font-weight: 500;
//color: #63758D;
`;

export const Configure = styled(_Configure)`
  & g {
    fill: ${({ isDone }) => (isDone ? '#BEC8D2' : 'rgba(255,86,48)')};
    stroke: ${({ isDone }) => (isDone ? '#BEC8D2' : 'rgba(255,86,48)')};
  }
`;

export const ConfirmButton = styled(Accept)`
  margin-left: 10px;
  width: fit-content;
`;
ConfirmButton.displayName = 'ConfirmButton';

export const CancelButton = styled(Cancel)`
  margin-left: auto;
`;
CancelButton.displayName = 'CancelButton';

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 30px;
`;

export const Password = styled(Input).attrs({
  type: 'password'
})`
`;
Password.displayName = 'Password';

export const CheckboxOption = styled.div`
  display:flex;
  justify-content: space-between;
  width: 95px;
`;
CheckboxOption.displayName = 'CheckboxOption';
