/* eslint-disable react/jsx-key */
/* eslint-disable no-nested-ternary */
import React from 'react';
import get from 'lodash/get';
import { Wrapper } from '../styled';
import { CustomErrorMark, StyledLabel } from './styled';
import { useIntl } from 'react-intl';
import { Delete } from '../FinishedOk/styled';
import { useDispatch } from 'react-redux';
import { removeCommand } from 'Common/Components/FileUploadContext/actions';

const FinishedWithError = ({ file, warning, warnings }) => {
  const commandId = get(file, 'command_id', 0);
  const mockCommandId = get(file, 'mockCommandId', null);
  const errorMessageUploading = get(file, 'errorMessage', '');
  const timeOut = get(file, 'timeOut', '');
  const intl = useIntl();
  const dispatch = useDispatch();
  const parsedError = intl.formatMessage({ id: 'uploadContext.status.finishedWithError' });
  const message = () => {
    if (warning) return warning;
    else if (warnings) return warnings;
    else return parsedError;
  };

  return (
    <Wrapper>
      <CustomErrorMark />
      <StyledLabel children={ timeOut ? 'Time out' : commandId ? message() : errorMessageUploading } />
      <Delete onClick={ () => dispatch(removeCommand(commandId, mockCommandId)) } />
    </Wrapper>
  );
};

export default FinishedWithError;
