import Importance from 'Common/Components/Importance';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateWS } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { Wrapper } from './styled';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const ImportanceColumn = ({ ws }) => {
  const dispatch = useDispatch();
  const [importance, setImportance] = useState();
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.UPDATE));

  useEffect(() => {
    if (ws && ws.importance) setImportance(ws.importance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callback = (importance) => {
    if (canUpdate) {
      setImportance(importance);
      dispatch(updateWS(ws.name, { importance }, false));
    }
  };

  return (
    <Wrapper>
      <Importance importance={ importance } callback={ callback } />
    </Wrapper>
  );
};

export default ImportanceColumn;
