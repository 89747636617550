import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import StandardTextField from 'Common/Components/StandardTextField';
import { Subtitle, Title } from 'Screens/Contextualization/Settings/common-styled';
import Header from '../Header';
import { Wrapper, Slack, FlexWrapper, ConfigColumn } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserId, selectIsAdmin } from 'store/Faraday/selectors';
import { getNotificationSettings } from 'store/Notifications/actions';
import api from 'services/api';
import { selectNotificationCenter } from 'store/Notifications/selectors';

const NotificationsPreferences = ({ onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [oAuthToken, setOAuthToken] = useState('');
  const [slackId, setSlackId] = useState('');
  // const [checkboxState, setCheckboxState] = useState(false);
  const isAdmin = useSelector(selectIsAdmin);
  const { slack_id } = useSelector(selectNotificationCenter);
  const userId = useSelector(selectCurrentUserId);

  // useEffect(() => {
  //   if (enable_slack) {
  //     checkboxState(enable_slack);
  //   }
  // }, [enable_slack]);
  useEffect(() => {
    if (slack_id) {
      setSlackId(slack_id);
    }
  }, [slack_id]);
  useEffect(() => {
    const fetchToken = async () => {
      const { slack_token } = await api.settings.getNotificationSlackOauthToken();
      setOAuthToken(slack_token);
    };
    fetchToken();
  }, []);

  useEffect(() => {
    dispatch(getNotificationSettings(userId));
  }, [dispatch, userId]);

  // const handleCheckboxChange = async (newState) => {
  //   try {
  //     await api.notifications.updateNotificationTypeApi(userId, 'enable_slack', newState);
  //     setCheckboxState(newState);
  //   } catch (error) {
  //     // console.error('Error updating pause all:', error);
  //   }
  // };

  const handleOAuthTokenChange = (newToken) => {
    setOAuthToken(newToken);
  };

  const handleSlackIdChange = (newId) => {
    setSlackId(newId);
  };

  const accept = async () => {
    await api.notifications.updateNotificationTypeApi(userId, 'slack_id', slackId);
    if (isAdmin) {
      await api.settings.setNotificationSlackOauthToken(oAuthToken);
    }
    onClose();
  };

  return (
      <Wrapper>
        <Header onAccept={ accept } onClose={ onClose } />
        <Title>{ intl.formatMessage({ id: 'preferences.notifications.title' }) }</Title>
        <Subtitle>{ intl.formatMessage({ id: 'preferences.notifications.subtitle' }) }</Subtitle>

  <FlexWrapper>

  <Slack />
  <ConfigColumn>
        {/* <OnOff
          state={ checkboxState }
          onChange={ handleCheckboxChange }
        /> */}

        {isAdmin && <StandardTextField
          title="Oauth Token"
          max={ 266 }
          min={ 266 }
          value={ oAuthToken }
          onChange={ handleOAuthTokenChange }
          placeholder="Token"
        />}

        <StandardTextField
          title="Slack ID"
          max={ 266 }
          min={ 266 }
          value={ slackId }
          onChange={ handleSlackIdChange }
          placeholder="ID"
        />
  </ConfigColumn>
  </FlexWrapper>
      </Wrapper>
  );
};

NotificationsPreferences.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default NotificationsPreferences;
