/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import usePermission from 'Hooks/usePermission/index';

/** HOC */
export const withPermission = (Component, permission, type) => {
  const ValidatedComponent = () => {
    const hasPermission = usePermission(permission, type);
    return hasPermission ? <Component /> : null;
  };

  return ValidatedComponent;
};

/** STANDARD */
export const WithPermission = ({
  children, permission, type
}) => {
  const hasPermission = usePermission(permission, type);
  return hasPermission ? children : null;
};

WithPermission.propTypes = {
  permission: PropTypes.string.isRequired,
  type: PropTypes.oneOf([PERMISSION_TYPES.READ, PERMISSION_TYPES.DELETE, PERMISSION_TYPES.UPDATE, PERMISSION_TYPES.CREATE])
};
