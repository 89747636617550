/* eslint-disable no-nested-ternary */
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTaskType, selectTaskName, selectTaskFormattedStartDate, selectTaskFormattedEndDate,
  selectTaskStatus, selectTaskDescription, selectTaskAssignees, selectTaskDependencies,
  selectTasksByProjectId, selectTaskProjectId, selectIsEditing, selectTaskId
} from 'store/Tasks/selectors';
import { setTaskField } from 'store/Tasks/actions';
import { selectProjectId } from 'store/Projects/selectors';
import { selectIsAdmin } from 'store/Faraday/selectors';
import DebouncedInput from 'Common/Components/v2/DebouncedInput';
import DebouncedTextArea from 'Common/Components/v2/DebouncedTextArea';
import DateInput from './components/DateInput';
import Dropdown from './components/Dropdown';
import SearchFilter from './components/SearchFilter';
import { TASK_TYPE, TASK_PROGRESS } from './constants';
import {
  Wrapper, ContentWrapper, Row, Field, Title,
  Asterisk, DisabledWrapper, DateWrapper, ProgressWrapper, EndDate
} from './styled';
import { selectWorkspaceUsers } from 'store/Session/selectors';

const General = () => {
  const dispatch = useDispatch();
  const taskId = useSelector(selectTaskId);
  const type = useSelector(selectTaskType);
  const name = useSelector(selectTaskName);
  const startDate = useSelector(selectTaskFormattedStartDate);
  const endDate = useSelector(selectTaskFormattedEndDate);
  const status = useSelector(selectTaskStatus);
  const description = useSelector(selectTaskDescription);
  const assignees = useSelector(selectTaskAssignees);
  const dependencies = useSelector(selectTaskDependencies);
  const isEditing = useSelector(selectIsEditing);
  const projectIdSelected = useSelector(selectProjectId);
  const taskProjectId = useSelector(selectTaskProjectId);
  const projectId = isEditing ? taskProjectId : projectIdSelected;
  const tasks = useSelector((state) => selectTasksByProjectId(state, projectId));
  const notAddedDeps = tasks.filter((dep) => dependencies.every((added) => added.id !== dep.id) && dep.id !== taskId);
  const isAdmin = useSelector(selectIsAdmin);

  const intl = useIntl();
  const getIntlText = (id) => intl.formatMessage({ id: `planner.task.tab.gral.${id}` });
  const users = useSelector(selectWorkspaceUsers);
  const notAddedUsers = users.filter((u) => assignees.every((added) => (u.name !== added.name) && u.active));

  const onChange = (field, value) => dispatch(setTaskField(field, value));

  return (
    <Wrapper>
      <Row>
        <Field disabled={ !isAdmin } width="70%">
          <DisabledWrapper disabled={ !isAdmin }>
            <DebouncedInput value={ name } onChange={ (e) => onChange('name', e) } name="name" title={ getIntlText('name') } placeholder={ intl.formatMessage({ id: 'planner.task.tab.gral.name.placeholder' }) } mandatory />
          </DisabledWrapper>
        </Field>
        <Field disabled={ !isAdmin }>
          <DisabledWrapper disabled={ !isAdmin }>
            <Dropdown options={ TASK_TYPE } value={ type } handleChange={ (e) => onChange('type', e.value) } fields="types" />
          </DisabledWrapper>
        </Field>
      </Row>
      { type === 'task'
        ? (
          <ContentWrapper>
            <Row>
              <Field disabled={ !isAdmin } width="100%">
                <DisabledWrapper disabled={ !isAdmin }>
                  <DateWrapper>
                    <DateInput
                      max={ endDate }
                      onChange={ (v) => onChange('startDate', v) }
                      title={ getIntlText('startDate') }
                      value={ startDate }
                    />
                    <DateInput
                      min={ startDate }
                      onChange={ (v) => onChange('endDate', v) }
                      title={ getIntlText('endDate') }
                      value={ endDate }
                    />
                  </DateWrapper>
                </DisabledWrapper>
              </Field>
              <ProgressWrapper>
                <Title>
                  { getIntlText('progress') }
                  <Asterisk>*</Asterisk>
                </Title>
                <Dropdown options={ TASK_PROGRESS } value={ status } handleChange={ (e) => onChange('status', e.value) } fields="status" hasTitle />
              </ProgressWrapper>
            </Row>
            <Row>
              <DebouncedTextArea disabled={ !isAdmin } name="description" value={ description } onChange={ (v) => onChange('description', v) } title={ getIntlText('description') } placeholder={ getIntlText('description.placeholder') } mandatory />
            </Row>
            <SearchFilter
              readOnly={ !isAdmin }
              title={ getIntlText('users.title') }
              subtitle={ getIntlText('users.subtitle') }
              data={ notAddedUsers }
              placeholder={ getIntlText('users.placeholder') }
              list={ assignees }
              onAdd={ (el) => dispatch(setTaskField('assignees', el)) }
              users
            />
            <SearchFilter
              readOnly={ !isAdmin }
              title={ getIntlText('dependencies.title') }
              subtitle={ getIntlText('dependencies.subtitle') }
              data={ notAddedDeps }
              placeholder={ getIntlText('dependencies.placeholder') }
              list={ dependencies }
              onAdd={ (el) => dispatch(setTaskField('dependencies', el)) }
            />
          </ContentWrapper>
          )
        : type === 'milestone'
          ? (
            <ContentWrapper>
              <Row>
                <EndDate onChange={ (v) => onChange('endDate', v) } title={ getIntlText('endDate') } value={ endDate } />
              </Row>
              <Row>
                <DebouncedTextArea name="description" value={ description } onChange={ (v) => onChange('description', v) } title={ getIntlText('description') } placeholder={ getIntlText('description.placeholder') } mandatory />
              </Row>
              <SearchFilter
                title={ getIntlText('dependencies.title') }
                subtitle={ getIntlText('dependencies.subtitle') }
                data={ notAddedDeps }
                placeholder={ getIntlText('dependencies.placeholder') }
                list={ dependencies }
                onAdd={ (el) => dispatch(setTaskField('dependencies', el)) }
              />
            </ContentWrapper>
            )
          : null}
    </Wrapper>
  );
};

export default General;
