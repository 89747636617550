import get from 'lodash/get';
import { selectEntity, selectOrderBy } from 'store/Filters/selectors';
import parseTemplate from 'store/ExecutiveReportEditCreate/mapping';
// eslint-disable-next-line import/prefer-default-export
export const selectIsFetching = (state) =>
  get(state, 'executiveReportEditCreate.isFetching', false);
export const selectVulnsCount = (state) =>
  get(state, 'executiveReportEditCreate.total_vulns', 0);
export const selectPreviewData = (state) =>
  get(state, 'executiveReportEditCreate.previewData', []);
export const selectInputField = (state, inputType) =>
  get(state, `executiveReportEditCreate.${inputType}`, '');
export const selectSelectedTemplate = (state) =>
  get(state, 'executiveReportEditCreate.selectedTemplate', []);
export const selectTemplates = (state) =>
  get(state, 'executiveReport.templates', []);
export const selectID = (state) =>
  get(state, 'executiveReportEditCreate.id', '');
export const selectFilterArray = (state) =>
  get(state, 'executiveReportEditCreate.filterArray', []);
export const selectParseError = (state) =>
  get(state, 'executiveReportEditCreate.parseError', false);
export const selectAdvancedFilter = (state) =>
  get(state, 'executiveReportEditCreate.advancedFilter', '');

export const selectWorkspaces = (state) => get(state, 'executiveReportEditCreate.workspaces', []);

export const selectAdvancedFilterQueryParam = (state) => {
  const { page, rowsPerPage } = selectEntity('reports', state);
  const advancedFilter = selectAdvancedFilter(state);
  const orderBy = JSON.stringify(selectOrderBy('reports', state));
  const offset = (page - 1) * rowsPerPage;
  const queryToParse = `{"offset":${offset},"limit":${rowsPerPage},"order_by":${orderBy},"filters":[${advancedFilter}]}`;
  const parsedQuery = JSON.parse(queryToParse);
  return parsedQuery;
};

export const selectParsedTemplateName = (state) => {
  const selectedTemplate = selectSelectedTemplate(state);
  return Object.keys(selectedTemplate).length === 0
    ? {}
    : parseTemplate(selectedTemplate);
};

export const selectTemplateTemp = (state) =>
  get(state, 'executiveReportEditCreate.selectTemplateTemp', {});

export const selectCustomTemplates = (state) => {
  const templates = selectTemplates(state);
  const customTemplates = templates.filter((t) => t[1].includes('custom'));
  const customTemplatesList = customTemplates.map((t) => {
    const isWord = t[1].includes('.docx');
    const isPdf = t[1].includes('.html');
    return {
      label: t[1],
      docxOption: isWord ? t[1] : '',
      pdfOption: isPdf ? t[1] : ''
    };
  });

  return customTemplatesList;
};

export const selectCustomLogo = (state) =>
  get(state, 'executiveReportEditCreate.custom_logo', '');
export const selectUploadState = (state) =>
  get(state, 'executiveReportEditCreate.uploadState', '');
export const selectTemplateFile = (state) =>
  get(state, 'executiveReportEditCreate.templateFile', '');
export const selectError = (state) =>
  get(state, 'executiveReportEditCreate.error', false);
export const selectErrorMessage = (state) =>
  get(state, 'executiveReportEditCreate.errorMessage', '');
