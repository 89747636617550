import React from 'react';
import { PropTypes } from 'prop-types';
import {
  ContextMenu, MenuItem
} from 'react-contextmenu';
import { changeReadOnly, moreStatusChange, enableDisable } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LockImage from 'Images/ws-lock.svg';
import ArchiveImage from 'Images/archive.svg';
import { MdKeyboardArrowRight as Arrow } from 'react-icons/md';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import find from 'lodash/find';
import {
  MenuItemContent, Text, IconActiveInactive, Wrapper, ArrowIcon, IconEdit, SubMenuStatus, StatusText
} from './styled';
import { LockIcon, UnlockIcon } from '../Table/LockUnlockWs/styled';

const ContextMenuWorkspaceMassiveUpdate = ({ trigger, setShowConfirmation, ws = null }) => {
  const dispatch = useDispatch();
  const workspacesSelected = useSelector((state) => selectWorkspacesSelected(state));
  const currentWorkspace = useSelector((state) => selectCurrentWorkspace(state));

  const setReadOnly = (value) => {
    if (workspacesSelected.length > 0) {
      workspacesSelected.forEach((ws) => {
        dispatch(changeReadOnly(ws.name, value));
      });
    }
  };

  const handleArchiveWs = () => {
    if (ws) {
      if (ws.active) {
        setShowConfirmation({ show: true, ws: ws.ws, isMassive: false });
      } else {
        dispatch(enableDisable(ws.ws, true));
      }
    } else {
      setShowConfirmation({ show: true, ws: '', isMassive: true });
    }
  };

  return (
    <Wrapper>
      <ContextMenu id={ trigger } onHide={ () => dispatch(moreStatusChange(false)) } onShow={ () => moreStatusChange(true) }>
        { !find(workspacesSelected, { name: currentWorkspace }) && (
        <MenuItem onClick={ handleArchiveWs }>
          <MenuItemContent>
            <IconActiveInactive src={ ArchiveImage } />
            <Text><FormattedMessage id="workspaces.contextMenu.activeArchived" /></Text>
          </MenuItemContent>
        </MenuItem>
        ) }
        <SubMenuStatus title={ (
          <MenuItemContent>
            <IconEdit src={ LockImage } />
            <Text><FormattedMessage id="workspaces.contextMenu.workspaceStatus" /></Text>
            <ArrowIcon><Arrow /></ArrowIcon>
          </MenuItemContent>
            ) }
        >
          <MenuItem onClick={ () => { setReadOnly(false); } }><UnlockIcon /><StatusText><FormattedMessage id="workspaces.table.columns.unlocked" /></StatusText></MenuItem>
          <MenuItem onClick={ () => { setReadOnly(true); } }><LockIcon /><StatusText><FormattedMessage id="workspaces.table.columns.locked" /></StatusText></MenuItem>
        </SubMenuStatus>
      </ContextMenu>
    </Wrapper>
  );
};

ContextMenuWorkspaceMassiveUpdate.propTypes = {
  trigger: PropTypes.string.isRequired
};

export default ContextMenuWorkspaceMassiveUpdate;
