import styled from 'styled-components';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  margin-top: 20px;
`;
Wrapper.displayName = 'Wrapper';

export const StyledTable = styled(CustomReactTable)`
overflow: initial;
  .rt-thead .rt-tr .rt-th {
    &:first-child {
      padding-left: 50px;
    }
    padding-left: 20px;
  }
  .rt-tbody {
    .rt-tr-group {
      &:hover {
        background: ${colors.iceBlue};
        .actionBar {
          visibility: visible;
        }
      }
    }
    .rt-tr {
      height: 50px;
      .rt-td {
        &:first-child {
          padding-left: 50px;
        }
        padding-left: 20px;
      }
    }
  }
  .actionBar {
    padding: 0px !important;
    visibility: hidden;
    overflow: visible;
  }
`;
CustomReactTable.displayName = 'CustomReactTable';
