import styled from 'styled-components';

export const EmptyComments = styled.div`
    user-select: none;
    padding: 15px 436px 18px 19px;
    border-radius: 3px;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: normal;
    color: #1c2243;
    width: 609px;
`;
EmptyComments.displayName = 'EmptyComments';

export const CommentsWrapper = styled.div`
    margin: 0 0 13px;
    padding: 21px 8px 21px 21px;
    border-radius: 3px;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
`;
CommentsWrapper.displayName = 'CommentsWrapper';
