import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './styled';

const ActionButton = ({
  onClick, title, icon, show
}) => {
  if (!show) return null;
  return (
    <Wrapper title={ title } onClick={ onClick }>
      {icon}
    </Wrapper>
  );
};

ActionButton.defaultProps = {
  onClick: null,
  show: true
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  show: PropTypes.bool
};

export default ActionButton;
