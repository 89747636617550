import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Input, Plus } from './styled';

const InputAdd = ({ onAdd, placeholder }) => {
  const [value, setValue] = useState('');
  const handleChange = (e) => setValue(e.target.value);
  const handleOnAdd = () => {
    onAdd(value);
    setValue('');
  };
  const handleKeyPress = (event) => { if (event.key === 'Enter') handleOnAdd(); };

  return (
    <Wrapper>
      <Input
        placeholder={ placeholder }
        onKeyPress={ handleKeyPress }
        onChange={ handleChange }
        value={ value }
      />
      <Plus onClick={ handleOnAdd } title="Add to this task" />
    </Wrapper>
  );
};

export default InputAdd;

InputAdd.propTypes = {
  onAdd: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
};
