import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setGraphType } from 'store/Analytics/actions';
import { Wrapper, Subtitle, Title } from './styled';

const SelectTypeCard = ({
  title, description, type, allowMultipleWs, allowHosts
}) => {
  const dispatch = useDispatch();
  return (
    <Wrapper onClick={ () => dispatch(setGraphType(type, allowMultipleWs, allowHosts)) }>
      <Title>{title}</Title>
      <Subtitle>{description}</Subtitle>
    </Wrapper>
  );
};

SelectTypeCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  allowMultipleWs: PropTypes.bool.isRequired,
  allowHosts: PropTypes.bool.isRequired
};

export default SelectTypeCard;
