import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import set from 'lodash/set';
import api from 'services/api';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import * as types from 'store/Contextualization/AssetDetail/types';
import { redirect } from 'store/Router/actions';
import { setFilter, setFilterError, setOrderBy, setPageNumber } from 'store/Filters/actions';
import {
  selectSelected, selectServices, selectShowHostDetail,
  selectCount, selectLastSelected,
  selectShowDetail,
  selectServiceId,
  selectVulnsQueryParam,
  selectVulnsAdvancedFilterQueryParam,
  selectDetail,
  selectModalBulkUpdateField,
  selectModalBulkUpdateValue,
  selectSelectAllVulns,
  selectCurrentAsset,
  selectContextMenuXPos,
  selectContextMenuYPos,
  selectVulns,
  selectVulnDetailId,
  selectServicesAdvancedFilterQueryParam,
  selectServicesQueryParam,
  selectBulkUpdateCustomAttribute
} from './selectors';
import { selectAdvancedFilter, selectQueryParam, selectRowsPerPage } from 'store/Filters/selectors';
import { selectCurrentHost } from 'store/Host/selectors';
import { MANAGE_SET_TEMP_CVSS, SAVE_TEMPLATE_MANAGE } from 'store/Manage/types';
import { contextMenufilteringFunctions, setVulnsFilterError } from 'store/Manage/filterActions';
import { MANAGE_CREATE_UPDATE_START, RESET_STATE_MANAGE_CREATE_UPDATE } from 'Screens/ManageEditCreate/actions/Actions';
import { getVulnsCountInWs } from 'Screens/Contextualization/Faraday/actions/Actions';
import { closeModal, openModal } from 'store/modals/actions';
import { MODAL_CREATE_SERVICE, MODAL_MANAGE_BULK_UPDATE, MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { addComment, getImpactData } from 'store/Manage/actions';
import { getTags, getHostsTags, getServicesTags } from 'Screens/Contextualization/Tags/actions/Actions';

const getVulnsSuccedCallback = (data) => ({
  type: types.GET_DATA_VULNS_SUCCESS,
  data: data && data.vulnerabilities.length > 0 ? data.vulnerabilities.map((x) => x.value) : [],
  count: data.count
});

const getVulnsFailureCallback = (error) => ({
  type: types.GET_DATA_VULNS_FAIL,
  error
});

export function resetHostDetail () {
  return (dispatch) => {
    dispatch({ type: types.RESET_STATE_HOST_DETAIL });
  };
}

export function getVulns (ip) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_DATA_VULNS_START });
    const state = getState();
    const workspaceSelected = selectCurrentWorkspace(state);
    const hasAdvancedFilter = selectAdvancedFilter(state, 'vulnsAssets');

    let advancedFilterQueryParam = [];
    if (hasAdvancedFilter) {
      try {
        advancedFilterQueryParam = selectVulnsAdvancedFilterQueryParam(ip, state);
      } catch (e) {
        dispatch(setFilterError('vulns', 'Syntax error. Please try again. For further help check our documentation'));
        return dispatch(setVulnsFilterError());
      }
    }

    const standardQueryParam = selectVulnsQueryParam(ip, state);
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    try {
      const response = await api.manage.newGetVulns(workspaceSelected, queryParam);

      return dispatch(getVulnsSuccedCallback(response));
    } catch (e) {
      return dispatch(getVulnsFailureCallback(e));
    }
  };
}

const getServicesSuccedCallback = (data) => ({
  type: types.GET_DATA_SERVICES_SUCCESS,
  data: data && data.services.length > 0 ? data.services.map((service) => service.value) : [],
  count: data.count
});

const getServicesFailureCallback = (error) => ({
  type: types.GET_DATA_SERVICES_FAIL,
  error
});

export const getServices = () => {
  return (dispatch, getState) => {
    const currentAsset = selectCurrentHost(getState());
    const assetIp = get(currentAsset, 'ip', '');

    dispatch(getAssetServices(assetIp));
  };
};

export const setServicesFilterError = () => async (dispatch) => dispatch({ type: types.SET_SERVICES_ASSETS_FILTER_ERROR });

export function getAssetServices (hostIp) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_DATA_SERVICES_START });
    try {
      const workspace = selectCurrentWorkspace(getState());
      const hasAdvancedFilter = selectAdvancedFilter(getState(), 'servicesAssets');

      let advancedFilterQueryParam = [];
      if (hasAdvancedFilter) {
        try {
          advancedFilterQueryParam = selectServicesAdvancedFilterQueryParam(hostIp, getState());
        } catch (e) {
          dispatch(setFilterError('servicesAssets', 'Syntax error. Please try again. For further help check our documentation'));
          return dispatch(setServicesFilterError());
        }
      }

      const standardQueryParam = selectServicesQueryParam(hostIp, getState());
      const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;
      const response = await api.service.getServices(workspace, queryParam);
      return dispatch(getServicesSuccedCallback(response));
    } catch (e) {
      return dispatch(getServicesFailureCallback(e));
    }
  };
}

export function getTools (workspace) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.GET_DATA_TOOLS_START });
      const { host } = getState().hostDetail;
      const data = await api.hostDetail.fetchTools(workspace, host.id);
      return dispatch({ type: types.GET_DATA_TOOLS_SUCCESS, data: data.tools });
    } catch (e) {
      return dispatch({ type: types.GET_DATA_TOOLS_FAIL, e });
    }
  };
}

export function redirectToHostDetail (id) {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/host/${currentWorkspace}/${id}`));
  };
}

export function redirectToHost () {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/host/${currentWorkspace}`));
  };
}

export function showAssetDetail (host) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_HOST_DETAIL, host });
  };
}

export function showHostDetailById (id) {
  return async (dispatch, getState) => {
    if (id > 0) {
      const currentWorkspace = selectCurrentWorkspace(getState());
      try {
        const host = await api.hostDetail.fetchById(currentWorkspace, id);
        dispatch(showAssetDetail(host));
      } catch (e) {
        dispatch(redirectToHost());
      }
    }
  };
}

export function hideHostDetail () {
  return (dispatch, getState) => {
    const showHostDetail = selectShowHostDetail(getState());
    if (showHostDetail) dispatch({ type: types.HIDE_HOST_DETAIL });
  };
}

export function addService (service) {
  return async (dispatch, getState) => {
    try {
      const { name, assets, status, ports, protocol, version, description, owned } = service;
      const { workspaceSelected } = getState().faraday;
      const hostSelectedId = get(assets, '[0]._id', 0);
      const portsParsed = ports.toString().split(',');

      const newService = await api.hostDetail.addService(workspaceSelected, hostSelectedId, name, status, portsParsed, protocol, version, description, owned);

      dispatch({ type: types.ADD_SERVICE_ON_ASSET_SUCCESS, service: newService });

      dispatch(closeModal(MODAL_CREATE_SERVICE));
    } catch (e) {
      const message = get(e, 'message', 'There was an error, please try again.');
      return dispatch({ type: types.ADD_SERVICE_ON_ASSET_FAIL, message });
    }
  };
}

export function selectService (selectedRow) {
  return (dispatch) => {
    dispatch({ type: types.SELECT_SERVICE, selectedRow });
  };
}

export function setOrderByVulnsAssets (sorting, ip) {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    const filters = [
      {
        name: 'target',
        op: '==',
        val: ip
      }
    ];
    dispatch(setOrderBy('vulnsAssets', sorting));
    const queryParam = selectQueryParam('vulnsAssets', getState());
    const data = await api.manage.newGetVulns(workspaceSelected, { ...queryParam, filters });

    dispatch({
      type: types.GET_DATA_VULNS_SUCCESS,
      data: data && data.vulnerabilities.length > 0 ? data.vulnerabilities.map((x) => x.value) : [],
      count: data.count
    });
  };

  // return (dispatch) => {
  //   dispatch(setOrderBy('vulnsAssets', sorting));
  //   dispatch(getVulns(ip))
  // };
}

export function updateVulnFromAsset (vulnBefore, field, value) {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    const vulnsList = selectVulns(getState());
    const vulnsCount = selectCount('vulns', getState());
    let newVuln = { ...vulnBefore };
    set(newVuln, field, value);
    try {
      const newList = vulnsList;
      let newRefs = [];
      if (field === 'refs') {
        newRefs = value.map((ref) => ({ name: ref, type: 'other' }));
        newVuln = await api.manage.updateVuln(workspaceSelected, { _id: vulnBefore._id, refs: newRefs });
      } else {
        newVuln = await api.manage.updateVuln(workspaceSelected, { _id: vulnBefore._id, [field]: value });
      }

      const index = vulnsList.findIndex((x) => newVuln._id === x.id || newVuln._id === x._id);
      newList[index] = newVuln;

      dispatch({ type: types.UPDATE_VULN_ASSET_PREVIEW_SUCCESS, data: newList, vulnsCount, vuln: newVuln });
      if (field === 'confirmed') dispatch(getVulnsCountInWs());
    } catch (e) {
      dispatch({ type: types.UPDATE_VULN_ASSET_PREVIEW_FAIL, errorMessage: e.message });
    }
  };
}

export function setPageNumberVulnAssets (pageNumber) {
  return (dispatch, getState) => {
    const currentAsset = selectCurrentAsset(getState());
    const ip = get(currentAsset, 'ip', '');
    dispatch(setPageNumber('vulnsAssets', pageNumber));
    dispatch(getVulns(ip));
  };
}
export const selectTotalItems = (entity) => (dispatch) => {
  return dispatch({ type: types.SELECT_TOTAL_ITEMS, entity });
};

export const unselectTotalItems = (entity) => (dispatch) => {
  return dispatch({ type: types.UNSELECT_TOTAL_ITEMS, entity });
};

export const unselectedRowFromSelectAll = (value, entity) => (dispatch) => {
  dispatch({ type: types.UNSELECTED_ROW_ITEM_FROM_SELECT_ALL, value, entity });
};

export const autoSelectVulnItem = (item) => (dispatch) => {
  dispatch({ type: types.AUTOSELECT_VULN_ITEM, item });
};

const addDeleteControllerServices = (services, serviceList) => {
  const allServicesAreSelected = services.every((testService) => serviceList.some((service) => service._id === testService._id));

  if (allServicesAreSelected) return [types.ASSET_DETAIL_SERVICE_UNSELECTED, services];
  return [types.ASSET_DETAIL_SERVICE_SELECTED, services];
};

const selectCalculatorServices = (e, service, serviceList, areServiceSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected('services', getState());
  const index = serviceList.findIndex((el) => el._id === service._id);
  const servicesSelected = selectSelected('services', getState());

  dispatch({ type: types.ASSET_DETAIL_NEW_PIVOT, payload: index, entity: 'services' });
  if (e.shiftKey && pivot !== -1 && areServiceSelected) {
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const services = serviceList.slice(start, end);
    const [type, payload] = addDeleteControllerServices(services, servicesSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteControllerServices([service], servicesSelected);
  return dispatch({ type, payload });
};

export const selectServiceRow = (e, service) => (dispatch, getState) => {
  const allService = selectServices(getState());
  const serviceSelected = selectSelected('services', getState());

  dispatch(selectCalculatorServices(e, service, allService, serviceSelected.length > 0));
};

export const unSelectAll = (entity) => (dispatch) => dispatch({ type: types.UNSELECT_ALL_ITEMS, entity });

export const selectAllServices = () => (dispatch, getState) => {
  const entity = 'services';
  const servicesList = selectServices(getState());
  const servicesSelected = selectSelected(entity, getState());
  const serviceCount = selectCount(entity, getState());
  const pageSize = selectRowsPerPage('servicesAssets', getState());

  if (servicesSelected.length === serviceCount || servicesSelected.length >= pageSize) return dispatch(unSelectAll(entity));

  return dispatch({ type: types.SELECT_ALL_ITEMS, entity, list: servicesList });
};

export function redirectToService () {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    const currentAsset = selectCurrentHost(getState());
    const assetId = get(currentAsset, '_id', 0);
    dispatch(redirect(`/host/${currentWorkspace}/${assetId}/services`));
  };
}

export function redirectToServiceDetail (serviceId) {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    const currentAsset = selectCurrentHost(getState());
    const assetId = get(currentAsset, '_id', 0);
    dispatch(redirect(`/host/${currentWorkspace}/${assetId}/services/${serviceId}`));
  };
}

export function showDetail (entity, data) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_DETAIL, entity, detail: data });
  };
}

export function loadServiceDetail (id) {
  return async (dispatch, getState) => {
    if (id > 0) {
      const currentWorkspace = selectCurrentWorkspace(getState());
      try {
        const service = await api.service.fetchById(currentWorkspace, id);
        dispatch(showDetail('services', service));
      } catch (e) {
        dispatch(redirectToService());
      }
    }
  };
}

export function hideServiceDetail () {
  return (dispatch, getState) => {
    const showServiceDetail = selectShowDetail('services', getState());
    if (showServiceDetail) dispatch({ type: types.HIDE_DETAIL, entity: 'services' });
  };
}

export function clearError (entity) {
  return async (dispatch) => {
    dispatch({ type: types.CLEAR_ERROR, entity });
  };
}

export function setPage (pageNumber) {
  return async (dispatch) => {
    dispatch({ type: types.SET_PAGE_NUMBER_SERVICES, pageNumber });
  };
}
export function setServicesPageNumber (pageNumber) {
  return (dispatch, getState) => {
    const currentAsset = selectCurrentHost(getState());
    const currentAssetIp = get(currentAsset, 'ip', '');
    dispatch(setPageNumber('servicesAssets', pageNumber));
    dispatch(getAssetServices(currentAssetIp));
  };
}

export function showModalDelete (entity) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_MODAL_DELETE, entity });
  };
}

export function hideModalDelete (entity) {
  return (dispatch) => {
    dispatch({ type: types.HIDE_MODAL_DELETE, entity });
  };
}

export function deleteServicesSelectedFromAsset () {
  return async (dispatch, getState) => {
    const entity = 'services';
    dispatch({ type: types.DELETE_START, entity });
    const state = getState();
    const currentWorkspace = selectCurrentWorkspace(state);
    const currentAsset = selectCurrentHost(state);
    const assetIp = get(currentAsset, 'ip', '');
    const servicesSelected = selectSelected('services', state);
    const servicesSelectedID = servicesSelected.map(service => service._id);
    try {
      const response = await api.service.deleteService(currentWorkspace, servicesSelectedID);
      dispatch({ type: types.DELETE_SUCCESS, response, entity });

      dispatch(hideModalDelete('services'));
      return dispatch(getAssetServices(assetIp));
    } catch (err) {
      dispatch({ type: types.DELETE_FAIL, entity });
    }
  };
}

export function resetServiceDetail () {
  return async (dispatch) => dispatch({ type: types.RESET_SERVICE_DETAIL });
}

export function updateService (serviceId, valueObj) {
  return async (dispatch, getState) => {
    const state = getState();
    const workspaceSelected = selectCurrentWorkspace(state);
    const serviceList = selectServices(state);
    const serviceCount = selectCount('services', state);
    const serviceDetailId = selectServiceId(state);
    const newService = await api.service.updateService(workspaceSelected, { id: serviceId, ...valueObj });
    const index = serviceList.findIndex((x) => serviceId === x.id || serviceId === x._id);
    serviceList[index] = { ...newService };
    dispatch({ type: types.ASSET_DETAIL_UPDATE_SERVICE_SUCCESS, data: serviceList, serviceCount });
    if (serviceDetailId && newService._id === serviceDetailId) dispatch(showDetail('services', newService));
  };
}

export function resetState (entity) {
  return (dispatch) => {
    dispatch({ type: types.RESET_ASSET_STATE, entity });
  };
}

export function redirectToManage () {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    const currentAsset = selectCurrentHost(getState());
    const assetId = get(currentAsset, '_id', 0);
    dispatch(redirect(`/host/${currentWorkspace}/${assetId}/vulns`));
  };
}

export function setVulnerabilityDetailTab (vulnSelectedTab) {
  return (dispatch) => {
    dispatch({ type: types.SET_VULNS_TAB, vulnSelectedTab });
  };
}

export function redirectToManageDetail (vulnId, vulnDetailSelectedTab) {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    const currentAsset = selectCurrentHost(getState());
    const assetId = get(currentAsset, '_id', 0);
    dispatch(redirect(`/host/${currentWorkspace}/${assetId}/vulns/${vulnId}`));
    if (vulnDetailSelectedTab) dispatch(setVulnerabilityDetailTab(vulnDetailSelectedTab));
  };
}

export function loadManageDetail (id) {
  return async (dispatch, getState) => {
    if (id > 0) {
      const currentWorkspace = selectCurrentWorkspace(getState());
      try {
        const vuln = await api.manage.fetchById(currentWorkspace, id);
        dispatch(showDetail('vulns', vuln));
      } catch (e) {
        dispatch(redirectToManage());
      }
    }
  };
}

export function hideManageDetail () {
  return (dispatch, getState) => {
    const showManageDetail = selectShowDetail('vulns', getState());
    if (showManageDetail) dispatch({ type: types.HIDE_DETAIL, entity: 'vulns' });
  };
}

const addDeleteControllerVulns = (vulns, vulnList) => {
  const allVulnsAreSelected = vulns.every((testVuln) => vulnList.some((vuln) => vuln._id === testVuln._id));
  if (allVulnsAreSelected) return [types.VULN_UNSELECTED, vulns];
  return [types.ASSET_DETAIL_VULN_SELECTED, vulns];
};

const selectCalculatorVulns = (e, vuln, vulnList, areVulnSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected('vulns', getState());
  const index = vulnList.findIndex((el) => el._id === vuln._id);
  const vulnsSelected = selectSelected('vulns', getState());

  dispatch({ type: types.ASSET_DETAIL_NEW_PIVOT, payload: index, entity: 'vulns' });
  if (e.shiftKey && pivot !== -1 && areVulnSelected) {
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const vulns = vulnList.slice(start, end);
    const [type, payload] = addDeleteControllerVulns(vulns, vulnsSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteControllerVulns([vuln], vulnsSelected);
  return dispatch({ type, payload });
};

export const selectVulnRow = (e, vuln) => (dispatch, getState) => {
  const allVulns = selectVulns(getState());
  const vulnSelected = selectSelected('vulns', getState());

  dispatch(selectCalculatorVulns(e, vuln, allVulns, vulnSelected.length > 0));
};

export const selectAllVulns = () => (dispatch, getState) => {
  const entity = 'vulns';
  const vulnsList = selectVulns(getState());
  const vulnsSelected = selectSelected(entity, getState());
  const vulnCount = selectCount(entity, getState());
  const pageSize = selectRowsPerPage('vulnsAssets', getState());

  if (vulnsSelected.length === vulnCount || vulnsSelected.length >= pageSize) return dispatch(unSelectAll(entity));

  return dispatch({ type: types.SELECT_ALL_ITEMS, entity, list: vulnsList });
};

export const saveTemplateFromAssetVuln = () => {
  return async (dispatch, getState) => {
    const vulnsSelected = selectSelected('vulns', getState());
    const workspaceSelected = selectCurrentWorkspace(getState());

    const getVulnsData = vulnsSelected.map((vuln) => api.manage.fetchById(workspaceSelected, vuln._id));

    const vulnsData = await Promise.all(getVulnsData);

    const save = async (template) => {
      const response = await api.knowledgeBase.createTemplate(template);
      return response;
    };

    const data = vulnsData.map((vuln) => {
      const filteredReferences = vuln.refs.filter(ref => ref.name).map(filteredRef => filteredRef.name);
      const template = {
        cwe: '',
        description: vuln.desc,
        desc: vuln.desc,
        data: vuln.data,
        exploitation: vuln.severity,
        name: vuln.name,
        references: filteredReferences,
        refs: filteredReferences,
        resolution: vuln.resolution,
        impact: vuln.impact,
        policyviolations: vuln.policyviolations,
        customfields: vuln.custom_fields,
        easeofresolution: vuln.easeofresolution,
        external_id: vuln.external_id,
        type: 'vulnerability_template'
      };
      return save(template);
    });

    Promise.allSettled(data).then((results) => {
      const templatesCreated = results ? results.filter((template) => template.status === 'fulfilled').map((t) => t.value) : [];
      const errors = results ? results.filter((template) => template.status === 'rejected').map((t) => t.reason.object) : [];
      dispatch({ type: SAVE_TEMPLATE_MANAGE, templatesCreated, errors });
    });
  };
};

export const deleteVulnsSelected = () => {
  return async (dispatch, getState) => {
    const entity = 'vulns';
    dispatch({ type: types.DELETE_START, entity });
    const state = getState();
    const currentWorkspace = selectCurrentWorkspace(state);
    const currentAsset = selectCurrentHost(state);
    const assetIp = get(currentAsset, 'ip', 0);
    const vulnsSelected = selectSelected(entity, state);
    const vulnsSelectedIDs = vulnsSelected.map(vuln => vuln._id);
    const selectAll = selectSelectAllVulns(getState());

    const hasAdvancedFilter = selectAdvancedFilter(state, 'vulnsAssets');
    const advancedFilterQueryParam = selectVulnsAdvancedFilterQueryParam(assetIp, state);
    const standardQueryParam = selectVulnsQueryParam(assetIp, state);
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    try {
      if (selectAll) {
        await api.manage.deleteAllVulns(currentWorkspace, { filters: queryParam.filters });
      } else {
        if (vulnsSelectedIDs.length === 1) await api.manage.deleteVuln(currentWorkspace, vulnsSelectedIDs[0]);
        else await api.manage.deleteVulns(currentWorkspace, vulnsSelectedIDs);
      }

      dispatch({ type: types.DELETE_SUCCESS, entity });

      dispatch(hideModalDelete());
      dispatch(getVulns(assetIp));
      dispatch(getVulnsCountInWs());
    } catch (err) {
      dispatch({ type: types.DELETE_FAIL, entity });
    }
  };
};

export const applyTemplate = (template, fields) => async (dispatch, getState) => {
  const entity = 'vulns';
  const state = getState();
  const vulnsSelected = selectSelected(entity, state);
  const workspaceSelected = selectCurrentWorkspace(state);
  const vulnsList = selectVulns(state);
  const currentAsset = selectCurrentHost(state);
  const assetIp = get(currentAsset, 'ip', 0);

  const data = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const field in fields) {
    if (fields[field]) {
      if (field === 'exploitation') data.severity = template[field];
      if (field === 'refs') {
        template[field] = template[field].map(name => { return { name, type: 'other' }; });
      }
      if (field === 'customfields') {
        data.custom_fields = template[field];
      }
      data[field] = template[field];
    }
  }

  const vulnPromises = vulnsSelected.map((vuln) => api.manage.updateVuln(workspaceSelected, { _id: vuln._id, ...data }));
  try {
    const res = await Promise.allSettled(vulnPromises);
    res.forEach(({ value }) => {
      const index = vulnsList.findIndex((x) => value._id === x.id || value._id === x._id);
      vulnsList[index] = value;
    });
    dispatch(getVulns(assetIp));
  } catch (error) { }
};

export const getVulnsByAsset = () => {
  return (dispatch, getState) => {
    const currentAsset = selectCurrentHost(getState());
    const assetIp = get(currentAsset, 'ip', '');

    dispatch(getVulns(assetIp));
  };
};

export function showVulnsSidebarFilters (visible) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_VULNS_SIDEBAR_FILTERS, visible });
  };
}

export function setHostForWorking (host) {
  return (dispatch) => {
    dispatch({ type: types.SET_HOST_FOR_WORKING, host });
  };
}

export function getVulnsByAssetId (id) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_DATA_VULNS_START });
    try {
      const workspace = selectCurrentWorkspace(getState());
      const host = await api.hostDetail.fetchById(workspace, id);

      const assetIp = get(host, 'ip', '');
      if (assetIp) dispatch(getVulns(assetIp));

      dispatch(setHostForWorking(host));
    } catch (e) {
      return dispatch(getVulnsFailureCallback());
    }
  };
}

export function setCurrentHostInServices (id) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_DATA_SERVICES_START });
    try {
      const workspace = selectCurrentWorkspace(getState());
      const host = await api.hostDetail.fetchById(workspace, id);
      dispatch(setHostForWorking(host));
    } catch (e) {
      return dispatch(getServicesFailureCallback());
    }
  };
}

export function createVulnFromAsset () {
  return async (dispatch, getState) => {
    dispatch({ type: MANAGE_CREATE_UPDATE_START });

    const state = getState().manageEditCreate;
    const selectedWs = selectCurrentWorkspace(getState());
    const newRefs = state.references.map((ref) => ({ name: ref, type: 'other' }));
    const isWebVuln = state.isWebVuln;
    const servicesTargets = state.targets.filter((target) => target.type === 'Service');
    const filteredTargets = servicesTargets.length > 0 ? servicesTargets : state.targets;
    const vulnsTargets = isWebVuln ? servicesTargets : filteredTargets;
    const hasCvss2 = state.cvss2;
    try {
      const promises = vulnsTargets.map((target) => {
        let vuln = {
          _id: state.id,
          confirmed: state.confirmed,
          custom_fields: state.customFields,
          data: state.data,
          desc: state.description,
          description: state.description,
          easeofresolution: state.easeOfResolution,
          external_id: state.externalId,
          impact: {
            accountability: get(state, 'accountability', false),
            availability: get(state, 'availability', false),
            confidentiality: get(state, 'confidentiality', false),
            integrity: get(state, 'integrity', false)
          },
          method: state.method,
          name: state.name,
          pname: state.paramName,
          params: state.params,
          parent: target.id,
          parent_type: target.type,
          path: state.path,
          policyviolations: state.policies,
          query: state.query,
          refs: newRefs,
          request: state.request,
          resolution: state.resolution,
          response: state.response,
          severity: state.severity,
          status_code: state.status_code ? state.status_code : 0,
          type: state.isWebVuln ? 'VulnerabilityWeb' : 'Vulnerability',
          website: state.website,
          _attachments: state._attachments, // eslint-disable-line no-underscore-dangle
          cve: state.CVE
        };
        if (hasCvss2) vuln = { ...vuln, cvss2: state.cvss2 };
        return api.manage.createVuln(selectedWs, vuln);
      });

      const res = await Promise.all(promises);
      dispatch(getVulnsCountInWs());
      dispatch({ type: types.CREATE_VULN_FROM_ASSET, vulns: res });
      dispatch({ type: RESET_STATE_MANAGE_CREATE_UPDATE });
    } catch (error) {
      return dispatch({ type: types.VULN_ASSET_ERROR, errorMessage: error.message });
    }
  };
}

export const setBulkUpdateField = (field) => (dispatch) => dispatch({ type: types.VULN_ASSET_SET_BULK_UPDATE_FIELD, field });

export const setBulkUpdateValue = (value) => (dispatch) => dispatch({ type: types.VULN_ASSET_SET_BULK_UPDATE_VALUE, value });

export const addBulkUpdateValue = (value) => (dispatch) => dispatch({ type: types.VULN_ASSET_ADD_BULK_UPDATE_VALUE, value });

export const removeBulkUpdateValue = (value) => (dispatch) => dispatch({ type: types.VULN_ASSET_REMOVE_BULK_UPDATE_VALUE, value });

export const refreshVulnsList = (vulnsList, vulnsSelected, vulnDetail) => (dispatch, getState) => {
  dispatch({
    type: types.VULNS_ASSET_REFRESH_VULNS, vulnsList, vulnsSelected, vulnDetail, vulnsCount: selectCount('vulns', getState())
  });
};

export const setCustomAttributeField = (customAttribute) => (dispatch) => {
  dispatch({ type: types.VULN_ASSET_SET_BULK_CUSTOM_ATTRIBUTE, customAttribute });
};

export function bulkUpdateVulnsFromAsset () {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch({ type: types.VULNS_CONFIRMATION_CHANGE_START });

    try {
      const vulnsList = selectVulns(state);
      const vulnsSelected = selectSelected('vulns', state);
      const vulnDetail = selectDetail('vulns', state);
      const field = selectModalBulkUpdateField(state);
      const value = selectModalBulkUpdateValue(state);
      const workspaceSelected = selectCurrentWorkspace(state);
      const selectAll = selectSelectAllVulns(state);
      const vulnIDs = vulnsSelected.map((v) => v._id);
      const host = selectCurrentHost(state);
      const customAttribute = selectBulkUpdateCustomAttribute(state);
      const customAttributeType = get(customAttribute, 'field_type', '');
      const customAttributeFieldName = get(customAttribute, 'field_name', '');
      const customAttributeValue = ((customAttributeType === 'choice') && (value === '(Empty)')) ? '' : value;

      const hasAdvancedFilter = selectAdvancedFilter(state, 'vulnsAssets');
      const advancedFilterQueryParam = selectVulnsAdvancedFilterQueryParam(host.ip, state);
      const standardQueryParam = selectVulnsQueryParam(host.ip, state);
      const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

      let data = {};

      if (field === 'references') data = { refs: value.map((v) => ({ name: v, type: 'other' })) };
      else if (field === 'policy violations') data = { policyviolations: value };
      else if (field === 'description') data = { description: value, desc: value };
      else if (field === 'impact') data = getImpactData(value, vulnsSelected);
      else if (field === 'custom_fields' && (vulnsSelected?.length > 1)) data = { custom_fields: { [customAttributeFieldName]: customAttributeValue } };
      else if (field === 'custom_fields' && (vulnsSelected?.length === 1)) data = { custom_fields: { ...vulnsSelected[0].custom_fields, [customAttributeFieldName]: customAttributeValue } };
      else data = { [field]: value };

      const isDetailVulnSelected = !isEmpty(vulnDetail) && vulnsSelected.some((selectedVuln) => selectedVuln._id === vulnDetail?._id);

      const updatedVulnDetail = isDetailVulnSelected ? { ...vulnDetail, ...data } : { ...vulnDetail };

      if (selectAll) {
        await api.manage.updateAllVulns(workspaceSelected, { filters: queryParam.filters }, data);

        const updatedVulnsList = vulnsList.map((vuln) => ({ ...vuln, ...data }));
        const updatedSelectedVulns = vulnsSelected.map((vuln) => ({ ...vuln, ...data }));

        dispatch(refreshVulnsList(updatedVulnsList, updatedSelectedVulns, updatedVulnDetail));
      } else {
        const payload = { ids: vulnIDs, ...data };

        await api.manage.updateVulns(workspaceSelected, payload);

        const updatedVulnsList = vulnsList.map((vuln) => {
          const isSelected = vulnsSelected.some((selectedVuln) => selectedVuln._id === vuln._id);
          if (isSelected) return { ...vuln, ...data };
          return vuln;
        });

        const updatedSelectedVulns = vulnsSelected.map((vuln) => ({ ...vuln, ...data }));

        dispatch({ type: types.VULN_ASSET_SET_BULK_UPDATE_SUCCESS });
        dispatch(refreshVulnsList(updatedVulnsList, updatedSelectedVulns, updatedVulnDetail));
      }

      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      dispatch({ type: types.VULN_ASSET_BULK_UPDATE_FINISHED });
    } catch (e) {
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      dispatch({ type: types.VULN_ASSET_ERROR, errorMessage: e.message || 'An error occured while updating vulns' });
    }
  };
}

export const showContextMenu = (show, XPos, YPos) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentXPos = selectContextMenuXPos(state);
    const currentYPos = selectContextMenuYPos(state);
    const newXPos = XPos ? (XPos + 1) : currentXPos;
    const newYPos = YPos ? (YPos + 1) : currentYPos;

    dispatch({
      type: types.VULNS_ASSET_SHOW_CONTEXT_MENU, show, contextMenuXPos: newXPos, contextMenuYPos: newYPos
    });
  };
};

export const setContextMenuFilter = (filterKey, selectedVuln) => {
  return (dispatch, getState) => {
    const currentAsset = selectCurrentAsset(getState());
    const ip = get(currentAsset, 'ip', '');
    const newFilter = contextMenufilteringFunctions[filterKey](selectedVuln);
    if (newFilter) dispatch(setFilter('vulnsAssets', newFilter));
    dispatch(getVulns(ip));
  };
};

export const showBulkUpdateModal = (bulkUpdateField, bulkUpdateValue) => {
  return (dispatch) => {
    dispatch(setBulkUpdateField(bulkUpdateField));
    dispatch(setBulkUpdateValue(bulkUpdateValue));
    dispatch(openModal(MODAL_MANAGE_BULK_UPDATE));
  };
};

export const bulkAddComment = () => {
  return (dispatch, getState) => {
    const state = getState();
    const vulnsSelected = selectSelected('vulns', state);
    const text = selectModalBulkUpdateValue(state);
    const vulnsIds = vulnsSelected?.map((vuln) => vuln._id);

    vulnsIds.forEach((id) => {
      const data = {
        text,
        object_type: 'vulnerability',
        object_id: id,
        create_date: new Date()
      };
      dispatch(addComment(data, true));
    });
  };
};

export const getVulnRefs = (id) => async (dispatch, getState) => {
  const workspaceSelected = selectCurrentWorkspace(getState());
  try {
    const response = await api.manage.fetchById(workspaceSelected, id);
    const refs = get(response, 'refs', []);
    dispatch(showBulkUpdateModal('references', refs.map((r) => r.name)));
  } catch (e) {
    dispatch({ type: types.VULN_ASSET_ERROR, errorMessage: e.message || 'An error has occurred' });
  }
};

export function setVulnsTags (tagsToAdd = [], tagsToRemove = []) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const vulnsSelected = selectSelected('vulns', state);
      const vulnsList = selectVulns(state);
      const selectedVulnsIds = vulnsSelected.map((v) => (v._id));
      const workspace = state.faraday.workspaceSelected;
      const selectAll = selectSelectAllVulns(state);
      const currentAsset = selectCurrentHost(state);
      const assetIp = get(currentAsset, 'ip', 0);

      const hasAdvancedFilter = selectAdvancedFilter(state, 'vulnsAssets');
      const advancedFilterQueryParam = selectVulnsAdvancedFilterQueryParam(assetIp, state);
      const standardQueryParam = selectVulnsQueryParam(assetIp, state);
      const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

      if (selectAll) {
        const data = {
          tags_to_add: tagsToAdd,
          tags_to_remove: tagsToRemove
        };

        await api.manage.setAllVulnsTags(workspace, { filters: queryParam.filters }, data);
      } else {
        const data = {
          vulnerability_ids: selectedVulnsIds,
          tags_to_add: tagsToAdd,
          tags_to_remove: tagsToRemove
        };

        await api.manage.setVulnsTags(workspace, data);
      }

      const newTags = tagsToAdd.filter((tag) => !tagsToRemove.includes(tag));

      selectedVulnsIds.forEach((id) => {
        const vuln = find(vulnsSelected, { _id: id });
        const newTagsList = newTags.filter((tag) => !vuln.tags.includes(tag));
        vuln.tags = [
          ...vuln.tags.filter((tag) => !tagsToRemove.includes(tag)),
          ...newTagsList
        ];

        const indexVulnsList = findIndex(vulnsList, { _id: vuln._id });
        if (indexVulnsList > -1 && !isEmpty(vulnsList)) vulnsList.splice(indexVulnsList, 1, vuln);

        const indexVulnsSelected = findIndex(vulnsSelected, { _id: id });
        if (indexVulnsList > -1 && !isEmpty(vulnsSelected)) vulnsSelected.splice(indexVulnsSelected, 1, vuln);
      });

      const vulnDetail = selectDetail('vulns', state);
      if (!!vulnDetail && selectedVulnsIds.includes(vulnDetail.id)) {
        vulnDetail.tags = [
          ...vulnDetail.tags.filter((tag) => !tagsToRemove.includes(tag)),
          ...newTags
        ];
      }

      dispatch({ type: types.SET_TAGS_SUCCESS, entity: 'vulns' });

      if (vulnsSelected.length === 1) {
        dispatch({ type: types.TAGS_UPDATE_DETAIL, entity: 'vulns', detail: vulnDetail });
      }
      dispatch(getTags());
      dispatch(getHostsTags());
    } catch (e) {
      dispatch({ type: types.SET_TAGS_FAIL, entity: 'vulns', error: e.message });
    }
  };
}

export function addVulnTag (tag) {
  return (dispatch) => {
    dispatch(setVulnsTags([tag], []));
  };
}

export function removeVulnTag (tag) {
  return (dispatch) => {
    dispatch(setVulnsTags([], [tag]));
  };
}

export function setServicesTags (tagsToAdd = [], tagsToRemove = []) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const servicesSelected = selectSelected('services', state);
      const servicesList = selectServices(state);
      const selectedServicesIds = servicesSelected.map((service) => (service._id));
      const workspace = selectCurrentWorkspace(state);

      const data = {
        service_ids: selectedServicesIds,
        tags_to_add: tagsToAdd,
        tags_to_remove: tagsToRemove
      };

      await api.service.setServicesTags(workspace, data);

      const newTags = tagsToAdd.filter((tag) => !tagsToRemove.includes(tag));

      selectedServicesIds.forEach((id) => {
        const service = find(servicesSelected, { _id: id });
        const newTagsList = newTags.filter((tag) => !service.tags.includes(tag));
        service.tags = [
          ...service.tags.filter((tag) => !tagsToRemove.includes(tag)),
          ...newTagsList
        ];

        const indexServicesList = findIndex(servicesList, { _id: service._id });
        if (indexServicesList > -1 && !isEmpty(servicesList)) servicesList.splice(indexServicesList, 1, service);

        const indexServicesSelected = findIndex(servicesSelected, { _id: id });
        if (indexServicesList > -1 && !isEmpty(servicesSelected)) servicesSelected.splice(indexServicesSelected, 1, service);
      });

      const serviceDetail = selectDetail('services', state);
      if (!!serviceDetail && selectedServicesIds.includes(serviceDetail.id)) {
        serviceDetail.tags = [
          ...serviceDetail.tags.filter((tag) => !tagsToRemove.includes(tag)),
          ...newTags
        ];
      }
      dispatch({ type: types.SET_TAGS_SUCCESS, entity: 'services' });

      if (servicesSelected.length === 1) {
        dispatch({ type: types.TAGS_UPDATE_DETAIL, entity: 'services', detail: serviceDetail });
      }

      dispatch(getTags());
      dispatch(getHostsTags());
      dispatch(getServicesTags());
    } catch (e) {
      dispatch({ type: types.SET_TAGS_FAIL, entity: 'services', error: e.message });
    }
  };
}

export function addServiceTag (tag) {
  return (dispatch) => {
    dispatch(setServicesTags([tag], []));
  };
}

export function removeServiceTag (tag) {
  return (dispatch) => {
    dispatch(setServicesTags([], [tag]));
  };
}

export function refreshVulnEnrichmentAsset () {
  return async (dispatch, getState) => {
    dispatch({ type: types.VULNS_ASSET_REFRESH_VULN_ENRICHMENT_REQUEST });

    const workspace = selectCurrentWorkspace(getState());
    const id = selectVulnDetailId(getState());
    const vulnsList = selectVulns(getState());
    const vulnsCount = selectCount('vulns', getState());

    try {
      const newList = vulnsList;
      const vulnAfter = await api.manage.refreshEnrichment(workspace, id);

      const index = vulnsList.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
      newList[index] = vulnAfter;

      dispatch({ type: types.UPDATE_VULN_ASSET_PREVIEW_SUCCESS, data: newList, vulnsCount, vuln: vulnAfter });
      dispatch({ type: types.VULNS_ASSET_REFRESH_VULN_ENRICHMENT_SUCCESS });
    } catch (e) {
      dispatch({
        type: types.VULNS_ASSET_REFRESH_VULN_ENRICHMENT_FAILURE,
        errorMessage: 'Faraday was not able to determine the requested Risk Information. Please try again later.'
      });
    }
  };
}

export function updateCvssFromAsset (tempCVSS) {
  return (dispatch, getState) => {
    const currentVuln = selectDetail('vulns', getState());
    const currentCVSSValue = currentVuln.cvss3.vector_string;

    const parsedTempCVSS = {
      base_score: Number(tempCVSS.score),
      base_severity: tempCVSS.severity.toLowerCase(),
      vector_string: tempCVSS.vector
    };
    dispatch({ type: MANAGE_SET_TEMP_CVSS, tempCVSS: parsedTempCVSS });

    const sameVector = currentCVSSValue === tempCVSS.vector;
    if ((!parsedTempCVSS.vector_string.includes('_')) && (!sameVector)) {
      dispatch(updateVulnFromAsset(currentVuln, 'cvss3', { vector_string: tempCVSS.vector }));
    }
  };
}

export const selectParsedIssueTrackers = (state) => {
  const currentVuln = selectDetail('vulns', state);
  const issuetracker = get(currentVuln, 'issuetracker', '');
  const parsedIssueTrackers = [];

  for (const tool in issuetracker) {
    if (issuetracker[tool].length >= 0) {
      issuetracker[tool].forEach((issue) => {
        parsedIssueTrackers.push({ url: issue.url, text: tool.toUpperCase() });
      });
    }
    if (!Array.isArray(issuetracker[tool])) {
      parsedIssueTrackers.push({ url: issuetracker[tool].url, text: tool.toUpperCase() });
    }
  }

  return parsedIssueTrackers;
};

export const updateCvssV4FromAsset = (vector_string) => (dispatch, getState) => {
  const currentVuln = selectDetail('vulns', getState());
  dispatch(updateVulnFromAsset(currentVuln, 'cvss4', { vector_string }));
};
