import React from 'react';
import { useDispatch } from 'react-redux';
import { WithPermission } from 'Common/Components/WithPermission';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { openModal } from 'store/modals/actions';
import { MODAL_WORKSPACE_CREATE } from 'store/modals/modals';
import { AddWsButton } from '../../../styled';

// eslint-disable-next-line import/prefer-default-export
export const WsAddButton = () => {
  const dispatch = useDispatch();
  return (
    <WithPermission permission={ FEATURE_WORKSPACES } type={ PERMISSION_TYPES.CREATE }>
      <AddWsButton text="Add Workspace" onClick={ () => dispatch(openModal(MODAL_WORKSPACE_CREATE)) } />
    </WithPermission>
  );
};
