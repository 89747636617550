import styled from 'styled-components';
import { ReactComponent as _Trash } from 'Images/tool_trash.svg';
import { ReactComponent as _Info } from 'Images/info.svg';

export const Trash = styled(_Trash)`
`;
Trash.displayName = 'Trash';

export const Info = styled(_Info)`
`;
Info.displayName = 'Info';

export const IconWrapper = styled.div`
  display: none;
  margin: auto 0px auto auto;
  padding-bottom: 4px;
  >:not(:last-child) {
      margin-right: 10px;
  }
`;
IconWrapper.displayName = 'IconWrapper';

export const NotAllowedWrapper = styled.div`
  background-color: #edf2f7;
  height: 39px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
NotAllowedWrapper.displayName = 'NotAllowedWrapper';

export const NotAllowedLabel = styled.div`
  color: #0082ed;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
NotAllowedLabel.displayName = 'NotAllowedLabel';

export const Wrapper = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  height: 39px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  >:not(:last-child) {
      margin-right: 25px;
  }
  &:hover {
    & ${IconWrapper} {
      display: block;
    }
  }
`;
Wrapper.displayName = 'Wrapper';
