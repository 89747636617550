import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { markDuplicates } from 'store/Manage/duplicateActions';
import IconButton from 'Common/Components/IconButton';
import { selectVulnsSelected, selectShowDetail, selectSelectedMainVulns } from 'store/Manage/selectors';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { unSelectAll } from 'store/Manage/actions';
import get from 'lodash/get';
import { FEATURE_DUPLICATES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import DuplicateIcon from './styled';
import ModalWarning from 'Common/Components/ModalWarning';

const DuplicateButton = ({ hasVulns, showModal }) => {
  const dispatch = useDispatch();
  const readonly = useSelector(selectIsReadOnly);
  const selectedVulns = useSelector(selectVulnsSelected);
  const oneOrNoneVulnsSelected = selectedVulns.length <= 1;
  const showVulnDetail = useSelector(selectShowDetail);
  const canUseDuplicates = useSelector((state) => selectAllowedByFeature(state, FEATURE_DUPLICATES, PERMISSION_TYPES.CREATE) && selectAllowedByRole(state, FEATURE_DUPLICATES, PERMISSION_TYPES.CREATE));
  const [showWarning, setShowWarning] = useState();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'vuln.detail.duplicates.markAsDuplicates' });
  const warningMessage = intl.formatMessage({ id: 'vuln.duplicates.modalWarning.message' });
  const selectedVulnsIDs = selectedVulns.map((v) => v.id);
  const mainsRelatedToSelection = useSelector(selectSelectedMainVulns);
  const mergingGroups = mainsRelatedToSelection.length > 1;
  const enabled = !readonly && !showVulnDetail && canUseDuplicates && hasVulns;

  const setDuplicates = () => {
    if (mergingGroups) {
      showModal();
    } else {
      const mainID = get(mainsRelatedToSelection, '[0].id', null);
      dispatch(markDuplicates(selectedVulnsIDs, mainID));
      dispatch(unSelectAll());
    }
  };

  const onClick = () => {
    if (enabled) {
      if (!oneOrNoneVulnsSelected) setDuplicates();
      else setShowWarning(true);
    }
  };
  const onCloseWarning = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <DuplicateIcon /> } title={ title } onClick={ onClick } disabled={ !enabled } />
      { showWarning && <ModalWarning onCloseCallback={ onCloseWarning } title={ 'Mark as Duplicate' } description={ warningMessage } /> }
    </>
  );
};

DuplicateButton.propTypes = {
  showModal: PropTypes.func.isRequired
};

export default DuplicateButton;
