import React from 'react';
import BulkMarkdownEditor from './BulkMarkdownEditor';
import NameInput from './NameInput';
import ItemList from './ItemList';
import Impact from './Impact';
import BulkComment from './BulkComment';
import Input from './Input';
import ChoiceInput from './ChoiceInput';
import Date from './Date';

const BULK_UPDATE_COMPONENTS = {
  'description': <BulkMarkdownEditor />,
  'data': <BulkMarkdownEditor />,
  'resolution': <BulkMarkdownEditor />,
  'request': <BulkMarkdownEditor />,
  'response': <BulkMarkdownEditor />,
  'name': <NameInput />,
  'references': <ItemList />,
  'impact': <Impact />,
  'policy violations': <ItemList />,
  'comment': <BulkComment />,
  'str': <Input />,
  'int': <Input />,
  'choice': <ChoiceInput />,
  'date': <Date />,
  'markdown': <BulkMarkdownEditor />
};

export default BULK_UPDATE_COMPONENTS;
