import React from 'react';
import {
  Wrapper,
  Cellphone, Label, QrImage
} from './styled';

const QrCode = () => (
  <Wrapper>
    <QrImage />
    <Label>
      <Cellphone />
      Scan this code with your preferred authentication app.
    </Label>
  </Wrapper>
);

export default QrCode;
