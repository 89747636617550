import React, { useState } from 'react';
import {
  Wrapper, SectionTitle,
  Header, ExpandedIcon, CollapsedIcon,
  Content
} from './styled';

const ExpandableWrapper = ({ sectionTitle, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Wrapper>
      <Header onClick={ () => setIsExpanded(!isExpanded) }>
        <SectionTitle>{sectionTitle}</SectionTitle>
        { isExpanded ? <ExpandedIcon /> : <CollapsedIcon /> }
      </Header>
      { isExpanded
        ? <Content>
            { content }
          </Content>
        : null
      }
    </Wrapper>
  );
};

export default ExpandableWrapper;
