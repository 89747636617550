import styled from 'styled-components';
import colors from 'Styles/colors';

export const ColUserName = styled.div`
  display: flex;
  align-items: center;
  font-size: 14.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.11px;
  color: ${(props) => (props.active ? colors.dark2 : colors.grey12)};
  &:after {
    ${({ isSaml }) => isSaml && `
      border-radius: 2px;
      box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.13);
      background-color: #63758d;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      margin-left: 15px;
      height: 24px;
      width: 44px;
      content: 'SAML';
      font-size: 9.5px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-align: center;
      color: ${colors.white};
    `}
    }
`;
ColUserName.displayName = 'ColUserName';

export const ColUserRole = styled.div`
  font-size: 14.5px;
  font-weight: 300;
  height: 25px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.11px;
  color: white !important;
  font-size: 12.5px;
  border-radius: 10px;
  padding-top: 5px;
  background-color: ${(props) => {
    const { role, active } = props;
    if (active) {
      if (role === 'admin') return colors.warmPurple;
      if (role === 'pentester') return colors.redPink;
      if (role === 'client') return colors.blueCerulean;
      if (role === 'asset_owner') return colors.softBlue;
      return colors.softBlue;
    }
    return 'transparent';
  }};
  width: ${(props) => {
    const { role } = props;

    if (role === 'admin') return '99px';
    if (role === 'pentester') return '76px';
    if (role === 'asset_owner') return '99px';
    return '55px';
  }};
  padding-left: ${(props) => {
    const { role } = props;

    if (role === 'admin') return '10px';
    if (role === 'pentester') return '10px';
    if (role === 'asset_owner') return '10px';
    return '10px';
  }};
`;
ColUserRole.displayName = 'ColUserRole';

export const ColUserLastSeen = styled.div`
  font-size: 11.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${colors.blueGrey};
`;
ColUserLastSeen.displayName = 'ColUserLastSeen';

export const ColUserIp = styled.div`
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${colors.dark5};
  margin-bottom: 3px;
`;
ColUserIp.displayName = 'ColUserIp';

export const ColUser2faStatus = styled.div`
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${(props) => {
    const { status } = props;

    if (status === 'active') return colors.green1;
    if (status !== 'disabled') return colors.orange2;
    return colors.grey12;
  }};
`;
ColUser2faStatus.displayName = 'ColUser2faStatus';

export const SwitchContainer = styled.div`
  text-align: center;

  .react-switch-bg {
    border: 1px solid ${(props) => (props.checked ? colors.green3 : colors.grey16)};
  }

  .react-switch-handle {
    border: 1px solid ${(props) => (props.checked ? colors.green3 : colors.grey16)};
  }
`;
SwitchContainer.displayName = 'SwitchContainer';

export const ColWorkspaces = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.dark5};
  font-size: 12.5px;
  ${({ allLabel }) => allLabel && 'font-weight: 700;'};
`;
ColWorkspaces.displayName = 'ColWorkspaces';

export const TableWrapper = styled.div`
  height: calc(100% - 46px);
`;
TableWrapper.displayName = 'TableWrapper';
