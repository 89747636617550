import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedEntity, selectFilterHistory, selectLabeledHistory } from 'store/Filters/selectors';
import { removeFilter } from 'store/Filters/actions';
import PropTypes from 'prop-types';
import {
  CloseIcon, TagContainer, TagLabel, FilterTagContainer, Label
} from './styled';
import moment from 'moment';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';

export const Tag = ({
  showDelete, value, onDelete, type, valSelected, filterKey, customAttributes, isOneOf
}) => {
  const selectedEntity = useSelector(getSelectedEntity);
  const isDate = type === 'val' && filterKey.toLowerCase().includes('date');
  const isCustomField = type === 'key' && filterKey.toLowerCase().includes('custom_field');
  const isPermission = type === 'val' && filterKey.toLowerCase().includes('permission');
  const isStatus = type === 'val' && filterKey.toLowerCase().includes('status') && (selectedEntity === 'workspaces');
  const permissionLabel = value === 'True' ? 'Locked' : 'Unlocked';
  const statusLabel = value === 'True' ? 'Active' : 'Archived';
  const label = isCustomField ? customAttributes.find((cf) => cf.field_name === value.split('->')[1]).field_display_name : '';
  const isOneOfVal = type === 'val' && isOneOf;
  const valueParsed = () => {
    if (isDate) return moment(value).format('L');
    else if (isCustomField) return label;
    else if (isOneOfVal) return value.split(',');
    else if (isPermission) return permissionLabel;
    else if (isStatus) return statusLabel;
    else return value;
  };

  if (!value) return null;
  return (
    <TagContainer type={ type } valSelected={ valSelected } isOneOfVal={ isOneOfVal }>
      { isOneOfVal
        ? valueParsed()?.map((v, i) => <Label key={ `filter_value__${value}_${i}` }>{ v }</Label>)
        : <TagLabel>
            {valueParsed()}
          </TagLabel>
      }

      { showDelete && <CloseIcon onClick={ onDelete } />}
    </TagContainer>
  );
};

export const FilterTags = ({ onSearch }) => {
  const dispatch = useDispatch();
  const selectedEntity = useSelector(getSelectedEntity);
  const filterHistory = useSelector((state) => selectFilterHistory(selectedEntity, state));
  const labeledFilters = useSelector((state) => selectLabeledHistory(selectedEntity, state));
  const customAttributes = useSelector(selectCustomAttributes);
  const deleteTag = (item) => {
    const isOneOf = item.op === 'is one of';
    const originalFilter = filterHistory.filter((f) => {
      if (isOneOf) {
        return (f.name === item.originalName) && (f.op === item.originalOp) && (item.originalOpVal ? (f.val.op === item.originalOpVal) : (f.op === item.originalOp));
      } else {
        if (item.originalOpVal) {
          return (f.name === item.originalName) && (f.op === item.originalOp) && (f.val.op === item.originalOpVal) && ((item.originalOpVal === 'ilike') ? (f.val.val === `%${item.val}%`) : (f.val.val.toLowerCase() === item.val.toLowerCase()));
        } else {
          return (f.name === item.originalName) && (f.op === item.originalOp) && ((item.originalOp === 'ilike') ? (f.val === `%${item.val}%`) : (f.val.toLowerCase() === item.val.toLowerCase()));
        }
      }
    });
    dispatch(removeFilter(selectedEntity, isOneOf ? originalFilter[0] : originalFilter[0], isOneOf));
    onSearch();
  };

  return (
    labeledFilters.map((filter, i) => (
      <FilterTagContainer key={ `${JSON.stringify(filter)}_${i}` }>
        { filter.name.includes('custom_fields') && <Tag type="mainKey" filterKey={ filter.name.split('->')[0] } value="Custom Attribute" /> }
        <Tag type="key" filterKey={ filter.name } value={ filter.name } customAttributes={ customAttributes } />
        <Tag type="op" filterKey={ filter.name } value={ filter.op } valSelected={ filter.val } />
        <Tag type="val" filterKey={ filter.name } value={ filter.val } showDelete onDelete={ () => deleteTag(filter) } isOneOf={ filter.op === 'is one of' } />
      </FilterTagContainer>
    )));
};

export const DraftTag = ({ draftTag }) => (
  <>
    <Tag type="key" value={ draftTag.name } />
    <Tag type="op" value={ draftTag.op } />
  </>
);

Tag.propTypes = {
  showDelete: PropTypes.bool,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  value: PropTypes.string,
  filterKey: PropTypes.string
};

Tag.defaultProps = {
  onDelete: null,
  showDelete: false,
  value: '',
  filterKey: ''
};

DraftTag.propTypes = {
  draftTag: PropTypes.object.isRequired
};
