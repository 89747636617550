import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendVulnToSolarWindsContext } from 'store/Settings/solarWindsActions';
import first from 'lodash/first';
import { useIntl } from 'react-intl';
import {
  Wrapper, Title, Subtitle, Form, WrapperField, Label, Input, Footer, Close, Save, Blue
} from './styled';

const SolarWinds = ({ onClose, vulnsSelected, vulnId, isVulnDetailOpen, entity }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [clientId, setCliendId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [locationId, setLocationId] = useState('');

  const onSave = () => {
    dispatch(sendVulnToSolarWindsContext(clientId, categoryId, locationId, vulnId, isVulnDetailOpen, entity));
    onClose();
  };

  const getText = () => ((vulnsSelected.length === 1) ? first(vulnsSelected).name : `${vulnsSelected.length} vulnerabilities`);

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'sendToTool.solarWinds.title' }) }</Title>
      <Subtitle>
        { intl.formatMessage({ id: 'sendToTool.solarWinds.message.youAreSending' }) }
        <Blue>{ getText() }</Blue>
        { intl.formatMessage({ id: 'sendToTool.solarWinds.message.pleaseFill' }) }
      </Subtitle>
      <Form>
        <WrapperField>
          <Label>{ intl.formatMessage({ id: 'sendToTool.solarWinds.form.clientId.title' }) }</Label>
          <Input placeholder={ intl.formatMessage({ id: 'sendToTool.solarWinds.form.clientId.placeholder' }) } value={ clientId } onChange={ (e) => setCliendId(e.target.value) } />
        </WrapperField>
        <WrapperField>
          <Label>{ intl.formatMessage({ id: 'sendToTool.solarWinds.form.categoryId.title' }) }</Label>
          <Input placeholder={ intl.formatMessage({ id: 'sendToTool.solarWinds.form.categoryId.placeholder' }) } value={ categoryId } onChange={ (e) => setCategoryId(e.target.value) } />
        </WrapperField>
        <WrapperField>
          <Label>{ intl.formatMessage({ id: 'sendToTool.solarWinds.form.locationId.title' }) }</Label>
          <Input placeholder={ intl.formatMessage({ id: 'sendToTool.solarWinds.form.locationId.placeholder' }) } value={ locationId } onChange={ (e) => setLocationId(e.target.value) } />
        </WrapperField>
      </Form>
      <Footer>
        <Close onClick={ onClose }>{ intl.formatMessage({ id: 'sendToTool.solarWinds.form.close' }) }</Close>
        <Save onClick={ onSave }>{ intl.formatMessage({ id: 'sendToTool.solarWinds.form.save' }) }</Save>
      </Footer>
    </Wrapper>
  );
};

export default SolarWinds;
