import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import { setModalAssignUsersStatus } from 'Screens/Contextualization/Workspaces/actions/Actions';
import IconButton from 'Common/Components/IconButton';
import Users from './styled';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';

// eslint-disable-next-line import/prefer-default-export
export const AssignUsersButton = () => {
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState();
  const selectedWs = useSelector(selectWorkspacesSelected);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.UPDATE));
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'workspaces' });

  const onClick = () => {
    if (canUpdate) {
      if ((selectedWs.length > 0)) dispatch(setModalAssignUsersStatus(true));
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Users disabled={ !canUpdate } /> } title="Assign users" onClick={ onClick } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Assign users" description={ message } /> }
    </>
  );
};
