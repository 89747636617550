import styled from 'styled-components';
import { TagsWrapper, Tag } from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/Tags/components/TagItems/styled';

export const Wrapper = styled(TagsWrapper)`
`;
Wrapper.displayName = 'Wrapper';

export const TagItem = styled(Tag)`
`;
TagItem.displayName = 'TagItem';
