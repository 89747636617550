/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import DateFormatter from 'Common/Components/DateFormatter';
import ReactMarkdown from 'react-markdown';
import {
  CommentsItem,
  CommentsDate,
  CommentsContent
} from './styled';

const parseNewLines = (text) => text.replace(/\n/gi, '\n &nbsp;');

const SystemComment = ({ comment }) => (
  <CommentsItem>
    <CommentsDate children={ <DateFormatter date={ comment.create_date } /> } />
    <CommentsContent>
      <ReactMarkdown children={ parseNewLines(comment.text) } />
    </CommentsContent>
  </CommentsItem>
);

SystemComment.propTypes = {
  comment: PropTypes.shape({
    create_date: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired
};

export default SystemComment;
