import styled from 'styled-components';
import { ReactComponent as MainDuplicateSVG } from 'Images/main-duplicate.svg';

export const DuplicateWrapper = styled.span`
  display: flex;
  flex-direction: row;
  width: 75px;
  height: 25px;
  border-radius: 6px;
  background-color: #bec8d2;
  padding: 3px 8px;
  margin-right: 10px;
`;
DuplicateWrapper.displayName = 'DuplicateWrapper';

export const DuplicateLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #264d6d;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
  user-select: none;
`;
DuplicateLabel.displayName = 'DuplicateLabel';

export const MainDuplicateIcon = styled(MainDuplicateSVG)`
  margin: auto 0 auto 0;
  cursor: default;
  & path{
    fill: #264d6d;
  }  
`;
MainDuplicateIcon.displayName = 'MainDuplicateIcon';
