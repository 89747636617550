import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { openTaskDetail, markTaskToDelete } from 'store/Tasks/actions';
import { openModal } from 'store/modals/actions';
import { MODAL_TASKS_DELETE } from 'store/modals/modals';
import Skeleton from 'Common/Components/Skeleton';
import { selectProjectById } from 'store/Projects/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_TASKS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import ActionButton from '../../ActionButton';
import {
  CommentIcon, EditIcon, DeleteIcon, CellWrapper, ActionButtonsWrapper
} from '../styled';

const TaskActions = ({ isMilestone, isFetching, rowInfo }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const tooltipComment = intl.formatMessage({ id: 'planner.task.commentTask' });
  const tooltipEdit = intl.formatMessage({ id: 'planner.task.editTask' });
  const tooltipDelete = intl.formatMessage({ id: 'planner.task.deleteTask' });
  const canDelete = useSelector((state) => selectAllowedByRole(state, FEATURE_TASKS, PERMISSION_TYPES.DELETE));
  const task = get(rowInfo, 'original', null);
  const projectId = get(task, 'projectId', null);
  const project = useSelector((state) => selectProjectById(state, projectId));

  const onDelete = () => {
    if (task && project) {
      dispatch(markTaskToDelete(task));
      dispatch(openModal(MODAL_TASKS_DELETE));
    }
  };

  const onEdit = () => (task && project) && dispatch(openTaskDetail(project.id, task.id, 'general'));
  const onComment = () => (task && project) && dispatch(openTaskDetail(project.id, task.id, 'comments'));

  if (isFetching) return <Skeleton />;
  return (
    <CellWrapper>
      <ActionButtonsWrapper className="taskActionButton">
        { !isMilestone && <ActionButton title={ tooltipComment } onClick={ onComment } icon={ <CommentIcon /> } /> }
        <ActionButton title={ tooltipEdit } onClick={ onEdit } icon={ <EditIcon /> } />
        <ActionButton show={ canDelete } title={ tooltipDelete } onClick={ onDelete } icon={ <DeleteIcon /> } />
      </ActionButtonsWrapper>
    </CellWrapper>

  );
};

TaskActions.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  rowInfo: PropTypes.instanceOf(Object).isRequired
};

export default TaskActions;
