import styled from 'styled-components';

const WrapperTemplate = styled.div`
  width: calc(92% / 2);
  padding-top: 20px;
  margin-bottom: 30px;
`;
WrapperTemplate.displayName = 'WrapperTemplate';

export default WrapperTemplate;
