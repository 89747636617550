import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { removeFieldItem } from 'store/Tasks/actions';
import RemoveItem from '../RemoveItem';
import {
  ItemWrapper, CustomItem, Name
} from './styled';

const TaskItem = ({ item, canDelete }) => {
  const dispatch = useDispatch();
  const removeItem = (id) => dispatch(removeFieldItem('dependencies', id));

  return (
    <ItemWrapper>
      <CustomItem canDelete={ canDelete }>
        <Name>{item.name}</Name>
        <RemoveItem removeItem={ removeItem } id={ item.id } />
      </CustomItem>
    </ItemWrapper>
  );
};

TaskItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  canDelete: PropTypes.bool
};

TaskItem.defaultProps = {
  canDelete: false
};

export default TaskItem;
