import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import ExpandableWrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_SERVICES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const ID = 'description';

const Description = ({ serviceId, description, updateService }) => {
  const intl = useIntl();
  const readOnly = useSelector(selectIsReadOnly);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_SERVICES, PERMISSION_TYPES.UPDATE));
  const disabled = readOnly || !canUpdate;
  const isExpanded = useExpandable('general', ID, description);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.description' }) } tab="general" id={ ID }>
      <MarkdownEditor
        value={ description }
        onBlur={ (value) => updateService(serviceId, { description: value }) }
        disabled={ disabled }
        minHeight={ 49 }
      />
    </ExpandableWrapper>
  );
};

export default Description;
