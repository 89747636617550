import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as ErrorMark } from 'Images/error-mark.svg';

export const StyledLabel = styled.div`
  color: ${colors.redPink};
  margin-left: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.0;
`;
StyledLabel.displayName = 'StyledLabel';

export const CustomErrorMark = styled(ErrorMark)`
  cursor: default;
  flex: 0 0 25px;
  height: 25px;
  `;
CustomErrorMark.displayName = 'CustomErrorMark';
