import { selectPermissions } from 'store/Faraday/selectors';
import get from 'lodash/get';

export const selectAllowedByRole = (state, feature, method) => {
  const permissions = selectPermissions(state);
  return get(permissions, `${feature}.${method}.allowed_by_role`, false);
};

export const selectAllowedByFeature = (state, feature, method) => {
  const permissions = selectPermissions(state);
  return get(permissions, `${feature}.${method}.allowed_by_feature_flag`, false);
};
