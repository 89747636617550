import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { removeCustomLogo, setCustomLogo } from 'store/ExecutiveReportEditCreate/actions';
import { selectCustomLogo } from 'store/ExecutiveReportEditCreate/selectors';
import { openModal } from 'store/modals/actions';
import { MODAL_REPORT_WARNING_LOGO } from 'store/modals/modals';
import {
  Wrapper, Title, UploadLabel, Upload, HelpIcon, Header,
  Box, InactiveBox, LogoWrapper, Logo, Delete, DeleteIcon,
  ActiveDragBox, Cloud, Text, TextInactive
} from './styled';

const UploadLogo = () => {
  const [dragActive, setDragActive] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const intl = useIntl();
  const customLogo = useSelector(selectCustomLogo);

  const title = intl.formatMessage({ id: 'reports.editCreate.inputTitle.customLogo' });
  const label = intl.formatMessage({ id: 'reports.editCreate.button.customLogo' });
  const help = intl.formatMessage({ id: 'reports.editCreate.customLogo.help' });

  const hasCustomLogo = Object.keys(customLogo).length !== 0;
  const name = hasCustomLogo && Object.keys(customLogo)[0];
  const contentType = hasCustomLogo && customLogo.content_type;
  const data = hasCustomLogo && customLogo[name].data;

  const imageSrc = hasCustomLogo && `data:${contentType};base64,${data}`;

  const upload = (files) => {
    const parsedToMb = files[0].size / 104857;
    const format = files[0].type;
    const isValidFormat = (format === 'image/jpeg') || (format === 'image/png');
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = () => {
        const height = image.height;
        const width = image.width;
        if ((height <= 500) && (width <= 500) && (parsedToMb <= 3.5) && (isValidFormat)) {
          dispatch(setCustomLogo(files[0], true));
        } else {
          dispatch(openModal(MODAL_REPORT_WARNING_LOGO));
        }
      };
    };
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragActive(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(0);
    if (dragCounter === 0) {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      upload(e.dataTransfer.files);
      setDragCounter(0);
    }
  };

  const deleteCustomLogo = () => dispatch(removeCustomLogo());

  return (
    <Wrapper>
      <Header>
        <Title >{ title }</Title>
        <HelpIcon title={ help } />
      </Header>
      <Box onDragEnter={ handleDragEnter } onDragOver={ handleDragOver } onDrop={ handleDrop } onDragLeave={ handleDragLeave } ref={ inputRef }>
      { hasCustomLogo
        ? <LogoWrapper>
            <Logo src={ imageSrc } alt="custom_logo" />
            <Delete onClick={ deleteCustomLogo }><DeleteIcon /></Delete>
          </LogoWrapper>
        : <InactiveBox>
            <Cloud />
            <TextInactive>drop file or</TextInactive>
            <UploadLabel htmlFor="upload_logo">{ label }</UploadLabel>
            <Upload id="upload_logo" type="file" accept="image/*" onChange={ (e) => upload(e.target.files) } />
          </InactiveBox>
        }
        { dragActive &&
        <ActiveDragBox>
            <Cloud color="#0082ed" />
            <Text>Drop your files here</Text>
        </ActiveDragBox>
        }
      </Box>
    </Wrapper>
  );
};

export default UploadLogo;
