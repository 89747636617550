import styled from 'styled-components';
import { Default, Accept } from 'Common/Components/Button/styled';

export const ButtonsWrapper = styled.div`
  margin-top: 34px;
  display: flex;
  justify-content: flex-end;
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';

export const Cancel = styled(Default)`
`;
Cancel.displayName = 'Cancel';

export const Done = styled(Accept)`
`;
Done.displayName = 'Done';
