export const PROJECTS_TASKS_RESET = 'PROJECTS_TASKS_RESET';
export const PROJECTS_TASKS_FAIL = 'PROJECTS_TASKS_FAIL';
export const PROJECTS_TASKS_FETCHING = 'PROJECTS_TASKS_FETCHING';

export const PROJECTS_TASKS_GET_SUCCESS = 'PROJECTS_TASKS_GET_SUCCESS';
export const PROJECTS_TASKS_CREATE_EDIT_SUCCESS = 'PROJECTS_TASKS_CREATE_EDIT_SUCCESS';
export const PROJECTS_TASKS_DELETE_SUCCESS = 'PROJECTS_TASKS_DELETE_SUCCESS';

export const PROJECTS_TASKS_SELECT = 'PROJECTS_TASKS_SELECT';
export const PROJECTS_TASKS_SET_FIELD = 'PROJECTS_TASKS_SET_FIELD';
export const PROJECTS_TASKS_REMOVE_ITEM = 'PROJECTS_TASKS_REMOVE_ITEM';

export const PROJECTS_TASKS_GET_COMMENTS_SUCCESS = 'PROJECTS_TASKS_GET_COMMENTS_SUCCESS';
export const PROJECTS_TASKS_ADD_COMMENT_SUCCESS = 'PROJECTS_TASKS_ADD_COMMENT_SUCCESS';
export const PROJECTS_TASKS_EDIT_COMMENT_SUCCESS = 'PROJECTS_TASKS_EDIT_COMMENT_SUCCESS';
export const PROJECTS_TASKS_DELETE_COMMENT_SUCCESS = 'PROJECTS_TASKS_DELETE_COMMENT_SUCCESS';
export const PROJECTS_TASKS_SET_SELECTED_COMMENT = 'PROJECTS_TASKS_SET_SELECTED_COMMENT';

export const PROJECTS_TASKS_UPDATE_TASK_LIST = 'PROJECTS_TASKS_UPDATE_TASK_LIST';
export const PROJECTS_TASKS_CLEAR = 'PROJECTS_TASKS_CLEAR';
export const PROJECTS_TASKS_CLEAR_LIST = 'PROJECTS_TASKS_CLEAR_LIST';

export const PROJECTS_TASKS_SET_SELECTED_LINKED_VULN = 'PROJECTS_TASKS_SET_SELECTED_LINKED_VULN';

export const PROJECTS_TASKS_MARK_TO_DELETE = 'PROJECTS_TASKS_MARK_TO_DELETE';
