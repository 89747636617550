import styled from 'styled-components';
import colors from 'Styles/colors';

export const Content = styled.div`
`;
Content.displayName = 'Content';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
`;
Section.displayName = 'Section';

export const MetricGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 11px;
`;
MetricGroup.displayName = 'MetricGroup';

export const MetricGroupTitle = styled.div`
  font-family: "CentraNo2";
  font-size: 13.5px;
  color: ${colors.darkBlueGrey};
`;
MetricGroupTitle.displayName = 'MetricGroupTitle';

export const Metrics = styled.div`
  display: flex;
  margin-top: 12px;
  & > *:not(:first-child) {
    margin-left: 2px;
  }
`;
Metrics.displayName = 'Metrics';

export const MetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 137px;
`;
MetricWrapper.displayName = 'MetricWrapper';

export const Metric = styled.div`
  background-color: #e0e5e9;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
`;
Metric.displayName = 'Metric';

export const MetricTitle = styled.h5`
  font-size: 13px;
  font-weight: 600;
  color: #1c4566;
  text-align: center;
  margin-bottom: 0;
`;
MetricTitle.displayName = 'MetricTitle';

const valuesColors = {
  0: colors.warmPurple,
  1: colors.redPink,
  2: colors.tangerine,
  3: colors.apple,
  4: colors.lightBlue2
};
export const Options = styled.div`
  background-color: ${colors.iceBlue};
  height: calc(100% - 49px);
  padding-bottom: 10px;
`;
Options.displayName = 'Options';

export const OptionWrapper = styled.div`
`;
OptionWrapper.displayName = 'OptionWrapper';

export const Option = styled.button`
  border: none;
  width: 100%;
  font-size: 13px;
  color: ${(props) => props.isSelected ? colors.white : colors.darkBlueGrey};
  background-color: ${(props) => props.isSelected ? valuesColors[props.position] : colors.iceBlue};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  &:focus, &:hover {
    background-color: ${(props) => valuesColors[props.position] || colors.iceBlue};
    color: ${colors.white};
    opacity: 0.9
  }

`;
Option.displayName = 'Option';

export const Tooltip = styled.div`
  position: absolute;
  max-width: 220px;
  border: solid 1px ${colors.lightGrey};
  display: block;
  color: ${colors.greyBlue};
  background-color: ${colors.white};
  border-radius: 2px;
  margin-top: .7em;
  margin-left: 4em;
  padding: 10px;
  z-index: 1;
  font-size: 14px;
`;
Tooltip.displayName = 'Tooltip';
