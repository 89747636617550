import get from 'lodash/get';
import { initialState } from './reducer';

export const selectMaxWorkspacesLimit = (state) => get(state, 'config.maxWorkspacesLimit', initialState.maxWorkspacesLimit);

export const selectMaxUsersLimit = (state) => get(state, 'config.maxUsersLimit', initialState.maxUsersLimit);

export const selectMaxHostsLimit = (state) => get(state, 'config.maxHostsLimit', initialState.maxHostsLimit);

export const selectMaxSchedulesLimit = (state) => get(state, 'config.maxSchedulesLimit', initialState.maxSchedulesLimit);

export const selectMaxJobsLimit = (state) => get(state, 'config.maxJobsLimit', initialState.maxJobsLimit);

export const selectLicenseType = (state) => get(state, 'config.licenseType', initialState.licenseType);

export const selectCloudInstance = (state) => get(state, 'config.cloudInstance', initialState.cloudInstance);

export const selectIsSsoEnabled = (state) => get(state, 'config.ssoEnabled', initialState.ssoEnabled);

export const selectLdapUseLocalRoles = (state) => get(state, 'config.ldapUseLocalRoles', initialState.ldapUseLocalRoles);

export const selectApiVersion = (state) => get(state, 'config.apiVersion', initialState.apiVersion);
