/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import { Tab, TabWrapper, TableWrapper } from './styled';
import getColumns from './getColumns';
import { getNotificationSettings } from 'store/Notifications/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserId, selectIsAdmin } from 'store/Faraday/selectors';
import { selectNotificationCenter } from 'store/Notifications/selectors';
import { FEATURE_ADVANCED_NOTIFICATIONS, FEATURE_PIPELINES, FEATURE_PROJECTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature } from 'Hooks/usePermission/selector';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE_NOTIFICATION } from 'store/modals/modals';
import notificationAdvancedLicenseImage from 'Images/notificationsAdvanced-license.png';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import selectModal from 'store/modals/selectors';
import { openModal } from 'store/modals/actions';
// import { selectShowLegacyUI } from 'store/Preferences/selectors';
// import { FormattedMessage } from 'react-intl';

const formatData = (data, isAdmin, isEnabledPipeline, isEnabledPlanner, isAdvanced) => {
  const orderCriteriaAdvanced = {
    highCritVuln: 1,
    riskScoreThreshold: 2,
    vulnOpenDaysCritical: 3,
    vulnOpenDaysHigh: 4,
    vulnOpenDaysMedium: 5,
    vulnOpenDaysLow: 6
  };

  const formattedData = [];

  let allowedTypes = ['agents', 'hosts', 'comments', 'pipelines', 'planner', 'reports', 'users', 'vulnerabilities', 'workspaces'];

  if (!isEnabledPipeline) allowedTypes = allowedTypes.filter(type => type !== 'pipelines');
  if (!isEnabledPlanner) allowedTypes = allowedTypes.filter(type => type !== 'planner');

  if (isAdvanced) {
    allowedTypes = ['riskScoreThreshold', 'highCritVuln', 'vulnOpenDaysCritical', 'vulnOpenDaysHigh', 'vulnOpenDaysMedium', 'vulnOpenDaysLow'];
  } else if (!isAdmin) {
    allowedTypes = allowedTypes.filter(type => type !== 'agents');
  }

  Object.entries(data).forEach(([key, value]) => {
    if (key !== 'user_id' && key !== 'id' && key !== 'slack_id' && key !== 'no_self_notify') {
      const [notificationType, notificationChannel] = key.split('_');
      const existingEntry = formattedData.find((entry) => entry.type === notificationType);

      if (existingEntry) {
        existingEntry[notificationChannel] = value;
      } else {
        formattedData.push({
          type: notificationType,
          [notificationChannel]: value
        });
      }
    }
  });

  // Add missing notification channels as false
  formattedData.forEach((entry) => {
    ['enabled', 'app', 'email', 'slack'].forEach((channel) => {
      if (!entry.hasOwnProperty(channel)) { // eslint-disable-line no-prototype-builtins
        entry[channel] = false;
      }
    });
  });

  const customSort = (a, b) => {
    if (!isAdvanced) {
      // Convertir "hosts" a "assets" solo para el propósito de ordenar
      const typeA = a.type === 'hosts' ? 'assets' : a.type;
      const typeB = b.type === 'hosts' ? 'assets' : b.type;
      return typeA.localeCompare(typeB);
    }

    const typeA = orderCriteriaAdvanced[a.type];
    const typeB = orderCriteriaAdvanced[b.type];
    return typeA - typeB;
  };

  const filteredData = formattedData
    .filter(entry => allowedTypes.includes(entry.type))
    .sort(customSort);

  return filteredData;
};

const Table = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectCurrentUserId);
  const data = useSelector(selectNotificationCenter);
  const isAdmin = useSelector(selectIsAdmin);
  const [activeTab, setActiveTab] = useState('System');
  const showModal = useSelector((state) => selectModal(state, MODAL_UPGRADE_LICENSE_NOTIFICATION));
  const isEnabledPipeline = useSelector((state) => selectAllowedByFeature(state, FEATURE_PIPELINES, PERMISSION_TYPES.READ));
  const isEnabledPlanner = useSelector((state) => selectAllowedByFeature(state, FEATURE_PROJECTS, PERMISSION_TYPES.READ));
  const isEnabledNotificationsAdvanced = useSelector((state) => selectAllowedByFeature(state, FEATURE_ADVANCED_NOTIFICATIONS, PERMISSION_TYPES.READ));
  // const showLegacyUI = useSelector(selectShowLegacyUI);
  // const height = showLegacyUI ? 'calc(100vh - 158px)' : 'calc(100vh - 280px)';
  const height = 'calc(100vh - 280px)';

  useEffect(() => {
    dispatch(getNotificationSettings(userId));
  }, [dispatch, userId]);

  const getTrProps = (rowInfo) => {
    const enabled = rowInfo.original.enabled;
    return {
      style: {
        opacity: enabled ? '1' : '0.5'
      }
    };
  };

  const handleTabClick = (tabName) => {
    if (tabName === 'Advanced' && !isEnabledNotificationsAdvanced) {
      dispatch(openModal(MODAL_UPGRADE_LICENSE_NOTIFICATION));
      return;
    }

    setActiveTab(tabName);
  };

  const getThProps = () => {
    return {
      style: {
        cursor: 'auto'
      }
    };
  };

  return (
    <>
    <TableWrapper showNewUI>
      <TabWrapper>
        <Tab active={ activeTab === 'System' } onClick={ () => handleTabClick('System') }>System</Tab>
        <Tab active={ activeTab === 'Advanced' } onClick={ () => handleTabClick('Advanced') }>Advanced</Tab>
      </TabWrapper>
      <CustomReactTable
        data={ formatData(data, isAdmin, isEnabledPipeline, isEnabledPlanner, activeTab === 'Advanced') }
        columns={ getColumns(userId, activeTab === 'Advanced') }
        minRows={ 0 }
        style={ { height, boxShadow: '-8px 14px 20px 0px rgba(0, 0, 0, 0.03)' } }
        manual
        getTrProps={ (_, rowInfo) => getTrProps(rowInfo) }
        getTheadThProps={ () => getThProps() }
        showPagination={ false }
      />
    </TableWrapper>
    { showModal &&
    <ReduxModal id={ MODAL_UPGRADE_LICENSE_NOTIFICATION }>
      <UpgradeLicense id={ MODAL_UPGRADE_LICENSE_NOTIFICATION } image={ notificationAdvancedLicenseImage } descriptionIntlId="upgradeLicense.notifications.description" />
    </ReduxModal>
    }
    </>
  );
};

export default Table;
