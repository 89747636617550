import React from 'react';
import Select from 'react-select';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { updateVuln } from 'store/Manage/actions';
import { selectVulnDetail, selectReadOnly } from 'store/Manage/selectors';
import { statusSelect } from 'Common/styles/style';
import { capitalize } from 'utils/stringUtils';
import { status as statusList } from 'Screens/Constants';
import Wrapper from './styled';
import { selectUserRole } from 'store/Faraday/selectors';

const StatusPicker = () => {
  const dispatch = useDispatch();
  const vuln = useSelector(selectVulnDetail);
  const role = useSelector(selectUserRole);

  const readOnly = useSelector(selectReadOnly);
  const status = get(vuln, 'status', '');
  const canUpdate = role !== 'client';

  const value = {
    label: capitalize(status),
    value: status
  };

  return (
    <Wrapper>
      <Select
        styles={ statusSelect }
        isSearchable={ false }
        isDisabled={ readOnly || !canUpdate }
        value={ value }
        onChange={ (e) => dispatch(updateVuln(vuln, 'status', e.value)) }
        options={ statusList.map((element) => ({ label: element.desc, value: element.name })) }
      />
    </Wrapper>
  );
};

export default StatusPicker;
