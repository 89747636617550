import api from 'services/api';
import last from 'lodash/last';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import { clearFilters } from 'store/Filters/actions';
import { selectQueryParam, selectFilters } from 'store/Filters/selectors';
import {
  REPORT_FILTERS, eq, createFilter
} from 'store/Filters/constants';
import {
  selectAdvancedFilterQueryParam, selectInputField, selectID, selectSelectedTemplate,
  selectCustomLogo
} from 'store/ExecutiveReportEditCreate/selectors';
import { selectTags } from 'store/Tags/selectors';
import * as types from './types';
import toBase64 from 'Common/Functions/ToBase64';
import { Base64 } from 'js-base64';
import * as FileSaver from 'file-saver';
import { selectCsrfToken } from 'store/Sesion/selectors';
import { getTemplates } from 'store/ExecutiveReport/actions';
import { EXECUTIVE_REPORT_FAIL } from 'store/ExecutiveReport/types';
import decodeHtml from 'utils/decodeHtml';

export const resetErrorValue = () => async (dispatch) => dispatch({ type: types.RESET_ERROR_VALUE });

export function resetState () {
  return (dispatch) => {
    dispatch({
      type: types.RESET_STATE_EXECUTVE_REPORT_CREATE_UPDATE
    });
  };
}

export function hideWarnings () {
  return (dispatch) => {
    dispatch({
      type: types.HIDE_WARNINGS_EXECUTIVE_REPORT_CREATE_UPDATE
    });
  };
}

export function createReport (isUpdating, onSuccess) {
  return async (dispatch, getState) => {
    const state = getState();
    const workspaces = selectInputField(state, 'workspaces');
    const filter = { filters: selectFilters('reports', state) };
    const title = selectInputField(state, 'title');
    const enterprise = selectInputField(state, 'enterprise');
    const selectedTemplate = selectSelectedTemplate(state);
    const advancedFilter = selectInputField(state, 'advancedFilter');
    const summary = selectInputField(state, 'summary');
    const conclusions = selectInputField(state, 'conclusions');
    const recommendations = selectInputField(state, 'recommendations');
    const objectives = selectInputField(state, 'objectives');
    const scope = selectInputField(state, 'scope');
    const id = selectID(state);
    const customLogo = selectCustomLogo(state);
    const logo = Object.keys(customLogo).length === 0 ? null : customLogo.logoFile;
    const parsedAdvancedFilter = advancedFilter ? JSON.parse(`{"filters":[${advancedFilter}]}`) : JSON.parse('{"filters":[]}');
    const stringAdvancedFilter = parsedAdvancedFilter.filters.length > 0 ? JSON.stringify(parsedAdvancedFilter) : '';
    const stringStandardFilter = filter.filters.length > 0 ? JSON.stringify(filter) : '{}';
    const stringFilter = stringAdvancedFilter || stringStandardFilter;

    dispatch({ type: types.EXECUTIVE_REPORT_CREATE_UPDATE_START });

    try {
      let reportPayload = {
        name: title,
        title,
        enterprise,
        summary,
        conclusions,
        recommendations,
        scope,
        objectives,
        filter: stringFilter,
        advanced_filter: !!advancedFilter,
        advanced_filter_parsed: advancedFilter,
        grouped: selectedTemplate[0],
        template_name: selectedTemplate[1],
        workspaces_names: workspaces
      };

      if (isUpdating) reportPayload = { ...reportPayload, id };

      const fd = new FormData();
      fd.append('json', JSON.stringify(reportPayload));
      if (logo) fd.append('custom_logo', logo);

      if (isUpdating) await api.report.updateReport(fd);
      else await api.report.createReport(fd);
      dispatch({ type: types.EXECUTIVE_REPORT_CREATE_UPDATE_SUCCESS });
      dispatch(trackEvent(CATEGORIES.report, ACTIONS.createReport.name, ACTIONS.createReport.label));
      onSuccess();
    } catch (e) {
      dispatch({ type: types.EXECUTIVE_REPORT_CREATE_UPDATE_VALIDATION_FAIL, validationError: ['Invalid filter.'] });
      dispatch({ type: EXECUTIVE_REPORT_FAIL, errorMessage: decodeHtml(e.message) });
    }
  };
}

function parseStringToFilterArray (filterString) {
  return async (dispatch, getState) => {
    const state = getState();
    const tags = selectTags(state);
    const assetsInFilter = filterString.match(/{"name":"target","op":"==","val":"(.*?)"}/g);
    dispatch(clearFilters('reports'));

    let assetsPremadeFilters = [];
    if (assetsInFilter) assetsPremadeFilters = assetsInFilter.map((f) => createFilter('target', eq.name.value, JSON.parse(f).val));

    const stringPremadeFilters = REPORT_FILTERS.map((elem) => ({
      name: elem.name.value,
      filter: elem.filter,
      parsedFilter: JSON.stringify(elem.filter)
    }));

    tags.forEach((tag) => {
      const tagFilter = { name: 'tags__name', filter: createFilter('tags__name', eq.name.value, tag.name) };
      const tagPremadeFilter = {
        name: tagFilter.name,
        filter: tagFilter.filter,
        parsedFilter: JSON.stringify(tagFilter.filter)
      };
      stringPremadeFilters.push(tagPremadeFilter);
    });

    let appliedFilters = [];
    appliedFilters = stringPremadeFilters
      .filter((filter) => filterString.includes(filter.parsedFilter))
      .map((filter) => ({ ...filter.filter }));

    appliedFilters = [
      ...appliedFilters,
      ...assetsPremadeFilters
    ];

    dispatch({ type: types.SET_FILTER_ARRAY, filterArray: appliedFilters });
  };
}

export function setInputField (label, value) {
  return (dispatch) => {
    dispatch({ type: types.SET_INPUT_FIELD, label, value });
  };
}

export function clearParseError () {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_PARSE_ADVANCED_FILTER_ERROR
    });
  };
}

export function setParseError (error) {
  return (dispatch) => {
    dispatch({
      type: types.SET_PARSE_ADVANCED_FILTER_ERROR, error
    });
  };
}

export function getVulnsPreview (workspaces) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let advancedFilterQueryParam = [];
      try {
        advancedFilterQueryParam = selectAdvancedFilterQueryParam(state);
      } catch (e) {
        return dispatch(setParseError('Filter syntax error'));
      }
      const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
      const standardQueryParam = selectQueryParam('reports', state);
      const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;
      dispatch({ type: types.GET_PREVIEW_DATA_START_REPORT });

      let vulnsList = [];
      let count = 0;
      const promises = workspaces.map((ws) => {
        return api.manage.newGetVulns(ws, queryParam);
      });

      const res = await Promise.all(promises);
      res.forEach((item) => {
        if (item.vulnerabilities.length > 0) {
          vulnsList = [...vulnsList, ...item.vulnerabilities];
          count = count + item.count;
        }
      });

      if (vulnsList) {
        const result = vulnsList.map((vuln) => vuln.value);
        return dispatch({
          type: types.GET_PREVIEW_DATA_SUCCESS_REPORT,
          data: result,
          count
        });
      }
      return dispatch({ type: types.GET_PREVIEW_DATA_FAIL_REPORT, error: 'Failed to obtain vulnerabilities.' });
    } catch (e) {
      if (e.message.includes('filter')) return dispatch(setParseError(e.message));
      return dispatch({ type: types.GET_PREVIEW_DATA_FAIL_REPORT, error: 'Failed to obtain vulnerabilities.' });
    }
  };
}
// Summary: Get report for detail
export function getById (id) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.EXECUTIVE_REPORT_CREATE_UPDATE_START });
      const report = await api.report.getReportDetail(id);
      if (!report.advanced_filter) dispatch(parseStringToFilterArray(report.filter));
      dispatch({ type: types.SET_REPORT_CREATE_UPDATE, report });
    } catch (error) {
      dispatch({ type: types.EXECUTIVE_REPORT_CREATE_UPDATE_FAIL, error });
    }
  };
}

export function resetTempTemplate () {
  return (dispatch) => {
    dispatch({
      type: types.RESET_TEMP_TEMPLATE_EXECUTIVE_REPORT_CREATE_UPDATE
    });
  };
}

export function setCustomLogo (logo, isCreating) {
  return async (dispatch) => {
    if (isCreating) {
      const uploadImage = await toBase64(logo);
      dispatch({
        type: types.SET_CUSTOM_LOGO,
        name: logo.name,
        content_type: logo.type,
        data: uploadImage.split(';base64,')[1],
        logoFile: logo
      });
    } else {
      const data = logo.data;
      const contentType = logo.content_type;
      const extension = contentType.split('/')[1];
      const name = `custom_logo.${extension}`;

      const datatoFile = (data, filename) => {
        Base64.extendString();
        const u8arr = Base64.toUint8Array(data);
        return new File([u8arr], filename, { type: contentType });
      };

      const file = datatoFile(data, name);

      dispatch({
        type: types.SET_CUSTOM_LOGO,
        name: 'custom_logo',
        content_type: logo.content_type,
        data: logo.data,
        logoFile: file
      });
    }
  };
}

export function removeCustomLogo () {
  return (dispatch) => {
    dispatch({
      type: types.RESET_CUSTOM_LOGO
    });
  };
}

export function getReportsCustomLogo (id) {
  return async (dispatch) => {
    try {
      const response = await api.report.getCustomLogo(id);
      const isImage = response.content_type.includes('image');
      if (isImage) dispatch(setCustomLogo(response));
    } catch (error) {
      dispatch({ type: types.GET_CUSTOM_LOGO_FAIL, error });
    }
  };
}

export function setUploadState (uploadState) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ADD_TEMPLATE_STATE, uploadState
    });
  };
}

export function testCustomTemplate (template, templateName) {
  return async (dispatch) => {
    const responseSession = await api.faraday.getSession();
    const fd = new FormData();
    fd.append('file', template);
    fd.set('csrf_token', responseSession.csrf_token);

    dispatch({ type: types.SET_TEMPLATE, fd });
    const fileName = templateName.split('.').slice(0, -1).join('.');
    const extension = `.${last(templateName.split('.'))}`;
    const fileType = (extension === '.docx') ? 'application/octet-stream; charset=utf-8' : 'application/pdf; charset=utf-8';

    try {
      const result = await api.report.testTemplate(fd);
      const fileReport = new Blob([result], { type: fileType });
      FileSaver.saveAs(fileReport, fileName + extension);
      dispatch(setUploadState('success'));
    } catch (error) {
      return dispatch(setUploadState('error'));
    }
  };
}

export function getTestTemplateInfo (template) {
  return async (dispatch, getState) => {
    const state = getState();
    const csrfToken = selectCsrfToken(state);
    const fd = new FormData();
    fd.append('csrf_token', csrfToken);
    fd.append('file', template);
    try {
      await api.report.getTemplateInfo(fd);
    } catch (error) {
      dispatch({ type: types.CUSTOM_TEMPLATE_FAIL, error });
    }
  };
}

export function uploadTemplate (fd) {
  return async (dispatch) => {
    try {
      await api.report.uploadTemplate(fd);
      dispatch(getTemplates());
    } catch (error) {
      dispatch({ type: types.CUSTOM_TEMPLATE_FAIL, error: error.message });
    }
  };
}

export function deleteTemplate (isGrouped, templateName) {
  return async (dispatch) => {
    const extension = templateName.includes('.docx') ? '.docx' : '.html';
    const filenameStart = templateName.split(extension)[0];
    const filename = filenameStart + extension;
    const data = {
      is_grouped: isGrouped,
      filename
    };

    try {
      await api.report.deleteTemplate(data);
      dispatch(getTemplates());
    } catch (error) {
      dispatch({ type: types.CUSTOM_TEMPLATE_FAIL, error });
    }
  };
}

export function downloadTemplate (isGrouped, templateName) {
  return async (dispatch) => {
    const extension = templateName.includes('.docx') ? '.docx' : '.html';
    const filenameStart = templateName.split(extension)[0];
    const filename = filenameStart + extension;
    const format = (extension === '.docx') ? '.docx' : '.pdf';
    const fileType = (extension === '.docx') ? 'application/octet-stream; charset=utf-8' : 'application/pdf; charset=utf-8';
    try {
      const result = await api.report.downloadTemplate(isGrouped, filename);
      const fileReport = new Blob([result], { type: fileType });
      FileSaver.saveAs(fileReport, filenameStart + format);
    } catch (error) {
      dispatch({ type: types.CUSTOM_TEMPLATE_FAIL, error });
    }
  };
}
