import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ showNewUI }) => showNewUI && `
    flex-direction: row;
    margin-top: 21px;
    width: 100%;
    height: 97%;
  `}
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: auto;
  ${({ showNewUI }) => showNewUI && `
    flex-direction: column;
    margin: 0 0 0 21px;
    width: 100%;
  `}
`;
