import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { Edit } from '../../styled';
import ModalWarning from 'Common/Components/ModalWarning';

const EditButton = ({ hasVulns, vulnsSelected, openVulnDetail }) => {
  const [showWarning, setShowWarning] = useState(false);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'vulnerability' });
  const handleClick = () => {
    if (hasVulns && canUpdate) {
      if ((vulnsSelected.length === 1)) openVulnDetail(vulnsSelected[0]._id);
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } disabled={ !hasVulns || !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Edit" description={ message } /> }
    </>
  );
};

export default EditButton;
