/* eslint-disable import/prefer-default-export */
import React from 'react';
import get from 'lodash/get';
import { TagWrapper, TagStyles } from './styled';
import Skeleton from 'Common/Components/Skeleton';

const Tag = ({ tag }) => {
  return (
    <TagWrapper className="mr-2 tag">
      <TagStyles>{tag}</TagStyles>
    </TagWrapper>
  );
};

const TagCell = ({ rowInfo, showSkeleton }) => {
  const tags = get(rowInfo, 'tags', []);

  if (showSkeleton) return <Skeleton />;
  return (
    <>
      {
          tags.map((tag) => (
            <Tag key={ `tag_service_table_${tag}` } tag={ tag } />
          ))
        }
    </>
  );
};

export default TagCell;
