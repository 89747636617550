import styled from 'styled-components';
import { tagStyles } from 'Styles/styles';

export const TagWrapper = styled.div`
  width: fit-content;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 6%);
  border: solid 1px ${({ state }) => (state ? '#63758d' : '#bec8d2')};
  background-color: ${({ state }) => (state ? '#63758d' : '#fafbfc')};
  color: ${({ state }) => (state ? '#fafbfc' : '#63758d')};
  display: inline-block;
  padding: 3px 7px;
  margin-top: 5px;
  &:hover {
   background-color:${({ state }) => (state ? '#63758d' : '#edf2f7')};;
  }
  &:active {
    border: solid 1px #90a9c0;
    background-color: #bec8d2;
    color:#63758d;
  }
`;
TagWrapper.displayName = 'TagWrapper';

export const TagStyles = styled.span`
  ${tagStyles};
  color: inherit;
  font-weight: 500;
  cursor: pointer;
`;
TagStyles.displayName = 'TagStyles';
