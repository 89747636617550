import get from 'lodash/get';
import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectMaxHostsLimit } from 'store/Config/selector';
import { selectTotalHosts } from 'store/Host/selectors';
import { openUpgradeLicense } from 'store/License/actions';
import { selectIsFetching } from 'store/License/selectors';
import { trackEvent } from 'tracking/GA';
import { CATEGORIES } from 'tracking/GA/constants';
import { LinkAsButton } from '../LinkAsButton/styled';
import {
  Wrapper, Summary, UpgradeLicense
} from './styled';

const TotalHostsUsed = ({ trackingShow, trackingClick }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const totalHosts = useSelector((state) => selectTotalHosts(state));
  const maxHostsLimit = useSelector((state) => selectMaxHostsLimit(state));
  const isFetching = useSelector((state) => selectIsFetching(state));

  useEffect(() => {
    dispatch(trackEvent(CATEGORIES.upgrade, get(trackingShow, 'name', ''), get(trackingShow, 'label', '')));
  }, [dispatch, trackingShow]);

  const onClick = () => {
    if (!isFetching) {
      dispatch(trackEvent(CATEGORIES.upgrade, get(trackingClick, 'name', ''), get(trackingClick, 'label', '')));
      dispatch(openUpgradeLicense());
    }
  };

  return (
    <Wrapper>
      <Summary>
        { intl.formatMessage({ id: 'host.assetsAvailable' }, {
          totalHosts,
          maxHostsLimit
        })}
      </Summary>
      <UpgradeLicense>
        { intl.formatMessage({ id: 'host.upgradeLicense' }, {
          clickHere: <LinkAsButton isFetching={ isFetching } onClick={ onClick }>Click Here</LinkAsButton>
        })}
      </UpgradeLicense>
    </Wrapper>
  );
};

TotalHostsUsed.propTypes = {
  trackingShow: PropTypes.instanceOf(Object).isRequired,
  trackingClick: PropTypes.instanceOf(Object).isRequired
};
export default TotalHostsUsed;
