/* eslint-disable react/no-children-prop */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { selectInputField } from 'store/ExecutiveReportEditCreate/selectors';
import {
  Wrapper, Title, Enterprise, TextWrapper, GenericTextWrapper, FaradayLogo, FaradayWhiteLogo, StyledDate, Separator
} from './styled';
import getParsedDate from '../dateFunctions';

const ThemePreview = ({ theme }) => {
  const title = useSelector((state) => selectInputField(state, 'title'));
  const enterprise = useSelector((state) => selectInputField(state, 'enterprise'));
  const date = getParsedDate();

  const placeholder = {
    title: "Title. e.g.: 'Network XYZ'",
    enterprise: "Client Name. e.g. 'ACME INC'"
  };

  return (
    <Wrapper theme={ theme }>
      { theme === 'generic' && <Separator />}
      { theme === 'light' && <FaradayLogo /> }
      { theme === 'dark' && <FaradayWhiteLogo /> }
      { theme === 'generic'
        ? (
          <>
            <GenericTextWrapper>
              <Title children={ title || placeholder.title } theme={ theme } />
              <Enterprise children={ enterprise || placeholder.enterprise } theme={ theme } />
            </GenericTextWrapper>
            <StyledDate children={ date } theme={ theme } />
          </>
          )
        : (
          <>
            <TextWrapper theme={ theme }>
              <Title children={ title || placeholder.title } theme={ theme } />
              <Enterprise children={ enterprise || placeholder.enterprise } theme={ theme } />
            </TextWrapper>
            <StyledDate children={ date } theme={ theme } />
          </>
          )}
    </Wrapper>
  );
};

ThemePreview.propTypes = {
  theme: PropTypes.string
};

ThemePreview.defaultProps = {
  theme: 'light'
};

export default ThemePreview;
