import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Loupe } from 'Images/icon_loupe.svg';
import { ReactComponent as Clear } from 'Images/icon_clearsearch.svg';
import BlueButton from 'Common/Components/BlueButton';

const zIndex = 2;

export const SearchInput = styled.div`
  max-width: 550px;
  flex: 1 1 0;
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  justify-content: left;
  flex-direction: row;
  padding: 0 8px 0 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-align: end;
  z-index: ${zIndex + 2};
  height: 40px;
`;

export const Input = styled.input`
  min-width: 150px;
  flex: 1 1 0;
  padding-left: 5.5px;
  border: solid 0px rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);

  font-size: 13px;
  letter-spacing: 0.1px;
  color: ${colors.darkBlueGrey};
  height: 40px;
  :-ms-input-placeholder {
    color: rgb(222,229,236);
  }
  ::-webkit-input-placeholder {
    color: rgb(222,229,236);
  }
`;

export const SearchIcon = styled(Loupe)`
  width: 18px;
`;
SearchIcon.displayName = 'SearchIcon';

export const ClearIcon = styled(Clear)`
  margin-left: auto;
  margin-left: 13px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 13px;
  color: #a1b4b6;
  cursor: pointer;
  background-color: white;
  `;
ClearIcon.displayName = 'ClearIcon';

export const SearchButton = styled(BlueButton)`
  width: 38px;
  height: 38px;
  padding: 0px;
`;
SearchButton.displayName = 'SearchButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;
  min-width: 490px;
  height: 40px;
  position: relative;
  display: flex;
  border-radius: 2px;
  box-shadow: 3px 15px 50px 0 '#edf2f7';
  border: solid 1px rgba(144, 169, 192, 0.3);
  background-color: '#fafbfc';
  box-shadow: 3px 15px 50px 0 '#edf2f7';
  background-color: white;
  margin: auto;
  
  .btn-loupe {
    padding: 7px 10px;
  }
`;
