import styled from 'styled-components';
import colors from 'Styles/colors';
import { ModalWrapper } from 'Common/Components/ConfirmNavigationModal/styled';
import { Accept } from 'Common/Components/Button/styled';
import StandardTextField from 'Common/Components/StandardTextField';
import { Asterisk } from 'Common/Components/StandardTextField/styled';

export const ModalWrapperStyled = styled(ModalWrapper)`
  height: 450px;
`;
ModalWrapperStyled.displayName = 'ModalWrapperStyled';

export const DocLink = styled.a`
  cursor: pointer;
  font-size: 12.5px;
  font-weight: 500;
  color: ${colors.blueCerulean};
  margin-left: 3px;
`;
DocLink.displayName = 'DocLink';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 93%;
`;
Content.displayName = 'Content';

export const Message = styled.div`
  font-size: 12.5px;
  font-weight: ${({ bold }) => bold ? '600' : '400'};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: ${colors.greyBlue};
  margin-top: 17px;
  width: 100%;
`;
Message.displayName = 'Message';

export const ButtonsWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';

export const Done = styled(Accept)`
  padding: 4px 17px;
`;
Done.displayName = 'Done';

export const Row = styled.div`
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
`;
Row.displayName = 'Row';

export const Input = styled(StandardTextField)`
  margin-bottom: 0;
  display: block;
  width: ${(props) => (props.width ? props.width : '100%')};
  
  ${Asterisk} {
    margin-left: 3px;
  }
  span {
    font-weight: 400;
  }
  input {
    box-shadow: ${(props) => (props.error ? 'inset 0 -1px 0 0 #ea3158' : 'inset 0 -1px 0 0 #afafaf')};
  }
  svg {
    top: 34px;
  }
`;
Input.displayName = 'Input';

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 201px;
`;
DropdownWrapper.displayName = 'DropdownWrapper';

export const InputTitle = styled.span`
  color: ${colors.grey17};
  font-size: 12.5px;
  position: relative;
  line-height: 26px;
  letter-spacing: normal;
  ${({ required }) => required && `
    &:after {
      content: '*';
      color: ${colors.redPink};
      position: absolute;
      margin-left: 3px;
    }
  `}
`;
InputTitle.displayName = 'InputTitle';

export const Token = styled.div`
  margin-top: 30px;
`;
Token.displayName = 'Token';

export const TokenBox = styled.div`
  display: flex;
  align-items: center;
  width: 435px;
  height: 34px;
  padding: 8px 0 9px 14px;
  box-shadow: inset 0 -1px 0 0 ${colors.grey30};
  background-color: ${colors.iceBlue};
  position: relative;
`;
TokenBox.displayName = 'TokenBox';

export const Text = styled.div`
  width: 384px;
  overflow: hidden;
  white-space: nowrap;
  color: ${colors.blueGrey};
  font-size: 13.5px;
  margin-right: 11px;
`;
Text.displayName = 'Text';

export const CopyButton = styled.button`
  border: none;
  background-color: transparent;
  &:hover {
    opacity: 0.7;
  }
`;
CopyButton.displayName = 'CopyButton';

export const InputDrowndown = styled.div`
  width: 201px;
  position: relative;
`;
InputDrowndown.displayName = 'InputDrowndown';

export const CustomWrapper = styled.div`
  display: none;
  background-color: ${colors.white};
  ${({ showCustom }) => showCustom && `
    display: flex;
    align-items: flex-end;
    position: absolute;
    z-index: 2;
    top: 6px;
    left: 10px;
    width: 165px;
    padding-left: 5px;
  `}
`;
CustomWrapper.displayName = 'CustomWrapper';

export const Label = styled.div`
  color: ${colors.darkBlueGrey};
  background-color: ${colors.white};
  opacity: 48%;
  font-size: 14.5px;
  font-weight: 500;
`;
Label.displayName = 'Label';

export const CustomInput = styled.input`
  color: ${colors.darkBlueGrey};
  border: none;
  border-bottom: 1px solid rgb(151 151 151 / 29%);
  width: 55px;
  background-color: ${colors.white};
  margin: 0 7px;
  font-size: 14.5px;
  font-weight: 500;
`;
CustomInput.displayName = 'CustomInput';

export const customDropdownStyle = {
  control: () => ({
    display: 'flex',
    height: '34px',
    borderRadius: '1px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: '#ffffff',
    border: 'solid 1px #fafbfc',
    borderBottomWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#1c2243',
    fontWeight: 500,
    fontSize: '14.5px'
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    fontWeight: 300

  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#505965',
    width: '30px'
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: isFocused ? '#edf2f7' : data.required ? 'rgba(255,86,48,0.1)' : 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: 'pointer',
    paddingLeft: '13px',
    height: '36px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginRight: 'auto'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '153px'
  }),
  menuList: () => ({
    maxHeight: '200px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '10px',
    paddingBottom: '10px'
  }),
  singleValue: (provided) => {
    const opacity = 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: '90px', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative'
    };
  }
};

export const Tooltip = styled.div`
  position: absolute;
  top: 46px;
  right: -19px;
`;
Tooltip.displayName = 'Tooltip';

export const TooltipText = styled.div`
  position: relative;
  width: 75px;
  border-radius: .4em;
  color: ${colors.darkBlueGrey};
  font-size: 13.5px;
  background-color: #e6ebf0;
  height: 30px;
  text-align: center;
  padding-top: 3px;
  &:after {
    content: " ";
    width: 0;
    height: 0;
    position: absolute;
    left: calc(50% - 8px);
    bottom: 100%;
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #e6ebf0;
    border-left: 8px solid transparent;
    transition: all .25s ease-out;
  }
`;
TooltipText.displayName = 'TooltipText';
