import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { selectIsFetching, selectPreviewData } from 'store/ExecutiveReportEditCreate/selectors';
import { selectRowsPerPage } from 'store/Filters/selectors';
import {
  Wrapper, TableWrapper, StyledTable, Title, Separator
} from './styled';
import getColumns from './columns';

const Table = ({ show }) => {
  const data = useSelector(selectPreviewData);
  const isFetching = useSelector(selectIsFetching);
  const rowsPerPage = useSelector((state) => selectRowsPerPage('reports', state));
  const mockData = [...Array(rowsPerPage).keys()];

  return show
    ? (
    <Wrapper>
      <Title> Vulnerabilities </Title>
      <Separator />
      <TableWrapper>
        <StyledTable
          data={ isFetching ? mockData : data }
          columns={ getColumns(isFetching) }
          minRows={ 0 }
          pages={ 1 }
          page={ 0 }
          defaultPageSize={ rowsPerPage }
          manual
          onFetchData={ () => {} }
          loading={ false }
          showPagination={ false }
          TheadComponent={ () => null }
        />
      </TableWrapper>
    </Wrapper>
      )
    : null;
};

Table.propTypes = {
  show: PropTypes.bool
};

Table.defaultProps = {
  show: true
};

export default Table;
