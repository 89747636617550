import styled from 'styled-components';
import colors from 'Styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const HeaderWrapper = styled.div`
  display: inline-flex;
  margin-bottom: 34px;
`;
HeaderWrapper.displayName = 'HeaderWrapper';

export const BodyWrapper = styled.div`
  
`;
BodyWrapper.displayName = 'BodyWrapper';

export const RowContainer = styled.div`
  margin-bottom: 10px;
`;
RowContainer.displayName = 'RowContainer';

export const Title = styled.span`
  font-size: 21.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c2243;
`;
Title.displayName = 'Title';

export const BtnsWrapper = styled.div`
  display: inline-flex;
  margin-left: auto;
`;
BtnsWrapper.displayName = 'BtnsWrapper';

export const CancelButton = styled.div`
  width: 89px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px #707070;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c2243;
  padding: 7px;
  cursor: pointer;
  background-color: white;
  margin-right: 8px;
`;
CancelButton.displayName = 'CancelButton';

export const CreateBtn = styled.div`
  width: 89px;
  height: 34px;
  border-radius: 2px;
  background-color:  ${(props) => (props.disabled ? '#ebeff3' : '#53a9ff')};    
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 8px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};;
`;
CreateBtn.displayName = 'CreateBtn';

export const SectionTitle = styled.div`
  font-size: 14.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c2243;
  margin-top: 14px;

`;
SectionTitle.displayName = 'SectionTitle';

export const SubTitle = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63758d;
  margin-top: 12px;
  margin-bottom: 21px;
`;
SubTitle.displayName = 'SubTitle';

export const WorkspaceList = styled.div`
  margin-top: 21px;
  width: 430px;
  height: 104px;
  border: solid 1px #e8e8e8;
  overflow-y: auto;
  overflow-x: hidden;
`;
WorkspaceList.displayName = 'WorkspaceList';

export const WorkspaceItem = styled.div`
  width: 428px;
  height: 33px;
  background-color: #fff;
  padding: 0 20px;
  display: table;

  &:hover {
    background-color: #edf2f7;
  }
`;
WorkspaceItem.displayName = 'WorkspaceItem';

export const WorkspaceName = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.64;
  letter-spacing: normal;
  color: #1c2243;
  display: contents;
`;
WorkspaceName.displayName = 'WorkspaceName';

export const WorkspaceStats = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.72;
  letter-spacing: normal;
  color: #90a9c0;
  float: right;
`;
WorkspaceStats.displayName = 'WorkspaceStats';

export const WorkspaceCriticalStats = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.64;
  letter-spacing: normal;
  color: ${colors.warmPurple};
  float:right;
  margin-left: 21px;
`;
WorkspaceCriticalStats.displayName = 'WorkspaceCriticalStats';

export const WorkspaceRemoveIcon = styled.div`
  color: #90a9c0;
  float: right;
  margin-left: 21px;
  position: relative;
  top: 4px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 0px 5px;
  cursor: pointer;

  &:hover {
    background-color: #90a9c0;
    svg {
      color: #fff!important;
    }
  }
`;
WorkspaceRemoveIcon.displayName = 'WorkspaceRemoveIcon';

export const PasswordErrorMessage = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ea3158;
`;
PasswordErrorMessage.displayName = 'PasswordErrorMessage';
