const defaultFields = {
  id: {
    visible: false, order: -1, maxWidth: 80, minWidth: 80, defaultMin: 80
  },
  important: {
    visible: true, order: 1, locked: true, maxWidth: 90, minWidth: 90, defaultMin: 90
  },
  asset: {
    visible: true, order: 2, locked: true, maxWidth: 300, minWidth: 200, defaultMin: 200
  },
  workspace_name: {
    visible: true, order: 3, locked: true, maxWidth: 150, minWidth: 150, defaultMin: 150
  },
  risk: {
    visible: true, order: 4, locked: true, maxWidth: 80, minWidth: 78, defaultMin: 78
  },
  search: {
    visible: true, order: 5, maxWidth: 60, minWidth: 60, defaultMin: 60
  },
  os: {
    visible: true, order: 6, maxWidth: 38, minWidth: 38, defaultMin: 38
  },
  hostnames: {
    visible: true, order: 7, maxWidth: 300, minWidth: 200, defaultMin: 200
  },
  open_services: {
    visible: true, order: 8, maxWidth: 135, minWidth: 40, defaultMin: 40
  },
  vulns: {
    visible: true, order: 9, minWidth: 60, defaultMin: 60
  },
  owned: {
    visible: true, order: 10, maxWidth: 80, minWidth: 80, defaultMin: 80
  },
  description: {
    visible: false, order: -1, maxWidth: 210, minWidth: 165, defaultMin: 165
  },
  mac: {
    visible: false, order: -1, maxWidth: 150, minWidth: 120, defaultMin: 120
  },
  owner: {
    visible: false, order: -1, minWidth: 70, defaultMin: 70
  },
  creation_time: {
    visible: true, order: 11, maxWidth: 150, minWidth: 110, defaultMin: 110
  },
  severity_counts: {
    visible: true, order: 12, maxWidth: 475, minWidth: 280, defaultMin: 280
  },
  last_modified: {
    visible: false, order: -1, maxWidth: 350, minWidth: 140, defaultMin: 140
  },
  services: {
    visible: false, order: -1, minWidth: 150, defaultMin: 150
  },
  tags: {
    visible: true, order: 13, maxWidth: 450, minWidth: 370, defaultMin: 370
  }
};

export default defaultFields;
