/* eslint-disable react/no-children-prop */
import React from 'react';
import { useIntl } from 'react-intl';
import {
  Title, Subtitle, TagContainer, Span
} from './styled';
import MainTag from '../MainTag';

const Texts = () => {
  const intl = useIntl();

  return (
    <>
      <Title children={ intl.formatMessage({ id: 'vuln.duplicates.modal.title' }) } />
      <Subtitle children={ intl.formatMessage({ id: 'vuln.duplicates.modal.subtitle' }) } />
      <TagContainer>
        <MainTag />
        <Span children={ intl.formatMessage({ id: 'vuln.duplicates.modal.records' }) } />
      </TagContainer>
      <Subtitle children={ intl.formatMessage({ id: 'vuln.duplicates.modal.selectMainRecord' }) } />
    </>
  );
};

export default Texts;
