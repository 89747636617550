import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import {
  selectWsErrors, selectHostErrors, selectName, notificationType,
  selectErrorMessage
} from 'store/Analytics/selectors';
import { closeNotification } from 'store/Analytics/actions';
import WarningModal from 'Common/Components/WarningModal';
import ModalWrapper from './styled';

const Errors = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const graphName = useSelector(selectName);
  const errorMessage = useSelector(selectErrorMessage);
  const wsErrors = useSelector(selectWsErrors);
  const hostErrors = useSelector(selectHostErrors);
  const dataNotFound = wsErrors.concat(hostErrors);
  const isDataNotFound = !isEmpty(dataNotFound);
  const type = useSelector(notificationType);
  const title = isDataNotFound ? intl.formatMessage({ id: `analytics.notificationItem.title.${type}` }) : 'Warning';
  const parsedTitle = isDataNotFound ? title : `"${graphName}" ${title}`;
  const subtitle = isDataNotFound ? intl.formatMessage({ id: `analytics.notificationItem.subtitle.${type}` }) : errorMessage;

  return (
    <ModalWrapper>
      <WarningModal
        title={ parsedTitle }
        subtitle={ subtitle }
        list={ isDataNotFound ? dataNotFound : null }
        showIcon
        onDone={ () => dispatch(closeNotification(isDataNotFound ? 'dataNotFound' : 'error')) }
      />
    </ModalWrapper>
  );
};

export default Errors;
