import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './styled';

const ActionButton = ({ onClick, title, icon }) => (
  <Wrapper title={ title } onClick={ onClick }>
    {icon}
  </Wrapper>
);

ActionButton.defaultProps = {
  onClick: null
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired
};

export default ActionButton;
