export const RESET_STATE_REPORT = 'RESET_STATE_REPORT';
export const EXECUTIVE_REPORT_FAIL = 'EXECUTIVE_REPORT_FAIL';

export const UPDATE_REPORT_STATUS_START = 'UPDATE_REPORT_STATUS_START';
export const UPDATE_REPORT_STATUS_SUCCESS = 'UPDATE_REPORT_STATUS_SUCCESS';

export const GET_DATA_REPORT_START = 'GET_DATA_REPORT_START';
export const GET_DATA_REPORT_SUCCESS = 'GET_DATA_REPORT_SUCCESS';

export const GET_REPORT_TEMPLATES_START = 'GET_REPORT_TEMPLATES_START';
export const GET_REPORT_TEMPLATES_SUCCESS = 'GET_REPORT_TEMPLATES_SUCCESS';

export const DOWNLOAD_REPORT_START = 'DOWNLOAD_REPORT_START';
export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS';

export const CLONE_REPORT_START = 'CLONE_REPORT_START';
export const CLONE_REPORT_SUCCESS = 'CLONE_REPORT_SUCCESS';

export const CREATE_REPORT_START = 'CREATE_REPORT_START';
export const CREATE_REPORT_FINISH = 'CREATE_REPORT_FINISH';

export const REMOVE_REPORT_START = 'REMOVE_REPORT_START';
export const REMOVE_REPORT_SUCCESS = 'REMOVE_REPORT_SUCCESS';

export const SET_EXECUTIVE_REPORT_PAGE = 'SET_EXECUTIVE_REPORT_PAGE';
export const SET_EXECUTIVE_REPORT_ORDER_BY = 'SET_EXECUTIVE_REPORT_ORDER_BY';

export const HIDE_MODAL_DELETE_CONFIRMATION_REPORT_SUCCED = 'HIDE_MODAL_DELETE_CONFIRMATION_REPORT_SUCCED';

export const RESET_ERROR_VALUE_REPORT = 'RESET_ERROR_VALUE_REPORT';
