import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Plus } from 'Images/icon-action-bar-plus.svg';

export const Button = styled.button`
  font-size: 11.5px;
  font-weight: 500;
  color: ${colors.darkBlueGrey};
  height: 34px;
  width: 121px;
  padding: 0 11px;
  border-radius: 2px;
  border: solid 1px ${colors.grey12};
  background-color: ${colors.iceBlue};
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  & > svg {
    height: 11px;
    margin-right: 10px;
     & > path {
      fill: ${colors.darkBlueGrey};
    }
  }
  &:hover {
    background-color: ${colors.white};
  }

  &:active {
    background-color: #d9e4ef;
  }
`;
Button.displayName = 'Button';

export const PlusIcon = styled(Plus)`
`;
PlusIcon.displayName = 'PlusIcon';
