import React from 'react';
import { DeleteIcon, MarkAsReadIcon, PaginationWrapper, Wrapper } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNotificationsAsync, markAllNotificationsAsReadAsync,
  markNotificationsAsReadAsync, setPage
} from 'store/Notifications/actions';
import {
  selectAllNotification, selectNotificationsCount,
  selectNotificationsLoading, selectPage, selectRowsPerPage,
  selectSelectAll, selectSelectedNotifications
} from 'store/Notifications/selectors';
import Pagination from 'Common/Components/Pagination';

const NotificationsDeleteButton = () => {
  const selectedNotifications = useSelector(selectSelectedNotifications);
  const dispatch = useDispatch();
  const handleClick = () => dispatch(deleteNotificationsAsync());

  // if(!selectedNotifications.length) return null

  return (
    <DeleteIcon
      title="Delete"
      onClick={ handleClick }
      disabled={ !selectedNotifications.length }
    />
  );
};

const NotificationsMarkAsReadButton = () => {
  const selectedNotifications = useSelector(selectSelectedNotifications);
  const selectAll = useSelector(selectSelectAll);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (selectAll) {
      dispatch(markAllNotificationsAsReadAsync());
    } else {
      const selectedIds = selectedNotifications.map((notification) => notification.id);
      dispatch(markNotificationsAsReadAsync(selectedIds));
    }
  };

  // if(!selectedNotifications.length) return null

  return (
    <MarkAsReadIcon
      title="Mark as read"
      onClick={ handleClick }
      disabled={ !selectedNotifications.length }
    />
  );
};

const ActionBar = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectAllNotification);
  const page = useSelector(selectPage);
  const rowsPerPage = useSelector(selectRowsPerPage);
  const loading = useSelector(selectNotificationsLoading);
  const notificationsCount = useSelector(selectNotificationsCount);
  const hasData = data.length > 0;
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));

  return (
      <Wrapper>
          <NotificationsDeleteButton />
          <NotificationsMarkAsReadButton />
          <PaginationWrapper>
            <Pagination offset={ (hasData || loading) ? page : null } limit={ rowsPerPage } total={ notificationsCount } onPrev={ onPrev } onNext={ onNext } />
          </PaginationWrapper>
      </Wrapper>
  );
};

export default ActionBar;
