import get from 'lodash/get';

export const selectTags = (state) => {
  const vulnsTags = get(state, 'tags.tagsList', []);
  const hostsTags = get(state, 'tags.hostsTagsList', []);
  const servicesTags = get(state, 'tags.servicesTagsList', []);
  const tagsList = [...vulnsTags, ...hostsTags, ...servicesTags];

  const uniqueTags = tagsList.reduce((acc, cur) => {
    if (!acc.find((tag) => tag.name === cur.name)) {
      acc.push(cur);
    }
    return acc;
  }, []);
  return uniqueTags;
};

export const selectGeneralTags = (state) => {
  const vulnsTags = get(state, 'tags.generalTagsList', []);
  const hostsTags = get(state, 'tags.generalHostsTagsList', []);
  const servicesTags = get(state, 'tags.generalServicesTagsList', []);
  const tagsList = [...vulnsTags, ...hostsTags, ...servicesTags];

  const uniqueTags = tagsList.reduce((acc, cur) => {
    if (!acc.find((tag) => tag.name === cur.name)) {
      acc.push(cur);
    }
    return acc;
  }, []);
  return uniqueTags;
};

export const selectShowTagsModal = (entity, state) => get(state, `tags.${entity}.showCreateTagModal`, false);

export const selectParsedTags = (state, selectedTags, selectedItems, allWorkspaces) => {
  const wsTags = allWorkspaces ? selectGeneralTags(state) : selectTags(state);
  const isBulk = selectedItems.length > 1;

  let parsedTags = [];

  if (isBulk) {
    let bulkTags = [];
    const duplicates = selectedTags.filter((item, index) => selectedTags.indexOf(item) !== index);
    const withoutDuplicates = selectedTags.filter((item, index) => selectedTags.indexOf(item) === index);
    const parsed = withoutDuplicates.map((t) => {
      const duplicated = duplicates.some((item) => item === t);
      return { name: t, partialSelected: !duplicated };
    });
    bulkTags = parsed;

    parsedTags = wsTags.map((tag) => {
      const tagAdded = bulkTags.find((t) => tag.name === t.name);
      let countRepeated = 0;
      selectedTags.forEach(t => {
        if (t === tag.name) {
          countRepeated += 1;
        }
      });

      return {
        id: tag.id,
        key: tag.key,
        name: tag.name,
        addedToVuln: !!tagAdded,
        partialSelected: tagAdded ? (tagAdded.partialSelected || (selectedItems.length !== countRepeated)) : false
      };
    });
  } else {
    parsedTags = wsTags.map((tag) => {
      const addedToVuln = selectedTags.some((t) => tag.name === t);
      return {
        id: tag.id,
        key: tag.key,
        name: tag.name,
        addedToVuln
      };
    });
  }

  return parsedTags;
};

export const orderedTags = (state, vulnsTags, selectedItems, allWorkspaces) => {
  const parsedTags = selectParsedTags(state, vulnsTags, selectedItems, allWorkspaces);
  const ordered = parsedTags.sort((a, b) => a.id - b.id);
  return ordered;
};

export const selectList = (state) => {
  const tagsList = get(state, 'tags.importTagsFullList', []);
  const parsedTags = tagsList.map((tag) => {
    if (tag.addedToVuln) {
      return tag;
    } else {
      return {
        id: tag.id,
        key: tag.key,
        name: tag.name,
        addedToVuln: false
      };
    }
  });

  const ordered = parsedTags.sort((a, b) => a.id - b.id);
  return ordered;
};

export const selectTagsToAdd = (state) => {
  const allTags = selectList(state);
  const newTags = allTags.filter((tag) => tag.addedToVuln);
  return newTags;
};

export const selectTagsWithFilter = (state, selectedTags, allWorkspaces) => {
  const wsTags = allWorkspaces ? selectGeneralTags(state) : selectTags(state);

  const addedTags = wsTags.filter((tag) => selectedTags.includes(tag.name));

  const parsedTags = addedTags.map((tag) => {
    return {
      id: tag.id,
      key: tag.key,
      name: tag.name,
      addedToVuln: true
    };
  });

  const ordered = parsedTags.sort((a, b) => a.id - b.id);
  return ordered;
};
