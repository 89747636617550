/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectError, selectErrorMessage, selectRedirect, selectShowGridViewContext, selectShowModalAssignUsers } from 'store/Workspace/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { stopRedirect, loadWorkspaceDetail, hideWorkspaceDetail, getUsers, resetError } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { resetFilters } from 'store/Filters/actions';
import Table from './components/Table';
import WSActionBar from './components/WSActionBar';
import Wrapper from './styled';
import GridView from './components/GridView';
import ModalAssignUsers from './components/ModalAssignUsers';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE, MODAL_WORKSPACE_CREATE } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { ACTIONS } from 'tracking/GA/constants';
import { selectAllowGetUsers } from 'store/Session/selectors';
import DeactivateConfirmationModal from './components/GridView/components/ DeactivateConfirmationModal';
import ModalCreation from './components/ModalCreation';
import selectModal from 'store/modals/selectors';
import { closeModal } from 'store/modals/actions';
import { SidebarTour } from 'Common/Components/Contextualization/Tour';
import { getVulnsCountInAllWorkspaces } from 'Screens/Contextualization/Faraday/actions/Actions';
import { selectIsLoggedIn } from 'store/Sesion/selectors';
import ErrorDialog from 'Common/Components/ErrorDialog';

const WorkspaceContext = ({ params }) => {
  const dispatch = useDispatch();
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const redirect = useSelector(selectRedirect);
  const showGridView = useSelector(selectShowGridViewContext);
  const showModalAssignUsers = useSelector(selectShowModalAssignUsers);
  const allowGetUsers = useSelector(selectAllowGetUsers);
  const [showConfirmation, setShowConfirmation] = useState({ show: false, ws: '', isMassive: false });
  const showModalCreateWs = useSelector((state) => selectModal(state, MODAL_WORKSPACE_CREATE));
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const handleClose = () => dispatch(closeModal(MODAL_WORKSPACE_CREATE));
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);

  const handleParamWsName = useCallback(() => {
    const wsName = get(params, 'wsname', '');
    if (wsName) dispatch(loadWorkspaceDetail(wsName));
    else dispatch(hideWorkspaceDetail());
  }, [params]);

  useEffect(() => {
    handleParamWsName();
  }, [handleParamWsName]);

  useEffect(() => {
    if (allowGetUsers && isLoggedIn) {
      dispatch(getUsers());
      dispatch(getVulnsCountInAllWorkspaces());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowGetUsers, dispatch, setShowConfirmation]);

  useEffect(() => () => {
    dispatch(resetFilters('workspaces'));
  }, [dispatch]);

  if (redirect) {
    dispatch(stopRedirect());
    return <Redirect to={ `/manage/${currentWorkspace}` } />;
  }

  return (
    <Wrapper>
      <WSActionBar setShowConfirmation={ setShowConfirmation } />
      { showGridView
        ? <GridView setShowConfirmation={ setShowConfirmation } />
        : <Table setShowConfirmation={ setShowConfirmation } />
      }
      { showConfirmation.show ? <DeactivateConfirmationModal onCloseCallback={ () => setShowConfirmation({ show: false, name: '', isMassive: false }) } show={ showConfirmation.show } ws={ showConfirmation.ws } isMassive={ showConfirmation.isMassive } /> : null}
      { showModalAssignUsers && <ModalAssignUsers /> }
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showWorkspace } trackingClick={ ACTIONS.clickWorkspaces } />
      </ReduxModal>
      { showModalCreateWs && <ModalCreation handleClose={ handleClose } /> }
      <SidebarTour />
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(resetError()) } /> : null }
    </Wrapper>
  );
};

export default WorkspaceContext;
