/* eslint-disable no-negated-condition */
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import get from 'lodash/get';
import filter from 'lodash/filter';
import head from 'lodash/head';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

export const selectFields = (state) => get(state, 'agents.table.list.fields', []);

export const selectIsFetching = (state) => get(state, 'agents.isFetching', false);

export const selectUserAgents = (state) => get(state, 'agents.userAgents', []);

export const selectCloudAgents = (state) => get(state, 'agents.cloudAgents', []);

// const mockCloudAgent = {
//   id: 1,
//   params: {},
//   name: 'First Scan',
//   slug: 'first-scan',
//   metadata: {
//     update_time: '2022-06-28T20:43:12.651048+00:00',
//     owner: 'faraday',
//     creator: '',
//     create_time: '2022-06-28T20:43:12.642066+00:00',
//     update_action: 0,
//     update_user: null,
//     update_controller_action: '',
//     command_id: null,
//   },
// };

export const selectSelectedCloudAgent = (state) => get(state, 'agents.selectedCloudAgent', null);

export const selectSelectedCloudAgentId = (state) => get(state, 'agents.selectedCloudAgent.id', 0);

// const mockParams = {
//   SCAN_ID: {
//     base: 'string',
//     type: 'string',
//     mandatory: false,
//   },
//   SSH_USER: {
//     base: 'string',
//     type: 'string',
//     mandatory: false,
//   },
//   SSH_PASSW: {
//     base: 'string',
//     type: 'string',
//     mandatory: false,
//   },
//   SCAN_TARGET: {
//     base: 'string',
//     type: 'string',
//     mandatory: true,
//   },
//   SOCKET_PATH: {
//     base: 'string',
//     type: 'string',
//     mandatory: false,
//   },
//   PORT_LIST_ID: {
//     base: 'string',
//     type: 'string',
//     mandatory: false,
//   },
//   TLS_PKEY_PASSW: {
//     base: 'string',
//     type: 'string',
//     mandatory: false,
//   },
//   CONNECTION_TYPE: {
//     base: 'string',
//     type: 'string',
//     mandatory: true,
//   },
//   TLS_CAFILE_PATH: {
//     base: 'string',
//     type: 'string',
//     mandatory: false,
//   },
//   TLS_KEYFILE_PATH: {
//     base: 'string',
//     type: 'string',
//     mandatory: false,
//   },
//   TLS_CERTFILE_PATH: {
//     base: 'string',
//     type: 'string',
//     mandatory: false,
//   },
// };

export const selectSelectedCloudAgentParams = (state) => get(state, 'agents.selectedCloudAgent.params', {});

export const selectTriggerAgentTools = (state) => get(state, 'agents.triggerAgent.tools', []);

export const selectTriggerAgentId = (state) => get(state, 'agents.triggerAgent.agentId', {});

export const selectTriggerAgentWs = (state) => get(state, 'agents.triggerAgent.workspaces', []);

export const selectTriggerIgnoreInfo = (state) => get(state, 'agents.triggerAgent.ignoreInfo', false);

export const selectTriggerResolveHostname = (state) => get(state, 'agents.triggerAgent.resolveHostname', true);

export const selectAssetsTags = (state) => get(state, 'agents.triggerAgent.hasAssetsTags', false);

export const selectVulnsTags = (state) => get(state, 'agents.triggerAgent.hasVulnsTags', false);

export const selectServicesTags = (state) => get(state, 'agents.triggerAgent.hasServicesTags', false);

export const selectAgentTypes = (state) => get(state, 'agents.agentTypes', {});

export const selectToolsByCategory = state => {
  const tools = selectAgentTypes(state);
  const groupedTools = groupBy(tools, 'category');
  const mappedTools = map(groupedTools, (value, key) => ({ categoryName: key, items: value }));
  return mappedTools;
};

export const selectDraftNewAgentName = (state) => get(state, 'agents.draftNewAgent.name', '');
export const selectDraftNewAgentServer = (state) => get(state, 'agents.draftNewAgent.server', '');
export const selectDraftNewAgentTools = (state) => get(state, 'agents.draftNewAgent.tools', []);

export const selectToolsSelected = (state) => {
  const draftNewAgentTools = selectDraftNewAgentTools(state);
  const toolsSelected = draftNewAgentTools.map((e) => e.name);
  const agentTypes = selectAgentTypes(state);
  const filteredAgentTypes = Object.fromEntries(Object.entries(agentTypes).filter(([key, value]) => toolsSelected.indexOf(key) > -1));
  return filteredAgentTypes;
};

export const selectToolIsAdded = (state, tool) => {
  const draftNewAgentTools = selectDraftNewAgentTools(state);
  const toolsSelected = draftNewAgentTools.map((e) => e.name);
  return toolsSelected.indexOf(tool) > -1;
};

export const selectToolHasEnvVars = (state, tool) => {
  const allTools = selectAgentTypes(state);
  const toolSelected = pickBy(allTools, { name: tool });
  return toolSelected ? toolSelected[tool].environment_variables.length > 0 : false;
};

export const selectToolHasOptionalEnvVars = (state, tool) => {
  const allTools = selectAgentTypes(state);
  const toolSelected = pickBy(allTools, { name: tool });
  return toolSelected[tool].optional_environment_variables ? toolSelected[tool].optional_environment_variables.length > 0 : false;
};

export const selectToolHasBeenConfigured = (state, tool) => {
  const allTools = selectAgentTypes(state);
  const currentTool = pickBy(allTools, { name: tool });
  const enviromentVariables = get(currentTool, `${tool}.environment_variables`, []);
  const draftNewAgentTools = selectDraftNewAgentTools(state);
  const toolSelected = head(filter(draftNewAgentTools, { name: tool }));
  const isConfigured = [];
  if (toolSelected && !isEmpty(toolSelected.variables)) {
    enviromentVariables.forEach((item) => {
      const envVarConfigured = Object.hasOwn(toolSelected.variables, item) || !isEmpty(toolSelected.variables[item]);
      isConfigured.push(envVarConfigured);
    });
  }
  return !isEmpty(isConfigured) ? !isConfigured.includes(false) : false;
};

export const selectToolsVariables = (state, tool) => {
  const draftNewAgentTools = selectDraftNewAgentTools(state);
  const toolSelected = head(filter(draftNewAgentTools, { name: tool }));
  return get(toolSelected, 'variables', {});
};

export const selectToolError = (state, tool) => {
  const errors = get(state, 'agents.errors', {});
  return get(errors, tool, false);
};

export const selectAgentError = (state) => get(state, 'agents.error', false);

export const selectToken = (state) => get(state, 'agents.token', null);

export const selectTokenExpiration = (state) => get(state, 'agents.tokenExpiresIn', 0);

// Selector to get the list of all agents
export const selectAgentList = (state) => {
  // Check the condition using lodash get
  const showCloudAgents = get(state, 'agents.showCloudAgents', false);

  // Return cloudAgents or userAgents based on the condition
  return showCloudAgents
    ? get(state, 'agents.cloudAgents', [])
    : get(state, 'agents.userAgents', []);
};

// Selector to get the list of selected agents
export const selectAgentsSelected = (state) => get(state, 'agents.agentsSelected', []);

// Selector to get the last selected agent's index
export const selectLastSelected = (state) => get(state, 'agents.lastSelected', -1);

// Selector to get the total count of agents
export const selectAgentCount = (state) => get(state, 'agents.agentCount', 0);

export const allAgentsAreSelected = (state) => {
  const agents = selectAgentList(state);
  const agentList = selectAgentsSelected(state);

  return agents.every((testAgent) => agentList.some((agent) => agent.id === testAgent.id)) && agentList.length > 0;
};

export const someAgentIsSelected = (state) => (selectAgentsSelected(state).length > 0);

export const SelectShowCloudAgents = (state) => get(state, 'agents.showCloudAgents', false);

export const selectAllAgents = (state) => {
  const userAgents = selectUserAgents(state);
  const cloudAgents = selectCloudAgents(state);
  const parsedCloudAgents = !isEmpty(cloudAgents) ? cloudAgents.map((agent) => ({ ...agent, type: 'cloud_agent' })) : [];
  const allAgents = userAgents.concat(parsedCloudAgents);
  return allAgents.sort((a, b) => a.name - b.name);
};

export const selectCloudAgentsForScheduler = (state) => {
  const cloudAgents = selectCloudAgents(state);
  const parsedCloudAgents = !isEmpty(cloudAgents) ? cloudAgents.map((agent) => ({ ...agent, type: 'cloud_agent' })) : [];
  return parsedCloudAgents;
};
