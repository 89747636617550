import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  margin-left: 27px;
  margin-top: 22px;
  * > svg {
    ${({ disabled }) => disabled && 'cursor: not-allowed'};
  }
`;
Wrapper.displayName = 'Wrapper';
