import api from 'services/api';
import { redirect, setHasPendingChanges } from 'store/Router/actions';
import { selectQuery } from 'store/Router/selectors';
import { openModal, closeModal } from 'store/modals/actions';
import { MODAL_TASKS_CREATE_EDIT } from 'store/modals/modals';
import { selectProjectById, selectProjectId } from 'store/Projects/selectors';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import {
  setSelectedProject, getProjects, somethingWentWrong
} from 'store/Projects/actions';
import get from 'lodash/get';
import * as types from './types';
import {
  selectTaskDependencies,
  selectTaskAssignees,
  selectTaskId,
  selectTasks,
  selectTasksByProjectId,
  selectTaskStartDate,
  selectTaskEndDate,
  selectTaskDescription,
  selectTaskName,
  selectTaskStatus,
  selectTaskType,
  selectTaskLinkedVulns,
  selectTaskProjectId,
  selectTaskById
} from './selectors';
import TABS from 'Screens/Planner/Tasks/CreateEdit/components/Tabs/Tabs';

export function fetchingStart () {
  return async (dispatch) => {
    dispatch({ type: types.PROJECTS_TASKS_FETCHING });
  };
}

export function resetTasksStore () {
  return (dispatch) => dispatch({ type: types.PROJECTS_TASKS_RESET });
}

export function getTasks (projectId, showError = true) {
  return async (dispatch) => {
    try {
      dispatch(fetchingStart());
      const tasks = await api.tasks.fetchTasks(projectId);
      return dispatch({ type: types.PROJECTS_TASKS_GET_SUCCESS, projectId, tasks });
    } catch (e) {
      if (showError) return dispatch(somethingWentWrong(e.message));
      throw e;
    }
  };
}

export function setSelectedTask (selectedTask) {
  return (dispatch) => {
    dispatch({ type: types.PROJECTS_TASKS_SELECT, selectedTask });
  };
}

export function closeTaskDetail () {
  return async (dispatch) => {
    dispatch(setSelectedProject(null));
    dispatch(setSelectedTask(null));
    dispatch(closeModal(MODAL_TASKS_CREATE_EDIT));
    dispatch(redirect('/planner', false));
  };
}

export function markTaskToDelete (task) {
  return (dispatch) => {
    dispatch({ type: types.PROJECTS_TASKS_MARK_TO_DELETE, task });
  };
}

export function getData (projectId, taskId) {
  return async (dispatch, getState) => {
    if (!projectId || !taskId) return null;
    const project = selectProjectById(getState(), projectId);
    if (!project) {
      try {
        await dispatch(getProjects());
      } catch (e) {
        if (e.status === 404) dispatch(somethingWentWrong('Project was not found'));
        return dispatch(closeTaskDetail());
      }
    }

    const fetchedProject = selectProjectById(getState(), projectId);

    if (!fetchedProject) {
      dispatch(closeTaskDetail());
      return dispatch(somethingWentWrong('Project was not found'));
    }

    const task = selectTaskById(getState(), taskId);

    if (!task) {
      try {
        await dispatch(getTasks(projectId, false));
      } catch (e) {
        if (e.status === 404) dispatch(somethingWentWrong('Project was not found'));
        return dispatch(closeTaskDetail());
      }
    }

    const fetchedTask = selectTaskById(getState(), taskId);

    if (!fetchedTask) {
      dispatch(resetTasksStore());
      dispatch(closeTaskDetail());
      return dispatch(somethingWentWrong('Task was not found'));
    }

    dispatch(getComments(taskId));
    dispatch(setSelectedProject(fetchedProject));
    dispatch(setSelectedTask(fetchedTask));
    return dispatch(openModal(MODAL_TASKS_CREATE_EDIT));
  };
}

export function openTaskDetail (projectId, taskId, tabId = 'general') {
  return async (dispatch, getState) => {
    const query = selectQuery(getState());
    const queryProjectId = parseInt(get(query, 'projectId', 0), 10);
    const queryTaskId = parseInt(get(query, 'taskId', 0), 10);
    const queryTabId = get(query, 'tabId', 'general');
    const isValidTab = TABS.some((t) => t.id === tabId);
    const oldQuery = `projectId=${queryProjectId}&taskId=${queryTaskId}&tabId=${queryTabId}`;
    const newQuery = `projectId=${projectId}&taskId=${taskId}&tabId=${isValidTab ? tabId : 'general'}`;

    if (projectId && taskId) {
      if (oldQuery !== newQuery) dispatch(redirect('/planner', false, newQuery));
    } else {
      dispatch(closeTaskDetail());
    }
  };
}

export function setSelectedTab (selectedTabId) {
  return (dispatch, getState) => {
    const query = selectQuery(getState());
    const queryProjectId = parseInt(get(query, 'projectId', 0), 10);
    const queryTaskId = parseInt(get(query, 'taskId', 0), 10);
    const queryTabId = get(query, 'tabId', 'general');
    const newQuery = `projectId=${queryProjectId}&taskId=${queryTaskId}&tabId=${selectedTabId}`;
    if (queryTabId !== selectedTabId) dispatch(redirect('/planner', false, newQuery, true));
  };
}

export function clearTasks (projectId) {
  return (dispatch) => dispatch({ type: types.PROJECTS_TASKS_CLEAR, projectId });
}

export function clearTasksList () {
  return (dispatch) => dispatch({ type: types.PROJECTS_TASKS_CLEAR_LIST });
}

export function editTask (data) {
  return async (dispatch, getState) => {
    const state = getState();
    const taskId = selectTaskId(state);
    const project_id = selectTaskProjectId(state);
    await api.tasks.editTask(taskId, data);
    dispatch(getTasks(project_id));
  };
}

export function createTask (data) {
  return async (dispatch, getState) => {
    const state = getState();
    const project_id = selectProjectId(state);
    await api.tasks.createTask({ ...data, project_id });
    dispatch(getTasks(project_id));
    dispatch(trackEvent(CATEGORIES.planner, ACTIONS.clickCreateTask.name, ACTIONS.clickCreateTask.label));
  };
}

export function createEditTask () {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const taskId = selectTaskId(state);
      const linkedVulns = selectTaskLinkedVulns(state);
      const linkedVulnsTotal = linkedVulns.length;

      const data = {
        name: selectTaskName(state),
        description: selectTaskDescription(state),
        task_dependencies: selectTaskDependencies(state).map((d) => d.id),
        type: selectTaskType(state),
        start_date: selectTaskStartDate(state),
        end_date: selectTaskEndDate(state),
        vulnerabilities_related: selectTaskLinkedVulns(state).map((v) => v.id),
        status: selectTaskStatus(state),
        users_assigned: selectTaskAssignees(state).map((d) => d.id)
      };

      if (taskId > 0) dispatch(editTask(data));
      else dispatch(createTask(data));

      dispatch(setHasPendingChanges(false));
      dispatch(closeTaskDetail());
      dispatch(getProjects());

      dispatch(trackEvent(CATEGORIES.planner, ACTIONS.linkedVulnsTotal.name, ACTIONS.linkedVulnsTotal.label, linkedVulnsTotal));
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function deleteTask (projectId, taskId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      await api.tasks.deleteTask(taskId);

      const tasksList = selectTasks(state);
      const projectTasks = selectTasksByProjectId(state, projectId);
      const updatedTasksList = [
        ...tasksList.filter((task) => task.projectId !== projectId),
        {
          projectId,
          tasks: projectTasks.filter((t) => t.id !== taskId)
        }
      ];
      dispatch(markTaskToDelete(null));

      return dispatch({ type: types.PROJECTS_TASKS_DELETE_SUCCESS, updatedTasksList });
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function getComments (taskId) {
  return async (dispatch, getState) => {
    try {
      const comments = await api.tasks.fetchComments(taskId);
      return dispatch({ type: types.PROJECTS_TASKS_GET_COMMENTS_SUCCESS, comments });
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function setSelectedComment (selectedComment) {
  return (dispatch) => {
    dispatch({ type: types.PROJECTS_TASKS_SET_SELECTED_COMMENT, selectedComment });
  };
}

export function deleteComment (commentId) {
  return async (dispatch) => {
    try {
      await api.tasks.deleteComment(commentId);
      return dispatch({ type: types.PROJECTS_TASKS_DELETE_COMMENT_SUCCESS, commentId });
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function addTaskComment (payload) {
  return async (dispatch) => {
    try {
      const taskComment = await api.tasks.addComment(payload);
      return dispatch({ type: types.PROJECTS_TASKS_ADD_COMMENT_SUCCESS, taskComment });
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function updateTaskComment (data) {
  return async (dispatch) => {
    try {
      const taskComment = await api.tasks.editComment(data.id, data);
      return dispatch({ type: types.PROJECTS_TASKS_EDIT_COMMENT_SUCCESS, taskComment });
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function updateTaskOnStore (projectId, task) {
  return async (dispatch, getState) => {
    const tasksList = selectTasks(getState());
    const projectTasks = selectTasksByProjectId(getState(), projectId);

    const newProjectTasks = projectTasks.map((item) => {
      if (item.id === task.id) return task;
      return item;
    });

    // actualizo la task en la listas de tasks
    const newTasksList = tasksList.map((item) => {
      if (item.projectId === projectId) {
        return ({
          projectId,
          tasks: [...newProjectTasks]
        });
      }
      return item;
    });

    // actualizo la lista de tasks en el store
    dispatch({ type: types.PROJECTS_TASKS_UPDATE_TASK_LIST, list: [...newTasksList] });
  };
}

function modifyLinkedVuln (projectId, taskId, vuln, add) {
  return async (dispatch, getState) => {
    try {
      // obtengo la task por id
      const task = selectTaskById(getState(), taskId);
      let linkedVulns = [];

      if (add) linkedVulns = [...task.linkedVulns, vuln];
      else linkedVulns = task.linkedVulns.filter((lv) => lv.id !== vuln.id);

      const newTask = { ...task, linkedVulns };
      // agrego la vuln a la task
      await api.tasks.editTask(task.id, { vulnerabilities_related: newTask.linkedVulns.map((lv) => lv.id) });
      return dispatch(updateTaskOnStore(projectId, newTask));
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function addLinkedVuln (projectId, taskId, vuln) {
  return async (dispatch) => {
    dispatch(modifyLinkedVuln(projectId, taskId, vuln, true));
  };
}

export function removeLinkedVuln (projectId, taskId, vulnId) {
  return async (dispatch) => {
    dispatch(modifyLinkedVuln(projectId, taskId, { id: vulnId }, false));
  };
}

export function setTaskField (fieldName, value) {
  return (dispatch) => {
    dispatch(setHasPendingChanges(true));
    dispatch({ type: types.PROJECTS_TASKS_SET_FIELD, field: fieldName, value });
  };
}

export function removeFieldItem (fieldName, id) {
  return (dispatch, getState) => {
    const assignees = selectTaskAssignees(getState());
    const dependencies = selectTaskDependencies(getState());
    const filteredData = fieldName === 'assignees'
      ? assignees.filter((el) => el.id !== id)
      : dependencies.filter((el) => el.id !== id);

    dispatch({ type: types.PROJECTS_TASKS_REMOVE_ITEM, fieldName, filteredData });
    dispatch(setHasPendingChanges(true));
  };
}

export const setSelectedLinkedVuln = (vuln) => ({ type: types.PROJECTS_TASKS_SET_SELECTED_LINKED_VULN, vuln });
