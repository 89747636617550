/* eslint-disable react/no-children-prop */
import useExpandable from 'Hooks/useExpandable';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectVulnDetailTags } from 'store/Manage/selectors';
import ExpandableWrapper from '../ExpandableWrapper';
import AddTagButton from './components/AddTagButton';
import TagItems from './components/TagItems';
import {
  Wrapper
} from './styled';

const TAB = 'general';
const ID = 'tags';

const Tags = () => {
  const intl = useIntl();
  const vulnTags = useSelector(selectVulnDetailTags);
  const isExpanded = useExpandable(TAB, ID, vulnTags);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } id="tags" title={ intl.formatMessage({ id: 'manage.detail.tab.general.tags' }) } tab={ TAB }>
      <Wrapper>
        <TagItems />
        <AddTagButton entity="vulnDetail" />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Tags;
