/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import MainContainer from 'Common/Components/MainContainer';
import StepFileSelector from 'Screens/Wizard/components/StepFileSelector';
import StepInitialMode from 'Screens/Wizard/components/StepInitialMode';
import StepSummary from 'Screens/Wizard/components/StepSummary';
import StepWorkspaceName from 'Screens/Wizard/components/StepWorkspaceName';
import StepWorkspaceSelection from 'Screens/Wizard/components/StepWorkspaceSelection';
import {
  FILE_SELECTOR, INITIAL_MODE, SUMMARY, WORKSPACE_NAME, WORKSPACE_SELECTION
} from 'Screens/Wizard/constants';
import {
  resetInitialStep, setStep, setUserHasBeenInWizard, hideWizardErrorModal
} from 'Screens/Wizard/actions/Actions';
import { connect } from 'react-redux';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { getUserAgents } from 'store/Agents/actions';
import { selectUserAgents } from 'store/Agents/selectors';
import { getCurrentWorkspace } from 'store/Workspace/selectors';
import { PERMISSION_TYPES, FEATURE_AGENTS } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';

class Main extends Component {
  componentDidMount () {
    const { userHasBeenInWizard, workspaces, getUserAgents, setStep, setUserHasBeenInWizard, canReadAgents } = this.props;

    if (canReadAgents) getUserAgents();

    if (userHasBeenInWizard) {
      if (workspaces.length > 0) setStep(WORKSPACE_SELECTION);
      else setStep(WORKSPACE_NAME);
    } else setUserHasBeenInWizard(true);
  }

  render () {
    const {
      step, width, error, errorMessage, hideErrorModal, setStep
    } = this.props;

    let child;

    switch (step) {
      case WORKSPACE_NAME:
        child = <StepWorkspaceName onStep={ setStep } />;
        break;
      case FILE_SELECTOR:
        child = <StepFileSelector onStep={ setStep } />;
        break;
      case INITIAL_MODE:
        child = <StepInitialMode onStep={ setStep } />;
        break;
      case SUMMARY:
        child = <StepSummary onStep={ setStep } />;
        break;
      case WORKSPACE_SELECTION:
        child = <StepWorkspaceSelection onStep={ setStep } />;
        break;

      default:
        child = <StepWorkspaceName onStep={ setStep } />;
        break;
    }

    return (
      <MainContainer width={ width } height="auto">
        { child }
        { error ? <ErrorDialog message={ errorMessage } resetError={ hideErrorModal } /> : null }
      </MainContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  step: state.wizard.step,
  width: state.wizard.width,
  height: state.wizard.height,
  userHasBeenInWizard: state.wizard.userHasBeenInWizard,
  workspaces: state.faraday.workspaces,
  error: state.wizard.error,
  errorMessage: state.wizard.errorMessage,
  userAgents: selectUserAgents(state),
  workspace: getCurrentWorkspace(state),
  canReadAgents: selectAllowedByRole(state, FEATURE_AGENTS, PERMISSION_TYPES.READ)
});

const mapDispatchToProps = (dispatch) => ({
  resetInitialStep: () => {
    dispatch(resetInitialStep());
  },
  setStep: (step) => {
    dispatch(setStep(step));
  },
  setUserHasBeenInWizard: (value) => {
    dispatch(setUserHasBeenInWizard(value));
  },
  hideErrorModal: () => {
    dispatch(hideWizardErrorModal());
  },
  getUserAgents: () => {
    dispatch(getUserAgents());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
