import React, { useState, useEffect } from 'react';
import { selectLocation } from 'store/Router/selectors';
import { selectShowUserFeedback } from 'store/Faraday/selectors';
import { setShowUserFeedback } from 'Screens/Faraday/actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Wrapper, Text, Close, CloseWrapper, Message,
  ButtonWrapper, MessageWrapper
} from './styled';
import Form from './Form';
import { selectIsProcessingFiles } from 'store/FileUploaderContext/selector';

const UserFeedback = () => {
  const dispatch = useDispatch();
  const [close, setClose] = useState(localStorage.getItem('hideFeedback') === null ? false : JSON.parse(localStorage.getItem('hideFeedback')));
  const isShowingForm = useSelector(selectShowUserFeedback);
  const location = useSelector(selectLocation);
  const disablePages = ['/login', '/wizard', '/forgotpass', '/resetpass'];
  const inDisablePage = disablePages.some((page) => location.pathname.startsWith(page));
  const showForm = (show) => dispatch(setShowUserFeedback(show));
  const isShowingImportModal = useSelector(selectIsProcessingFiles);

  useEffect(() => {
    const localData = localStorage.getItem('hideFeedback');
    setClose(localData === null ? false : JSON.parse(localData));
  }, []);

  const handleClose = () => {
    setClose(true);
    localStorage.setItem('hideFeedback', true);
  };

  if ((close && !isShowingForm) || inDisablePage || isShowingImportModal) return null;
  if (isShowingForm) return <Form handleClose={ handleClose } showForm={ showForm } />;
  return (
    <Wrapper>
      <ButtonWrapper>
        <CloseWrapper onClick={ handleClose }>
          <Close />
        </CloseWrapper>
        <Text onClick={ () => showForm(true) }>Got Feedback?</Text>
        <MessageWrapper onClick={ () => showForm(true) }><Message /></MessageWrapper>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default UserFeedback;
