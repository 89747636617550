/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  selectIsEditing, selectTab, selectTaskType
} from 'store/Tasks/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTab } from 'store/Tasks/actions';
import { FormattedMessage } from 'react-intl';
import {
  TabsWrapper, TabList, TabListContent, Tab
} from './styled';
import TABS from './Tabs';

const Tabs = () => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(selectTab);
  const type = useSelector(selectTaskType);
  const isEditing = useSelector(selectIsEditing);

  const filteredTabs = TABS.filter((t) => {
    if (!isEditing && t.id === 'comments') return null;
    return t;
  });

  if (type === 'task') {
    return (
      <TabsWrapper>
        <TabList>
          { filteredTabs.map((tab) => (
            <Tab
              key={ tab.id }
              active={ selectedTab.id === tab.id }
              onClick={ () => dispatch(setSelectedTab(tab.id)) }
              children={ <FormattedMessage id={ `planner.task.tab.${tab.intlId}` } /> }
            />
          ))}
        </TabList>
        <TabListContent children={ selectedTab.component } />
      </TabsWrapper>
    );
  }

  return (
    <TabsWrapper>
      <TabList>
        <Tab active children={ <FormattedMessage id={ `planner.task.tab.${selectedTab.intlId}` } /> } />
      </TabList>
      <TabListContent children={ selectedTab.component } />
    </TabsWrapper>
  );
};

export default Tabs;
