import React from 'react';
import General from './General';
import Comments from './Comments';
import LinkedVulns from './LinkedVulns';

const TABS = [
  {
    id: 'general',
    intlId: 'general',
    component: <General />
  },
  {
    id: 'linkedVulns',
    intlId: 'linkedVulns',
    component: <LinkedVulns />
  },
  {
    id: 'comments',
    intlId: 'comments',
    component: <Comments />
  }
];

export default TABS;
