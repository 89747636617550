import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  enableDisable
} from 'Screens/Contextualization/Workspaces/actions/Actions';
import { selectIsFetching } from 'store/Workspace/selectors';
import ModalWrapper from 'Common/Components/ModalWrapper';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  ConfirmButton, CancelButton, Warning, ModalBody
} from 'Common/Components/ModalConfirmation/styled';
import useKeyPress from 'Hooks/useKeyPress';
import { massiveUpdateWorkspacesActive } from 'Screens/Workspaces/actions/Actions';

const DeactivateConfirmationModal = ({ onCloseCallback, show, ws, isMassive }) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);

  const handleSubmit = () => {
    if (isMassive) dispatch(massiveUpdateWorkspacesActive());
    else dispatch(enableDisable(ws, false));
    onCloseCallback();
  };

  useKeyPress(() => {
    if (onCloseCallback) onCloseCallback();
  }, ['Escape']);

  useKeyPress(() => {
    handleSubmit();
  }, ['Enter']);

  if (!show) return null;
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>{'Archive Workspace'}</Title>
        </ModalTitle>
        <ModalBody>
          <BodyText>{'You’re archiving a workspace.  Are you sure you want to proceed?'}</BodyText>
        </ModalBody>
        <ButtonsContainer>
          <CancelButton onClick={ onCloseCallback }>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={ handleSubmit } disabled={ isFetching }>
            Archive Workspace
          </ConfirmButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default DeactivateConfirmationModal;
