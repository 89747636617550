/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import InputString from 'Common/Components/InputString';
import SearchWorkspaces from 'Common/Components/SearchWorkspaces';
import UserRoleDropdown from 'Common/Components/UserRoleDropdown';
import { unselectAll, saveUser } from 'Screens/Users/actions/Actions';
import { FiTrash2 as Trash } from 'react-icons/fi';
import isEmpty from 'lodash/isEmpty';
import { selectCurrentUserId } from 'store/Faraday/selectors';
import { selectLdapUseLocalRoles } from 'store/Config/selector';
import {
  selectedUserId, selectedUserUsername, selectedUserEmail,
  selectedUserWorkspaces, selectedUserRoles, selectedUserType
} from 'store/Users/selectors';
import {
  HeaderWrapper,
  Title,
  BtnsWrapper,
  CancelButton,
  CreateBtn,
  BodyWrapper,
  RowContainer,
  SectionTitle,
  SubTitle,
  WorkspaceList,
  WorkspaceItem,
  WorkspaceName,
  WorkspaceRemoveIcon,
  PasswordErrorMessage
} from './styled';
// import formatNumber from 'Common/Functions/FormatNumber';

const MAX_USER_LENGTH = 90;

const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
};

const renderEmailError = (email) => (!isValidEmail(email) && email ? 'Email is not valid' : '');

const passwordIsStrong = (password) => password.length >= 8 && password.match(/[0-9~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/\\]/g) && password.match(/[A-Z]/g);

const isValidName = (name) => {
  let error = '';

  if (!name) {
    return error;
  } if (!Number.isNaN(Number(name))) {
    error = 'Name must contain at least one letter';
  } else if (name.length <= 3 || name.match(/[^a-z0-9@._-]/)) {
    error = 'Name must be at least 4 characters long and lowercase. It can contain numbers and the following special characters: @ . _ -';
  }
  return error;
};

class UserCreationModal extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      repeatPassword: '',
      roles: [],
      type: 'user',
      workspaces: [],
      role: '',
      email: '',
      disable: false
    };
    this.baseState = this.state;
    this.addWorkspace = this.addWorkspace.bind(this);
    this.removeWorkspace = this.removeWorkspace.bind(this);
    this.onHandleClose = this.onHandleClose.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateStrongPassword = this.validateStrongPassword.bind(this);
    this.handleUserInputChange = this.handleUserInputChange.bind(this);
    this.requiredPassword = this.requiredPassword.bind(this);
  }

  resetForm () {
    this.setState({ ...this.baseState });
  }

  componentDidMount () {
    const {
      selectedUserId, selectedUserUsername, selectedUserEmail, selectedUserRoles,
      selectedUserWorkspacesNames, workspaceList, currentUserId
    } = this.props;
    const workspacesSelected = workspaceList.filter((item) => selectedUserWorkspacesNames.includes(item.name));
    this.setState({
      name: selectedUserUsername,
      email: selectedUserEmail,
      workspaces: workspacesSelected,
      role: selectedUserRoles[0] === 'asset_owner' ? 'asset owner' : selectedUserRoles[0],
      roles: selectedUserRoles,
      disable: currentUserId === selectedUserId
    });
  }

  componentDidUpdate (prevProps) {
    const {
      success, selectedUserId, selectedUserUsername, selectedUserEmail, selectedUserRoles,
      selectedUserWorkspacesNames, workspaceList, currentUserId
    } = this.props;

    const selectedUserChanged = prevProps.selectedUserId !== selectedUserId;

    if (prevProps.success !== success && success) this.onHandleClose();
    if (selectedUserChanged) {
      if (selectedUserId) {
        const workspacesSelected = workspaceList.filter((item) => selectedUserWorkspacesNames.includes(item.name));
        this.setState({
          name: selectedUserUsername,
          email: selectedUserEmail,
          workspaces: workspacesSelected,
          role: selectedUserRoles[0],
          roles: selectedUserRoles,
          disable: currentUserId === selectedUserId
        });
      }
    }
  }

  handleUserInputChange (value) {
    isValidName(value);
    const newValue = value.slice(0, MAX_USER_LENGTH);
    this.setState({ name: newValue });
  }

  onHandleSubmit () {
    const {
      name, password, roles,
      role, type, email, workspaces
    } = this.state;

    const userRoles = roles.map((rol) => (rol === 'asset owner' ? 'asset_owner' : rol));
    const userData = {
      name,
      password,
      roles: userRoles,
      role,
      type,
      workspaces: workspaces.map((ws) => (ws.name)),
      email
    };

    if (this.isValidForm()) {
      this.props.createUser(userData);
    }
  }

  onHandleClose () {
    this.props.unselectAll();
    this.props.handleClose();
    this.resetForm();
  }

  addWorkspace (ws) {
    const workspaceList = [...this.state.workspaces];
    if (workspaceList.findIndex((item) => (item.name === ws.name)) === -1) {
      workspaceList.push(ws);
      this.setState({ workspaces: workspaceList });
    }
  }

  removeWorkspace (ws) {
    const workspaceList = this.state.workspaces;
    const index = workspaceList.findIndex((item) => (item.name === ws.name));
    if (index !== -1) {
      workspaceList.splice(index, 1);
      this.setState({ workspaces: workspaceList });
    }
  }

  changeRole (role) {
    this.setState({ role });
    this.setState({ roles: [role] });
  }

  validateStrongPassword () {
    const { password } = this.state;
    if (!passwordIsStrong(password) && password) {
      return 'Password must be at least 8 characters long and must contain at least one uppercase letter and one of the following: Numbers (0-9) or special characters ( ~!@#$%^&*_-+=`|(){}[]:;"\'<>,.?/ )';
    }
    return '';
  }

  validatePassword () {
    const { password, repeatPassword } = this.state;
    const { selectedUserId } = this.props;
    return selectedUserId && isEmpty(password) ? true : passwordIsStrong(password) && password === repeatPassword;
  }

  requiredPassword () {
    const { password, repeatPassword } = this.state;
    return this.validateStrongPassword() || password === repeatPassword;
  }

  isValidForm () {
    const {
      name, roles, role, email
    } = this.state;
    return name !== '' && isValidName(name).length === 0 && isValidEmail(email) && this.validatePassword() && roles.length > 0 && role !== '';
  }

  render () {
    const {
      show,
      intl,
      message,
      selectedUserId,
      selectedUserType,
      isLdapUsingLocalRoles
    } = this.props;

    const {
      workspaces, password, repeatPassword, name, email, role
    } = this.state;

    const titleId = selectedUserId ? 'users.editUser' : 'users.createNewUser';
    const isSaml = selectedUserType === 'saml';
    const isLdap = selectedUserType === 'ldap';

    const ldapGroupsTooltip = (isLdap && !isLdapUsingLocalRoles) ? intl.formatMessage({ id: 'users.creationModal.ldapGroups' }) : '';

    const disabledTooltip = () => {
      const ldapTooltip = intl.formatMessage({ id: 'users.creationModal.disabled.ldap' });
      const samlTooltip = intl.formatMessage({ id: 'users.creationModal.disabled.saml' });
      if (isSaml) return samlTooltip;
      if (isLdap) return ldapTooltip;
      return '';
    };

    return (
      <Modal show={ show } id="modal_creation_user" onHide={ this.onHandleClose } size="md" centered dialogClassName="cu-modal" backdrop="static">
        <HeaderWrapper>
          <Title><FormattedMessage id={ titleId } /></Title>
          <BtnsWrapper>
            <CancelButton onClick={ () => { this.onHandleClose(); } } id="test_cancel_button_user">Cancel</CancelButton>
            <CreateBtn disabled={ !this.isValidForm() } onClick={ () => this.onHandleSubmit() } id="test_create_button_user">{ selectedUserId ? 'Save' : 'Create'}</CreateBtn>
          </BtnsWrapper>
        </HeaderWrapper>

        <BodyWrapper>
          <RowContainer>
            <PasswordErrorMessage>
              { message }
            </PasswordErrorMessage>
            <Row className="m-0">
              <InputString tooltip={ disabledTooltip() } disabled={ isLdap } max={ MAX_USER_LENGTH } width="267px" placeholder="john_doe" title={ `${intl.formatMessage({ id: 'username' })}*` } renderError={ isValidName(this.state.name) } marginBottom="8px" onChange={ this.handleUserInputChange } defaultValue={ name } id="test_user_create_username" />
              <UserRoleDropdown tooltip={ ldapGroupsTooltip } updateValue={ this.changeRole } defaultValue={ role } id="test_user_create_role" disabled={ this.state.disable || (isLdap && !isLdapUsingLocalRoles) } />
            </Row>
            <PasswordErrorMessage>
              { isValidName(this.state.name) }
            </PasswordErrorMessage>
          </RowContainer>

          <RowContainer>
            <Row className="m-0">
              <InputString width="321px" placeholder="johnd@faradaysec.com" title={ `${intl.formatMessage({ id: 'email' })}*` } marginBottom="8px" renderError={ renderEmailError(this.state.email) } onChange={ (v) => { this.setState({ email: v }); } } type="text" defaultValue={ email } id="test_user_create_email" />
            </Row>
            <PasswordErrorMessage>
              { renderEmailError(this.state.email) }
            </PasswordErrorMessage>
          </RowContainer>

          <RowContainer>
            <Row className="m-0">
              <InputString tooltip={ disabledTooltip() } disabled={ isSaml || isLdap } width="205px" placeholder="*********" title={ `${intl.formatMessage({ id: 'password' })}*` } marginBottom="8px" renderError={ this.validateStrongPassword() } onChange={ (v) => { this.setState({ password: v }); } } type="password" autoComplete="new-password" defaultValue={ password } id="test_user_create_password" />
              <div className="wd-21" />
              <InputString tooltip={ disabledTooltip() } disabled={ isSaml || isLdap } width="205px" placeholder="*********" title={ `${intl.formatMessage({ id: 'repeat_password' })}*` } marginBottom="8px" renderError={ !this.requiredPassword() } onChange={ (v) => { this.setState({ repeatPassword: v }); } } type="password" autoComplete="new-password" defaultValue={ repeatPassword } id="test_user_create_repeat_password" />
            </Row>
            { password !== repeatPassword && <PasswordErrorMessage><FormattedMessage id="password-missmatch" /></PasswordErrorMessage> }
            { this.validateStrongPassword() !== '' &&
            (
              <PasswordErrorMessage>
                { this.validateStrongPassword() }
              </PasswordErrorMessage>
            ) }
          </RowContainer>

          <SectionTitle><FormattedMessage id="assign-workspaces" /></SectionTitle>
          <SubTitle><FormattedMessage id="search-ws" /></SubTitle>
          <SearchWorkspaces onSelect={ this.addWorkspace } id="test_user_search_workspace" />
          {
            workspaces.length > 0 &&
            (
            <WorkspaceList>
              { workspaces.map((ws) => (
                <WorkspaceItem key={ `ws_${ws.id}` } className="test_user_workspace_selected">
                  <WorkspaceName>{ ws.name }</WorkspaceName>
                  <WorkspaceRemoveIcon onClick={ () => { this.removeWorkspace(ws); } }><Trash color="#1c4566" /></WorkspaceRemoveIcon>
                  {/* <WorkspaceCriticalStats>{`${ws.stats ? formatNumber(ws.stats.critical_vulns) : 'N/A'} critical`}</WorkspaceCriticalStats>
                  <WorkspaceStats>{ `${ws.stats ? formatNumber(ws.stats.total_vulns) : 'N/A'} vulnerabilities` }</WorkspaceStats> */}
                </WorkspaceItem>
              )) }
            </WorkspaceList>
            )
          }
        </BodyWrapper>
      </Modal>
    );
  }
}

UserCreationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  createUser: PropTypes.func.isRequired,
  workspaceList: PropTypes.array
};

UserCreationModal.defaultProps = {
  workspaceList: []
};

const mapDispatchToProps = (dispatch) => ({
  createUser: (data) => {
    dispatch(saveUser(data));
  },
  unselectAll: () => {
    dispatch(unselectAll());
  }
});

const mapStateToProps = (state) => ({
  workspaceList: state.faraday.workspaces,
  currentUserId: selectCurrentUserId(state),
  success: state.users.success,
  error: state.users.error,
  message: state.users.message,
  selectedUserId: selectedUserId(state),
  selectedUserType: selectedUserType(state),
  selectedUserUsername: selectedUserUsername(state),
  selectedUserEmail: selectedUserEmail(state),
  selectedUserWorkspacesNames: selectedUserWorkspaces(state),
  selectedUserRoles: selectedUserRoles(state),
  isLdapUsingLocalRoles: selectLdapUseLocalRoles(state)
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserCreationModal));
