import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectDetail } from 'store/Contextualization/AssetDetail/selectors';
import { updateVulnFromAsset } from 'store/Contextualization/AssetDetail/actions';
import CustomList from 'Common/Components/CustomList';
import Wrapper from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/Policies/styled';
import ExpandableWrapper from '../ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const TAB = 'general';
const ID = 'policies';

const Policies = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentVuln = useSelector((state) => selectDetail('vulns', state));
  const list = get(currentVuln, 'policyviolations', []);
  const isExpanded = useExpandable(TAB, ID, list);
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const canUpdate = !readOnly && allowedByRole;

  const onAdd = (valueAdded) => {
    const newRefs = [...list, valueAdded];
    dispatch(updateVulnFromAsset(currentVuln, 'policyviolations', newRefs));
  };

  const onRemove = (indexToRemove) => {
    const newPolicies = list.filter((pol, index) => index !== indexToRemove);
    dispatch(updateVulnFromAsset(currentVuln, 'policyviolations', newPolicies));
  };

  return (
    <ExpandableWrapper defaultValue={ isExpanded } id="policies" title={ intl.formatMessage({ id: 'manage.detail.tab.general.policies' }) } tab={ TAB }>
      <Wrapper>
        <CustomList
          key="DETAIL_CREATION_MODAL"
          placeholder="Add a Policy Violation"
          addItem={ onAdd }
          removeItem={ onRemove }
          listData={ list }
          responsive
          canUpdate={ canUpdate }
        />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Policies;
