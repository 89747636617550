import api from 'services/api';

// **
// smtp section
// **
export const GET_SETTINGS_SMTP_START = 'GET_SETTINGS_SMTP_START';
export const GET_SETTINGS_SMTP_FAIL = 'GET_SETTINGS_SMTP_FAIL';
export const GET_SETTINGS_SMTP_SUCCESS = 'GET_SETTINGS_SMTP_SUCCESS';
export const SAVE_SETTINGS_SMTP_START = 'SAVE_SETTINGS_SMTP_START';
export const SAVE_SETTINGS_SMTP_SUCCESS = 'SAVE_SETTINGS_SMTP_SUCCESS';
export const SAVE_SETTINGS_SMTP_FAIL = 'SAVE_SETTINGS_SMTP_FAIL';
export const SET_SETTINGS_SMTP_FIELD = 'SET_SETTINGS_SMTP_FIELD';

// **
// smtp section
// **
export function getSmtp () {
  return async (dispatch) => {
    dispatch({ type: GET_SETTINGS_SMTP_START });
    try {
      const response = await api.settings.fetchSmtp();
      return dispatch({ type: GET_SETTINGS_SMTP_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: GET_SETTINGS_SMTP_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function saveSmtp () {
  return async (dispatch, getState) => {
    dispatch({ type: SAVE_SETTINGS_SMTP_START });
    try {
      const {
        hostname, port, sender, username, password, enabled, ssl
      } = getState().settings.smtp;
      const response = await api.settings.saveSmtp(hostname, port, sender, username, password, enabled, ssl);
      return dispatch({ type: SAVE_SETTINGS_SMTP_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: SAVE_SETTINGS_SMTP_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function setSmtpField (field, value) {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_SMTP_FIELD, field, value });
  };
}
