import React from 'react';
import { TableHeader } from '../../styled';
import OnOffColumn from './components/OnOffColumn';
import CheckColumn from './components/CheckColumn';
import TypeColumn from './components/TypeColumn';

export default function getColumns (userId, isAdvanced = false) {
  const checkColumnProps = (channel, original) => ({
    userId,
    initialState: original[channel],
    type: `${original.type}_${channel}`
  });

  const columns = [
    {
      Header: () => <TableHeader>ON/OFF</TableHeader>,
      id: 'on',
      resizable: false,
      maxWidth: 70,
      minWidth: 70,
      headerStyle: { overflow: 'visible' },
      style: { display: 'flex', overflow: 'visible' },
      Cell: ({ original }) => <OnOffColumn { ...checkColumnProps('enabled', original) } />
    }, {
      Header: () => <TableHeader>TYPE</TableHeader>,
      id: 'type',
      resizable: false,
      style: { display: 'flex', overflow: 'visible', textTransform: 'capitalize' },
      maxWidth: isAdvanced ? 506 : 206,
      minWidth: isAdvanced ? 506 : 206,
      Cell: ({ original }) => <TypeColumn original={ original } { ...checkColumnProps('number', original) } /> // <-- Use the new component here
    }, {
      Header: () => <TableHeader>IN APP</TableHeader>,
      id: 'app',
      resizable: false,
      maxWidth: 100,
      minWidth: 100,
      Cell: ({ original }) => <CheckColumn { ...checkColumnProps('app', original) } />
    },
    {
      Header: () => <TableHeader>EMAIL</TableHeader>,
      resizable: false,
      maxWidth: 100,
      minWidth: 100,
      id: 'email',
      Cell: ({ original }) => <CheckColumn { ...checkColumnProps('email', original) } />
    },
    {
      Header: () => <TableHeader>SLACK</TableHeader>,
      resizable: false,
      maxWidth: 100,
      minWidth: 100,
      id: 'slack',
      Cell: ({ original }) => <CheckColumn { ...checkColumnProps('slack', original) } />
    }
  ];

  return columns;
}
