import React from 'react';
import { Accept, Cancel } from '../Button/styled';
import {
  Wrapper, Title, Footer, WarningIcon
} from './styled';
import useKeyPress from 'Hooks/useKeyPress';

const Warning = ({
  title, children, onAccept, onClose
}) => {
  useKeyPress(() => {
    if (onClose) onClose();
  }, ['Escape']);

  useKeyPress(() => {
    if (onAccept) onAccept();
  }, ['Enter']);
  return (
    <Wrapper>
      <Title>
        <WarningIcon />
        {title}
      </Title>
      { children }
      <Footer>
        { onClose ? <Cancel onClick={ onClose }>Close</Cancel> : null }
        { onAccept ? <Accept onClick={ onAccept }>Accept</Accept> : null }
      </Footer>
    </Wrapper>
  );
};
export default Warning;
