import styled from 'styled-components';
import colors from 'Styles/colors';
import StringField from 'Common/Components/StringField';
import { Title as InputTitle } from 'Common/Components/StringField/styled';
import { Title } from 'Screens/Contextualization/Settings/common-styled';
import Link from 'Common/Components/Link/styled';

export const Wrapper = styled.div`
  padding: 0px 0 20px 0;
  ${Title} {
    margin-bottom: 20px;
    font-size: 14.5px;
    font-weight: 500;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  flex-flow: wrap;
`;
Content.displayName = 'Content';

export const CustomInput = styled(StringField)`
  margin-bottom: 16px;
  ${InputTitle} {
    font-weight: 400;
    color: ${colors.grey17};
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
  }
`;
CustomInput.displayName = 'CustomInput';

export const Url = styled(CustomInput)`
  margin-right: 10px;
  max-width: 100%;
`;
Url.displayName = 'Url';

export const ApiKey = styled(CustomInput)`
  margin-right: 10px;
  max-width: 100%;
`;
ApiKey.displayName = 'ApiKey';

export const Test = styled(Link)`
  padding: 20px 0;
  display: block;
`;
Test.displayName = 'Test';
