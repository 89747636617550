/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_UPGRADE_LICENSE, MODAL_REPORTS_SELECT_TEMPLATE } from 'store/modals/modals';
import { closeModal, openModal } from 'store/modals/actions';
import upgradeLicenseImage from 'Images/reporting-license.png';
import { useIntl } from 'react-intl';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import { selectAllowedByFeature } from 'Hooks/usePermission/selector';
import { FEATURE_REPORTING, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { resetTempTemplate, setInputField } from 'store/ExecutiveReportEditCreate/actions';
import { selectParsedTemplateName } from 'store/ExecutiveReportEditCreate/selectors';
import { getTemplates } from 'store/ExecutiveReport/actions';
import { ACTIONS } from 'tracking/GA/constants';
import SelectTemplateModal from './components/SelectTemplateModal';
import TemplatePreview from './components/SelectTemplateModal/components/Items/components/TemplatePreview';
import SelectFormat from './components/SelectFormat';
import {
  Wrapper, Title, Content, Thumbnail, Right,
  ReportTitle, Buttons, BlueButton
} from './styled';

const SelectTemplateSection = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isAllowed = useSelector((state) => selectAllowedByFeature(state, FEATURE_REPORTING, PERMISSION_TYPES.CREATE));
  const selectedTemplate = useSelector(selectParsedTemplateName);
  const parsedTemplate = selectedTemplate.length > 0 && selectedTemplate[0];
  const buttonLabel = intl.formatMessage({ id: 'reports.editCreate.reportType.changeTemplateBtn' });

  const openSelectionModal = async () => {
    await dispatch(getTemplates());
    if (isAllowed) {
      dispatch(openModal(MODAL_REPORTS_SELECT_TEMPLATE));
    }
  };

  const getTemplateName = () => {
    if (parsedTemplate.docxOption) {
      if (parsedTemplate.docxOption.includes('(grouped)')) {
        return `${parsedTemplate.label} (grouped)`;
      } else {
        return parsedTemplate.label;
      }
    } else {
      return parsedTemplate.label;
    }
  };

  const templateName = getTemplateName();
  const theme = () => {
    let themeSelected = 'generic';
    if (Object.keys(selectedTemplate).length !== 0) {
      if (templateName.includes('dark')) {
        themeSelected = 'dark';
      } else if (templateName.includes('light')) {
        themeSelected = 'light';
      } else {
        themeSelected = 'generic';
      }
    }
    return themeSelected;
  };

  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (!parsedTemplate) {
      dispatch(setInputField('selectedTemplate', [false, 'generic_vulnerabilities_light.docx (generic) (Word)']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedTemplate, selectedTemplate]);
  const onClose = () => {
    dispatch(closeModal(MODAL_REPORTS_SELECT_TEMPLATE));
    dispatch(resetTempTemplate());
  };

  return (
    <Wrapper>
      <Title>
        Report type
      </Title>
      { templateName &&
        <Content>
          <Thumbnail>
            <TemplatePreview theme={ theme() } thumbnail />
          </Thumbnail>
          <Right>
          <ReportTitle>
              { templateName }
          </ReportTitle>
            <Buttons>
              <BlueButton onClick={ openSelectionModal }>{ buttonLabel }</BlueButton>
              <SelectFormat />
            </Buttons>
          </Right>
        </Content> }
      <ReduxModal id={ MODAL_REPORTS_SELECT_TEMPLATE }>
        <SelectTemplateModal onClose={ onClose } />
      </ReduxModal>
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.reporting.description" trackingShow={ ACTIONS.showReport } trackingClick={ ACTIONS.clickReport } />
      </ReduxModal>
    </Wrapper>
  );
};

export default SelectTemplateSection;
