const fileFormatInformation = {
  csv: {
    apiPayload: {
      method: 'GET',
      responseType: 'blob',
      headers: {
        Accept: 'text/csv'
      }
    },
    fileType: 'text/csv; charset=utf-8',
    extension: '.csv'
  },
  pdf: {
    apiPayload: {
      method: 'GET',
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      }
    },
    fileType: 'application/pdf; charset=utf-8',
    extension: '.pdf'
  },
  doc: {
    apiPayload: {
      method: 'GET',
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      }
    },
    fileType: 'application/octet-stream; charset=utf-8',
    extension: '.docx'
  }
};

export default fileFormatInformation;
