import api from 'services/api';
import { selectCloudInstance } from 'store/Config/selector';
import { selectIsAdmin } from 'store/Faraday/selectors';

import {
  LICENSE_FETCHING, LICENSE_FAIL
} from './constants';

export const URL_PRICING = 'https://faradaysec.com/pricing/';

export function fetching (isFetching = true) {
  return async (dispatch) => {
    dispatch({ type: LICENSE_FETCHING, isFetching });
  };
}

export function somethingWentWrong () {
  return async (dispatch) => {
    dispatch({ type: LICENSE_FAIL, message: 'There was an error, please try again.' });
  };
}

export function openUpgradeLicense () {
  return async (dispatch, getState) => {
    dispatch(fetching());
    let urlPricing = URL_PRICING;
    try {
      const cloudInstance = selectCloudInstance(getState());
      const isAdmin = selectIsAdmin(getState());
      if (cloudInstance && isAdmin) {
        const response = await api.license.fetchUrlPricing();
        urlPricing = response.url;
      }
    } catch (e) {
      dispatch(somethingWentWrong());
    }
    window.open(urlPricing);
    dispatch(fetching(false));
  };
}
