import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Header from '../Header';
import Items from '../Items';
import { Section, ContentTitle, ContentWrapper, Templates } from '../../styled';
import { Button, PlusIcon } from './styled';
import UploadCustomTemplate from './UploadCustomTemplate';
import { selectErrorMessage, selectError, selectTemplates } from 'store/ExecutiveReportEditCreate/selectors';
import { resetErrorValue, setInputField } from 'store/ExecutiveReportEditCreate/actions';
import ErrorDialog from 'Common/Components/ErrorDialog';
import get from 'lodash/get';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import parseTemplate from 'store/ExecutiveReportEditCreate/mapping';
import { selectUserRole } from 'store/Faraday/selectors';

const Custom = ({ title, onClose }) => {
  const [showUploadModal, toggleUploadModal] = useState(false);
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const listTemplates = useSelector(selectTemplates);
  const role = useSelector(selectUserRole);
  const dispatch = useDispatch();
  const intl = useIntl();
  const showUpload = role !== 'pentester';

  const onSelection = (t) => {
    let isTemplateAllowed = true;
    let templateParsed = [];
    if (t.pdfOption) {
      const temp = listTemplates.find((template) => template[1] === t.pdfOption);
      isTemplateAllowed = get(temp, '[2]', false);
      templateParsed = parseTemplate(temp);
    } else {
      const temp = listTemplates.find((template) => template[1] === t.docxOption);
      isTemplateAllowed = get(temp, '[2]', false);
      templateParsed = parseTemplate(temp);
    }

    if (isTemplateAllowed) {
      dispatch(setInputField('selectTemplateTemp', get(templateParsed, '[0]', [])));
    } else {
      dispatch(openModal(MODAL_UPGRADE_LICENSE));
    }
  };

  return (
    <Section>
      <Header onClose={ onClose } />
      <ContentWrapper>
        <ContentTitle>{ title }</ContentTitle>
        { showUpload &&
          <Button title="Add template" onClick={ () => toggleUploadModal(!showUploadModal) }>
            <PlusIcon />
            {intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.addTemplate.add' }) }
          </Button>
        }
        <Templates>
          <Items onSelection={ onSelection } custom />
        </Templates>
      </ContentWrapper>
      { showUploadModal && <UploadCustomTemplate setShowModal={ () => toggleUploadModal(!showUploadModal) } /> }
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(resetErrorValue()) } /> : null }
    </Section>
  );
};

export default Custom;
