import styled from 'styled-components';

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 38px;
  height: 38px;
  align-items: center;
  justify-content: center;
  & > svg {
    background-color: #264d6d;
    cursor: pointer;
    min-width: 17px;
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
