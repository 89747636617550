import React from 'react';
import { useSelector } from 'react-redux';
import { selectUseMarkdown } from 'store/Settings/reportSelectors';
import { Wrapper, Text } from './styled';

const TextPreview = () => {
  const isMarkdown = useSelector(selectUseMarkdown);

  const headerText = isMarkdown ? 'This is a header' : '##This is a header';
  const boldText = isMarkdown ? 'This is bold text' : '**This is bold text**';
  const italicText = isMarkdown ? 'This is italic text' : '_This is italic text_';

  return (
    <Wrapper isMarkdown={ isMarkdown }>
      <Text>{ headerText }</Text>
      <Text>{ boldText }</Text>
      <Text>{ italicText }</Text>
    </Wrapper>
  );
};

export default TextPreview;
