import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getSchedules, selectScheduleRow, changeStatus, runSchedule
} from 'store/Schedule/actions';
import {
  selectIsFetching, selectData, selectRowsSelected, selectLastRowSelected, selectLastIndexForRange, selectLastSelected, selectFields
} from 'store/Schedule/selectors';
import { MODAL_SCHEDULER_NO_WS_WARNING, MODAL_SCHEDULER_OFFLINE_AGENT_WARNING } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import Table from 'Common/Components/Table';
import getColumns from 'Screens/Automation/Schedule/components/TableContainer/components/Columns';
import TableWrapper from './styled';
import EmptySchedule from '../EmptySchedule';

const TableContainer = () => {
  const dispatch = useDispatch();

  const fields = useSelector(selectFields);
  const isFetching = useSelector(selectIsFetching);
  const data = useSelector(selectData);
  const rowsSelected = useSelector(selectRowsSelected);
  const lastRowSelected = useSelector(selectLastRowSelected);
  const lastIndexForRange = useSelector(selectLastIndexForRange);
  const lastSelected = useSelector(selectLastSelected);

  const noSchedule = data.rows && (data.rows.length === 0 && !isFetching);

  useEffect(() => {
    dispatch(getSchedules());
  }, [dispatch]);

  const showNoWsWarning = (workspaces) => {
    const schedulerWithoutWs = workspaces.length === 0;
    return schedulerWithoutWs;
  };

  const onChangeStatus = (id, status, workspaces, type) => {
    const emptyWorkspaces = showNoWsWarning(workspaces);
    if (emptyWorkspaces) {
      dispatch(openModal(MODAL_SCHEDULER_NO_WS_WARNING));
    } else {
      dispatch(changeStatus(id, status, type));
    }
  };

  const run = (e, id, workspaces, isOnline, type) => {
    const emptyWorkspaces = showNoWsWarning(workspaces);
    const offlineAgent = type === 'agent' && !isOnline;
    if (emptyWorkspaces) {
      dispatch(openModal(MODAL_SCHEDULER_NO_WS_WARNING));
    } else if (offlineAgent) {
      dispatch(openModal(MODAL_SCHEDULER_OFFLINE_AGENT_WARNING));
    } else {
      dispatch(runSchedule(id, type));
      e.stopPropagation();
    }
  };

  if (noSchedule) return (<TableWrapper children={ <EmptySchedule /> } />);
  return (
    <TableWrapper>
      <Table
        selectRowAction={ selectScheduleRow }
        isFetching={ isFetching }
        data={ data.rows }
        rowsSelected={ rowsSelected }
        lastRowSelected={ lastRowSelected }
        lastIndexForRange={ lastIndexForRange }
        lastSelected={ lastSelected }
        columns={ getColumns(fields, onChangeStatus, isFetching, run) }
      />
    </TableWrapper>
  );
};

export default TableContainer;
