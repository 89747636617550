import styled from 'styled-components';

export const ExpanderWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    min-height: 55px;
    border-radius: 3px;
    box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.06);
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
`;
ExpanderWrapper.displayName = 'ExpanderWrapper';

export const HeaderContainer = styled.div`
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
    padding: 17px 20px;
    position: relative;
    height: 55px;
    display: flex;
    flex-direction: row;
    :hover{
        >:nth-child(2){
            display: block;
        }
        >:nth-child(3){
            display: block;
        }
    }
`;
HeaderContainer.displayName = 'HeaderContainer';
