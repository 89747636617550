import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { showActionModal, updateUserToken } from 'store/Settings/accessTokens/actions';
import {
  Wrapper, ModalWrapper, Title, Message
} from 'Common/Components/ConfirmNavigationModal/styled';
import { ButtonsWrapper, Cancel, Done } from './styled';
import useKeyPress from 'Hooks/useKeyPress';
import { selectSelectedTokenId } from 'store/Settings/accessTokens/selectors';

const ActionModal = ({ action }) => {
  const dispatch = useDispatch();
  const id = useSelector(selectSelectedTokenId);
  const intl = useIntl();
  const title = intl.formatMessage({ id: `preferences.accessTokens.${action}.title` });
  const message = intl.formatMessage({ id: `preferences.accessTokens.${action}.message` });
  const cancel = intl.formatMessage({ id: 'common.components.button.cancel' });
  const done = intl.formatMessage({ id: `preferences.accessTokens.${action}.confirm` });

  const onCancel = () => dispatch(showActionModal(false, ''));

  const onDone = () => {
    dispatch(updateUserToken(id, { [action]: true }));
    onCancel();
  };

  useKeyPress(() => {
    if (onDone) onDone();
  }, ['Enter']);

  useKeyPress(() => {
    if (onCancel) onCancel();
  }, ['Escape']);

  return (
    <Wrapper>
      <ModalWrapper>
        <Title>{ title }</Title>
        <Message>{ message }</Message>
        <ButtonsWrapper cancel={ cancel }>
          <Cancel onClick={ onCancel }>{ cancel }</Cancel>
          <Done onClick={ onDone }>{ done }</Done>
        </ButtonsWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

export default ActionModal;
