import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as warningIcon } from 'Images/warning-delete.svg';
import { fadeIn } from 'Styles/effects';

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .7);
  z-index: 500;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  & > :first-child {
    border-top: 10px solid ${colors.softBlue};
    padding: 36px 39px;
  }
  ${fadeIn(0, 1)}
`;
ModalWrapper.displayName = 'ModalWrapper';

export const Wrapper = styled.div`
  background: ${colors.white};
  max-width: 460px;
  width: 100%;
  text-align: left;
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.44;
  color: ${colors.grey19};
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  padding-bottom: 22px;
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
  color: ${colors.dark2};
`;
Title.displayName = 'Title';

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;
`;
Footer.displayName = 'Footer';

export const DefaultButton = styled.div`
  cursor: pointer;
  width: 89px;
  line-height: 34px;
  margin: 0 8px 0 0;
  border-radius: 2px;
  border: 0px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;
DefaultButton.displayName = 'DefaultButton';

export const Close = styled(DefaultButton)`
  background: ${colors.blueCerulean};
  color: ${colors.white};
`;
Close.displayName = 'Close';

export const WarningIcon = styled(warningIcon)`
  width: 27px;
  margin-right: 13px;
  height: 27px;
`;
WarningIcon.displayName = 'WarningIcon';
