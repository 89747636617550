import React from 'react';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { redirect } from 'store/Router/actions';
import { copyGraph, setGraphId } from 'store/Analytics/actions';
import { MODAL_ANALYTICS_CONFIRM_DELETE } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import {
  Wrapper, TrashIcon, DuplicateIcon, EditIcon
} from './styled';

const Options = ({ id, name }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const edit = intl.formatMessage({ id: 'analytics.options.edit' });
  const duplicate = intl.formatMessage({ id: 'analytics.options.duplicate' });
  const remove = intl.formatMessage({ id: 'analytics.options.remove' });

  const handleGraphSelected = () => dispatch(redirect(`/analytics/${id}`));
  const handleDuplicateGraph = (e) => {
    e.stopPropagation();
    dispatch(copyGraph(id));
  };
  const handleDeleteGraph = (e) => {
    e.stopPropagation();
    dispatch(setGraphId(id, name));
    dispatch(openModal(MODAL_ANALYTICS_CONFIRM_DELETE));
  };

  return (
    <Wrapper>
      <EditIcon onClick={ handleGraphSelected } title={ edit } />
      <DuplicateIcon onClick={ (e) => handleDuplicateGraph(e) } title={ duplicate } />
      <TrashIcon onClick={ (e) => handleDeleteGraph(e) } title={ remove } />
    </Wrapper>
  );
};

Options.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string
};

Options.defaultProps = {
  id: null,
  name: ''
};

export default Options;
