import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import ModalWrapper from 'Common/Components/ModalWrapper';
import {
  Content, Title, Box, Buttons, CancelButton,
  Help, QuestionMark, HelpLink
} from './styled';
import BlueButton from 'Common/Components/BlueButton';
import DragFile from 'Common/Components/DragFile';
import TestingFile from './TestingFile';
import Error from './Error';
import Success from './Success';
import { testCustomTemplate, setUploadState, uploadTemplate } from 'store/ExecutiveReportEditCreate/actions';
import { selectUploadState, selectTemplateFile } from 'store/ExecutiveReportEditCreate/selectors';

const UploadCustomTemplate = ({ setShowModal }) => {
  const uploadState = useSelector(selectUploadState);
  const [step, setStep] = useState('drag');
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const intl = useIntl();
  const getText = id => intl.formatMessage({ id: `reports.editCreate.selectTemplateModal.addTemplate.${id}` });
  const template = useSelector(selectTemplateFile);

  useEffect(() => {
    if (uploadState === 'error') setStep('error');
    if (uploadState === 'success') setStep('success');
  }, [uploadState]);

  useEffect(() => {
    return () => {
      setStep('drag');
      dispatch(setUploadState(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrop = (files) => {
    setName(files[0].name);
    setStep('testing');
    dispatch(testCustomTemplate(files[0], files[0].name));
  };

  const upload = () => {
    dispatch(uploadTemplate(template));
    setShowModal(false);
  };

  const RenderStep = {
    drag: <DragFile onManage handleDrop={ handleDrop } />,
    testing: <TestingFile name={ name } />,
    error: <Error setStep={ setStep } />,
    success: <Success name={ name } />
  };

  return (
    <ModalWrapper>
      <Content>
        <Title> { getText('title') }</Title>
        <Box>
          { RenderStep[step] }
        </Box>
        <Help>
          <QuestionMark />
          <HelpLink href="https://docs.faradaysec.com/How-to-build-your-template/" target="_blank">{ getText('help') }</HelpLink>
        </Help>
        <Buttons>
          <CancelButton onClick={ () => setShowModal(false) }>{ intl.formatMessage({ id: 'common.components.button.cancel' }) }</CancelButton>
          <BlueButton onClick={ upload } label={ getText('add') } disabled={ step !== 'success' } />
        </Buttons>
      </Content>
    </ModalWrapper>

  );
};

export default UploadCustomTemplate;
