/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import 'draft-js-mention-plugin/lib/plugin.css';
import { selectUserMentions } from 'store/Manage/selectors';
import { useSelector } from 'react-redux';
import Entry from './Entry';

const MentionPlugin = ({ Component }) => {
  const userMentions = useSelector(selectUserMentions);
  const [suggestions, setSuggestions] = useState(userMentions);

  const onSearchChange = ({ value }) => setSuggestions(defaultSuggestionsFilter(value, userMentions));

  return (
    <Component
      onSearchChange={ onSearchChange }
      suggestions={ suggestions }
      entryComponent={ Entry }
    />
  );
};

MentionPlugin.propTypes = {
  Component: PropTypes.node.isRequired
};

export default MentionPlugin;
