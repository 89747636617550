import styled from 'styled-components';
import colors from 'Styles/colors';
import { ellipsisText } from 'Styles/styles';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as _Error } from 'Images/error-mark.svg';
import { FaPlus } from 'react-icons/fa';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 26px 20px;
`;
Wrapper.displayName = 'Wrapper';

export const TrashWrapper = styled.div`
  margin-left: auto;
  padding-left: 20px;
  opacity: 0;
`;

export const DataContainer = styled.div`
    //width: 400px;      
    background-color: ${colors.white};
    box-shadow: 0 2px 50px 0 ${colors.iceBlue};
    //max-height: 117px;
    overflow-y: auto;
`;
DataContainer.displayName = 'DataContainer';

export const DataItem = styled.div`    
    font-size: 13px;
    color: #1c2243;
    cursor: pointer;
    padding: 0 14px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => (props.isOdd ? `${colors.iceBlue}` : '')};

    &:hover{
      background-color: #f1f4f7;
      font-weight: 500;

      & ${TrashWrapper}{
        opacity: 1;
      }
  }
`;
DataItem.displayName = 'DataItem';

export const Placeholder = styled.a`
  text-align: left;
  font-size: 13.5px;
  line-height: 2.52;
  margin-right: auto;
  ${ellipsisText}
`;
Placeholder.displayName = 'Placeholder';

export const InputComp = styled.input`
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: transparent;
  border-width: 1px 1px 0px 1px;
  box-shadow: 0 1px 0 0 #afafaf;
  background-color: #edf2f7;
  padding-left: 15px;
  padding-right: 42px;
  font-weight: 300 !important;
  margin-bottom: 2px;

  &.full{
    background-color: white; 
  }

  &.responsive {
      width:100%;
  }

  &::placeholder {
    color: #afb1bc;
    font-size: 13.5px;
  }

  &:hover{
    border-style: solid;
    border-color: #afb1bc;
    border-width: 1px 1px 0px 1px;
  }

  &:focus{
    background-color: white;

    &::placeholder {
        color: transparent;
    }
  }
`;
InputComp.displayName = 'InputComp';

export const Plus = styled(FaPlus)`
  position: absolute;
  right: 14px;
  top: 14px;
  height: 12px;
  width: 12px;
  cursor: pointer;
  color: #515a65;
  &:hover{
      color: white;       
  }
`;

export const Trash = styled(TrashIcon)` 
    width: 16px;
    height: 17px;
    align-self: flex-end;
    color: ${colors.grey12};
`;

export const Error = styled.div`
  color: #ea3158;
  position: absolute;
  font-size: 10.5px;
  letter-spacing: 0.08px;
  top: 12px;
  right: 35px;
`;

export const InputWrapper = styled.div`
  height: 38px;
  position: relative;
`;

export const ErrorIcon = styled(_Error)`
  margin-right: 5px;
`;
ErrorIcon.displayName = 'ErrorIcon';
