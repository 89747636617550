import styled from 'styled-components';
import React from 'react';
import { PropTypes } from 'prop-types';

export const Wrapper = styled.div`
    display: flex;
    padding-left: 13px;
    padding-right: 1px;
    flex-direction: column;
    margin-top: 15px;
    >:nth-child(n+2){
      margin-bottom: 15px;
    }
`;
Wrapper.displayName = 'Wrapper';

const TitleStyle = styled.div`
  margin-bottom: 33px;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  text-align: left;
  user-select: none;
`;
TitleStyle.displayName = 'TitleStyle';

const EditSubtitleStyle = styled.div`
  margin-bottom: 15px;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8f94b2;
  text-align: left;
  user-select: none;
`;
EditSubtitleStyle.displayName = 'EditSubtitleStyle';

const AddSubtitleStyle = styled(EditSubtitleStyle)`
  margin-top: 15px;
`;
AddSubtitleStyle.displayName = 'AddSubtitleStyle';

export const Title = ({ label }) => (
  <TitleStyle>
    { label }
  </TitleStyle>
);

export const EditSubtitle = ({ label }) => (
  <EditSubtitleStyle>
    { label }
  </EditSubtitleStyle>
);

export const AddSubtitle = ({ label }) => (
  <AddSubtitleStyle>
    { label }
  </AddSubtitleStyle>
);

Title.propTypes = {
  label: PropTypes.string.isRequired
};

EditSubtitle.propTypes = {
  label: PropTypes.string.isRequired
};
AddSubtitle.propTypes = {
  label: PropTypes.string.isRequired
};
