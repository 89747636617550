import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  padding: 30px;
  justify-content: center;
  & > *:not(:last-child) {
    margin-right: 54px;
  }
`;
Wrapper.displayName = 'Wrapper';
