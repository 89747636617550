import React from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Wrapper from './styled';
import NonBooleanField from 'Screens/Automation/Agents/components/Grid/components/CloudAgents/components/RunCloudAgentModal/AgentParams/components/NonBooleanField';
import BooleanField from 'Screens/Automation/Agents/components/Grid/components/CloudAgents/components/RunCloudAgentModal/AgentParams/components/BooleanField';

const AgentParams = ({ currentAgent, onChangeField, targetError, defaultParams }) => {
  const parameters = get(currentAgent, 'value.params', {});
  const booleanParams = Object.keys(parameters).filter((param) => parameters[param].base === 'boolean');
  const nonBooleanParams = Object.keys(parameters).filter((param) => parameters[param].base !== 'boolean');

  if (isEmpty(parameters)) return null;
  return (
    <Wrapper>
      { nonBooleanParams.map((param) => <NonBooleanField defaultValue={ get(defaultParams, param, '') } key={ param } param={ param } parameters={ parameters } onChangeField={ onChangeField } targetError={ targetError } />) }
      { booleanParams.map((param) => <BooleanField defaultValue={ get(defaultParams, param, false) } key={ param } param={ param } parameters={ parameters } onChangeField={ onChangeField } />)}
    </Wrapper>
  );
};

export default AgentParams;
