/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setSamlField } from 'store/Settings/saml/actions';
import { selectIdpCertificate, selectIsEnabled } from 'store/Settings/saml/selectors';
import TextArea from 'Common/Components/v2/TextArea';
import TextAreaWrapper from './styled';

export const IdpCert = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const enabled = useSelector(selectIsEnabled);
  const value = useSelector(selectIdpCertificate);
  const idpCertificate = intl.formatMessage({ id: 'preferences.saml.idpCertificate' });

  const onChange = (v) => dispatch(setSamlField('idpCertificate', v));

  return (
    <TextAreaWrapper>
      <TextArea defaultValue={ value } disabled={ !enabled } required title={ idpCertificate } onChange={ onChange } />
    </TextAreaWrapper>
  );
};
