import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjects, selectProjectsIsEmpty } from 'store/Projects/selectors';
import { useIntl } from 'react-intl';
import { getProjects } from 'store/Projects/actions';
import { clearTasks, getTasks } from 'store/Tasks/actions';
import { selectTasksByProjectId } from 'store/Tasks/selectors';
import {
  Blue, Wrapper, ProjectWrapper, ProjectHeader, ProjectTitle, Arrow, EmptyWrapper
} from './styled';
import Empty from '../Empty';
import Task from '../Tasks';

const Project = ({ id, name }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const tasks = useSelector((state) => selectTasksByProjectId(state, id));
  const intl = useIntl();

  const ProjectTasks = () => {
    if (tasks.length === 0) {
      return (
        <EmptyWrapper>
          {intl.formatMessage({ id: 'quickPlanner.project.empty.text' })}
          <Blue to="/planner">{ `${intl.formatMessage({ id: 'quickPlanner.project.empty.planner' })}.`}</Blue>
        </EmptyWrapper>
      );
    }

    return (
      <>
        {tasks.map((task) => <Task key={ task.id } projectId={ id } taskId={ task.id } />)}
      </>
    );
  };

  const handleGetTasks = () => {
    if (isOpen) dispatch(clearTasks(id));
    else dispatch(getTasks(id));
    toggleOpen();
  };

  return (
    <ProjectWrapper>
      <ProjectHeader onClick={ handleGetTasks }>
        <ProjectTitle>{name}</ProjectTitle>
        <Arrow isOpen={ isOpen } />
      </ProjectHeader>
      {isOpen && <ProjectTasks />}
    </ProjectWrapper>
  );
};

const Projects = () => {
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  const isEmpty = useSelector(selectProjectsIsEmpty);

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  if (isEmpty) return <Empty />;

  return (
    <Wrapper>
      {projects.map((project) => <Project key={ `project-${project.id}` } id={ project.id } name={ project.name } />)}
    </Wrapper>
  );
};

export default Projects;

Project.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
};
