/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  ExpanderWrapper, HeaderContainer
} from './styled';

import {
  Title, Subtitle, Icons, Child
} from './components';

const Expander = ({
  children, title, subtitle, isRemovable, canExpand, onDelete, onAdd, id, startExpanded
}) => {
  const [isExpanded, setExpanded] = useState(startExpanded);
  const removeSection = () => onDelete(id);
  const addSection = () => onAdd(id);
  const toggleExpanded = () => setExpanded(!isExpanded);
  const onClickHandler = canExpand ? toggleExpanded : addSection;
  return (
    <ExpanderWrapper>
      <HeaderContainer onClick={ onClickHandler }>
        <Title label={ title } />
        <Icons
          canExpand={ canExpand }
          isExpanded={ isExpanded }
          isRemovable={ isRemovable }
          onRemove={ removeSection }
        />
      </HeaderContainer>
      <Child isExpanded={ isExpanded }>
        <Subtitle label={ subtitle } />
        { children }
      </Child>
    </ExpanderWrapper>
  );
};

Expander.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isRemovable: PropTypes.bool,
  canExpand: PropTypes.bool,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  startExpanded: PropTypes.bool
};

Expander.defaultProps = {
  children: null,
  title: '',
  subtitle: '',
  isRemovable: true,
  canExpand: true,
  onDelete: null,
  onAdd: null,
  startExpanded: false
};

export default Expander;
