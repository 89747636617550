import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  NameCell, EditDeleteCell, AddTaskCell
} from './components/Cells';

const getSplitViewColumns = (isFetching, isPivot) => {
  const columns = [
    {
      Header: () => null,
      show: false,
      id: 'expander',
      expander: true,
      Expander: () => null
    }, {
      Header: () => <FormattedMessage id="planner.project.name" />,
      id: 'name',
      resizable: false,
      sortable: false,
      headerStyle: { borderRight: '1px dashed transparent' },
      Cell: (rowInfo) => <NameCell isFetching={ isFetching } isPivot={ isPivot } rowInfo={ rowInfo } />
    }, {
      Header: () => <FormattedMessage id="planner.project.startDate" />,
      id: 'startDate',
      className: isPivot ? 'actionButton' : '',
      resizable: false,
      sortable: false,
      width: 110,
      Cell: (rowInfo) => <EditDeleteCell isPivot={ isPivot } isFetching={ isFetching } rowInfo={ rowInfo } />
    }, {
      Header: () => <FormattedMessage id="planner.project.endDate" />,
      id: 'endDate',
      className: isPivot ? 'actionButton' : '',
      resizable: false,
      sortable: false,
      width: 110,
      Cell: (rowInfo) => <AddTaskCell isPivot={ isPivot } isFetching={ isFetching } rowInfo={ rowInfo } />
    }
  ];
  return columns;
};

export default getSplitViewColumns;
