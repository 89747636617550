/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import itemTypes from 'Common/Components/DragAndDrop/ItemTypes';
import Vuln from 'Screens/Planner/Tasks/CreateEdit/components/Tabs/LinkedVulns/VulnPreview';
import { useDispatch, useSelector } from 'react-redux';
import { addLinkedVuln, removeLinkedVuln } from 'store/Tasks/actions';
import { selectTaskById } from 'store/Tasks/selectors';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import {
  Arrow, Wrapper, Name, VulnCount, Header, DropZone, VulnsListWrapper
} from './styled';

const Task = ({ projectId, taskId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const task = useSelector((state) => selectTaskById(state, taskId));
  const linkedVulns = get(task, 'linkedVulns', []);
  const hasLinkedVulns = !isEmpty(linkedVulns);
  const showLinkedVulns = isOpen && hasLinkedVulns;
  const name = get(task, 'name', '');
  const isMilestone = get(task, 'type', '') === 'milestone';
  const normalDragMessage = intl.formatMessage({ id: 'quickPlanner.drag' });
  const alreadyDraggedMessage = intl.formatMessage({ id: 'quickPlanner.drag.alreadyDragged' });
  const isMilestoneMessage = intl.formatMessage({ id: 'quickPlanner.drag.isMilestone' });
  const vulnsCountTooltip = intl.formatMessage({ id: 'quickPlanner.vulnsCount.tooltip' });
  const dragMessage = (canDrop) => {
    if (isMilestone) return isMilestoneMessage;
    if (canDrop) return normalDragMessage;
    return alreadyDraggedMessage;
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  const addVulnToTask = (vuln) => {
    const linkedVuln = {
      id: vuln.id,
      name: vuln.name,
      severity: vuln.severity,
      confirmed: vuln.confirmed,
      allowed: true
    };
    dispatch(addLinkedVuln(projectId, taskId, linkedVuln));
  };

  const deleteVulnFromTask = (vulnId) => dispatch(removeLinkedVuln(projectId, taskId, vulnId));

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: itemTypes.VULN,
    drop: (item) => addVulnToTask(item),
    canDrop: (item) => !linkedVulns.some((vuln) => vuln.id === item.id) && !isMilestone,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }), [linkedVulns]);

  return (
    <Wrapper ref={ drop }>
      {isOver && <DropZone canDrop={ canDrop } children={ dragMessage(canDrop) } /> }
      <Header canClick={ !isEmpty(linkedVulns) } onClick={ toggleOpen }>
        <Name children={ name } isMilestone={ isMilestone } />
        <VulnCount children={ linkedVulns.length } title={ vulnsCountTooltip } />
        <Arrow showArrow={ !isEmpty(linkedVulns) } isOpen={ isOpen } />
      </Header>
      { showLinkedVulns && (
        <VulnsListWrapper>
          {linkedVulns.map((vuln) => <Vuln key={ vuln.id } vuln={ vuln } deleteVuln={ () => deleteVulnFromTask(vuln.id) } />)}
        </VulnsListWrapper>
      )}
    </Wrapper>
  );
};

export default Task;

Task.propTypes = {
  projectId: PropTypes.number.isRequired,
  taskId: PropTypes.number.isRequired
};
