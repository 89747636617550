import React from 'react';
import get from 'lodash/get';
import { FormattedMessage, useIntl } from 'react-intl';
import Skeleton from 'Common/Components/Skeleton';
import { Cell, AliasCellStyled, RevokeButton, TrashIcon } from './styled';
import IconButton from 'Common/Components/IconButton';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_USER_TOKENS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useDispatch, useSelector } from 'react-redux';
import { showActionModal } from 'store/Settings/accessTokens/actions';
import timeSince from 'Common/Functions/timeSince';
import DateFormatter from 'Common/Components/DateFormatter';

export const GenericCell = ({ rowInfo, isFetching, column }) => {
  const intl = useIntl();
  const text = get(rowInfo, `original.${column}`, '');
  const parsedText = () => {
    if (column === 'token') return '*****************';
    else if (column === 'scope') return intl.formatMessage({ id: `preferences.accessTokens.table.scope.${text}` });
    else return text;
  };

  if (isFetching) return <Skeleton />;
  return <Cell>{ parsedText() }</Cell>;
};

export const AliasCell = ({ rowInfo, isFetching }) => {
  const name = get(rowInfo, 'original.alias', '');

  if (isFetching) return <Skeleton />;
  return <AliasCellStyled>{ name }</AliasCellStyled>;
};

export const CreatedCell = ({ rowInfo, isFetching, column }) => {
  const date = get(rowInfo, `original.${column}`, '');
  const parsedDate = timeSince(date);

  if (isFetching) return <Skeleton />;
  return <Cell>{ parsedDate }</Cell>;
};

export const ExpiredCell = ({ rowInfo, isFetching, column }) => {
  const date = get(rowInfo, `original.${column}`, '');

  if (isFetching) return <Skeleton />;
  return <Cell>{ date ? <DateFormatter date={ date } midDate /> : 'Never' }</Cell>;
};

export const ActionsCell = ({ rowInfo, isFetching, tab }) => {
  const dispatch = useDispatch();
  const id = get(rowInfo, 'original.id', 0);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_USER_TOKENS, PERMISSION_TYPES.PATCH) &&
    selectAllowedByFeature(state, FEATURE_USER_TOKENS, PERMISSION_TYPES.PATCH));

  const handleClick = (field, id) => {
    if (canUpdate) dispatch(showActionModal(true, field, id));
  };

  if (isFetching) return <Skeleton />;
  if (tab === 'inactive') return <IconButton icon={ <TrashIcon /> } title="Delete" onClick={ () => handleClick('hide', id) } />;
  return (
    <RevokeButton onClick={ () => handleClick('revoked', id) }>
      <FormattedMessage id="preferences.accessTokens.table.actions.revoke" />
    </RevokeButton>
  );
};
