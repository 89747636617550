import Importance from 'Common/Components/Importance';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHost } from 'store/Host/actions';
import { updateHostGeneral } from 'store/HostsGeneral/actions';
import { Wrapper } from './styled';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';

const ImportanceColumn = ({ host, assetsGeneral }) => {
  const dispatch = useDispatch();
  const [importance, setImportance] = useState();
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.UPDATE));
  const canUpdate = !readOnly && allowedByRole;

  useEffect(() => {
    if (host && host.importance) setImportance(host.importance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host]);

  const callback = (importance) => {
    if (canUpdate) {
      setImportance(importance);
      if (assetsGeneral) dispatch(updateHostGeneral(host._id, { importance }, host.workspace_name));
      else dispatch(updateHost(host._id, { importance }));
    }
  };

  return (
    <Wrapper>
      <Importance importance={ importance } callback={ callback } />
    </Wrapper>
  );
};

export default ImportanceColumn;
