import get from 'lodash/get';
import SAVE_CONFIG from './constants';

export const initialState = {
  maxWorkspacesLimit: 2,
  maxUsersLimit: 2,
  maxHostsLimit: 2,
  maxSchedulesLimit: null,
  maxJobsLimit: null,
  licenseType: '',
  cloudInstance: false,
  ssoEnabled: false,
  ldapUseLocalRoles: true,
  apiVersion: ''
};

function config (state = initialState, action) {
  switch (action.type) {
    case SAVE_CONFIG: {
      return {
        ...state,
        maxWorkspacesLimit: get(action, 'maxWorkspacesLimit', initialState.maxWorkspacesLimit),
        maxUsersLimit: get(action, 'maxUsersLimit', initialState.maxUsersLimit),
        maxHostsLimit: get(action, 'maxHostsLimit', initialState.maxHostsLimit),
        maxSchedulesLimit: get(action, 'maxSchedulesLimit', initialState.maxSchedulesLimit),
        maxJobsLimit: get(action, 'maxJobsLimit', initialState.maxJobsLimit),
        licenseType: get(action, 'licenseType', initialState.licenseType),
        cloudInstance: get(action, 'cloudInstance', initialState.cloudInstance),
        ssoEnabled: get(action, 'ssoEnabled', initialState.ssoEnabled),
        ldapUseLocalRoles: get(action, 'ldapUseLocalRoles', initialState.ldapUseLocalRoles),
        apiVersion: get(action, 'apiVersion', initialState.apiVersion)
      };
    }

    default: {
      return state;
    }
  }
}

export default config;
