import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentImpacts, selectVulnDetail } from 'store/Manage/selectors';
import { updateVuln } from 'store/Manage/actions';
import Impacts from 'Common/Components/Impacts';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import ExpandableWrapper from '../ExpandableWrapper';
import Wrapper from './styled';
import useExpandable from 'Hooks/useExpandable';
import { selectIsReadOnly } from 'store/Faraday/selectors';

const TAB = 'general';
const ID = 'impact';

const ImpactsTab = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const initialValue = useSelector(selectCurrentImpacts);
  const currentVuln = useSelector(selectVulnDetail);
  const readOnly = useSelector(selectIsReadOnly);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const disabled = readOnly || !canUpdate;

  const [impacts, setImpacts] = useState(initialValue);

  const getDefaultValue = () => {
    return (
      impacts.accountability ||
      impacts.availability ||
      impacts.integrity ||
      impacts.accountability
    );
  };

  const isExpanded = useExpandable(TAB, ID, getDefaultValue());

  useEffect(() => {
    setImpacts(initialValue);
  }, [initialValue]);

  const onSelectCheckbox = (impactChanged) => {
    const newImpacts = { ...impacts, [impactChanged]: !impacts[impactChanged] };
    setImpacts(newImpacts);
    dispatch(updateVuln(currentVuln, 'impact', newImpacts, true));
  };

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.impact' }) } tab={ TAB } id={ ID }>
      <Wrapper>
        <Impacts
          selectCheckbox={ onSelectCheckbox }
          hideTitle
          impacts={ impacts }
          disabled={ disabled }
        />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default ImpactsTab;
