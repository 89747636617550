import * as types from 'store/ExecutiveReportEditCreate/types';
import { copy } from '../../Common/Functions/Copy';
import initialState from './initialState';

function executiveReportEditCreate (state = initialState, action) {
  switch (action.type) {
    case types.RESET_STATE_EXECUTVE_REPORT_CREATE_UPDATE: {
      return {
        ...copy(initialState)
      };
    }

    case types.SET_INPUT_FIELD: {
      return {
        ...state,
        [action.label]: action.value
      };
    }

    case types.SET_FILTER_ARRAY: {
      return {
        ...state,
        filterArray: action.filterArray
      };
    }

    case types.GET_VULNS_EXECUTIVE_REPORT_CREATE_UPDATE_SUCCESS: {
      return {
        ...state,
        warnings: action.warnings,
        total_vulns: action.total_vulns
      };
    }

    case types.HIDE_WARNINGS_EXECUTIVE_REPORT_CREATE_UPDATE: {
      return {
        ...state,
        warnings: []
      };
    }

    case types.EXECUTIVE_REPORT_CREATE_UPDATE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.EXECUTIVE_REPORT_CREATE_UPDATE_VALIDATION_FAIL: {
      return {
        ...state,
        validationError: action.validationError,
        isFetching: false
      };
    }

    case types.EXECUTIVE_REPORT_CREATE_UPDATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error ? action.error : 'An error has occurred'
      };
    }

    case types.EXECUTIVE_REPORT_CREATE_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }

    case types.SET_REPORT_CREATE_UPDATE: {
      const {
        grouped,
        template_name
      } = action.report;
      return {
        ...state,
        isFetching: false,
        title: action.report.title,
        enterprise: action.report.enterprise,
        selectedTemplate: [grouped, template_name],
        advancedFilter: action.report.advanced_filter_parsed,
        summary: action.report.summary,
        conclusions: action.report.conclusions,
        recommendations: action.report.recommendations,
        scope: action.report.scope,
        id: action.report.id,
        objectives: action.report.objectives,
        confirmed: action.report.confirmed,
        workspaces: action.report.workspaces
      };
    }

    case types.GET_PREVIEW_DATA_START_REPORT: {
      return {
        ...state,
        isFetchingPreview: true,
        isFetching: true
      };
    }

    case types.GET_PREVIEW_DATA_SUCCESS_REPORT: {
      return {
        ...state,
        previewData: action.data,
        previewError: '',
        total_vulns: action.count,
        isFetchingPreview: false,
        isFetching: false,
        parseError: false
      };
    }

    case types.GET_PREVIEW_DATA_FAIL_REPORT: {
      return {
        ...state,
        previewData: [],
        previewError: action.error,
        total_vulns: 0,
        isFetchingPreview: false,
        isFetching: false
      };
    }

    case types.CLEAR_PARSE_ADVANCED_FILTER_ERROR: {
      return {
        ...state,
        isFetchingPreview: false,
        isFetching: false,
        parseError: ''
      };
    }

    case types.SET_PARSE_ADVANCED_FILTER_ERROR: {
      return {
        ...state,
        previewData: [],
        total_vulns: 0,
        isFetchingPreview: false,
        isFetching: false,
        parseError: action.error
      };
    }

    case types.RESET_TEMP_TEMPLATE_EXECUTIVE_REPORT_CREATE_UPDATE: {
      return {
        ...state,
        selectTemplateTemp: {}
      };
    }

    case types.SET_CUSTOM_LOGO: {
      return {
        ...state,
        custom_logo: {
          [action.name]: {
            content_type: action.content_type,
            data: action.data
          },
          logoFile: action.logoFile
        }
      };
    }

    case types.RESET_CUSTOM_LOGO: {
      return {
        ...state,
        custom_logo: {}
      };
    }

    case types.GET_CUSTOM_LOGO_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }

    case types.SET_TEMPLATE: {
      return {
        ...state,
        templateFile: action.fd
      };
    }

    case types.SET_ADD_TEMPLATE_STATE: {
      return {
        ...state,
        uploadState: action.uploadState
      };
    }

    case types.CUSTOM_TEMPLATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error ? action.error : 'An error has occurred',
        error: true
      };
    }

    case types.RESET_ERROR_VALUE: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }

    default:
      return state;
  }
}

export default executiveReportEditCreate;
