import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  ElementWrapper, PDFIcon, CsvIcon, DropdownLabel, WordIcon
} from './styled';

const ExportItem = ({ type, exportAction }) => {
  const getIcon = () => {
    if (type === 'pdf') return <PDFIcon />;
    if (type === 'doc') return <WordIcon />;
    return <CsvIcon />;
  };

  return (
    <ElementWrapper onClick={ () => exportAction(type) }>
      { getIcon() }
      <DropdownLabel>
        <FormattedMessage id={ `reports.table.dropdown.export.${type}` } />
      </DropdownLabel>
    </ElementWrapper>
  );
};

ExportItem.propTypes = {
  type: PropTypes.string.isRequired,
  exportAction: PropTypes.func.isRequired
};

export default ExportItem;
