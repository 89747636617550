/* eslint-disable react/no-children-prop */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { selectFilterHistory } from 'store/Filters/selectors';
import { useIntl } from 'react-intl';
import { Wrapper, FolderIcon, NoVulnsTitle } from './styled';

const NoReportsPreview = ({ show }) => {
  const filters = useSelector((state) => selectFilterHistory('reports', state));
  const intl = useIntl();
  const noVulns = intl.formatMessage({ id: 'reports.editCreate.preview.noVulns' });
  const emptyReport = intl.formatMessage({ id: 'reports.editCreate.preview.emptyReport' });
  const title = filters.length > 0 ? noVulns : emptyReport;
  return show
    ? (
    <Wrapper>
      <FolderIcon />
      <NoVulnsTitle children={ title } />
    </Wrapper>
      )
    : null;
};

NoReportsPreview.propTypes = {
  show: PropTypes.bool
};

NoReportsPreview.defaultProps = {
  show: true
};

export default NoReportsPreview;
