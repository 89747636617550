import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import AddButton from 'Common/Components/AddButton';

const AddProject = ({ show, onClick }) => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'planner.project.add' });
  if (!show) return null;
  return <AddButton onClick={ onClick } title={ title } text={ title } />;
};

AddProject.propTypes = {
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default AddProject;
