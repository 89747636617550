/* eslint-disable react/no-children-prop */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { selectTaskId } from 'store/Tasks/selectors';
import { FEATURE_COMMENTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import CommentTextArea from 'Common/Components/CommentTextArea';
import { useIntl } from 'react-intl';
import {
  addTaskComment, updateTaskComment
} from 'store/Tasks/actions';
import { Wrapper, Title } from './styled';

const CreateEdit = ({ commentToEdit, setCommentToEdit }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const taskId = useSelector(selectTaskId);
  const placeholder = intl.formatMessage({ id: 'manage.detail.tab.comment.placeholder' });
  const titleLabel = intl.formatMessage({ id: 'planner.task.tab.comments' });
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_COMMENTS, PERMISSION_TYPES.CREATE));
  const readOnly = !taskId;
  const disabled = readOnly || !canUpdate;

  return (
    <Wrapper>
      <Title children={ titleLabel } />
      <CommentTextArea
        addComment={ (data) => dispatch(addTaskComment(data)) }
        commentToEdit={ commentToEdit }
        disabled={ disabled }
        editComment={ (data) => dispatch(updateTaskComment(data)) }
        entity="project_task"
        entityId={ taskId }
        placeholder={ placeholder }
        setCommentToEdit={ setCommentToEdit }
      />
    </Wrapper>
  );
};

CreateEdit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  commentToEdit: PropTypes.object,
  setCommentToEdit: PropTypes.func.isRequired
};

CreateEdit.defaultProps = {
  commentToEdit: null
};

export default CreateEdit;
