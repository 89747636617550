import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { setField } from 'store/Settings/serviceNowActions';
import { selectTemplate } from 'store/Settings/serviceNowSelectors';
import { selectTemplates } from 'store/Settings/settingsSelectors';
import WrapperTemplate from './styled';
import { Label } from '../IncidentConfiguration/styled';

const Template = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const templates = useSelector(selectTemplates);
  const selectedTemplate = useSelector(selectTemplate);

  const onChange = (field, value) => {
    dispatch(setField(field, value));
  };

  return (
    <WrapperTemplate>
      <Label>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.incidentConfiguration.template.title' }) }</Label>
      <StandardDropdown field="template" options={ templates } updateValue={ onChange } value={ selectedTemplate } defaultValue={ selectedTemplate } width="425px" />
    </WrapperTemplate>
  );
};

export default Template;
