import React from 'react';
import Header from '../Header';
import Items from '../Items';
import { Section, ContentTitle, ContentWrapper, Templates } from '../../styled';

const Default = ({ title, onClose, onSelection }) => (
  <Section>
    <Header onClose={ onClose } />
    <ContentWrapper>
      <ContentTitle>{ title }</ContentTitle>
        <Templates>
          <Items onSelection={ onSelection } defaultTemplates />
        </Templates>
    </ContentWrapper>
  </Section>
);

export default Default;
