import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateEdit from 'Screens/Planner/Tasks/CreateEdit/components/Tabs/Comments/components/CreateEdit';
import List from 'Screens/Planner/Tasks/CreateEdit/components/Tabs/Comments/components/List';
import { setSelectedComment } from 'store/Tasks/actions';
import { selectTaskComments } from 'store/Tasks/selectors';
import { openModal } from 'store/modals/actions';
import { MODAL_TASKS_COMMENT_DELETE } from 'store/modals/modals';
import { Wrapper } from './styled';

const CommentTab = () => {
  const [commentToEdit, setCommentToEdit] = useState(null);
  const comments = useSelector(selectTaskComments);
  const dispatch = useDispatch();

  const onClickEdit = (comment) => {
    setCommentToEdit(comment);
  };

  const onClickRemove = (comment) => {
    dispatch(setSelectedComment(comment));
    dispatch(openModal(MODAL_TASKS_COMMENT_DELETE));
  };

  return (
    <Wrapper>
      <CreateEdit commentToEdit={ commentToEdit } setCommentToEdit={ setCommentToEdit } />
      <List comments={ comments } onClickEdit={ onClickEdit } onClickRemove={ onClickRemove } />
    </Wrapper>
  );
};

export default CommentTab;
