import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import api from 'services/api';

export const GET_TAGS_LIST_START = 'GET_TAGS_LIST_START';
export const GET_TAGS_LIST_SUCCESS = 'GET_TAGS_LIST_SUCCESS';
export const GET_TAGS_LIST_ERROR = 'GET_TAGS_LIST_ERROR';
export const SHOW_CREATE_TAG_MODAL = 'SHOW_CREATE_TAG_MODAL';
export const SET_TAGS_IMPORT_MODAL = 'SET_TAGS_IMPORT_MODAL';
export const GET_HOSTS_TAGS_LIST_SUCCESS = 'GET_HOSTS_TAGS_LIST_SUCCESS';
export const GET_SERVICES_TAGS_LIST_SUCCESS = 'GET_SERVICES_TAGS_LIST_SUCCESS';
export const GET_GENERAL_TAGS_LIST_SUCCESS = 'GET_GENERAL_TAGS_LIST_SUCCESS';
export const GET_GENERAL_HOSTS_TAGS_LIST_SUCCESS = 'GET_GENERAL_HOSTS_TAGS_LIST_SUCCESS';
export const GET_GENERAL_SERVICES_TAGS_LIST_SUCCESS = 'GET_GENERAL_SERVICES_TAGS_LIST_SUCCESS';

export function getTags () {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    dispatch({ type: GET_TAGS_LIST_START });
    try {
      const response = await api.tags.getTags(workspaceSelected);
      return dispatch({ type: GET_TAGS_LIST_SUCCESS, data: response.rows });
    } catch (e) {
      return dispatch({ type: GET_TAGS_LIST_ERROR });
    }
  };
}

export function setCreationTagModal (show, entity) {
  return (dispatch) => {
    dispatch({ type: SHOW_CREATE_TAG_MODAL, show, entity });
  };
}

export function setTagsImportModal (tags) {
  return (dispatch) => {
    dispatch({ type: SET_TAGS_IMPORT_MODAL, tags });
  };
}

export function getHostsTags () {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    dispatch({ type: GET_TAGS_LIST_START });
    try {
      const response = await api.tags.getHostsTags(workspaceSelected);
      return dispatch({ type: GET_HOSTS_TAGS_LIST_SUCCESS, data: response.rows });
    } catch (e) {
      return dispatch({ type: GET_TAGS_LIST_ERROR });
    }
  };
}

export function getServicesTags () {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    dispatch({ type: GET_TAGS_LIST_START });
    try {
      const response = await api.tags.getServicesTags(workspaceSelected);
      return dispatch({ type: GET_SERVICES_TAGS_LIST_SUCCESS, data: response.rows });
    } catch (e) {
      return dispatch({ type: GET_TAGS_LIST_ERROR });
    }
  };
}

export function getTagsForExecutiveReports (workspaces) {
  return async (dispatch) => {
    dispatch({ type: GET_TAGS_LIST_START });
    try {
      let tags = [];
      const promises = workspaces.map((ws) => {
        return api.tags.getTags(ws);
      });

      const res = await Promise.all(promises);
      res.forEach(({ rows }) => {
        if (rows.length > 0) tags = [...tags, ...rows];
      });

      return dispatch({ type: GET_TAGS_LIST_SUCCESS, data: tags });
    } catch (e) {
      return dispatch({ type: GET_TAGS_LIST_ERROR });
    }
  };
}

export const getGeneralTags = () => async (dispatch) => {
  dispatch({ type: GET_TAGS_LIST_START });
  try {
    const response = await api.tags.getContextTags();
    return dispatch({ type: GET_GENERAL_TAGS_LIST_SUCCESS, data: response.rows });
  } catch (e) {
    return dispatch({ type: GET_TAGS_LIST_ERROR });
  }
};

export const getGeneralHostsTags = () => async (dispatch) => {
  dispatch({ type: GET_TAGS_LIST_START });
  try {
    const response = await api.tags.getContextHostsTags();
    return dispatch({ type: GET_GENERAL_HOSTS_TAGS_LIST_SUCCESS, data: response.rows });
  } catch (e) {
    return dispatch({ type: GET_TAGS_LIST_ERROR });
  }
};

export const getGeneralServicesTags = () => async (dispatch) => {
  dispatch({ type: GET_TAGS_LIST_START });
  try {
    const response = await api.tags.getContextServicesTags();
    return dispatch({ type: GET_GENERAL_SERVICES_TAGS_LIST_SUCCESS, data: response.rows });
  } catch (e) {
    return dispatch({ type: GET_TAGS_LIST_ERROR });
  }
};
