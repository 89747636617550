export const isFirstScanCommand = (command) => command.fileType === 'firstScan';

export const shouldBeShownOnPopUp = (command) => command.fileType === 'file';

export const isProcessingAFirstScan = (commands) => commands.some((c) => isFirstScanCommand(c) && !c.finished);

export const removeDuplicateFirstScanCommands = (commands) => {
  let firstScanCommandCount = 0;
  const newCommandList = [];
  commands.forEach((c) => {
    if (!isFirstScanCommand(c)) {
      newCommandList.push(c);
    }

    if (isFirstScanCommand(c) && c.name !== 'First Scan') {
      newCommandList.push(c);
    }

    if (isFirstScanCommand(c) && c.name === 'First Scan' && firstScanCommandCount === 0) {
      firstScanCommandCount = 1;
      newCommandList.push(c);
    }
  });

  return newCommandList;
};

export const hasFinishedProcessingFirstScanCommand = (commands) => commands.some((c) => isFirstScanCommand(c) && c.finished);
