import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import viewTypes from 'Screens/Planner/Projects/ActionBar/components/ViewSelector/viewTypes';
import { COLORS_BY_PROGRESS, COLORS_BY_TYPE, PERCENTAGE_BY_STATUS } from 'Screens/Planner/Projects/Content/components/Gantt/constants';
import { selectTasks, selectTasksByProjectId } from 'store/Tasks/selectors';

export const selectErrorMessage = (state) => get(state, 'projects.errorMessage', '');
export const selectProjects = (state) => get(state, 'projects.list', []);
export const selectProjectsIsEmpty = (state) => isEmpty(selectProjects(state));
export const selectIsHidingTasks = (state) => get(state, 'projects.isHidingTasks', false);
export const selectProjectById = (state, projectId) => {
  const projectsList = selectProjects(state);
  const project = projectsList.find((p) => p.id === projectId);
  if (project) return project;
  return null;
};

export const selectProject = (state) => get(state, 'projects.selectedProject', null);
export const selectProjectId = (state) => get(state, 'projects.selectedProject.id', 0);
export const selectProjectName = (state) => get(state, 'projects.selectedProject.name', '');

export const selectIsFetching = (state) => get(state, 'projects.isFetching', false);

export const selectViewType = (state) => {
  const viewTypeId = get(state, 'projects.viewType', 'listView');
  return viewTypes[viewTypeId];
};

export const selectGanttData = (state) => {
  const projects = get(state, 'projects.list', []);
  const ganttData = projects.map((project) => {
    const tasks = selectTasksByProjectId(state, project.id);
    const isExpanded = selectTasks(state).some((t) => t.projectId === project.id);
    const isHidingCompletedTasks = selectIsHidingTasks(state);
    const filteredTasks = isHidingCompletedTasks ? tasks.filter((task) => task.status !== 'completed') : tasks;
    const noTasksLabel = 'Your project doesn’t have any tasks or milestones yet.';
    const noFilteredTasksLabel = 'All the tasks of this project are completed.';

    const pStartDate = new Date(project.start_date || project.end_date || project.create_date);
    const pEndDate = new Date(project.end_date || project.start_date || project.create_date);

    const getEndDate = (startDate, endDate) => {
      const posixStartDate = startDate.getTime();
      const posixEndDate = endDate.getTime();
      const daysInterval = 10 * 24 * 3600 * 1000;
      if (posixEndDate - posixStartDate > daysInterval) return endDate;
      return new Date(posixStartDate + daysInterval);
    };

    const t = {
      start: pStartDate,
      end: pEndDate,
      name: project.name,
      id: project.id,
      progress: 25,
      type: 'project',
      styles: COLORS_BY_TYPE.project
    };

    let y = [];

    if (isExpanded) {
      if (isEmpty(filteredTasks)) {
        y = [{
          start: pStartDate,
          end: getEndDate(pStartDate, pEndDate),
          name: isEmpty(tasks) ? noTasksLabel : noFilteredTasksLabel,
          id: `${project.id}-noData`,
          progress: 0,
          styles: COLORS_BY_TYPE.noTask,
          progressName: 'completed',
          type: 'task',
          isDisabled: true,
          project: project.id,
          assignees: [],
          dependencies: []
        }];
      } else {
        y = filteredTasks.map((task) => ({
          start: new Date(task.startDate || task.endDate || task.createDate),
          end: new Date(task.endDate || task.startDate || task.createDate),
          name: task.name,
          id: `${project.id}-${task.id}`,
          progress: PERCENTAGE_BY_STATUS[task.status],
          progressName: task.status,
          type: task.type,
          project: project.id,
          assignees: task.assignees,
          dependencies: task.dependencies.map((dep) => `${project.id}-${dep.id}`),
          styles: task.type === 'task' ? COLORS_BY_PROGRESS[task.status] : COLORS_BY_TYPE[task.type]
        }));
      }
    }

    return [t, ...y];
  });
  return ganttData.reduce((prev, curr) => [...prev, ...curr], []);
};
