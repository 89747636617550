import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectRiskScoreFeatureFlag } from 'store/Faraday/selectors';
import { Wrapper, Text } from './styled';

const ShowRiskScore = ({ currentVuln }) => {
  const risk = useMemo(() => currentVuln.risk, [currentVuln.risk]);
  const isAllowedByLicense = useSelector(selectRiskScoreFeatureFlag);

  const tooltip = 'Faraday Risk Score';

  if (!risk || !isAllowedByLicense) return null;
  if (!risk.score) {
    return (<Wrapper title={ tooltip }>
        <Text>N/A</Text>
            </Wrapper>);
  }

  return (<Wrapper color={ risk.severity } title={ tooltip }>
    <Text>{risk.score}</Text>
          </Wrapper>);
};

export default ShowRiskScore;
