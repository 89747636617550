export const RESET_STATE_HOST_CREATE_UPDATE = 'RESET_STATE_HOST_CREATE_UPDATE';
export const ADD_REPORT_HOST_CREATE_UPDATE = 'ADD_REPORT_HOST_CREATE_UPDATE';
export const REMOVE_REPORT_HOST_CREATE_UPDATE = 'REMOVE_REPORT_HOST_CREATE_UPDATE';
export const IMPORT_REPORT_HOST_CREATE_UPDATE_START = 'IMPORT_REPORT_HOST_CREATE_UPDATE_START';
export const IMPORT_REPORT_HOST_CREATE_UPDATE_FAIL = 'IMPORT_REPORT_HOST_CREATE_UPDATE_FAIL';
export const IMPORT_REPORT_HOST_CREATE_UPDATE_SUCCESS = 'IMPORT_REPORT_HOST_CREATE_UPDATE_SUCCESS';
export const SET_FIELD_NAME_HOST_CREATE_UPDATE = 'SET_FIELD_NAME_HOST_CREATE_UPDATE';
export const REMOVE_HOST_NAME_HOST_CREATE_UPDATE = 'REMOVE_HOST_NAME_HOST_CREATE_UPDATE';
export const ADD_HOST_NAME_HOST_CREATE_UPDATE = 'ADD_HOST_NAME_HOST_CREATE_UPDATE';
export const SET_HOST_NAME_HOST_CREATE_UPDATE = 'SET_HOST_NAME_HOST_CREATE_UPDATE';
export const HOST_CREATE_UPDATE_START = 'HOST_CREATE_UPDATE_START';
export const HOST_CREATE_UPDATE_FAIL = 'HOST_CREATE_UPDATE_FAIL';
export const HOST_CREATE_UPDATE_SUCCESS = 'HOST_CREATE_UPDATE_SUCCESS';
export const SET_HOST_CREATE_UPDATE = 'SET_HOST_CREATE_UPDATE';
export const HOST_CREATE_UPDATE_ERROR = 'HOST_CREATE_UPDATE_ERROR';
export const SET_HOST_FOR_EDIT = 'SET_HOST_FOR_EDIT';
