import React, { useEffect, useState } from 'react';
import { Plus } from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/CVE/styled';
import api from 'services/api';
import { Wrapper, DataContainer, DataItem, Error, IconWrapper, InputComp, InputWrapper, Loading, Subtitle, Title, Trash } from './styled';
import { useSelector } from 'react-redux';
import { selectJiraUsers } from 'store/Settings/jiraSelectors';

const JiraUserAssign = () => {
  const [users, setUsers] = useState([]);
  const jiraUsers = useSelector(selectJiraUsers);

  useEffect(() => {
    function transformArray (inputArray) {
      // We use the map function to apply a transformation to each item in the array
      return inputArray.map(item => {
        // Construct the name property by adding the username and email format
        const name = `${item.username}`;

        // Return the new object format
        return {
          found: true,
          name,
          loading: false,
          id: item.id_,
          label: item.label
        };
      });
    }

    setUsers(transformArray(jiraUsers));
  }, [jiraUsers]);

  return (
    <Wrapper>
    <Title>Assign Users</Title>
    <Subtitle>Search for Users or select them from the list.</Subtitle>
    <Input setUsers={ setUsers } users={ users } />
    <Users setUsers={ setUsers } users={ users } />
    </Wrapper>
  );
};

const Input = ({ setUsers, users }) => {
  const [name, setName] = useState('');

  const addJiraUser = async () => {
    if (!name) return;
    const newUser = { name, found: false, loading: true, label: name };

    setUsers(prevUsers => [newUser, ...prevUsers]);

    try {
      const foundUser = await api.settings.addJiraUser({ name });

      setUsers(prevUsers => prevUsers.map(user =>
        user.name === newUser.name
          ? {
              ...user,
              name: foundUser.name ? foundUser.name : user.name,
              label: foundUser.label ? foundUser.label : user.label,
              found: foundUser.found,
              id: foundUser.id_,
              loading: false
            }
          : user
      ));

      setName('');
    } catch (error) {
      setUsers(prevUsers => prevUsers.map(user =>
        user.name === newUser.name
          ? { ...user, found: false, loading: false }
          : user
      ));
    }
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      addJiraUser();
    }
  };

  return (
          <InputWrapper>
            <InputComp
              value={ name }
              placeholder="Jira User Display Name or User email"
              onChange={ handleChange }
              onKeyPress={ handleKeyPress }
            />
            <Plus onClick={ addJiraUser } />
          </InputWrapper>
  );
};

export const Users = ({ users, setUsers }) => {
  const onRemove = async (id, loading) => {
    if (loading) return;
    setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
    await api.settings.deleteJiraUser({ id_: id });
  };

  const getIcon = (loading, found) => {
    if (loading) {
      return <Loading />;
    } else if (found) {
      return <Trash />;
    } else {
      return <Error />;
    }
  };

  return (
    <DataContainer>
      {users.map((user, i) => (
        <DataItem
          key={ user.id || user.name }
          loading={ user.loading }
          found={ user.found }
        >
                    {user.label}
                     <IconWrapper onClick={ () => onRemove(user.id, user.loading) }>
        {getIcon(user.loading, user.found)}
                     </IconWrapper>
        </DataItem>
      ))}
    </DataContainer>);
};

export default JiraUserAssign;
