/* eslint-disable react/no-children-prop */
import React from 'react';
import { Subtitle } from 'Screens/Settings/common-styled';
import { useIntl } from 'react-intl';
import Checkbox from 'Common/Components/Checkbox/styled';
import { selectIsEnabled } from 'store/Settings/saml/selectors';
import { setSamlField } from 'store/Settings/saml/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TitleLabel, TitleWrapper, BetaLabel } from './styled';
import { selectEnabled } from 'store/Settings/ldap/selectors';

const SamlTitle = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const ldapEnabled = useSelector(selectEnabled);
  const samlEnabled = useSelector(selectIsEnabled);
  const setEnabled = (e) => dispatch(setSamlField('enabled', e.target.checked));
  const title = intl.formatMessage({ id: 'preferences.saml.title' });
  const subtitle = intl.formatMessage({ id: 'preferences.saml.subtitle' });
  const beta = intl.formatMessage({ id: 'feature.beta' });

  return (
    <>
      <TitleWrapper>
        <TitleLabel children={ title } />
        <BetaLabel children={ beta } />
        <Checkbox disabled={ ldapEnabled } title={ ldapEnabled ? 'To enable SAML please disable LDAP first.' : '' } onChange={ setEnabled } checked={ samlEnabled } />
      </TitleWrapper>
      <Subtitle children={ subtitle } />
    </>
  );
};

export default SamlTitle;
