import api from 'services/api';
import * as types from './types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import {
  selectBulkUpdateCustomAttribute, selectContextMenuXPos, selectContextMenuYPos, selectLastSelected,
  selectModalBulkUpdateField, selectModalBulkUpdateValue, selectSelectAll,
  selectShowDetail, selectVulnDetail, selectVulnDetailId, selectVulnWorkspace,
  selectVulns, selectVulnsCount, selectVulnsSelected
} from './selectors';
import { selectAdvancedExpandedQueryParam, selectAdvancedFilterQueryParam, selectExpandedQueryParam, selectGroupByField, selectIsGrouping, selectQueryParam, selectRowsPerPage } from 'store/Filters/selectors';
import { clearGroupBy, expandGroupBy, setFilter, setFilterError, setGroupBy, setOrderBy, setPageNumber } from 'store/Filters/actions';
import { MODAL_MANAGE_BULK_UPDATE_CONFIRMATION, MODAL_MANAGE_BULK_UPDATE } from 'store/modals/modals';
import { getImpactData } from 'store/Manage/actions';
import { closeModal, openModal } from 'store/modals/actions';
import { redirect } from 'store/Router/actions';
import { contextMenufilteringFunctions } from 'store/Manage/filterActions';
import { selectCsrfToken } from 'store/Sesion/selectors';
import { MANAGE_CREATE_UPDATE_START } from 'Screens/ManageEditCreate/actions/Actions';
import { getVulnsCountInAllWorkspaces } from 'Screens/Contextualization/Faraday/actions/Actions';
import { MANAGE_CREATE_UPDATE_FAIL, RESET_STATE_MANAGE_CREATE_UPDATE } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import { getGeneralHostsTags, getGeneralServicesTags, getGeneralTags } from 'Screens/Contextualization/Tags/actions/Actions';
import { MANAGE_SET_TEMP_CVSS } from 'store/Manage/types';

export const resetState = () => (dispatch) => dispatch({ type: types.GENERAL_MANAGE_RESET_STATE });

export const somethingWentWrong = (errorMessage) => async (dispatch) => dispatch({ type: types.GENERAL_MANAGE_FAIL, errorMessage: errorMessage || 'There was an error, please try again.' });

export const clearError = () => async (dispatch) => dispatch({ type: types.GENERAL_MANAGE_CLEAR_ERROR });

export const setVulnsFilterError = () => async (dispatch) => dispatch({ type: types.GENERAL_MANAGE_SET_VULNS_FILTER_ERROR });

export const getVulnsGeneral = () => async (dispatch, getState) => {
  dispatch({ type: types.GENERAL_MANAGE_GET_REQUEST });
  let advancedFilterQueryParam = [];

  try {
    advancedFilterQueryParam = selectAdvancedFilterQueryParam(getState(), 'vulnsGeneral');
  } catch (e) {
    dispatch(setFilterError('vulns', 'Syntax error. Please try again. For further help check our documentation'));
  }

  const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
  const standardQueryParam = selectQueryParam('vulnsGeneral', getState());
  const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

  const groupBy = selectIsGrouping('vulnsGeneral', getState());

  try {
    const response = await api.vulnsGeneral.fetchVulns(queryParam);
    if (!response.status || response.status !== 'canceled') {
      const vulns = get(response, 'vulnerabilities', []);
      const result = vulns.map((vuln) => vuln.value);
      const vulnsCountArray = vulns.map((vuln) => vuln.value.count);
      const vulnsCountGroupBy = vulnsCountArray.reduce((prev, cur) => prev + cur, 0);
      return dispatch({
        type: types.GENERAL_MANAGE_GET_SUCCESS,
        data: result,
        count: groupBy ? vulnsCountGroupBy : response.count
      });
    }
  } catch (error) {
    const errorMessage = get(error, 'message', 'Failed to obtain vulnerabilities.');
    if (errorMessage.includes('filter')) {
      dispatch(setFilterError('vulnsGeneral', 'Invalid filters. Please try again. For further help check our documentation'));
      return dispatch(setVulnsFilterError());
    }
    return dispatch(somethingWentWrong(errorMessage));
  }
};

const addDeleteController = (vulns, vulnList) => {
  const allVulnsAreSelected = vulns.every((testVuln) => vulnList.some((vuln) => vuln._id === testVuln._id));

  if (allVulnsAreSelected) return [types.GENERAL_MANAGE_UNSELECTED, vulns];
  return [types.GENERAL_MANAGE_SELECTED, vulns];
};

const selectCalculator = (e, vuln, vulnList, areVulnsSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = vulnList.findIndex((el) => el._id === vuln._id);
  const vulnsSelected = selectVulnsSelected(getState());

  dispatch({ type: types.GENERAL_MANAGE_NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areVulnsSelected) {
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const vulns = vulnList.slice(start, end);
    const [type, payload] = addDeleteController(vulns, vulnsSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([vuln], vulnsSelected);
  return dispatch({ type, payload });
};

export const selectVulnRow = (e, vuln) => (dispatch, getState) => {
  const allVulns = selectVulns(getState());
  const isGroupingBy = selectIsGrouping('vulnsGeneral', getState());
  const vulnsSelected = selectVulnsSelected(getState());

  if (isGroupingBy) {
    const group = allVulns.find((g) => g.groupData && g.groupData.find((el) => el._id === vuln._id));
    if (!group) return;
    const vulnGroup = group.groupData;
    const areVulnsSelected = vulnsSelected.length > 0 && vulnGroup.some((el) => el.id === vuln.id);
    dispatch(selectCalculator(e, vuln, vulnGroup, areVulnsSelected, vulnsSelected));
  } else {
    dispatch(selectCalculator(e, vuln, allVulns, vulnsSelected.length > 0));
  }
};

export const unSelectAllVulns = () => (dispatch) => dispatch({ type: types.GENERAL_MANAGE_UNSELECT_ALL });

export const selectAllVulns = () => (dispatch, getState) => {
  const vulnsList = selectVulns(getState());
  const vulnsSelected = selectVulnsSelected(getState());
  const vulnCount = selectVulnsCount(getState());
  const pageSize = selectRowsPerPage('vulnsGeneral', getState());

  if (vulnsSelected.length === vulnCount || vulnsSelected.length >= pageSize) return dispatch(unSelectAllVulns());

  return dispatch({ type: types.GENERAL_MANAGE_SELECT_ALL, vulnsList });
};

export const selectTotalVulns = () => (dispatch) => {
  return dispatch({ type: types.GENERAL_MANAGE_SELECT_TOTAL_VULNS });
};

export const unselectTotalVulns = () => (dispatch) => {
  return dispatch({ type: types.GENERAL_MANAGE_UNSELECT_TOTAL_VULNS });
};

export const setShowContextMenu = (show, XPos, YPos) => (dispatch, getState) => {
  const state = getState();
  const currentXPos = selectContextMenuXPos(state);
  const currentYPos = selectContextMenuYPos(state);
  const newXPos = XPos ? (XPos + 1) : currentXPos;
  const newYPos = YPos ? (YPos + 1) : currentYPos;

  dispatch({
    type: types.GENERAL_MANAGE_SHOW_CONTEXT_MENU, show, contextMenuXPos: newXPos, contextMenuYPos: newYPos
  });
};

export const setContextMenuFilter = (filterKey, selectedVuln) => (dispatch) => {
  const newFilter = contextMenufilteringFunctions[filterKey](selectedVuln);
  if (newFilter) dispatch(setFilter('vulnsGeneral', newFilter));
  dispatch(getVulnsGeneral());
};

export const onGetVulnsFail = (errorMessage) => (dispatch) => dispatch({ type: types.GENERAL_MANAGE_SEARCH_VULNS_FAIL, data: errorMessage });

const getExpandedGroupByData = (index) => async (dispatch, getState) => {
  dispatch({ type: types.GENERAL_MANAGE_GET_GROUPED_DATA_START });
  const state = getState();

  let advancedFilterQueryParam = [];
  try {
    advancedFilterQueryParam = selectAdvancedExpandedQueryParam(state, 'vulnsGeneral');
  } catch (e) {
    dispatch(setFilterError('vulns', 'Syntax error. Please try again. For further help check our documentation'));
  }
  const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
  const standardQueryParam = selectExpandedQueryParam('vulnsGeneral', state);
  const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

  try {
    const response = await api.vulnsGeneral.fetchVulns(queryParam);

    if (!response.status || response.status !== 'canceled') {
      const result = response.vulnerabilities.map((vuln) => vuln.value);

      return dispatch({
        type: types.GENERAL_MANAGE_GET_GROUPED_DATA_SUCCESS,
        data: result,
        index
      });
    }
  } catch (e) {
    const errorMessage = get(e, 'message', 'Failed to obtain vulnerabilities.');
    if (errorMessage.includes('filter')) {
      dispatch(setFilterError('vulns', 'Invalid filters. Please try again. For further help check our documentation'));
    }
    return dispatch(somethingWentWrong(errorMessage));
  }
};

export const setGroupByVulnsGeneral = (group_by) => async (dispatch) => {
  const field = get(group_by, '[0].field', '');
  await dispatch(setGroupBy('vulnsGeneral', group_by));
  if (field) await dispatch(setOrderBy('vulnsGeneral', [{ id: field, desc: field === 'severity' }]));
  dispatch(getVulnsGeneral());
};

export const clearGroupByVulnsGeneral = () => async (dispatch) => await dispatch(clearGroupBy('vulnsGeneral'));

export const expandGroupByVulns = (index, data) => async (dispatch) => {
  await dispatch(expandGroupBy('vulnsGeneral', data));
  dispatch(getExpandedGroupByData(index));
};

export const setOrderByVulnsGeneral = (sorting) => (dispatch, getState) => {
  const state = getState();
  const isGrouping = selectIsGrouping('vulnsGeneral', state);

  dispatch(setOrderBy('vulnsGeneral', sorting));
  if (!isGrouping) dispatch(getVulnsGeneral());
};

export const setBulkUpdateField = (field) => (dispatch) => {
  return dispatch({ type: types.GENERAL_MANAGE_SET_BULK_UPDATE_FIELD, field });
};

export const setBulkUpdateValue = (value) => (dispatch) => {
  return dispatch({ type: types.GENERAL_MANAGE_SET_BULK_UPDATE_VALUE, value });
};

export const addBulkUpdateValue = (value) => (dispatch) => {
  return dispatch({ type: types.GENERAL_MANAGE_ADD_BULK_UPDATE_VALUE, value });
};

export const removeBulkUpdateValue = (value) => (dispatch) => {
  return dispatch({ type: types.GENERAL_MANAGE_REMOVE_BULK_UPDATE_VALUE, value });
};

export const refreshVulnsList = (vulnsList, vulnsSelected, vulnDetail) => (dispatch, getState) => {
  dispatch({
    type: types.GENERAL_MANAGE_REFRESH_VULNS, vulnsList, vulnsSelected, vulnDetail, vulnsCount: selectVulnsCount(getState())
  });
};

export const setCustomAttributeField = (customAttribute) => (dispatch) => {
  dispatch({ type: types.GENERAL_MANAGE_SET_BULK_CUSTOM_ATTRIBUTE, customAttribute });
};

export const bulkUpdateVulns = () => async (dispatch, getState) => {
  const state = getState();
  const field = selectModalBulkUpdateField(state);
  dispatch({ type: types.GENERAL_MANAGE_CONFIRMATION_CHANGE_START });

  try {
    const vulnsList = selectVulns(state);
    const vulnsSelected = selectVulnsSelected(state);
    const vulnDetail = selectVulnDetail(state);
    const value = selectModalBulkUpdateValue(state);
    const selectAll = selectSelectAll(state);
    const vulnIDs = vulnsSelected.map((v) => v._id);
    const customAttribute = selectBulkUpdateCustomAttribute(state);
    const customAttributeType = get(customAttribute, 'field_type', '');
    const customAttributeFieldName = get(customAttribute, 'field_name', '');
    const customAttributeValue = ((customAttributeType === 'choice') && (value === '(Empty)')) ? '' : value;

    const advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'vulnsGeneral');
    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam('vulnsGeneral', state);
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    let data = {};

    if (field === 'references') data = { refs: value.map((v) => ({ name: v, type: 'other' })) };
    else if (field === 'policy violations') data = { policyviolations: value };
    else if (field === 'description') data = { description: value, desc: value };
    else if (field === 'impact') data = getImpactData(value, vulnsSelected);
    else if (field === 'custom_fields' && (vulnsSelected?.length > 1)) data = { custom_fields: { [customAttributeFieldName]: customAttributeValue } };
    else if (field === 'custom_fields' && (vulnsSelected?.length === 1)) data = { custom_fields: { ...vulnsSelected[0].custom_fields, [customAttributeFieldName]: customAttributeValue } };
    else data = { [field]: value };

    const isDetailVulnSelected = vulnsSelected.some((selectedVuln) => !isEmpty(vulnDetail) && selectedVuln._id === vulnDetail._id);

    const updatedVulnDetail = isDetailVulnSelected ? { ...vulnDetail, ...data } : { ...vulnDetail };

    if (selectAll) {
      await api.vulnsGeneral.updateAllVulns({ filters: queryParam.filters }, data);

      const updatedVulnsList = vulnsList.map((vuln) => ({ ...vuln, ...data }));
      const updatedSelectedVulns = vulnsSelected.map((vuln) => ({ ...vuln, ...data }));

      dispatch(refreshVulnsList(updatedVulnsList, updatedSelectedVulns, updatedVulnDetail));
    } else {
      const payload = { ids: vulnIDs, ...data };

      await api.vulnsGeneral.updateVulns(payload);

      const updatedVulnsList = vulnsList.map((vuln) => {
        const isSelected = vulnsSelected.some((selectedVuln) => selectedVuln._id === vuln._id);
        if (isSelected) return { ...vuln, ...data };
        return vuln;
      });

      const updatedSelectedVulns = vulnsSelected.map((vuln) => ({ ...vuln, ...data }));

      dispatch({ type: types.GENERAL_MANAGE_SET_BULK_UPDATE_SUCCESS });
      dispatch(refreshVulnsList(updatedVulnsList, updatedSelectedVulns, updatedVulnDetail));
    }

    dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
    dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    dispatch({ type: types.GENERAL_MANAGE_BULK_UPDATE_FINISHED });
  } catch (e) {
    dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    if (field === 'name') dispatch({ type: types.SET_ERROR_MESSAGE, errorMessage: e.message });
    else {
      dispatch({ type: types.GENERAL_MANAGE_FAIL, errorMessage: e.message || 'An error occured while updating vulns' });
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
    }
  }
};

export function showBulkUpdateModal (bulkUpdateField, bulkUpdateValue) {
  return (dispatch) => {
    dispatch(setBulkUpdateField(bulkUpdateField));
    dispatch(setBulkUpdateValue(bulkUpdateValue));
    dispatch(openModal(MODAL_MANAGE_BULK_UPDATE));
  };
}

function vulnPreviewEditSuccedCallback (vulnBefore, vulnAfter) {
  return (dispatch, getState) => {
    const groupBy = selectGroupByField('vulnsGeneral', getState());
    const vulnsList = selectVulns(getState());

    if (groupBy) {
      // se actualiza la tabla agrupada
      const indexGroupDataAfter = vulnsList.findIndex((x) => x[groupBy] === vulnAfter[groupBy]);
      const groupDataAfter = vulnsList[indexGroupDataAfter].groupData || [];
      const indexVulnAfter = groupDataAfter.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
      if (indexVulnAfter < 0) { // no lo encontro, lo borro
        const indexGroupDataBefore = vulnsList.findIndex((x) => x[groupBy] === vulnBefore[groupBy]);
        const groupDataBefore = vulnsList[indexGroupDataBefore].groupData || {};
        const indexVulnBefore = groupDataBefore.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
        // lo quito del grupo.
        vulnsList[indexGroupDataBefore].groupData.splice(indexVulnBefore, 1);
        // eslint-disable-next-line no-negated-condition
        if (!vulnsList[indexGroupDataAfter].groupData) vulnsList[indexGroupDataAfter].count += 1;
        else vulnsList[indexGroupDataAfter].groupData.push(vulnAfter);
      } else {
        vulnsList[indexGroupDataAfter].groupData[indexVulnAfter] = vulnAfter;
      }
    } else {
      // se actualiza la tabla
      const index = vulnsList.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
      vulnsList[index] = vulnAfter;
    }

    dispatch({ type: types.GENERAL_MANAGE_UPDATE_PREVIEW_SUCCESS, vuln: vulnAfter, vulnsList });
  };
}

function vulnPreviewEditErrorCallback (error) {
  return (dispatch) => {
    dispatch({ type: types.GENERAL_MANAGE_UPDATE_PREVIEW_FAILURE, error: error.message || 'An error has occurred' });
  };
}

export const updateVulnGeneral = (vulnBefore, field, value) => async (dispatch) => {
  const workspace = get(vulnBefore, 'workspace_name', '');
  let vulnAfter = { ...vulnBefore };
  set(vulnAfter, field, value);

  try {
    let newRefs = [];
    if (field === 'refs') {
      newRefs = value.map((ref) => ({ name: ref, type: 'other' }));
      vulnAfter = await api.manage.updateVuln(workspace, { _id: vulnBefore._id, refs: newRefs });
    } else {
      vulnAfter = await api.manage.updateVuln(workspace, { _id: vulnBefore._id, [field]: value });
    }

    dispatch(vulnPreviewEditSuccedCallback(vulnBefore, vulnAfter));
  } catch (error) {
    dispatch(vulnPreviewEditErrorCallback(error));
  }
};

export const redirectToVulnerabilities = () => (dispatch) => {
  return dispatch(redirect('/vulnerabilities'));
};

export const redirectToVulnDetailGeneral = (id) => (dispatch) => {
  return dispatch(redirect(`/vulnerabilities/${id}`));
};

export const setVulnerabilityDetailTab = (vulnDetailSelectedTab) => (dispatch) => {
  return dispatch({ type: types.GENERAL_MANAGE_SET_VULN_DETAIL_TAB, vulnDetailSelectedTab });
};

export const showVulnDetailGeneral = (id, vulnDetailSelectedTab) => (dispatch) => {
  if (id > 0) {
    dispatch(redirect(`/vulnerabilities/${id}`));
    dispatch(setVulnerabilityDetailTab(vulnDetailSelectedTab));
  }
};

export const loadVulnDetail = (id) => async (dispatch) => {
  if (id > 0) {
    try {
      const vuln = await api.vulnsGeneral.fetchVulnById(id);
      dispatch({ type: types.GENERAL_MANAGE_SHOW_VULN_DETAIL, vuln });
    } catch (e) {
      dispatch(redirectToVulnerabilities());
    }
  }
};

export const hideVulnDetail = () => (dispatch, getState) => {
  const showVulnDetail = selectShowDetail(getState());
  if (showVulnDetail) dispatch({ type: types.GENERAL_MANAGE_HIDE_VULN_DETAIL });
};

export const setPage = (pageNumber) => {
  return (dispatch) => {
    dispatch(setPageNumber('vulnsGeneral', pageNumber));
    dispatch(getVulnsGeneral());
  };
};

export function showVulnModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.GENERAL_MANAGE_SHOW_MODAL_DELETE_CONFIRMATION });
  };
}

// Summary: Hide confirmation modal when user delete vuln/s
export function hideVulnModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.GENERAL_MANAGE_HIDE_MODAL_DELETE_CONFIRMATION });
  };
}

export const deleteSelectedVulns = () => async (dispatch, getState) => {
  const vulnsSelected = selectVulnsSelected(getState());
  const vulnIDs = vulnsSelected.map((vuln) => vuln._id);
  const groupByField = selectGroupByField('vulnsGeneral', getState());
  const vulnsList = selectVulns(getState());
  const selectAll = selectSelectAll(getState());

  const advancedFilterQueryParam = selectAdvancedFilterQueryParam(getState(), 'vulnsGeneral');
  const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
  const standardQueryParam = selectQueryParam('vulnsGeneral', getState());
  const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

  dispatch({ type: types.GENERAL_MANAGE_HIDE_MODAL_DELETE_CONFIRMATION_SUCCEED });
  dispatch({ type: types.GENERAL_MANAGE_DELETE });

  try {
    if (selectAll) {
      await api.vulnsGeneral.deleteAllVulns({ filters: queryParam.filters });
    } else {
      await api.vulnsGeneral.deleteVulns(vulnIDs);
    }
    if (groupByField) {
      const newVulnsList = vulnsList.map((group) => {
        const groupedData = get(group, 'groupData', null);
        if (groupedData) {
          const newGroupedData = groupedData.filter((vuln) => !vulnIDs.includes(vuln._id));
          return {
            ...group,
            count: newGroupedData.length,
            groupData: newGroupedData
          };
        }
        return group;
      });
      dispatch({ type: types.GENERAL_MANAGE_UPDATE_VULNS_LIST, vulnsList: newVulnsList });
    } else dispatch(getVulnsGeneral());
  } catch (e) {
    dispatch({ type: types.GENERAL_MANAGE_FAIL, errorMessage: e.message || 'An error occured while deleting vulns' });
  }
};

export const showVulnsGeneralRightFilters = (visible) => (dispatch) => dispatch({ type: types.GENERAL_MANAGE_SHOW_FILTERS, visible });

export function addEvidences (evidences, getEvidences) {
  return async (dispatch, getState) => {
    const id = selectVulnDetailId(getState());
    const csrfToken = selectCsrfToken(getState());
    const workspace = selectVulnWorkspace(getState());
    const promises = evidences.map((evidence) => {
      const newEvidenceName = evidence.name.replace(/\s+/g, '');
      const renameEvidence = new File([evidence], newEvidenceName);
      const fd = new FormData();
      fd.append('csrf_token', csrfToken);
      fd.append('file', renameEvidence);
      return api.attachments.saveAttachments(workspace, id, fd);
    });

    try {
      await Promise.all(promises);
    } catch (e) {
      const doc = e.message ? new DOMParser().parseFromString(e.message, 'text/html') : null;
      const message = e.message ? doc.documentElement.textContent : 'An error has occurred';
      dispatch({ type: types.ADD_EVIDENCE_FAILURE, error: message });
    }
    getEvidences();
  };
}

export const getVulnRefs = (id) => async (dispatch) => {
  try {
    const response = await api.vulnsGeneral.fetchVulnById(id);
    const refs = get(response, 'refs', []);
    dispatch(showBulkUpdateModal('references', refs.map((r) => r.name)));
  } catch (e) {
    dispatch({ type: types.GENERAL_MANAGE_FAIL, errorMessage: e.message || 'An error has occurred' });
  }
};

export function createVulnOutsideWs () {
  return async (dispatch, getState) => {
    dispatch({ type: MANAGE_CREATE_UPDATE_START });

    const state = getState().manageEditCreate;
    const newRefs = state.references.map((ref) => ({ name: ref, type: 'other' }));
    const isWebVuln = state.isWebVuln;
    const servicesTargets = state.targets.filter((target) => target.value.type === 'Service');
    const servicesParents = servicesTargets.map((service) => service.value.parent);
    const allTargets = state.targets;
    const hostsTargets = allTargets.filter((target) => target.value.type === 'Host');
    const removeServiceParent = hostsTargets.filter((target) => !servicesParents.includes(target.value.id));
    const filteredTargets = servicesTargets.length > 0 ? servicesTargets.concat(removeServiceParent) : allTargets;
    const vulnsTargets = isWebVuln ? servicesTargets : filteredTargets;

    try {
      const promises = vulnsTargets.map((target) => {
        const vuln = {
          _id: state.id,
          confirmed: state.confirmed,
          custom_fields: state.customFields,
          data: state.data,
          desc: state.description,
          description: state.description,
          easeofresolution: state.easeOfResolution,
          external_id: state.externalId,
          impact: {
            accountability: get(state, 'accountability', false),
            availability: get(state, 'availability', false),
            confidentiality: get(state, 'confidentiality', false),
            integrity: get(state, 'integrity', false)
          },
          method: state.method,
          name: state.name,
          pname: state.paramName,
          params: state.params,
          parent: target.value.id,
          parent_type: target.value.type,
          path: state.path,
          policyviolations: state.policies,
          query: state.query,
          refs: newRefs,
          request: state.request,
          resolution: state.resolution,
          response: state.response,
          severity: state.severity,
          status_code: state.status_code ? state.status_code : 0,
          type: state.isWebVuln ? 'VulnerabilityWeb' : 'Vulnerability',
          website: state.website,
          _attachments: state._attachments, // eslint-disable-line no-underscore-dangle
          cve: state.CVE
        };
        return api.manage.createVuln(target.value.workspace_name, vuln);
      });
      const res = await Promise.all(promises);

      dispatch(getVulnsCountInAllWorkspaces());

      dispatch({ type: types.GENERAL_MANAGE_CREATE_UPDATE_SUCCESS, vulns: res });

      dispatch({ type: RESET_STATE_MANAGE_CREATE_UPDATE });
    } catch (error) {
      return dispatch({ type: MANAGE_CREATE_UPDATE_FAIL, errorMessage: error.message });
    }
  };
}

export function setVulnsGeneralTags (tagsToAdd = [], tagsToRemove = []) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const vulnsSelected = selectVulnsSelected(state);
      const vulnsList = selectVulns(state);
      const selectedVulnsIds = vulnsSelected.map((v) => (v._id));
      const selectAll = selectSelectAll(state);

      const advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'vulnsGeneral');
      const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
      const standardQueryParam = selectQueryParam('vulnsGeneral', state);
      const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

      if (selectAll) {
        const data = {
          tags_to_add: tagsToAdd,
          tags_to_remove: tagsToRemove
        };
        await api.vulnsGeneral.setAllVulnsTags({ filters: queryParam.filters }, data);
      } else {
        const data = {
          vulnerability_ids: selectedVulnsIds,
          tags_to_add: tagsToAdd,
          tags_to_remove: tagsToRemove
        };

        await api.vulnsGeneral.setVulnsTags(data);
      }

      const newTags = tagsToAdd.filter((tag) => !tagsToRemove.includes(tag));

      selectedVulnsIds.forEach((id) => {
        const vuln = find(vulnsSelected, { _id: id });
        const newTagsList = newTags.filter((tag) => !vuln.tags.includes(tag));
        vuln.tags = [
          ...vuln.tags.filter((tag) => !tagsToRemove.includes(tag)),
          ...newTagsList
        ];

        const indexVulnsList = findIndex(vulnsList, { _id: vuln._id });
        if (indexVulnsList > -1 && !isEmpty(vulnsList)) vulnsList.splice(indexVulnsList, 1, vuln);

        const indexVulnsSelected = findIndex(vulnsSelected, { _id: id });
        if (indexVulnsList > -1 && !isEmpty(vulnsSelected)) vulnsSelected.splice(indexVulnsSelected, 1, vuln);
      });

      const vulnDetail = selectVulnDetail(state);
      if (!!vulnDetail && selectedVulnsIds.includes(vulnDetail.id)) {
        vulnDetail.tags = [
          ...vulnDetail.tags.filter((tag) => !tagsToRemove.includes(tag)),
          ...newTags
        ];
      }

      dispatch({ type: types.GENERAL_MANAGE_SET_TAGS_SUCCESS });

      if (vulnsSelected.length === 1) {
        dispatch({ type: types.GENERAL_MANAGE_TAGS_UPDATE_DETAIL, vuln: vulnDetail });
      }
      dispatch(getGeneralTags());
      dispatch(getGeneralHostsTags());
      dispatch(getGeneralServicesTags());
    } catch (e) {
      dispatch({ type: types.GENERAL_MANAGE_SET_TAGS_FAIL, error: e.message });
    }
  };
}

export function addTag (tag) {
  return (dispatch) => {
    dispatch(setVulnsGeneralTags([tag], []));
  };
}

export function removeTag (tag) {
  return (dispatch) => {
    dispatch(setVulnsGeneralTags([], [tag]));
  };
}

export function refreshVulnEnrichment (vulnBefore) {
  return async (dispatch, getState) => {
    dispatch({ type: types.CONTEXT_REFRESH_VULN_ENRICHMENT_REQUEST });

    const workspace = get(vulnBefore, 'workspace_name', '');
    const id = selectVulnDetailId(getState());

    try {
      const vulnAfter = await api.manage.refreshEnrichment(workspace, id);
      dispatch(vulnPreviewEditSuccedCallback(vulnBefore, vulnAfter));
      dispatch({ type: types.CONTEXT_REFRESH_VULN_ENRICHMENT_SUCCESS });
    } catch (e) {
      dispatch({
        type: types.CONTEXT_REFRESH_VULN_ENRICHMENT_FAILURE,
        errorMessage: 'Faraday was not able to determine the requested Risk Information. Please try again later.'
      });
    }
  };
}

export function updateCvssGeneral (tempCVSS) {
  return (dispatch, getState) => {
    const currentVuln = selectVulnDetail(getState());
    const currentCVSSValue = currentVuln.cvss3.vector_string;

    const parsedTempCVSS = {
      base_score: Number(tempCVSS.score),
      base_severity: tempCVSS.severity.toLowerCase(),
      vector_string: tempCVSS.vector
    };
    dispatch({ type: MANAGE_SET_TEMP_CVSS, tempCVSS: parsedTempCVSS });

    const sameVector = currentCVSSValue === tempCVSS.vector;
    if ((!parsedTempCVSS.vector_string.includes('_')) && (!sameVector)) {
      dispatch(updateVulnGeneral(currentVuln, 'cvss3', { vector_string: tempCVSS.vector }));
    }
  };
}

export function setSelectedComment (selectedComment) {
  return (dispatch) => {
    dispatch({ type: types.GENERAL_MANAGE_SET_SELECTED_COMMENT, selectedComment });
  };
}

function getCommentsSucceedCallback (data) {
  return (dispatch) => {
    dispatch({
      type: types.GENERAL_MANAGE_GET_COMMENTS_SUCCESS,
      data
    });
  };
}

export function getComments (id) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GENERAL_MANAGE_GET_COMMENTS_REQUEST });
      const data = await api.vulnsGeneral.getComments(id);
      dispatch(getCommentsSucceedCallback(data));
    } catch (e) {
      dispatch({ type: types.GENERAL_MANAGE_GET_COMMENTS_FAIL, data: e.message });
    }
  };
}

function addCommentSucceedCallback (data) {
  return (dispatch) => {
    dispatch({
      type: types.GENERAL_MANAGE_ADD_COMMENT_SUCCESS,
      data
    });
  };
}

export function addComment (ws, data) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GENERAL_MANAGE_ADD_COMMENT_REQUEST });
      const response = await api.manage.newComment(ws, data);
      dispatch(addCommentSucceedCallback(response));
    } catch (e) {
      dispatch({ type: types.GENERAL_MANAGE_ADD_COMMENT_FAIL, data: e.message });
    }
  };
}

function removeCommentSucceedCallback (commentId) {
  return (dispatch) => {
    dispatch({
      type: types.GENERAL_MANAGE_REMOVE_COMMENT_SUCCESS,
      commentId
    });
  };
}

export function removeComment (ws, commentId) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GENERAL_MANAGE_REMOVE_COMMENT_REQUEST });
      await api.manage.removeComment(ws, commentId);
      dispatch(removeCommentSucceedCallback(commentId));
    } catch (e) {
      dispatch({ type: types.GENERAL_MANAGE_REMOVE_COMMENT_FAIL, data: e.message });
    }
  };
}

function updateCommentSucceedCallback (data) {
  return (dispatch) => {
    dispatch({
      type: types.GENERAL_MANAGE_UPDATE_COMMENT_SUCCESS,
      data
    });
  };
}

export function updateComment (ws, data) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GENERAL_MANAGE_UPDATE_COMMENT_REQUEST });
      const response = await api.manage.saveComment(ws, data.id, data);
      dispatch(updateCommentSucceedCallback(response));
    } catch (e) {
      dispatch({ type: types.GENERAL_MANAGE_UPDATE_COMMENT_FAIL, data: e.message });
    }
  };
}

export function bulkAddComment () {
  return (dispatch, getState) => {
    const state = getState();
    const vulnsSelected = selectVulnsSelected(state);
    const text = selectModalBulkUpdateValue(state);

    vulnsSelected.forEach((vuln) => {
      const data = {
        text,
        object_type: 'vulnerability',
        object_id: vuln._id,
        create_date: new Date()
      };
      dispatch(addComment(vuln.workspace_name, data));
    });
  };
}

export const updateCvssV4General = (vector_string) => (dispatch, getState) => {
  const currentVuln = selectVulnDetail(getState());
  dispatch(updateVulnGeneral(currentVuln, 'cvss4', { vector_string }));
};
