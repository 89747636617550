import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useResizeX } from 'Hooks/useResize';
import useWindowDimensions from 'Hooks/useWindowSize';
import Header from './components/Header';
import TechnicalInfo from './components/TechnicalInfo';
import Description from './components/Description';
import Tags from './components/Tags';
import {
  Wrapper, ResizerWrapper, DetailWrapper, Resizer,
  Transparency, Content
} from './styled';
import { selectShowTagsModal } from 'store/Tags/selectors';
import CreateEditTagsModal from '../CreateEditTagsModal';

const ServiceDetail = ({
  data, resetServiceDetail, serviceId, owned, updateService,
  redirectToService, loadServiceDetail, description, entity,
  serviceTags, servicesSelected, addTag, removeTag, setServicesTags,
  allWorkspaces
}) => {
  const [showTransparency, setShowTransparency] = useState(false);
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const resizerRef = useRef(null);
  const { width } = useWindowDimensions();
  const minWidth = width < 3000 ? width * 0.55 : width * 0.35;
  const maxWidth = width < 3000 ? width * 0.75 : width * 0.55;
  const wrapperWidth = useResizeX(wrapperRef, resizerRef, minWidth, minWidth, maxWidth);
  const dispatch = useDispatch();
  const showTagsModal = useSelector((state) => selectShowTagsModal(entity, state));

  const onScroll = () => {
    const scrollTop = get(ref, 'current.scrollTop', 0);
    if (scrollTop === 0) setShowTransparency(false);
    else setShowTransparency(true);
  };

  useEffect(() => () => {
    dispatch(resetServiceDetail());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return data
    ? (
      <>
        { showTagsModal && <CreateEditTagsModal servicesTags={ serviceTags } entity={ entity } servicesSelected={ servicesSelected } addTag={ addTag } removeTag={ removeTag } setServicesTags={ setServicesTags } allWorkspaces={ allWorkspaces } /> }
        <Wrapper ref={ wrapperRef } wrapperWidth={ wrapperWidth }>
          <ResizerWrapper ref={ resizerRef } children={ <Resizer /> } />
          <DetailWrapper>
            <Header data={ data } owned={ owned } serviceId={ serviceId } redirectToService={ redirectToService } updateService={ updateService } />
            { showTransparency && <Transparency /> }
            <Content tabRef={ ref } onScroll={ onScroll } showTransparency={ showTransparency }>
              <TechnicalInfo serviceId={ serviceId } selectedService={ data } updateService={ updateService } loadServiceDetail={ loadServiceDetail } />
              <Description serviceId={ serviceId } description={ description } updateService={ updateService } />
              <Tags serviceTags={ serviceTags } entity={ entity } />
            </Content>
          </DetailWrapper>
        </Wrapper>
      </>
      )
    : null;
};

export default ServiceDetail;
