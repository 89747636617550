/* eslint-disable react/no-children-prop */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Wrapper, TagItem } from './styled';

const Tags = ({ tags }) => {
  if (isEmpty(tags)) return null;
  return (
  <Wrapper>
    {
      tags.map((tag, i) => (
        <TagItem key={ `${tag.name}_${i}` }>
          { tag.name }
        </TagItem>
      ))
    }
  </Wrapper>
  );
};

export default Tags;
