import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_TAGS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useIntl } from 'react-intl';
import { Wrapper, Button } from './styled';
import { setCreationTagModal } from 'Screens/Contextualization/Tags/actions/Actions';
import { selectIsReadOnly } from 'store/Faraday/selectors';

const AddTagButton = ({ entity }) => {
  const intl = useIntl();
  const editLabel = intl.formatMessage({ id: 'manage.detail.tab.general.tags.edit' });
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_TAGS, PERMISSION_TYPES.CREATE));
  const readOnly = useSelector(selectIsReadOnly);
  const canUpdate = allowedByRole && !readOnly;
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Button onClick={ () => dispatch(setCreationTagModal(true, entity)) } disabled={ !canUpdate }>{ editLabel }</Button>
    </Wrapper>
  );
};

export default AddTagButton;
