/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  getLdap, setLdapField, saveLdap, resetLdap
} from 'store/Settings/ldap/actions';
import { getSaml, resetSaml } from 'store/Settings/saml/actions';
import {
  selectEnabled, selectServer, selectPort, selectDomain, selectDomainDn, selectBindFormat,
  selectBindDn, selectUserAttribute, selectUseLocalRoles, selectDefaultLocalRole, selectAdminGroup, selectPentesterGroup,
  selectClientGroup, selectAssetOwnerGroup, selectDisconnectTimeout, selectUserClass,
  selectUserGroup
} from 'store/Settings/ldap/selectors';
import { Title } from 'Screens/Settings/common-styled';
import Form from './components/Form';
import {
  Wrapper, Subtitle, SubsectionTitle, Row, Toggle
} from './styled';
import Header from '../Header';
import { selectIsEnabled } from 'store/Settings/saml/selectors';

const Ldap = ({ onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const getIntlText = (id) => intl.formatMessage({ id: `preferences.ldap.${id}` });
  const title = getIntlText('title');
  const subtitle = getIntlText('subtitle');
  const activate = getIntlText('activate');
  const ldapEnabled = useSelector(selectEnabled);
  const samlEnabled = useSelector(selectIsEnabled);
  const server = useSelector(selectServer);
  const port = useSelector(selectPort);
  const domain = useSelector(selectDomain);
  const domainDn = useSelector(selectDomainDn);
  const bindFormat = useSelector(selectBindFormat);
  const bindDn = useSelector(selectBindDn);
  const userAttribute = useSelector(selectUserAttribute);
  const useLocalRoles = useSelector(selectUseLocalRoles);
  const defaultLocalRole = useSelector(selectDefaultLocalRole);
  const adminGroup = useSelector(selectAdminGroup);
  const pentesterGroup = useSelector(selectPentesterGroup);
  const clientGroup = useSelector(selectClientGroup);
  const assetOwnerGroup = useSelector(selectAssetOwnerGroup);
  const connectionTimeout = useSelector(selectDisconnectTimeout);
  const userClass = useSelector(selectUserClass);
  const userGroup = useSelector(selectUserGroup);

  useEffect(() => {
    dispatch(getLdap());
    dispatch(getSaml());
  }, [dispatch]);

  useEffect(() => () => {
    dispatch(resetLdap());
    dispatch(resetSaml());
  }, [dispatch]);

  const onStatusChange = (e) => dispatch(setLdapField('enabled', e.target.checked));

  const close = () => onClose();

  const bindFormatFields = bindFormat === 'DN' ? (isEmpty(bindDn) || isEmpty(userAttribute)) : null;
  const roles = useLocalRoles
    ? isEmpty(defaultLocalRole)
    : (isEmpty(adminGroup) || isEmpty(pentesterGroup) || isEmpty(clientGroup) || isEmpty(assetOwnerGroup));

  const allowSave = () => !isEmpty(server) &&
  !(port === '') &&
  !isEmpty(domain) &&
  !isEmpty(domainDn) &&
  !isEmpty(bindFormat) &&
  !bindFormatFields &&
  !roles &&
  !(connectionTimeout === '') &&
  !isEmpty(userClass) &&
  !isEmpty(userGroup);

  const handleSave = () => {
    dispatch(saveLdap(onClose));
  };

  return (
    <Wrapper>
      <Header onClose={ close } disabled={ !allowSave() } onAccept={ handleSave } />
      <Title children={ title } />
      <Subtitle children={ subtitle } />
      <Row>
        <SubsectionTitle children={ activate } />
        <Toggle disabled={ samlEnabled } title={ samlEnabled ? 'To enable LDAP please disable SAML first.' : '' } onChange={ onStatusChange } checked={ ldapEnabled } />
      </Row>
      <Form />
    </Wrapper>
  );
};

Ldap.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default Ldap;
