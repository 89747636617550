export const SCHEDULE_TABLE_SELECT_ROW = 'SCHEDULE_TABLE_SELECT_ROW';
export const SCHEDULE_TABLE_GET_DATA = 'SCHEDULE_TABLE_GET_DATA';
export const SCHEDULE_FETCHING = 'SCHEDULE_FETCHING';
export const SCHEDULE_FAIL = 'SCHEDULE_FAIL';
export const SCHEDULE_TABLE_PAGING_NEXT = 'SCHEDULE_TABLE_PAGING_NEXT';
export const SCHEDULE_TABLE_PAGING_PREVIOUS = 'SCHEDULE_TABLE_PAGING_PREVIOUS';
export const SCHEDULE_TABLE_SORT_DATA = 'SCHEDULE_TABLE_SORT_DATA';
export const SCHEDULE_TABLE_RESET = 'SCHEDULE_TABLE_RESET';
export const GET_TOTAL_SCHEDULES = 'GET_TOTAL_SCHEDULES';
export const RUN_SCHEDULE = 'RUN_SCHEDULE';
export const SCHEDULE_SET_TAGS_ENTITY = 'SCHEDULE_SET_TAGS_ENTITY';
export const SCHEDULE_RESET_TAGS_FIELDS = 'SCHEDULE_RESET_TAGS_FIELDS';
