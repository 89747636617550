import styled from 'styled-components';

export const Wrapper = styled.div`
   
`;
Wrapper.displayName = 'Wrapper';

export const MentionItem = styled.div`
     padding: 2px 8px;
     cursor:pointer;  
`;
MentionItem.displayName = 'MentionItem';

export const MentionName = styled.div`
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.52;
    letter-spacing: normal;
    color: #1c2243
`;
MentionName.displayName = 'MentionName';
