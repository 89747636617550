/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import createMentionPlugin from 'draft-js-mention-plugin';
import 'draft-js-mention-plugin/lib/plugin.css';
import { selectUserMentions } from 'store/Manage/selectors';
import { useSelector } from 'react-redux';
import MentionPlugin from './components/MentionPlugin';
import CommentEditor from './components/CommentEditor';
import { MentionItem, Wrapper } from './styled';

const CustomMentionEditor = ({
  disabled, entity, entityId, placeholder, addComment, editComment, commentToEdit, setCommentToEdit,
  isBulk, handleClose
}) => {
  const userMentions = useSelector(selectUserMentions);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentions: userMentions,
      mentionComponent: (mentionProps) => <MentionItem children={ mentionProps.children } />,
      mentionPrefix: '@',
      supportWhitespace: false
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { MentionSuggestions, plugins };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <CommentEditor
        disabled={ disabled }
        placeholder={ placeholder }
        addComment={ addComment }
        editComment={ editComment }
        commentToEdit={ commentToEdit }
        setCommentToEdit={ setCommentToEdit }
        plugins={ plugins }
        entity={ entity }
        entityId={ entityId }
        isBulk={ isBulk }
        handleClose={ handleClose }
      />
      <MentionPlugin Component={ MentionSuggestions } />
    </Wrapper>
  );
};

CustomMentionEditor.propTypes = {
  disabled: PropTypes.bool,
  entity: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  commentToEdit: PropTypes.object,
  setCommentToEdit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  addComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired
};

CustomMentionEditor.defaultProps = {
  commentToEdit: null,
  placeholder: '',
  disabled: false,
  entityId: null
};

export default CustomMentionEditor;
