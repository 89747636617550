import styled from 'styled-components';
import { ReactComponent as SlackSVG } from 'Images/Slack-RGB.svg';
import Checkbox from 'Common/Components/Checkbox/styled';

export const Wrapper = styled.div`
  font-size: 21.5px;
`;
Wrapper.displayName = 'Wrapper';

export const FlexWrapper = styled.div`
  display: flex;
`;

export const Slack = styled(SlackSVG)`
height: 34px;
//margin-top: -10px;
margin-right: 5px;
`;

export const OnOff = styled(Checkbox)`
  margin-bottom: 22px;
`;

export const ConfigColumn = styled.div`
  margin-top: 30px;
`;
