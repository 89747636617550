import get from 'lodash/get';
import { CURRENT_API_VERSION } from 'services/api/connector';
import * as p from './permissions';

const defaultPermission = {
  allowed_by_feature_flag: false,
  allowed_by_role: false
};

const getFeaturePermission = (pathCreate, pathUpdateDelete, permissions) => {
  const permission = get(
    permissions,
    `/${CURRENT_API_VERSION}/${pathCreate}`,
    {}
  ); // GET, POST
  const permissionUpdateDelete = get(
    permissions,
    `/${CURRENT_API_VERSION}/${pathUpdateDelete}`,
    {}
  ); // PUT, DELETE, PATCH
  return {
    read: get(permission, 'GET', defaultPermission),
    create: get(permission, 'POST', defaultPermission),
    update: get(permissionUpdateDelete, 'PUT', defaultPermission),
    delete: get(permissionUpdateDelete, 'DELETE', defaultPermission),
    patch: get(permissionUpdateDelete, 'PATCH', defaultPermission)
  };
};

const getPermissionDuplicates = (permissions) => {
  const permissionGet = get(
    permissions,
    `/${CURRENT_API_VERSION}/ws/<workspace_name>/vulns/<int:vuln_id>/duplicates`,
    {}
  ); // GET
  const permissionAssociate = get(
    permissions,
    `/${CURRENT_API_VERSION}/ws/<workspace_name>/vulns/duplicates/associate`,
    {}
  ); // POST
  const permissionDisassociate = get(
    permissions,
    `/${CURRENT_API_VERSION}/ws/<workspace_name>/vulns/duplicates/disassociate`,
    {}
  ); // DELETE

  return {
    read: get(permissionGet, 'GET', defaultPermission),
    create: get(permissionAssociate, 'POST', defaultPermission),
    update: false,
    delete: get(permissionDisassociate, 'DELETE', defaultPermission),
    patch: false
  };
};

const getSettingsPermission = (path, permissions) => {
  const permission = get(permissions, `/${CURRENT_API_VERSION}/${path}`, {}); // GET, POST, PUT, DELETE, PATCH

  return {
    read: get(permission, 'GET', defaultPermission),
    create: get(permission, 'POST', defaultPermission),
    update: get(permission, 'PUT', defaultPermission),
    delete: get(permission, 'DELETE', defaultPermission),
    patch: get(permission, 'PATCH', defaultPermission)
  };
};

const getPermissionTags = (pathCreate, pathUpdateDelete, permissions) => {
  const permission = get(
    permissions,
    `/${CURRENT_API_VERSION}/${pathCreate}`,
    {}
  ); // GET
  const permissionUpdateDelete = get(
    permissions,
    `/${CURRENT_API_VERSION}/${pathUpdateDelete}`,
    {}
  ); // POST, PUT, DELETE, PATCH
  return {
    read: get(permission, 'GET', defaultPermission),
    create: get(permissionUpdateDelete, 'POST', defaultPermission),
    update: get(permissionUpdateDelete, 'PUT', defaultPermission),
    delete: get(permissionUpdateDelete, 'DELETE', defaultPermission),
    patch: get(permissionUpdateDelete, 'PATCH', defaultPermission)
  };
};

const getPermissionUserTokens = (pathCreate, pathUpdate, permissions) => {
  const permission = get(
    permissions,
    `/${CURRENT_API_VERSION}/${pathCreate}`,
    {}
  );
  const permissionUpdateDelete = get(
    permissions,
    `/${CURRENT_API_VERSION}/${pathUpdate}`,
    {}
  );
  return {
    read: get(permission, 'GET', defaultPermission),
    create: get(permission, 'POST', defaultPermission),
    patch: get(permissionUpdateDelete, 'PATCH', defaultPermission)
  };
};

const mappedPermissions = (permissions) => {
  return {
    [p.FEATURE_COMMENTS]: getFeaturePermission(
      'comment',
      'comment/<object_id>',
      permissions
    ),
    [p.FEATURE_VULNS]: getFeaturePermission(
      'ws/<workspace_name>/vulns',
      'ws/<workspace_name>/vulns/<object_id>',
      permissions
    ),
    [p.FEATURE_TAGS]: getPermissionTags(
      'ws/<workspace_name>/tags',
      'ws/<workspace_name>/vulns/set_tags',
      permissions
    ),
    [p.FEATURE_2FA]: getSettingsPermission(
      '2fa/request',
      permissions
    ),
    [p.FEATURE_AGENTS]: getFeaturePermission(
      'agents',
      'agents/<object_id>',
      permissions
    ),
    [p.FEATURE_CLOUD_AGENTS]: getFeaturePermission(
      'cloud_agents',
      'agents/<object_id>',
      permissions
    ),
    [p.FEATURE_CUSTOM_FIELDS]: getFeaturePermission(
      'custom_fields_schema',
      'custom_fields_schema/<object_id>',
      permissions
    ),
    [p.FEATURE_HOSTS]: getFeaturePermission(
      'ws/<workspace_name>/hosts',
      'ws/<workspace_name>/hosts/<object_id>',
      permissions
    ),
    [p.FEATURE_REPORTING]: getFeaturePermission(
      'reports',
      'reports/<object_id>',
      permissions
    ),
    [p.FEATURE_SCHEDULER]: getFeaturePermission(
      'agents_schedule',
      'agents_schedule/<object_id>',
      permissions
    ),
    [p.FEATURE_SETTINGS_REPORTING]: getSettingsPermission(
      'settings/executive_reports',
      permissions
    ),
    [p.FEATURE_SETTINGS_SMTP]: getSettingsPermission(
      'settings/smtp',
      permissions
    ),
    [p.FEATURE_SETTINGS_LDAP]: getSettingsPermission(
      'settings/ldap',
      permissions
    ),
    [p.FEATURE_SETTINGS_SAML]: getSettingsPermission(
      'settings/saml',
      permissions
    ),
    [p.FEATURE_TICKETING_GITLAB]: getSettingsPermission(
      'integrations/gitlab/issues',
      permissions
    ),
    [p.FEATURE_TICKETING_JIRA]: getSettingsPermission(
      'integrations/jira/issues',
      permissions
    ),
    [p.FEATURE_TICKETING_SERVICENOW]: getSettingsPermission(
      'integrations/servicenow/issues',
      permissions
    ),
    [p.FEATURE_TICKETING_WHD]: getSettingsPermission(
      'integrations/whd/issues',
      permissions
    ),
    [p.FEATURE_USERS]: getFeaturePermission(
      'users',
      'users/<object_id>',
      permissions
    ),
    [p.FEATURE_VULN_TEMPLATE]: getFeaturePermission(
      'vulnerability_template',
      'vulnerability_template/<object_id>',
      permissions
    ),
    [p.FEATURE_WORKSPACES]: getFeaturePermission(
      'ws',
      'ws/<object_id>',
      permissions
    ),
    [p.FEATURE_PIPELINES]: getFeaturePermission(
      'pipelines',
      'pipelines/<object_id>',
      permissions
    ),
    [p.FEATURE_JOBS]: getFeaturePermission(
      'jobs',
      'jobs/<object_id>',
      permissions
    ),
    [p.FEATURE_DUPLICATES]: getPermissionDuplicates(permissions),
    [p.FEATURE_PROJECTS]: getFeaturePermission(
      'projects',
      'projects/<object_id>',
      permissions
    ),
    [p.FEATURE_TASKS]: getFeaturePermission(
      'ptasks',
      'ptasks/<object_id>',
      permissions
    ),
    [p.FEATURE_ANALYTICS]: getFeaturePermission(
      'analytics',
      'analytics/<object_id>',
      permissions
    ),
    [p.FEATURE_GLOBAL_HOSTS]: getFeaturePermission(
      'global_hosts',
      '/global_hosts',
      permissions
    ),
    [p.FEATURE_ADVANCED_NOTIFICATIONS]: getFeaturePermission(
      'notification_center/advanced',
      '/notification_center/advanced',
      permissions
    ),
    [p.FEATURE_SERVICES]: getFeaturePermission(
      'ws/<workspace_name>/services',
      'ws/<workspace_name>/services/<object_id>',
      permissions
    ),
    [p.FEATURE_USER_TOKENS]: getPermissionUserTokens(
      'user_token',
      'user_token/<object_id>',
      permissions
    )
  };
};

export default mappedPermissions;
