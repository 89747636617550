import React from 'react';
import { useSelector } from 'react-redux';
import { selectTags, orderedTags, selectGeneralTags } from 'store/Tags/selectors';
import TagsModal from 'Common/Components/TagsModal';

const CreateEditTagsModal = ({ hostsTags, entity, addTag, removeTag, setTags, allWorkspaces, hostsSelected }) => {
  const wsTags = useSelector(selectTags);
  const allTags = useSelector(selectGeneralTags);
  const tags = useSelector((state) => orderedTags(state, hostsTags, hostsSelected, allWorkspaces));

  const onClickTag = (tag) => {
    if (tag.partialSelected) {
      addTag(tag.name);
    } else {
      if (tag.addedToVuln) removeTag(tag.name);
      else addTag(tag.name);
    }
  };

  const addTags = (newTags) => {
    setTags(newTags);
  };
  return (
    <TagsModal tags={ tags } onClickTag={ onClickTag } addTags={ addTags } allTags={ allWorkspaces ? allTags : wsTags } entity={ entity } />
  );
};

export default CreateEditTagsModal;
