import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import metrics from './metrics';
import Vector from './cvss40';
import Wrapper from './styled';
import ExpandableWrapper from './components/ExpandableWrapper';
import { updateCvssV4 } from 'store/Manage/actions';
import { updateCvssV4General } from 'store/ManageGeneral/actions';
import { updateCvssV4FromAsset } from 'store/Contextualization/AssetDetail/actions';
import DetailsSection from './components/DetailSection';
import CalculatorSection from './components/CalculatorSection';

const CVSS4Calculator = ({ cvss4Data, entity }) => {
  const vectorString = cvss4Data?.vector_string;
  const [vectorInstance] = useState(new Vector(vectorString));
  const details = vectorInstance.severityBreakdown();
  const macroVector = vectorInstance.equivalentClasses();
  const [vectorMetrics, setVectorMetrics] = useState([vectorInstance.metrics]);
  const dispatch = useDispatch();

  const handleClick = (metric, value) => {
    if (value) {
      vectorInstance.updateMetric(metric, value);
      setVectorMetrics([vectorInstance.metrics]);
      if (entity === 'vulns') {
        dispatch(updateCvssV4(vectorInstance.raw()));
      } else if (entity === 'vulnsGeneral') {
        dispatch(updateCvssV4General(vectorInstance.raw()));
      } else {
        dispatch(updateCvssV4FromAsset(vectorInstance.raw()));
      }
    }
  };

  return (
    <Wrapper>
      <ExpandableWrapper
        sectionTitle="Details"
        content={ <DetailsSection cvss4={ cvss4Data } details={ details } macroVector={ macroVector } /> }
      />
      { vectorInstance.metrics && metrics.map((item) =>
        <ExpandableWrapper
          key={ item.metricType }
          sectionTitle={ item.metricType }
          content={ <CalculatorSection info={ item } handleClick={ handleClick } vectorMetrics={ vectorMetrics } /> }
        />
      )}
    </Wrapper>
  );
};

export default CVSS4Calculator;
