const initialState = {
  isFetching: false,
  errorMessage: undefined,
  validationError: '',
  extra_content: [],
  warnings: [],
  total_vulns: 0,
  previewData: [],
  previewError: '',
  isFetchingPreview: false,
  id: undefined,
  parseError: false,
  filterArray: [],
  title: '',
  enterprise: '',
  selectedTemplate: {},
  advancedFilter: '',
  objectives: '',
  summary: '',
  conclusions: '',
  recommendations: '',
  scope: '',
  custom_logo: {}
};
export default initialState;
