import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div` 
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  user-select: none;
  font-size: 21.5px;
  font-weight: 600;
  color: ${colors.dark2};
`;
Title.displayName = 'Title';
