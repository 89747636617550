import {
  LICENSE_FETCHING,
  LICENSE_FAIL
} from './constants';

export const initialState = {
  isFetching: false,
  error: false,
  success: false,
  message: ''
};

function license (state = initialState, action) {
  switch (action.type) {
    case LICENSE_FETCHING: {
      return {
        ...initialState,
        isFetching: action.isFetching,
        error: false
      };
    }

    case LICENSE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        success: false,
        message: action.message
      };
    }

    default: {
      return state;
    }
  }
}

export default license;
