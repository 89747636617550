/* eslint-disable react/no-children-prop */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { selectInputField, selectVulnsCount, selectSelectedTemplate, selectWorkspaces } from 'store/ExecutiveReportEditCreate/selectors';
import { createReport, resetState } from 'store/ExecutiveReportEditCreate/actions';
import { resetFilters } from 'store/Filters/actions';
import { redirect } from 'store/Router/actions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_REPORTING, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
// import { useIntl } from 'react-intl';
import { Header } from './styled';
import { CancelButton, CreateButton } from './components/Buttons';
// import { selectShowLegacyUI } from 'store/Preferences/selectors';

const ReportHeader = ({ isEditing }) => {
  const dispatch = useDispatch();
  const title = useSelector((state) => selectInputField(state, 'title'));
  const enterprise = useSelector((state) => selectInputField(state, 'enterprise'));
  const selectedTemplate = useSelector(selectSelectedTemplate);
  const vulnsCount = useSelector(selectVulnsCount);
  const allowCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_REPORTING, PERMISSION_TYPES.CREATE));
  const allowEdit = useSelector((state) => selectAllowedByRole(state, FEATURE_REPORTING, PERMISSION_TYPES.PATCH));
  const workspaces = useSelector(selectWorkspaces);
  // const showLegacyUI = useSelector(selectShowLegacyUI);
  const cantCreate = title === '' || enterprise === '' || selectedTemplate.length === 0 || vulnsCount === 0 || !allowCreate || !allowEdit || workspaces.length === 0;
  // const intl = useIntl();
  // const titleLabel = intl.formatMessage({ id: isEditing ? 'reports.editCreate.header.edit' : 'reports.editCreate.header.create' });

  const redirectTo = () => {
    dispatch(resetState());
    dispatch(resetFilters('reports'));
    dispatch(redirect('/report'));
  };

  const startReportCreation = async () => {
    dispatch(createReport(false, redirectTo));
  };

  return (
    <Header>
      {/* { showLegacyUI && <Title children={ titleLabel } /> } */}
      <CancelButton onClick={ redirectTo } />
      <CreateButton disabled={ cantCreate } onClick={ startReportCreation } isEditing={ isEditing } />
    </Header>
  );
};

ReportHeader.propTypes = {
  isEditing: PropTypes.bool
};
ReportHeader.defaultProps = {
  isEditing: false
};

export default ReportHeader;
