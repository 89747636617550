/* eslint-disable import/prefer-default-export */
import React from 'react';
import { eq, createFilter } from 'store/Filters/constants';
import useFilterToggle from 'Hooks/useFilterToggle';
import get from 'lodash/get';
import { TagWrapper, TagStyles } from './styled';

export const TagCell = ({ rowInfo }) => {
  const tags = get(rowInfo, 'original.tags', []);
  return (
    <>
      {
          tags.map((tag) => (
            <Tag key={ `tag_assets_table_${tag}` } tag={ tag } />
          ))
        }
    </>
  );
};

const Tag = ({ tag }) => { // eslint-disable-line import/prefer-default-export
  const filter = createFilter('tags__name', eq.name.value, tag);
  const [isFilterActive, toggleFilter] = useFilterToggle(filter, 'assets');

  return (
    <TagWrapper className="mr-2 tag" onClick={ toggleFilter } state={ isFilterActive }>
      <TagStyles>{tag}</TagStyles>
    </TagWrapper>
  );
};
