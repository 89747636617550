import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  PERMISSION_TYPES, FEATURE_TICKETING_GITLAB, FEATURE_TICKETING_JIRA, FEATURE_TICKETING_SERVICENOW, FEATURE_TICKETING_WHD
} from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import useExpandable from 'Hooks/useExpandable';
import ExpandableWrapper from '../ExpandableWrapper';
import SendTo from './SendTo';
import List from 'Common/Components/List';
import { DataWrapper, Subtitle } from './styled';
import { selectParsedIssueTrackersContext } from 'store/ManageGeneral/selectors';

const TAB = 'general';
const ID = 'issuetracker';

const IssueTrackers = () => {
  const intl = useIntl();
  const issuetracker = useSelector(selectParsedIssueTrackersContext);
  const getDefaultValue = () => Object.keys(issuetracker).length !== 0;
  const isExpanded = useExpandable(TAB, ID, getDefaultValue());

  const isEnabledJira = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const isEnabledGitlab = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const isEnabledServiceNow = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const isEnabledWhd = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));

  const isEnabledByRoleJira = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleGitlab = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleServiceNow = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleWhd = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));

  const showIssueTrackers = () => isEnabledJira || isEnabledGitlab || isEnabledServiceNow || isEnabledWhd;
  const canSendToTool = () => isEnabledByRoleJira || isEnabledByRoleGitlab || isEnabledByRoleServiceNow || isEnabledByRoleWhd;

  return showIssueTrackers() && (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.issueTrackers' }) } tab={ TAB } id={ ID }>
      { canSendToTool() && <SendTo /> }
      { issuetracker.length !== 0 &&
         <DataWrapper>
          <Subtitle>{ intl.formatMessage({ id: 'manage.detail.tab.general.issueTrackers.subtitle' }) }</Subtitle>
          <List data={ issuetracker } />
         </DataWrapper>
      }
    </ExpandableWrapper>
  );
};

export default IssueTrackers;
