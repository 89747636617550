import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import { selectBulkUpdateCustomAttribute, selectModalBulkUpdateField, selectModalBulkUpdateValue } from 'store/ManageGeneral/selectors';
import { setBulkUpdateValue } from 'store/ManageGeneral/actions';
import get from 'lodash/get';

const BulkMarkdownEditor = () => {
  const dispatch = useDispatch();
  const bulkUpdateField = useSelector(selectModalBulkUpdateField);
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);
  const customAttribute = useSelector(selectBulkUpdateCustomAttribute);
  const fieldName = get(customAttribute, 'field_display_name', '');
  const title = bulkUpdateField === 'custom_fields' ? fieldName : bulkUpdateField;

  return (
    <MarkdownEditor
      title={ title }
      onBlur={ (value) => dispatch(setBulkUpdateValue(value)) }
      value={ bulkUpdateValue }
    />
  );
};

export default BulkMarkdownEditor;
