import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  ConfirmButton, Warning, ModalBody
} from './styled';
import useKeyPress from 'Hooks/useKeyPress';

const ModalWarning = ({
  onCloseCallback, title, description
}) => {
  useKeyPress(() => {
    onCloseCallback();
  }, ['Enter', 'Escape']);

  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>{ title }</Title>
        </ModalTitle>
        <ModalBody>
          <BodyText>{ description }</BodyText>
        </ModalBody>
        <ButtonsContainer>
          <ConfirmButton onClick={ onCloseCallback }>
            Ok
          </ConfirmButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ModalWarning;
