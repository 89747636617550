import styled from 'styled-components';

export const Text = styled.p`
cursor: pointer;
`;

export const BlueStyle = styled.b`
  color: #0082ed;
`;

export const Bold = styled.b`
  color: #292929;
`;
