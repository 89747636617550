import styled, { keyframes, css } from 'styled-components';
import { menuItemHeight } from 'Common/Components/ContextMenu/components/MenuItem/styled';
import { MenuWrapper } from 'Common/Components/ContextMenu/styled';

export const subMenuWidth = 200;

const fadeInKeyframes = (from = 0, to = 1) => keyframes`
  from { opacity: ${from};}
  to { opacity: ${to};}
`;

const fadeIn = (from, to) => css`
  animation: ${fadeInKeyframes(from, to)} 0.5s forwards ease;
`;

export const SubMenuWrapper = styled(MenuWrapper)`
    display: flex;
    position: fixed;
    left: auto;
    top: auto;
    margin-left: ${({ xPos }) => `${xPos}px`};
    margin-top: ${({ yPos }) => `${yPos}px`};
    width: ${`${subMenuWidth}px`};
    ${fadeIn(0, 1)};
`;
SubMenuWrapper.displayName = 'SubMenuWrapper';

export const RelativeWrapper = styled.div`
    position: relative;
    height: ${`${menuItemHeight}px`};
    width: ${`${subMenuWidth}px`};
`;
RelativeWrapper.displayName = 'RelativeWrapper';

export const AbsoluteWrapper = styled.div`
    position: absolute;
    height: ${`${menuItemHeight}px`};
    width: ${`${subMenuWidth}px`};
`;
AbsoluteWrapper.displayName = 'AbsoluteWrapper';
