import React from 'react';
import { Wrapper, Label, LightLabel } from './styled';
import { WsAddButton } from '../WSActionBar/components/LeftGroup';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from 'store/Faraday/selectors';

const EmptyState = () => {
  const isAdmin = useSelector(selectIsAdmin);
  return (
    <Wrapper>
      <Label>You currently have 0 Workspaces</Label>
     { isAdmin
       ? <>
        <LightLabel>Start by creating one</LightLabel>
        <WsAddButton />
         </>
       : <LightLabel>Please contact your admin.</LightLabel>
      }
    </Wrapper>
  );
};

export default EmptyState;
