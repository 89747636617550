import fetchApi from 'services/api/connector';

//
// SMTP section
//
export const fetchSmtp = () => fetchApi('settings/smtp');
export const saveSmtp = (host, port, sender, username, password, enabled, ssl) => fetchApi('settings/smtp', {
  method: 'PATCH',
  data: {
    host, port, sender, username, password, enabled, ssl
  }
});

//
// SAML section
//
export const fetchSaml = () => fetchApi('settings/saml');
export const saveSaml = (data) => fetchApi('settings/saml', { method: 'PATCH', data });

//
// CHANGE PASSWORD section
//
export const changePassword = (password, new_password, new_password_confirm) => fetchApi('change', { version: 'v1', method: 'POST', data: { password, new_password, new_password_confirm } });

//
// TWO FACTOR section
//
export const fetchTwoFactor = () => fetchApi('session', { version: 'v1' });
export const requestTwoFactor = () => fetchApi('2fa/request', { method: 'POST', version: 'v3' });
export const confirmTwoFactor = (password, secret) => fetchApi('2fa/confirm', { method: 'POST', data: { password, secret }, version: 'v3' }, null, false);
export const disableTwoFactor = (password) => fetchApi('2fa/disable', { method: 'POST', data: { password }, version: 'v3' }, null, false);

//
// TICKETING TOOLS section
//
export const fetchTicketingToolsTemplates = (tool) => fetchApi(`${tool}/templates`);
export const fetchTicketingTools = (tool) => fetchApi(`integrations/${tool}`);
export const saveTicketingToolsJira = (tool, url, project_key, consumer_key, template, issue_type, custom_fields) => fetchApi(`integrations/${tool}`, {
  method: 'PUT',
  data: {
    tool,
    oauth: {
      consumer_key
    },
    project_key,
    url,
    ticket_config: {
      custom_fields,
      issue_type,
      template
    }
  }
});
export const saveTicketingToolsServiceNow = (data) => fetchApi('integrations/servicenow', {
  method: 'PUT',
  data
});
export const saveTicketingToolsGitLab = (tool, url, access_token, project, ssl_verify, self_signed_cert, template) => fetchApi(`integrations/${tool}`, {
  method: 'PUT',
  data: {
    tool,
    access_token,
    project,
    ssl_config: {
      ssl_verify,
      self_signed_cert
    },
    url,
    ticket_config: {
      template
    }
  }
});
export const saveTicketingToolsSolarWinds = (tool, url, apikey, template) => fetchApi(`integrations/${tool}`, {
  method: 'PUT',
  data: {
    tool,
    apikey,
    url,
    ticket_config: {
      template
    }
  }
});
export const fetchTemplates = (tool) => fetchApi(`integrations/${tool}/templates`);
export const fetchProjectsData = (tool, data) => fetchApi(`integrations/${tool}/get_projects_data`, { // get_projects_from_jira
  data
});
export const authenticate = (tool, data) => fetchApi(`integrations/${tool}/oauth/authenticate`, { method: 'POST', version: 'v3', data }, null, false);
export const deauthenticate = (tool) => fetchApi(`integrations/${tool}/oauth/deauthenticate`, { method: 'POST', version: 'v3' }, null, false);
export const authorize = (tool, data) => fetchApi(`integrations/${tool}/oauth/authenticate/authorize`, { method: 'POST', version: 'v3', data }, null, false);
export const accessToken = (tool) => fetchApi(`integrations/${tool}/oauth/authenticate/access_token`, { method: 'POST' }, null, false);
export const sendVulnToServiceNow = (data) => fetchApi('integrations/servicenow/issues', {
  method: 'POST',
  data
});
export const sendVulnToGitLab = (data) => fetchApi('integrations/gitlab/issues', {
  method: 'POST',
  data
});
export const sendVulnToSolarWinds = (data) => fetchApi('integrations/whd/issues', { // antes se llamaba solarwinds, ahora es web help desk
  method: 'POST',
  data
});
export const updateVuln = (id, workspace, data) => fetchApi(`ws/${workspace}/vulns/${id}`, { version: 'v3', method: 'PATCH', data }, null, false);

// JIRA
export const fetchJIRAProjectsData = (data) => {
  if (!data || !data.password || !data.username) return fetchApi('integrations/jira/get_projects_from_jira');
  return fetchApi(`integrations/jira/get_projects_from_jira?password=${data.password}&username=${data.username}`);
};

export const fetchJIRACacheProjectsData = (data) => {
  if (!data || !data.password || !data.username) return fetchApi('integrations/jira/get_project_saved_data');
  return fetchApi(`integrations/jira/get_project_saved_data?password=${data.password}&username=${data.username}`);
};

export const fetchJIRAProjectTemplate = (key) => fetchApi(`integrations/jira/get_project_saved_data?project_key=${key}`);
export const sendVulnToJira = (data) => fetchApi('integrations/jira/issues', { // Buscar uso
  method: 'POST',
  data
});

export const sendTemplateToJira = (data) => fetchApi('integrations/jira', {
  method: 'PUT',
  data
});

export const getPublicKey = (tool) => fetchApi(`integrations/${tool}/oauth/authenticate/get_public_key`);

export const addJiraUser = (data) => fetchApi('integrations/jira/add_jira_user', {
  method: 'POST',
  data
});

export const deleteJiraUser = (data) => fetchApi('integrations/jira/remove_jira_user', {
  method: 'DELETE',
  data
});

export const getActiveConfig = () => fetchApi('active_integration');

export const updateActiveConfig = (tool) => fetchApi(`integrations/${tool}/activate`, { method: 'POST' });

// LDAP

export const fetchLdap = () => fetchApi('settings/ldap');
export const saveLdap = (payload) => fetchApi('settings/ldap', { method: 'PATCH', data: payload });

// Notifications

export const getNotificationSlackOauthToken = () => fetchApi('settings/notifications');
export const setNotificationSlackOauthToken = (slack_token) => fetchApi('settings/notifications', { method: 'PATCH', data: { slack_token } });

// Access Tokens
export const fetchUserTokens = (queryParam) => fetchApi(`user_token/filter?q=${escape(JSON.stringify(queryParam))}`);
export const fetchUserTokenDetail = (id) => fetchApi(`user_token/${id}`);
export const createUserToken = (data) => fetchApi('user_token', { method: 'POST', data });
export const updateUserToken = (id, data) => fetchApi(`user_token/${id}`, { method: 'PATCH', data });
export const fetchScopes = () => fetchApi('user_token/scopes');
export const allowMultipleSessions = (isAllow) => fetchApi('settings/sessions', { method: 'PATCH', data: { allow_multiple_sessions: isAllow } });
export const getMultipleSessions = () => fetchApi('settings/sessions');
