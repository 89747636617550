/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'Common/Components/Skeleton';
import DateFormatter from 'Common/Components/DateFormatter';
import get from 'lodash/get';
import {
  NameCell, EditDeleteCell, AddTaskCell
} from './components/Cells';

export default function getColumns (isFetching, isPivot, onExpand) {
  const columns = [
    {
      Header: () => null,
      show: false,
      id: 'expander',
      expander: true,
      Expander: () => null
    }, {
      Header: () => <FormattedMessage id="planner.project.name" />,
      id: 'name',
      resizable: false,
      sortable: false,
      headerStyle: { borderRight: '1px dashed transparent' },
      Cell: (rowInfo) => <NameCell onExpand={ onExpand } isFetching={ isFetching } isPivot={ isPivot } rowInfo={ rowInfo } />
    }, {
      Header: () => <FormattedMessage id="planner.project.startDate" />,
      id: 'startDate',
      resizable: false,
      sortable: false,
      width: 110,
      Cell: (rowInfo) => (isFetching ? <Skeleton /> : !isPivot && rowInfo.original.startDate ? <DateFormatter date={ get(rowInfo, 'original.startDate', '') } midDate /> : '')
    }, {
      Header: () => <FormattedMessage id="planner.project.endDate" />,
      id: 'endDate',
      resizable: false,
      sortable: false,
      width: 110,
      Cell: (rowInfo) => (isFetching ? <Skeleton /> : !isPivot && <DateFormatter date={ get(rowInfo, 'original.endDate', '') } midDate />)
    }, {
      Header: () => <FormattedMessage id="planner.project.progress" />,
      id: 'progress',
      resizable: false,
      sortable: false,
      maxWidth: 155,
      minWidth: 55,
      Cell: (rowInfo) => (isFetching ? <Skeleton /> : !isPivot && get(rowInfo, 'original.status', ''))
    }, {
      Header: () => <FormattedMessage id="planner.project.description" />,
      id: 'description',
      resizable: false,
      sortable: false,
      maxWidth: 155,
      minWidth: 55,
      Cell: (rowInfo) => (isFetching ? <Skeleton /> : !isPivot && get(rowInfo, 'original.description', ''))
    }, {
      Header: () => <FormattedMessage id="planner.project.assignees" />,
      id: 'assignees',
      className: isPivot ? 'actionButton' : '',
      resizable: false,
      sortable: false,
      maxWidth: 155,
      minWidth: 55,
      Cell: (rowInfo) => <EditDeleteCell isPivot={ isPivot } isFetching={ isFetching } rowInfo={ rowInfo } />
    }, {
      Header: () => <FormattedMessage id="planner.project.dependencies" />,
      id: 'dependencies',
      className: isPivot ? 'actionButton' : '',
      resizable: false,
      sortable: false,
      maxWidth: 155,
      minWidth: 55,
      Cell: (rowInfo) => <AddTaskCell isPivot={ isPivot } isFetching={ isFetching } rowInfo={ rowInfo } />
    }
  ];
  return columns;
}
