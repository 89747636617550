import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  margin-left: 12px;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 2.82;
  color: #63758d;
  user-select: none;
`;
Title.displayName = 'Title';
