/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { PropTypes } from 'prop-types';
import SystemComment from '../SystemComment';
import UserComment from '../UserComment';
import CommentSeparator from './styled';

const CommentItem = ({
  comment, index, onRemove
}) => {
  const state = useSelector((s) => ({ currentUser: s.sesion.user }));

  const isSystemComment = get(comment, 'comment_type', '') === 'system';

  if (isSystemComment) {
    return (
      <>
        { index !== 0 && <CommentSeparator />}
        <SystemComment comment={ comment } key={ `sys_comment_${comment.id}` } />
      </>
    );
  }

  return (
    <>
      { index !== 0 && <CommentSeparator />}
      <UserComment
        comment={ comment }
        key={ `user_comment_${comment.id}` }
        isCreator={ state.currentUser === comment.creator }
        onRemove={ onRemove }
      />
    </>
  );
};

CommentItem.propTypes = {
  comment: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default CommentItem;
