import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectCurrentHost } from 'store/Host/selectors';
import {
  clearError, resetServiceDetail,
  redirectToService, loadServiceDetail, updateService,
  hideServiceDetail, resetState, addServiceTag, removeServiceTag,
  setServicesTags, setCurrentHostInServices, getServices
} from 'store/Contextualization/AssetDetail/actions';
import {
  selectError, selectErrorMessage, selectShowDetail,
  selectDetail, selectServiceId, selectServiceOwned,
  selectServiceDescription, selectSelected, selectServicesTags,
  selectDetailTags
} from 'store/Contextualization/AssetDetail/selectors';
import ErrorDialog from 'Common/Components/ErrorDialog';
import ActionBar from './ActionBar';
import ServicesTable from './ServicesTable';
import { closeModal } from 'store/modals/actions';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_CREATE_SERVICE } from 'store/modals/modals';
import CreateService from 'Screens/Contextualization/Services/components/CreateService';
import Wrapper from './styled';
import ServiceDetail from 'Screens/Contextualization/Services/components/ServiceDetail';
import { selectShowTagsModal } from 'store/Tags/selectors';
import CreateEditTagsModal from 'Screens/Contextualization/Services/components/CreateEditTagsModal';
import { getData } from 'store/Host/actions';

const HostServices = ({ params }) => {
  const dispatch = useDispatch();
  const currentAsset = useSelector(selectCurrentHost);
  const assetId = get(currentAsset, '_id', 0);
  const error = useSelector((state) => selectError('services', state));
  const errorMessage = useSelector((state) => selectErrorMessage('services', state));
  const showDetail = useSelector((state) => selectShowDetail('services', state));
  const detailData = useSelector((state) => selectDetail('services', state));
  const serviceId = useSelector(selectServiceId);
  const owned = useSelector(selectServiceOwned);
  const description = useSelector(selectServiceDescription);
  const serviceDetailTags = useSelector((state) => selectDetailTags('services', state));
  const servicesSelected = useSelector((state) => selectSelected('services', state));
  const showTagsModal = useSelector((state) => selectShowTagsModal('serviceAssetMenu', state));
  const servicesTags = useSelector(selectServicesTags);

  const handleParamId = useCallback(() => {
    const id = get(params, 'serviceId', 0);
    if (parseInt(id, 10) > 0) dispatch(loadServiceDetail(id));
    else dispatch(hideServiceDetail());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (assetId) dispatch(getServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => {
    const id = get(params, 'id', 0);
    if ((!assetId) && id) {
      dispatch(getData());
      dispatch(setCurrentHostInServices(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => {
    dispatch(resetState('services'));
  }, [dispatch]);

  return (
    <Wrapper>
      <ActionBar />
      <ServicesTable />
      { showDetail &&
        <ServiceDetail
          data={ detailData }
          resetServiceDetail={ resetServiceDetail }
          serviceId={ serviceId }
          owned={ owned }
          updateService={ (id, obj) => dispatch(updateService(id, obj)) }
          redirectToService={ redirectToService }
          loadServiceDetail={ loadServiceDetail }
          description={ description }
          entity="serviceAssetDetail"
          serviceTags={ serviceDetailTags }
          servicesSelected={ servicesSelected }
          addTag={ addServiceTag }
          removeTag={ removeServiceTag }
          setServicesTags={ setServicesTags }
        />
      }
      <ReduxModal id={ MODAL_CREATE_SERVICE }>
        <CreateService onClose={ () => dispatch(closeModal(MODAL_CREATE_SERVICE)) } entity="servicesAsset" />
      </ReduxModal>
      { showTagsModal && <CreateEditTagsModal servicesTags={ servicesTags } entity="serviceAssetMenu" servicesSelected={ servicesSelected } addTag={ addServiceTag } removeTag={ removeServiceTag } setServicesTags={ setServicesTags } /> }
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(clearError('services')) } /> : null }
    </Wrapper>
  );
};

export default HostServices;
