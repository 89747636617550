import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { resetState } from 'store/Analytics/actions';
import { redirect } from 'store/Router/actions';
// import { TitleWrapper, Title, Subtitle } from 'Screens/Analytics/components/ActionBar/styled';
import { Wrapper, CancelButton } from './styled';
// import { selectShowLegacyUI } from 'store/Preferences/selectors';

const Cancel = ({ showNewUI }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const cancelLabel = intl.formatMessage({ id: 'analytics.createEdit.cancel' });

  const onClick = () => {
    dispatch(resetState());
    dispatch(redirect('/analytics'));
  };
  return (
    <CancelButton onClick={ onClick } showNewUI={ showNewUI }>{ cancelLabel }</CancelButton>
  );
};

const ActionBar = () => {
  // const showLegacyUI = useSelector(selectShowLegacyUI);
  return (
    <Wrapper>
      {/* { showLegacyUI &&
        <TitleWrapper>
          <Title>Analytics</Title>
          <Subtitle>- Create new graph</Subtitle>
        </TitleWrapper>
      } */}
      <Cancel showNewUI />
    </Wrapper>
  );
};
export default ActionBar;
