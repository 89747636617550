import styled from 'styled-components';
import colors from 'Styles/colors';

const Wrapper = styled.div`
  flex: 1 1 0;
  width: 100%;
  .calendar {
    rect {
      stroke: none;
      fill: ${colors.paleGrey};
    }
    text {
      font-family: 'CentraNo2';
      font-size: 10px;
      color: ${colors.grey5};
      fill: ${colors.grey5};
      text-transform: uppercase;
    } 
  }

  .bar text {
    fill:${colors.blueGrey2};
    font-weight: 500;
  }

  .rows rect:nth-child(even) {
    fill: ${colors.white};
  }

  .rows rect:nth-child(${(props) => props.row}) {
    fill: ${colors.iceBlue};
  }
`;
export default Wrapper;
