import styled from 'styled-components';
import { ReactComponent as MainDuplicateSVG } from 'Images/main-duplicate.svg';
import { ReactComponent as DuplicateSVG } from 'Images/duplicate.svg';

export const Text = styled.p`
  margin: auto 15px auto 0px;
  font-size: 13.5px;
  color: #53686f;
  font-weight: ${({ confirmed }) => (confirmed ? '600' : 'normal')}; 
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
Text.displayName = 'Text';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconWrapper = styled.div`
  width: 19px;
  height: 19px;
  margin-left: auto;
  cursor: default;
`;
IconWrapper.displayName = 'IconWrapper';

export const MainDuplicateIcon = styled(MainDuplicateSVG)`
  cursor: default;
  `;
MainDuplicateIcon.displayName = 'DuplicateIcon';

export const DuplicateIcon = styled(DuplicateSVG)`
  cursor: default;
`;
DuplicateIcon.displayName = 'DuplicateIcon';
