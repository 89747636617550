/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Wrapper } from './styled';

class Dropdown extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.state = {
      value: undefined
    };
  }

  componentDidMount () {
    if (this.props.defaultValue !== null && this.props.defaultValue !== '') {
      const option = {
        label: (this.props.avoidCamelize ? this.props.defaultValue : this.props.defaultValue.charAt(0).toUpperCase() + this.props.defaultValue.slice(1).toLowerCase()),
        value: this.props.defaultValue
      };
      this.setState({ value: option });
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      if (this.props.defaultValue !== null && this.props.defaultValue !== '') {
        const option = {
          label: (this.props.avoidCamelize ? this.props.defaultValue : this.props.defaultValue.charAt(0).toUpperCase() + this.props.defaultValue.slice(1).toLowerCase()),
          value: this.props.defaultValue
        };
        this.setState({ value: option });
      } else {
        this.setState({ value: this.props.defaultValue });
      }
    }
  }

  onChangeHandler (selectedOption) {
    const {
      selectObject, updateValue, avoidCamelize, defaultValue
    } = this.props;
    const { value, id } = selectedOption;
    if (selectObject) {
      updateValue(selectedOption);
    } else {
      updateValue(value);
    }
    const option = { label: avoidCamelize ? defaultValue : value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(), value, id };
    this.setState({ value: option });
  }

  render () {
    const { value } = this.state;
    const {
      customStyle,
      options,
      disabled,
      placeholder,
      className,
      menuPortalTarget,
      id,
      isSearchable
    } = this.props;

    return (
      <Wrapper isDisabled={ disabled }>
        <Select
          menuPlacement="auto"
          styles={ customStyle }
          isSearchable={ isSearchable }
          value={ value }
          isDisabled={ disabled }
          onChange={ (e) => { this.onChangeHandler(e); } }
          options={ options }
          placeholder={ placeholder }
          className={ className }
          menuPortalTarget={ menuPortalTarget }
          inputId={ id }
        />
      </Wrapper>
    );
  }
}

Dropdown.propTypes = {
  customStyle: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any,
    value: PropTypes.string
  })).isRequired,
  disabled: PropTypes.bool,
  updateValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  menuPortalTarget: PropTypes.node,
  selectObject: PropTypes.bool
};

Dropdown.defaultProps = {
  disabled: false,
  defaultValue: '',
  placeholder: '',
  className: '',
  menuPortalTarget: undefined,
  selectObject: false,
  isSearchable: false
};

export default withRouter(Dropdown);
