import React from 'react';
import { PropTypes } from 'prop-types';
import { getVulnsPreview } from 'store/ExecutiveReportEditCreate/actions';
import { useDispatch, useSelector } from 'react-redux';
import { addFilter, removeFilter } from 'store/Filters/actions';
import { isFilteringBy } from 'store/Filters/selectors';
import { selectWorkspaces } from 'store/ExecutiveReportEditCreate/selectors';
import { Tag, Label } from './styled';

const FilterTag = ({ filterElement, disabled }) => {
  const dispatch = useDispatch();
  const { filter, name } = filterElement;
  const isFilterApplied = useSelector((state) => (isFilteringBy(state, 'reports', filter)));
  const workspaces = useSelector(selectWorkspaces);

  const toggleFilter = () => {
    if (!disabled) {
      if (isFilterApplied) dispatch(removeFilter('reports', filter));
      else dispatch(addFilter('reports', filter));
      dispatch(getVulnsPreview(workspaces));
    }
  };

  return (
    <Tag
      disabled={ disabled }
      onClick={ toggleFilter }
      selected={ isFilterApplied }
    >
      <Label>
        {name.label}
      </Label>
    </Tag>
  );
};
FilterTag.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filterElement: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

FilterTag.defaultProps = {
  disabled: false
};

export default FilterTag;
