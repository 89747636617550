import React, { createRef } from 'react';
import { useDispatch } from 'react-redux';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import handleLeftClick from 'Common/Components/CustomReactTable/leftClickHelper';
import colors from 'Styles/colors';
import debounce from 'lodash/debounce';
import Wrapper from './styled';

const Table = ({
  selectRowAction, // custom action que permite seleccionar una fila, es custom para que se pueda usar desde varios lugares donde exista una tabla.
  rowsPerPage,
  page,
  isFetching,
  data,
  rowsSelected,
  lastRowSelected,
  lastIndexForRange,
  lastSelected,
  columns,
  onDoubleClick
}) => {
  const dispatch = useDispatch();
  const table = createRef(null);
  const mockData = [...Array(rowsPerPage).keys()];

  const selectRow = (selectionInfo) => {
    dispatch(selectRowAction(selectionInfo));
  };

  const debounceFunction = debounce((callback) => {
    callback();
  }, 190);

  const getTrProps = (state, rowInfo) => {
    const selected = rowsSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

    if (rowInfo && rowInfo.row && !rowInfo.original.count) {
      return {
        onClick: (e) => {
          const leftClickResponse = handleLeftClick(e, rowInfo, '', selected, data, rowsSelected, lastRowSelected, lastIndexForRange, lastSelected);
          if (leftClickResponse) {
            debounceFunction(() => {
              selectRow(leftClickResponse);
            });
          }

          return true;
        },
        onDoubleClick: () => {
          if (rowInfo.original._id || rowInfo.original.id) {
            if (onDoubleClick) {
              debounceFunction(() => {
                selectRow({ rowSelected: rowInfo.original, newRowsSelected: [rowInfo.original] });
                onDoubleClick(rowInfo.original);
              });
            }
          }
        },
        onContextMenu: () => {
          if (!selected) selectRow({ newRowsSelected: [rowInfo.original] });
          return false;
        },
        style: {
          background: selected ? colors.blue8 : 'transparent'
        }
      };
    }
    return {};
  };

  return (
    <Wrapper ref={ table }>
      <CustomReactTable
        data={ isFetching ? mockData : data }
        columns={ columns }
        minRows={ 0 }
        page={ page }
        defaultPageSize={ rowsPerPage }
        manual
        getTrProps={ getTrProps }
        showPagination={ false }
      />
    </Wrapper>
  );
};

export default Table;
