import styled from 'styled-components';
import AddButton from 'Common/Components/Contextualization/AddButton';
import colors from 'Styles/colors';

const Button = styled(AddButton)`
  background-color: ${colors.iceBlue};
  border: 1px solid ${colors.grey12};
  margin-right: auto;
`;
Button.displayName = 'Button';

export default Button;
