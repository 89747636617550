import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectAssetsTags, selectServicesTags, selectVulnsTags } from 'store/Agents/selectors';
import { setTagsEntity } from 'store/Agents/actions';
import Entity from './Entity';
import { Wrapper, Title, Subtitle, Input, Entities } from './styled';

const Tags = ({ tags, setTags }) => {
  const dispatch = useDispatch();
  const assetsTags = useSelector(selectAssetsTags);
  const vulnsTags = useSelector(selectVulnsTags);
  const servicesTags = useSelector(selectServicesTags);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'agents.triggerAgent.tags.title' });
  const subtitle = intl.formatMessage({ id: 'agents.triggerAgent.tags.subtitle' });

  return (
    <Wrapper>
      <Title children={ title } />
      <Subtitle children={ subtitle } />
      <Input onChange={ (e) => setTags(e.target.value) } value={ tags } />
      <Entities>
        <Entity entity="assets" value={ assetsTags } onChangeField={ (checked) => dispatch(setTagsEntity('assetsTags', checked)) } />
        <Entity entity="vulnerabilities" value={ vulnsTags } onChangeField={ (checked) => dispatch(setTagsEntity('vulnsTags', checked)) } />
        <Entity entity="services" value={ servicesTags } onChangeField={ (checked) => dispatch(setTagsEntity('servicesTags', checked)) } />
      </Entities>
    </Wrapper>
  );
};

export default Tags;
