import React from 'react';
import { TableHeader, TableHeaderAlias } from './styled';
import { FormattedMessage } from 'react-intl';
import { GenericCell, AliasCell, ActionsCell, CreatedCell, ExpiredCell } from './components/Cells';

export default function getColumns (tab, isFetching) {
  const columns = [
    {
      Header: () => <TableHeaderAlias> <FormattedMessage id="preferences.accessTokens.table.alias" /></TableHeaderAlias>,
      id: 'alias',
      resizable: true,
      sortable: false,
      maxWidth: 150,
      minWidth: 110,
      Cell: (rowInfo) => <AliasCell rowInfo={ rowInfo } isFetching={ isFetching } />
    },
    {
      Header: () => <TableHeader> <FormattedMessage id="preferences.accessTokens.table.token" /></TableHeader>,
      id: 'token',
      resizable: false,
      sortable: false,
      maxWidth: 120,
      minWidth: 120,
      Cell: (rowInfo) => <GenericCell rowInfo={ rowInfo } isFetching={ isFetching } column="token" />
    },
    {
      Header: () => <TableHeader> <FormattedMessage id="preferences.accessTokens.table.scope" /></TableHeader>,
      id: 'scope',
      resizable: false,
      sortable: false,
      maxWidth: 70,
      minWidth: 70,
      Cell: (rowInfo) => <GenericCell rowInfo={ rowInfo } isFetching={ isFetching } column="scope" />
    },
    {
      Header: () => <TableHeader> <FormattedMessage id="preferences.accessTokens.table.created" /></TableHeader>,
      id: 'create_date',
      resizable: false,
      maxWidth: 100,
      minWidth: 100,
      Cell: (rowInfo) => <CreatedCell rowInfo={ rowInfo } isFetching={ isFetching } column="create_date" />
    },
    {
      Header: () => <TableHeader> <FormattedMessage id="preferences.accessTokens.table.expires" /></TableHeader>,
      id: 'expires_at',
      resizable: false,
      sortable: false,
      maxWidth: 100,
      minWidth: 100,
      Cell: (rowInfo) => <ExpiredCell rowInfo={ rowInfo } isFetching={ isFetching } column="expires_at" />
    },
    {
      Header: () => <TableHeader> <FormattedMessage id="preferences.accessTokens.table.actions" /></TableHeader>,
      id: 'actions',
      resizable: false,
      sortable: false,
      maxWidth: 87,
      minWidth: 87,
      Cell: (rowInfo) => <ActionsCell rowInfo={ rowInfo } isFetching={ isFetching } tab={ tab } />
    }
  ];

  return columns;
}
