import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  CLOSED_FILTERS, CONFIRMED_FLAG_FILTERS, DASHBOARD_FILTERS
} from 'store/Filters/constants';
import { addFilter } from 'store/Filters/actions';
import { redirect } from 'store/Router/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { isFilteringBy } from 'store/Filters/selectors';
import {
  Wrapper, Title, Content, Threat, Value, Text,
  ExploitIcon, CisaIcon, TrendingIcon, Empty
} from './styled';
import formatNumber from 'Common/Functions/FormatNumber';

const Threats = () => {
  const dispatch = useDispatch();
  const stats = useSelector((state) => get(state, 'dashboard.tools[3].data.stats', {}));
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'dashboard.threats.title' });
  const exploitText = intl.formatMessage({ id: 'dashboard.threats.exploitable' });
  const cisaText = intl.formatMessage({ id: 'dashboard.threats.cisa' });
  const trendingText = intl.formatMessage({ id: 'dashboard.threats.trending' });

  const { confirmed } = CONFIRMED_FLAG_FILTERS;
  const { closed } = CLOSED_FILTERS;

  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));
  const isFilteringByClosed = useSelector((state) => isFilteringBy(state, 'vulns', closed));

  const handleClick = (tag) => {
    const filterBySeverity = DASHBOARD_FILTERS.vulnsByTag(tag);
    const filterByConfirmed = isFilteringByConfirm ? CONFIRMED_FLAG_FILTERS.confirmed : CONFIRMED_FLAG_FILTERS.notConfirmed;

    dispatch(addFilter('vulns', filterBySeverity));
    if (isFilteringByConfirm) dispatch(addFilter('vulns', filterByConfirmed));
    if (isFilteringByClosed) dispatch(addFilter('vulns', CLOSED_FILTERS.closed));
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  return (
    <Wrapper>
      <Title>{ title }</Title>
      {stats
        ? <Content>
          <Threat onClick={ () => { handleClick('exploit-available'); } }>
            <ExploitIcon />
            <Value>{ formatNumber(stats.exploitable_vulns) }</Value>
            <Text>{ exploitText }</Text>
          </Threat>
          <Threat onClick={ () => { handleClick('CISA'); } }>
            <CisaIcon />
            <Value>{ formatNumber(stats.cisa_vulns) }</Value>
            <Text>{ cisaText }</Text>
          </Threat>
          <Threat onClick={ () => { handleClick('trending'); } }>
            <TrendingIcon />
            <Value>{ formatNumber(stats.trending_vulns) }</Value>
            <Text>{ trendingText }</Text>
          </Threat>
          </Content>
        : <Empty>No vulnerabilities has been found yet.</Empty>
      }
    </Wrapper>
  );
};

export default Threats;
