/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import Skeleton from 'Common/Components/Skeleton';
import { Target, VulnName } from './styled';

const SeverityCell = ({ severity }) => (
  <div className={ `background-${severity === 'info' ? 'informational' : (severity === 'med' ? 'medium' : severity)} ml-0` }>
    <div className="severity-letter">{severity ? severity.charAt(0).toUpperCase() : ''}</div>
  </div>
);

SeverityCell.propTypes = { severity: PropTypes.string.isRequired };

const VulnNameCell = ({ name }) => (
  <VulnName>
    {name}
  </VulnName>
);

VulnNameCell.propTypes = { name: PropTypes.string.isRequired };

const TargetCell = ({ target }) => (
  <Target>
    {target}
  </Target>
);

TargetCell.propTypes = { target: PropTypes.string.isRequired };

export default function getColumns (isFetching) {
  const columns = [{
    Header: () => <div />,
    maxWidth: 50,
    Cell: (rowInfo) => {
      if (isFetching) return <Skeleton />;
      return <SeverityCell severity={ rowInfo.original.severity } />;
    },
    resizable: false
  }, {
    Header: () => <div />,
    resizable: false,
    getProps: () => ({ className: 'column_name' }),
    Cell: (rowInfo) => {
      if (isFetching) return <Skeleton />;
      return <VulnNameCell name={ rowInfo.original.name } />;
    }
  }, {
    Header: () => <div />,
    maxWidth: 110,
    resizable: false,
    Cell: (rowInfo) => {
      if (isFetching) return <Skeleton />;
      return <TargetCell target={ rowInfo.original.target } />;
    }
  }];

  return columns;
}
