import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  background-color: ${colors.paleGrey};
  padding: 12px 0 13px 14px;
  display: flex;
  margin-top: ${({ hasItems }) => (hasItems ? '0' : '25px')};
`;
Wrapper.displayName = 'Wrapper';

export const InputWrapper = styled.div`
  margin-right: 10px;
`;
InputWrapper.displayName = 'InputWrapper';

export const Input = styled.input`
  width: 214px;
  height: 34px;
  padding: 10px 15px 8px 15px;
  box-shadow: inset 0 -1px 0 0 #afafaf;
  background-color: #eff0f3;
  border: none;
  color: ${colors.black};
  font-size: 13.5px;
  font-weight: normal;
  ::placeholder {
    color: #bec8d2;
  }
  :focus {
    background-color: ${colors.white};
  }
`;
Input.displayName = 'Input';

export const Label = styled.div`
  margin-top: 10px;
  font-size: 12.5px;
  font-weight: normal;
  color: ${colors.greyBlue};
  text-align: initial;
`;
Label.displayName = 'Label';

export const AddButton = styled.button`
  border-radius: 2px;
  border: solid 1px #707070;
  background-color: ${colors.white};
  color: ${colors.darkBlueGrey};
  text-align: center;
  min-width: 68px;
  width: auto;
  height: 34px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px;
  cursor: pointer;
`;
AddButton.displayName = 'AddButton';
