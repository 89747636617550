/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React, { useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'store/modals/actions';
import { MODAL_TASKS_REDIRECT_TO_MANAGE } from 'store/modals/modals';
import { setSelectedLinkedVuln } from 'store/Tasks/actions';
import { selectHasPendingChanges } from 'store/Tasks/selectors';
import get from 'lodash/get';
import { redirect } from 'store/Router/actions';
import Text from './styled';

const TextLink = ({ vuln, isLinkEnabled }) => {
  const dispatch = useDispatch();
  const linkRef = useRef(null);
  const linkedVulnId = get(vuln, 'id', 0);
  const linkedVulnWs = get(vuln, 'workspace', '');
  const hasPendingChanges = useSelector(selectHasPendingChanges);

  const openInNewTab = () => {
    if (isLinkEnabled) {
      const newWindow = window.open(`manage/${linkedVulnWs}/${linkedVulnId}`, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
      dispatch(setSelectedLinkedVuln(null));
    }
  };

  const redirectConfirmation = (e) => {
    if (e.ctrlKey || e.metaKey) openInNewTab();
    else {
      dispatch(setSelectedLinkedVuln(vuln));
      if (hasPendingChanges) dispatch(openModal(MODAL_TASKS_REDIRECT_TO_MANAGE));
      else {
        dispatch(redirect(`/manage/${linkedVulnWs}/${linkedVulnId}`));
        dispatch(setSelectedLinkedVuln(null));
      }
    }
  };

  useEffect(() => {
    const currentLinkRef = linkRef.current;
    if (currentLinkRef && isLinkEnabled) currentLinkRef.addEventListener('click', redirectConfirmation);

    return () => {
      if (currentLinkRef && isLinkEnabled) currentLinkRef.removeEventListener('click', redirectConfirmation);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPendingChanges]);

  return <Text isLinkEnabled={ isLinkEnabled } ref={ linkRef } onAuxClick={ openInNewTab } confirmed children={ vuln.name } />;
};

TextLink.propTypes = {
  vuln: PropTypes.object.isRequired,
  isLinkEnabled: PropTypes.bool.isRequired
};

export default TextLink;
