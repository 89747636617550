import React, { useState } from 'react';
import {
  dashboardTourConfig, sidebarMenuTourConfig,
  assetVulnerTourConfig, vulnsTourConfig
} from './tourConfig';
import CommonTour from './CommonTour';

export const SidebarTour = () => {
  const [isTourOpen, setTour] = useState(false);

  return (
    <CommonTour
      steps={ sidebarMenuTourConfig() }
      isTourOpen={ isTourOpen }
      setTour={ setTour }
      flag="sidebarTourFinished"
    />
  );
};

export const DashboardTour = () => {
  const [isTourOpen, setTour] = useState(false);

  return (
    <CommonTour
      steps={ dashboardTourConfig() }
      isTourOpen={ isTourOpen }
      setTour={ setTour }
      flag="dashboardTourFinished"
    />
  );
};

export const AssetVulnsTour = () => {
  const [isTourOpen, setTour] = useState(false);

  return (
    <CommonTour
      steps={ assetVulnerTourConfig() }
      isTourOpen={ isTourOpen }
      setTour={ setTour }
      flag="assetVulnsTourFinished"
    />
  );
};

export const VulnsTour = () => {
  const [isTourOpen, setTour] = useState(false);

  return (
    <CommonTour
      steps={ vulnsTourConfig() }
      isTourOpen={ isTourOpen }
      setTour={ setTour }
      flag="vulnsTourFinished"
    />
  );
};
