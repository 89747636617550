import styled from 'styled-components';
import colors from 'Styles/colors';

const ModalWrapper = styled.div`
  border-top: 10px solid ${colors.softBlue};
  padding: 36px 39px;
`;
ModalWrapper.displayName = 'ModalWrapper';

export default ModalWrapper;
