import React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectAll } from 'store/Manage/selectors';
import { selectIsFiltering } from 'store/Filters/selectors';
import { Wrapper, Tag } from './styled';

const Items = ({ tags, onClickTag }) => {
  const selectAll = useSelector(selectSelectAll);
  const isFiltering = useSelector(state => selectIsFiltering('vulns', state));

  return (
  <Wrapper>
    { tags && tags.map((tag) => (
      <Tag
        key={ `wtag_${tag.key}` }
        added={ tag.addedToVuln }
        partialSelected={ tag.partialSelected }
        onClick={ () => onClickTag(tag) }
        isDisable={ selectAll || (selectAll && isFiltering) }
      >
        { tag.name }
      </Tag>)) }
  </Wrapper>
  );
};

export default Items;
