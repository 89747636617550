import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Wrapper, ModalWrapper, Title, WarningIcon, Subtitle, List,
  Item, Done, Bold
} from './styled';
import useKeyPress from 'Hooks/useKeyPress';

const BoldSubtitle = ({ sub }) => {
  if (!sub) return null;
  return (
    <Subtitle>
      {sub.start}
      <Bold>{sub.middle}</Bold>
      {sub.end}
    </Subtitle>
  );
};

const renderList = (list, listWithDoubleQuotes) => {
  if (list && listWithDoubleQuotes) return <List>{ list.map((el) => <Item key={ el }>{ `"${el}"` }</Item>) }</List>;
  else if (list) return <List>{ list.map((el) => <Item key={ el }>{ `- ${el}` }</Item>) }</List>;
  else return null;
};

const WarningModal = ({
  title, subtitle, firstSubtitleBold, secondSubtitleBold, list, showIcon, onDone, listWithDoubleQuotes
}) => {
  useKeyPress(() => {
    onDone();
  }, ['Escape', 'Enter']);

  return (
    <Wrapper>
      <ModalWrapper>
        <Title>
          { showIcon ? <WarningIcon /> : null }
          { title }
        </Title>
        { firstSubtitleBold ? <BoldSubtitle sub={ firstSubtitleBold } /> : null }
        { secondSubtitleBold ? <BoldSubtitle sub={ secondSubtitleBold } /> : null }
        { subtitle ? <Subtitle>{ subtitle }</Subtitle> : null }
        {renderList(list, listWithDoubleQuotes)}
        <Done onClick={ onDone }>OK</Done>
      </ModalWrapper>
    </Wrapper>
  );
};

WarningModal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  firstSubtitleBold: PropTypes.instanceOf(Object),
  secondSubtitleBold: PropTypes.instanceOf(Object),
  list: PropTypes.instanceOf(Object),
  showIcon: PropTypes.bool,
  onDone: PropTypes.func.isRequired,
  listWithDoubleQuotes: PropTypes.bool
};

WarningModal.defaultProps = {
  subtitle: '',
  firstSubtitleBold: null,
  secondSubtitleBold: null,
  list: null,
  showIcon: false,
  listWithDoubleQuotes: false
};

export default WarningModal;
