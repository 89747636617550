import React, { useState } from 'react';
import Wrapper from './styled';

const useModal = (Element) => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => setShowModal(!showModal);
  /* eslint-disable react/jsx-props-no-spreading */
  const Modal = (props) => <Wrapper><Element { ...props } /></Wrapper>;

  return [Modal, showModal, toggle];
};

export default useModal;
