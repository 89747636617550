import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-bottom: ${(props) => ((props.marginBottom) ? `${props.marginBottom}` : '20px')};
`;
Wrapper.displayName = 'Wrapper';

export const DisabledWrapper = styled.div`
    ${({ disabled }) => disabled && 'cursor: not-allowed;'};
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    color: #afb1bc;
    margin-bottom: 8px;
    color: ${(props) => (props.renderError ? '#ea3158' : '#afb1bc')};
`;
Title.displayName = 'Title';

export const CustomInput = styled.input`
    width: ${(props) => (props.width ? `${props.width}` : '224px')};
    height: ${(props) => (props.height ? `${props.height}` : '38px')};
    box-shadow: ${(props) => (props.renderError ? 'inset 0 -1px 0 0 #ea3158' : 'inset 0 -1px 0 0 #afafaf')};
    background-color: #ffffff;
    border: none;
    padding-left: 15px;
    padding-right: 42px;
    font-size: 14.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.48;
    letter-spacing: normal;
    color: #1c2243;

    ${({ disabled }) => disabled &&
        `
            color: #bec8d2;
            background-color: #edf2f7;
            pointer-events: none;
        `
    };

    &.full{
        background-color: white;
    }

    &::placeholder {
        color: #afb1bc;
        font-size: 13.5px;
    }

    &:hover{
        border: 1px solid #afb1bc;
    }

    &:focus{
        background-color: white;

        &::placeholder {
            color: transparent;
        }
    }
`;
CustomInput.displayName = 'CustomInput';
