/* eslint-disable no-useless-constructor */
import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'Common/Components/Dropdown';
import { UserRoleDropdownStyle } from 'Common/styles/style';
import { injectIntl } from 'react-intl';
import { Wrapper } from './styled';

const SeverityItem = ({ title }) => (
  <div className="d-inline-flex item-option role-name">
    <span>{title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()}</span>
  </div>
);

SeverityItem.propTypes = {
  title: PropTypes.string.isRequired
};

// eslint-disable-next-line react/prefer-stateless-function
class UserRoleDropdown extends React.Component {
  constructor (props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange (value) {
    const { updateValue } = this.props;
    updateValue(value);
  }

  render () {
    const roles = [
      { name: 'Administrator', value: 'admin' },
      { name: 'Pentester', value: 'pentester' },
      { name: 'Asset Owner', value: 'asset owner' },
      { name: 'Client', value: 'client' }
    ];
    const {
      defaultValue, intl, id, disabled, tooltip
    } = this.props;
    const options = roles.map((element) => ({ value: element.value, label: <SeverityItem title={ element.name } /> }));
    return (
      <Wrapper id={ id } title={ tooltip } disabled={ disabled }>
        <Dropdown disabled={ disabled } customStyle={ UserRoleDropdownStyle } options={ options } updateValue={ this.onChange } placeholder={ `${intl.formatMessage({ id: 'user-role' })}*` } defaultValue={ defaultValue } />
      </Wrapper>
    );
  }
}

UserRoleDropdown.propTypes = {
  updateValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string
};

UserRoleDropdown.defaultProps = {
  defaultValue: undefined,
  id: '',
  disabled: false,
  tooltip: ''
};

export default injectIntl(UserRoleDropdown);
