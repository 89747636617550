import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteVulnsSelected, hideModalDelete, redirectToManageDetail, setPageNumberVulnAssets, showModalDelete } from 'store/Contextualization/AssetDetail/actions';
import { selectGroupByField, selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import { selectIsAdmin } from 'store/Faraday/selectors';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import Pagination from 'Common/Components/Pagination';
import CreateActions from './components/CreateActions';
import EditButton from './components/EditButton';
import TagButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/TagButton';
import CopyLink from 'Screens/Contextualization/Manage/components/CustomActionBar/components/CopyLink';
import CopyApiLink from 'Screens/Contextualization/Manage/components/CustomActionBar/components/CopyApiLink';
import DeleteButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/DeleteButton';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import RefreshButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/RefreshButton';
import Severity from 'Screens/Contextualization/Manage/components/CustomActionBar/components/Severity';
import Status from 'Screens/Contextualization/Manage/components/CustomActionBar/components/Status';
import AddNewEvidence from 'Screens/Contextualization/Manage/components/CustomActionBar/components/AddNewEvidence';
import SaveAsTemplate from 'Screens/Contextualization/Manage/components/CustomActionBar/components/SaveAsTemplate';
import ApplyTemplate from 'Screens/Contextualization/Manage/components/CustomActionBar/components/ApplyTemplate';
import AddRemoveColumnsButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/AddRemoveColumnsButton';
import FilterSideBarIcon from 'Screens/Contextualization/Manage/components/CustomActionBar/components/FilterSidebarIcon';
import DuplicateButton from './components/DuplicateButton';
import QuickPlannerButton from 'Screens/Contextualization/Planner/QuickPlanner/QuickPlannerButton';
import SendToTool from 'Common/Components/Contextualization/SendToTool';
import AddComment from './components/AddComment';
import { selectCount, selectIsFetching, selectSelectAllVulns, selectSelected, selectShowDeleteConfirmation, selectShowDetail, selectVulnDetailId } from 'store/Contextualization/AssetDetail/selectors';
import { selectVulnsAssetFields } from 'store/Preferences/vulnsAsset/selectors';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/vulnsGeneral/actions';

const ActionBar = ({ entity, showDuplicateModal }) => {
  const dispatch = useDispatch();
  const vulnsCount = useSelector((state) => selectCount('vulns', state));
  const page = useSelector((state) => selectPage('vulnsAssets', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('vulnsAssets', state));
  const hasVulns = vulnsCount > 0;
  const onPrev = () => dispatch(setPageNumberVulnAssets(page - 1));
  const onNext = () => dispatch(setPageNumberVulnAssets(page + 1));
  const isAdmin = useSelector(selectIsAdmin);
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const showDetail = useSelector((state) => selectShowDetail('vulns', state));
  const isGrouping = useSelector((state) => selectGroupByField('vulnsAssets', state));
  const fields = useSelector(selectVulnsAssetFields);
  const showDeleteConfirmation = useSelector((state) => selectShowDeleteConfirmation('vulns', state));
  const selectAll = useSelector(selectSelectAllVulns);
  const isFetching = useSelector((state) => selectIsFetching('vulns', state));
  const isVulnDetailVisible = useSelector((state) => selectShowDetail('vulns', state));
  const vulnDetailId = useSelector(selectVulnDetailId);

  return (
    <Wrapper>
      { showDeleteConfirmation
        ? <DeleteConfirmationModal
            showDeleteConfirmation={ showDeleteConfirmation }
            selectAll={ selectAll }
            isFetching={ isFetching }
            vulnsSelected={ vulnsSelected }
            deleteVulns={ () => dispatch(deleteVulnsSelected()) }
            hideModalDelete={ () => dispatch(hideModalDelete('vulns')) }
            vulnsCount={ vulnsCount }
        />
        : null
      }
      <LeftGroup>
        <CreateActions />
        <Separator />
        <Group>
          <EditButton hasVulns={ hasVulns } />
          <SendToTool hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } isVulnDetailVisable={ showDetail } vulnId={ vulnDetailId } entity={ entity } />
          <TagButton hasItems={ hasVulns } />
          <CopyLink hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } />
          <CopyApiLink hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } />
          <Severity hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } />
          <Status hasVulns={ hasVulns } groupBy={ isGrouping } vulnsSelected={ vulnsSelected } entity={ entity } />
          <AddComment hasVulns={ hasVulns } />
          <AddNewEvidence hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } openEvidenceTab={ (vulnId) => dispatch(redirectToManageDetail(vulnId, 'evidence')) } />
          <SaveAsTemplate hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } entity={ entity } />
          <ApplyTemplate hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } />
          <DeleteButton hasVulns={ hasVulns } vulnsSelected={ vulnsSelected } showVulnDetail={ isVulnDetailVisible } showModalDelete={ () => dispatch(showModalDelete('vulns')) } />
          <DuplicateButton hasVulns={ hasVulns } showModal={ showDuplicateModal } />
        </Group>
        { isAdmin &&
          <Group>
            <Separator />
            <QuickPlannerButton hasVulns={ hasVulns } entity={ entity } />
          </Group>
        }
      </LeftGroup>

      <RightGroup>
        { <Pagination offset={ hasVulns ? page : null } limit={ rowsPerPage } total={ vulnsCount } onPrev={ onPrev } onNext={ onNext } />}
        { hasVulns && <Separator /> }
        <Group>
          <RefreshButton entity={ entity } />
          <FilterSideBarIcon entity="vulnsAssets" />
          <AddRemoveColumnsButton
            fields={ fields }
            setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
            setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
            resetDefault={ () => dispatch(resetDefault()) }
          />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default ActionBar;
