import styled from 'styled-components';
import { ReactComponent as RemoveSVG } from 'Images/icon-close-without-background.svg';
import { LinkAsButton } from 'Common/Components/LinkAsButton/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 25px;
  &:hover{
    & > :last-child {
      display: block;
    }
  }  
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c4566;
  user-select: none;
  margin: auto 0px auto 12px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;
Label.displayName = 'Label';

export const ThisVulnLabel = styled(Label)`
  ${({ show }) => !show && 'display: none'};
  font-weight: 400;
  margin-left: 6px;
`;
ThisVulnLabel.displayName = 'ThisVulnLabel';

export const LinkButton = styled(LinkAsButton)`
  display: ${({ show }) => (show ? 'block' : 'none')};
  padding: 0px;
  padding-top: 2px;
  flex: 0 0 80px;
  text-decoration: underline;
  margin: 2px 0 auto 5px;
`;
LinkButton.displayName = 'LinkButton';

export const RemoveIcon = styled(RemoveSVG)`
  display: ${({ show }) => (show ? 'none' : 'none !important')}; // Avoid showing the remove icon if is a main vuln.
  margin: auto auto auto 7px;
  cursor: pointer;
  & g {
    & path{
      fill: #a4b4c2;
    }  
  }
`;
RemoveIcon.displayName = 'RemoveIcon';
