/* eslint-disable react/jsx-key */
import React from 'react';
import get from 'lodash/get';
import Processing from './components/Processing';
import FinishedOk from './components/FinishedOk';
import FinishedWithError from './components/FinishedWithError';
import Uploading from './components/Uploading';

const Status = ({ file }) => {
  const mockCommandId = get(file, 'mockCommandId', '');
  const commandId = get(file, 'command_id', 0);
  const finished = get(file, 'finished', false);
  const error = get(file, 'error', true);
  const warning = get(file, 'warning', null);
  const warnings = get(file, 'warnings', null);

  if (!finished && mockCommandId) return <Uploading />;
  if (!finished && commandId) return <Processing commandId={ commandId } />;
  if (error || warning || warnings) return <FinishedWithError file={ file } warning={ warning } warnings={ warnings } />;
  return <FinishedOk commandId={ commandId } />;
};

export default Status;
