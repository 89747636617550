/* eslint-disable import/prefer-default-export */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Skeleton from 'Common/Components/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'store/modals/actions';
import { MODAL_PROJECTS_CREATE_EDIT, MODAL_PROJECTS_DELETE } from 'store/modals/modals';
import ActionButton from 'Screens/Planner/Projects/Content/components/Table/components/ActionButton';
import { useIntl } from 'react-intl';
import { setSelectedProject } from 'store/Projects/actions';
import DateFormatter from 'Common/Components/DateFormatter';
import { selectViewType } from 'store/Projects/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_PROJECTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { EditIcon, DeleteIcon, Wrapper } from '../styled';

export const EditDeleteCell = ({
  isFetching, isPivot, rowInfo
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const assignees = get(rowInfo, 'original.assignees', '');
  const project = get(rowInfo, 'original', null);
  const editProjectLabel = intl.formatMessage({ id: 'planner.project.editProject' });
  const deleteProjectLabel = intl.formatMessage({ id: 'planner.project.deleteProject' });
  const viewType = useSelector(selectViewType);
  const splitView = viewType.id === 'splitView';
  const startDate = get(rowInfo, 'original.startDate', '');
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_PROJECTS, PERMISSION_TYPES.UPDATE));
  const canDelete = useSelector((state) => selectAllowedByRole(state, FEATURE_PROJECTS, PERMISSION_TYPES.DELETE));

  const editProject = () => {
    dispatch(setSelectedProject(project));
    dispatch(openModal(MODAL_PROJECTS_CREATE_EDIT));
  };

  const deleteProject = () => {
    dispatch(setSelectedProject(project));
    dispatch(openModal(MODAL_PROJECTS_DELETE));
  };

  if (isFetching) return <Skeleton />;
  if (!isPivot && !splitView) return <div children={ assignees.map((d) => d.name).join(', ') } />;
  if (!isPivot && splitView) return startDate ? <DateFormatter date={ startDate } midDate /> : null;
  return (
    <Wrapper>
      <ActionButton show={ canUpdate } title={ editProjectLabel } onClick={ editProject } icon={ <EditIcon /> } />
      <ActionButton show={ canDelete } title={ deleteProjectLabel } onClick={ deleteProject } icon={ <DeleteIcon /> } />
    </Wrapper>
  );
};

EditDeleteCell.propTypes = {
  isPivot: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  rowInfo: PropTypes.object.isRequired
};
