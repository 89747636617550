import React from 'react';
import Checkbox from 'Common/Components/Checkbox';
import { CheckboxWrapper, Label } from './styled';

const Entity = ({ entity, value, onChangeField }) => {
  return (
    <CheckboxWrapper>
      <Checkbox state={ value } onChange={ (e) => onChangeField(!e.currentTarget.checked) } />
      <Label>{ entity }</Label>
    </CheckboxWrapper>
  );
};

export default Entity;
