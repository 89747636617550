import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'Common/Components/Checkbox';
import { autoSelectNotification, selectNotificationRow } from 'store/Notifications/actions';
import {
  selectNotificationsLoading, selectSelectAll,
  selectSelectedNotifications, selectUnselectedRowFromSelectAll
} from 'store/Notifications/selectors';

const CheckColumn = ({ notification }) => {
  const dispatch = useDispatch();
  const selectedNotifications = useSelector(selectSelectedNotifications);
  const selectAll = useSelector(selectSelectAll);
  const loading = useSelector(selectNotificationsLoading);
  const unselectedRowFromSelectAll = useSelector(selectUnselectedRowFromSelectAll);

  const isSelected = selectedNotifications.some(
    (selected) => selected.id === notification.id
  );
  useEffect(() => {
    if (!loading && selectAll && !isSelected && !unselectedRowFromSelectAll) {
      dispatch(autoSelectNotification(notification));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  const handleClick = (e) => {
    dispatch(selectNotificationRow(e, notification));
  };

  return (
    <Checkbox
      theme="grey"
      state={ isSelected }
      onChange={ handleClick }
    />
  );
};

export default CheckColumn;
