import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectId } from 'store/Analytics/selectors';
import { confirmNavigation, resetState } from 'store/Analytics/actions';
import { redirect } from 'store/Router/actions';
import {
  CancelButton
} from './styled';

const Cancel = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const graphId = useSelector(selectId);
  const cancelLabel = intl.formatMessage({ id: 'analytics.createEdit.cancel' });

  const onClick = () => {
    if (graphId) {
      dispatch(confirmNavigation());
    } else {
      dispatch(resetState());
      dispatch(redirect('/analytics'));
    }
  };
  return (
    <CancelButton onClick={ onClick }>{ cancelLabel }</CancelButton>
  );
};

export default Cancel;
