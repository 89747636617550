/* eslint-disable react/no-children-prop */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { FormattedMessage, useIntl } from 'react-intl';
import { redirect } from 'store/Router/actions';
import { useDispatch } from 'react-redux';
import { MessageWrapper, StyledLink } from './styled';

const Message = ({ agentToDelete }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  // Each agent has several executors that can be linked to multiple schedulers.
  // This takes the related schedulers of the executors and count them to change the warning message if needed.
  const executors = get(agentToDelete, 'executors', []);
  const executorsSchedules = executors.map((e) => get(e, 'schedules', []));
  const scheduleCount = uniq(executorsSchedules.flat().map((s) => s.id)).length;
  const scheduleMessage = `${scheduleCount} ${intl.formatMessage({ id: 'agents.delete.scheduleCount' })}`;

  if (scheduleCount === 0) return <MessageWrapper children={ intl.formatMessage({ id: 'agents.delete.message' }) } />;
  return (
    <MessageWrapper>
      <FormattedMessage
        id="agents.delete.scheduleMessage"
        values={ { scheduleCount: <StyledLink children={ scheduleMessage } onClick={ () => dispatch(redirect('/automation/schedule')) } /> } }
      />
    </MessageWrapper>
  );
};

export default Message;
