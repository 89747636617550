/* eslint-disable import/prefer-default-export */
import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnsSelected } from 'store/Manage/selectors';
import {
  showVulnerabilityDetail, showContextMenu
} from 'store/Manage/actions';
import { useIntl } from 'react-intl';
import {
  FEATURE_VULNS, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission';
import get from 'lodash/get';
import head from 'lodash/head';
import { EvidenceIcon } from './styled';

export const AddNewEvidence = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const selectedVulnID = get(head(vulnsSelected), '_id', null);
  const isBulk = vulnsSelected.length > 1;

  if (isBulk) return null;
  return (
    <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
      <MenuItem
        title={ intl.formatMessage({ id: 'manage.cm.addNewEvidence' }) }
        icon={ <EvidenceIcon /> }
        onClick={ () => {
          if (selectedVulnID) dispatch(showVulnerabilityDetail(selectedVulnID, 'evidence'));
          dispatch(showContextMenu(false));
        } }
      />
    </WithPermission>
  );
};
