import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import TagItems from './components/TagItems';
import { Wrapper, Title, Subtitle, EditButton, Entities } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { getTags, setCreationTagModal, setTagsImportModal } from 'Screens/Contextualization/Tags/actions/Actions';
import { selectTags } from 'store/Tags/selectors';
import CheckboxField from '../AdvancedOptions/CheckboxField';
import { setTagsEntityImport } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import { selectHasAssetTags, selectHasServiceTags, selectHasVulnTags } from 'store/ManageEditCreate/selectors';

const Tags = ({ tags }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const hasTags = !isEmpty(tags);
  const hasTagsLabel = intl.formatMessage({ id: 'importFileCreationModal.tags.hasTagsSubtitle' });
  const noTagsLabel = intl.formatMessage({ id: 'importFileCreationModal.tags.noTagsSubtitle' });
  const editLabel = intl.formatMessage({ id: 'manage.detail.tab.general.tags.edit' });
  const wsTags = useSelector(selectTags);
  const hasAssetsTags = useSelector(selectHasAssetTags);
  const hasVulnsTags = useSelector(selectHasVulnTags);
  const hasServicesTags = useSelector(selectHasServiceTags);

  useEffect(() => {
    dispatch(getTags());
    dispatch(setTagsImportModal(wsTags));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Title children={ intl.formatMessage({ id: 'importFileCreationModal.tags.title' }) } />
      <Subtitle children={ hasTags ? hasTagsLabel : noTagsLabel } />
      <TagItems tags={ tags } />
      <EditButton onClick={ () => dispatch(setCreationTagModal(true, 'import')) }>{ editLabel }</EditButton>
      <Entities>
        <CheckboxField defaultValue={ hasAssetsTags } label="Assets" onChange={ (checked) => dispatch(setTagsEntityImport('assetsTags', checked)) } />
        <CheckboxField defaultValue={ hasVulnsTags } label="Vulnerabilities" onChange={ (checked) => dispatch(setTagsEntityImport('vulnsTags', checked)) } />
        <CheckboxField defaultValue={ hasServicesTags } label="Services" onChange={ (checked) => dispatch(setTagsEntityImport('servicesTags', checked)) } />
      </Entities>

    </Wrapper>
  );
};

export default Tags;
