import StringField from 'Common/Components/StringField';
import styled from 'styled-components';
import colors from 'Styles/colors';

export const Title = styled.div`
  user-select: none;
  font-size: 21.5px;
  font-weight: 600;
  line-height: 0.84;
  color: ${colors.dark2};
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  user-select: none;
  padding: 17px 0 0 0;
  font-size: 12.5px;
  line-height: 1.12;
  text-align: left;
  color: ${colors.grey19};
  margin-bottom: 30px;
`;
Subtitle.displayName = 'Subtitle';

export const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  .checkbox-container {
    display: inline;
    margin-bottom: 0px;
  }
`;
WrapperCheckbox.displayName = 'WrapperCheckbox';

export const CheckboxLabel = styled.div`
  font-size: 13.5px;
  line-height: 1.04;
  text-align: left;
  font-weight: 400;
`;
CheckboxLabel.displayName = 'CheckboxLabel';

export const Input = styled(StringField).attrs(({
  type: 'text',
  width: '100%'
}))`
  margin-bottom: 16px;
  max-width: 62%;
  width: 100%;
  ${Title} {
    margin-left: 0;
    font-weight: 400;
    color: #afb1bc;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
  }
`;
Input.displayName = 'Input';

export const Wrapper = styled.div`
  width: 900px;
  height: 813px;
  background-color: ${colors.white};
  padding: 0 !important;
  text-align: initial;
`;
Wrapper.displayName = 'Wrapper';
