import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Warning } from 'Images/warning-delete.svg';

export const WarningIcon = styled(Warning)`
  margin-bottom: 7px; 
  width: 21px;
  height: 20px;
  & g {
    fill: ${colors.redPink};
  }

`;
WarningIcon.displayName = 'WarningIcon';

export const Text = styled.p`
  font-size: 12.5px;
  font-weight: 500;
  color: ${colors.dark2};
  text-align: center;
`;
Text.displayName = 'Text';

export const BlueText = styled.p`
  font-size: 12.5px;
  font-weight: 500;
  color: ${colors.blueCerulean};
  cursor: pointer;
  margin-top: 14px;
`;
BlueText.displayName = 'BlueText';
