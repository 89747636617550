import styled from 'styled-components';

export const Wrapper = styled.div`
  & > :not(:last-child){
    margin-bottom: 25px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const CommentTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
CommentTextAreaWrapper.displayName = 'CommentTextAreaWrapper';

export const Title = styled.div`
  height: 42px;
  width: 100%;
  padding-left: 25px;
  justify-content: center;
  user-select: none;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  font-size: 17.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;
Title.displayName = 'Title';
