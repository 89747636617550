import styled from 'styled-components';
import textPreview from 'Images/text-preview.png';
import imagePreview from 'Images/image-preview.png';

export const Wrapper = styled.div`
  font-size: 21.5px;
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  font-size: 13.5px;
  line-height: 1.04;
`;
Label.displayName = 'Label';

export const PreviewText = styled.img.attrs({
  src: textPreview
})`
  width: 129px;
  margin: 0px 21px 20px;
`;
PreviewText.displayName = 'PreviewText';

export const PreviewImage = styled.img.attrs({
  src: imagePreview
})`
  width: 240px;
  display: block;
  margin-left: 21px;
  border: ${(props) => props.borderWidth}px solid black;
`;
PreviewImage.displayName = 'PreviewImage';

export const ExampleContainer = styled.div`
  background-color: #fafbfc;
`;

export const ErrorMessage = styled.div`
  margin-top: 5px;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ea3158;
`;
ErrorMessage.displayName = 'ErrorMessage';
