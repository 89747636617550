/* eslint-disable import/prefer-default-export */
import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { saveTemplateFromVulnContext, showContextMenu } from 'store/Manage/actions';
import { useIntl } from 'react-intl';
import { selectUserRole } from 'store/Faraday/selectors';
import {
  FEATURE_VULNS, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission';
import { SaveTemplateIcon } from './styled';
import { selectVulnsSelected } from 'store/Manage/selectors';

export const SaveAsTemplate = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const role = useSelector(selectUserRole);

  if (role === 'client' || role === 'asset_owner') return null;

  return (
    <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
      <MenuItem
        title={ intl.formatMessage({ id: 'manage.cm.saveAsTemplate' }) }
        icon={ <SaveTemplateIcon /> }
        onClick={ () => {
          dispatch(saveTemplateFromVulnContext(vulnsSelected, 'vulns'));
          dispatch(showContextMenu(false));
        } }
      />
    </WithPermission>
  );
};
