import get from 'lodash/get';

export const selectIsFetching = (state) => get(state, 'settings.saml.isFetching', false);

export const selectIsEnabled = (state) => get(state, 'settings.saml.enabled', false);
export const selectDisplayName = (state) => get(state, 'settings.saml.displayName', '');
export const selectIdentityId = (state) => get(state, 'settings.saml.identityId', '');
export const selectUserRole = (state) => get(state, 'settings.saml.userRole', '');
export const selectAttributeIdentifier = (state) => get(state, 'settings.saml.attributeIdentifier', '');
export const selectSsoUrl = (state) => get(state, 'settings.saml.ssoUrl', '');
export const selectSpCertificate = (state) => get(state, 'settings.saml.spCertificate', '');
export const selectSpPrivateKey = (state) => get(state, 'settings.saml.spPrivateKey', '');
export const selectIdpCertificate = (state) => get(state, 'settings.saml.idpCertificate', '');
export const selectUseAttributeName = (state) => get(state, 'settings.saml.useAttributeRole', false);
export const selectAttributeName = (state) => get(state, 'settings.saml.attributeIdentifierRole', '');

export const selectAdminRole = (state) => get(state, 'settings.saml.admin', '');
export const selectPentesterRole = (state) => get(state, 'settings.saml.pentester', '');
export const selectAssetOwnerRole = (state) => get(state, 'settings.saml.assetOwner', '');
export const selectClientRole = (state) => get(state, 'settings.saml.client', '');

export const selectCanSubmit = (state) => {
  const displayName = !!selectDisplayName(state);
  const identityId = !!selectIdentityId(state);
  const userRole = !!selectUserRole(state);
  const attributeIdentifier = !!selectAttributeIdentifier(state);
  const ssoUrl = !!selectSsoUrl(state);
  const spCertificate = !!selectSpCertificate(state);
  const spPrivateKey = !!selectSpPrivateKey(state);
  const idpCertificate = !!selectIdpCertificate(state);
  const useAttributeName = !!selectUseAttributeName(state);
  const attributeName = !!selectAttributeName(state);
  const isAttributeNameEnabled = useAttributeName ? attributeName : userRole;

  return displayName &&
  identityId &&
  userRole &&
  attributeIdentifier &&
  ssoUrl &&
  spCertificate &&
  spPrivateKey &&
  idpCertificate &&
  isAttributeNameEnabled;
};
