import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { setUploadState } from 'store/ExecutiveReportEditCreate/actions';
import { Wrapper } from '../TestingFile/styled';
import { WarningIcon, Text, BlueText } from './styled';

const Error = ({ setStep }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const goBack = () => {
    setStep('drag');
    dispatch(setUploadState(''));
  };

  return (
    <Wrapper>
      <WarningIcon />
      <Text>{ intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.addTemplate.error' }, { br: <br /> }) }</Text>
      <BlueText onClick={ goBack }>{ intl.formatMessage({ id: 'reports.editCreate.selectTemplateModal.addTemplate.goBack' }) }</BlueText>
    </Wrapper>
  );
};

export default Error;
