import styled from 'styled-components';
import colors from 'Styles/colors';

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 282px;
  min-width: 430px;
  width: 610px;
  background-color: #fff;
  padding: 34px 34px 33px 34px;
  border-top-style: solid;
  border-top-width: 10px;
  border-top-color: ${colors.softBlue};
  position: relative;
`;
Modal.displayName = 'Modal';

export default Modal;
