import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { FiTrash2 as Trash } from 'react-icons/fi';

// TODO: Refactor code
const DeleteButton = ({
  readonly, entity, selectedEntities, display, openConfirmationModal
}) => {
  if (display) {
    return (
      <div className="margin-right-12">
        <Tooltip title={ readonly && entity !== 'workspaces' ? 'Workspace is read only.' : 'Delete' }>
          <div className={ `trash-container-hover ${selectedEntities.length === 0 ? 'disabled' : ''}` }>
            <Trash
              color="#1c4566"
              style={ { fontSize: '20px' } }
              onClick={ () => { if ((!readonly || entity !== 'workspaces')) openConfirmationModal(); } }
              className={ `icon-trash ${(readonly && entity !== 'workspaces') || selectedEntities.length === 0 ? 'disabled' : ''}` }
              id="action-button-delete"
            />
          </div>
        </Tooltip>
      </div>
    );
  }
  return null;
};

export default DeleteButton;
