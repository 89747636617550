import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import head from 'lodash/head';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import {
  FEATURE_VULNS, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import EvidenceIcon from './styled';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';

const AddNewEvidence = ({ hasVulns, vulnsSelected, openEvidenceTab }) => {
  const [showWarning, setShowWarning] = useState(false);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.cm.addNewEvidence' });
  const selectedVulnID = get(head(vulnsSelected), '_id', null);
  const readOnly = useSelector(selectIsReadOnly);
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.READ));
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'vulnerability' });
  const canUpdate = !readOnly && allowedByRole && hasVulns;

  const openEvidence = () => {
    if (canUpdate) {
      if (vulnsSelected.length === 1) openEvidenceTab(selectedVulnID);
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <EvidenceIcon /> } title={ title } onClick={ openEvidence } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title={ title } description={ message } /> }
    </>
  );
};

export default AddNewEvidence;
