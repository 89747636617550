import { copyArray } from 'Common/Functions/Copy';
import {
  UPDATE_NOTIFICATIONS,
  NEW_NOTIFICATION,
  SELECT_NOTIFICATION,
  DELETE_NOTIFICATION,
  SET_LAST_SELECTED_NOTIFICATION,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  SELECT_ALL_NOTIFICATIONS,
  UNSELECT_ALL_NOTIFICATIONS,
  MARK_NOTIFICATIONS_AS_READ,
  DELETE_NOTIFICATIONS,
  UPDATE_NOTIFICATION_TYPE,
  SET_NOTIFICATION_SETTINGS,
  SET_NOTIFICATIONS_PAGE,
  DELETE_ALL_NOTIFICATIONS,
  SELECT_TOTAL_NOTIFICATIONS,
  UNSELECT_TOTAL_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  UNSELECTED_ROW_FROM_SELECT_ALL,
  AUTOSELECT_NOTIFICATION
} from './actions';

const initialState = {
  allNotifications: [],
  unreadNotifications: [],
  selectedNotifications: [],
  notificationCenter: [],
  notificationAdvanced: [
    {
      type: 'New High/Critical severity Vulnerability in Workspace',
      slack: true,
      enabled: true,
      app: true,
      email: false
    },
    {
      type: 'Vulnerability with risk score grather than',
      slack: true,
      enabled: true,
      app: true,
      email: false
    },
    {
      type: 'High/Critical severity open vuln in Workspace for more than                        days',
      slack: true,
      enabled: true,
      app: true,
      email: false
    }
  ],
  count: 0,
  unreadCount: 0,
  lastSelectedNotificationIndex: -1,
  loading: false,
  error: null,
  rowsPerPage: 50,
  page: 1,
  selectAll: false
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: action.data,
        unreadCount: action.unreadCount
      };
    case NEW_NOTIFICATION:
      return {
        ...state,
        allNotifications: [...state.allNotifications, action.data],
        unreadCount: action.unreadCount
      };
    case SELECT_NOTIFICATION:
      return {
        ...state,
        selectedNotifications: action.selectedNotifications
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        allNotifications: state.allNotifications.filter(
          (notification) => !action.notificationIds.includes(notification.id)
        ),
        count: state.count - action.notificationIds.length
      };
    case SET_LAST_SELECTED_NOTIFICATION:
      return {
        ...state,
        lastSelectedNotificationIndex: action.index
      };
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        allNotifications: action.notifications,
        unreadCount: action.unreadCount,
        count: action.count,
        loading: false,
        error: null
      };
    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case SELECT_ALL_NOTIFICATIONS:
      return {
        ...state,
        selectedNotifications: state.allNotifications
      };
    case UNSELECT_ALL_NOTIFICATIONS:
      return {
        ...state,
        selectedNotifications: []
      };
    case MARK_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        unreadCount: action.count,
        allNotifications: state.allNotifications.map((notification) =>
          action.notificationIds.includes(notification.id)
            ? { ...notification, read: true }
            : notification
        ),
        selectedNotifications: []
      };
    case DELETE_NOTIFICATIONS: {
      return {
        ...state,
        selectedNotifications: []
      };
    }

    case UPDATE_NOTIFICATION_TYPE:

      return {
        ...state,
        notificationCenter: {
          ...state.notificationCenter,
          [action.payload.type]: action.payload.active
        }
      };
    case SET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationCenter: action.payload
      };

    case SET_NOTIFICATIONS_PAGE: {
      return {
        ...state,
        page: action.page
      };
    }

    case DELETE_ALL_NOTIFICATIONS: {
      return {
        ...state,
        allNotifications: initialState.allNotifications
      };
    }

    case SELECT_TOTAL_NOTIFICATIONS: {
      return {
        ...state,
        selectAll: true,
        unselectedRowFromSelectAll: false
      };
    }

    case UNSELECT_TOTAL_NOTIFICATIONS: {
      return {
        ...state,
        selectAll: false
      };
    }

    case MARK_ALL_NOTIFICATIONS_AS_READ: {
      return {
        ...state,
        unreadCount: action.count,
        allNotifications: state.allNotifications.map((notification) => { return { ...notification, read: true }; })
      };
    }

    case UNSELECTED_ROW_FROM_SELECT_ALL: {
      return {
        ...state,
        unselectedRowFromSelectAll: action.value
      };
    }

    case AUTOSELECT_NOTIFICATION : {
      const selectedNotifications = copyArray(state.selectedNotifications);
      selectedNotifications.push(action.notification);
      return {
        ...state,
        selectedNotifications
      };
    }

    default:
      return state;
  }
};

export default notifications;
